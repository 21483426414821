import React from "react";
import CardGestora from "../../Cards/CardGestora";
import _ from 'lodash';

const MyRoutesCard = ({routes = []}) => {
	return (
		<CardGestora title={"Rutas de aprendizaje"}>
			<div className="row">
				{routes.length > 0 ? (
					routes.map((route, index) => (
						<div className="d-flex col-12 my-1" key={index}>
							<img
								src="/images/check-morado.png"
								alt="check"
								style={{ width: "20px" }}
								className="mr-2"
							/>
							<span className="ge-title txt-bold">
								{_.capitalize(route.nombre_ruta)}
							</span>
						</div>
					))
				) : (
					<div className="col-12 no-tiene-cursos-asignado text-light py-2 ge-radius">
						<span>No tiene cursos asignados</span>
					</div>
				)}
			</div>
		</CardGestora>
	);
};

export default MyRoutesCard;
