import React, { useEffect, useState } from "react";
import { getBlogsFromDocente } from "../../../controllers/docentes/docentes";

const CardDocenteBlog = ({ idDocente = null}) => {
    
    const [blogList, setBlogList] = useState([]);
    
    const fetchData = async () => {
        const data = getBlogsFromDocente(idDocente);
        if(data) {
            setBlogList(data);
        }
    }
    
    useEffect(() => {
        if(idDocente) {
            fetchData();
        }
    }, [idDocente]);
    
    if(!idDocente) return null;
	return (
		<div className="card">
			<div className="card-body">
				{/* Header */}
				<div className="d-flex col-12 align-items-center header-details mb-3">
					<img
						src="/images/medalla.png"
						alt="icon-file"
						className="mr-2"
						style={{ maxWidth: "25px", minWidth: "25px" }}
					/>
					<span className="ge-title ge-h3">Publicaciones del blog</span>
				</div>
				<div className="list-items-details">
                    {
                        blogList.length > 0 ? (
                            blogList.map((blog, index) => (
                                <div 
                                    className="d-flex flex-column item-detail"
                                    key={index}
                                >
                                    <span className="my-3">
                                        {blog.titulo}
                                    </span>
                                    <div>
                                        <span className="ge-txt-primary mr-3 my-2">
                                            <i className="fas fa-heart mr-2"></i>
                                            {blog.likes}
                                        </span>
                                        <span className="ge-txt-primary mr-3 my-2">
                                            <i className="fas fa-comment mr-2"></i>
                                            {blog.comments}
                                        </span>
                                        <button className="btn ge-btn-primary btn-sm my-2">
                                            <i className="fas fa-edit mr-2"></i>
                                            Editar
                                        </button>
                                    </div>
                                </div>
                            ))
                        ):(
                            <div className="d-flex justify-content-center align-items-center w-100 rounded">
                                <span className="ge-title">No tiene publicaciones</span>
                            </div>
                        )
                    }
				</div>
			</div>
		</div>
	);
};

export default CardDocenteBlog;
