import React from "react";
import GananciaPerdida from "./Graficos/GananciaPerdida";

const HomeContabilidad = () => {
  return (
    <div className="card p-4">
      <h3>Dashboard Contabilidad</h3>
      <div className="d-flex align-items-center justify-content-center col-md-12 mt-4">
        <GananciaPerdida />
      </div>
    </div>
  );
};

export default HomeContabilidad;
