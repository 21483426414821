
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { createPlan, getFrecuenciasPago, getTiposPlan } from "../../controllers/planes/planes";

const CrearPlan = ({handleShow}) => {

    const{data, error , isLoading } = useQuery(["getFrecuenciasPago"], getFrecuenciasPago ,
            {
                refetchOnWindowFocus:false
            }
    )

    const{data : dataTipoPlan, error:errorTipoPlan , isLoading : isLoadingTipoPlan } = useQuery(["getTiposPlan"], getTiposPlan ,
    {
        refetchOnWindowFocus:false
    }
)

    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const {mutate, error : errorUpdate , isLoading : isLoadingUpdate } = useMutation(createPlan,
        {
            onSuccess:()=>{
                queryClient.invalidateQueries("planes")
                handleShow(false)
               // navigate("/listar-posts", { replace: true });
            }
        })

    const {user} = useSelector(state=>state.user)

    const initialValues = {
        id_tipo_plan:'',
        intervalo:'',
        intervalo_count:'',
        limite:'',
        currency_code: "PEN",
        nombre_plan:'',
        amount:'',
        n_estudiantes:'',
        precio_regular:'',
	};
	
   

    const [datosPost,setDatosPost]=useState(initialValues)
	const validationSchema = yup.object({
		id_tipo_plan: yup
			.string()
			.required("El campo es requerido"),
        intervalo: yup
			.string()
			.required("El campo es requerido"),
        intervalo_count: yup
            .number("Debe ser un número válido")
			.required("El campo es requerido"),
        amount: yup
            .number("Debe ser un número válido")
            .required("El campo es requerido"),
        precio_regular: yup
            .number("Debe ser un número válido")
            .required("El campo es requerido"),
        n_estudiantes: yup
            .number("Debe ser un número válido")
            .required("El campo es requerido"),
        limite: yup
            .number("Debe ser un número válido")
			.required("El campo es requerido"),
        nombre_plan: yup
            .string()
            .required("El campo es requerido"),
	});
	
	const formik = useFormik({
		initialValues:datosPost,
		validationSchema,
		onSubmit: async (values) => {
            if(values.limite==="0")
            {
                delete values.limite;
            }
            const datos = {...values}
            console.log(datos)
			mutate(datos)
		},
	});

    return (
        <form onSubmit={formik.handleSubmit}>
           <div className='row'>
                <div className='col-md-12'>
                    <div className="card">
                        <div className="card-body">
                                    <div className="form-group mb-3">
                                        <label>
                                            Nombre
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Nombre del Plan"
                                            name="nombre_plan"
                                            onChange={formik.handleChange}
                                            value={formik.values.nombre_plan}
                                        />
                                        <p> {formik.errors.nombre_plan} </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>Tipo de plan:</label>
                                        <select className="form-control"
                                                name="id_tipo_plan"
                                                onChange={formik.handleChange}
                                                value={formik.values.id_tipo_plan}>
                                                <option value="">{isLoadingTipoPlan ? 'Cargando tipos': !error ? 'Seleccione una tipo...': 'Error al traer datos...'}</option>
                                            {
                                                !isLoadingTipoPlan ? dataTipoPlan.length ? dataTipoPlan.map((tipo,i)=>(
                                                    <option key={i} value={tipo.id}>{tipo.nombre}</option>
                                                )) : null : null
                                            }
                                        </select>
                                        <p> {formik.errors.id_tipo_plan} </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>Frecuencia de pago:</label>
                                        <select className="form-control"
                                                name="intervalo"
                                                onChange={formik.handleChange}
                                                value={formik.values.intervalo}>
                                                <option value="">{isLoading ? 'Cargando frecuencias': !error ? 'Seleccione una frecuencia...': 'Error al traer escuelas'}</option>
                                            {
                                                !isLoading ? data.length ? data.map((frecuencia,i)=>(
                                                    <option key={i} value={frecuencia.id}>{frecuencia.frecuencia}</option>
                                                )) : null : null
                                            }
                                        </select>
                                        <p> {formik.errors.intervalo} </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>
                                            Cantidad de frecuencia:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Cada cuanto de va realizar cargos Ej: 1"
                                            title="Cantidad de cada cuanto se deben ejecutar los cargos del plan. Si en 'Frecuencia de pago' pusimos 'meses', poner valor 1 en este campo hará que se cobre cada mes."
                                            name="intervalo_count"
                                            onChange={formik.handleChange}
                                            value={formik.values.intervalo_count}
                                        />
                                        <p> {formik.errors.intervalo_count} </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>
                                            Límite:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Limite de cargos a realizar (ciclos). Ej: 12"
                                            name="limite"
                                            title="Limite de cargos a realizar (ciclos). Si es 0, es automáticamente sin límite"
                                            onChange={formik.handleChange}
                                            value={formik.values.limite}
                                        />
                                        <p> {formik.errors.limite} </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>
                                            Monto:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Precio del plan"
                                            name="amount"
                                            onChange={formik.handleChange}
                                            value={formik.values.amount}
                                        />
                                        <p> {formik.errors.amount} </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>
                                            Monto Regular:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Precio regular"
                                            name="precio_regular"
                                            onChange={formik.handleChange}
                                            value={formik.values.precio_regular}
                                        />
                                        <p> {formik.errors.precio_regular} </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>
                                            N° Estudiantes:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Número de beneficiarios. Ej: 1"
                                            name="n_estudiantes"
                                            onChange={formik.handleChange}
                                            value={formik.values.n_estudiantes}
                                        />
                                        <p> {formik.errors.n_estudiantes} </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <button
                                            type="submit"
                                            className="btn ge-btn-primary btn-block"
                                        >
                                            Guardar plan
                                        </button>
                                    </div>
                                    
                        </div>
                    </div>        
                </div>
           </div>
        </form>
    );
};

export default CrearPlan;