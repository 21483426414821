import React, { useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useMutateCreateHabilidad } from '../../../hooks/habilidades.hooks';
import { SwalToast } from '../../../utils/toasts';

const FormularioHabilidad = ({id_curso,setShow}) => {

    const [habilidad, setHabilidad] = useState("");

    const {mutateAsync: mutateCreateHabilidad, isLoading} = useMutateCreateHabilidad(id_curso);
    
    

    const handleSubmit = async (e)=>{
        e.preventDefault()
        if (habilidad.trim() === "") {
            return;
        }
        const datos = {
            id_curso:id_curso,
            habilidad:habilidad,
        }
        try {
            await mutateCreateHabilidad(datos,{
                onSuccess: () => {
                    setHabilidad("");
                    setShow(false);
                },
            });
        }catch(e){
            SwalToast.fire({
                icon: "error",
                title: "Error del servidor",
            });
        }
    }


    return (
<Form
                onSubmit={handleSubmit}
            >
				<Form.Group controlId="formNameModule" className="my-3">
					<Form.Label>Habilidad</Form.Label>
					<Form.Control
						type="text"
						placeholder="Ingrese la habilidad"
                        autoComplete="off"
                        value={habilidad}
                        onChange={(e) => setHabilidad(e.target.value)}
                        isInvalid={habilidad === ""}
                        isValid={habilidad !== ""}
					/>
					<Form.Control.Feedback type="invalid">
						La habilidad es requerida.
					</Form.Control.Feedback>
				</Form.Group>
                <div
                    className="d-flex justify-content-around w-100"
                >
                    <button
                        type="submit"
                        className="btn ge-btn-primary"
                        disabled={isLoading}
                    >
                        {
                            isLoading ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : (
                                "Crear"
                            )
                        }
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                            setShow(false);
                        }}
                        disabled={isLoading}
                    >
                        Cancelar
                    </button>
                </div>
			</Form>
    );
};

export default FormularioHabilidad;