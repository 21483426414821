import React from "react";
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from "react-vertical-timeline-component";
import dayjs from "dayjs";
import * as MdIcons from "react-icons/md";

const stylesSupport = {
	contentStyle: {
        background: "rgb(97, 76, 148)",
		color: "#fff",
	},
    contentArrowStyle: {
        borderRight: "7px solid rgb(97, 76, 148)",
    },
};

const stylesStudent = {
    contentStyle: {
        background: "rgb(153, 42, 255)",
		color: "#000",
	},
    contentArrowStyle: {
        borderRight: "7px solid rgb(153, 42, 255)",
    }
};


const TimeLineAnswers = ({ asnwers }) => {
	if (!asnwers || asnwers.length === 0) {
		return null;
	}

	return (
		<>
			{asnwers.map((answer, index) => (
				<VerticalTimeline 
                    layout="1-column-left" 
                    lineColor="#000"
                    key={answer.id_respuesta}
                >
					<VerticalTimelineElement
						contentStyle={
                            parseInt(answer.id_rol) === 1 ? stylesStudent.contentStyle : stylesSupport.contentStyle
                        }
						contentArrowStyle={
                            parseInt(answer.id_rol) === 1 ? stylesStudent.contentArrowStyle : stylesSupport.contentArrowStyle
                        }
						date={dayjs(answer.fecha_registro).format(
							"DD/MM/YYYY hh:mm A"
						)}
						iconStyle={{
							background: "rgb(152, 42, 255)",
							color: "#fff",
							border: "1px solid #000",
							boxShadow: "none",
							textAlign: "center",
						}}
						icon={
							parseInt(answer.id_rol) === 1 ? (
								<img
									src={
										answer.img_perfil
											? process.env.REACT_APP_API_URL + answer.img_perfil
											: "/images/user-icon.png"
									}
									className="rounded-circle"
									style={{ width: "100%" }}
									alt="..."
								/>
							) : (
								<MdIcons.MdSupportAgent size={20} />
							)
						}
					>
						<h5 className="vertical-timeline-element-subtitle">
							Respuesta:
						</h5>
						<p>{answer.respuesta}</p>
						{answer.img && (
							<div className="row">
								<div className="col-12">
									<strong>Evidencia:</strong>
								</div>
								<div className="col-12">
									<img
										src={
											process.env.REACT_APP_API_URL +
											answer.img
										}
										className="img-fluid"
										alt="..."
									/>
								</div>
							</div>
						)}
                        {
                            parseInt(answer.id_rol) !== 1 && (
                                <p className="txt-italic txt-semi-dark">
                                    - Soporte: {answer.remitente}
                                </p>
                            )
                        }
					</VerticalTimelineElement>
				</VerticalTimeline>
			))}
		</>
	);
};

export default TimeLineAnswers;
