


import dayjs from "dayjs";
import { getAuthAxios } from "../../../axios/axiosCustom";


export const getSelectCampaigns = async () => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get('/ads/select_tipo_campaign');
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}


export const getGoogleAdsCampaigns = async () => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get('/ads/google_ads/campaigns');
        if(response.data.status){
            return response.data.data;
        }
        throw new Error(response.data.message);
    }catch(error){
        console.log(error)
    }
}

export const getGoogleAdsCampaigndetails = async (id) => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/ads/google_ads/one_campaign/${id}`);
        if(response.data.status){
            return response.data.data;
        }
        throw new Error(response.data.message);
    }catch(error){
        console.log(error)
    }
}

export const createGoogleAdsCampaign = async (data) => {
    try{
        const data_ = {
            ...data,
            fecha_inicio: dayjs(data.fecha_inicio).format('YYYY-MM-DD'),
            fecha_fin: dayjs(data.fecha_fin).format('YYYY-MM-DD'),
        }
        
        const axios = getAuthAxios();
        const response = await axios.post('/ads/google_ads/crear_campaign', data_);
        if(response.data.status){
            return response.data;
        }
        throw new Error(response.data.message);
    }catch(error){
        console.log(error)
    }
}


export const editGoogleAdsCampaign = async (data) => {
    try{
        const data_ = {
            ...data,
            fecha_inicio: dayjs(data.fecha_inicio).format('YYYY-MM-DD'),
            fecha_fin: dayjs(data.fecha_fin).format('YYYY-MM-DD'),
        }
        const axios = getAuthAxios();
        const response = await axios.post('/ads/google_ads/editar_campaign', data_);
        if(response.data.status){
            return response.data;
        }
        throw new Error(response.data.message);
    }catch(error){
        console.log(error)
    }
}


export const toggleActiveGoogleAdsCampaign = async ({
    id,
    status,
}) => {
    try{
        const axios = getAuthAxios();
        const response = await axios.put(`/ads/google_ads/toggle_active_campaign/${id}/${status}`);
        if(response.data.status){
            return response.data;
        }
        throw new Error(response.data.message);
    }catch(error){
        console.log(error)
    }
}