import React, { useEffect, useState } from "react";
import { getTop10Courses } from "../../controllers/metricas/metricas.controller";
import CarouselCustom from "../Custom/Carousels/CarouselCustom";
import CardTopCourse from "./CardTopCourse";

const TopCourseContainer = () => {
    
    const [topCourses, setTopCourses] = useState([]);
    
    const itemsBySlide = {
		superLargeDesktop: 3,
		desktop: 3,
		tablet: 2,
		mobile: 1,
	};
    
    const getData = async () => {
        const response = await getTop10Courses();
        if (response) {
            setTopCourses(response);
        }
    }
    
    useEffect(() => {
        getData();
    }, []);
    
	return (
		<div className="card">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column flex-sm-row justify-content-between align-items-center">
						<h5 className="ge-title txt-bold">
							Cursos con mejor calificación
						</h5>
					</div>
					<div className="col-12 mt-4">
                    {
                        topCourses.length > 0 ? (
                            <CarouselCustom
                                autoplay={true}
                                infinite={false}
                                autoPlaySpeed={5000}
                                transitionDuration={500}
                                itemClass="px-2 pt-5 px-lg-2 pt-lg-5"
                                itemsBySlide={itemsBySlide}
                                withButtons={true}
                            >
                                {topCourses.map((course, index) => (
                                    <CardTopCourse
                                        key={index}
                                        courseData={course}
                                    />
                                ))}
                            </CarouselCustom>
                        ) : <div className="text-center">No hay información disponible</div>
                    }
					</div>
				</div>
			</div>
		</div>
	);
};


export default TopCourseContainer;
