import { useQuery } from "react-query"
import { getIngresos } from "../../../controllers/contabilidad/ingresos/ingresos.controller";

const KEY_INGRESOS = "ingresos";


export const useQueryIngresos = (fecha_consulta) => {
    return useQuery(
        [KEY_INGRESOS],
        () => getIngresos({
            fecha_consulta
        }),
        {
            refetchOnWindowFocus: false,
            retry: 2,
        }
    )
}