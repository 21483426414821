import { useFormik } from "formik";
import React, { useEffect, useState, Suspense } from "react";
import { Form, Spinner, Tab, Tabs, Alert } from "react-bootstrap";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { useMutateEditClass, useQueryClassById } from "../../hooks/clase.hooks";
import { SwalToast } from "../../utils/toasts";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import TabEditResources from "../../components/Clases/TabEditResources";
import * as FaIcons from "react-icons/fa";
import dayjs from "dayjs";
import ReactDatePicker from "react-datepicker";
import { filterPassedTime } from "../../utils/time";
import CustomInputDate from "../../components/Custom/CustomInputDate";
import { YOUTUBE_REGEX, ZOOM_REGEX } from "../../utils/regex";
const ReactPlayer = React.lazy(() => import('react-player'));

const validationSchema = yup.object({
  titulo: yup.string().min(2).required("El nombre es requerido"),
  urlVideo: yup
    .string()
    .url("Ingrese una URL válida.")
    .when("live", {
      is: 1,
      then: yup
        .string()
        .url("Ingrese una URL válida.")
        .test("url-zoom", "Ingrese una URL válida de zoom", (value) => {
          return value ? ZOOM_REGEX.test(value) : true;
        }),
      otherwise: yup.string().url("Ingrese una URL válida."),
    }),
  duracion: yup.number(),
  claseAbierta: yup.number().required("Seleccione una opción"),
  live: yup.number().required("Live es requerido"),
  fecha_inicio: yup.date().when("live", {
    is: 1,
    then: yup.date().required("La fecha de inicio es requerida"),
    otherwise: yup.date().notRequired().nullable(),
  }),
});


const EditarClaseScreen = () => {
	const [key, setKey] = useState("classDetails");
	const [disabledResources, setDisabledResources] = useState(true);
	const [ minutos, setMinutos ] = useState(0);
    const [ segundos, setSegundos ] = useState(0);
	const [ duration, setDuration ] = useState();
	const { idClase } = useParams();
	const {
		data: dataClass,
		isLoading,
		isSuccess,
	} = useQueryClassById(idClase);

	const { mutate: mutateEditClass, isLoading: loadingEdit } =
		useMutateEditClass(idClase);

		
	const agregarCeros = (number) => {
		if(number >= 10){
			return number
		} else {
			return "0" + number
		}
	};

    const handleDuration = (duration) => {
        const seconds = (Math.round(duration % 0x3C)).toString();
        const minutos  = (Math.floor(duration / 0x3C ) % 0x3C).toString();
		setMinutos(agregarCeros(minutos));
        setSegundos(agregarCeros(seconds));
		setDuration(parseInt(minutos))
    };

	const initialValues = {
		titulo: "",
		urlVideo: "",
		duracion: 0.0,
		claseAbierta: 1,
		fecha_inicio: null,
		live: 0,
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		// validateOnMount: true,
		onSubmit: (values) => {
			values.duracion = duration
			// console.log(values.duracion)
			mutateEditClass(
				{
					idClass: idClase,
					classData: values,
				},
				{
					onSuccess: (data) => {
						//	console.log(data);
						SwalToast.fire({
							icon: "success",
							title: "Curso editado correctamente",
						});
					},
					onError: (error) => {
						//	console.log(error);
						SwalToast.fire({
							icon: "error",
							title: "Error al editar el curso",
						});
					},
				}
			);
		},
	});
    
	useEffect(() => {
		if (isSuccess) {
			const dataFormik = {
				titulo: dataClass.titulo || "",
				urlVideo: dataClass.video || "",
				duracion: dataClass.duracion || 0.0,
				claseAbierta: parseInt(dataClass.clase_abierta),
				fecha_inicio: dataClass.fecha_inicio
					? dayjs(dataClass.fecha_inicio).$d
					: null,
				live: parseInt(dataClass.live) || 0,
			};
			if (dataClass.id) {
				setDisabledResources(false);
			}
			formik.setValues(dataFormik);
		}
	}, [dataClass, isSuccess]);
    
	return (
		<Suspense fallback={null}>
		<LayoutAdmin contentTitle="" routeTitle="Editar Clase">
			{isLoading ? (
				<div className="text-center">
					{" "}
					<Spinner animation="border" />
				</div>
			) : dataClass ? (
				<div className="row">
					<div className="col-12">
						{/* Tabs */}
						<Tabs
							id="classTabs"
							activeKey={key}
							onSelect={(k) => setKey(k)}
							className=""
						>
							<Tab eventKey="classDetails" title="Clase">
								{/* Componente de aspectos generales */}
								<div className="card">
									<div className="card-header ge-noborder">
										<span className="ge-h3 ge-title">
											Aspectos generales
										</span>
									</div>
									<div className="card-body">
										<Form
											className="row"
											onSubmit={formik.handleSubmit}
										>
											<Form.Group className="col-12 my-2">
												<Form.Label>
													Nombre de la clase: *
												</Form.Label>
												<Form.Control
													type="text"
													placeholder="Nombre"
													name="titulo"
													onChange={
														formik.handleChange
													}
													value={formik.values.titulo}
													autoComplete="off"
													isValid={
														formik.touched.titulo &&
														!formik.errors.titulo
													}
													isInvalid={
														formik.touched.titulo &&
														!!formik.errors.titulo
													}
												/>
												<Form.Control.Feedback type="invalid">
													{formik.errors.titulo}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group className="col-12 my-2">
												<Form.Label>
													{
                                                        formik.values.live === 1
                                                            ? "URL del zoom:"
                                                            : "URL del video:"
                                                    }
												</Form.Label>
												<Form.Control
													type="text"
													placeholder={
														parseInt(
															formik.values.live
														) === 1
															? "URL del zoom"
															: "URL de youtube"
													}
													name="urlVideo"
													onChange={
														formik.handleChange
													}
													value={
														formik.values.urlVideo
													}
													autoComplete="off"
													isValid={
														formik.touched
															.urlVideo &&
														!formik.errors.urlVideo
													}
													isInvalid={
														formik.touched
															.urlVideo &&
														!!formik.errors.urlVideo
													}
												/>
												<Form.Control.Feedback type="invalid">
													{formik.errors.urlVideo}
												</Form.Control.Feedback>
											</Form.Group>
											{parseInt(formik.values.live) ===
												0 && (<div className="col-12 my-2 d-inline-block">
														<span className="font-weight-bold">Duración del video:{" "}</span>
														<span className="txt-ge-muted">
															({minutos + ":" + segundos})
														</span>
														{
															formik.values.urlVideo && (
																<div className="mt-2">
																	<ReactPlayer 
																		url={formik.values.urlVideo} 
																		width="240px" 
																		height="140px" 
																		onDuration={handleDuration}
																	/>
																</div>
															)	
														}
													</div>
											)}
											{parseInt(formik.values.live) ===
												1 && (
												<Form.Group className="col-12 my-2">
													<Form.Label>
														Fecha de inicio:
													</Form.Label>
													<ReactDatePicker
														locale={"es"}
														selected={
															formik.values
																.fecha_inicio
														}
														onChange={(date) => {
															formik.setFieldValue(
																"fecha_inicio",
																date
															);
														}}
														isClearable={true}
														dateFormat="dd/MM/yyyy h:mm aa"
														showTimeInput
														timeInputLabel="Hora"
														filterTime={
															filterPassedTime
														}
														minDate={new Date()}
														customInput={
															<CustomInputDate />
														}
														name="fecha_inicio"
													/>
													{formik.touched
														.fecha_inicio &&
														!!formik.errors
															.fecha_inicio && (
															<div className="invalid-feedback">
																{
																	formik
																		.errors
																		.fecha_inicio
																}
															</div>
														)}
												</Form.Group>
											)}
											<Form.Group className="col-12 col-md-6 my-2">
												<Form.Label className="mr-3">
													¿Es clase abierta?:
												</Form.Label>
												<Form.Check
													type="radio"
													label="Si"
													name="claseAbierta"
													id="claseAbiertaSi"
													className="ml-3"
													value={1}
													onChange={() =>
														formik.setFieldValue(
															"claseAbierta",
															1
														)
													}
													checked={
														formik.values.claseAbierta === 1
													}
												/>
												<Form.Check
													type="radio"
													label="No"
													name="claseAbierta"
													id="claseAbiertaNo"
													className="ml-3"
													value={0}
													onChange={() =>
														formik.setFieldValue(
															"claseAbierta",
															0
														)
													}
													checked={
														formik.values.claseAbierta === 0
													}
												/>
											</Form.Group>
											<div className="d-flex w-100 justify-content-around">
												<button
													type="submit"
													className="btn ge-btn-primary my-2"
													disabled={loadingEdit}
												>
													{loadingEdit ? (
														<Spinner
															as="span"
															animation="border"
															size="sm"
															role="status"
															aria-hidden="true"
														/>
													) : (
														"Guardar cambios"
													)}
												</button>
											</div>
										</Form>
									</div>
								</div>
							</Tab>
							<Tab
								eventKey="resources"
								title="Recursos"
								disabled={disabledResources}
							>
								{/* Editar recursos */}
								{!disabledResources && <TabEditResources />}
							</Tab>
						</Tabs>
					</div>
				</div>
			) : (
				<Alert key={"info"} variant={"info"}>
					<FaIcons.FaExclamationTriangle /> No existe informacion
					relacionada....
				</Alert>
			)}
		</LayoutAdmin>
		</Suspense>
	);
};

export default EditarClaseScreen;