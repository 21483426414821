
import React, { useEffect,useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import { Spinner } from "react-bootstrap";
import FormularioHistoriaContenido from "./FormularioHistoriaContenido";
import { getHistoriasByUid } from "../../controllers/historias/historias";
import FormularioHistoriaVideo from "./FormularioHistoriaVideo";
const EditHistoria = () => {
    const {uId} = useParams()

    const{data, error , isLoading } = useQuery(["getHistoria",uId], ()=> getHistoriasByUid(uId),
        {
            refetchOnWindowFocus:false,
            retry:0
        }
    )

	
    const [datosPost,setDatosPost]=useState(null)

   useEffect(()=>{
        if(!isLoading)
        {   
            //console.log(data[0])
            setDatosPost(data[0]) 
        }
    },[isLoading])

    return (
        <LayoutAdmin
        contentTitle="Editar historia"
        routeTitle="Historia"
        >
        { isLoading ? <div className="text-center"> <Spinner animation="border" /></div> : datosPost ? parseInt(datosPost?.tipo_historia)===1 ? <FormularioHistoriaContenido datosContenido={data[0]} /> : <FormularioHistoriaVideo datosContenido={data[0]} /> : <div className="text-center"> <Spinner animation="border" /></div>}
        </LayoutAdmin>
    );
};

export default EditHistoria;