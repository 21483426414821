import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import * as yup from "yup";
import { useMutateUpdateGlosary } from "../../../hooks/glosario.hooks";
import { compareObjects } from "../../../utils/helpers";
import { SwalToast } from "../../../utils/toasts";

const ModalEditGlosary = ({ showModal, setShowModal, dataGlosary = null, idCourse, setDataToEdit }) => {
    
    const {mutate} = useMutateUpdateGlosary(idCourse);
    const [btnDisabled, setBtnDisabled] = useState(true);
    
	const initialValues = useMemo(() => {
        return {
            id_glosario: dataGlosary?.id_glosario || '',
            titulo: dataGlosary?.titulo || '',
            descripcion: dataGlosary?.descripcion || '',
        }
    }, [dataGlosary]);
    
    useEffect(() => {
        if(dataGlosary) {
            formik.setValues(initialValues);
        }
    }, [initialValues]);
    
	const validationSchema = yup.object({
		titulo: yup
			.string()
			.min(1, "Escribe un término")
			.required("El término es requerido"),
		descripcion: yup
			.string()
			.min(1, "Escribe una descripción")
			.required("La descripción es requerida"),
	});
    
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			mutate(values, {
				onSuccess: () => {
					SwalToast.fire({
						icon: "success",
						title: "Término actualizado con éxito",
					});
				},
				onError: () => {
					SwalToast.fire({
						icon: "error",
						title: "Error al actualizar el término.",
					});
				},
			});
			formik.resetForm();
            setDataToEdit(null);
			setShowModal(false);
		},
	});
    
	const onClose = () => {
		formik.resetForm();
        setDataToEdit(null);
		setShowModal(false);
	};
    
    //Disabled or enabled Update button when some field is changed
	useEffect(() => {
		if (compareObjects(initialValues, formik.values)) {
			setBtnDisabled(true);
		} else {
			setBtnDisabled(false);
		}
	}, [initialValues, formik.values]);
    
	return (
		<Modal
			show={showModal}
			onHide={() => onClose()}
			centered
			backdrop="static"
		>
			<Modal.Header className="ge-bg-primary txt-white">
				<Modal.Title className="txt-bold">Actualizar término</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<Form className="w-100" onSubmit={formik.handleSubmit}>
						<Form.Group className="col-12 my-2">
							<Form.Label>Titulo</Form.Label>
							<Form.Control
								type="text"
								placeholder="titulo"
								name="titulo"
								value={formik.values.titulo}
								onChange={formik.handleChange}
								isInvalid={
									formik.errors.titulo && formik.touched.titulo
								}
								isValid={
									!formik.errors.titulo && formik.touched.titulo
								}
								className="ge-form"
							/>
							<Form.Control.Feedback type="invalid">
								{formik.errors.titulo}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="col-12 my-2">
							<Form.Label>Descripción</Form.Label>
							<Form.Control
								as="textarea"
								name="descripcion"
								value={formik.values.descripcion}
								onChange={formik.handleChange}
								rows="3"
								placeholder="Descripción"
								isInvalid={
									formik.errors.descripcion &&
									formik.touched.descripcion
								}
								isValid={
									!formik.errors.descripcion &&
									formik.touched.descripcion
								}
								className="ge-form"
							/>
							<Form.Control.Feedback type="invalid">
								{formik.errors.descripcion}
							</Form.Control.Feedback>
						</Form.Group>
						<div className="d-flex flex-column flex-md-row justify-content-md-between">
							<button
								className="btn ge-btn-deg-primary mt-3"
								type="submit"
                                disabled={btnDisabled}
							>
								Editar término
							</button>
							<button
								type="button"
								className="btn mt-3 btn-secondary"
								onClick={() => onClose()}
							>
								Cancelar
							</button>
						</div>
					</Form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalEditGlosary;
