import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useMutateCreatePlanBenefits } from '../../../hooks/planes.beneficios';

const CrearBeneficio = ({ idPlan, handleShow }) => {

    const {mutate: mutateCreateBenefits } = useMutateCreatePlanBenefits()

    const initialValues = {
        plan: idPlan,
        beneficio: "",
    }

    const validationSchema = Yup.object({
        beneficio: Yup.string().required('El campo es requerido'),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            mutateCreateBenefits(values)
            handleShow(false)
            formik.resetForm()
        },
    })

  return (
    <Form
        onSubmit={formik.handleSubmit}
    >
        <Form.Group>
            <Form.Label>
                Beneficio
            </Form.Label>
            <Form.Control
                name='beneficio'
                onChange={formik.handleChange}
                placeholder="Escribe el beneficio"
                value={formik.values.beneficio}
            />
            <button type='submit' className="btn ge-btn-primary btn-block mt-3">
                Crear Beneficio
            </button>
        </Form.Group>
    </Form>
  )
}

export default CrearBeneficio;