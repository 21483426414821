import { useMutation, useQuery, useQueryClient } from "react-query";
import { getSchoolSelect } from "../controllers/select.controller";
import { deleteEscuelaByid } from "../controllers/escuelas/escuelas";

const SELECT_ESCUELAS = 'SELECT_ESCUELAS';
const KEY_ESCUELAS = 'getEscuelas';

export const useQuerySelectEscuelas = () => {
    return useQuery(
        SELECT_ESCUELAS,
        () => getSchoolSelect(),
        {
            retry: 2,
            refetchOnWindowFocus: false,
        }
    )
}

export const useMutateDeleteEscuelaById = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteEscuelaByid, {
        onSuccess: () => {
            queryClient.invalidateQueries([KEY_ESCUELAS]);
        }
    });
}