import React, { useState, useEffect } from "react";

import { Form } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import { saveDataExam } from "../../controllers/cursos/examen/examen.controller.js";
import AgregarPreguntas from "./AgregarPreguntas.js";


const DatosExamen = ({ id_curso, examen = null }) => {
	
	const queryClient = useQueryClient();
	
	const { mutate: guardarExamen } = useMutation(saveDataExam, {
		onSuccess: () => {
			queryClient.refetchQueries(["Examen"]);
		},
	});
	
	const initialSatate = () => ({
		id_examen: "",
		titulo: "",
		descripcion: "",
		duracion: 0,
		preguntas_min_aprobar: 0,
		id_curso: id_curso,
		estado: "1",
	});
	
	const [infoExamen, setinfoExamen] = useState(initialSatate());
	const { 
		id_examen, 
		titulo, 
		descripcion, 
		duracion, 
		preguntas_min_aprobar 
	} = infoExamen;
	
	const handleChange = (e) => {
		setinfoExamen({
			...infoExamen,
			[e.target.name]: e.target.value,
		});
	};
	
	const onSubmit = (e) => {
		e.preventDefault();
		//console.log(infoExamen)
		guardarExamen(infoExamen);
	};
	
	useEffect(() => {
		if (examen) {
			setinfoExamen(examen);
		}
	}, [examen]);
	
	return (
		<div className="row">
			<div className="col-md-5 nuevo-examen">
				<div className="my-2">
					<h4>Datos del Exámen</h4>
				</div>
				<Form onSubmit={onSubmit}>
					<Form.Group className="my-2">
						<Form.Control
							type="hidden"
							name="id_examen"
							value={id_examen}
						/>
						<Form.Label>Titulo</Form.Label>
						<Form.Control
							required
							type="text"
							placeholder="Título exámen"
							name="titulo"
							onChange={handleChange}
							value={titulo}
						/>
					</Form.Group>
					<Form.Group className="my-2">
						<Form.Label>Descripción:</Form.Label>
						<Form.Control
							as="textarea"
							rows="3"
							name="descripcion"
							value={descripcion}
							onChange={handleChange}
							placeholder="Información adicional"
						/>
					</Form.Group>
					<Form.Group className="my-2">
						<Form.Label>Duración (mins):</Form.Label>
						<Form.Control
							required
							type="number"
							min="1"
							max="60"
							placeholder="Duración en minutos"
							name="duracion"
							onChange={handleChange}
							value={duracion}
						/>
					</Form.Group>
					<Form.Group className="my-2">
						<Form.Label>Aprobación (%):</Form.Label>
						<Form.Control
							required
							type="number"
							min="1"
							max="100"
							placeholder="Porcentaje de aprobación 1 a 100"
							name="preguntas_min_aprobar"
							onChange={handleChange}
							value={preguntas_min_aprobar}
						/>
					</Form.Group>

					<button
						className="btn ge-btn-primary my-4"
						type="submit"
						variant="info"
					>
						Guardar
					</button>
				</Form>
			</div>
			{infoExamen.id_examen !== "" ? (
				<AgregarPreguntas
					id_curso={id_curso}
					id_examen={infoExamen.id_examen}
				/>
			) : null}
		</div>
	);
};

export default DatosExamen;
