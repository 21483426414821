import { getAuthAxios } from "../../../axios/axiosCustom";
import dayjs from "dayjs";
import Swal from "sweetalert2";

export const createClass = async ({classData, idModule}) => {
    const axios = getAuthAxios();
    const data = {
        modulo: idModule,
        ...classData
    }
    
    /* Si la clase no es en vivo, la fecha de inicio debe ser null */
    const dataToApi = {
        ...data,
        fecha_inicio: data.live === 1 ? dayjs(data.fecha_inicio).format("YYYY-MM-DD HH:mm:ss") : null,
    }
    
    try{
        const response = await axios.post("/clases/create_clase", dataToApi);
        if(response.data.status){
            return response.data;
        }
        throw new Error('Error al crear la clase');
    }catch(e){
        throw new Error('Error del servidor');
    }
}


export const getClassById = async (idClass) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/clases/clase/${idClass}`);
        if(response.data.status){
            return response.data.data;
        }
        throw new Error('Error al obtener la clase');
    }catch(e){
        throw new Error('Error del servidor');
    }
}


export const editClass = async ({idClass, classData}) => {
    const axios = getAuthAxios();
    const data = {
        ...classData
    }
    // Eliminar el campo live
    delete data.live;
    
    /* Si la clase no es en vivo, la fecha de inicio debe ser null */
    const dataToApi = {
        ...data,
        fecha_inicio: parseInt(classData.live) === 1 ? dayjs(classData.fecha_inicio).format("YYYY-MM-DD HH:mm:ss") : null,
    }
    
    try{
        const response = await axios.post(`/clases/edit_clase/${idClass}`, dataToApi);
        if(response.data.status){
            return response.data;
        }
        throw new Error('Error al editar la clase');
    }catch(e){
        throw new Error('Error del servidor');
    }
}

export const toggleActiveClass = async ({idClass, active}) => {
    const axios = getAuthAxios();
    const data = {
        habilitado: active
    }
    try{
        const response = await axios.put(`/clases/estado_clase/${idClass}`, data);
        if(response.data.status){
            return response.data;
        }
        throw new Error('Error al editar la clase');
    }catch(e){
        throw new Error('Error del servidor');
    }
}

export const deleteClassById = async (id_clase) => {
    const axios = getAuthAxios();
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
  
    await  Swal.fire({
        title: '¿Estás seguro?',
        text: "Una vez eliminado, no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
            const response = await axios.delete(`/clases/delete_clase/${id_clase}`);
              
            if(response.data.status){
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  })
            }
            else{
                Toast.fire({
                    icon: 'warning',
                    title: response.data.message
                  })
            }
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      })
  }

/* Resources */

export const addResources = async ({idClass, resources}) => {
    const axios = getAuthAxios();
    const formData = new FormData();
    resources.forEach(element => { 
        formData.append('recursos[]', element); 
    });
    // formData.append('recursos[]', resources);
    try{
        const response = await axios.post(`/clases/create_archivo/${idClass}`, formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if(response.data.status){
            return response.data;
        }
        throw new Error('Error al agregar recursos');
    }catch(e){
        throw new Error('Error del servidor');
    }
}

export const getResourcesByClass = async (idClass) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/clases/all_archivos/${idClass}`);
        if(response.data.status){
            return response.data.data;
        }
        throw new Error('Error al obtener los recursos');
    }catch(e){
        throw new Error('Error del servidor');
    }
}


export const deleteResourceById = async (idResource) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.delete(`/clases/delete_archivo/${idResource}`);
        if(response.data.status){
            return response.data;
        }
        throw new Error('Error al eliminar el recurso');
    }catch(e){
        throw new Error('Error del servidor');
    }
}

/* Resource URL */

export const addResourcesUrl = async ({idClass, resourceUrl}) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.post(`/clases/create_recurso_url/${idClass}`, resourceUrl,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if(response.data.status){
            return response.data;
        }
        throw new Error('Error al agregar recursos');
    }catch(e){
        throw new Error('Error del servidor');
    }
}