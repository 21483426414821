import React from "react";
import TimeLineAnswers from "./TimeLineAnswers";
import TimeLineTicket from "./TimeLineTicket";

const TimeLineContainer = ({ ticketInfo = null }) => {
    
	if (!ticketInfo) {
		return null;
	}
    
	return (
		<>
			<TimeLineTicket reporteInfo={ticketInfo.reporte} />
            <TimeLineAnswers asnwers={ticketInfo.respuestas} />
		</>
	);
};

export default TimeLineContainer;
