import React, { useEffect, useMemo, useState } from "react";
import HeaderSearchTable from "../../components/Custom/HeaderSearchTable";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import LoaderSmall from "../../components/Loaders/LoaderSmall";
import DataTable from "react-data-table-component";
import { getSolicitudesFactura } from "../../controllers/facturas/facturas.controller";
import { Badge, Button } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import { useMutateDeleteFacturaById } from "../../hooks/factura.hooks";
import SubirFacturasModal from "../../components/Facturas/SubirFacturasModal";

const ListarFacturasScreen = () => {
  const [filterText, setFilterText] = useState("");
  const [resetPagination, setResetPagination] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [solicitudesList, setSolicitudesList] = useState([]);
  const [facturaEliminada, setFacturaEliminada] = useState(false);
  const { mutate: mutateDeleteFactura } = useMutateDeleteFacturaById();

  const handleDeleteFile = async (id_doc) => {
    await mutateDeleteFactura(id_doc);
    setFacturaEliminada(true);
  };

  const fetchData = async () => {
    setIsLoading(true);
    const response = await getSolicitudesFactura();
    console.log(response);
    setSolicitudesList(response);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let timeout;
    if (facturaEliminada) {
      timeout = setTimeout(() => {
        setFacturaEliminada(false);
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [facturaEliminada]);

  /* Search Component */
  const searchComponentMemo = useMemo(() => {
    return (
      <HeaderSearchTable
        filterText={filterText}
        setFilterText={setFilterText}
        arrayData={solicitudesList}
        setArrayFiltered={setFilteredItems}
        setResetPagination={setResetPagination}
        placeholder="Buscar por nombre, apellido o correo"
      />
    );
  }, [filterText, resetPagination]);

  /* Config Table */
  const columns = useMemo(
    () => [
      {
        name: "ID",
        cell: (row, index) => row.id_solicitud,
        width: "5%",
      },
      {
        name: "Nombres y apellidos",
        cell: (row, index) => (
          <span>
            {row.nombres} {row.apellidos}
          </span>
        ),
        width: "10%",
      },
      {
        name: "DNI o RUC",
        cell: (row, index) => row.dni_ruc,
        width: "15%",
      },
      {
        name: "Razón Social",
        cell: (row, index) => row.razon_social,
        width: "8%",
      },
      {
        name: "Correo",
        cell: (row, index) => row.correo,
        width: "200px",
      },
      {
        name: "Región",
        cell: (row, index) => row.region,
      },
      {
        name: "Provincia",
        cell: (row, index) => row.provincia,
      },
      {
        name: "Distrito",
        cell: (row, index) => row.distrito,
      },
      {
        name: "Dirección",
        cell: (row, index) => row.direccion,
        width: "200px",
      },
      {
        name: "Estado",
        width: "8%",
        cell: (row) =>
          row.id_doc === null || row.id_doc === "" ? (
            <Badge className="bg-danger">Sin factura</Badge>
          ) : (
            <Badge className="ge-deg-primary p-1">Con factura</Badge>
          ),
      },     
      {
        name: "Acciones",
        width: "12%",
        cell: (row) => (
          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="btn ge-btn-deg-primary btn-sm"
              onClick={() => setShowModal(true)}
            >
              <FaIcons.FaPlus />
            </Button>
            {/* Boton para eliminar */}
            <button
              className="btn btn-danger btn-sm ml-2"
              onClick={() => handleDeleteFile(row.id_doc)}
            >
              <FaIcons.FaTrashAlt />
            </button>
          </div>
        ),
      },
    ],
    []
  );

  /** CODIGO AGREGADO */
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };
  /** FIN CODIGO */

  return (
    <LayoutAdmin>
      <SubirFacturasModal showModal={showModal} setShowModal={setShowModal} />
      <div className="row">
        <div className="col-12">
          <div className="card p-2">
            <div className="card-header ge-noborder">
              <>
                <img
                  src="/images/file-icon.png"
                  className="mr-2"
                  style={{ width: "40px" }}
                  alt="file-icon"
                />
                <span className="ge-h3 ge-title">Lista de solicitudes</span>
              </>
            </div>
            <div className="card-body">
              <DataTable
                className="custom-datatable"
                columns={columns}
                data={
                  filteredItems.length > 0 ? filteredItems : solicitudesList
                }
                subHeader
                subHeaderComponent={searchComponentMemo}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                responsive
                progressPending={isLoading}
                progressComponent={<LoaderSmall />}
                noDataComponent={
                  <div className="text-center mt-4">No hay solicitudes</div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default ListarFacturasScreen;
