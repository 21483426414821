import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import { useQuery } from "react-query";
import { getWeekCommentsClasses } from "../../../controllers/docentes/docentes";
import LoaderSmall from "../../Loaders/LoaderSmall";
import * as FaIcons from "react-icons/fa";

const CardCommentsClass = ({idDocente = null}) => {
    
    const {data, isLoading, isError} = useQuery(
        "commentsClass",
        () => getWeekCommentsClasses(idDocente),
        {
            refetchOnWindowFocus: false,
            retry: 2,
        }
    );
    
    /* Config Table */
	const columns = useMemo(
		() => [
			{
				name: '#',
				cell: (row, index) => index+1,
				width: '5%',
			},
			{
				name: "Estudiante",
                cell: (row) => (
                    <>
                        {row.nombres} {row.apellidos}
                    </>
                )
			},
			{
				name: "Comentario",
				cell: (row) => (
                    <span
                        title={row.comentario}
                    >
                        {row.comentario}
                    </span>
                ),
                width: '300px',
			},
            {
                name: "Likes",
                cell: (row) => (
                    <>
                        <FaIcons.FaThumbsUp className="mr-2" /> {row.likes}
                    </>
                ),
            },
            {
                name: "Acciones",
                cell: (row) => (
                    /* Boton para responder el comentario */
                    <div className="d-flex justify-content-center align-items-center">
                        <button className="btn ge-btn-primary btn-xs my-2">
                            <FaIcons.FaComments className="mr-2" />
                            Responder
                        </button>
                    </div>
                ),
            }
		],
		[]
	);
    
    if(!idDocente) return null;
	return (
		<div className="row">
			<div className="col-12">
				<div className="card p-2">
					<div className="card-header ge-noborder">
						<>
							<img
								src="/images/file-icon.png"
								className="mr-2"
								style={{ width: "40px" }}
								alt="file-icon"
							/>
							<span className="ge-h3 ge-title">
								Últimos comentarios de clases
							</span>
						</>
					</div>
					<div className="card-body">
						<DataTable
                            data={data}
							className="custom-datatable"
							columns={columns}
							pagination
							responsive
							fixedHeader
							fixedHeaderScrollHeight="300px"
							progressPending={isLoading}
							progressComponent={<LoaderSmall />}
                            noDataComponent={<div className="text-center">No hay comentarios</div>}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardCommentsClass;
