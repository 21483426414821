import React, { forwardRef } from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const BarChartDataset = forwardRef(
	({ datos, showTitle = true, showLegend = true, showGrid = true }, ref) => {
		//console.log(datos.dataSets)
		const state = {
			labels: datos.labels,
			datasets: datos.dataSets,
		};
		
		return (
			<Bar
				ref={ref}
				datasetIdKey={`id-${datos.title}`}
				data={state}
				options={{
					responsive: true,
					plugins: {
						title: {
							display: showTitle,
							text: datos.title,
							fontSize: 20,
						},
						legend: {
							display: showLegend,
							position: "top",
						},
					},
					scales: {
						y: {
							// display: false,
							beginAtZero: true,
							grid: {
								display: showGrid,
							},
							ticks:{
								stepSize: 1,
							}
						},
						x: {
							beginAtZero: true,
							grid: {
								display: showGrid,
							},
						},
					},
				}}
			/>
		);
	}
);

export default BarChartDataset;
