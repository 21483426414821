import React, { useRef } from "react";
import { parseMinToHM } from "../../../utils/parsers";
import CardGestora from "../../Cards/CardGestora";

const MyProgressCard = ({
	avgStudy = 0,//number in minutes
	examResults = {
		approved: 0,
		reprobate: 0
	},
	endedCourses = 0,
}) => {
	
	// const { approved, reprobate } = examResults;
	const avgRef = useRef(null);
	const resultRef = useRef(null);
	const endedRef = useRef(null);
	
	const avgStudyParsed = parseMinToHM(avgStudy);
	const examResultParsed = `${examResults?.reprobate} fallidos y ${examResults?.approved} aprobados`;
	
	
	//setear los ref
	avgRef.current = avgStudyParsed;
	resultRef.current = examResultParsed;
	endedRef.current = endedCourses;
	
	return (
		<CardGestora title={"Progreso"}>
			<div className="row">
				<div className="form-group col-12">
					<label className="ge-title">Promedio de estudio</label>
					<input
						type="text"
						ref={avgRef}
						className="form-control ge-input"
						// defaultValue={avgStudyParsed}
						value={avgRef.current}
						onChange={(e) => {
							avgRef.current = e.target.value;
						}}
						readOnly
					/>
				</div>
				<div className="form-group col-12 col-md-4 mb-0">
					<label 
						className="ge-title"
						style={{
							fontSize: "0.7rem"
						}}
					>
						Cursos terminados
					</label>
					<input
						type="text"
						ref={endedRef}
						className="form-control ge-input"
						// defaultValue={endedCourses}
						value={endedRef.current}
						onChange={(e) => {
							endedRef.current = e.target.value;
						}}
						readOnly
					/>
				</div>
				<div className="form-group col-12 col-md-8 mb-0">
					<label className="ge-title">Intento de exámenes</label>
					<input
						type="text"
						ref={resultRef}
						className="form-control ge-input"
						// defaultValue={`${examResults?.reprobate || 0} fallidos y ${examResults?.approved || 0} aprobados`}
						value={
							resultRef.current
						}
						onChange={(e) => {
							resultRef.current = e.target.value;
						}}
						readOnly
					/>
				</div>
			</div>
		</CardGestora>
	);
};

export default MyProgressCard;
