import React, { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import Select from "react-select";
import EstudiantesPorCursos from "../../components/Estudiantes/EstudiantesPorCursos";
import EstudiantesPorEscuela from "../../components/Estudiantes/EstudiantesPorEscuela";
import EstudiantesPorRutas from "../../components/Estudiantes/EstudiantesPorRutas";
import ModalEstudianteInfo from "../../components/Estudiantes/ModalEstudianteInfo";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import { dynamicSelect } from "../../controllers/select.controller";
import { useSelector } from "react-redux";

const ListarEstudiantesScreen = () => {
	
	const [showModal, setShowModal] = useState(false);
	const [idStudent, setIdStudent] = useState(null);
	const { user } = useSelector(( state ) => state.user)
	const [key, setKey] = useState("curso");
	const [selectOption, setSelectOption] = useState({
		panelTitle: "",
		value: "",
		label: "",
	});
	
	const [optionValue, setOptionValue] = useState({
		value: "",
		label: "--- Seleccione una opción ---",
	});
	
	const [selectData, setSelectData] = useState([]);
	
	const changeSelectData = async (typeSelect) => {
		const data = await dynamicSelect(typeSelect,user.roles.includes('3') ? user.id_user:null);
		if (data) {
			let dataToOptions = data.map((item) => ({
				value: item.id,
				label: item.nombre,
			}));
			
			dataToOptions.unshift({
				value: "",
				label: "--- Seleccione una opción ---",
			});
			setSelectData(dataToOptions);
		}
	};
	
	
	const handleViewStudent = (idStudent) => {
		setIdStudent(idStudent);
		setShowModal(true);
	}
	
	const handleCloseModal = () => {
		setIdStudent(null);
		setShowModal(false);
	}
	
	useEffect(() => {
		changeSelectData(key);
		setSelectOption({
			panelTitle: key,
			value: "",
			label: "",
		});
		setOptionValue({
			value: "",
			label: "--- Seleccione una opción ---",
		});
	}, [key]);
	
	return (
		<LayoutAdmin contentTitle="" routeTitle="Lista de estudiantes">
			<ModalEstudianteInfo
				show={showModal}
				setShow={setShowModal}
				handleClose={handleCloseModal}
				idStudent={idStudent}
			/>
			<div className="card">
				<div className="card-header ge-noborder">
					<>
						<img
							src="/images/file-icon.png"
							className="mr-2"
							style={{ width: "40px" }}
							alt="file-icon"
						/>
						<span className="ge-h3 ge-title">
							Lista de estudiantes
						</span>
					</>
				</div>
				<div className="card-body">
					<div className="row">
						{/* Tabs */}
						<Tab.Container
							id="tabs-students"
							defaultActiveKey="curso"
						>
							<div className="col-12 col-md-6">
								<Nav variant="pills" className="d-flex">
									<Nav.Item>
										<Nav.Link className="btn-curso-estudiante"
											onClick={() => {
												setKey("curso");
											}}
											eventKey="curso"
										>
											Cursos
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link className="btn-curso-estudiante"
											onClick={() => {
												setKey("ruta");
											}}
											eventKey="ruta"
										>
											Rutas
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link className="btn-curso-estudiante"
											onClick={() => {
												setKey("escuela");
											}}
											eventKey="escuela"
										>
											Escuelas
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</div>
							<div className="col-12 col-md-6">
								<Select
									placeholder="Seleccione una opción"
									// isClearable={true}
									// isLoading={false}
									isSearchable={true}
									options={selectData}
									onChange={(e) => {
										console.log(e);
										setOptionValue({
											value: e.value,
											label: e.label,
										});
										setSelectOption({
											...selectOption,
											value: e.value,
											label: e.label,
										});
									}}
									value={optionValue}
								/>
							</div>
							<div className="col-12 mt-4">
								<Tab.Content>
									<Tab.Pane eventKey="curso">
										<EstudiantesPorCursos
											selectOption={selectOption}
											handleViewStudent={handleViewStudent}
										/>
									</Tab.Pane>
									<Tab.Pane eventKey="ruta">
										<EstudiantesPorRutas
											selectOption={selectOption}
											handleViewStudent={handleViewStudent}
										/>
									</Tab.Pane>
									<Tab.Pane eventKey="escuela">
										<EstudiantesPorEscuela
											selectOption={selectOption}
											handleViewStudent={handleViewStudent}
										/>
									</Tab.Pane>
								</Tab.Content>
							</div>
						</Tab.Container>
					</div>
				</div>
			</div>
		</LayoutAdmin>
	);
};

export default ListarEstudiantesScreen;
