import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CreateConvocatoria from "../../components/Convocatorias/CreateConvocatoria";
import EditConvocatoria from "../../components/Convocatorias/EditConvocatoria";
import HeaderSearchTable from "../../components/Custom/HeaderSearchTable";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import LoaderSmall from "../../components/Loaders/LoaderSmall";
import { useMutateDeleteConvocatoria, useMutatePublishConvocatoria, useQueryConvocatorias } from "../../hooks/convocatorias.hooks";
import { FaIcons, MdIcons } from "../../icons/export-reacticons";
import { ToastToConfirm } from "../../utils/toasts";


const ListaConvocatoriasScreen = () => {
	
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
	const [showModalCreate, setShowModalCreate] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [idJob, setIdJob] = useState(null);
	
	//React Query Hooks
	const { data: convocatorias, isLoading } = useQueryConvocatorias();
	const { mutate: togglePublish } = useMutatePublishConvocatoria();
	const { mutate: deleteConvocatoria } = useMutateDeleteConvocatoria();
	
	const handleSendToEdit = (id) => {
		setIdJob(id);
		setShowModalEdit(true);
	};
	
	const handlePublish = (id_registro, publicado) => {
		const params = {
			id_registro,
			publicado
		}
		
		const text = publicado === 1 
			? "¿Deseas publicar esta convocatoria?"
			: "¿Deseas ocultar esta convocatoria?";
			
		ToastToConfirm(togglePublish, params, text);
	}
	
	const handleDelete = (id_registro) => {
		const params = {
			id_registro
		}
		
		const text = "¿Deseas eliminar esta convocatoria?";
		
		ToastToConfirm(deleteConvocatoria, params, text);
	}
	
	
	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={convocatorias}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
				placeholder="Buscar por titulo"
				columnsToFilter={["titulo"]}
			/>
		);
	}, [filterText, resetPagination]);
	
	
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "ID",
				cell: (row) => row.id_registro,
				width: "10%",
			},
			{
				name: "Título",
				width: "200px",
				selector: (row) => row.titulo,
				sortable: true,
			},
			{
				name: "Escuela",
				width: "200px",
				selector: (row) => row.nombre_escuela,
			},
			{
				name: "Enlace",
				width: "200px",
				cell: (row) => (
					<a
						href={row.enlace}
						target="_blank"
						rel="noreferrer"
						className="text-blue-500"
					>
						{row.enlace}
					</a>
				)
			},
			{
				name: "Fecha de inicio",
				cell: (row) => dayjs(row.fecha_inicio).format("DD/MM/YYYY"),
			},
			{
				name: "Fecha de fin",
				cell: (row) => dayjs(row.fecha_fin).format("DD/MM/YYYY"),
			},
			{
				name: "Acciones",
				cell: (row) => (
					<div className="d-flex p-3">
						<button
							className="btn ge-btn-deg-primary btn-sm mx-2"
							onClick={() => handleSendToEdit(row.id_registro)}
						>
							<FaIcons.FaCogs size={20} />
						</button>
						{parseInt(row.publicado) === 1 ? (
							<button
								className="btn btn-danger btn-sm mx-2"
								type="button"
								title="Despublicar"
								onClick={() => handlePublish(row.id_registro, 0)}
							>
								<MdIcons.MdPublicOff size={20} />
							</button>
						) : (
							<button
								className="btn btn-success btn-sm mx-2"
								type="button"
								title="Publicar"
								onClick={() => handlePublish(row.id_registro, 1)}
							>
								<MdIcons.MdPublic size={20} />
							</button>
						)}
						<button
							className="btn btn-danger btn-sm mx-2"
							title="Eliminar"
							onClick={() => handleDelete(row.id_registro)}
						>
							<FaIcons.FaTrashAlt size={20} />
						</button>
					</div>
				),
			},
		],
		[]
	);
	
	
	return (
		<LayoutAdmin contentTitle="" routeTitle="Convocatorias">
			<div className="row">
				<CreateConvocatoria 
					show={showModalCreate}
					setShow={setShowModalCreate}
				/>
				
				<EditConvocatoria
					id={idJob}
					setId={setIdJob}
					show={showModalEdit}
					setShow={setShowModalEdit}
				/>
				<div className="col-12">
					<div className="card p-2">
						<div className="d-flex justify-content-between card-header card-no-after ge-noborder">
							<div>
								<img
									src="/images/file-icon.png"
									className="mr-2"
									style={{ width: "40px" }}
									alt="file-icon"
								/>
								<span className="ge-h3 ge-title">
									Convocatorias laborales
								</span>
							</div>
							<div className="">
								<button
									className="btn ge-btn-deg-primary btn-sm"
									onClick={() => setShowModalCreate(true)}
								>
									<FaIcons.FaPlus />
									<span className="ml-2">Crear convocatoria</span>
								</button>
							</div>
						</div>
						<div className="card-body">
							<DataTable
								className="custom-datatable"
								columns={columns}
								data={
									filteredItems.length > 0
										? filteredItems
										: convocatorias
								}
								subHeader
								subHeaderComponent={searchComponentMemo}
								pagination
								responsive
								progressPending={isLoading}
								progressComponent={<LoaderSmall />}
							/>
						</div>
					</div>
				</div>
			</div>
		</LayoutAdmin>
	);
};

export default ListaConvocatoriasScreen;
