import React, { useEffect, useMemo, useRef, useState } from "react";
import { Form, FormGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import * as FaIcons from "react-icons/fa";
import {
	actualizar_info_portada,
	traer_info_portada,
} from "../../controllers/historias/historias";
import { SwalToast } from "../../utils/toasts";

const initialValues = {
	titulo: "",
	url_video: "",
	img_fondo: null,
	img_portada: null,
};

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

let schemaFile = {
	titulo: yup.string().required("El titulo es requerido"),
	url_video: yup.string().required("La url del video es requerida"),
	img_fondo: yup
		.mixed()
		/* .test("fileSize", "El archivo debe ser menor a 5MB", (value) => {
			return value && value.size < 5000000;
		}) */
		.test("fileFormat", "El formato debe ser una imagen", (value) => {
			return value && SUPPORTED_FORMATS.includes(value.type);
		}),

	img_portada: yup
		.mixed()
		/* .test("fileSize", "El archivo debe ser menor a 5MB", (value) => {
			return value && value.size < 5000000;
		}) */
		.test("fileFormat", "El formato debe ser una imagen", (value) => {
			return value && SUPPORTED_FORMATS.includes(value.type);
		}),
};

let schemaFileString = {
	titulo: yup.string().required("El titulo es requerido"),
	url_video: yup.string().required("La url del video es requerida"),
	img_fondo: yup
		.string()
		.test("imageUrl", "La URL debe ser una imagen", (value) => {
			return value && value.includes("files");
		}),
	img_portada: yup
		.string()
		.test("imageUrl", "La URL debe ser una imagen", (value) => {
			return value && value.includes("files");
		}),
};

const setFieldStringFile = (field, schema, setState) => {
	if (field === null) {
		return yup.object(schema);
	}
	
	const newSchema = {
		...schema,
		[field]: yup
			.string()
			.test("imageUrl", "La URL debe ser una imagen", (value) => {
				return value && value.includes("files");
			}),
	};
	
	setState(newSchema);
	
	return yup.object({
		...newSchema,
	});
};

const setFieldFile = (field, schema, setState) => {
	if (field === null) {
		return yup.object(schema);
	}

	const newSchema = {
		...schema,
		[field]: yup
			.mixed()
			/* .test("fileSize", "El archivo debe ser menor a 5MB", (value) => {
				return value && value.size < 5000000;
			}) */
			.test("fileFormat", "El formato debe ser una imagen", (value) => {
				return value && SUPPORTED_FORMATS.includes(value.type);
			}),
	};

	setState(newSchema);

	return yup.object({
		...newSchema,
	});
};

const EditarPortada = () => {
	const [fetch, setFetch] = useState(true);
	const [fieldSchema, setFieldSchema] = useState(null);
	const [typeSchema, setTypeSchema] = useState("withFile");
	
	const [initialSchema, setInitialSchema] = useState(schemaFileString);
	
	//states
	const [renderImagePortada, setRenderImagePortada] = useState(null);
	const [renderImageFondo, setRenderImageFondo] = useState(null);
	const [dataPortada, setDataPortada] = useState(null);
	
	//refs
	const btnImgFondoRef = useRef(null);
	const btnImgPortadaRef = useRef(null);
	
	//Memo schema
	const validationSchema = useMemo(() => {
		if (typeSchema === "withFile") {
			return setFieldFile(fieldSchema, initialSchema, setInitialSchema);
		} else {
			return setFieldStringFile(
				fieldSchema,
				initialSchema,
				setInitialSchema
			);
		}
	}, [fieldSchema, typeSchema]);
	
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values) => {
			// console.log(values);
			const response = await actualizar_info_portada(
				values,
				dataPortada.id_registro
			);
			if (response) {
				setInitialSchema(schemaFileString);
                setFieldSchema(null);
                setTypeSchema("withString");
				setFetch(true);
				SwalToast.fire({
					title: "Actualizado",
					text: "Se actualizo correctamente",
					icon: "success",
				});
			} else {
				SwalToast.fire({
					title: "Error",
					text: "No se pudo actualizar",
					icon: "error",
				});
			}
		},
	});
	
	const fetchData = async () => {
		const response = await traer_info_portada();
		if (response) {
			setDataPortada(response);
			formik.setValues({
				...response,
			});
		}
	};
	
	const onChangeImage = (e) => {
		if (e.target.files.length > 0) {
			const file = e.target.files[0];
			formik.setFieldValue(e.target.name, file);
			setFieldSchema(e.target.name);
			setTypeSchema("withFile");
		} else {
			setFieldSchema(e.target.name);
			setTypeSchema("withString");
		}
	};
	
	useEffect(() => {
		if (formik.values.img_portada?.name) {
			setRenderImagePortada(
				URL.createObjectURL(formik.values.img_portada)
			);
		} else {
			if (
				formik.values.img_portada &&
				formik.values.img_portada.includes("files")
			) {
				setRenderImagePortada(
					process.env.REACT_APP_API_URL + formik.values.img_portada
				);
			}
		}
	}, [formik.values.img_portada]);
	
	useEffect(() => {
		if (formik.values.img_fondo?.name) {
			setRenderImageFondo(URL.createObjectURL(formik.values.img_fondo));
		} else {
			if (
				formik.values.img_fondo &&
				formik.values.img_fondo.includes("files")
			) {
				setRenderImageFondo(
					process.env.REACT_APP_API_URL + formik.values.img_fondo
				);
			}
		}
	}, [formik.values.img_fondo]);
	
	useEffect(() => {
		if (fetch) {
			fetchData();
			setFetch(false);
		}
	}, [fetch]);
	
	
	useEffect(() => {
		formik.validateForm();
	}, [initialSchema]);

	return (
		<div className="row">
			<div className="col-12">
				<div className="card">
					<div className="card-body">
						<form className="row" onSubmit={formik.handleSubmit}>
							{/* Img fondo */}
							<div className="col-12 my-2 h-100">
								<label>Imagen de fondo:</label>
								<div className="d-flex justify-content-center align-items-center img-form-preview">
									{formik.values.img_fondo ? (
										formik.values.img_fondo.name ||
										formik.values.img_fondo.includes(
											"files"
										) ? (
											<img
												src={renderImageFondo}
												alt={"..."}
												className="w-100"
												style={{
													objectFit: "fill",
													maxHeight: "400px",
													borderRadius: "10px",
												}}
											/>
										) : (
											<img
												src="images/gestora-placeholder.png"
												alt={"..."}
												// className="w-100"
												style={{
													objectFit: "fill",
													maxWidth: "100%",
													borderRadius: "10px",
												}}
											/>
										)
									) : (
										<img
											src="images/gestora-placeholder.png"
											alt={"..."}
											// className="w-100"
											style={{
												objectFit: "fill",
												maxWidth: "100%",
												borderRadius: "10px",
											}}
										/>
									)}
									<div
										title="Seleccionar imagen"
										className="btn-ref-file"
										onClick={() =>
											btnImgFondoRef.current.click()
										}
									>
										<FaIcons.FaFileUpload />
									</div>
								</div>
								<p
									className={
										formik.errors.img_fondo
											? "text-danger"
											: "d-none"
									}
								>
									{" "}
									{formik.errors.img_fondo
										? formik.errors.img_fondo
										: null}{" "}
								</p>
								<div className="d-none form-group mb-3">
									<input
										ref={btnImgFondoRef}
										className="form-control"
										type="file"
										accept="image/*"
										name="img_fondo"
										onChange={onChangeImage}
									/>
								</div>
							</div>
							<div className="col-md-6 my-2 h-100">
								<label>Imagen de portada:</label>
								<div className="d-flex justify-content-center align-items-center img-form-preview">
									{formik.values.img_portada ? (
										formik.values.img_portada.name ||
										formik.values.img_portada.includes(
											"files"
										) ? (
											<img
												src={renderImagePortada}
												alt={"..."}
												className="w-100"
												style={{
													objectFit: "fill",
													maxHeight: "200px",
													borderRadius: "10px",
												}}
											/>
										) : (
											<img
												src="images/gestora-placeholder.png"
												alt={"..."}
												style={{
													objectFit: "fill",
													maxWidth: "100%",
													borderRadius: "10px",
												}}
											/>
										)
									) : (
										<img
											src="images/gestora-placeholder.png"
											alt={"..."}
											style={{
												objectFit: "fill",
												maxWidth: "100%",
												borderRadius: "10px",
											}}
										/>
									)}
									<div
										title="Seleccionar imagen"
										className="btn-ref-file"
										onClick={() =>
											btnImgPortadaRef.current.click()
										}
									>
										<FaIcons.FaFileUpload />
									</div>
								</div>
								<p
									className={
										formik.errors.img_portada
											? "text-danger"
											: "d-none"
									}
								>
									{" "}
									{formik.errors.img_portada
										? formik.errors.img_portada
										: null}{" "}
								</p>
								<div className="d-none form-group mb-3">
									<input
										ref={btnImgPortadaRef}
										className="form-control"
										type="file"
										accept="image/*"
										name="img_portada"
										onChange={onChangeImage}
									/>
								</div>
							</div>
							<div className="col-md-6 my-2">
								<Form.Group className="my-2">
									<Form.Label>Titulo</Form.Label>
									<Form.Control
										type="text"
										placeholder="Titulo"
										name="titulo"
										value={formik.values.titulo}
										onChange={formik.handleChange}
										isValid={
											formik.touched.titulo &&
											!formik.errors.titulo
										}
										isInvalid={
											formik.touched.titulo &&
											formik.errors.titulo
										}
									/>
									<Form.Control.Feedback type="invalid">
										{formik.errors.titulo}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group className="my-2">
									<Form.Label>URL video</Form.Label>
									<Form.Control
										type="text"
										placeholder="URL video"
										name="url_video"
										value={formik.values.url_video}
										onChange={formik.handleChange}
										isValid={
											formik.touched.url_video &&
											!formik.errors.url_video
										}
										isInvalid={
											formik.touched.url_video &&
											formik.errors.url_video
										}
									/>
									<Form.Control.Feedback type="invalid">
										{formik.errors.url_video}
									</Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className="col-12 d-flex justify-content-center justify-content-md-end">
								<button
									type="submit"
									className="btn ge-btn-primary"
								>
									Guardar
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditarPortada;
