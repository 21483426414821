import { getAuthAxios } from "../../axios/axiosCustom"
import { enVivoMap } from "../../mappers/envivo.mapper";
import { getDateTimeObject } from "../../utils/dateTime";
const dayJs = getDateTimeObject();

export const getLives = async () => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get("/lives/all_lives");
        if(response.data.status){
            return response.data.data;
        }
        throw new Error('Error al obtener los lives');
    }catch(error){
        console.log(error);
    }
}

export const getLivesEnVivo = async () => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get("/lives_public/getPublicLive");
        if(response.data.status){
            return enVivoMap(response.data.data);
        }
        throw new Error('Error al obtener los lives');
    }catch(error){
        console.log(error);
    }
}

export const getEnVivo = async (code) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/lives_public/getPublicLive/${code}`);
        if(response.data.status){
            return enVivoMap(response.data.data);
        }
        throw new Error('Error al obtener los lives');
    }catch(error){
        console.log(error);
    }
}




export const getLiveById = async(id) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/lives/one_live/${id}`);
        if(response.data.status){
            return response.data.data;
        }
        throw new Error('Error al obtener el live');
    }catch(error){
        console.log(error);
    }
}



export const createLive = async(data) => {
    const axios = getAuthAxios();
    const data_ = {
        nombre_live: data.nombreLive,
        escuela: data.escuela,
        fecha_inicio: dayJs(data.fecha).format("YYYY-MM-DD HH:mm:ss"),
        enlace_youtube: data.url_live,
    }
    
    try{
        const response = await axios.post("/lives/create_new_live", data_);
        if(response.data.status){
            return response.data.message;
        }
        throw new Error('Error al crear el live');
    }catch(error){
        console.log(error);
    }
}

export const createEnVivo = async(data) => {
    const axios = getAuthAxios();
    const datos_file = new FormData();
    Object.keys(data).forEach((key) => datos_file.append(key, data[key]));
    
    try{
        const response = await axios.post("/lives_public/createPublicLive", datos_file);
        if(response.data.status){
            return response.data.message;
        }
        throw new Error('Error al crear el live');
    }catch(error){
        console.log(error);
    }
}

export const updateEnVivo = async(data) => {
    const axios = getAuthAxios();
    const datos_file = new FormData();
    Object.keys(data).forEach((key) => datos_file.append(key, data[key]));
    
    try{
        const response = await axios.post(`/lives_public/updatePulicLive/${data.id_live_publico}`, datos_file);
        if(response.data.status){
            return response.data.message;
        }
        throw new Error('Error al crear el live');
    }catch(error){
        console.log(error);
    }
}




export const updateLive = async({idLive, data}) => {
    const axios = getAuthAxios();
    const data_ = {
        nombre_live: data.nombreLive,
        escuela: data.escuela,
        fecha_inicio: dayJs(data.fecha).format("YYYY-MM-DD HH:mm:ss"),
        enlace_youtube: data.url_live,
    }
    try{
        const response = await axios.post(`/lives/edit_live/${idLive}`, data_);
        if(response.data.status){
            return response.data.message;
        }
        throw new Error('Error al actualizar el live');
    }catch(error){
        console.log(error);
    }
}


export const togglePublishLive = async({idLive, status}) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.put(`/lives/toggle_publish_live/${idLive}/${status}`);
        if(response.data.status){
            return response.data.message;
        }
        throw new Error('Error al actualizar el live');
    }catch(error){
        console.log(error);
    }
}

export const togglePublishEnVivo = async({idLive, status}) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.put(`/lives_public/toggle_publish_live/${idLive}/${status}`);
        if(response.data.status){
            return response.data.message;
        }
        throw new Error('Error al actualizar el live');
    }catch(error){
        console.log(error);
    }
}


export const toggleDeleteLive = async({idLive, status}) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.put(`/lives/toggle_delete_live/${idLive}/${status}`);
        if(response.data.status){
            return response.data.message;
        }
        throw new Error('Error al actualizar el live');
    }catch(error){
        console.log(error);
    }
}

export const toggleDeleteEnVivo = async({idLive, status}) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.put(`/lives_public/toggle_delete_live/${idLive}/${status}`);
        if(response.data.status){
            return response.data.message;
        }
        throw new Error('Error al actualizar el live');
    }catch(error){
        console.log(error);
    }
}