import Swal from "sweetalert2";
import { getAuthAxios } from "../axios/axiosCustom";

export const uploadImage = async (file) => {
    const axios = getAuthAxios();
    const datos_file = new FormData();
    datos_file.append("archivo", file);
    const response = await axios.post(`/info/uploadImage`,datos_file);
    if(response.data.status){
        return response.data;
    }
    throw new Error(response.data.message);
}