import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import * as FaIcons from "react-icons/fa";
import FBPostsList from "../../components/Marketing/Posts/FacebookPosts/FBPostsList";
import IGPostsList from "../../components/Marketing/Posts/InstagramPosts/IGPostsList";
import TikTokPostsList from "../../components/Marketing/Posts/TiktokPosts/TikTokPostsList";
import TwitterPostsList from "../../components/Marketing/Posts/TwitterPosts/TwitterPostsList";

const ListaPostsScreen = () => {
	return (
		<LayoutAdmin routeTitle="Lista de publicaciones" contentTitle="">
			<Tabs id="controller-posts" defaultActiveKey={"facebook-posts-list"} >
				<Tab
					eventKey={"facebook-posts-list"}
					title={
						<div className="text-center">
							<FaIcons.FaFacebookSquare />
							<p className="mt-2">Facebook</p>
						</div>
					}
				>
					<FBPostsList />
				</Tab>
				<Tab
					eventKey={"instagram-posts-list"}
					title={
						<div className="text-center">
							<FaIcons.FaInstagramSquare />
							<p className="mt-2">Instagram</p>
						</div>
					}
				>
					<IGPostsList />
				</Tab>
                <Tab
                    eventKey={"twitter-posts-list"}
                    title={
                        <div className="text-center">
                            <FaIcons.FaTwitterSquare />
                            <p className="mt-2">Twitter</p>
                        </div>
                    }
                >
                    <TwitterPostsList />
                </Tab>
                {/* <Tab
                    eventKey={"tiktok-posts-list"}
                    title={
                        <div className="text-center">
                            <FaIcons.FaTiktok />
                            <p className="mt-2">TikTok</p>
                        </div>
                    }
                >
                    <TikTokPostsList />
                </Tab> */}
			</Tabs>
		</LayoutAdmin>
	);
};

export default ListaPostsScreen;
