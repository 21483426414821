import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateSessionAction } from "../../redux/actions/userActions";
import AdminContent from "./admin.content";
import AdminFooter from "./admin.footer";
import AdminHeader from "./admin.header";
import AdminSidebar from "./admin.sidebar";

const LayoutAdmin = ({ children, contentTitle = "Dashboard", routeTitle="Dashboard" }) => {
	
	const dispatch = useDispatch();
	// const { user, isAuthenticated } = useSelector((state) => state.user);
	
	useEffect(() => {
		dispatch(validateSessionAction());
	} , []);
	
	return (
		<div className="wrapper">
			<AdminHeader />
			<AdminSidebar />
			<AdminContent title={contentTitle} routeTitle={routeTitle}>
				{children}
			</AdminContent>
			<AdminFooter />
		</div>
	);
};

export default LayoutAdmin;
