import React from "react";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import { Tab, Tabs } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import FBCampaignList from "../../components/Marketing/facebook/FBCampaignList";
import GoogleCampaignList from "../../components/Marketing/google/GoogleCampaignList";

const ListaCampaignsScreen = () => {
	return (
		<LayoutAdmin routeTitle="Lista de Campañas" contentTitle="">
			<Tabs id="controller-campaigns" defaultActiveKey={"facebook-list"}>
				<Tab
					eventKey={"facebook-list"}
					title={
						<div className="text-center">
							<FaIcons.FaFacebookSquare />
							<p className="mt-2">Facebook Ads</p>
						</div>
					}
				>
					<FBCampaignList />
				</Tab>
				<Tab
					eventKey={"google-list"}
					title={
						<div className="text-center">
							<FaIcons.FaGoogle />
							<p className="mt-2">Google Ads</p>
						</div>
					}
				>
					<GoogleCampaignList />
				</Tab>
			</Tabs>
		</LayoutAdmin>
	);
};

export default ListaCampaignsScreen;
