import React, { useState,useEffect,useMemo }  from 'react';
import { Badge } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import { useQueryGetCuentaAfiliadosById } from '../../hooks/afiliados.hooks';
import { useSelector } from 'react-redux';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import LoaderSmall from '../../components/Loaders/LoaderSmall';
import HeaderSearchTable from '../../components/Custom/HeaderSearchTable';
import DataTable from 'react-data-table-component';
import { SwalToast } from '../../utils/toasts';
const ComisionesDocente = () => {
    const { user } = useSelector((state) => state.user);
    const [filterText, setFilterText] = useState("");
    const [filteredItems, setFilteredItems] = useState();
    const [resetPagination, setResetPagination] = useState(false);
    const [info, setInfo] = useState([]);
    const [comisiones, setComisiones] = useState([]);
    const { data, isLoading } = useQueryGetCuentaAfiliadosById(user.id_user);

     /* Search Component */
  const searchComponentMemo = useMemo(() => {
    return (
      <HeaderSearchTable
        filterText={filterText}
        setFilterText={setFilterText}
        arrayData={comisiones}
        setArrayFiltered={setFilteredItems}
        setResetPagination={setResetPagination}
        columnsToFilter={[
          "Producto",
        ]}
        placeholder="Búsqueda en registros"
      />
    );
  }, [filterText, resetPagination]);

/* Config Table */
const columns = useMemo(
() => [
{
  name: "#",
  cell: (row, index) => index + 1,
  width: "5%",
},
{
  name: "Producto",
  selector: (row) => row.nombre_producto,
  sortable: true,
  wrap:true
},
{
  name: "Comprador",
  selector: (row) => row.nombre_comprador,
  sortable: true,
},
{
  name: "Comision",
  selector: (row) => <span>S/. {row.comision} </span>,
  sortable: true,
},
{
  name: "Estado",
  selector: (row) =>
    row.estado === "1" ? <Badge bg="success">Aprobado</Badge> : row.estado === "0"? <Badge bg="warning">Pendiente</Badge>: <Badge bg="danger">Rechazado</Badge>,
},
],
[]
);

useEffect(()=>{if(data){
        setInfo(data.data[0]);
        setComisiones(data.data[0].comisiones)
}},[data])
    return (
        <LayoutAdmin routeTitle="Mis ingresos" contentTitle="Mis ingresos">
            <div className='card'>
                <div className='card-body'>
                    <div className='input-group'>
                            <input
                                type='text'
                                className='form-control'
                                defaultValue={info.enlace}
                                readOnly
                                style={{width:'60%'}}
                            />
                            <button
                                className='btn ge-btn-primary px-3'
                                type='button'
                                id='button-addon2'
                                onClick={() => {
                                navigator.clipboard.writeText(info.enlace);
                                SwalToast.fire({
                                    icon: 'success',
                                    title: 'Enlace copiado correctamente.',
                                });
                                }}>
                                Copiar enlace
                            </button>
                        </div>
                </div>   
            </div>
            <div className="card p-4">
                <div className="card-body">
                <DataTable
                    columns={columns}
                    data={filteredItems?.length > 0 ? filteredItems : comisiones}
                    subHeader
                    subHeaderComponent={searchComponentMemo}
                    pagination
                    responsive
                    fixedHeader
                    fixedHeaderScrollHeight="300px"
                    progressPending={isLoading}
                    progressComponent={<LoaderSmall />}
                />
                </div>
            </div>
        </LayoutAdmin>
   
    );
};

export default ComisionesDocente;