import { useMutation, useQuery, useQueryClient } from "react-query";
import { createFacebookPost, editFacebookPost, getFacebookPostById, getFacebookPosts, toggleFacebookPost } from "../../../controllers/marketing/posts/facebookPosts.controller";

const KEY_FB_POSTS = 'facebookPosts';

export const useQueryFacebookPosts = () => {
    return useQuery(
        [KEY_FB_POSTS],
        () => getFacebookPosts(), 
        {
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}

export const useQueryOneFacebookPost = (id) => {
    return useQuery(
        [KEY_FB_POSTS, id],
        () => getFacebookPostById(id),
        {
            enabled: (id && id !== "") ? true : false,
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}


export const useMutateCreateFacebookPost = () => {
    const queryClient = useQueryClient();
    return useMutation(
        createFacebookPost,
        {
            onSuccess: () => {
                queryClient.invalidateQueries([KEY_FB_POSTS]);
            }
        }
    );
}

export const useMutateEditFacebookPost = (id) => {
    const queryClient = useQueryClient();
    return useMutation(
        editFacebookPost,
        {
            onSuccess: () => {
                queryClient.invalidateQueries([KEY_FB_POSTS]);
                queryClient.invalidateQueries([KEY_FB_POSTS, id]);
            }
        }
    );
}


export const useMutateToggleFacebookPost = () => {
    const queryClient = useQueryClient();
    return useMutation(
        toggleFacebookPost,
        {
            onSuccess: () => {
                queryClient.invalidateQueries([KEY_FB_POSTS]);
            }
        }
    );
}