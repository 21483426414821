import React from "react";
import * as FaIcons from "react-icons/fa";
import Rating from "react-rating";

const CardTopCourse = ({
    courseData = {
        id_curso: "",
        nombre_curso: "",
        img_curso: null,
        valoracion: 0,
    }
}) => {
    
    const {
        id_curso,
        nombre_curso,
        img_curso,
        valoracion
    } = courseData;
    
    if (!courseData) {
        return null;
    }
	return (
		<div 
            className="d-flex flex-column card-top-docente justify-content-center text-center h-100"
            /* style={{
                height: "275px",
            }} */
        >
			<div className="d-flex flex-column align-items-center justify-content-center avatar-docente mb-2">
				<img
					src={
						(!img_curso || img_curso === "")
							? `https://ui-avatars.com/api/?name=${nombre_curso}&background=random`
							: img_curso.includes("files")
                                ? // ? `${process.env.REACT_APP_API_URL}${imagen}`
                                `${process.env.REACT_APP_API_URL}${img_curso}`
                                : img_curso
					}
					alt="course-avatar"
				/>
				<span className="ge-title">
					{nombre_curso}
				</span>
			</div>
            <span className="ge-title ge-h2">{valoracion.replace('.',',')}{" "}</span>
			<Rating
				className="rating-stars mb-1"
				initialRating={valoracion}
				emptySymbol={
					<FaIcons.FaRegStar visibility={"visible"} color="#547397" />
				}
				fullSymbol={
					<FaIcons.FaStar visibility={"visible"} color="#992AFF" />
				}
				fractions={10}
				readonly={true}
			/>
            <span className="d-none d-sm-block mt-2 ge-txt-secondary ge-weight-medium-alt">
                Total en calificaciones:{" "}
                {valoracion.replace('.',',')}{" "}
                {" "}/{" "}5,0
            </span>
		</div>
	);
};

export default CardTopCourse;
