import dayjs from "dayjs";
import { getDiffDays, getDiffMonths } from "./time";
import _ from "lodash";
import { getDateTimeObject } from "./dateTime";

/* Los datasets se recibirán de manera diaria por defecto */

export const dailyDatasets = (data) => {
    return data;
}


export const weeklyDatasets = (data, startDate, endDate) => {
    const { dataSets, title } = data;
    const { data: dataInSets, ...rest } = dataSets[0];
    
    const labels_ = [];
    let newData = [];
    
    const numberOfDays = getDiffDays(startDate, endDate);
    const numberOfWeeks = Math.ceil(numberOfDays / 7);
    const daysLastWeek = numberOfDays % 7;
    
    //generar label para las semanas en formato '01-07'
    for (let i = 0; i < numberOfWeeks; i++) {
        const weekStart = dayjs(startDate).add(i * 7, "day");
        
        //si la semana es la última
        const weekEnd = (numberOfWeeks - 1 === i) 
            //si la ultima semana tiene 7 días o menos
            ? (daysLastWeek === 0)
                ? dayjs(endDate)
                : dayjs(weekStart).add((daysLastWeek-1), "day")
            : weekStart.add(6, "day");
        const weekStart_ = dayjs(weekStart).format("DD");
        const weekEnd_ = dayjs(weekEnd).format("DD");
        // labels_.push(`Semana ${weekStart_.substring(8, 10)} - ${weekEnd_.substring(8, 10)}`);
        labels_.push(`Semana ${weekStart_} - ${weekEnd_}`);
    }
    
    //generar data para las semanas
    newData = _.chunk(dataInSets, 7);
    newData = newData.map((week) => {
        return week.reduce((acc, cur) => {
            return parseInt(acc) + parseInt(cur);
        }, 0);
    });
    
    return {
        dataSets: [
            {
                ...rest,
                data: newData
            }
        ],
        labels: labels_,
        title
    }
}


export const monthlyDatasets = (data, startDate, endDate) => {
    
    //custom dayjs object spanish
    const dayJsEs = getDateTimeObject();
    
    const { dataSets, labels, title } = data;
    const { data: dataInSets, ...rest } = dataSets[0];
    
    const labels_ = [];
    let newData = [];
    let dataInSets_ = dataInSets;
    
    //obtener los meses entre startDate y endDate
    const numberOfMonths = getDiffMonths(startDate, endDate);
    console.log('numberOfMonths', numberOfMonths); //1
    
    for (let i = 0; i < numberOfMonths; i++) {
        const month = dayJsEs(startDate).add(i, "month");
        
        //obtener cuantos días tiene el mes
        //si es el primer mes
        const daysInMonth = (i === 0)
            ? (parseInt(dayJsEs(month).daysInMonth()) - parseInt(dayJsEs(startDate).format("DD"))) + 1
            //si es el ultimo mes
            : (i === numberOfMonths - 1)
                ? parseInt(dayJsEs(endDate).format("D"))
                //si no es el primer ni el ultimo
                : parseInt(dayJsEs(month).daysInMonth());
        
        console.log('daysInMonth', daysInMonth);
        
        //tomar la cantidad de datos dependiendo del mes
        const dataInMonth = _.take(dataInSets_, daysInMonth);
        console.log('dataInMonth', dataInMonth);
        
        //sumar los datos del mes
        const dataInMonthSum = dataInMonth.reduce((acc, cur) => {
            return parseInt(acc) + parseInt(cur);
        });
        console.log('dataInMonthSum', dataInMonthSum);
        
        //agregar la suma al array de datos
        newData.push(dataInMonthSum);
        
        //eliminar los datos tomados del array
        dataInSets_ = _.drop(dataInSets_, daysInMonth);
        
        //generar label para los meses en formato 'mayo', 'junio', etc
        labels_.push(month.format("MMMM"));
    }
    
    return {
        dataSets: [
            {
                ...rest,
                data: newData
            }
        ],
        labels: labels_,
        title
    }
}



export const parseDataset = (data, type = "day", startDate, endDate) => {
    switch (type) {
        case "day":
            return dailyDatasets(data);
        case "week":
            return weeklyDatasets(data, startDate, endDate);
        case "month":
            return monthlyDatasets(data, startDate, endDate);
        default:
            return dailyDatasets(data);
    }
}