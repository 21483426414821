import React from "react";
import { useDispatch } from "react-redux";
import { logoutUserAction } from "../../redux/actions/userActions";
import * as FiIcons from "react-icons/fi";

const AdminHeader = () => {
	
	const dispatch = useDispatch();
	
	const logoutUser = () => {
		dispatch(logoutUserAction());
	}
	
	return (
		<nav className="main-header navbar navbar-expand navbar-white navbar-light">
			{/* Left navbar links */}
			<ul className="navbar-nav">
				<li className="nav-item">
					<a
						className="nav-link"
						data-widget="pushmenu"
						href="#"
						role="button"
					>
						<i className="fas fa-bars" />
					</a>
				</li>
			</ul>
			{/* Right navbar links */}
			<ul className="navbar-nav ml-auto">
				{/* Navbar Search */}
				{/* <li className="nav-item">
					<a
						className="nav-link"
						data-widget="navbar-search"
						href="#"
						role="button"
					>
						<i className="fas fa-search" />
					</a>
					<div className="navbar-search-block">
						<form className="form-inline">
							<div className="input-group input-group-sm">
								<input
									className="form-control form-control-navbar"
									type="search"
									placeholder="Search"
									aria-label="Search"
								/>
								<div className="input-group-append">
									<button
										className="btn btn-navbar"
										type="submit"
									>
										<i className="fas fa-search" />
									</button>
									<button
										className="btn btn-navbar"
										type="button"
										data-widget="navbar-search"
									>
										<i className="fas fa-times" />
									</button>
								</div>
							</div>
						</form>
					</div>
				</li> */}
				{/* Messages Dropdown Menu */}
				{/* <li className="nav-item dropdown">
					<a className="nav-link" data-toggle="dropdown" href="#">
						<i className="far fa-comments" />
						<span className="badge ge-bg-primary txt-white navbar-badge">
							3
						</span>
					</a>
					<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
						<a href="#" className="dropdown-item">
						
							<div className="media">
								<img
									src="/dist/img/user1-128x128.jpg"
									alt="User Avatar"
									className="img-size-50 mr-3 img-circle"
								/>
								<div className="media-body">
									<h3 className="dropdown-item-title">
										Brad Diesel
										<span className="float-right text-sm text-danger">
											<i className="fas fa-star" />
										</span>
									</h3>
									<p className="text-sm">
										Call me whenever you can...
									</p>
									<p className="text-sm text-muted">
										<i className="far fa-clock mr-1" /> 4
										Hours Ago
									</p>
								</div>
							</div>
							
						</a>
						<div className="dropdown-divider" />
						<a href="#" className="dropdown-item dropdown-footer">
							See All Messages
						</a>
					</div>
				</li> */}
				{/* Notifications Dropdown Menu */}
				{/* <li className="nav-item dropdown">
					<a className="nav-link" data-toggle="dropdown" href="#">
						<i className="far fa-bell" />
						<span className="badge ge-bg-primary txt-white navbar-badge">
							15
						</span>
					</a>
					<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
						<span className="dropdown-item dropdown-header">
							15 Notifications
						</span>
						<div className="dropdown-divider" />
						<a href="#" className="dropdown-item">
							<i className="fas fa-envelope mr-2" /> 4 new
							messages
							<span className="float-right text-muted text-sm">
								3 mins
							</span>
						</a>
						<div className="dropdown-divider" />
						<a href="#" className="dropdown-item">
							<i className="fas fa-users mr-2" /> 8 friend
							requests
							<span className="float-right text-muted text-sm">
								12 hours
							</span>
						</a>
						<div className="dropdown-divider" />
						<a href="#" className="dropdown-item">
							<i className="fas fa-file mr-2" /> 3 new reports
							<span className="float-right text-muted text-sm">
								2 days
							</span>
						</a>
						<div className="dropdown-divider" />
						<a href="#" className="dropdown-item dropdown-footer">
							See All Notifications
						</a>
					</div>
				</li> */}
				<li className="nav-item">
					<a
						className="nav-link"
						data-widget="fullscreen"
						href="#"
						role="button"
					>
						<i className="fas fa-expand-arrows-alt" />
					</a>
				</li>
				<li 
					className="nav-item"
					onClick={logoutUser}
				>
					<a
						className="nav-link"
						role="button"
					>
						<FiIcons.FiLogOut
							width="14px"
							height="16px"
							className="mb-1"
						/>
					</a>
				</li>
			</ul>
		</nav>
	);
};

export default AdminHeader;
