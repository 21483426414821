import React from "react";

import { useParams } from "react-router-dom";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import { useQuery } from "react-query";
import { Alert, Spinner } from "react-bootstrap";
import TabEscuela from "../../components/Escuelas/TabEscuela";
import { getEscuelaByUid } from "../../controllers/escuelas/escuelas";
import * as FaIcons from "react-icons/fa";
const EditEscuela = () => {
	const { uId } = useParams();

	const {
		data: datosEscuela,
		error,
		isLoading,
	} = useQuery(["getEscuela", uId], () => getEscuelaByUid(uId), {
		refetchOnWindowFocus: false,
	});

	return (
		<LayoutAdmin
			contentTitle="Configurar escuela"
			routeTitle="Configuración de escuela"
		>
			{isLoading ? (
				<div className="text-center">
					{" "}
					<Spinner animation="border" />
				</div>
			) : datosEscuela ? (
				<TabEscuela
					escuela={datosEscuela.escuela[0]}
					rutas={datosEscuela.rutas}
				/>
			) : (
				<Alert key={"info"} variant={"info"}>
					<FaIcons.FaExclamationTriangle /> No existe informacion
					relacionada....
				</Alert>
			)}
		</LayoutAdmin>
	);
};

export default EditEscuela;
