import Swal from "sweetalert2";
import { getAuthAxios } from "../../axios/axiosCustom";
import dayjs from "dayjs";

export const getGraficoSuscripciones = async (startDate, endDate) => {
    const startDate_ = dayjs(startDate).format("YYYY-MM-DD");
    const endDate_ = dayjs(endDate).format("YYYY-MM-DD");
    const axios = getAuthAxios();
    const response = await axios.get(`/compra/suscripciones/${startDate_}/${endDate_}`);
    if(response.data.status){
        return response.data.data;
    }
    throw new Error(response.data.message);
}

export const getGraficoCursos= async (startDate, endDate) => {
    const startDate_ = dayjs(startDate).format("YYYY-MM-DD");
    const endDate_ = dayjs(endDate).format("YYYY-MM-DD");
    const axios = getAuthAxios();
    const response = await axios.get(`/compra/cursos/${startDate_}/${endDate_}`);
    if(response.data.status){
        return response.data.data;
    }
    throw new Error(response.data.message);
}
