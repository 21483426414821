import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";

const OverlayIdCampaign = () => {
	const [showPopover, setShowPopover] = useState(false);
    
	return (
		<OverlayTrigger
			trigger="click"
			placement="bottom"
			show={showPopover}
			onToggle={() => setShowPopover(!showPopover)}
			overlay={
				<Popover
					id="popover-basic"
					className="popover-options-container"
				>
					<Popover.Body>
                        <span className="mb-2">El ID de la campaña de GoogleAds lo encuentras en la URL como campaignId='Codigo'</span>
                        <div className="mt-2">
                            <img
                                src="/images/google_ads_id.png"
                                alt="info-id-campaign"
                                className="img-fluid"
                            />
                        </div>
					</Popover.Body>
				</Popover>
			}
		>
			<span
                style={{ cursor: "pointer" }}
            >
				<FaIcons.FaInfoCircle />
			</span>
		</OverlayTrigger>
	);
};

export default OverlayIdCampaign;
