// import LayoutAdmin from "./components/layouts/LayoutAdmin";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { AppRouter } from "./routers/AppRouter";

const AdminGestora = () => {

	return (
		<Provider store={store}>
			<AppRouter />
		</Provider>
	);
};

export default AdminGestora;
