import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import {  getDataInscripciones } from "../../controllers/estudiantes/estudiantes.controller";
import HeaderSearchTable from "../Custom/HeaderSearchTable";
import LoaderSmall from "../Loaders/LoaderSmall";
import * as FaIcons from 'react-icons/fa';

const InscritosPorRuta = ({selectOption = null}) => {
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [dataTable, setDataTable] = useState([]);
	const [dataTableFiltered, setDataTableFiltered] = useState([]);
	const [loading, setLoading] = useState(false);
	
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={dataTable}
				setArrayFiltered={setDataTableFiltered}
				setResetPagination={setResetPagination}
				placeholder="Buscar curso"
				columnsToFilter={["nombre_curso"]}
			/>
		);
	}, [filterText, resetPagination]);
	
	const columns = useMemo(
		() => [
			{
				name: "#",
				selector: (row, rowIndex) => rowIndex + 1,
				width: "10%",
			},
			{
				name: "Ruta",
				// width: '250px',
				selector: (row) => row.nombre_ruta,
				sortable: true,
			},
			{
				name: "Curso",
				// width: '250px',
				selector: (row) => row.nombre_curso,
				sortable: true,
			},
			{
				name: "N° Inscritos",
				width: "250px",
				selector: (row) => row.n_inscritos,
				sortable: true,
			},
		],
		[]
	);
	
	
	useEffect(() => {
		if (selectOption?.panelTitle === "ruta" && selectOption?.value) {
			getData(selectOption?.panelTitle,selectOption.value);
		}
	}, [selectOption]);
	
	const getData = async (grupo,idRoute) => {
		setLoading(true);
		try {
			const response = await getDataInscripciones(grupo,idRoute);
			if (response) {
				setDataTable(response);
			}
			setLoading(false);
		} catch (error) {
		//	console.log(error);
			setLoading(false);
		}
	};
	
	return (
		<>
			<DataTable
				className="custom-datatable"
				columns={columns}
				data={
					dataTableFiltered.length > 0 ? dataTableFiltered : dataTable
				}
				subHeader
				subHeaderComponent={searchComponentMemo}
				pagination
				responsive
				progressPending={loading}
				progressComponent={<LoaderSmall />}
				noDataComponent={
					<div className="mt-5 w-100 text-center">
						No hay datos.
					</div>
				}
			/>
		</>
	);
};

export default InscritosPorRuta;
