export const enVivoMap = (listEnVivo) => {
    console.log(listEnVivo)
    return listEnVivo.map((enVivo) => ({
        id_live_publico:enVivo.id_live_publico,
        url_envivo:enVivo.enlace_youtube ? enVivo.enlace_youtube : enVivo.enlace_vimeo,
        activo:enVivo.activo,
        eliminado:enVivo.eliminado,
        slug:enVivo.slug,
        cod_unico:enVivo.cod_unico,
        nombre_live:enVivo.cod_unico,
        color_boton:enVivo.color_boton,
        texto_boton:enVivo.texto_boton,
        enlace_boton:enVivo.enlace_boton,
        banner_promocional:enVivo.banner_promocional,
        isLive:enVivo.enlace_youtube ? true:false,
    }));
  };
  