import React from "react";
import { Modal } from "react-bootstrap";

const ModalObj = (props) => {
	const { show, updateShowModal, title, children } = props;
	return (
		<Modal
			size="md"
			show={show}
			onHide={() => updateShowModal()}
			backdrop="static"
			keyboard={false}
			aria-labelledby="example-modal-sizes-title-lg"
			centered
		>
			<Modal.Header closeButton className="ge-bg-primary txt-white">
				<Modal.Title id="example-modal-sizes-title-lg">
					<h4>{title}</h4>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>
		</Modal>
	);
};

export default ModalObj;
