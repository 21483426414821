import React from 'react'

const CardGestora = ({
    title,
    headerClass,
    bodyClass,
    footerClass,
    children,
    ...props
}) => {
  return (
    <div className="card card-gestora-primary h-100">
        <div 
            className= {
                `card-header ge-noborder py-2 ${headerClass}`
            }
        >
            <h5 className="mb-0 txt-white txt-bold">{title}</h5>
        </div>
        <div 
            className={
                `card-body ${bodyClass} card-body-scroll card-body-gestora`
            }
        >
            {children}
        </div>
	</div>
  )
}

export default CardGestora