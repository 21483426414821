import { getAuthAxios } from "../../axios/axiosCustom";
import Swal from "sweetalert2";


export const getSolicitudesFactura = async () => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get('/factura/solicitudes');
        if(response.data.status){
            return response.data.data;
        }else{
            return [];
        }
    }catch(error){
        return [];
    }
}



export const getFacturasFiles = async () => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get('/factura/listar-facturas');
        if(response.data.status){
            return response.data.data;
        }else{
            throw new Error('Error al obtener las facturas');
        }
    }catch(error){
        throw new Error('Error al obtener las facturas');
    }
}



export const uploadFacturas = async ({files}) => {
    const axios = getAuthAxios();
    const formData = new FormData();
    files.forEach(element => { 
        formData.append('facturas[]', element); 
    });
    // formData.append('recursos[]', resources);
    try{
        const response = await axios.post('/factura/subir-facturas', formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log('response',response);
        if(response.data.status){
            return response.data;
        }else{
            const {error, nombre} = response.data.error[0];
            throw new Error(`Error al subir la factura ${nombre}: ${error}`);
        }
    }catch(e){
        console.log(e);
        throw new Error(e);
    }
}

export const deleteFacturaById = async (id_factura) => {
    const axios = getAuthAxios();
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
  
    await  Swal.fire({
        title: '¿Estás seguro?',
        text: "Una vez eliminado, no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
            const response = await axios.delete(`/factura/eliminar-factura/${id_factura}`);
              
            if(response.data.status){
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  })
            }
            else{
                Toast.fire({
                    icon: 'warning',
                    title: response.data.message
                  })
            }
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      })
  }