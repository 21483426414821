import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { SwalToast } from '../../../utils/toasts';
import { modulos_id } from '../../../utils/constants';
import { editRol } from '../../../controllers/usuariosRoles/roles';

const EditRoles = ({ handleShow, dataRol }) => {
    const [moduloState, setModuloState] = useState([]);

    const { nombre_rol: name, modulos, id_rol} = dataRol;

    useEffect(() => {
        if(modulos.length > 0){
            const soloIdModulo = modulos.map(v => parseInt(v.id_modulo))
            setModuloState(soloIdModulo)
        }
    },[])

    const { 
        Metricas,
        Escuelas,
        Rutas,
        Cursos,
        Docentes,
        Suscripciones_y_cursos,
        Incidencias,
        Lives,
        Convocatorias_laborales,
        Estudiantes,
        BlogeHistoriasdeExito,
        Contabilidad,
        Facturas,
        Planes,
        Marketing,
        Ajustes,
        Usuarios_y_Roles,
    } = modulos_id;

    const initialValues = {
        nombre_rol: name ? name : "",
        modulos: [],
    }

    const validationSchema = Yup.object({
        nombre_rol: Yup.string().required('El nombre del rol es requerido'),
        modulos: Yup.array().required('Tiene que seleccionar al menos un modulo')
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            values.modulos = JSON.stringify(moduloState);
            const response = await editRol(id_rol, values)
            if(response.status){
                handleShow(false)
                formik.resetForm()
                SwalToast.fire({
                    icon: "success",
                    title: "Rol editado con exito",
                });
            }
        }
    })


    const check = (value) => {
        const rolesSelected = [...moduloState]
        if(rolesSelected.includes(value)){
             rolesSelected.splice(rolesSelected.indexOf(value),1)
        } else {
            rolesSelected.push(value)
        }
        setModuloState(rolesSelected)
    }

  return (
    <div className="row">
	    <div className="col-12">
            <Form 
                className='row'
                onSubmit={formik.handleSubmit}
            >
                <Form.Group className='my-2 col-md-6'>
                    <Form.Label>
                        Nombre del rol
                    </Form.Label>
                        <Form.Control 
                            name='nombre_rol'
                            onChange={formik.handleChange}
                            value={formik.values.nombre_rol}
                            placeholder="Escribe el Nombre"
                            type='text'
                            isValid={
                                formik.touched.nombre_rol &&
                                !formik.errors.nombre_rol
                            }
                            isInvalid={
                                formik.touched.nombre_rol &&
                                !!formik.errors.nombre_rol
                            }
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.nombre_rol}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='my-2 col-md-12'>
                        <Form.Label>
                            Modulos asignados
                        </Form.Label>
                        <div className='row mx-1' >
                            <Form.Check className='col-md-4' checked={moduloState.includes(Metricas)} type="checkbox" label="METRICAS" onChange={() => check(Metricas)}/>
                            <Form.Check className='col-md-4' checked={moduloState.includes(Escuelas)} type="checkbox" label="ESCUELAS" onChange={() => check(Escuelas)}/>
                            <Form.Check className='col-md-4' checked={moduloState.includes(Rutas)} type="checkbox" label="RUTAS" onChange={() => check(Rutas)}/>
                            <Form.Check className='col-md-4' checked={moduloState.includes(Cursos)} type="checkbox" label="CURSOS" onChange={() => check(Cursos)}/>
                            <Form.Check className='col-md-4' checked={moduloState.includes(Docentes)} type="checkbox" label="DOCENTES" onChange={() => check(Docentes)}/>
                            <Form.Check className='col-md-4' checked={moduloState.includes(Suscripciones_y_cursos)} type="checkbox" label="SUSCRIPCIONES Y CURSOS" onChange={() => check(Suscripciones_y_cursos)}/>
                            <Form.Check className='col-md-4' checked={moduloState.includes(Incidencias)} type="checkbox" label="INCIDENCIAS" onChange={() => check(Incidencias)}/>
                            <Form.Check className='col-md-4' checked={moduloState.includes(Lives)} type="checkbox" label="LIVES" onChange={() => check(Lives)}/>
                            <Form.Check className='col-md-4' checked={moduloState.includes(Convocatorias_laborales)} type="checkbox" label="CONVOCATORIAS LABORALES" onChange={() => check(Convocatorias_laborales)}/>
                            <Form.Check className='col-md-4' checked={moduloState.includes(Estudiantes)} type="checkbox" label="ESTUDIANTES" onChange={() => check(Estudiantes)}/>
                            <Form.Check className='col-md-4' checked={moduloState.includes(BlogeHistoriasdeExito)} type="checkbox" label="BLOG E HISTORIAS DE EXITO" onChange={() => check(BlogeHistoriasdeExito)}/>
                            <Form.Check className='col-md-4' checked={moduloState.includes(Contabilidad)} type="checkbox" label="CONTABILIDAD" onChange={() => check(Contabilidad)}/>
                            <Form.Check className='col-md-4' checked={moduloState.includes(Facturas)} type="checkbox" label="FACTURAS" onChange={() => check(Facturas)}/>
                            <Form.Check className='col-md-4' checked={moduloState.includes(Planes)} type="checkbox" label="PLANES" onChange={() => check(Planes)}/>
                            <Form.Check className='col-md-4' checked={moduloState.includes(Marketing)} type="checkbox" label="MARKETING" onChange={() => check(Marketing)}/>
                            <Form.Check className='col-md-4' checked={moduloState.includes(Ajustes)} type="checkbox" label="AJUSTES" onChange={() => check(Ajustes)}/>
                            <Form.Check className='col-md-4' checked={moduloState.includes(Usuarios_y_Roles)} type="checkbox" label="USUARIOS Y ROLES" onChange={() => check(Usuarios_y_Roles)}/>
                        </div>
                    </Form.Group>
                    <button type='submit' className="btn ge-btn-primary btn-block mt-3">
                            Editar Rol
                    </button>
            </Form>
        </div>
    </div>
  )
}

export default EditRoles;