import { forwardRef } from "react";

const CustomInputDate = forwardRef((props, ref) => {
    
    // console.log("CustomInputDate", props);
    const { value, onClick, onChange, placeholder } = props;
    
    return(
        <div 
            className="d-flex align-items-center custom-date px-2 py-1"
            onClick={onClick}
            ref={ref}
        >
            <img
                src="/images/calendar-icon.png"
                alt="calendar-icon"
                className="d-none d-sm-block mr-2"
                style={{ maxWidth: "20px" }}
            />
            <input
                type="text"
                value={value}
                className="custom-date-input"
                onChange={onChange}
                placeholder={placeholder}
            />
        </div>
    )
});

export default CustomInputDate;