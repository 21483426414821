import React, { useMemo, useState } from "react";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import {FaIcons, BsIcons, MdIcons} from "../../icons/export-reacticons";
import DataTable from 'react-data-table-component';
import LoaderSmall from "../../components/Loaders/LoaderSmall";
import HeaderSearchTable from "../../components/Custom/HeaderSearchTable";
import { useMutateToggleDeleteLive, useMutateTogglePublishLive, useQueryLives } from "../../hooks/lives.hooks";
import ModalNewLive from "../../components/Lives/ModalNewLive";
import { getDateTimeObject } from "../../utils/dateTime";
import ModalEditLive from "../../components/Lives/ModalEditLive";
import { SwalToast } from "../../utils/toasts";
import Swal from "sweetalert2";

const dayJs = getDateTimeObject();

const ListaLives = () => {
    
    const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
    const [showModalCreate, setShowModalCreate] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);
    const [idLive, setIdLive] = useState(null);
	
	//React Query Hooks
    const { data:livesList, isLoading } = useQueryLives();
    const { mutate: togglePublish } = useMutateTogglePublishLive();
	const { mutate: toggleDelete } = useMutateToggleDeleteLive();
	
	const handleSendToEdit = (idLive) => {
		setIdLive(idLive);
		setShowModalEdit(true);
	}
	
	const handleCopyToClipboard = (text) => {
		navigator.clipboard.writeText(process.env.REACT_APP_API_CLIENT+'lives/'+text).then(() => {
			SwalToast.fire({
				title: "Copiado al portapapeles",
				icon: "success",
				timer: 1500,
			})
		})
		.catch(err => {
			SwalToast.fire({
				title: "Error al copiar al portapapeles",
				icon: "error",
				timer: 1500,
			})
		});
	}
	
	const handleTogglePublish = (idLive, status) => {
		Swal.fire({
			title: "¿Estás seguro?",
			text: "¿Deseas cambiar el estado del live?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si, cambiar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if(result.isConfirmed){
				togglePublish({
					idLive,
					status,
				}, {
					onSuccess: () => {
						SwalToast.fire({
							icon: "success",
							title: "El live se ha actualizado correctamente",
						});
					},
					onError: (error) => {
						SwalToast.fire({
							icon: "error",
							title: "Error al actualizar el live",
						});
					}
				});
			}
		})
	}
	
	const handleToggleDelete = (idLive, status) => {
		Swal.fire({
			title: "¿Estás seguro?",
			text: "¿Deseas cambiar el estado del live?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si, cambiar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if(result.isConfirmed){
				toggleDelete({
					idLive,
					status,
				}, {
					onSuccess: () => {
						SwalToast.fire({
							icon: "success",
							title: "El live se ha actualizado correctamente",
						});
					},
					onError: (error) => {
						SwalToast.fire({
							icon: "error",
							title: "Error al actualizar el live",
						});
					}
				});
			}
		})
	}
	
    /* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={livesList}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
				placeholder="Buscar por nombre"
				columnsToFilter={["nombre_live"]}
			/>
		);
	}, [filterText, resetPagination]);
    
    /* Config Table */
	const columns = useMemo(
		() => [
			{
				name: '#',
				cell: (row) => row.id_live,
				width: '5%',
			},
			{
				name: "Nombre LIVE",
                width: '250px',
				selector: (row) => row.nombre_live,
				sortable: true,
			},
			{
				name: "Escuela",
				width: '200px',
				selector: (row) => row.nombre_escuela
			},
			{
				name: "Fecha de inicio",
                cell: (row) => dayJs(row.fecha_inicio).format("DD/MM/YYYY"),
			},
			{
				name: "Hora de inicio",
				cell: (row) => dayJs(row.fecha_inicio).format("h:mm A"),
			},
			{
				name: "Publicar",
				width: "7%",
				cell: (row) => (
					<>
						{
							parseInt(row.publicado) === 0 ? (
								<button
									className="btn btn-sm mx-1"
									type="button"
									title="Publicar"
									onClick={() => handleTogglePublish(row.id_live, 1)}
								>
									<FaIcons.FaToggleOff size={20} color="red"/>
								</button>
							):(
								<button
									className="btn btn-sm mx-1"
									type="button"
									title="Despublicar"
									onClick={() => handleTogglePublish(row.id_live, 0)}
								>
									<FaIcons.FaToggleOn size={20} color="green"/>
								</button>
							)
						}
					</>
				)
			},
			{
				name: "Acciones",
				cell: (row) => (
						<div className="d-flex">
							<button
								className="btn ge-btn-deg-primary btn-sm mx-1"
								title="Editar"
								onClick={() => handleSendToEdit(row.id_live)}
							>
								<FaIcons.FaEdit size={15} />
							</button>
							<button
								className="btn ge-btn-deg-primary text-light btn-sm mx-1"
								title="Copiar enlace"
								onClick={() => handleCopyToClipboard(row.slug)}
							>
								<FaIcons.FaLink size={15} />
							</button>
							{
								parseInt(row.eliminado) === 0 ? (
									<button 
										className="btn btn-danger text-light btn-sm mx-1"
										title="Eliminar"
										onClick={() => handleToggleDelete(row.id_live, 1)}
									>
										<FaIcons.FaTrashAlt size={15} />
									</button>
								):(
									<button 
										className="btn btn-success btn-sm mx-1"
										title="Restaurar"
										onClick={() => handleToggleDelete(row.id_live, 0)}
										>
										<FaIcons.FaTrashRestoreAlt size={15} />
									</button>
								)
							}
						</div>
					),
			},
			{
				name: "",
				cell: (row) => (
					<div className="d-flex align-items-center justify-content-center">
						<a 
							href={process.env.REACT_APP_API_CLIENT + "lives/" + row.slug} 
							target="_blank" rel="noopener noreferrer"
						>
							Ingresar al live
						</a>
					</div>
				)
			},
		],
		[]
	);
    
	return (
		<LayoutAdmin contentTitle="" routeTitle="Lista de docentes">
            <ModalNewLive show={showModalCreate} setShow={setShowModalCreate} />
			<ModalEditLive
				show={showModalEdit}
				setShow={setShowModalEdit}
				idLive={idLive}
				setIdLive={setIdLive}
			/>
			<div className="row">
				<div className="col-12">
					<div className="card p-2">
						<div className="d-flex justify-content-between card-header card-no-after ge-noborder">
							<div>
								<img
									src="/images/file-icon.png"
									className="mr-2"
									style={{ width: "40px" }}
									alt="file-icon"
								/>
								<span className="ge-h3 ge-title">
									Lista de lives
								</span>
							</div>
							<div className="">
								<button
									className="btn ge-btn-deg-primary btn-sm"
									onClick={() => setShowModalCreate(true)}
								>
									<FaIcons.FaPlus />
									<span className="ml-2">Crear live</span>
								</button>
							</div>
						</div>
						<div className="card-body">
							<DataTable
								className="custom-datatable"
								columns={columns}
								data={
									filteredItems.length > 0
										? filteredItems
										: livesList
								}
								subHeader
								subHeaderComponent={searchComponentMemo}
								pagination
								responsive
								progressPending={isLoading}
								progressComponent={<LoaderSmall />}
							/>
						</div>
					</div>
				</div>
			</div>
		</LayoutAdmin>
	);
};

export default ListaLives;
