import React from 'react';
import Select from 'react-select';

export default ({onChange, options, value, className="", isLoading }) => {

    const defaultValue = (options, value) => {
        return options ? options.find(option => option.id_user === value) : "";
    };

    return (
        <div className={className} style={{zIndex:4}}>
            <Select
                isLoading={isLoading}
                isClearable
                placeholder='--Seleccione--'
                noOptionsMessage={() => 'No hay opciones'}
                value={defaultValue(options, value)}
                onChange={value => {
                    if(!value)
                    {
                        value={
                            id_user:''
                        }
                    }
                    onChange(value)

                }} options={options}
                getOptionValue={usuarios => usuarios.id_user}
                getOptionLabel={usuarios => usuarios.nombre_completo} />
        </div>

    )
}