/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { SelectionState } from "draft-js";

const ShowMenuItem = (props) => {
	
	const location = useLocation();
	const { user } = useSelector((state) => state.user);
	const [isActiveMenu, setIsActiveMenu] = useState(false);
	const [canAccessModule, setCanAccessModule] = useState(false);
	const [mostrarItems, setMostrarItems] = useState(true)
	const { pathname } = location;
	
	const { menuModule } = props;
	const { title, icon, roles: rolesMenu, items, url } = menuModule;
	const { roles: rolesUser } = user;
	
	const itemsUrl = items.map((item) => item.url);
	
	const haveAccess = (rolesToValidate) =>{
        let intersectionRoles = [];
        intersectionRoles = _.intersection(rolesToValidate, rolesUser.map(role => parseInt(role)));
        return intersectionRoles.length > 0;
    }
	
	useEffect(() => {
		const isOpenMenu = itemsUrl.includes(pathname);
		if (isOpenMenu) {
			setIsActiveMenu(isOpenMenu);
		}
	}, [pathname]);
	
	useEffect(() => {
		const canAccess = haveAccess(rolesMenu);
		setCanAccessModule(canAccess);
	},[]);
	
	
	if (canAccessModule) {
		return (
			/* Module Item */
			<li className={isActiveMenu ? "nav-item menu-open" : "nav-item"} >
				{/* Name Module with icon */}
				{url === "nav-menu" ? (
					<a
						className={
							isActiveMenu ? "nav-link active" : "nav-link"
						}
						href="#"
						onClick={() => setMostrarItems(!mostrarItems)}
					>
						<i className={`nav-icon ${icon}`} />
						<p>
							{_.upperCase(title)}
							<i className="right fas fa-angle-left" />
						</p>
					</a>
				) : (
					<NavLink
						to={url}
						className={
							pathname === url ? "nav-link active" : "nav-link"
						}
					>
						<i className={`nav-icon ${icon}`} />
						<p>
							{_.upperCase(title)}
							<i className="right fas fa-angle-left" />
						</p>
					</NavLink>
				)}

				{/* Menu Items */}
				{mostrarItems && items.length > 0 && (
					<ul className="nav nav-treeview">
						{items.map((item, index) => {
							const { label, url, roles: rolesPerItem } = item;
							
							const canAccessItem = haveAccess(rolesPerItem);
							
							if (canAccessItem) {
								return (
									<li className="nav-item" key={index}>
										{url === "nav-menu" ? (
											<a
												className={
													pathname === url
														? "nav-link active"
														: "nav-link"
												}
												href="#"
											>
												<i className="nav-icon far fa-circle" />
												<p>{label}</p>
											</a>
										) : (
											<NavLink
												to={url}
												className={
													pathname === url
														? "nav-link active"
														: "nav-link"
												}
											>
												<i className="far fa-circle nav-icon" />
												<p>{label}</p>
											</NavLink>
										)}
									</li>
								);
							}
							return null;
						})}
					</ul>
				)}
			</li>
		);
	}

	return null;
};
export default ShowMenuItem;
