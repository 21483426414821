import { roles } from "../../../utils/constants"

const { ADMIN, DOCENTE, MARKETING, MANAGER, CONTABILIDAD } = roles

/* Docentes Items */
const ITEMS_DOCENTE=[
	{
		label: "Dashboard docentes",
		icon: "fas fa-chart-line",
		roles: [ADMIN, MANAGER],
		url: "/dashboard-docentes",
		items: [],
	},
	{
		label: "Listar docentes",
		icon: "fas fa-users",
		roles: [ADMIN, MANAGER],
		url: "/listar-docentes",
		items: [],
	},
	{
		label: "Crear docente",
		icon: "fas fa-user-plus",
		roles: [ADMIN, MANAGER],
		url: "/crear-docente",
		items: [],
	},
]

const ITEMS_BLOG_HISTORIAS=[
	{
		label: "DashBoard",
		icon: "fas fa-dashboard",
		roles: [ADMIN, DOCENTE, MARKETING, MANAGER],
		url: "/dashboard-post-historias",
		items: [],
	},
	{
		label: "Listar Posts",
		icon: "fas fa-list",
		roles: [ADMIN, DOCENTE, MARKETING, MANAGER],
		url: "/listar-posts",
		items: [],
	},
	{
		label: "Listar Historias",
		icon: "fas fa-list",
		roles: [ADMIN, DOCENTE, MARKETING, MANAGER],
		url: "/listar-historias",
		items: [],
	}
]

const ITEMS_RUTAS=[
	{
		label: "Listar Rutas",
		icon: "fas fa-list",
		roles: [ADMIN, MANAGER],
		url: "/listar-rutas",
		items: [],
	},
	{
		label: "Crear ruta",
		icon: "fas fa-plus",
		roles: [ADMIN, MANAGER],
		url: "/crear-ruta",
	}
]

const ITEMS_SUSCRIPCIONES_CURSOS=[
	{
		label: "Dashboard",
		icon: "fas fa-dashboard",
		roles: [ADMIN, CONTABILIDAD, MARKETING, MANAGER],
		url: "/dashboard-suscripciones-cursos",
		items: [],
	},
	{
		label: "Listar Suscripciones",
		icon: "fas fa-list",
		roles: [ADMIN, CONTABILIDAD, MARKETING, MANAGER],
		url: "/listar-ventas-suscripciones",
	}
	,
	{
		label: "Listar Cursos",
		icon: "fas fa-list",
		roles: [ADMIN, CONTABILIDAD, MARKETING, MANAGER],
		url: "/listar-ventas-cursos",
	}
]

const ITEMS_PLANES=[
	{
		label: "Listar Planes",
		icon: "fas fa-list",
		roles: [ADMIN],
		url: "/listar-planes",
		items: [],
	}
]

const ITEMS_CURSO=[
	{
		label: "Lista de cursos",
		icon: "fas fa-list-alt",
		roles: [ADMIN, MANAGER],
		url: "/listar-cursos",
		items: [],
	},
]

const ITEMS_CUPONES=[
	{
		label: "Listar cupones",
		icon: "fas fa-list-alt",
		roles: [ADMIN, MANAGER],
		url: "/cupones",
	},
]

const ITEMS_ESCUELA=[
	{
		label: "Listar escuelas",
		icon: "fas fa-list",
		roles: [ADMIN, MANAGER],
		url: "/listar-escuelas",
		items: [],
	},
	{
		label: "Crear escuela",
		icon: "fas fa-plus",
		roles: [ADMIN, MANAGER],
		url: "/crear-escuela",
		items: [],
	},
	{
		label: "Editar portada",
		icon: "fas fa-edit",
		roles: [ADMIN, MANAGER],
		url: "/escuela/portada",
		items: [],
	}
]

const ITEMS_ESTUDIANTES = [
	{
		label: 'Dashboard estudiantes',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, MANAGER],
		url: '/estudiantes/dashboard',
	},
	{
		label: "Listar estudiantes",
		icon: "fas fa-list",
		roles: [ADMIN, DOCENTE, MANAGER],
		url: '/estudiantes/listar-estudiantes',
		items: [],
	}
]

const ITEMS_USARIOS_Y_ROLES = [
	{
		label: 'Listar usuarios',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN],
		url: '/listar-usuarios-roles',
	},
	{
		label: "Listar roles",
		icon: "fas fa-list",
		roles: [ADMIN],
		url: '/listar-roles',
	}
	,
	{
		label: "Crear cuentas (Free)",
		icon: "fas fa-list",
		roles: [ADMIN],
		url: '/crear-cuenta',
	}
]

const ITEMS_AFILIADOS = [
	{
		label: 'Listar afiliados',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN],
		url: '/listar-afiliados',
	},
]


const ITEMS_FACTURAS = [
	{
		label: 'Solicitudes de facturación',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, CONTABILIDAD],
		url: '/facturas/listar',
	},
	/*{
		label: "Subir facturas",
		icon: "fas fa-upload",
		roles: [ADMIN, CONTABILIDAD],
		url: '/facturas/subir',
		items: [],
	}*/
]

const ITEMS_INCIDENCIAS = [
	{
		label: 'Dashboard incidencias',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, MANAGER],
		url: '/incidencias/dashboard',
		items: [],
	},
	{
		label: 'Listar incidencias',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, MANAGER],
		url: '/incidencias/listar',
	}
]

const ITEMS_METRICAS = [
	{
		label: 'Dashboard',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, MARKETING],
		url: '/metricas/dashboard',
	},
	{
		label: "Registro de usuarios",
		icon: "fas fa-user-plus",
		roles: [ADMIN, MARKETING],
		url: '/metricas/registro-usuarios',
	},
	{
		label: "Usuarios con plan",
		icon: "fas fa-user-plus",
		roles: [ADMIN, MARKETING],
		url: '/metricas/usuarios-con-plan'
	},
	{
		label: 'Inscripciones',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, MARKETING],
		url: '/metricas/inscripciones',
	}
]

const ITEMS_LIVES = [
	{
		label: 'Listar lives',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, MANAGER],
		url: '/lives/listar',
	},
]

const ITEMS_ENVIVO = [
	{
		label: 'Listar En vivos',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, MANAGER],
		url: '/lives/listar-envivos',
	},
	{
		label: 'Crear En vivo',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, MANAGER],
		url: '/lives/crear',
	},

]

const ITEMS_MARKETING = [
	{
		label: 'Listar campañas',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, MARKETING],
		url: '/marketing/listar',
	},
	{
		label: 'Listar publicaciones',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, MARKETING],
		url: '/marketing/posts/listar',
	},
	{
		label: 'Analítica Web',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, MARKETING],
		url: '/marketing/analytics',
	}
]

const ITEMS_CONVOCATORIAS = [
	{
		label: 'Listar convocatorias',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, MANAGER],
		url: '/convocatorias/listar',
	}
]


const ITEMS_CONTABILIDAD = [
	{
		label: 'Dashboard',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, CONTABILIDAD],
		url: '/contabilidad/dashboard',
	},
	{
		label: 'Ingresos',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, CONTABILIDAD],
		url: '/contabilidad/ingresos',
	},
	{
		label: 'Gastos',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, CONTABILIDAD],
		url: '/contabilidad/gastos',
	}
]

const ITEMS_NETPROMOTERSCORE = [
	{
		label: 'Dashboard',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN],
		url: '/nps'
	},
]

const ITEMS_AJUSTES = [
	{
		label: 'Manejar Roles',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN],
		url: '/ajustes/roles',
	}
]

const ITEMS_INGRESOS = [
	{
		label: 'Mis ingresos',
		icon: 'fas fa-dollar-sign',
		roles: [DOCENTE],
		url: '/ingresos-docente'
	},
	{
		label: 'Mis comisiones',
		icon: 'fas fa-dollar-sign',
		roles: [DOCENTE],
		url: '/comisiones-docente'
	}
]


/* ADMIN MENU */

const ITEMS_BANNER = [
	{
		label: 'Ver banners',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN],
		url: '/listar-banners',
	},
	{
		label: 'Crear Banner',
		icon: 'fas fa-chart-bar',
		roles: [ADMIN, CONTABILIDAD],
		url: '/crear-anuncio',
	},
]

export const ADMIN_MENU =[
	
	/* Home Menu */
	
	{
		title: "Home",
		icon: "fas fa-home",
		roles: [ADMIN, DOCENTE, MARKETING, MANAGER, CONTABILIDAD],
		url: "/",
		items: [],
	},
	
	/* Escuela Menu */
	{
		title: "Escuelas",
		icon: "fas fa-chalkboard",
		roles: [ADMIN, MANAGER],
		url: "nav-menu",
		items: [...ITEMS_ESCUELA],
	},

	/* Rutas */
	{
		title: "Rutas",
		icon: "fas fa-route",
		roles: [ADMIN, MANAGER],
		url: "nav-menu",
		items: [...ITEMS_RUTAS],
	},

	/* Cursos Menu */
	{
		title: "Cursos",
		icon: "fas fa-book",
		roles: [ADMIN, MANAGER],
		url: "nav-menu",
		items: [...ITEMS_CURSO],
	},
	
	/* Estudiantes Menu */
	{
		title: "Estudiantes",
		icon: "fas fa-user-circle",
		roles: [ADMIN, DOCENTE, MANAGER],
		url: "nav-menu",
		items:[...ITEMS_ESTUDIANTES],
	},

	/* Usuarios y roles Menu */
	{
		title: "Usuarios y roles",
		icon: "fas fa-user-shield",
		roles: [ADMIN],
		url: "nav-menu",
		items:[...ITEMS_USARIOS_Y_ROLES],
	},

	/* Afiliados */
	{
		title: "Afiliados",
		icon: "fas fa-user-check",
		roles: [ADMIN],
		url: "nav-menu",
		items: [...ITEMS_AFILIADOS],
	},

	/* Docentes Menu */
	{
		title: "Docentes",
		icon: "fas fa-users",
		roles: [ADMIN, MANAGER],
		url: "nav-menu",
		items: [...ITEMS_DOCENTE],
	},
	
	
	/* Blog Historias Menu */
	{
		title: "Blog e Historias",
		icon: "fas fa-folder",
		roles: [ADMIN, DOCENTE, MARKETING, MANAGER],
		url: "nav-menu",
		items: [...ITEMS_BLOG_HISTORIAS],
	},

	/* Ingresos */
	{
		title: "Mis ingresos",
		icon: "fas fa-dollar-sign",
		roles: [DOCENTE],
		url: "nav-menu",
		items: [...ITEMS_INGRESOS],
	},

	/* Planes */
	{
		title: "Planes",
		icon: "fas fa-file-invoice-dollar",
		roles: [ADMIN],
		url: "nav-menu",
		items: [...ITEMS_PLANES],
	},
	
	/* Suscripciones y Cursos */
	{
		title: "Suscripciones y Cursos",
		icon: "fas fa-folder-open",
		roles: [ADMIN, CONTABILIDAD, MARKETING, MANAGER],
		url: "nav-menu",
		items: [...ITEMS_SUSCRIPCIONES_CURSOS],
	},

	/* Cupones */
	{
		title: "Cupones",
		icon: "fas fa-folder-open",
		roles: [ADMIN,MANAGER],
		url: "nav-menu",
		items: [...ITEMS_CUPONES],
	},
	
	/* Factura Menu */
	{
		title: "Facturas",
		icon: "fas fa-file-invoice",
		roles: [ADMIN, CONTABILIDAD],
		url: "nav-menu",
		items: [...ITEMS_FACTURAS],
	},
	
	/* Incidencias Menu */
	{
		title: "Incidencias",
		icon: "fas fa-exclamation-triangle",
		roles: [ADMIN, MANAGER],
		url: "nav-menu",
		items: [...ITEMS_INCIDENCIAS],
	},
	
	/* Métricas Menu */
	{
		title: "Métricas",
		icon: "fas fa-chart-bar",
		roles: [ADMIN, MARKETING],
		url: "nav-menu",
		items: [...ITEMS_METRICAS],
	},
	
	/* Lives Menu */
	{
		title: "Lives",
		icon: "fas fa-video",
		roles: [ADMIN, MANAGER],
		url: "nav-menu",
		items: [...ITEMS_LIVES],
	},

	/* En Vivos Menu */
	{
		title: "En Vivo (Home)",
		icon: "fas fa-tv",
		roles: [ADMIN, MANAGER],
		url: "nav-menu",
		items: [...ITEMS_ENVIVO],
	},
		
	
	/* Marketing Menu */
	{
		title: "Marketing",
		icon: "fas fa-bullhorn",
		roles: [ADMIN, MARKETING],
		url: "nav-menu",
		items: [...ITEMS_MARKETING],
	},
	
	/* Convocatorias Menu */
	{
		title: "Convocatorias laborales",
		icon: "fas fa-briefcase",
		roles: [ADMIN, MANAGER],
		url: "nav-menu",
		items: [...ITEMS_CONVOCATORIAS],
	},
	
	/* Contabilidad Menu */
	{
		title: "Contabilidad",
		icon: "fas fa-chart-line",
		roles: [ADMIN, CONTABILIDAD],
		url: "nav-menu",
		items: [...ITEMS_CONTABILIDAD],
	},

	/* Net Promoter Score */
	{
		title: 'Net promoter Score',
		icon: 'fas fa-poll',
		roles: [ADMIN],
		url: 'nav-menu',
		items: [...ITEMS_NETPROMOTERSCORE],
	},
	/* Banner Publicidad */
	
	{
		title: "Banner",
		icon: "fas fa-images",
		roles: [ADMIN],
		url: "nav-menu",
		items: [ ...ITEMS_BANNER]
	},

	/* Ajustes Menu */
	{
		title: "Ajustes",
		icon: "fas fa-cog",
		roles: [ADMIN],
		url: "nav-menu",
		items: [...ITEMS_AJUSTES],
	}
];