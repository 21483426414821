import React, { useEffect, useState } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { SwalToast } from "../../../utils/toasts";
import { getSelectStatePosts } from "../../../controllers/marketing/posts/facebookPosts.controller";

const validationSchema = yup.object({
    id_publicacion: yup.string().required("El id de la publicación es requerida"),
    titulo: yup.string().required("El titulo es requerido"),
    descripcion: yup.string().required("La descripción es requerida"),
    estado: yup.string().required("El estado es requerido"),
});

const ModalCreatePost = ({ show, setShow, useMutateCreate }) => {
    
    const [selectStatePosts, setSelectStatePosts] = useState([]);
    const { mutate: createPost, isLoading } = useMutateCreate();
    
    /* Traer select de tipos de campaña */
    const fetchData = async () => {
        const response = await getSelectStatePosts();
        if(response) {
            setSelectStatePosts(response);
        }
    }
    
    
    /* Formik hook */
    const formik = useFormik({
        initialValues: {
            id_publicacion: "",
            titulo: "",
            descripcion: "",
            estado: "",
        } ,
        validationSchema,
        onSubmit: (values) => {
            createPost(
                values,
                {
                    onSuccess: () => {
                        formik.resetForm();
                        SwalToast.fire({
                            icon: "success",
                            title: "Publicación registrada con éxito",
                        });
                        setShow(false);
                    },
                    onError: (error) => {
                        SwalToast.fire({
                            icon: "error",
                            title: "Error al registrar la publicación",
                        });
                    }
                }
            );
        }
    });
    
    const onHide = () => {
        formik.resetForm();
        setShow(false);
    }
    
    useEffect(() => {
        fetchData();
        return () => {
            setSelectStatePosts([]);
        }
    } , []);
    
	return (
		<Modal
			show={show}
			onHide={onHide}
			backdrop="static"
			size="md"
			centered
		>
            <Modal.Header className="ge-bg-primary text-white" closeButton>
                <h5>Registrar publicación</h5>
            </Modal.Header>
			<Modal.Body>
				<Form 
                    className="row"
                    onSubmit={formik.handleSubmit}
                >
                    <Form.Group className="col-12 my-2">
                        <Form.Label className="mb-1">Título:</Form.Label>
                        <Form.Control
                            className="ge-form"
                            type="text"
                            placeholder="Título de la publicación"
                            autoComplete="off"
                            name="titulo"
                            onChange={formik.handleChange}
                            value={formik.values.titulo}
                            isValid={formik.touched.titulo && !formik.errors.titulo}
                            isInvalid={formik.touched.titulo && formik.errors.titulo}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.titulo}
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group className="col-12 my-2">
                        <Form.Label className="mb-1">Descripción:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            className="ge-form"
                            type="text" 
                            placeholder="Descripción de la publicación"
                            name="descripcion"
                            onChange={formik.handleChange}
                            value={formik.values.descripcion}
                            isValid={formik.touched.descripcion && !formik.errors.descripcion}
                            isInvalid={formik.touched.descripcion && formik.errors.descripcion}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.descripcion}
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group className="col-12 my-2">
                        <Form.Label className="mb-1">ID de la publicación:</Form.Label>
                        <Form.Control
                            className="ge-form"
                            type="text"
                            placeholder="ID de la publicación"
                            autoComplete="off"
                            name="id_publicacion"
                            onChange={formik.handleChange}
                            value={formik.values.id_publicacion}
                            isValid={formik.touched.id_publicacion && !formik.errors.id_publicacion}
                            isInvalid={formik.touched.id_publicacion && formik.errors.id_publicacion}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.id_publicacion}
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group
						className="col-12 my-2"
					>
						<Form.Label className="mb-1">Estado de la publicación</Form.Label>
						<Form.Select
							className="form-control ge-form"
							name="estado"
                            onChange={formik.handleChange}
                            value={formik.values.estado}
                            isValid={formik.touched.estado && !formik.errors.estado}
                            isInvalid={formik.touched.estado && formik.errors.estado}
						>
							<option 
                                value=""
                                disabled
                            >
                                -- Seleccione un estado --
                            </option>
							{
                                selectStatePosts.length > 0 && selectStatePosts.map((item, index) => (
                                    <option key={item.id_registro} value={item.id_registro}>{item.nombre}</option>
                                ))
                            }
						</Form.Select>
						<Form.Control.Feedback type="invalid">
							{formik.errors.estado}
						</Form.Control.Feedback>
					</Form.Group>
                    
                    <div className="col-12 my-3 d-flex justify-content-center justify-content-md-end">
                        <button 
                            className="btn ge-btn-primary"
                            type="submit"
                        >
                            {
                                isLoading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </>
                                ) : (
                                    <span>
                                        Registrar publicación
                                    </span>
                                )
                            }
                        </button>
                    </div>
                </Form>
			</Modal.Body>
		</Modal>
	);
};

export default ModalCreatePost;
