import React from "react";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import ChartBarUsersPlan from "../../components/Metricas/ChartBarUsersPlan";
import UsersPlanTable from "../../components/Metricas/UsersPlanTable";

const UsuariosPlan = () => {
	return (
		<LayoutAdmin contentTitle="" routeTitle="Registro de Usuarios">
			<div className="row">
                <div className="col-12">
                    <UsersPlanTable />
                </div>
				<div className="col-12">
					<ChartBarUsersPlan />
				</div>
            </div>
		</LayoutAdmin>
	);
};

export default UsuariosPlan;
