import React from 'react';

const CustomContainer = WrappedComponent => props => {
  
  const{show, handleShow, children, title} = props

  const actualizarEstado = () => {
    handleShow(!show)
  }

  return ( 
    <WrappedComponent
      {...props}
      show={show}
      updateShowModal={actualizarEstado}
      children={children}
      title={title}
    />
   );
}
 
export default CustomContainer;