import AdminScreen from "../pages/AdminScreen";
import CreatePost from "../pages/Blog/CreatePost";
import EditPost from "../pages/Blog/EditPost";
import ListarPosts from "../pages/Blog/ListarPosts";
import ListaDocentesScreen from "../pages/ListaDocentesScreen";
import HomeScreen from "../pages/HomeScreen";
import DashboardDocentesScreen from "../pages/Docentes/DashboardDocentesScreen";
import CreateDocenteScreen from "../pages/Docentes/CreateDocenteScreen";
import ListarEscuelas from "../pages/Escuelas/ListarEscuelas";
import EditEscuela from "../pages/Escuelas/EditEscuela";
import CreateEscuela from "../pages/Escuelas/CreateEscuela";
import ListarRutas from "../pages/Rutas/ListarRutas";
import EditRuta from "../pages/Rutas/EditRuta";
import CreateRuta from "../pages/Rutas/CreateRuta";
import EditDocenteScreen from "../pages/Docentes/EditDocenteScreen";
import ListarCursosScreen from "../pages/Cursos/ListarCursosScreen";
import EditarCursoScreen from "../pages/Cursos/EditarCursoScreen";
import EditarModuloScreen from "../pages/Cursos/Modulos/EditarModuloScreen";
import EditarClaseScreen from "../pages/Clase/EditarClaseScreen";
import ListarEstudiantesScreen from "../pages/Estudiantes/ListarEstudiantesScreen";
import DashboardEstudiantes from "../pages/Estudiantes/DashboardEstudiantes";
import ListarPlanes from "../pages/Planes/ListarPlanes";
import Dashboard from "../pages/SuscripcionesCursos/Dashboard";
import ListarSuscripciones from "../pages/SuscripcionesCursos/ListarSuscripciones";
import ListarCursos from "../pages/SuscripcionesCursos/ListarCursos";
import EditHistoria from "../pages/Historias/EditHistoria";
import CreateHistoria from "../pages/Historias/CreateHistoria";
import SubirFacturaScreen from "../pages/Facturas/SubirFacturaScreen";
import ListarFacturasScreen from "../pages/Facturas/ListarFacturasScreen";
import DashboardPH from "../pages/Historias/DashboardPH";
import ListaIncidenciasScreen from "../pages/Incidencias/ListaIncidenciasScreen";
import MetricasDashboard from "../pages/Metricas/MetricasDashboard";
import RegistroUsuarios from "../pages/Metricas/RegistroUsuarios";
import UsuariosPlan from "../pages/Metricas/UsuariosPlan";
import Inscripciones from "../pages/Metricas/Inscripciones";
import TabHistorias from "../pages/Historias/TabHistorias";
import EditarPortadaEscuela from "../pages/Escuelas/EditarPortadaEscuela";
import ListaLives from "../pages/Lives/ListaLives";
import DetalleIncidenciaScreen from "../pages/Incidencias/DetalleIncidenciaScreen";
import DashboardIncidencias from "../pages/Incidencias/DashboardIncidencias";
import ListaCampaignsScreen from "../pages/Marketing/ListaCampaignsScreen";
import ListaPostsScreen from "../pages/Marketing/ListaPostsScreen";
import AnaliticaWebScreen from "../pages/Marketing/AnaliticaWebScreen";
import ListaConvocatoriasScreen from "../pages/Convocatorias/ListaConvocatoriasScreen";
import ListaIngresos from "../pages/Contabilidad/Ingresos/ListaIngresos";
import { roles } from "../utils/constants";
import Roles from "../pages/Ajustes/Roles";
import Banners from "../pages/Banner/BannerAll";
import GastosScreen from "../pages/Contabilidad/Gastos/GastosScreen";
import CrearGasto from "../pages/Contabilidad/Gastos/CrearGasto";
import AdministrarBeneficios from "../pages/Planes/AdministrarBeneficios";
import HabilidadesDocente from "../pages/Docentes/HabilidadesDocente";
import ListaRoles from "../pages/UsariosRoles/ListaRoles";
import ListaUsuariosRoles from "../pages/UsariosRoles/ListaUsuariosRoles";
import ListarAfiliados from "../pages/Afiliados/ListarAfiliados";
import IngresosDocente from "../pages/Ingresos/IngresosDocente";
import NetPromoterScore from "../pages/NPS/NetPromoterScore";
import ComisionesDocente from "../pages/Ingresos/ComisionesDocente";
import CrearLivesV2 from "../pages/Lives/CrearLivesV2";
import ListarEnVivos from "../pages/Lives/ListarEnVivos";
import EditLives from "../pages/Lives/EditLives";
import ListarCupones from "../pages/Cupones/ListarCupones";
import CreateUserPlanFree from "../pages/Usuario/CreateUserPlanFree";
import CreateAnuncio from "../pages/Anuncios/CreateAnuncio";
import ListarAnuncios from "../pages/Anuncios/ListarAnuncios";
import EditAnuncio from "../pages/Anuncios/EditAnuncio";

const { ADMIN, DOCENTE, MARKETING, MANAGER, CONTABILIDAD } = roles;

export const pagesByRole = [
  /* Home */
  {
    path: "/",
    component: HomeScreen,
    allowedRoles: [ADMIN, DOCENTE, MARKETING, MANAGER, CONTABILIDAD],
  },

  /* Escuelas */
  {
    path: "/listar-escuelas",
    component: ListarEscuelas,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/editar-escuela/:uId",
    component: EditEscuela,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/crear-escuela",
    component: CreateEscuela,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/escuela/portada",
    component: EditarPortadaEscuela,
    allowedRoles: [ADMIN, MANAGER],
  },

  /* Rutas */
  {
    path: "/listar-rutas",
    component: ListarRutas,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/editar-ruta/:uId",
    component: EditRuta,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/crear-ruta",
    component: CreateRuta,
    allowedRoles: [ADMIN, MANAGER],
  },

  /* Cursos Module */
  {
    path: "/listar-cursos",
    component: ListarCursosScreen,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/editar-curso/:slugCurso",
    component: EditarCursoScreen,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/editar-modulo/:idModulo",
    component: EditarModuloScreen,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/clases/editar-clase/:idClase",
    component: EditarClaseScreen,
    allowedRoles: [ADMIN, MANAGER],
  },

  /* Estudiantes */
  {
    path: "/estudiantes/dashboard",
    component: DashboardEstudiantes,
    allowedRoles: [ADMIN, DOCENTE, MANAGER],
  },
  {
    path: "/estudiantes/listar-estudiantes",
    component: ListarEstudiantesScreen,
    allowedRoles: [ADMIN, DOCENTE, MANAGER],
  },

  /* Usuarios y Roles */
  {
    path: "/listar-roles",
    component: ListaRoles,
    allowedRoles: [ADMIN],
  },
  {
    path: "/listar-usuarios-roles",
    component: ListaUsuariosRoles,
    allowedRoles: [ADMIN],
  },

  /* Afiliados */
  {
    path: "/listar-afiliados",
    component: ListarAfiliados,
    allowedRoles: [ADMIN],
  },


  /* DocentesModule */
  {
    path: "/listar-docentes",
    component: ListaDocentesScreen,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/listar-docentes/habilidades-docente/:id_docente",
    component: HabilidadesDocente,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/dashboard-docentes",
    component: DashboardDocentesScreen,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/crear-docente",
    component: CreateDocenteScreen,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/editar-docente",
    component: EditDocenteScreen,
    allowedRoles: [ADMIN, MANAGER],
  },

  /* Ingresos */
  {
    path: "/ingresos-docente",
    component: IngresosDocente,
    allowedRoles: [DOCENTE]
  },
  /*Comisiones docentes*/
  {
    path: "/comisiones-docente",
    component: ComisionesDocente,
    allowedRoles: [DOCENTE]
  },

  /* Blog e Historias */
  {
    path: "/listar-historias",
    component: TabHistorias,
    allowedRoles: [ADMIN, DOCENTE, MARKETING, MANAGER],
  },
  {
    path: "/dashboard-post-historias",
    component: DashboardPH,
    allowedRoles: [ADMIN, DOCENTE, MARKETING, MANAGER],
  },
  {
    path: "/listar-posts",
    component: ListarPosts,
    allowedRoles: [DOCENTE, ADMIN, MANAGER, MARKETING],
  },
  {
    path: "/editar-historia/:uId",
    component: EditHistoria,
    allowedRoles: [ADMIN, DOCENTE, MARKETING, MANAGER],
  },
  {
    path: "/crear-historia",
    component: CreateHistoria,
    allowedRoles: [ADMIN, DOCENTE, MARKETING, MANAGER],
  },
  {
    path: "/editar-post/:uId",
    component: EditPost,
    allowedRoles: [ADMIN, MARKETING, DOCENTE, MANAGER],
  },
  {
    path: "/crear-post",
    component: CreatePost,
    allowedRoles: [DOCENTE, ADMIN, MARKETING, MANAGER],
  },

  /* Planes */
  {
    path: "/listar-planes",
    component: ListarPlanes,
    allowedRoles: [ADMIN],
  },
  {
    path: "/listar-planes/administrar-beneficios/:idPlan",
    component: AdministrarBeneficios,
    allowedRoles: [ADMIN],
  },

  /* Suscripciones y cursos */
  {
    path: "/dashboard-suscripciones-cursos",
    component: Dashboard,
    allowedRoles: [ADMIN, MARKETING, CONTABILIDAD, MANAGER],
  },
  {
    path: "/listar-ventas-suscripciones",
    component: ListarSuscripciones,
    allowedRoles: [ADMIN, MARKETING, CONTABILIDAD, MANAGER],
  },
  {
    path: "/listar-ventas-cursos",
    component: ListarCursos,
    allowedRoles: [ADMIN, MARKETING, CONTABILIDAD, MANAGER],
  },

  /* Factura */
  {
    path: "/facturas/subir",
    component: SubirFacturaScreen,
    allowedRoles: [ADMIN, CONTABILIDAD],
  },
  {
    path: "/facturas/listar",
    component: ListarFacturasScreen,
    allowedRoles: [ADMIN, CONTABILIDAD],
  },

  /* Incidencias Module */
  {
    path: "/incidencias/dashboard",
    component: DashboardIncidencias,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/incidencias/listar",
    component: ListaIncidenciasScreen,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/incidencias/ver/:cod_unico",
    component: DetalleIncidenciaScreen,
    allowedRoles: [ADMIN, MANAGER],
  },

  /* Métricas Module */
  {
    path: "/metricas/dashboard",
    component: MetricasDashboard,
    allowedRoles: [ADMIN, MARKETING],
  },
  {
    path: "/metricas/registro-usuarios",
    component: RegistroUsuarios,
    allowedRoles: [ADMIN, MARKETING],
  },
  {
    path: "/metricas/usuarios-con-plan",
    component: UsuariosPlan,
    allowedRoles: [ADMIN, MARKETING],
  },
  {
    path: "/metricas/inscripciones",
    component: Inscripciones,
    allowedRoles: [ADMIN, MARKETING],
  },

  /* Lives */
  {
    path: "/lives/listar",
    component: ListaLives,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/lives/crear",
    component: CrearLivesV2,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/lives/editar-envivo/:uId",
    component: EditLives,
    allowedRoles: [ADMIN, MANAGER],
  },
  {
    path: "/lives/listar-envivos",
    component: ListarEnVivos,
    allowedRoles: [ADMIN, MANAGER],
  },
  /* Marketing */
  {
    path: "/marketing/listar",
    component: ListaCampaignsScreen,
    allowedRoles: [ADMIN, MARKETING, MANAGER],
  },
  {
    path: "/marketing/posts/listar",
    component: ListaPostsScreen,
    allowedRoles: [ADMIN, MARKETING, MANAGER],
  },
  {
    path: "/marketing/analytics",
    component: AnaliticaWebScreen,
    allowedRoles: [ADMIN, MARKETING, MANAGER],
  },

  /* Convocatorias Laborales */
  {
    path: "/convocatorias/listar",
    component: ListaConvocatoriasScreen,
    allowedRoles: [ADMIN, MANAGER],
  },

  /* Contabilidad */
  {
    path: "/contabilidad/ingresos",
    component: ListaIngresos,
    allowedRoles: [ADMIN, CONTABILIDAD],
  },
  {
    path: "/contabilidad/gastos",
    component: GastosScreen,
    allowedRoles: [ADMIN, CONTABILIDAD],
  },
  {
    path: "/contabilidad/gastos/crear",
    component: CrearGasto,
    allowedRoles: [ADMIN, CONTABILIDAD],
  },

  /* Net Promoter Score */

  {
    path: "/nps",
    component: NetPromoterScore,
    allowedRoles: [ADMIN],
  },
  /* Banner Publicidad */
  {
    path: "/listar-banners",
    component: ListarAnuncios,
    allowedRoles: [ADMIN],
  },

  /* Ajustes Module */
  {
    path: "/ajustes/roles",
    component: Roles,
    allowedRoles: [ADMIN],
  },

  /* Admin Module */
  {
    path: "/administrador",
    component: AdminScreen,
    allowedRoles: [ADMIN],
  },
  
  /* Cupon Module */
  {
    path: "/cupones",
    component: ListarCupones,
    allowedRoles: [ADMIN,MANAGER],
  },
    /* Cupon Module */
    {
      path: "/crear-cuenta",
      component: CreateUserPlanFree,
      allowedRoles: [ADMIN,MANAGER],
    },
    /* Top Anounce Module */
    {
      path: "/crear-anuncio",
      component: CreateAnuncio,
      allowedRoles: [ADMIN,MANAGER],
    },
    /* Top Anounce Module */
    {
      path: "/editar-anuncio/:uId",
      component: EditAnuncio,
      allowedRoles: [ADMIN,MANAGER],
    },
];
