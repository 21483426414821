import Swal from "sweetalert2";
import { getAuthAxios } from "../../axios/axiosCustom";

export const getEscuelas = async () => {
	const axios = getAuthAxios();
	const response = await axios.get(`/escuela/getAll`);
	if (response.data.status) {
		return response.data.data;
	}
	throw new Error(response.data.message);
};

export const createEscuela = async (datos) => {
	const axios = getAuthAxios();
	const datos_file = new FormData();
	Object.keys(datos).forEach((key) => datos_file.append(key, datos[key]));
	const response = await axios.post(`/escuela/create_escuela`, datos_file);
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});

	if (response.data.status) {
		Toast.fire({
			icon: "success",
			title: response.data.message,
		});
		return response.data;
	}
	throw new Error(response.data.error);
};

export const getEscuelaByUid = async (uId) => {
	const axios = getAuthAxios();
	const response = await axios.get(`/escuela/getEscuela/${uId}`);
	if (response.data.status) {
		return response.data.data;
	}
	throw new Error(response.data.message);
};

export const addRuta = async (datos) => {
	const axios = getAuthAxios();
	const response = await axios.post(
		`/escuela/add_ruta/${datos.id_ruta}`,
		datos
	);
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});

	if (response.data.status) {
		Toast.fire({
			icon: "success",
			title: response.data.message,
		});
		return response.data;
	} else {
		Toast.fire({
			icon: "warning",
			title: response.data.message,
		});
		return response.data;
	}
};

export const deleteRuta = async ({ id_escuela, id_ruta }) => {
	const axios = getAuthAxios();
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});

	await Swal.fire({
		title: "Estas seguro?",
		text: "Una vez eliminado, no se puede revertir!",
		icon: "warning",
		showCancelButton: true,
		confirmButtonText: "Si",
		cancelButtonText: "No",
	}).then(async (result) => {
		if (result.isConfirmed) {
			const response = await axios.delete(
				`/escuela/delete_ruta/${id_escuela}/${id_ruta}`
			);

			if (response.data.status) {
				Toast.fire({
					icon: "success",
					title: response.data.message,
				});
			} else {
				Toast.fire({
					icon: "warning",
					title: response.data.message,
				});
			}
		} else if (
			/* Read more about handling dismissals below */
			result.dismiss === Swal.DismissReason.cancel
		) {
		}
	});
};

export const updateEscuela = async (datos) => {
	const axios = getAuthAxios();
	const datos_file = new FormData();
	Object.keys(datos).forEach((key) => datos_file.append(key, datos[key]));
	const response = await axios.post(`/escuela/edit_escuela`, datos_file);
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});

	if (response.data.status) {
		Toast.fire({
			icon: "success",
			title: response.data.message,
		});
		return response.data;
	}
	throw new Error(response.data.error);
};

export const toggleEscuela = async (datos) => {
	const axios = getAuthAxios();
	const response = await axios.put(
		`/escuela/delete_escuela/${datos.id_escuela}`,
		datos
	);
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});

	if (response.data.status) {
		Toast.fire({
			icon: "success",
			title: response.data.message,
		});
		return response.data;
	}
	throw new Error(response.data.error);
};

export const deleteEscuelaByid = async (id_escuela) => {
    const axios = getAuthAxios();
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
  
    await  Swal.fire({
        title: '¿Estás seguro?',
        text: "Una vez eliminado, no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
            const response = await axios.delete(`/escuela/delete_escuela_admin/${id_escuela}`);
              
            if(response.data.status){
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  })
            }
            else{
                Toast.fire({
                    icon: 'warning',
                    title: response.data.message
                  })
            }
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      })
  }

/* Portada Escuela */
export const getPortadaEscuela = async () => {
	try{
		const axios = getAuthAxios();
		const response = await axios.get(`/escuela/portada`);
		if (response.data.status) {
			return response.data.data;
		}
		return null;
	}catch(error){
		return null;
	}
}


export const editarPortadaEscuela = async (datos, idRegistro) => {
	
	try{
		const datos_file = new FormData();
		Object.keys(datos).forEach((key) => datos_file.append(key, datos[key]));
		const axios = getAuthAxios();
		const response = await axios.post(`/escuela/edit_portada/${idRegistro}`, datos_file, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		if (response.data.status) {
			return response.data;
		}
		return null;
	}
	catch(error){
		return null;
	}
}