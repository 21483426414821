import Swal from "sweetalert2";
import { getAuthAxios, getPublicAxios } from "../../axios/axiosCustom";

export const getHistorias = async () => {
	const axios = getAuthAxios();
	const response = await axios.get("/historias/getHistorias");
	if (response.data.status) {
		return response.data.data;
	}
	throw new Error(response.data.message);
};

export const getHistoriasByParams = async (datos) => {
	if (datos.fecha) {
		const axios = getAuthAxios();
		const response = await axios.get(
			`/historias/getHistoriasByParams/${datos.fecha.startDate}/${datos.fecha.endDate}`
		);
		if (response.data.status) {
			return response.data.data;
		}
		throw new Error(response.data.message);
	}
};

export const getHistoriasByUid = async (id) => {
	const axios = getAuthAxios();
	const response = await axios.get(`/historias/getHistorias/${id}`);
	if (response.data.status) {
		return response.data.data;
	}
	throw new Error(response.data.message);
};

export const createHistoria = async (datos) => {
	const axios = getAuthAxios();
	const datos_file = new FormData();
	Object.keys(datos).forEach((key) =>
		key !== "cursos"
			? datos_file.append(key, datos[key])
			: datos_file.append(key, JSON.stringify(datos[key]))
	);
	const response = await axios.post(`/historias/new_historia`, datos_file);
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});

	if (response.data.status) {
		Toast.fire({
			icon: "success",
			title: response.data.message,
		});
		return response.data;
	}
	throw new Error(response.data.error);
};

export const updateHistoria = async (datos) => {
	const axios = getAuthAxios();
	const datos_file = new FormData();
	Object.keys(datos).forEach((key) => datos_file.append(key, datos[key]));
	const response = await axios.post(`/historias/edit_historia`, datos_file);
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});

	if (response.data.status) {
		Toast.fire({
			icon: "success",
			title: response.data.message,
		});
		return response.data;
	}
	throw new Error(response.data.error);
};

export const disableHistory = async (datos) => {
	const axios = getAuthAxios();
	const response = await axios.put(
		`/historias/delete_historia/${datos.id}`,
		datos
	);
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});

	if (response.data.status) {
		Toast.fire({
			icon: "success",
			title: response.data.message,
		});
		return response.data;
	}
	throw new Error(response.data.error);
};

export const add_curso = async (datos) => {
	const axios = getAuthAxios();
	const response = await axios.post(`/historias/add_curso`, datos);
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});

	if (response.data.status) {
		Toast.fire({
			icon: "success",
			title: response.data.message,
		});
		return response.data;
	}
	throw new Error(response.data.error);
};

export const delete_curso = async (datos) => {
	const axios = getAuthAxios();
	const response = await axios.delete(
		`/historias/delete_curso/${datos.id_historia}/${datos.id_curso}`
	);
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});

	if (response.data.status) {
		Toast.fire({
			icon: "success",
			title: response.data.message,
		});
		return response.data;
	}
	throw new Error(response.data.error);
};


export const traer_info_portada = async () => {
	
	try{
		const axios = getPublicAxios();
		const response = await axios.get(`/historias/portada_historia`);
		
		if (response.data.status) {
			return response.data.data;
		}else{
			return null;
		}
	}catch(error){
		return null;
	}
}


export const actualizar_info_portada = async (datos, id_info) => {
	try{
		const axios = getAuthAxios();
		const datos_file = new FormData();
		Object.keys(datos).forEach((key) => datos_file.append(key, datos[key]));
		// console.log('formdata',datos_file);
		const response = await axios.post(`/historias/update_portada/${id_info}`, datos_file,{
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
		
		if (response.data.status) {
			return response.data;
		}
		return null;
	}catch(error){
		return null;
	}
}