import { useFormik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { updateEscuela } from "../../controllers/escuelas/escuelas";
import { useMutation, useQueryClient } from "react-query";

const DatosEscuela = ({ escuela = null }) => {
	const queryClient = useQueryClient();

	const {
		mutate,
		error: errorUpdate,
		isLoading: isLoadingUpdate,
	} = useMutation(updateEscuela, {
		onSuccess: () => {
			queryClient.invalidateQueries(["getEscuelas"]);
			queryClient.refetchQueries(["getEscuela"]);
		},
	});

	const initialValues = {
		cod_unico: "",
		nombre_escuela: "",
		descripcion: "",
		imagen_portada: "",
		archivo: {},
	};

	const [datosEscuela, setDatosEscuela] = useState(initialValues);
	const [renderImage, setRenderImage] = useState(null);

	const SUPPORTED_FORMATS = [
		"image/jpg",
		"image/jpeg",
		"image/gif",
		"image/png",
		"application/pdf",
	];

	const validationSchema = yup.object({
		nombre_escuela: yup.string().required("El campo es requerido"),
		descripcion: yup.string().required("El campo es requerido").max(350, 'Debe tener 350 caracteres o menos'),
		archivo: yup
			.mixed()
			.notRequired()
			.test("fileFormat", "Archivo no permitido", (value) =>
				value ? SUPPORTED_FORMATS.includes(value.type) : true
			),
	});

	const formik = useFormik({
		initialValues: datosEscuela,
		validationSchema,
		onSubmit: async (values) => {
			const datos = { ...values };
			// console.log(datos)
			//  datos.contenido=contenidoHTML
			mutate(datos);
		},
	});

	useEffect(() => {
		if (formik.values.archivo && formik.values.archivo.name) {
			setRenderImage(URL.createObjectURL(formik.values.archivo));
		}
	}, [formik.values.archivo]);

	useEffect(() => {
		if (escuela) {
			setDatosEscuela(escuela);
		}
	}, [escuela]);

	useEffect(() => {
		if (datosEscuela) {
			formik.setValues(datosEscuela);
		}
	}, [datosEscuela]);

	return (
		<div className="card">
			<div className="card-body">
				<form onSubmit={formik.handleSubmit}>
					<div className="row">
						<div className="col-md-6">
							<div className="form-group mb-3">
								<label>Nombre escuela:</label>
								<input
									type="text"
									className="form-control"
									placeholder="Nombre escuela"
									name="nombre_escuela"
									onChange={formik.handleChange}
									value={formik.values.nombre_escuela}
								/>
								<p> {formik.errors.nombre_escuela} </p>
							</div>
							<div className="form-group mb-3">
								<label>Descripción</label>
								<textarea
									className="form-control"
									placeholder="Descripción"
									name="descripcion"
									onChange={formik.handleChange}
									value={formik.values.descripcion}
									maxLength="350"
								/>
								<p className={formik.values.descripcion.length != 350 ? "" : "text-danger"}>
									{formik.values.descripcion.length}/350
								</p>
							</div>
							<div className="form-group mb-3">
								<label>Imagen de Portada:</label>
								<div className="text-center my-4">
									<img
										src={
											datosEscuela.imagen_portada !== ""
												? process.env
														.REACT_APP_API_URL +
												  datosEscuela.imagen_portada
												: ""
										}
										alt={"..."}
										style={{
											objectFit: "cover",
											maxWidth: "340px",
										}}
									/>
								</div>
								<label>Cambiar por:</label>
								<input
									className="form-control"
									id="Voucher"
									type="file"
									name="archivo"
									onChange={(e) => {
										formik.setFieldValue(
											"archivo",
											e.target.files[0]
										);
									}}
								/>
								<p> {formik.errors.archivo} </p>
								<div className="text-center my-4">
									{formik.values.archivo ? (
										formik.values.archivo.name ? (
											<img
												src={renderImage}
												alt={"..."}
												style={{
													objectFit: "cover",
													maxWidth: "340px",
												}}
											/>
										) : null
									) : null}
								</div>
							</div>
							<div className="form-group">
								<button
									type="submit"
									className="btn ge-btn-primary btn-block"
								>
									Actualizar datos
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default DatosEscuela;
