import React, { useEffect } from "react";
import GraficoCursos from "../../../pages/SuscripcionesCursos/GraficoCursos";
import GraficoSuscripciones from "../../../pages/SuscripcionesCursos/GraficoSuscripciones";
import dayjs from "dayjs";
import { Tab, Tabs } from "react-bootstrap";
import RedesSociales from "./RedesSociales";

const HomeMarketing = () => {
    
    const fechaInicioMes = dayjs().startOf("month").$d;
    const fechaActual = dayjs().$d;
    
	return (
        <Tabs>
            <Tab eventKey="graficoCursos" title="Cursos">
                <GraficoCursos
                    initialState={[
                        fechaInicioMes,
                        fechaActual
                    ]}
                />
            </Tab>
            <Tab eventKey="graficoSuscripciones" title="Suscripciones">
                <GraficoSuscripciones
                    initialState={[
                        fechaInicioMes,
                        fechaActual
                    ]}
                />
            </Tab>
            <Tab eventKey="graficoRedesSociales" title="Redes sociales">
                <RedesSociales />
            </Tab>
        </Tabs>
	);
};

export default HomeMarketing;
