import React, { useState, useMemo } from "react";
import PagosDocente from "./PagosDocente";
import {
  useQueryPagosDocentes,
  useQueryPagarDocente,
} from "../../hooks/docentes.hooks";
import DataTable from "react-data-table-component";
import * as FaIcons from "react-icons/fa";
import LoaderSmall from "../../components/Loaders/LoaderSmall";
import { Badge } from "react-bootstrap";
import { SwalToast } from "../../utils/toasts";

const TablePagosDocentes = ({ idDocente }) => {
  const { data, isLoading } = useQueryPagosDocentes(idDocente);
  const { mutate } = useQueryPagarDocente();

  /* Config Table */
  const columns = useMemo(
    () => [
      {
        name: "ID Reporte",
        cell: (row) => row.id_registro,
        width: "8%",
      },
      {
        name: "Fecha",
        selector: (row) => new Date(row.fecha_registro).toLocaleDateString(),
        sortable: true,
        width: "11%",
      },
      {
        name: "Concepto de pago",
        selector: (row) =>
          row.id_tipo_pago_docente === "1"
            ? "Pago fijo"
            : row.id_tipo_pago_docente === "2"
            ? "% venta directa de curso"
            : "% venta de membresía",
        sortable: true,
      },
      {
        name: "Monto a pagar",
        selector: (row) => <p>{`s/${row.monto}.00`}</p>,
        sortable: true,
      },
      {
        name: "Estado",
        selector: (row) =>
          row.estado != 0 ? (
            <Badge bg="success" style={{ fontSize: "12px" }}>
              Realizado
            </Badge>
          ) : (
            <Badge bg="danger" style={{ fontSize: "12px" }}>
              Pendiente
            </Badge>
          ),
        sortable: true,
      },
      {
        name: "Acciones",
        cell: (row) => (
          <div className="d-flex">
            <button
              className="btn ge-btn-primary btn-sm mr-2"
              onClick={() => handlePagarDocente(row.id_registro)}
            >
              <FaIcons.FaCheck color="success" size={"20px"} />
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handlePagarDocente = (id_registro) => {
    mutate(id_registro, {
      onSuccess: (data) => {
        SwalToast.fire({
          icon: "success",
          title: "Pago actualizado correctamente",
        });
      },
    });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card p-2">
          <PagosDocente data={data} />
        </div>
        <div className="card p-2">
          <div className="card-header ge-noborder"></div>
          <div className="card-body">
            <DataTable
              className="custom-datatable"
              columns={columns}
              data={data}
              pagination
              responsive
              fixedHeader
              fixedHeaderScrollHeight="300px"
              progressPending={isLoading}
              progressComponent={<LoaderSmall />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TablePagosDocentes;
