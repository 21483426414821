import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useQueryGetTipoDeGastos,
  useQueryGetDetallesGastos,
  useQueryGetSubDetallesGastos,
  useQueryGetDocumentosGastos,
  useMutationEnviarDatosGastos,
} from "../../../hooks/contabilidad/gastos/gastos.hooks";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";

const FormularioGastos = ({ setShowForm }) => {
  const { user } = useSelector((state) => state.user);

  const [dataGastos, setDataGastos] = useState({
    tipo_registro_compra: null,
    tipo_1: null,
    detalle: null,
    sub_detalle: null,
    fecha_emision: null,
    fecha_vencimiento: null,
    documento: null,
    retencion: 0,
    monto_neto: null,
    base_imponible: null,
    moneda: null,
    descripcion: null,
    conformidad_abono: 0,
    enlace_comprobante: null,
    observacion: null,
  });

  const { tipo_registro_compra, tipo_1, detalle } = dataGastos;

  const { data: tiposDeGastos, isLoading } = useQueryGetTipoDeGastos();
  const { data: DocumentoGastos, isLoading: DocumentoLoad } =
    useQueryGetDocumentosGastos();
  const { data: DetallesGastos, status: statusDetalle } =
    useQueryGetDetallesGastos(tipo_1);
  const { data: SubDetalleGastos, status: statusSubDetalle } =
    useQueryGetSubDetallesGastos(detalle);
  const { mutate } = useMutationEnviarDatosGastos();

  const handleChange = (e) => {
    const data = { ...dataGastos };
    data[e.target.name] = e.target.value;
    setDataGastos(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(
      { id_user: user.id_user, dataGastos },
      {
        onSuccess: (data) => {
          setShowForm(false);
          setDataGastos({
            tipo_registro_compra: null,
            tipo_1: null,
            detalle: null,
            sub_detalle: null,
            fecha_emision: null,
            fecha_vencimiento: null,
            documento: null,
            retencion: 0,
            monto_neto: null,
            base_imponible: null,
            moneda: null,
            descripcion: null,
            conformidad_abono: 0,
            enlace_comprobante: null,
            observacion: null,
          });
          Swal.fire({
            title: "Se registro el gasto correctamente",
            icon: "success",
          });
        },
      }
    );
  };

  return (
    <Form
      className="d-flex flex-column col-md-12"
      onSubmit={(e) => handleSubmit(e)}
    >
      <Form.Group className="d-flex align-items-center justify-content-start">
        <div className="d-flex flex-column col-md-3 align-items-center justify-content-center">
          <Form.Label>Tipo de registro</Form.Label>
          <Form.Select
            name="tipo_registro_compra"
            onChange={(e) => handleChange(e)}
            className="form-control"
          >
            <option value="" label="Escoge el tipo de registro"></option>
            <option value={1}>Factura</option>
            <option value={2}>Recibo por Honorarios</option>
          </Form.Select>
        </div>
        <div className="d-flex flex-column col-md-3 align-items-center justify-content-center">
          <Form.Label>Escoge el tipo de gasto</Form.Label>
          <Form.Select
            name="tipo_1"
            onChange={(e) => handleChange(e)}
            className="form-control"
          >
            <option value="" label="Escoge el tipo de gasto"></option>
            {!isLoading &&
              tiposDeGastos.map((v) => (
                <option key={v.tipo} value={v.id_costo_gasto}>
                  {v.tipo}
                </option>
              ))}
          </Form.Select>
        </div>
        <div className="d-flex flex-column col-md-3 align-items-center justify-content-center">
          <Form.Label>Selecciona el Detalle</Form.Label>

          <Form.Select
            name="detalle"
            onChange={(e) => handleChange(e)}
            className="form-control"
          >
            <option value="" label="Selecciona el Detalle"></option>
            {statusDetalle === "success" &&
              DetallesGastos.map((v) => (
                <option key={v.detalle} value={v.id}>
                  {v.detalle}
                </option>
              ))}
          </Form.Select>
        </div>
        <div className="d-flex flex-column col-md-3 align-items-center justify-content-center">
          <Form.Label>Selecciona el Sub-Detalle</Form.Label>

          <Form.Select
            name="sub_detalle"
            onChange={(e) => handleChange(e)}
            className="form-control"
          >
            <option value="" label="Selecciona el Sub-Detalle"></option>
            {statusSubDetalle === "success" &&
              SubDetalleGastos?.map((v) => (
                <option key={v.detalle} value={v.id}>
                  {v.detalle}
                </option>
              ))}
          </Form.Select>
        </div>
      </Form.Group>
      <Form.Group className="d-flex align-items-center justify-content-around mt-4">
        <div className="d-flex flex-column col-md-3 align-items-center justify-content-center">
          <Form.Label>Escoge el tipo de documento</Form.Label>
          <Form.Select
            name="documento"
            onChange={(e) => handleChange(e)}
            className="form-control"
          >
            <option value="" label="Escoge el tipo de documento"></option>
            {!DocumentoLoad &&
              DocumentoGastos.map((v) => (
                <option key={v.documento} value={v.id_registro}>
                  {v.documento}
                </option>
              ))}
          </Form.Select>
        </div>
        <div className="d-flex flex-column col-md-3 align-items-center justify-content-center">
          <Form.Label>Tipo de moneda</Form.Label>
          <Form.Select
            name="moneda"
            onChange={(e) => handleChange(e)}
            className="form-control"
          >
            <option value="" label="Escoge el tipo de moneda"></option>
            <option value={1}>USD</option>
            <option value={2}>PEN</option>
          </Form.Select>
        </div>
        <div className="d-flex flex-column col-md-2 align-items-center justify-content-center">
          <Form.Label>Fecha de emisión</Form.Label>
          <Form.Control
            type="date"
            name="fecha_emision"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="d-flex flex-column col-md-2 align-items-center justify-content-center">
          <Form.Label>Fecha de vencimiento</Form.Label>
          <Form.Control
            type="date"
            name="fecha_vencimiento"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </Form.Group>
      <Form.Group className="d-flex align-items-center justify-content-around mt-4">
        <div className="d-flex flex-column col-md-2 align-items-center justify-content-center">
          <Form.Label>Conformidad de Abono</Form.Label>
          <Form.Select
            name="conformidad_abono"
            onChange={(e) => handleChange(e)}
            className="form-control"
          >
            <option value={0}>No</option>
            <option value={1}>Si</option>
          </Form.Select>
        </div>
        {tipo_registro_compra === "2" ? (
          <>
            <div className="d-flex flex-column col-md-2 align-items-center justify-content-center">
              <Form.Label>Retención</Form.Label>
              <Form.Select
                name="retencion"
                onChange={(e) => handleChange(e)}
                className="form-control"
              >
                <option value={0}>No</option>
                <option value={1}>Si</option>
              </Form.Select>
            </div>
            <div className="d-flex flex-column col-md-3 align-items-center justify-content-center">
              <Form.Label>Monto neto</Form.Label>
              <Form.Control
                name="monto_neto"
                onChange={(e) => handleChange(e)}
                placeholder="Ingresa el monto"
              />
            </div>
            <div className="d-flex flex-column col-md-3 align-items-center justify-content-center">
              <Form.Label>Enlace Comprobante</Form.Label>
              <Form.Control
                name="enlace_comprobante"
                onChange={(e) => handleChange(e)}
                placeholder="ej: wwww.google.com"
              />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex flex-column col-md-3 align-items-center justify-content-center">
              <Form.Label>Base imponible</Form.Label>
              <Form.Control
                name="base_imponible"
                onChange={(e) => handleChange(e)}
                placeholder="Ingresa el monto"
              />
            </div>
            <div className="d-flex flex-column col-md-3 align-items-center justify-content-center">
              <Form.Label>Enlace Comprobante</Form.Label>
              <Form.Control
                name="enlace_comprobante"
                onChange={(e) => handleChange(e)}
                placeholder="ej: wwww.google.com"
              />
            </div>
          </>
        )}
      </Form.Group>
      <Form.Group className="d-flex align-items-center justify-content-around mt-4">
        <div className="d-flex flex-column col-md-3 align-items-center justify-content-center">
          <Form.Label>Observacion</Form.Label>
          <textarea
            name="observacion"
            onChange={(e) => handleChange(e)}
            className="form-control"
            style={{ resize: "none" }}
            maxLength={150}
          />
        </div>
        <div className="d-flex flex-column col-md-3 align-items-center justify-content-center">
          <Form.Label>Descripción</Form.Label>
          <textarea
            name="descripcion"
            onChange={(e) => handleChange(e)}
            className="form-control"
            style={{ resize: "none" }}
            maxLength={300}
          />
        </div>
      </Form.Group>
      <button className="mt-5 btn ge-btn-primary" type="submit">
        Guardar gasto
      </button>
    </Form>
  );
};

export default FormularioGastos;
