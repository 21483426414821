import React from 'react';
import * as FaIcons from 'react-icons/fa'
/* props
carouselState:{
    containerWidth: 1374
    currentSlide: 0
    deviceType: "desktop"
    domLoaded: true
    itemWidth: 344
    slidesToShow: 4
    totalItems: 7
    transform: -0
}

goToSlide(index)
next()
previous()

*/

const CarouselButtonsAlt = (props) => {
    
    const {carouselState, next, previous} = props;
    
	return (
        <div className="carousel-btn-container text-white">
            <FaIcons.FaChevronLeft onClick={() => previous()} className="arrow-icons"/>
            <p>{Number(carouselState?.currentSlide)+1}</p>
            <FaIcons.FaChevronRight onClick={() => next()} className="arrow-icons"/>
        </div>
        
    );
};

export default CarouselButtonsAlt;
