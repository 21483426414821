import React, { useMemo, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';
import HeaderSearchTable from '../../components/Custom/HeaderSearchTable';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import * as FaIcons from 'react-icons/fa';
import * as BsIcons from 'react-icons/bs';
import DataTable from 'react-data-table-component';
import LoaderSmall from '../../components/Loaders/LoaderSmall';
import {
  toggleEscuela,
  getEscuelas,
} from '../../controllers/escuelas/escuelas';
import { useSelector } from 'react-redux';
import { useMutateDeleteEscuelaById } from '../../hooks/escuelas.hooks';

const ListarEscuelas = () => {
  const {
    data: escuelasList,
    error,
    isLoading,
  } = useQuery(['getEscuelas'], getEscuelas, {
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();

  const {
    mutate,
    error: errorUpdate,
    isLoading: isLoadingUpdate,
  } = useMutation(toggleEscuela, {
    onSuccess: () => {
      queryClient.invalidateQueries(['getEscuelas']);
    },
  });

  const [filterText, setFilterText] = useState('');
  const [resetPagination, setResetPagination] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  /* Traer datos del Redux */
  const { user } = useSelector((state) => state.user);

  const { mutate: mutateDeleteEscuela } = useMutateDeleteEscuelaById();

  /* Search Component */
  const searchComponentMemo = useMemo(() => {
    return (
      <HeaderSearchTable
        filterText={filterText}
        setFilterText={setFilterText}
        arrayData={escuelasList}
        setArrayFiltered={setFilteredItems}
        setResetPagination={setResetPagination}
        columnsToFilter={['nombre_escuela', 'fecha_creacion']}
        placeholder='Buscar por nombre escuela o fecha de creación'
      />
    );
  }, [filterText, resetPagination]);

  /* Config Table */
  const columns = useMemo(
    () => [
      {
        name: '#',
        cell: (row, index) => index + 1,
        width: '50px',
      },
      {
        name: 'Fecha registro',
        selector: (row) => row.fecha_creacion,
        width: '120px',
      },
      {
        name: 'Escuela',
        selector: (row) => row.nombre_escuela,
        width: '230px',
      },
      {
        name: 'Descripción',
        selector: (row) => (
          <span>{row.descripcion}</span>
        ),
        width: '320px',
      },
      {
        name: 'Status',
        cell: (row) =>
          <Badge className='ge-deg-primary p-1'>Activo</Badge>,          
        width: '80px',
      },
      {
        name: 'Publicado',
        cell: (row) => (
          <button
            className='btn btn-light btn-sm'
            title={parseInt(row.status) === 1 ? 'Deshabilitar' : 'Activar'}
            onClick={() =>
              mutate({
                id_escuela: row.id_escuela,
                eliminado: parseInt(row.status) === 1 ? 0 : 1,
              })
            }>
            {parseInt(row.status) === 1 ? (
              <BsIcons.BsToggleOn color='#37af48' size={20} />
            ) : (
              <BsIcons.BsToggleOff color='#ff0000' size={20} />
            )}
          </button>
        ),
        width: '10%',
      },
      {
        name: 'Acciones',
        cell: (row) => (
          <div className='d-flex'>
            <NavLink
              to={`/editar-escuela/${row.cod_unico}`}
              className='btn ge-btn-primary btn-sm mr-2'>
              <FaIcons.FaCogs />
            </NavLink>
            {user.roles.includes('2') && (
              <button
                className='btn btn-danger btn-sm ml-2'
                onClick={() => handleDeleteEscuela(row.id_escuela)}>
                <FaIcons.FaTrash />
              </button>
            )}
          </div>
        ),
        width: '10%',
      },
    ],
    []
  );

  const handleDeleteEscuela = (id_escuela) => {
    mutateDeleteEscuela(id_escuela);
  };

  /** CODIGO AGREGADO */
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };
  /** FIN CODIGO */

  return (
    <LayoutAdmin contentTitle='' routeTitle='Lista de escuelas'>
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header ge-noborder'>
              <>
                <img
                  src='/images/file-icon.png'
                  className='mr-2'
                  style={{ width: '40px' }}
                  alt='file-icon'
                />
                <span className='ge-h3 ge-title'>Lista de Escuelas</span>
              </>
            </div>
            <div className='card-body'>
              <DataTable
                className='custom-datatable'
                columns={columns}
                data={filteredItems.length > 0 ? filteredItems : escuelasList}
                subHeader
                subHeaderComponent={searchComponentMemo}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                responsive
                progressPending={isLoading}
                progressComponent={<LoaderSmall />}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default ListarEscuelas;
