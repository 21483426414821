import React from "react";

import { useParams } from "react-router-dom";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import { useQuery } from "react-query";
import { Alert, Spinner } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import TabRuta from "../../components/Rutas/TabRuta";
import { getRutaByUid } from "../../controllers/rutas/rutas";

const EditRuta = () => {
	const { uId } = useParams();
	const {
		data: datosRuta,
		error,
		isLoading,
	} = useQuery(["getRuta", uId], () => getRutaByUid(uId), {
		refetchOnWindowFocus: false,
		retry: 2,
	});
	
	return (
		<LayoutAdmin
			contentTitle={
				<div className="ge-title">
					<FaIcons.FaEdit /> Editar Ruta
				</div>
			}
			routeTitle="Configuración de ruta"
		>
			{isLoading ? (
				<div className="text-center">
					{" "}
					<Spinner animation="border" />
				</div>
			) : datosRuta ? (
				<TabRuta ruta={datosRuta.ruta} cursos={datosRuta.cursos} />
			) : (
				<Alert key={"info"} variant={"info"}>
					<FaIcons.FaExclamationTriangle /> No existe informacion
					relacionada....
				</Alert>
			)}
		</LayoutAdmin>
	);
};

export default EditRuta;
