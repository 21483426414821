import { useMutation, useQuery, useQueryClient } from "react-query";
import { createConvocatoria, deleteConvocatoria, getConvocatoriaId, getConvocatorias, togglePublicarConvocatoria, updateConvocatoria } from "../controllers/convocatorias/convocatorias.controller";
import { SwalToast } from "../utils/toasts";

const KEY_CONVOCATORIAS = "convocatorias";

export const useQueryConvocatorias = () => {
    return useQuery(
        [KEY_CONVOCATORIAS],
        () => getConvocatorias(),
        {
            refetchOnWindowFocus: false,
            retry: 2,
        }
    )
}


export const useQueryConvocatoriaId = (id_registro) => {
    return useQuery(
        [KEY_CONVOCATORIAS, id_registro],
        () => getConvocatoriaId(id_registro),
        {
            refetchOnWindowFocus: false,
            retry: 2,
            enabled: !!id_registro,
        }
    )
}



export const useMutateCreateConvocatoria = () => {
    const queryClient = useQueryClient();
    return useMutation(
        createConvocatoria,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(KEY_CONVOCATORIAS);
            }
        }
    )
}

export const useMutateEditConvocatoria = (id_registro) => {
    const queryClient = useQueryClient();
    return useMutation(
        updateConvocatoria,
        {
            onSuccess: () => {
                queryClient.invalidateQueries([KEY_CONVOCATORIAS]);
                queryClient.invalidateQueries([KEY_CONVOCATORIAS, id_registro]);
            }
        }
    )
}


export const useMutatePublishConvocatoria = () => {
    const queryClient = useQueryClient();
    return useMutation(
        togglePublicarConvocatoria,
        {
            onSuccess: () => {
                queryClient.invalidateQueries([KEY_CONVOCATORIAS]);
                SwalToast.fire({
                    icon: "success",
                    title: "Convocatoria publicada correctamente",
                });
            },
            onError: () => {
                SwalToast.fire({
                    icon: "error",
                    title: "Error al publicar la convocatoria",
                });
            }
        }
    )
}


export const useMutateDeleteConvocatoria = () => {
    const queryClient = useQueryClient();
    return useMutation(
        deleteConvocatoria,
        {
            onSuccess: () => {
                queryClient.invalidateQueries([KEY_CONVOCATORIAS]);
                SwalToast.fire({
                    icon: "success",
                    title: "Convocatoria eliminada correctamente",
                });
            },
            onError: () => {
                SwalToast.fire({
                    icon: "error",
                    title: "Error al eliminar la convocatoria",
                });
            }
        }
    )
}