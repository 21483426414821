import React, { useState, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import ListarOpciones from "./ListarOpciones";
import * as BsIcons from "react-icons/bs";
import CustomSelectGeneral from "../Custom/CustomSelectGeneral";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
	addQuestion,
	getClassByCourse,
} from "../../controllers/cursos/examen/examen.controller";


const NuevaPregunta = ({ id_curso, id_examen, setShow }) => {
	
	const {
		data: clasesList,
		error,
		isLoading,
	} = useQuery(["ClasesCurso", id_curso], () => getClassByCourse(id_curso), {
		retry: 1,
		refetchOnWindowFocus: false,
	});
	
	const queryClient = useQueryClient();
	
	const { mutate: agregarPregunta } = useMutation(addQuestion, {
		onSuccess: () => {
			queryClient.refetchQueries(["Preguntas"]);
		},
	});
	
	const initialState = () => ({
		id_examen: id_examen,
		pregunta: "",
		descripcion: "",
		explicacion_correcta: "",
		clase: {},
		opciones: [],
	});
	
	const initialStateOpcion = () => ({
		id_opcion: "",
		opcion_text: "",
		respuesta_correcta: 0,
	});
	
	const [infoPregunta, setInfoPregunta] = useState(initialState());
	const [opcion, setOpcion] = useState(initialStateOpcion());
	
	const { pregunta, descripcion, explicacion_correcta, opciones } = infoPregunta;
	const { opcion_text, respuesta_correcta } = opcion;
	
	const toggleChecked = (status = 0) => {
		setOpcion({
			...opcion,
			respuesta_correcta: status,
		});
	};
	
	const handleChange = (e) => {
		setInfoPregunta({
			...infoPregunta,
			[e.target.name]: e.target.value,
		});
	};
	
	const handleChangeClase = (value) => {
		setInfoPregunta({
			...infoPregunta,
			clase: value.id_clase,
		});
	};
	
	const handleChangeOpcion = (e) => {
		setOpcion({
			...opcion,
			[e.target.name]: e.target.value,
		});
	};
	
	const agregarOpcion = (op) => {
		if (op.opcion_text !== "") {
			op.id_opcion = new Date().getTime();
			const nuevoArreglo = [...infoPregunta.opciones, op];
			setInfoPregunta({
				...infoPregunta,
				opciones: nuevoArreglo,
			});
			setOpcion(initialStateOpcion());
			// avisoCorrecto("Opción Agregada")
		} else {
			//  avisoError("Debe llenar el campo opción")
		}
	};
	
	const eliminarOpcion = (id) => {
		const copiaOpciones = [...infoPregunta.opciones];
		const arregloActualizado = copiaOpciones.filter(
			(opcion) => opcion.id_opcion !== id
		);
		setInfoPregunta({
			...infoPregunta,
			opciones: arregloActualizado,
		});
	};
	
	const onSubmit = (e) => {
		e.preventDefault();
		agregarPregunta(infoPregunta);
		setInfoPregunta(initialState());
		setShow(false);
	};
	
	useEffect(() => {
		// console.log(infoPregunta.opciones)
	}, [infoPregunta.opciones]);
	
	return (
		<>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<fieldset className="mb-4 p-4">
						<legend>Información de la pregunta</legend>
						<Form.Group className="my-2">
							<Form.Label
								className="mb-0"
							>
								Pregunta
							</Form.Label>
							<Form.Control
								required
								name="pregunta"
								type="text"
								value={pregunta}
								onChange={handleChange}
								placeholder="Tu pregunta"
							/>
						</Form.Group>
						{/*<Form.Group className="my-2">
							<Form.Label
								className="mb-0"
							>
								Descripción de la pregunta
							</Form.Label>
							<Form.Control
								as="textarea"
								name="descripcion"
								value={descripcion}
								onChange={handleChange}
								placeholder={"Descripción de la pregunta"}
							/>
						</Form.Group>
						<Form.Group className="my-2">
							<Form.Label className="mb-0">
								Explicacion de Respuesta Correcta
							</Form.Label>
							<Form.Control
								as="textarea"
								name="explicacion_correcta"
								value={explicacion_correcta}
								onChange={handleChange}
								placeholder="Explicación de la respuesta"
							/>
						</Form.Group>*/}
						<Form.Group className="my-2">
							<CustomSelectGeneral
								options={clasesList}
								id={"id_clase"}
								lbl={"titulo_clase"}
								seleccion={"Seleccione una clase"}
								value={infoPregunta.clase}
								onChange={handleChangeClase}
								lblColor={"#000"}
							/>
						</Form.Group>
					</fieldset>
					<fieldset className="p-4">
						<legend>Opciones de la pregunta</legend>
						<Form.Group>
							<Form.Label>Opción</Form.Label>
							<Form.Control
								as="textarea"
								name="opcion_text"
								value={opcion_text}
								type="text"
								onChange={handleChangeOpcion}
								placeholder="Tu opción"
							/>
						</Form.Group>
						<Form.Group 
							className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center mt-3 mb-2"
						>
							<Form.Label
								className="mb-2 mb-sm-0"
							>
								Marcar opción correcta
							</Form.Label>
							{parseInt(respuesta_correcta) === 1 ? (
								<span
									className="btn btn-light btn-sm my-sm-2"
									onClick={() => toggleChecked(0)}
								>
									<BsIcons.BsToggleOn
										color="#37af48"
										size={20}
									/>
								</span>
							) : (
								<span
									className="btn btn-light btn-sm my-sm-2"
									onClick={() => toggleChecked(1)}
								>
									<BsIcons.BsToggleOff
										color="#ff0000"
										size={20}
									/>
								</span>
							)}
							
							<Button
								size="sm"
								type="button"
								variant="success"
								onClick={() => {
									agregarOpcion(opcion);
								}}
							>
								Agregar Opción
							</Button>
						</Form.Group>
						<div className="mt-3">
							<ListarOpciones
								opciones={opciones}
								eliminarOpcion={eliminarOpcion}
							/>
						</div>
					</fieldset>
				</Modal.Body>
				<Modal.Footer>
					<Button 
						variant="info" 
						type="submit"
					>
						Guardar Pregunta
					</Button>
				</Modal.Footer>
			</Form>
		</>
	);
};

export default NuevaPregunta;
