import React, { useEffect, useMemo, useState } from "react";
import LayoutAdmin from "../../../components/layouts/LayoutAdmin";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import { useNavigate, useParams,useLocation  } from "react-router-dom";
import {
	useMutateEditModuleName,
	useQueryFindModuleById,
} from "../../../hooks/curso-modulos.hooks";
import HeaderSearchTable from "../../../components/Custom/HeaderSearchTable";
import DataTable from "react-data-table-component";
import LoaderSmall from "../../../components/Loaders/LoaderSmall";
import ModalAddClass from "../../../components/Clases/ModalAddClass";
import { SwalToast } from "../../../utils/toasts";
import { Badge } from "react-bootstrap";
import { useMutateActiveClass, useMutateDeleteClassById } from "../../../hooks/clase.hooks";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useQueryOneCourseDetails } from '../../../hooks/cursos.hooks';

const EditarModuloScreen = () => {
	const { idModulo } = useParams();
	const [moduleInput, setModuleInput] = useState("");
	const [filteredData, setFilteredData] = useState([]);
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [moduleList, setModuleList] = useState([]);
	const { data, isLoading, isSuccess } = useQueryFindModuleById(idModulo);
	const { mutate: mutateEditName, isLoading: loadingName } =
		useMutateEditModuleName(idModulo);
	const { mutate: mutateActiveClass, isLoading: loadingActive } =
		useMutateActiveClass(idModulo);
	const [showModalAddClass, setShowModalAddClass] = useState(false);
	const navigate = useNavigate();
	
	const handleToEdit = (idClass) => {
		navigate(`/clases/editar-clase/${idClass}`);
	};

  // id del curso para traer datos, editarlo y sumar la duracion del video nuevo
  const location = useLocation();
  const slugCurso = location.state?.slugCurso;
  const idCourse = location.state?.idCourse;
	const { data: dataCourse, isLoading2, isSuccess2 } = useQueryOneCourseDetails(
		slugCurso
	);

	useEffect(() => {
		if (data?.clases) {
			// console.log('data', data);
			setModuleInput(data.modulo.nombre_modulo);
			setModuleList(data.clases);
		}
	}, [data]);

	/* Traer datos del Redux */
	const { user } = useSelector(( state ) => state.user)

	const { mutate: mutateDeleteClass } = useMutateDeleteClassById();
	
	const handleChangeActiveClass = (idClass, active) => {
		Swal.fire({
			title: "¿Estás seguro?",
			text: "Cambiar el estado de la clase se verá reflejado en el sistema",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si, cambiar!",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				mutateActiveClass(
					{
						idClass,
						active,
					},
					{
						onSuccess: () => {
							SwalToast.fire({
								icon: "success",
								title: "Estado cambiado con éxito",
							});
						},
						onError: () => {
							SwalToast.fire({
								icon: "error",
								title: "Error al cambiar el estado.",
							});
						},
					}
				);
			}
		});
	};
	
	const columns = useMemo(
		() => [
			{
				name: "#",
				selector: (row) => row.id_clase,
				width: "10%",
			},
			{
				name: "Nombre",
				width: "200px",
				selector: (row) => row.titulo,
				sortable: true,
			},
			{
				name: "Url del video",
				width: "350px",
				selector: (row) => row.video,
			},
			{
				name: "Estado",
				width: "10%",
				cell: (row) =>
					parseInt(row.estado) === 1 ? (
						<Badge className="ge-deg-primary p-1">Activo</Badge>
					) : (
						<Badge className="bg-danger">Inactivo</Badge>
					),
			},
			{
				name: "Acciones",
				cell: (row) => (
					<div className="d-flex">
						<button
							className="btn ge-btn-primary btn-sm mr-2"
							onClick={() => handleToEdit(row.id_clase)}
						>
							<FaIcons.FaCogs />
						</button>
						
						{parseInt(row.estado) === 1 ? (
							<button
								className="btn btn-light btn-sm"
								onClick={() =>
									handleChangeActiveClass(row.id_clase, 0)
								}
							>
								<BsIcons.BsToggleOn color="#37af48" size={20} />
							</button>
						) : (
							<button
								className="btn btn-light btn-sm"
								onClick={() =>
									handleChangeActiveClass(row.id_clase, 1)
								}
							>
								<BsIcons.BsToggleOff
									color="#ff0000"
									size={20}
								/>
							</button>
						)}
						{/* Verificar si es admin */}
						{ user.roles.includes("2") && (
						<button
							className="btn btn-danger btn-sm mr-2"
							onClick={()=>  handleDeletedClaseByAdmin(row.id_clase)}
						>
							<FaIcons.FaTrash />
						</button>
						)}
					</div>
				),
			},
		],
		[]
	);
	
	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={moduleList}
				setArrayFiltered={setFilteredData}
				setResetPagination={setResetPagination}
				placeholder="Buscar por nombre"
				columnsToFilter={["titulo"]}
			/>
		);
	}, [filterText, resetPagination]);
	
	const handleChangeName = () => {
		mutateEditName(
			{
				idModule: idModulo,
				nameModule: moduleInput,
			},
			{
				onSuccess: (data) => {
					SwalToast.fire({
						icon: "success",
						title: "Modulo editado correctamente",
					});
				},
			}
		);
	};

	const handleDeletedClaseByAdmin = (id_clase) => {
		mutateDeleteClass(id_clase)
	};
	
	return (
		<LayoutAdmin 
			routeTitle="Editar Módulo"
			contentTitle=""
		>
			<ModalAddClass
				show={showModalAddClass}
				setShow={setShowModalAddClass}
        idCourse={idCourse}
        dataCourse={dataCourse}
			/>
			
			<div className="card p-2">
				<div className="d-flex justify-content-between card-header card-no-after ge-noborder">
					<div className="d-flex flex-column">
						<span className="ge-h3 ge-title">Lista de clases</span>
						<div className="d-flex mt-3">
							<input
								type="text"
								className="form-control"
								placeholder="Nombre del Módulo"
								name="nombre_modulo"
								value={moduleInput}
								onChange={(e) => setModuleInput(e.target.value)}
							/>
							<button
								className="btn ge-btn-primary d-flex align-items-center btn-sm ml-2"
								disabled={moduleInput === "" || loadingName}
								onClick={handleChangeName}
							>
								<FaIcons.FaSave />
								<span className="ml-2">Guardar</span>
							</button>
						</div>
					</div>
					<div className="d-flex align-items-end">
						<button
							className="btn ge-btn-primary"
							onClick={() => setShowModalAddClass(true)}
						>
							<FaIcons.FaPlus />
							<span className="ml-2">Agregar Clase</span>
						</button>
					</div>
				</div>
				<div className="card-body">
					<DataTable
						className="custom-datatable"
						columns={columns}
						data={
							filteredData.length > 0 ? filteredData : moduleList
						}
						subHeader
						subHeaderComponent={searchComponentMemo}
						pagination
						responsive
						progressPending={isLoading}
						progressComponent={<LoaderSmall />}
						noDataComponent={
							<div className="mt-3 w-100 text-center">
								No hay clases, crea uno.
							</div>
						}
					/>
				</div>
			</div>
		</LayoutAdmin>
	);
};

export default EditarModuloScreen;
