import { useMutation, useQuery, useQueryClient } from "react-query";
import { deletePostById } from "../controllers/blog/posts";

const KEY_POST = "posts";

export const useMutateDeletePostById = () => {
    const queryClient = useQueryClient();
    return useMutation(deletePostById, {
        onSuccess: () => {
            queryClient.invalidateQueries([KEY_POST]);
        }
    });
}