import React from "react";
import { Modal } from "react-bootstrap";

const DarkModal = ({
	showModal,
	onHide,
	modalProps = {},
	headerProps = {},
	bodyProps = {},
    footerProps = {},
    children,
    title = null,
    headerComponent = null,
    footerComponent = null,
}) => {
	return (
		<Modal
			show={showModal}
			onHide={onHide}
			className={
				modalProps.className
					? `${modalProps.className}`
					: ""
			}
            centered
			backdrop="static"
		>
            {
                (title) && (
                    <Modal.Header
                        {...headerProps}
                    >
                        {
                            headerComponent 
                            ? headerComponent
                            : <Modal.Title>{title}</Modal.Title>
                        }
                    </Modal.Header>
                )
            }
			<Modal.Body
                {...bodyProps}
            >
                {children || <p>Modal body text goes here.</p>}
            </Modal.Body>
            {
                footerComponent && (
                    <Modal.Footer
                        {...footerProps}
                    >
                        {footerComponent}
                    </Modal.Footer>
                )
            }
		</Modal>
	);
};

export default DarkModal;
