import React from "react";
import TableTopDocentes from "../../Docentes/TableTopDocentes";
import IndiceSatisfaccion from "./IndiceSatisfaccion";
import TasaCancelacion from "./TasaCancelacion";
import TasaRetencion from "./TasaRetencion";
import TiempoPermanencia from "./TiempoPermanencia";
import TiempoVisualizacion from "./TiempoVisualizacion";

const HomeManager = () => {
	return (
		<div className="row">
			<div className="col-12 my-2">
				<TableTopDocentes />
			</div>
			<div className="col-12 col-md-4 my-2">
				<TasaCancelacion />
			</div>
			<div className="col-12 col-md-4 my-2">
				<TiempoPermanencia />
			</div>
			<div className="col-12 col-md-4 my-2">
				<IndiceSatisfaccion />
			</div>
			<div className="col-12 col-md-4 my-2">
				<TasaRetencion />
			</div>
			<div className="col-12 col-md-4 my-2">
				<TiempoVisualizacion />
			</div>
			
		</div>
	);
};

export default HomeManager;
