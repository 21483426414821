import { useMutation, useQuery, useQueryClient } from "react-query";
import { getFacturasFiles, uploadFacturas, deleteFacturaById } from "../controllers/facturas/facturas.controller";


const KEY_FACTURAS = "FACTURAS";



export const useQueryFacturasList = () => {
    return useQuery(
        [KEY_FACTURAS],
        () => getFacturasFiles(),
        {
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}


export const useMutateUploadFacturas = () => {
    const queryClient = useQueryClient();
    return useMutation(uploadFacturas, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_FACTURAS]);
        }
    });
}

export const useMutateDeleteFacturaById = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteFacturaById, {
        onSuccess: () => {
            queryClient.invalidateQueries([KEY_FACTURAS]);
        }
    });
}