import dayjs from "dayjs";
import { getAuthAxios } from "../../../axios/axiosCustom"


export const getIngresos = async ({
    fecha_consulta
}) => {
    try{
        const fecha_format = dayjs(fecha_consulta).format("YYYY-MM");
        
        const axios = getAuthAxios();
        const response = await axios.get(`/ingresos_gastos/traer_ingresos/${fecha_format}`);
        
        if(response.data.status){
            return response.data.data;
        }
        
        throw new Error('No se pudo obtener los ingresos');
    }catch(error){
        console.error(error);
    }
}