import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createCurso,
  editCurso,
  getAllCursos,
  getOneCourseDetails,
  toggleCurso,
  deleteCourseById,
  getSelectCursos,
  getTotalDuracion,
  getAllCursosNET,
} from "../controllers/cursos/cursos.controller";

const KEY_ALL_CURSOS = "getAllCursos";
const KEY_ALL_CURSOS_NET = "getAllCursosNet";
const KEY_ONE_COURSE = "getCourse";

export const useQueryCursos = () => {
  return useQuery(KEY_ALL_CURSOS, getAllCursos, {
    retry: 2,
    refetchOnWindowFocus: false,
  });
};

export const useQueryCursosNet = () => {
  return useQuery(KEY_ALL_CURSOS_NET, getAllCursosNET, {
    retry: 2,
    refetchOnWindowFocus: false,
  });
};

export const useQueryDuracionCurso = (idCourse) => {
  return useQuery(KEY_ONE_COURSE, 
    () => getTotalDuracion(idCourse), 
    {
    retry: 2,
    refetchOnWindowFocus: false,
  });
};

export const useQuerySelectCursos = () => {
    return useQuery(KEY_ALL_CURSOS, getSelectCursos, {
      retry: 2,
      refetchOnWindowFocus: false,
    });
  };

export const useQueryOneCourseDetails = (slugCurso) => {
  return useQuery(
    [KEY_ONE_COURSE, slugCurso],
    () => getOneCourseDetails(slugCurso),
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );
};

export const useMutateCreateCurso = () => {
  const queryClient = useQueryClient();
  return useMutation(createCurso, {
    onSuccess: (data) => {
      /* queryClient.setQueryData("getAllDocentes", (prev) => {
                return [...prev, data];
            }); */
      queryClient.invalidateQueries([KEY_ALL_CURSOS]);
    },
  });
};

export const useMutateEditCurso = () => {
  const queryClient = useQueryClient();
  return useMutation(editCurso, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([KEY_ALL_CURSOS]);
    },
  });
};

export const useMutateToggleCourse = () => {
  const queryClient = useQueryClient();
  return useMutation(toggleCurso, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([KEY_ALL_CURSOS]);
    },
  });
};

export const useMutateDeleteCourseById = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCourseById, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_ALL_CURSOS]);
    },
  });
};
