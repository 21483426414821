import { getAuthAxios } from "../axios/axiosCustom"



export const getCoursesSelect = async (id_user=null) => {
    const axios = getAuthAxios();
    try{
const response = await axios.get(`/curso/select-cursos/${id_user}`);
        if(response.data.status){
            return response.data.data;
        }else{
            return null;
        }
    }catch(error){
        console.log(error);
        return null;
    }
}


export const getRoutesSelect = async (id_user=null) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/ruta/select-rutas/${id_user}`);
        if(response.data.status){
            return response.data.data;
        }else{
            return null;
        }
    }catch(error){
        console.log(error);
        return null;
    }
}

export const getSchoolSelect = async (id_user=null) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/escuela/select-escuelas/${id_user}`);
        if(response.data.status){
            return response.data.data;
        }else{
            return null;
        }
    }catch(error){
        console.log(error);
        return null;
    }
}



/*  */
export const dynamicSelect = async (typeSelect,id_user) => {
    switch(typeSelect){
        case "curso":
            return await getCoursesSelect(id_user);
        case "ruta":
            return await getRoutesSelect(id_user);
        case "escuela":
            return await getSchoolSelect(id_user);
        default:
            return null;
    }
}



export const getRestCoursesFromUser = async(idUser) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/dashboard/estudiantes/rest_courses_select/${idUser}`);
        if(response.data.status){
            return response.data.data;
        }else{
            return null;
        }
    }catch(error){
        console.log(error);
        return null;
    }
}