import React from "react";
import { useSelector } from "react-redux";
import CardCommentsClass from "../Docentes/Cards/CardCommentsClass";
import CardDocentePerformance from '../Docentes/Cards/CardDocentePerformance';
import CardWeekCommentsPost from "../Docentes/Cards/CardWeekCommentsPost";

const HomeDocente = () => {
    
    const {user} = useSelector(state => state.user);
    const {id_user, nombres, apellidos, img_perfil} = user;
    
	return (
		<div className="row">
            <div className="col-12">
                <CardCommentsClass
                    idDocente={id_user}
                />
            </div>
            <div className="col-12 col-md-8">
                <CardWeekCommentsPost
                    idDocente={id_user}
                />
            </div>
			<div className="col-12 col-md-4">
				<CardDocentePerformance
                    idDocente={id_user}
                    nombres={nombres}
                    apellidos={apellidos}
                    imagen={img_perfil}
                />
			</div>
		</div>
	);
};

export default HomeDocente;
