import Swal from "sweetalert2";
import { getAuthAxios } from "../../axios/axiosCustom";
import dayjs from "dayjs";

export const getRutas = async () => {
	const axios = getAuthAxios();
	const response = await axios.get(`/ruta/getAll`);
	if (response.data.status) {
		return response.data.data;
	}
	throw new Error(response.data.message);
};

export const getRutasEnVivo = async () => {
	try{
		const axios = getAuthAxios();
		const response = await axios.get(`/admin/rutas_en_vivo`);
		if (response.data.status) {
			return response.data.data;
		}
		return null;
	}catch(e){
		console.log(e);
		return null;
	}
}

export const getRutaByUid = async (uId) => {
	const axios = getAuthAxios();
	const response = await axios.get(`/ruta/getRuta/${uId}`);
	if (response.data.status) {
		return response.data.data;
	}
	throw new Error(response.data.message);
};

export const getCursos = async () => {
	const axios = getAuthAxios();
	const response = await axios.get(`/curso/getAll`);
	if (response.data.status) {
		return response.data.data;
	}
	throw new Error(response.data.message);
};

export const addCurso = async (datos) => {
	const axios = getAuthAxios();
	const response = await axios.post(
		`/ruta/add_curso/${datos.id_ruta}`,
		datos
	);
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});

	if (response.data.status) {
		Toast.fire({
			icon: "success",
			title: response.data.message,
		});
		return response.data;
	} else {
		Toast.fire({
			icon: "warning",
			title: response.data.message,
		});
		return response.data;
	}
};

export const deleteCurso = async ({ id_ruta, id_curso }) => {
	const axios = getAuthAxios();
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});

	await Swal.fire({
		title: "Estas seguro?",
		text: "Una vez eliminado, no se puede revertir!",
		icon: "warning",
		showCancelButton: true,
		confirmButtonText: "Si",
		cancelButtonText: "No",
	}).then(async (result) => {
		if (result.isConfirmed) {
			const response = await axios.delete(
				`/ruta/delete_curso/${id_ruta}/${id_curso}`
			);

			if (response.data.status) {
				Toast.fire({
					icon: "success",
					title: response.data.message,
				});
			} else {
				Toast.fire({
					icon: "warning",
					title: response.data.message,
				});
			}
		} else if (
			/* Read more about handling dismissals below */
			result.dismiss === Swal.DismissReason.cancel
		) {
		}
	});
};

export const createRuta = async (datos) => {
	const axios = getAuthAxios();
	const datos_file = new FormData();
	
	let urlApi = "/ruta/create_ruta";
	let data_={
		...datos
	}
	//Si la ruta es en vivo cambiar la url y la data
	if(parseInt(datos.live) === 1){
		urlApi = "/ruta/create_ruta_en_vivo";
		data_={
			...data_,
			fecha_inicio: dayjs(data_.fecha_inicio).format("YYYY-MM-DD HH:mm:ss"),
		}
		// delete data_.contenido_html;
	}
	
	// si la ruta no es en vivo eliminar la fecha de inicio y el enlace
	if(parseInt(datos.live) === 0){
		delete data_.fecha_inicio;
		delete data_.enlace;
	}
	
	//eliminar el campo live
	delete data_.live;
	
	Object.keys(data_).forEach((key) => datos_file.append(key, data_[key]));
	const response = await axios.post(urlApi, datos_file,{
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	
	if (response.data.status) {
		return response.data;
	}
	throw new Error(response.data.error);
};

export const updateRuta = async (datos) => {
	try{
		const axios = getAuthAxios();
		const datos_file = new FormData();
		let urlApi = `/ruta/edit_ruta/${datos.id_ruta}`;
		let data_={
			...datos
		}
		//Si la ruta es en vivo cambiar la url y parsear la fecha de inicio
		if(parseInt(datos.live) === 1){
			urlApi = `/ruta/edit_ruta_en_vivo/${datos.id_ruta}`;
			data_={
				...data_,
				fecha_inicio: dayjs(data_.fecha_inicio).format("YYYY-MM-DD HH:mm:ss"),
			}
			// delete data_.contenido_html;
		}
		
		// si la ruta no es en vivo eliminar la fecha de inicio y el enlace
		if(parseInt(datos.live) === 0){
			delete data_.fecha_inicio;
			delete data_.enlace;
		}
		
		//eliminar el campo live y isImageUrl
		delete data_.live;
		delete data_.imageIsUrl;
		
		Object.keys(data_).forEach((key) => datos_file.append(key, data_[key]));
		const response = await axios.post(
			urlApi,
			datos_file,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);
		
		if (response.data.status) {
			return response.data;
		}
		throw new Error('Error al actualizar la ruta');
	}catch(error){
		throw new Error(error);
	}
};

export const ToggleRuta = async (datos) => {
	const axios = getAuthAxios();
	const response = await axios.put(
		`/ruta/delete_ruta/${datos.id_ruta}`,
		datos
	);
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});

	if (response.data.status) {
		Toast.fire({
			icon: "success",
			title: response.data.message,
		});
		return response.data;
	}
	throw new Error(response.data.error);
};

export const getGlosaryByRoute = async (id_ruta) => {
	const axios = getAuthAxios();
	const response = await axios.get(`/ruta/traer_glosario/${id_ruta}`);
	if (response.data.status) {
		return response.data.data;
	}
	throw new Error(response.data.message);
};

export const addGlosary = async ({id_ruta, datos}) => {
	const data = {
		glosarios: datos,
	}
	const axios = getAuthAxios();
	const response = await axios.post(`/ruta/add_glosario/${id_ruta}`, data);
	
	if (response.data.status) {
		return response.data;
	}
	throw new Error(response.data.message);
};

export const deleteRutaById = async (id_ruta) => {
    const axios = getAuthAxios();
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
  
    await  Swal.fire({
        title: '¿Estás seguro?',
        text: "Una vez eliminado, no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
            const response = await axios.delete(`/ruta/delete_ruta_admin/${id_ruta}`);
              
            if(response.data.status){
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  })
            }
            else{
                Toast.fire({
                    icon: 'warning',
                    title: response.data.message
                  })
            }
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      })
  }