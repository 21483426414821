import React from "react";
import { Table } from "react-bootstrap";

const TablaReporte = ({ datos }) => {
	return (
		<Table responsive hover bordered>
			<thead>
				<tr>
					<th>Reporte/Días</th>
					{datos.labels.map((label, i) => (
						<th style={{ textAlign: "center" }} key={i}>{label}</th>
					))}
					<th style={{ textAlign: "right" }}>Total:</th>
				</tr>
			</thead>
			<tbody>
				{datos.dataSets.map((medio, i) => (
					<tr key={i}>
						<td style={{fontWeight: 'initial'}}>{medio.label}</td>
						{medio.data.map((cantidad, j) => (
							<td style={{ textAlign: "center" }} key={j}>
								{parseInt(cantidad) > 0 ? (
									<span>
										{cantidad}
									</span>
								) : (
									<span>{cantidad}</span>
								)}
							</td>
						))}
						<td style={{ textAlign: "right", fontWeight: "bold" }}>
							{datos.Total_medio[i]}
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default TablaReporte;
