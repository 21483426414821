import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { getNewTicketsDataset } from "../../../controllers/incidencias/incidencias.controller";
import { SwalToast } from "../../../utils/toasts";
import LineChartDataset from "../../Charts/LineChartDataset";
import CustomInputDate from "../../Custom/CustomInputDate";
import LoaderSmall from "../../Loaders/LoaderSmall";

const CardNewTickets = () => {
    
	/* Obtener los últimos 7 días por defecto */
	const actualDay = new Date();
	let daysAgo7 = new Date();
	daysAgo7.setDate(actualDay.getDate() - 6);
    
	const initialState = [daysAgo7, actualDay];
    
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [dateRange, setDateRange] = useState(initialState);
	const [startDate, endDate] = dateRange;
	const chartRef = useRef(null);
	
	const getData = async () => {
		setLoading(true);
		const data_ = await getNewTicketsDataset(startDate, endDate);
		console.log('data',data_);
		if (data_) {
			setData(data_);
			setLoading(false);
		} else {
			SwalToast.fire({
				icon: "error",
				title: "Error",
				text: "Error al obtener los datos",
			});
			setData(null);
			setLoading(false);
		}
	};
	
	const downloadReport = async () => {
		if (chartRef) {
			const link = document.createElement("a");
			link.download = "chart";
			link.href = chartRef.current.toBase64Image();
			link.click();
		}
	};
	
	useEffect(() => {
		if (startDate && endDate) {
			getData();
		}
	}, [dateRange]);
    
	return (
		<div className="card ge-bt-primary">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column align-items-center">
						<h5 className="ge-title txt-bold">Nuevos Tickets</h5>
						<div>
							{/* Range Picker */}
							<span className="txt-bold ge-title">Periodo:</span>
							<ReactDatePicker
								locale={"es"}
								selectsRange={true}
								startDate={startDate}
								endDate={endDate}
								onChange={(update) => {
									setDateRange(update);
								}}
								isClearable={true}
								// className="form-control ge-form my-2"
								placeholderText="Seleccione un rango"
								dateFormat="yyyy-MM-dd"
								maxDate={actualDay}
								customInput={<CustomInputDate />}
							/>
						</div>
					</div>
					<div className="col-12 mt-4">
						{/* Grafico */}
						{loading ? (
							<LoaderSmall />
						) : data ? (
							<LineChartDataset
								ref={chartRef}
								datos={data}
								showLegend={false}
								showTitle={false}
							/>
						) : (
							<div className="d-flex justify-content-center align-items-center w-100">
								<span className="ge-txt-muted-dark">
									No hay datos para mostrar
								</span>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardNewTickets;
