import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { obtenerTasaDeRetencion } from "../../../controllers/dashboard_roles/manager.controller";
import CustomInputDate from "../../Custom/CustomInputDate";

const TasaRetencion = () => {
    
    const [data, setData] = useState({
		tasa_retencion: 0,
	});
	
	const { tasa_retencion } = data;
    
    const getData = async () => {
        const data_ = await obtenerTasaDeRetencion();
        if(data_){
            setData({
				tasa_retencion: data_.tasa_retencion,
			});
        }
    }
    
    useEffect(() => {
		getData();
        return () => {
            setData({
				tasa_retencion: 0,
			});
        }
    }, []);
    
	return (
		<div className="card ge-bl-2 h-100">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column align-items-center mb-4">
                        <h5 className="ge-title txt-bold">
                            Tasa de retención de clientes
                        </h5>
					</div>
					<div className="col-12 text-center">
						<img
							src="/images/retencion.png"
							alt="Tasa de retención de clientes"
							className="img-fluid"
							style={{ maxWidth: "100px" }}
						/>
					</div>
                    <div className="col-12 d-flex justify-content-center">
                        <span className="ge-h1 txt-bold ge-title text-center">
							{tasa_retencion}
							{ " " }
							{ parseFloat(tasa_retencion) > 1 ? "Meses" : "Mes" }
						</span>
                    </div>
				</div>
			</div>
		</div>
	);
};

export default TasaRetencion;
