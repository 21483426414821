import { useMutation, useQuery, useQueryClient } from "react-query";
import { archiveTicket, closeTicket, getIncidentsList, getInfoIncident, markIncidentAsResolved, reponseIncident } from "../controllers/incidencias/incidencias.controller";


const INCIDENTS_LIST = 'incidents_list';

export const useQueryIncidents = () => {
    return useQuery(
        [INCIDENTS_LIST],
        () => getIncidentsList(),
        {
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}


export const useQueryIncidentInfo = (codIncident) => {
    return useQuery(
        [INCIDENTS_LIST, codIncident],
        () => getInfoIncident(codIncident),
        {
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}


export const useMutationIncidentResolved = () => {
    const queryClient = useQueryClient();
    return useMutation(
        markIncidentAsResolved,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([INCIDENTS_LIST]);
            }
        }
    );
}



export const useMutationResponseIncident = (cod_incident) => {
    const queryClient = useQueryClient();
    return useMutation(
        reponseIncident,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([INCIDENTS_LIST, cod_incident]);
            }
        }
    );
}


export const useMutationCloseIncident = (cod_incident) => {
    const queryClient = useQueryClient();
    return useMutation(
        closeTicket,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([INCIDENTS_LIST, cod_incident]);
            }
        }
    );
}


export const useMutationArchiveIncident = (cod_incident) => {
    const queryClient = useQueryClient();
    return useMutation(
        archiveTicket,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([INCIDENTS_LIST, cod_incident]);
            }
        }
    );
}