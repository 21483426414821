import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutateCreateHabilidadDocente } from '../../hooks/docentes.hooks';

const CreateHabilidadDocente = ({ idDocente, handleShow }) => {

    const { mutate: mutateCreateHabilidad } = useMutateCreateHabilidadDocente();

    const initialValues = {
        id_docente: idDocente,
        habilidad: "",
    }

    const validationSchema = Yup.object({
        habilidad: Yup.string().required('Este campo es requerido')
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            mutateCreateHabilidad(values)
            handleShow(false)
            formik.resetForm()
        }
    })

  return (
    <Form
        onSubmit={formik.handleSubmit}
    >
        <Form.Group>
            <Form.Control 
                name='habilidad'
                onChange={formik.handleChange}
                value={formik.values.habilidad}
                placeholder="Escribe la habilidad del docente"

            />
            <button type='submit' className="btn ge-btn-primary btn-block mt-3">
                Crear Habilidad
            </button>
        </Form.Group>
    </Form>
  )
}

export default CreateHabilidadDocente