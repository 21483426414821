import React from "react";
import TablaGastos from "../../../components/Contabilidad/Gastos/TablaGastos";
import LayoutAdmin from "../../../components/layouts/LayoutAdmin";

const GastosScreen = () => {
	return (
		<LayoutAdmin contentTitle="" routeTitle="Gastos">
			<TablaGastos />
		</LayoutAdmin>
	);
};

export default GastosScreen;
