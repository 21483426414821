import React, { useEffect, useState } from "react";
import { getTimeResolvedTicket } from "../../../controllers/incidencias/incidencias.controller";
import { formatMinutes } from "../../../utils/time";

const CardTimeCloseTicket = () => {
	
	const [avgTime, setAvgTime] = useState(0);
	
	const getAvgTime = async () => {
		const data = await getTimeResolvedTicket();
		if(data) {
			const data_ = parseInt(data);
			setAvgTime(data_);
		} else {
			setAvgTime(0);
		}
	}
	
	useEffect(() => {
		getAvgTime();
	} , []);
	
	
	return (
		<div className="card ge-bt-primary">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column align-items-center">
						<h5 className="ge-title txt-bold">Tiempo de resolución</h5>
						<span className="text-muted">Tiempo promedio en que un ticket tarda en resolverse</span>
						<div className="my-3">
							<img
								src="/images/clock.png"
								alt="clock"
								// className="img-fluid"
								style={{ maxWidth: "120px" }}
							/>
						</div>
						<div className="mt-2">
							<span className="txt-bold ge-h2">
								{
									formatMinutes(avgTime)
								}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardTimeCloseTicket;
