import React, { useMemo, useState } from "react";
import HeaderSearchTable from "../Custom/HeaderSearchTable";
import * as FaIcons from "react-icons/fa";
import LoaderSmall from "../Loaders/LoaderSmall";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import CustomModal from "../Modal/CustomModal";
import ListaCursos from "./ListaCursos";
import { useMutation, useQueryClient } from "react-query";
import { deleteCurso } from "../../controllers/rutas/rutas";

const DatosCursos = ({ id_ruta, cursos }) => {
	const { uId } = useParams();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const {
		mutate,
		error: errorCreate,
		isLoading: isLoadingCreate,
	} = useMutation(deleteCurso, {
		onSuccess: () => {
			queryClient.invalidateQueries(["getRuta", uId]);
		},
	});

	const [show, setShow] = useState(false);

	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
	const handleSendToEdit = (slugCurso) => {
		navigate(`/editar-curso/${slugCurso}`);
	};

	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={cursos}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
				columnsToFilter={["nombre_curso"]}
				placeholder="Buscar por nombre del curso"
			/>
		);
	}, [filterText, resetPagination]);

	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "#",
				cell: (row) => row.id_curso,
				wrap: true,
			},
			{
				name: "Nombre Curso",
				selector: (row) => (
					<div style={{ textAlign: "left", width: "100%" }}>
						{" "}
						{row.nombre_curso}{" "}
					</div>
				),
				sortable: true,
			},
			{
				name: "Acciones",
				cell: (row) => (
					<div className="d-flex">
						<button
							className="btn ge-bg-primary txt-white btn-sm mr-2"
							title="Editar"
							onClick={() => handleSendToEdit(row.slug)}
						>
							<FaIcons.FaCogs />
						</button>
						<button
							className="btn btn-danger btn-sm"
							title="Deshabilitar"
							onClick={() =>
								mutate({
									id_ruta: id_ruta,
									id_curso: row.id_curso,
								})
							}
						>
							<FaIcons.FaTrash />
						</button>
					</div>
				),
			},
		],
		[]
	);

	return (
		<>
			<CustomModal
				title={"Agregar curso"}
				show={show}
				handleShow={setShow}
			>
				<ListaCursos id_ruta={id_ruta} handleShow={setShow} />
			</CustomModal>
			
			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-start my-3">
						{" "}
						<button
							className="btn ge-btn-primary txt-white"
							onClick={() => setShow(!show)}
						>
							Agregar curso
						</button>{" "}
					</div>
					<DataTable
						className="custom-datatable"
						columns={columns}
						data={filteredItems.length > 0 ? filteredItems : cursos}
						subHeader
						subHeaderComponent={searchComponentMemo}
						pagination
						responsive
						fixedHeader
						fixedHeaderScrollHeight="300px"
						progressComponent={<LoaderSmall />}
					/>
				</div>
			</div>
		</>
	);
};

export default DatosCursos;
