

export const roles = {
    ESTUDIANTE: 1,
    ADMIN: 2,
    DOCENTE: 3,
    MARKETING: 4,
    MANAGER: 5,
    CONTABILIDAD: 6,
}

export const modulos_id = {
    Metricas: 1,
    Escuelas: 2,
    Rutas: 3,
    Cursos: 4,
    Docentes: 5,
    Suscripciones_y_cursos: 6,
    Incidencias: 7,
    Lives: 8,
    Convocatorias_laborales: 9,
    Estudiantes: 10,
    BlogeHistoriasdeExito: 11,
    Contabilidad: 12,
    Facturas: 13,
    Planes: 14,
    Marketing: 15,
    Ajustes: 16,
    Usuarios_y_Roles: 17,
}