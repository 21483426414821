
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createLive, getLiveById, getLives, getLivesEnVivo, toggleDeleteEnVivo, toggleDeleteLive, togglePublishEnVivo, togglePublishLive, updateLive } from "../controllers/lives/lives.controller";

const KEY_LIVES = "lives";
const KEY_ENVIVOS = "getEnVivos";
export const useQueryLives = () => {
    return useQuery(
        [KEY_LIVES],
        () => getLives(),
        {
            retry: 1,
            refetchOnWindowFocus: false,
        }
    )
}

export const useQueryLivesEnVivo = () => {
    return useQuery(
        [KEY_ENVIVOS],
        () => getLivesEnVivo(),
        {
            retry: 1,
            refetchOnWindowFocus: false,
        }
    )
}

export const useQueryOneLive = (id) => {
    return useQuery(
        [KEY_LIVES, id],
        () => getLiveById(id),
        {
            retry: 2,
            refetchOnWindowFocus: false,
            enabled: !id ? false : true,
        }
    )
}

export const useMutateCreateLive = () => {
    const queryClient = useQueryClient();
    return useMutation(
        createLive,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([KEY_LIVES]);
            }
        }
    )
}


export const useMutateEditLive = (id) => {
    const queryClient = useQueryClient();
    return useMutation(
        updateLive,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([KEY_LIVES]);
                queryClient.invalidateQueries([KEY_LIVES, id]);
            }
        }
    )
}


export const useMutateTogglePublishLive = () => {
    const queryClient = useQueryClient();
    return useMutation(
        togglePublishLive,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([KEY_LIVES]);
            }
        }
    )
}

export const useMutateTogglePublishEnVivo = () => {
    const queryClient = useQueryClient();
    return useMutation(
        togglePublishEnVivo,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([KEY_ENVIVOS]);
            }
        }
    )
}

export const useMutateToggleDeleteLive = () => {
    const queryClient = useQueryClient();
    return useMutation(
        toggleDeleteLive,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([KEY_LIVES]);
            }
        }
    )
}

export const useMutateToggleDeleteEnVivo = () => {
    const queryClient = useQueryClient();
    return useMutation(
        toggleDeleteEnVivo,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([KEY_ENVIVOS]);
            }
        }
    )
}