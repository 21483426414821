import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import HeaderSearchTable from "../Custom/HeaderSearchTable";
import LoaderSmall from "../Loaders/LoaderSmall";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import ReactDatePicker from "react-datepicker";
import { getNewStudents } from "../../controllers/metricas/metricas.controller";
import CustomInputDate from "../Custom/CustomInputDate";
import dayjs from "dayjs";

const NewUsersTable = () => {
    
    /* Init week dates */
    const actualDay = new Date();
	let daysAgo7 = new Date();
	daysAgo7.setDate(actualDay.getDate() - 6);
	
	const initialState = [daysAgo7, actualDay];
    
	/* States */
	const [dateRange, setDateRange] = useState(initialState);
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [startDate, endDate] = dateRange;
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
	
	const getData = async () => {
		setIsLoading(true);
		const data_ = await getNewStudents(startDate, endDate);
		if (data_) {
			setData(data_);
		}
		setIsLoading(false);
	};
    
	useEffect(() => {
		if (startDate && endDate) {
			getData();
		}
	}, [dateRange]);
    
    
	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={data}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
				placeholder="Buscar por nombre, apellido o correo"
                columnsToFilter={["nombres", "apellidos", "correo"]}
			/>
		);
	}, [filterText, resetPagination]);

	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "#",
				cell: (row, index) => index + 1,
				width: "10%",
			},
			{
				name: "Nombres y apellidos",
				selector: (row) => row.nombres + " " + row.apellidos,
				sortable: true,
			},
			{
				name: "Correo",
				selector: (row) => row.correo,
			},
			{
				name: "Teléfono",
				cell: (row) =>
					row.celular ? (
						<>
							<FaIcons.FaPhone className="text-primary mr-1" />
							{row.celular}
						</>
					) : (
						<BsIcons.BsX className="text-danger" />
					),
			},
			{
				name: "Fecha de registro",
				selector: (row) => dayjs(row.fecha_registro).format("DD/MM/YYYY"),
			},
		],
		[]
	);

	return (
		<div className="card p-2">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column flex-sm-row justify-content-between align-items-center">
                        <div className="mb-3 mb-md-0">
                            <img
                                src="/images/file-icon.png"
                                className="mr-2"
                                style={{ width: "40px" }}
                                alt="file-icon"
                            />
                            <span className="ge-h3 ge-title">Registro de Usuarios</span>
                        </div>
						<div>
							{/* Range Picker */}
							<span className="txt-bold ge-title">Periodo:</span>
							<ReactDatePicker
								locale={"es"}
								selectsRange={true}
								startDate={startDate}
								endDate={endDate}
								onChange={(update) => {
									setDateRange(update);
								}}
								isClearable={true}
								// className="form-control ge-form my-2"
								placeholderText="Seleccione un rango"
								dateFormat="yyyy-MM-dd"
								maxDate={actualDay}
								customInput={<CustomInputDate />}
							/>
						</div>
					</div>
                    <div className="col-12 mt-4">
                        <DataTable
                            className="custom-datatable"
                            columns={columns}
                            data={filteredItems.length > 0 ? filteredItems : data}
                            subHeader
                            subHeaderComponent={searchComponentMemo}
                            pagination
                            responsive
                            fixedHeader
                            fixedHeaderScrollHeight="300px"
                            progressPending={isLoading}
                            progressComponent={<LoaderSmall />}
                            noDataComponent={
                                <div className="text-center mt-4">
                                    <h5 className="text-muted">
                                        No hay información disponible
                                    </h5>
                                </div>
                            }
                        />
                    </div>
				</div>
			</div>
		</div>
	);
};

export default NewUsersTable;
