import React from "react";
import { Button } from "react-bootstrap";
import { useMutateDeleteOption } from "../../hooks/examen.hooks";
import { SwalToast } from "../../utils/toasts";


const Opcion = ({
	id_opcion,
	opcion_text,
	respuesta_correcta,
	eliminarOpcion,
}) => {
	
	//Hooks fetch
	const { mutate: mutateDeleteOption } = useMutateDeleteOption();
	
	const handleDelete = (idOption) => {
		mutateDeleteOption(idOption,{
			onSuccess: (data) => {
				eliminarOpcion(idOption);
				SwalToast.fire({
					title: "Opcion eliminada",
					icon: "success",
					showConfirmButton: false,
					timer: 1500,
				});
			},
			onError: (err) => {
				SwalToast.fire({
					icon: "error",
					title: "Error",
					text: 'Error al eliminar la opción',
				})
			}
		});
	}
	
	return (
		<div
			className="d-flex justify-content-between align-items-center w-100 my-2"
		>
			<div>
				{
					parseInt(respuesta_correcta) === 1 ? (
						<span
							style={{
								verticalAlign: "middle",
							}}
						>
							<i className="fas fa-check-circle text-success mr-2"></i>
							{opcion_text}
						</span>
					) : (
						<span
							style={{
								verticalAlign: "middle",
							}}
						>
							<i className="fas fa-times-circle text-danger mr-2"></i>
							{opcion_text}
						</span>
					)
				}
			</div>
			<div>
				<Button
					type="button"
					variant="danger"
					onClick={() => handleDelete(id_opcion)}
				>
					{" "}
					<i className="fa fa-trash" aria-hidden="true"></i>{" "}
				</Button>
			</div>
		</div>
	);
};

export default Opcion;
