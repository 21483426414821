import React from "react";
import CardBestCourses from "./admin/CardBestCourses";
import CardLiveRoutes from "./admin/CardLiveRoutes";
import CardReport from "./admin/CardReport";

const HomeAdmin = () => {
	return (
		<div className="row">
			<div className="col-12">
				<CardReport />
			</div>
			<div className="col-12 col-md-6">
				<CardLiveRoutes />
			</div>
			<div className="col-12 col-md-6">
                <CardBestCourses />
            </div>
		</div>
	);
};

export default HomeAdmin;
