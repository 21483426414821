import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import DataTable from "react-data-table-component";
import HeaderSearchTable from '../../components/Custom/HeaderSearchTable';
import LoaderSmall from '../../components/Loaders/LoaderSmall';
import CustomModal from '../../components/Modal/CustomModal';
import * as FaIcons from 'react-icons/fa';
import { useQueryHabilidadDocente, useMutateDeleteHabilidadDocente } from '../../hooks/docentes.hooks';
import CreateHabilidadDocente from './CreateHabilidadDocente';

const HabilidadesDocente = () => {
    const [filterText, setFilterText] = useState("");
    const [filteredItems, setFilteredItems] = useState();
    const [resetPagination, setResetPagination] = useState(false);
    const [ showCreate, setShowCreate ] = useState(false);

    const { id_docente } = useParams();

    const { mutate: mutateDeleteHablidadDocente } = useMutateDeleteHabilidadDocente();
    const {data: habilidades, isLoading } = useQueryHabilidadDocente(id_docente);


        	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={habilidades}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
                columnsToFilter={["habilidad"]}
				placeholder="Buscar por habilidad"
			/>
		);
	}, [filterText, resetPagination]);


    /* Config Table */
	const columns = useMemo(
        () => [
          {
            name: "#",
            cell: (row, index) => index + 1,
            width: "5%",
          },
          {
            name: "Habilidades",
            selector: (row) => row.habilidad,
            sortable: true,
          },
          {
            name: "Acciones",
            cell: (row) => (
              <div className="d-flex">
                <button
                  className="btn btn-danger btn-sm mr-2"
                  onClick={()=> handleDeleteHabilidad(row.id)}
                >
                  <FaIcons.FaTrash />
                </button>
              </div>
            )}
        ],[]);

const handleDeleteHabilidad = (id_registro_habilidad) => {
    mutateDeleteHablidadDocente(id_registro_habilidad);
}
 
  return (
    <LayoutAdmin contentTitle="" routeTitle="Habilidades docente">
        <CustomModal title={'Crear Habilidad'} show={showCreate} handleShow={setShowCreate}>
            <CreateHabilidadDocente idDocente={id_docente} handleShow={setShowCreate}/>
        </CustomModal>
        <div className='row'>
                <div className='col-12'>
                    <div className="card p-2">
                      <div className="card-header ge-noborder">
                        <div className="d-flex justify-content-between">
                          <div>
                            <img
                              src="/images/file-icon.png"
                              className="mr-2"
                              style={{ width: "40px" }}
                              alt="file-icon"
                            />
                            <span className="ge-h3 ge-title">
                              Lista de Habilidades
                            </span>
                          </div>
                          <div className="">
                            <button
                              className="btn ge-btn-primary btn-sm"
                              onClick={() => setShowCreate(true)}
                            >
                              <FaIcons.FaPlus />
                              <span className="ml-2">Agregar Habilidades</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <DataTable
                          className="custom-datatable"
                          columns={columns}
                          data={
                            filteredItems?.length > 0
                              ? filteredItems
                              : habilidades
                          }
                          subHeader
                          subHeaderComponent={searchComponentMemo}
                          pagination
                          responsive
                          fixedHeader
                          fixedHeaderScrollHeight="300px"
                          progressPending={isLoading}
                          progressComponent={<LoaderSmall />}
                        />
                        </div>
                    </div>
                </div>
           </div>
    </LayoutAdmin>
  )
}

export default HabilidadesDocente