import { useFormik } from "formik";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { getCursos } from "../../controllers/rutas/rutas";
import * as yup from "yup";
import CustomSelectCursos from "../../components/Custom/CustomSelectCursos";
import { add_curso } from "../../controllers/historias/historias";



const ListaCursos = ({ id_historia, handleShow }) => {
	const { uId } = useParams();
    
	const { data, error, isLoading } = useQuery(["getCursos"], getCursos, {
		refetchOnWindowFocus: false,
	});
	const queryClient = useQueryClient();
	const {
		mutate,
		error: errorCreate,
		isLoading: isLoadingCreate,
	} = useMutation(add_curso, {
		onSuccess: () => {
			queryClient.refetchQueries(["getHistoria",uId]);
			handleShow(false);
		},
	});
	const initialValues = {
		unique: uId,
		id_historia: id_historia,
		id_curso: "",
	};

	const validationSchema = yup.object({
		id_curso: yup.string().required("El campo es requerido"),
	});

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values) => {
			const datos = { ...values };
			// datos.contenido=contenidoHTML
			console.log(datos);
			mutate(datos);
		},
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<div className="row">
				<div className="col-md-12">
					<div className="form-group mb-3">
						{/* <label>Curso:</label> */}
						<CustomSelectCursos
							className=""
							onChange={(value) =>
								formik.setFieldValue("id_curso", value.id_curso)
							}
							value={formik.values.id_curso}
							options={data}
						/>
						<p> {formik.errors.id_curso} </p>
					</div>
					<div className="form-group mt-4">
						<button
							type="submit"
							className="btn ge-btn-primary btn-block"
						>
							Agregar curso
						</button>
					</div>
				</div>
			</div>
		</form>
	);
};

export default ListaCursos;
