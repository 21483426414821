import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { obtenerTiempoVisualizacion  } from "../../../controllers/dashboard_roles/manager.controller";
import { formatSecondsHMS } from "../../../utils/time";

const TiempoVisualizacion = () => {
    
    const [data, setData] = useState(0);
    
    const getData = async () => {
        const data_ = await obtenerTiempoVisualizacion();
        if(data_){
            setData(data_.tiempo_visualizacion);
        }
    }
    
    useEffect(() => {
		getData();
        
        return () => {
            setData(0);
        }
    }, []);
    
	return (
		<div className="card ge-bl-2 h-100">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column align-items-center mb-4">
                        <h5 className="ge-title txt-bold">
                            Tiempo promedio de visualización de las clases
                        </h5>
					</div>
                    <div className="col-12 my-2 d-flex justify-content-center">
                        <span className="ge-h1 txt-bold ge-title text-center">
							{formatSecondsHMS(data)}
						</span>
                    </div>
				</div>
			</div>
		</div>
	);
};

export default TiempoVisualizacion;
