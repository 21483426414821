import { getAuthAxios, getPublicAxios } from "../../axios/axiosCustom"
import { deleteTokenLS, getTokenLS, setTokenLS } from "../../utils/localStorage";
import { hasExpiredToken } from "../../utils/token";
import { userTypes } from "../types"
import { startLoading, stopLoading } from "./uiActions";


export const loginUserAction = (user) =>{
    return async (dispatch) => {
        dispatch(startLoading());
        try{
            const axios = getPublicAxios();
            const response = await axios.post("/auth/admin/login", user);
            if(!response.data.error){
                //login correcto
                setTokenLS(response.data.encode);
                dispatch(loginUserToStore(response.data.decode));
                dispatch(stopLoading());
            }
            else{
                console.log('err in try',response.data);
                dispatch(stopLoading());
            }
        }catch(error){
            console.log('err in catch',error)
            dispatch(stopLoading());
        }
        
    }
}


export const logoutUserAction = () =>{
    return async (dispatch) => {
        dispatch(startLoading());
        //eliminar la cookie
        deleteTokenLS();
        dispatch(logoutUserToStore());
        dispatch(stopLoading());
    }
}


export const validateSessionAction = () => {
	return async (dispatch) => {
		dispatch(startLoading());
		const token = getTokenLS();
		if (token) {
			const {isExpired, user} = hasExpiredToken(token);
			if (isExpired) {
				//borrar el token de la cookie
				deleteTokenLS();
				//limpiar usuario del store
				dispatch(logoutUserToStore());
				//limpiar el state de ui
				dispatch(stopLoading());
			}else{
				//mantener la sesion
				dispatch(loginUserToStore(user));
				dispatch(stopLoading());
			}
		}else{
			dispatch(stopLoading());
		}
	}
}







export const loginUserToStore = (user) => {
    return {
        type: userTypes.LOGIN_USER,
        payload: user
    }
}

export const logoutUserToStore = () => {
    return {
        type: userTypes.LOGOUT_USER,
    }
}