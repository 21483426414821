import { map } from "lodash";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import DataTable from "react-data-table-component";
import ReactDatePicker from "react-datepicker";
import { useQueryGastos } from "../../../hooks/contabilidad/gastos/gastos.hooks";
import { FaIcons } from "../../../icons/export-reacticons";
import CustomInputDate from "../../Custom/CustomInputDate";
import HeaderSearchTable from "../../Custom/HeaderSearchTable";
import LoaderSmall from "../../Loaders/LoaderSmall";
import { Link } from "react-router-dom";

const tipos_de_gasto = [
  { id: 1, nombre: "Costos Operativos" },
  { id: 2, nombre: "Costos de ventas" },
  { id: 3, nombre: "Gastos administrativos" },
  { id: 4, nombre: "Gastos financieros" },
];

const TablaGastos = () => {
  const [startDate, setStartDate] = useState(new Date());
  const { data: gastosList, isLoading, refetch } = useQueryGastos(startDate);
  const [expanded, setExpanded] = useState(true);

  const renderTableBody = () => {
    return tipos_de_gasto.map(({ id, nombre }, index) => {
      const rowsFromTipo =
        gastosList?.filter((row) => parseInt(row.id_tipo) === parseInt(id)) ??
        [];

      let subRowsFromTipo = rowsFromTipo.map((row) =>
        row.sub_detalle.length > 0 ? row.sub_detalle.length : 0
      );
      subRowsFromTipo = subRowsFromTipo.reduce((a, b) => a + b, 0);

      if (rowsFromTipo.length === 0) return null;

      return rowsFromTipo.map((row, index) =>
        index === 0 ? (
          <>
            <tr className="text-center" key={`row-id-${nombre}-${index}`}>
              <th
                rowSpan={
                  rowsFromTipo.length === 0
                    ? 1
                    : rowsFromTipo.length + subRowsFromTipo
                }
                scope="rowGroup"
              >
                {nombre}
              </th>
              <td>
                {row.sub_detalle.length > 0 ? (
                  <b>
                    {row.detalle} <FaIcons.FaChevronDown />
                  </b>
                ) : (
                  row.detalle
                )}
              </td>
              <td>{row.base_imponible}</td>
              <td>{row.IGV}</td>
              <td>{row.monto_total}</td>
              <td>{row.monto_neto}</td>
              <td>{row.IR}</td>
              <td>{row.monto_bruto}</td>
            </tr>
            {row.sub_detalle.length > 0 &&
              row.sub_detalle.map((subRow, index) => (
                <tr className="text-center" key={`sub-row-id-${nombre}-${index}`}>
                  <td className="pl-2">{subRow.detalle}</td>
                  <td>{subRow.base_imponible}</td>
                  <td>{subRow.IGV}</td>
                  <td>{subRow.monto_total}</td>
                  <td>{subRow.monto_neto}</td>
                  <td>{subRow.IR}</td>
                  <td>{subRow.monto_bruto}</td>
                </tr>
              ))}
          </>
        ) : (
          <>
            <tr className="text-center" key={`row-id-${nombre}-${index}`}>
              <td>
                {row.sub_detalle.length > 0 ? (
                  <b>
                    {row.detalle} <FaIcons.FaChevronDown />
                  </b>
                ) : (
                  row.detalle
                )}
              </td>
              <td>{row.base_imponible}</td>
              <td>{row.IGV}</td>
              <td>{row.monto_total}</td>
              <td>{row.monto_neto}</td>
              <td>{row.IR}</td>
              <td>{row.monto_bruto}</td>
            </tr>
            {row.sub_detalle.length > 0 &&
              row.sub_detalle.map((subRow, index) => (
                <tr className="text-center" key={`sub-row-id-${nombre}-${index}`}>
                  <td className="pl-2">{subRow.detalle}</td>
                  <td>{subRow.base_imponible}</td>
                  <td>{subRow.IGV}</td>
                  <td>{subRow.monto_total}</td>
                  <td>{subRow.monto_neto}</td>
                  <td>{subRow.IR}</td>
                  <td>{subRow.monto_bruto}</td>
                </tr>
              ))}
          </>
        )
      );
    });
  };

  useEffect(() => {
    refetch();
  }, [startDate]);

  return (
    <div className="card">
      <div className="d-flex justify-content-between card-header card-no-after ge-noborder mt-3">
        <div>
          <img
            src="/images/file-icon.png"
            className="mr-2"
            style={{ width: "40px" }}
            alt="file-icon"
          />
          <span className="ge-h3 ge-title">Gastos</span>
        </div>
        <div className="">
          <Link
            to="/contabilidad/gastos/crear"
            className="btn ge-btn-deg-primary btn-sm"
          >
            <FaIcons.FaPlus />
            <span className="ml-2">Registrar gasto</span>
          </Link>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div>
              {/* Range Picker */}
              <span className="txt-bold ge-title">Mes:</span>
              <ReactDatePicker
                locale={"es"}
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                isClearable={true}
                placeholderText="Seleccione en mes y año"
                dateFormat="MM/yyyy"
                customInput={<CustomInputDate />}
                showMonthYearPicker
                showFullMonthYearPicker
                showTwoColumnMonthYearPicker
                maxDate={new Date()}
              />
            </div>
          </div>
          <div className="col-12 p-2">
            <table className="w-100">
              <thead>
                <tr>
                  <th colSpan="1"></th>
                  <th colSpan="1"></th>
                  <th colSpan="1"></th>
                  <th colSpan="1"></th>
                  <th colSpan="1"></th>
                  <th colSpan="3">
                    <div>
                      <span className="d-inline-block w-100 text-center">
                        Recibo por Honorarios
                      </span>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th colSpan="1">
                    <div>Tipo de gasto</div>
                  </th>
                  <th colSpan="1">
                    <div>
                      <span>Detalle</span>
                    </div>
                  </th>
                  <th colSpan="1">
                    <div>
                      <span>Base Imponible</span>
                    </div>
                  </th>
                  <th colSpan="1">
                    <div>
                      <span>IGV</span>
                    </div>
                  </th>
                  <th colSpan="1">
                    <div>
                      <span>Total</span>
                    </div>
                  </th>
                  <th colSpan="1">
                    <div>
                      <span>Monto</span>
                    </div>
                  </th>
                  <th colSpan="1">
                    <div>
                      <span>Impuesto a la renta</span>
                    </div>
                  </th>
                  <th colSpan="1">
                    <div>
                      <span>Total</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>{renderTableBody()}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TablaGastos;
