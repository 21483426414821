import React from "react";
import * as FaIcons from "react-icons/fa";

const FileItem = ({ file, errors = null, handleDeleteFile }) => {
    
    const handlePropagation = (e) => {
        e.stopPropagation();
    }
    
    
	return (
		<div
            onClick={handlePropagation}
			key={file.path}
			className={`d-flex justify-content-md-between align-items-center dz-file-item ${
				errors ? "file-error" : "file-success"
			}`}
		>
			<div className="d-flex flex-column file-details">
				<span>
					{file.path}
				</span>
                
				{errors && <span className="span-error">*{" "}{errors[0].message}</span>}
			</div>
            <button
                title="Eliminar"
                type="button"
                className="btn-delete-file"
                onClick={() => handleDeleteFile(file)}
            >
                <FaIcons.FaTimes />
            </button>
		</div>
	);
};

export default FileItem;
