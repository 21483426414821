import { docenteTypes } from "../types"


export const setDocenteEditAction = (docente) => {
    return {
        type: docenteTypes.SET_DOCENTE_EDIT,
        payload: docente
    }
}


export const clearDocenteEditAction = () => {
    return {
        type: docenteTypes.CLEAR_DOCENTE_EDIT,
    }
}
