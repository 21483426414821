import { useMutation, useQuery, useQueryClient } from "react-query";
import { createInstagramPost, editInstagramPost, getInstagramPostById, getInstagramPosts, toggleInstagramPostActive } from "../../../controllers/marketing/posts/instagramPosts.controller";

const KEY_IG_POSTS = 'InstagramPosts';

export const useQueryInstagramPosts = () => {
    return useQuery(
        [KEY_IG_POSTS],
        () => getInstagramPosts(), 
        {
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}

export const useQueryOneInstagramPost = (id) => {
    return useQuery(
        [KEY_IG_POSTS, id],
        () => getInstagramPostById(id),
        {
            enabled: (id && id !== "") ? true : false,
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}


export const useMutateCreateInstagramPost = () => {
    const queryClient = useQueryClient();
    return useMutation(
        createInstagramPost,
        {
            onSuccess: () => {
                queryClient.invalidateQueries([KEY_IG_POSTS]);
            }
        }
    );
}

export const useMutateEditInstagramPost = (id) => {
    const queryClient = useQueryClient();
    return useMutation(
        editInstagramPost,
        {
            onSuccess: () => {
                queryClient.invalidateQueries([KEY_IG_POSTS]);
                queryClient.invalidateQueries([KEY_IG_POSTS, id]);
            }
        }
    );
}

export const useMutateToggleInstagramPost = (id) => {
    const queryClient = useQueryClient();
    return useMutation(
        toggleInstagramPostActive,
        {
            onSuccess: () => {
                queryClient.invalidateQueries([KEY_IG_POSTS]);
            }
        }
    );
}