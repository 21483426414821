import React, { useEffect, useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import CustomInputDate from "../Custom/CustomInputDate";
import DataTable from "react-data-table-component";
import LoaderSmall from "../Loaders/LoaderSmall";
import { getPlayHours } from "../../controllers/metricas/metricas.controller";
import { SwalToast } from "../../utils/toasts";
import dayjs from "dayjs";


const CardHorasReproduccion = () => {
    
    const actualDay = new Date();
	let daysAgo7 = new Date();
	daysAgo7.setDate(actualDay.getDate() - 6);
	
	const initialState = [daysAgo7, actualDay];
	
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [dateRange, setDateRange] = useState(initialState);
	const [startDate, endDate] = dateRange;
    
    /* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "#",
				cell: (row, index) => index + 1,
				width: "5%",
			},
			{
				name: "Horas",
				selector: (row) => row.horas,
				sortable: true,
			},
			{
				name: "Fecha",
				selector: (row) => dayjs(row.fecha).format("DD/MM/YYYY"),
				sortable: true,
			},
		],
		[]
	);
    
    const getData = async () => {
		setIsLoading(true);
		const data_ = await getPlayHours(startDate, endDate);
		if (data_) {
			setData(data_);
		}
		setIsLoading(false);
	};
    
    useEffect(() => {
		if (startDate && endDate) {
			getData();
		}
	}, [dateRange]);
    
	return (
		<div className="card h-100">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column flex-xl-row justify-content-between align-items-center">
						<h5 className="ge-title txt-bold">Horas de reproducción</h5>
						<div>
							{/* Range Picker */}
							<span className="txt-bold ge-title">Periodo:</span>
							<ReactDatePicker
								locale={"es"}
								selectsRange={true}
								startDate={startDate}
								endDate={endDate}
								onChange={(update) => {
									setDateRange(update);
								}}
								isClearable={true}
								// className="form-control ge-form my-2"
								placeholderText="Seleccione un rango"
								dateFormat="yyyy-MM-dd"
								maxDate={actualDay}
								customInput={<CustomInputDate />}
							/>
						</div>
					</div>
					<div className="col-12 mt-2">
						<DataTable
							className="custom-datatable"
							columns={columns}
							data={data}
							responsive
							fixedHeader
							fixedHeaderScrollHeight="300px"
							progressPending={isLoading}
							progressComponent={<LoaderSmall />}
							noDataComponent={
								<div className="text-center mt-4">
									<h5 className="ge-title txt-bold">
										No hay información disponible
									</h5>
								</div>
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardHorasReproduccion;
