import { getAuthAxios } from "../../axios/axiosCustom";
import Swal from "sweetalert2";



export const getAllDocentes = async () => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get("/docente/all_docentes");
        if(response.data.status){
            return response.data.docentes;
        }
        throw new Error('Error al obtener los docentes');
    }catch(e){
        throw new Error('Error del servidor');
    }
}

export const getPagosDocentes = async (id_user) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/docente/docente_pagos/${id_user}`);
        if(response.data.status){
            return response.data.data;
        }
        throw new Error('Error al obtener los pagos');
    }catch(e){
        throw new Error('Error del servidor');
    }
}

export const getReportePagosDocente = async (id_user) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/docente/getDataPagoDocente/${id_user}`);
        if(response.data.status){
            return response.data.data;
        }
        throw new Error('Error al obtener los pagos');
    }catch(e){
        throw new Error('Error del servidor');
    }
}

export const getUltimoReporteDocente = async (id_docente) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/docente/descargar_ultimo_reporte/${id_docente}`);
        if(response.data.status){
            return response.data.data[0].comprobante
        }
        throw new Error('Error al obtener los pagos');
    }catch(e){
        throw new Error('Error del servidor');
    }
}


export const createDocente = async (docente) => {
    const axios = getAuthAxios();
    
    const formData = new FormData();
    formData.append('nombres', docente.nombres);
    formData.append('apellidos', docente.apellidos);
    formData.append('correo', docente.correo);
    formData.append('celular', docente.telefono);
    formData.append('pais', docente.pais);
    formData.append('descripcion', docente.descripcion);
    formData.append('escuela', docente.escuela);
    formData.append('password', docente.password);
    formData.append('img_perfil', docente.imagen);
    formData.append('pago', JSON.stringify(docente.pago))
    
    try{
        const response = await axios.post("/docente/create_docente", formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if(response.data.status){
            return response.data;
        }
        throw new Error('Error al crear el docente');
    }catch(e){
        throw new Error('Error del servidor');
    }
}

export const realizarPagoDocente = async (id_registro) => {
    const axios = getAuthAxios();

    try {
        const response = await axios.post(`/docente/realizar_pago_docente/${id_registro}`)
        if(response.data.status){
            return response.data.data
        } else {
            return null
        }
    } catch (error) {
        console.log(error)
    }
};

/* Toogle active */
export const toggleActiveDocente = async ({idDocente, isEliminated}) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.put(`/docente/delete_docente/${idDocente}`, {
            eliminado: isEliminated
        });
        if(response.data.status){
            return response.data;
        }
        throw new Error('Error al eliminar el docente');
    }catch(e){
        throw new Error('Error del servidor');
    }
}


export const editDocente = async ({idDocente, dataDocente}) => {
    const axios = getAuthAxios();
    let dataToApi;
    if(dataDocente?.imgPerfil){
        dataToApi  = {
            nombres: dataDocente.nombres,
            apellidos: dataDocente.apellidos,
            correo: dataDocente.correo,
            password: dataDocente.password,
            celular: dataDocente.telefono,
            imgPerfil: dataDocente.imgPerfil,
            descripcion: dataDocente.about
        }
    }else{
        dataToApi  = {
            nombres: dataDocente.nombres,
            apellidos: dataDocente.apellidos,
            correo: dataDocente.correo,
            password: dataDocente.password,
            celular: dataDocente.telefono,
            descripcion: dataDocente.about
        }
    }
    
    try{
        const response = await axios.post(`/docente/edit_docente/${idDocente}`,dataToApi, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if(response.data.status){
            return response.data;
        }else{
            throw new Error('Error al editar el docente');
        }
    }catch(e){
        throw new Error('Error del servidor');
    }
}

export const deleteDocenteById = async (id_docente) => {
    const axios = getAuthAxios();
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
  
    await  Swal.fire({
        title: '¿Estás seguro?',
        text: "Una vez eliminado, no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
            const response = await axios.delete(`/docente/delete_docente_admin/${id_docente}`);
              
            if(response.data.status){
                Toast.fire({
                    icon: 'success',
                    title: "Docente eliminado con exito"
                  })
            }
            else{
                Toast.fire({
                    icon: 'warning',
                    title: "Error al eliminar el docente"
                  })
            }
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      })
  }

export const selectDocentes = async () => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get("/docente/all_select_docentes");
        if(response.data.status){
            return response.data.docentes;
        }
        throw new Error('Error al obtener los docentes');
    }catch(e){
        throw new Error('Error del servidor');
    }
}


export const getCoursesFromDocente = async (idDocente) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/docente/courses/${idDocente}`);
        if(response.data.status){
            return response.data.data;
        }
        throw new Error('Error al obtener los cursos');
    }catch(e){
        throw new Error('Error del servidor');
    }
}


export const getPerformanceFromDocente = async (idDocente) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/docente/performance/${idDocente}`);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(e){
        return null;
    }
}

export const getCommentsFromDocente = async (idDocente) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/docente/comments/${idDocente}`);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(e){
        return null;
    }
}

export const getBlogsFromDocente = async (idDocente) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/docente/blog_posts/${idDocente}`);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(e){
        return null;
    }
}



export const getTop5Docentes = async () => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get("/docente/top_docentes_valoracion?limite=5");
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(e){
        return null;
    }
}

export const getTopDocentes = async () => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get("/docente/top_docentes_valoracion");
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(e){
        return null;
    }
}

export const getWeekCommentsPosts = async (idDocente) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/docente/week_comments_posts/${idDocente}`);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(e){
        return null;
    }
}

export const getWeekCommentsClasses = async (idDocente) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/docente/week_comments_class/${idDocente}`);
        if(response.data.status){
            return response.data.data;
        }
        throw new Error('Error al obtener los comentarios');
    }catch(e){
        throw new Error('Error del servidor');
    }
}

/* Habilidades Docentes */

export const getHabilidadDocente = async (id_docente) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/docente/docente_skills/${id_docente}`);
        if(response.data.status){
            return response.data.data;
        }
        throw new Error('Error al obtener los comentarios');
    }catch(e){
        throw new Error('Error del servidor');
    }
};

export const createHabilidadDocente = async (datos) => {
    const axios = getAuthAxios()
    try {
        const response = await axios.post('/docente/create_docente_skill', datos)
        if(response.data.status){
            return response.data
        }
        throw new Error('Error al crear la habilidad del docente');
    } catch (error) {
        throw new Error('Error del servidor');
    }
};

export const deleteHabilidadDocente = async (id_habilidad) => {
    const axios = getAuthAxios();
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

  await  Swal.fire({
      title: '¿Estás seguro?',
      text: "Una vez eliminado, no se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
          const response = await axios.delete(`/docente/delete_docente_skills/${id_habilidad}`);
            
          if(response.status){
              Toast.fire({
                  icon: 'success',
                  title: "Habilidad eliminada"
                })
          }
          else{
              Toast.fire({
                  icon: 'warning',
                  title: response.data.message
                })
          }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
      }
    })
}