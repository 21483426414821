import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import DatosEscuela from "./DatosEscuela";
import DatosRutas from "./DatosRutas";
const TabEscuela = ({ escuela, rutas = [] }) => {
	return (
		<Tabs id="controller-escuela" defaultActiveKey={"datos"}>
			<Tab
				eventKey={"datos"}
				title={
					<div className="text-center">
						<FaIcons.FaCog />
						<p className="mt-2">Datos</p>
					</div>
				}
			>
				<DatosEscuela escuela={escuela} />
			</Tab>
			<Tab
				title={
					<div className="text-center">
						<FaIcons.FaRoute />
						<p className="mt-2">Rutas</p>
					</div>
				}
				eventKey={"rutas"}
			>
				<DatosRutas id_escuela={escuela.id_escuela} rutas={rutas} />
			</Tab>
		</Tabs>
	);
};

export default TabEscuela;
