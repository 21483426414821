import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllAfiliados, getAfiliadoById, aceptODeclineAfiliados, aceptODeclineComisiones, getCuentaAfiliadoById } from "../controllers/afiliados/afiliados";

const KEY_AFILIADOS = "AFILIADOSALL";

export const useQueryAllAfiliados = () => {
    return useQuery(
        [KEY_AFILIADOS], getAllAfiliados,
        {
            retry: 1,
            refetchOnWindowFocus: false,
        }
    );
}

export const useQueryGetAfiliadosById = (id_user) => {
    return useQuery(
        [KEY_AFILIADOS, id_user],
        () => getAfiliadoById(id_user),
        {
            retry: 1,
            refetchOnWindowFocus: false,
        }
    );
}

export const useQueryGetCuentaAfiliadosById = (id_user) => {
    return useQuery(
        [KEY_AFILIADOS, id_user],
        () => getCuentaAfiliadoById(id_user),
        {
            retry: 1,
            refetchOnWindowFocus: true,
        }
    );
}

export const useQueryAceptDeclineAfiliados = () => {
    const queryClient = useQueryClient();
    return useMutation(aceptODeclineAfiliados, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_AFILIADOS]);
        }
    });
}

export const useQueryAceptDeclineComisiones = () => {
    const queryClient = useQueryClient();
    return useMutation(aceptODeclineComisiones, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_AFILIADOS,data]);
        }
    });
}