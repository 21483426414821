import React, { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import Select from "react-select";
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import InscritosPorEscuela from '../../components/Metricas/InscritosPorEscuela';
import InscritosPorRuta from '../../components/Metricas/InscritosPorRuta';
import { dynamicSelect } from '../../controllers/select.controller';

const Inscripciones = () => {

	
	const [key, setKey] = useState("escuela");
	const [selectOption, setSelectOption] = useState({
		panelTitle: "",
		value: "",
		label: "",
	});
	
	const [optionValue, setOptionValue] = useState({
		value: "",
		label: "--- Seleccione una opción ---",
	});
	
	const [selectData, setSelectData] = useState([]);
	
	const changeSelectData = async (typeSelect) => {
		const data = await dynamicSelect(typeSelect);
		if (data) {
			let dataToOptions = data.map((item) => ({
				value: item.id,
				label: item.nombre,
			}));
			
			dataToOptions.unshift({
				value: "",
				label: "--- Seleccione una opción ---",
			});
			setSelectData(dataToOptions);
		}
	};
	
	

	useEffect(() => {
		changeSelectData(key);
		setSelectOption({
			panelTitle: key,
			value: "",
			label: "",
		});
		setOptionValue({
			value: "",
			label: "--- Seleccione una opción ---",
		});
	}, [key]);
    return (
    <LayoutAdmin contentTitle="" routeTitle="Inscripciones">
       <div className="card">
				<div className="card-header ge-noborder">
					<>
						<img
							src="/images/file-icon.png"
							className="mr-2"
							style={{ width: "40px" }}
							alt="file-icon"
						/>
						<span className="ge-h3 ge-title">
							Inscripciones
						</span>
					</>
				</div>
				<div className="card-body">
					<div className="row">
						{/* Tabs */}
						<Tab.Container
							id="tabs-students"
							defaultActiveKey="escuela"
						>
							<div className="col-12 col-md-6">
								<Nav variant="pills" className="d-flex">

									
									<Nav.Item>
										<Nav.Link className="btn-curso-estudiante"
											onClick={() => {
												setKey("escuela");
											}}
											eventKey="escuela"
										>
											Escuelas
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link className="btn-curso-estudiante"
											onClick={() => {
												setKey("ruta");
											}}
											eventKey="ruta"
										>
											Rutas
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</div>
							<div className="col-12 col-md-6">
								<Select
									placeholder="Seleccione una opción"
									// isClearable={true}
									// isLoading={false}
									isSearchable={true}
									options={selectData}
									onChange={(e) => {
										console.log(e);
										setOptionValue({
											value: e.value,
											label: e.label,
										});
										setSelectOption({
											...selectOption,
											value: e.value,
											label: e.label,
										});
									}}
									value={optionValue}
								/>
							</div>
							<div className="col-12 mt-4">
								<Tab.Content>
                                    <Tab.Pane eventKey="escuela">
										<InscritosPorEscuela
											selectOption={selectOption}
										
										/>
									</Tab.Pane>
									<Tab.Pane eventKey="ruta">
										<InscritosPorRuta
											selectOption={selectOption}
											
										/>
									</Tab.Pane>
								</Tab.Content>
							</div>
						</Tab.Container>
					</div>
				</div>
			</div>
    </LayoutAdmin>
    );
};

export default Inscripciones;