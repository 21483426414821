import { useMutation, useQuery, useQueryClient } from "react-query";
import { addOption, deleteOption, edit_question, getQuestionData } from "../controllers/cursos/examen/examen.controller";


const KEY_QUESTIONS = "Preguntas";
const KEY_QUESTION = "Pregunta";

export const useQueryQuestion = (id_pregunta) => {
    return useQuery(
        [KEY_QUESTION, id_pregunta],
        () => getQuestionData(id_pregunta),
        {
            retry: 1,
            refetchOnWindowFocus: false,
        }
    )
}

export const useMutateEditQuestion = (id_pregunta) => {
    const queryClient = useQueryClient();
    return useMutation(
        edit_question,
        {
            onSuccess: () => {
                queryClient.refetchQueries([KEY_QUESTION, id_pregunta]);
                queryClient.refetchQueries([KEY_QUESTIONS]);
            }
        }
    )
}


export const useMutateAddOption = () => {
    return useMutation(
        addOption
    )
}


export const useMutateDeleteOption = () => {
    return useMutation(
        deleteOption
    )
}