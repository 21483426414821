import Swal from "sweetalert2";
import { getAuthAxios } from "../../axios/axiosCustom";

export const getPosts = async () => {
    const axios = getAuthAxios();
    const response = await axios.get("/blog/getPosts");
    if(response.data.status){
        return response.data.data;
    }
    throw new Error(response.data.message);
}

export const getSelectUsers = async () => {
  const axios = getAuthAxios();
  const response = await axios.get("/info/getSelectUsers");
  if(response.data.status){
      return response.data.data;
  }
  throw new Error(response.data.message);
}


export const getPostsByParams = async (datos) => {
  if(datos.fecha)
  {
    const axios = getAuthAxios();
    const response = await axios.get(`/blog/getPostsByParams/${datos.fecha.startDate}/${datos.fecha.endDate}/${datos.escuela ? datos.escuela !==''?datos.escuela:null:null}`);
    if(response.data.status){
        return response.data.data;
    }
    throw new Error(response.data.message);
  }
}

export const getPostsByUid = async (id) => {
    const axios = getAuthAxios();
    const response = await axios.get(`/blog/getPosts/${id}`);
    if(response.data.status){
        return response.data.data;
    }
    throw new Error(response.data.message);
}

export const createPost = async (datos) => {
    const axios = getAuthAxios();
    const datos_file = new FormData();
    Object.keys(datos).forEach(key => datos_file.append(key, datos[key]));
    const response = await axios.post(`/blog/new_post`,datos_file);
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      

    if(response.data.status){
        Toast.fire({
            icon: 'success',
            title: response.data.message
          })
        return response.data;
    }
    throw new Error(response.data.error);
}

export const updatePost = async (datos) => {
    const axios = getAuthAxios();
    const datos_file = new FormData();
    Object.keys(datos).forEach(key => datos_file.append(key, datos[key]));
    const response = await axios.post(`/blog/edit_post`,datos_file);
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      

    if(response.data.status){
        Toast.fire({
            icon: 'success',
            title: response.data.message
          })
        return response.data;
    }
    throw new Error(response.data.error);
}

export const disableCommentPost = async (datos) => {
  const axios = getAuthAxios();
  const response = await axios.put(`/blog/delete_comment_post/${datos.id}`,datos);
  const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    

  if(response.data.status){
      Toast.fire({
          icon: 'success',
          title: response.data.message
        })
      return response.data;
  }
  throw new Error(response.data.error);
}

export const disablePost = async (datos) => {
  const axios = getAuthAxios();
  const response = await axios.put(`/blog/delete_post/${datos.id}`,datos);
  const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    

  if(response.data.status){
      Toast.fire({
          icon: 'success',
          title: response.data.message
        })
      return response.data;
  }
  throw new Error(response.data.error);
}

export const deletePostById = async (id_post) => {
  const axios = getAuthAxios();
  const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

  await  Swal.fire({
      title: '¿Estás seguro?',
      text: "Una vez eliminado, no se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
          const response = await axios.delete(`/blog/delete_post_admin/${id_post}`);
            
          if(response.data.status){
              Toast.fire({
                  icon: 'success',
                  title: response.data.message
                })
          }
          else{
              Toast.fire({
                  icon: 'warning',
                  title: response.data.message
                })
          }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
      }
    })
}