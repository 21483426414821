import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import { createPost } from '../../controllers/blog/posts';
import EditorGeneral from '../../components/Editor/EditorGeneral';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getEscuelas } from '../../controllers/escuelas/escuelas';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
const CreatePost = () => {
  const { data, error, isLoading } = useQuery(['getEscuelas'], getEscuelas, {
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    mutate,
    error: errorUpdate,
    isLoading: isLoadingUpdate,
  } = useMutation(createPost, {
    onSuccess: () => {
      queryClient.invalidateQueries('posts');
      navigate('/listar-posts', { replace: true });
    },
  });

  const { user } = useSelector((state) => state.user);

  const initialValues = {
    titulo: '',
    descripcion_corta: '',
    id_usuario: user.id_user,
    id_escuela: '',
    archivo: {},
  };

  const [datosPost, setDatosPost] = useState(initialValues);
  const [contenidoHTML, setContenidoHTML] = useState('');
  const [renderImage, setRenderImage] = useState(null);

  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const imgInputRef = useRef();

  //Cambia la imagen de portada
  const handleChangeImage = (e) => {
    e.preventDefault();
    setLoading(true);
    if (imgInputRef.current.files.length > 0) {
      //validar solo imagenes
      const file = e.target.files[0];
      if (file.type.indexOf('image') < 0) {
        setLoading(false);
        return;
      }
      //console.log(file);
      setImagePreview(URL.createObjectURL(file));
      formik.setFieldValue('archivo', file);
      setLoading(false);
    }
  };

  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png',
    'application/pdf',
  ];

  const validationSchema = yup.object({
    titulo: yup.string().required('El campo es requerido'),
    descripcion_corta: yup
      .string()
      .required('El campo es requerido')
      .max(350, 'Debe tener 350 carecteres o menos'),
    id_escuela: yup.string().required('El campo es requerido'),
    archivo: yup
      .mixed()
      .required('Debes seleccionar una imagen')
      .test(
        'fileFormat',
        'Archivo no permitido',
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
  });

  const formik = useFormik({
    initialValues: datosPost,
    validationSchema,
    onSubmit: async (values) => {
      const datos = { ...values };
      datos.contenido = contenidoHTML;
      //  console.log(datos)
      mutate(datos);
    },
  });

  useEffect(() => {
    if (formik.values.archivo.name) {
      setRenderImage(URL.createObjectURL(formik.values.archivo));
    }
  }, [formik.values.archivo]);

  return (
    <LayoutAdmin contentTitle='Crear post' routeTitle='Post'>
      <form onSubmit={formik.handleSubmit}>
        <div className='row'>
          <div className='col-md-12'>
            <div className='card'>
              <div
                className='card-header p-3'
                style={{ backgroundColor: '#842eff', color: '#fff' }}>
                <h4>Crear publicación</h4>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-lg-4 p-2'>
                    <div className='form-group mb-3'>
                      <label>Imagen de portada</label>
                      <div className='img-portada-container'>
                        <img
                          src={
                            !imagePreview
                              ? 'https://via.placeholder.com/340x130?text=Gestora+Portada+Post'
                              : imagePreview
                          }
                          alt='...'
                          className='img-profile'
                        />
                        <div className='button-container'>
                          <input
                            type='file'
                            ref={imgInputRef}
                            onChange={(e) => {
                              handleChangeImage(e);
                            }}
                            className='d-none'
                            disabled={loading}
                            name='archivo'
                          />
                          {/* <p> {formik.errors.archivo} </p> */}
                          {loading ? (
                            <span>
                              <Spinner
                                animation='border'
                                variant='primary'
                                className='mt-2'
                              />
                            </span>
                          ) : (
                            <span
                              className='img-profile-button text-center'
                              onClick={() => imgInputRef.current.click()}>
                              <FaIcons.FaPencilAlt />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-8'>
                    <div className='form-group mb-3'>
                      <label>Titulo</label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Titulo'
                        name='titulo'
                        onChange={formik.handleChange}
                        value={formik.values.titulo}
                      />
                      <p> {formik.errors.titulo} </p>
                    </div>
                    <div className='form-group mb-3'>
                      <label>Descripción Corta</label>
                      <textarea
                        className='form-control'
                        placeholder='Titulo'
                        name='descripcion_corta'
                        onChange={formik.handleChange}
                        value={formik.values.descripcion_corta}
                        maxLength='350'
                      />
                      <p
                        className={
                          formik.values.descripcion_corta.length != 350
                            ? ''
                            : 'text-danger'
                        }>
                        {formik.values.descripcion_corta.length}/350
                      </p>
                    </div>
                    <div className='form-group mb-3'>
                      <label>Escuela:</label>
                      <select
                        className='form-control'
                        name='id_escuela'
                        onChange={formik.handleChange}
                        value={formik.values.id_escuela}>
                        <option value=''>
                          {isLoading
                            ? 'Cargando escuelas'
                            : !error
                            ? 'Seleccione una escuela...'
                            : 'Error al traer escuelas'}
                        </option>
                        {!isLoading
                          ? data.length
                            ? data.map((escuela, i) => (
                                <option key={i} value={escuela.id_escuela}>
                                  {escuela.nombre_escuela}
                                </option>
                              ))
                            : null
                          : null}
                      </select>
                      <p> {formik.errors.id_escuela} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-12 mb-2'>
                    <EditorGeneral
                      contenido={formik.values.contenido}
                      onChange={setContenidoHTML}
                    />
                  </div>
                  <div className='col-md-12'>
                    <button
                      type='submit'
                      className='btn ge-btn-primary btn-block'>
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </LayoutAdmin>
  );
};

export default CreatePost;
