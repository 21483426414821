import { getAuthAxios } from "../../../axios/axiosCustom";
import Swal from "sweetalert2";


export const getModulesByCourse = async (idCurso) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/modulo/all_modulos/${idCurso}`);
        if(response.data.status){
            return response.data.modulos;
        }
        throw new Error('Error al obtener el curso');
    }catch(e){
        throw new Error('Error del servidor');
    }
}


export const createModule = async (moduleData) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.post("/modulo/create_modulo", moduleData);
        if(response.data.status){
            return response.data;
        }
        throw new Error('Error al crear el curso');
    }catch(e){
        throw new Error('Error del servidor');
    }
}



export const findModuleById = async (idModule) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/clases/modulo_clases/${idModule}`);
        if(response.data.status){
            return response.data.data;
        }
        throw new Error('Error al obtener el detalle del modulo');
    }catch(e){
        throw new Error('Error del servidor');
    }
}


export const editNameModule = async ({idModule, nameModule}) => {
    const axios = getAuthAxios();
    const data = {
        nombre: nameModule
    }
    try{
        const response = await axios.put(`/modulo/edit_modulo/${idModule}`, data);
        if(response.data.status){
            return response.data;
        }
        throw new Error('Error al editar el nombre del modulo');
    }catch(e){
        console.log(e);
        throw new Error('Error del servidor');
    }
}


export const toggleActiveModule = async ({idModule, active}) => {
    const axios = getAuthAxios();
    const data = {
        estado: active
    }
    try{
        const response = await axios.put(`/modulo/estado_modulo/${idModule}`, data);
        if(response.data.status){
            return response.data;
        }
        throw new Error('Error al editar el nombre del modulo');
    }catch(e){
        console.log(e);
        throw new Error('Error del servidor');
    }
}

export const deleteModuloById = async (id_modulo) => {
    const axios = getAuthAxios();
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
  
    await  Swal.fire({
        title: '¿Estás seguro?',
        text: "Una vez eliminado, no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
            const response = await axios.delete(`/modulo/delete_modulo/${id_modulo}`);
              
            if(response.data.status){
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  })
            }
            else{
                Toast.fire({
                    icon: 'warning',
                    title: response.data.message
                  })
            }
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      })
  }