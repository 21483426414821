import React, { useMemo, useState } from "react";
import * as FaIcons from "react-icons/fa";
import DataTable from "react-data-table-component";
import { SwalToast } from "../../../utils/toasts";
import Swal from "sweetalert2";
import HeaderSearchTable from "../../../components/Custom/HeaderSearchTable";
import LoaderSmall from "../../../components/Loaders/LoaderSmall";
import { useMutateDeleteHabilidad, useQueryHabilidades } from "../../../hooks/habilidades.hooks";
import CustomModal from "../../../components/Modal/CustomModal";
import FormularioHabilidad from "../../../components/Cursos/Habilidades/FormularioHabilidad";

const ListaHabilidades = ({ idCourse }) => {
	
	const [filteredHabilidades, setFilteredGlosary] = useState([]);
	// const [idCourse_, setIdCourse_] = useState(idCourse);
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const { data: habilidadesList, isLoading } = useQueryHabilidades(idCourse);
	const {mutate: mutateDelete} = useMutateDeleteHabilidad(idCourse);
    
	const [show,setShow]=useState(false)
	
	const handleToggleDelete = (idHabilidad) => {
		Swal.fire({
			title: '¿Estás seguro?',
			text: "Esta acción no se puede revertir.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!',
			cancelButtonText: 'Cancelar'
		}).then((result) => {
			if (result.isConfirmed) {
				mutateDelete(idHabilidad,
				{
					onSuccess: () => {
						SwalToast.fire({
							icon: 'success',
							title: 'Habilidad eliminada con éxito',
						});
					},
					onError: () => {
						SwalToast.fire({
							icon: 'error',
							title: 'Error al eliminar la habilidad',
						});
					}
				});
			}
		})
	}
    
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "#",
				selector: (row) => row.id_habilidad,
				width: "10%",
			},
			{
				name: "Habilidad",
				width: '200px',
				selector: (row) => row.habilidad,
				sortable: true,
			},
			{
				name: "Acciones",
				width: "100px",
				cell: (row) => (
					<div className="d-flex">
                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => {
								handleToggleDelete(row.id_habilidad)
							}}
                        >
                            <FaIcons.FaTrash />
                        </button>
					</div>
				),
			},
		],
		[]
	);
		
	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={habilidadesList}
				setArrayFiltered={setFilteredGlosary}
				setResetPagination={setResetPagination}
				placeholder="Buscar por habilidad"
				columnsToFilter={["habilidad"]}
			/>
		);
	}, [filterText, resetPagination]);
    
	return (
		<div className="card p-2">
            {
              <CustomModal	
			  	title={"Agregar habilidad"}
				show={show}
				handleShow={setShow} >
				<FormularioHabilidad id_curso={idCourse} setShow={setShow} />
			  </CustomModal>
            }
			<div className="d-flex justify-content-between card-header card-no-after ge-noborder">
				<div>
					<span className="ge-h3 ge-title">Habilidades</span>
				</div>
				<div className="">
					<button 
                        className="btn ge-btn-primary btn-sm"
                        onClick={() => setShow(true)}
                    >
						<FaIcons.FaPlus />
						<span className="ml-2">Agregar habilidad</span>
					</button>
				</div>
			</div>
			<div className="card-body">
				<DataTable
					className="custom-datatable"
					columns={columns}
					data={
						filteredHabilidades.length > 0
							? filteredHabilidades
							: habilidadesList
					}
					subHeader
					subHeaderComponent={searchComponentMemo}
					pagination
					responsive
					progressPending={isLoading}
					progressComponent={<LoaderSmall />}
                    noDataComponent={<div className="w-100 text-center mt-4">No hay datos, agrega una habilidad.</div>}
				/>
			</div>
		</div>
	);
};

export default ListaHabilidades;
