import Swal from "sweetalert2";
import { getAuthAxios } from "../../axios/axiosCustom";

export const getPlanes = async () => {
    const axios = getAuthAxios();
    const response = await axios.get(`/plan/all_planes`);
    if(response.data.status){
        return response.data.planes;
    }
    throw new Error(response.data.message);
}


export const getPlanById = async (id) => {
  const axios = getAuthAxios();
  const response = await axios.get(`/plan/find_plan/${id}`);

  if(response.status!==200)
  {
    throw new Error(response.data.message);
  }

  if(response.data.status){
      return response.data.plan;
  }

  return null
 
}



export const getFrecuenciasPago = async () => {
  const axios = getAuthAxios();
  const response = await axios.get(`/plan/all_frecuencia_pago`);
  if(response.data.status){
      return response.data.data;
  }
  throw new Error(response.data.message);
}


export const getTiposPlan = async () => {
  const axios = getAuthAxios();
  const response = await axios.get(`/plan/all_tipo_plan`);
  if(response.data.status){
      return response.data.data;
  }
  throw new Error(response.data.message);
}


export const deletePlan = async (id_culqi) => {
  const axios = getAuthAxios();
  const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

  await  Swal.fire({
      title: '¿Estás seguro?',
      text: "Una vez eliminado, no se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
          const response = await axios.delete(`/plan/delete_plan/${id_culqi}`);
            
          if(response.data.status){
              Toast.fire({
                  icon: 'success',
                  title: response.data.message
                })
          }
          else{
              Toast.fire({
                  icon: 'warning',
                  title: response.data.message
                })
          }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
      }
    })
}


export const createPlan = async (datos) => {
    const axios = getAuthAxios();
    const response = await axios.post(`/plan/create_plan`,datos);
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      

    if(response.data.status){
        Toast.fire({
            icon: 'success',
            title: response.data.message
          })
        return response.data;
    }
    throw new Error(response.data.error);
}

export const updatePlan = async (datos) => {
    const axios = getAuthAxios();
    const response = await axios.put(`/plan/edit_plan/${datos.id}`,datos);
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      

    if(response.data.status){
        Toast.fire({
            icon: 'success',
            title: response.data.message
          })
        return response.data;
    }
    throw new Error(response.data.error);
}


export const disablePlan = async (datos) => {
    const axios = getAuthAxios();
    const response = await axios.put(`/plan/disablePlan/${datos.id}`,datos);
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

    if(response.data.status){
        Toast.fire({
            icon: 'success',
            title: response.data.message
          })
        return response.data;
    }
    throw new Error(response.data.error);
}


/* PLAN BENEFICIOS */

export const getBenefitsByPlanId = async (idPlan) => {
    const axios = getAuthAxios();
    const response = await axios.get(`/plan/all_beneficio_plan/${idPlan}`);
    
    if(response.data.status){
      return response.data.beneficios;
    } else {
      throw new Error(response.data.error);
    }
}

export const deletePlanBenefits = async (id_beneficio) => {
    const axios = getAuthAxios();
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

  await  Swal.fire({
      title: '¿Estás seguro?',
      text: "Una vez eliminado, no se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
          const response = await axios.delete(`/plan/delete_beneficio/${id_beneficio}`);
            
          if(response.data.status){
              Toast.fire({
                  icon: 'success',
                  title: response.data.message
                })
          }
          else{
              Toast.fire({
                  icon: 'warning',
                  title: response.data.message
                })
          }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
      }
    })
}

export const createPlanBeneficio = async (datos) => {
  const axios = getAuthAxios();
  const response = await axios.post(`/plan/create_beneficio`, datos);
  const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    

  if(response.data.status){
      Toast.fire({
          icon: 'success',
          title: response.data.message
        })
      return response.data;
  }
  throw new Error(response.data.error);
}