import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import LoaderSmall from "../../Loaders/LoaderSmall";
import { getRutasEnVivo } from "../../../controllers/rutas/rutas";
import dayjs from "dayjs";

const CardLiveRoutes = () => {
	
	const [liveRoutes, setLiveRoutes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	
	const getData = async () => {
        setIsLoading(true);
		const response = await getRutasEnVivo();
		if (response) {
			setLiveRoutes(response);
		}
        setIsLoading(false);
	};
	
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "#",
				cell: (row, index) => index + 1,
				width: "10%",
			},
			{
				name: "Rutas en vivo",
				selector: (row) => row.nombre_ruta,
				// width: "250px",
			},
			{
				name: "Fecha de estreno",
				cell: (row) => dayjs(row.fecha_inicio).format("DD/MM/YYYY h:mm A"),
                width: "150px",
			},
		],
		[]
	);
    
    
	useEffect(() => {
		getData();
		return () => {
			setLiveRoutes([]);
		}
	}, []);
    
    
	return (
		<div className="card ge-bt-primary">
			<div className="card-header ge-noborder my-2">
				<>
					<img
						src="/images/file-icon.png"
						className="mr-2"
						style={{ width: "40px" }}
						alt="file-icon"
					/>
					<span className="ge-h4 ge-title">Rutas en ejecución</span>
				</>
			</div>
			<div className="card-body">
				<div className="row">
					<div className="col-12">
						<DataTable
							className="custom-datatable"
							columns={columns}
							data={liveRoutes}
							pagination
							responsive
							progressPending={isLoading}
							progressComponent={<LoaderSmall />}
							noDataComponent={
								<div className="text-center mt-3">
									No hay datos
								</div>
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardLiveRoutes;
