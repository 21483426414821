import React from 'react';
import Select from 'react-select';

export default ({ onChange, options, value, className }) => {

    const defaultValue = (options, value) => {
        return options ? options.find(option => option.id_ruta === value) : "";
    };

    return (
        <div className={className} style={{zIndex:4}}>
            <Select
                value={defaultValue(options, value)}
                onChange={value => {
                    onChange(value)

                }} options={options}
                getOptionValue={rutas => rutas.id_ruta}
                getOptionLabel={rutas => rutas.nombre_ruta} />
        </div>

    )
}