import React, { useEffect, useMemo, useState } from "react";
import { Badge, Spinner } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import HeaderSearchTable from "../Custom/HeaderSearchTable";
import LoaderSmall from "../Loaders/LoaderSmall";
import { useMutateAddGlosary, useQueryRouteGlosario } from "../../hooks/rutas.hooks";
import { SwalToast } from "../../utils/toasts";

const DatosGlosario = ({ idRoute }) => {
	
	//Query fetching
    const { data: glosaryList, isLoading, isSuccess } = useQueryRouteGlosario(idRoute);
	const { mutate: mutateAddGlosary, isLoading: loadingAdd } = useMutateAddGlosary(idRoute);
	
	
	
	//State
	const [filteredGlosary, setFilteredGlosary] = useState([]);
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
    const [glosariesSelected, setGlosariesSelected] = useState([]);
	console.log(glosariesSelected)

    
    /* Checked glosaries from api */
    useEffect(() => {
        if(isSuccess) {
			const arrayFiltered = glosaryList.filter(glosary => parseInt(glosary.checked) === 1);
            setGlosariesSelected(arrayFiltered.map(glosary => glosary.id_glosario));
        }
    }, [glosaryList, isSuccess]);
    
	/* Events */
    const onSelectCheckbox = (idGlosary) => {
        const newGlosariesSelected = [...glosariesSelected];
        if (glosariesSelected.includes(idGlosary)) {
            newGlosariesSelected.splice(
                newGlosariesSelected.indexOf(idGlosary),
                1
            );
        } else {
				newGlosariesSelected.push(idGlosary);
			}
        setGlosariesSelected(newGlosariesSelected);
    }
	
	const onAddGlosary = () => {
		mutateAddGlosary({
			id_ruta: idRoute,
			datos: JSON.stringify(glosariesSelected),
		},{
			onSuccess: () => {
				SwalToast.fire({
					title: "Glosarios actualizados correctamente",
					icon: "success",
				});
			},
			onError: () => {
				SwalToast.fire({
					title: "Error al actualizar glosarios",
					icon: "error",
				});
			}
		});
	}
	
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "#",
				selector: (row) => row.id_glosario,
				width: "10%",
			},
			{
				name: "Término",
				width: '200px',
				selector: (row) => row.titulo,
				sortable: true,
			},
			{
				name: "Descripción",
                // width: "250px",
				selector: (row) => row.descripcion,
			},
            {
                name:"Curso",
                selector: (row) => row.nombre_curso,
            },
			{
				name: "Mostrar",
				width: "100px",
				cell: (row) => (
					<div className="d-flex justify-content-center">
                        <input
                            type="checkbox"
                            className="form-control"
                            checked={glosariesSelected.includes(row.id_glosario)}
                            onChange={() => onSelectCheckbox(row.id_glosario)}
                        />
					</div>
				),
			},
		],
		[glosariesSelected]
	);
	
	
	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={glosaryList}
				setArrayFiltered={setFilteredGlosary}
				setResetPagination={setResetPagination}
				placeholder="Buscar por término"
				columnsToFilter={["titulo"]}
			/>
		);
	}, [filterText, resetPagination]);
    
	return (
		<div className="card p-2">
			<div className="d-flex justify-content-between card-header card-no-after ge-noborder">
				<div>
					<span className="ge-h3 ge-title">Glosario</span>
				</div>
				<div>
					<button
						className="btn ge-btn-primary"
						onClick={onAddGlosary}
					>
						{
							loadingAdd ? (
								<Spinner animation="border" variant="dark" />
							): (
								<>
									<FaIcons.FaSyncAlt className="mr-2" />
									Actualizar glosarios
								</>
							)
						}
					</button>
				</div>
			</div>
			<div className="card-body">
				<DataTable
					className="custom-datatable"
					columns={columns}
					data={
						filteredGlosary.length > 0
							? filteredGlosary
							: glosaryList
					}
					subHeader
					subHeaderComponent={searchComponentMemo}
					pagination
					responsive
					progressPending={isLoading}
					progressComponent={<LoaderSmall />}
                    noDataComponent={<div className="w-100 text-center mt-4">No hay datos, agrega un término.</div>}
				/>
			</div>
		</div>
	);
};

export default DatosGlosario;
