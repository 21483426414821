import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import dayjs from "dayjs";
import CustomInputDate from "../../Custom/CustomInputDate";
import { SwalToast } from "../../../utils/toasts";
import LoaderSmall from "../../Loaders/LoaderSmall";
import { getNumberOfVisits } from "../../../controllers/marketing/google_analytics/googleAnalytics.controller";
import BarChartDataset from "../../Charts/BarChartDataset";
import DoughnutChart from "../../Charts/DoughnutChart";

const CantidadVisitas = () => {
    
	const actualDay = new Date();
	let daysAgo7 = new Date();
	daysAgo7.setDate(actualDay.getDate() - 6);
    
	const initialState = [daysAgo7, actualDay];
    
	const [data, setData] = useState(null);
    const [totalVisits, setTotalVisits] = useState(0);
	const [loading, setLoading] = useState(false);
	const [dateRange, setDateRange] = useState(initialState);
	const [startDate, endDate] = dateRange;
	const chartRef = useRef(null);
	
	const getData = async () => {
		setLoading(true);
		const data_ = await getNumberOfVisits(startDate, endDate);
		if (data_) {
			setData(data_.chart_dispositivos);
            setTotalVisits(data_.cantidad_visitas);
		} else {
			SwalToast.fire({
				icon: "error",
				title: "Error",
				text: "Error al obtener los datos",
			});
			/* Setting the dateRange to null, null. */
            setData(null);
		}
		setLoading(false);
	};
    
    
	useEffect(() => {
		if (startDate && endDate) {
            getData();
		}
	}, [dateRange]);
    
	return (
		<div className="card">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column flex-lg-row justify-content-lg-between align-items-center">
                        <h5 className="ge-title txt-bold"># Visitas a la web</h5>
						<div className="my-2">
							{/* Range Picker */}
							<span className="txt-bold ge-title">Periodo:</span>
							<ReactDatePicker
								locale={"es"}
								selectsRange={true}
								startDate={startDate}
								endDate={endDate}
								onChange={(update) => {
									setDateRange(update);
								}}
								isClearable={true}
								// className="form-control ge-form my-2"
								placeholderText="Seleccione un rango"
								dateFormat="yyyy-MM-dd"
								maxDate={actualDay}
								customInput={<CustomInputDate />}
							/>
						</div>
					</div>
                    
                    <div className="col-12 mt-3 d-flex justify-content-center align-items-center">
                        <span className="ge-h4 txt-bold">Total de visitas: {totalVisits}</span>
                    </div>
                    
					<div 
						className="col-12 mt-4"
					>
						{/* Grafico */}
						{loading ? (
							<LoaderSmall />
						) : data ? (
							<DoughnutChart
								ref={chartRef}
								datos={data}
								showLegend={true}
								showTitle={true}
							/>
						) : (
							<div className="mt-3 d-flex justify-content-center align-items-center w-100">
								<h5 className="ge-txt-muted-dark">
									No hay información disponible
								</h5>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CantidadVisitas;
