
export const setTokenLS = (token) => {
    localStorage.setItem("token", token);
};

export const getTokenLS = () => {
    return localStorage.getItem("token");
}

export const deleteTokenLS = () => {
    localStorage.removeItem("token");
}

/* Último segundo de la clase */
export const getLastSecond = (pathClass, idUser) => {
    return localStorage.getItem(`${pathClass}/${idUser}`);
}

export const setLastSecond = (pathClass, lastSecond, idUser) => {
    
    let prevLastSecond = getLastSecond(pathClass, idUser);
    if(prevLastSecond){
        prevLastSecond = JSON.parse(prevLastSecond);
        const data = {
            lastSecond: lastSecond > prevLastSecond.lastSecond ? lastSecond : prevLastSecond.lastSecond,
            idUser,
        };
        localStorage.setItem(`${pathClass}/${idUser}`,JSON.stringify(data));
    }else{
        const data = {
            lastSecond,
            idUser,
        };
        localStorage.setItem(`${pathClass}/${idUser}`,JSON.stringify(data));
    }
    
}


/* Docente a editar local storage */
