import React from "react";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutationResetPassword } from "../../hooks/userRoles";
import Swal from "sweetalert2";

const RestablecerPasswordModal = ({ handleShow, idUser }) => {
  const { mutate } = useMutationResetPassword();

  const initialValues = {
    password: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("La contraseña es requerida")
      .min(8, "Minimo 8 caracteres"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      mutate({ values, id_user: idUser },
        {
          onSuccess: () => {
            Swal.fire({
              title: "Contraseña actualizada correctamente",
              icon: "success",
            });
            handleShow(false);
          },
        }
      );
    },
  });

  return (
    <div className="row">
      <div className="col-12">
        <Form
          className="d-flex align-items-center "
          onSubmit={formik.handleSubmit}
        >
          <Form.Group className="my-2 col-md-6">
            <Form.Control
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              placeholder="Escriba su nueva contraseña"
              type="password"
              isValid={formik.touched.password && !formik.errors.password}
              isInvalid={formik.touched.password && !!formik.errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.password}
            </Form.Control.Feedback>
          </Form.Group>
          <button type="submit" className="btn ge-btn-primary btn-block">
            Actualizar Contraseña
          </button>
        </Form>
      </div>
    </div>
  );
};

export default RestablecerPasswordModal;
