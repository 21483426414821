import React, { useEffect, useState } from "react";

const CardIngresosDocentes = ({ ingresos }) => {
  const [data, setData] = useState([]);

  const fecha = new Date();
  const fecha_ahora = fecha.toLocaleString("es-PE", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  useEffect(() => {
    if (ingresos) {
      const {
        tipos_de_pago_del_docente,
        ingresos_hasta_hoy,
        ingresos_mes_anterior,
      } = ingresos;
      const array = [
        { title: "Ingresos al día de hoy", monto: ingresos_hasta_hoy },
      ];
      tipos_de_pago_del_docente.includes("2") &&
        array.push({
          title: "Ingresos por venta directa de cursos",
          monto: ingresos.traer_ingresos_por_venta_directa,
        });
      tipos_de_pago_del_docente.includes("3") &&
        array.push({
          title: "Ingresos por membresias",
          monto: ingresos.traer_ingresos_por_menbresia,
        });
      array.push({
        title: "Ingresos del mes anterior",
        monto: ingresos_mes_anterior,
      });
      setData(array);
    }
  }, [ingresos]);
  
  return (
    <>
      {data.map((v, i) => (
        <div key={i} className="card-ingresos-docente col-md-auto px-4 py-3">
          <div className="d-flex justify-content-start align-items-center">
            <img
              src="/images/ingreso-user.png"
              alt="images"
              style={{ width: "80px", imageResolution: "from-image" }}
            />
            <p className="title-ingresos-docente">{v.title}</p>
          </div>
          <div className="d-flex justify-content-start align-items-end">
            <span style={{ fontSize: "23px", margin: "0px 5px 12px 0px" }}>
              PEN
            </span>
            <span className="monto-ingresos-docente">{v.monto + ".00"}</span>
          </div>
          <div>
            <p style={{ color: "#7d34f4" }}>{`Actualizado ${fecha_ahora}`}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default CardIngresosDocentes;
