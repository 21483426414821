import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

import Login from "../pages/Login";
import { validateSessionAction } from "../redux/actions/userActions";
import DashboardRoutes from "./DashboardRoutes";
import PrivateRoutes from "./PrivateRoutes";

export const AppRouter = () => {
	//TODO: validate if the user is logged in and maintain the session

	const { isAuthenticated } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	
	useEffect(() => {
		if (!isAuthenticated) {
			dispatch(validateSessionAction());
		}
	}, []);
    
	return (
		<BrowserRouter>
			<Routes>
				<Route index path="/login" element={<Login />} />
                
				<Route
					path="/*"
					element={
						<PrivateRoutes
							component={DashboardRoutes}
						/>
					}
				/>
                
				{/* <Route path="*" element={<Navigate to="/login" />} /> */}
			</Routes>
		</BrowserRouter>
	);
};
