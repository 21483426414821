import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import CantidadRebote from "../../components/Marketing/GoogleAnalytics/CantidadRebote";
import CantidadVisitas from "../../components/Marketing/GoogleAnalytics/CantidadVisitas";
import TraficoOrganico from "../../components/Marketing/GoogleAnalytics/TraficoOrganico";
import TraficoReferido from "../../components/Marketing/GoogleAnalytics/TraficoReferido";

const AnaliticaWebScreen = () => {
	return (
		<LayoutAdmin
            /* contentTitle={
                <div className="d-flex align-items-center">
                    <>
                        <img
                            src="/images/file-icon.png"
                            className="mr-2"
                            style={{ width: "40px" }}
                            alt="file-icon"
                        />
                        <span className="ge-h3 ge-title">
                            Lista de docentes
                        </span>
                    </>
                </div>
            } */
            contentTitle=""
            routeTitle="Analítica Web"
        >
            <Tabs>
                <Tab eventKey="cantidadVisitas" title="Cantidad de visitas">
                    <CantidadVisitas />
                </Tab>
                <Tab eventKey="cantidadRebote" title="% de rebote">
                    <CantidadRebote />
                </Tab>
                <Tab eventKey="traficoOrganico" title="Tráfico orgánico">
                    <TraficoOrganico />
                </Tab>
                <Tab eventKey="traficoReferido" title="Tráfico referido">
                    <TraficoReferido />
                </Tab>
            </Tabs>
		</LayoutAdmin>
	);
};

export default AnaliticaWebScreen;
