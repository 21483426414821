import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import DatosRuta from "./DatosRuta";
import DatosCursos from "./DatosCursos";
import DatosGlosario from "./DatosGlosario";
const TabRuta = ({ ruta, cursos = [] }) => {
	
	const [showRestTabs, setShowRestTabs] = useState(true);
	
	// useEffect(() => {
	// 	if(ruta){
	// 		if(parseInt(ruta.id_tipo_ruta) === 1){
	// 			setShowRestTabs(true);
	// 		}
	// 	}
	// }, [ruta]);
	
	
	if(!showRestTabs){
		return (
			<Tabs id="controller-escuela" defaultActiveKey={"datos"}>
				<Tab
					eventKey={"datos"}
					title={
						<div className="text-center">
							<FaIcons.FaCog />
							<p className="mt-2">Datos</p>
						</div>
					}
				>
					<DatosRuta ruta={ruta} />
				</Tab>
			</Tabs>
		)
	}
	
	return (
		<Tabs id="controller-escuela" defaultActiveKey={"datos"}>
			<Tab
				eventKey={"datos"}
				title={
					<div className="text-center">
						<FaIcons.FaCog />
						<p className="mt-2">Datos</p>
					</div>
				}
			>
				<DatosRuta ruta={ruta} />
			</Tab>
			
			<Tab
				title={
					<div className="text-center">
						<FaIcons.FaBook />
						<p className="mt-2">Cursos</p>
					</div>
				}
				eventKey={"cursos"}
			>
				<DatosCursos id_ruta={ruta.id_ruta} cursos={cursos} />
			</Tab>
			
			<Tab
				eventKey={"glosario"}
				title={
					<div className="text-center">
						<FaIcons.FaListOl />
						<p className="mt-2">Glosario</p>
					</div>
				}
			>
				<DatosGlosario 
					idRoute = {ruta.id_ruta}
				/>
			</Tab>
		</Tabs>
	);
};

export default TabRuta;
