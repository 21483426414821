import React from "react";
import { Link, useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";

const AdminContent = (props) => {
    
    const { title, routeTitle, children } = props;
	const navigate = useNavigate();
    
	const handleBack = () => {
		navigate(-1);
	};
	
	return (
		<div className="content-wrapper">
			{/* Content Header (Page header) */}
			<div className="content-header">
				<div className="container-fluid">
					<div className="row mb-2">
						<div className="col-sm-6">
							<h1 className="mb-2">{title}</h1>
							<span
								className="text-muted"
								onClick={handleBack}
								style={{ cursor: "pointer" }}
							>
								<FaIcons.FaArrowLeft className="mr-2" />
								Atrás
							</span>
						</div>
						<div className="col-sm-6">
							<ol className="breadcrumb float-sm-right">
								<li className="breadcrumb-item">
									<Link 
										to="/"
										className="ge-txt-primary"
									>
											Inicio
									</Link>
								</li>
								<li className="breadcrumb-item active">
									{routeTitle}
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
			{/* Main content */}
			<div className="content">
                <div className="container-fluid">
                    {children}
                </div>
            </div>
		</div>
	);
};

export default AdminContent;
