import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import ReactDatePicker from "react-datepicker";
import { useSchoolsSelect } from "../../hooks/custom/useSchoolsSelect";
import { getDateTimeObject } from "../../utils/dateTime";
import { useMutateEditConvocatoria, useQueryConvocatoriaId } from "../../hooks/convocatorias.hooks";
import { SwalToast } from "../../utils/toasts";
import CustomInputDate from "../Custom/CustomInputDate";
import { FaIcons } from "../../icons/export-reacticons";

const validationSchema = yup.object({
    titulo: yup.string().required("El titulo es requerido"),
    id_escuela: yup.string().required("La escuela es requerida"),
    fecha_fin: yup.date().required("La fecha de fin es requerida"),
    enlace: yup.string().url("El enlace no es valido").required("El enlace es requerido"),
});

const dayJs = getDateTimeObject();


const EditConvocatoria = ({ show, setShow, id, setId }) => {
    
    const [schools] = useSchoolsSelect();
    const [endDate, setEndDate] = useState(new Date());
    
    const { data, refetch } = useQueryConvocatoriaId(id);
    const { mutate: updateConvocatoria, isLoading } = useMutateEditConvocatoria(id);
    
    const initialValues = {
        titulo: "",
        id_escuela: "",
        fecha_fin: new Date(),
        enlace: "",
    }
    
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            if(id) {
                updateConvocatoria({
                    id_registro: id,
                    data: values,
                }, {
                    onSuccess: () => {
                        SwalToast.fire({
                            icon: "success",
                            title: "Convocatoria actualizada correctamente",
                        });
                        onClose();
                    },
                    onError: (error) => {
                        SwalToast.fire({
                            icon: "error",
                            title: "Error al actualizar la convocatoria",
                        });
                    }
                });
            }
        }
    });
    
    
    const onDateChange = (date) => {
        setEndDate(date);
        formik.setFieldValue("fecha_fin", date);
    }
    
    const onClose = () => {
        setId(null);
        setShow(false);
    }
    
    
    // si el id cambia, refresca los datos
    useEffect(() => {
        if(id){
            refetch();
        }
    }, [id]);
    
    // si el registro existe, actualiza los valores del formulario
    useEffect(() => {
        if(data){
            formik.setValues({
                titulo: data.titulo,
                id_escuela: data.id_escuela,
                enlace: data.enlace,
                fecha_fin: dayJs(data.fecha_fin).$d,
            });
            setEndDate(dayJs(data.fecha_fin).$d);
        }
    } ,[data]);
    
	return (
		<Modal 
            show={show} 
            onHide={onClose} 
            backdrop="static"
            centered
        >
			<Modal.Header closeButton className="ge-bg-primary text-white">
				<Modal.Title className="txt-bold">Editar Convocatoria</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
                    className="row"
                    onSubmit={formik.handleSubmit}
                >
					<Form.Group
                        className="col-12 my-2"
                    >
						<Form.Label>Título:</Form.Label>
						<Form.Control
                            className="ge-form"
                            type="text"
                            name="titulo"
                            placeholder="Ej. Convocatoria 2021"
                            onChange={formik.handleChange}
                            value={formik.values.titulo}
                            isValid={formik.touched.titulo && !formik.errors.titulo}
                            isInvalid={formik.touched.titulo && !!formik.errors.titulo}
                            autoComplete="off"
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.titulo}
                        </Form.Control.Feedback>
					</Form.Group>
                    <Form.Group
                        className="col-12 my-2"
                    >
                        <Form.Label>URL del aviso:</Form.Label>
                        <Form.Control
                            className="ge-form"
                            type="text"
                            name="enlace"
                            placeholder="Ej. https://www.convocatoria.com"
                            onChange={formik.handleChange}
                            value={formik.values.enlace}
                            isValid={formik.touched.enlace && !formik.errors.enlace}
                            isInvalid={formik.touched.enlace && !!formik.errors.enlace}
                            autoComplete="off"
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.enlace}
                        </Form.Control.Feedback>
                    </Form.Group>
					<Form.Group
						className="col-12 my-2"
					>
						<Form.Label>Escuela:</Form.Label>
						<Form.Select
							className="form-control ge-form"
							name="id_escuela"
                            onChange={formik.handleChange}
                            value={formik.values.id_escuela}
                            isValid={formik.touched.id_escuela && !formik.errors.id_escuela}
                            isInvalid={formik.touched.id_escuela && !!formik.errors.id_escuela}
						>
							<option 
                                value=""
                                disabled
                            >
                                ---- Seleccione una escuela ----
                            </option>
							{schools.length > 0 &&
								schools.map((escuela) => (
									<option value={escuela.id} key={`esc-${escuela.id}`}>
										{escuela.nombre}
									</option>
                                ))
                            }
						</Form.Select>
						<Form.Control.Feedback type="invalid">
							{formik.errors.id_escuela}
						</Form.Control.Feedback>
					</Form.Group>
                    <Form.Group
                        className="col-12 my-2"
                    >
                        <Form.Label>Fecha de inicio:</Form.Label>
                        <ReactDatePicker
                            selected={endDate}
                            onChange={(date) => onDateChange(date)}
                            showTimeSelect
                            dateFormat="yyyy-MM-dd HH:mm"
                            dateFormatCalendar="MMMM"
                            customInput={<CustomInputDate />}
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={15}
                        />
                    </Form.Group>
                    <div className="col-12 my-2 d-flex justify-content-center justify-content-md-end">
                        <button
                            type="submit"
                            className="btn ge-btn-primary"
                            disabled={isLoading}
                        >
                            {
                                isLoading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </>
                                ):(
                                    <>
                                        <FaIcons.FaSave size={20} />
                                        <span className="ml-2">Guardar</span>
                                    </>
                                )
                            }
                        </button>
                    </div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default EditConvocatoria;
