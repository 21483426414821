import Swal from "sweetalert2";
import { getAuthAxios } from "../../../axios/axiosCustom";

export const getExamen = async (id_curso) => {
	const axios = getAuthAxios();
	const response = await axios.get(`/examen/traerExamen/${id_curso}`);
	if (response.data.status) {
		return response.data.examen[0];
	}
	throw new Error(response.data.message);
};

export const saveDataExam = async (data) => {
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});
	
	const axios = getAuthAxios();
	const response = await axios.post(
		`/examen/create_examen/${data.id_curso}`,
		data
	);
	if (response.data.status) {
		Toast.fire({
			icon: "success",
			title: response.data.message,
		});
		return response.data;
	}
	throw new Error(response.data.message);
};

export const getQuestions = async (id_examen) => {
	const axios = getAuthAxios();
	const response = await axios.get(`/examen/traerPreguntas/${id_examen}`);
	if (response.data.status) {
		return response.data.preguntas;
	}
	throw new Error(response.data.message);
};

export const getClassByCourse = async (id_curso) => {
	const axios = getAuthAxios();
	const response = await axios.get(`/examen/traerClases/${id_curso}`);
	if (response.data.status) {
		return response.data.data;
	}
	throw new Error(response.data.message);
};

export const addQuestion = async (data) => {
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});

	const axios = getAuthAxios();
	const response = await axios.post(
		`/examen/pregunta_examen/${data.id_examen}`,
		data
	);
	if (response.data.status) {
		Toast.fire({
			icon: "success",
			title: response.data.message,
		});
		return response.data;
	}
	throw new Error(response.data.message);
};

export const deleteQuestion = async (id_pregunta) => {
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});
	
	const axios = getAuthAxios();
	const response = await axios.delete(
		`/examen/deletePregunta/${id_pregunta}`
	);
	if (response.data.status) {
		Toast.fire({
			icon: "success",
			title: response.data.message,
		});
		return response.data;
	}
	throw new Error(response.data.message);
};

export const getQuestionData = async (id_pregunta) => {
	const axios = getAuthAxios();
	const response = await axios.get(`/examen/info_pregunta/${id_pregunta}`);
	if (response.data.status) {
		return response.data.data;
	}else{
		throw new Error(response.data.message);
	}
};

/* CRUD Options */

export const addOption = async ({id_pregunta, data}) => {
	const axios = getAuthAxios();
	const response = await axios.post(`/examen/opcion_pregunta/${id_pregunta}`, data);
	if (response.data.status) {
		return response.data;
	}else{
		throw new Error(response.data.message);
	}
}

export const deleteOption = async (id_opcion) => {
	const axios = getAuthAxios();
	const response = await axios.delete(`/examen/borrar_opcion/${id_opcion}`);
	if (response.data.status) {
		return response.data;
	}else{
		throw new Error(response.data.message);
	}
}


export const edit_question = async ({id_pregunta, data}) => {
	const axios = getAuthAxios();
	const response = await axios.post(`/examen/edit_pregunta/${id_pregunta}`, data);
	if (response.data.status) {
		return response.data;
	}else{
		throw new Error(response.data.message);
	}
}

