import React, { useMemo, useState } from "react";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import DataTable from "react-data-table-component";
import {
	useMutationIncidentResolved,
	useQueryIncidents,
} from "../../hooks/incidencias.hooks";
import * as AiIcons from "react-icons/ai";
import LoaderSmall from "../../components/Loaders/LoaderSmall";
import { useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
import dayjs from "dayjs";
import CustomHeader from "../../components/Incidencias/CustomHeader";

const ListaIncidenciasScreen = () => {
	
	const navigate = useNavigate();
	
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
	
	const { data: incidentsList, isLoading } = useQueryIncidents();
	const { mutate: markIncidentAsResolved } = useMutationIncidentResolved();
	
	
	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<CustomHeader
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={incidentsList}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
				placeholder="Buscar por nombre, apellido o clase"
				columnsToFilter={["nombres", "apellidos", "problema"]}
			/>
		);
	}, [filterText, resetPagination,incidentsList]);
	
	const handleSeeIncident = (cod_incident) => {
		navigate(`/incidencias/ver/${cod_incident}`);
	}
	
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "#",
				cell: (row, index) => row.id_registro,
				width: "5%",
			},
			{
				name: "Fecha de reporte",
				cell: (row, index) => dayjs(row.fecha_registro).format("DD/MM/YYYY"),
			},
			{
				name: "Estudiante",
				selector: (row) => row.nombres + " " + row.apellidos,
			},
			{
				name: "Clase",
				cell: (row) => row.id_clase ? (
					<span>{row.titulo_clase}</span>
				) : (
					<span>No asignada</span>
				)
			},
			{
				name: "Razón de la incidencia",
				selector: (row) => row.problema,
			},
			{
				name: "Estado",
				selector: (row) => parseInt(row.id_estado) === 1 ? (
					<Badge className="p-2" bg="warning">
						Abierta
					</Badge>
				): parseInt(row.id_estado) === 2 ? (
					<Badge className="p-2" bg="success">
						Resuelta
					</Badge>
				) : (
					<Badge className="p-2" bg="danger">
						Archivado
					</Badge>
				)
			},
			{
				name: "Acciones",
				cell: (row) => (
					<div className="d-flex justify-content-center align-items-center">
						<button
							className="btn btn-sm btn-primary"
							title="Ver detalle"
							onClick={() => handleSeeIncident(row.codigo_unico)}
						>
							<AiIcons.AiFillEye size={20} />
						</button>
					</div>
				),
			},
		],
		[]
	);
	
	return (
		<LayoutAdmin contentTitle="" routeTitle="Lista de incidencias">
			<div className="row">
				<div className="col-12">
					<div className="card p-2">
						<div className="card-header ge-noborder">
							<>
								<img
									src="/images/file-icon.png"
									className="mr-2"
									style={{ width: "40px" }}
									alt="file-icon"
								/>
								<span className="ge-h3 ge-title">
									Lista de incidencias
								</span>
							</>
						</div>
						<div className="card-body">
							<DataTable
								className="custom-datatable"
								columns={columns}
								data={
									filteredItems.length > 0
										? filteredItems
										: incidentsList
								}
								subHeader
								subHeaderComponent={searchComponentMemo}
								pagination
								responsive
								fixedHeader
								fixedHeaderScrollHeight="300px"
								progressPending={isLoading}
								progressComponent={<LoaderSmall />}
							/>
						</div>
					</div>
				</div>
			</div>
		</LayoutAdmin>
	);
};

export default ListaIncidenciasScreen;
