import React from 'react';
import { useQuery } from 'react-query';
import DatosExamen from '../../../components/Examen/DatosExamen';
import { getExamen } from '../../../controllers/cursos/examen/examen.controller';

const Examen = ({id_curso}) => {

    const {data : datosExamen,error,isLoading} = useQuery(['Examen',id_curso],()=>getExamen(id_curso),{
        retry:1,
        refetchOnWindowFocus: false,
    })

    return (
        <div className='card'>
            <div className='card-body'>
                <DatosExamen id_curso={id_curso} examen={datosExamen} />
            </div>
        </div>
    );
};

export default Examen;