import React, { useState } from "react";
import * as BsIcons from "react-icons/bs";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { disableCommentPost } from "../../controllers/blog/posts";


const CommentItem = ({data}) => {
    const {uId} = useParams()

    const {id_comentario_post, nombres, img_perfil,comentario,estado,fecha_creacion} = data;

    const[estado_,setEstado]=useState(parseInt(estado) === 1)

    const queryClient = useQueryClient()
    
    const {mutate:disableComentario, error : errorUpdate , isLoading : isLoadingUpdate } = useMutation(disableCommentPost,{
        onSuccess: ()=>{
            queryClient.refetchQueries(["getPost",uId])
            setEstado(!estado_)
        }
    })


    const handleDisableCommentPost = (id,eliminado)=>{
        disableComentario({id:id,eliminado:eliminado})
    }
    
	return (
		<div className="row comment-item d-flex p-2 mt-4 mb-3">
			<div className="col-1 m-0 p-0 profile-image">
				<img src={process.env.REACT_APP_API_URL+img_perfil} alt="..." />
			</div>
			<div className="col-7 comment-item-content d-flex">
				<div className="d-flex flex-column">
					<div className="comment-item-header d-flex">
						<span className="comment-item-name me-2">
							<b>{nombres}</b>
						</span>
						<span className="comment-item-date"> {" "+fecha_creacion}</span>
					</div>
					<div className="comment-item-text mt-2">
						<p>{comentario}</p>
					</div>
                    <div className="comment-item-action d-flex">
                        {
                            estado_ ? 	<span 
										className="btn btn-light btn-sm mr-2"
										onClick={() => handleDisableCommentPost(id_comentario_post, 0)}
									>
										<BsIcons.BsToggleOn color="#37af48" size={20} style={{marginRight:'5px'}} />
                                        Habilitado
									</span>: <span 
										className="btn btn-light btn-sm mr-2"
										onClick={() => handleDisableCommentPost(id_comentario_post, 1)}
										>
										<BsIcons.BsToggleOff color="#ff0000" size={20} style={{marginRight:'5px'}} />
                                        Deshabilitado
									</span>
                        }
			        </div>
				</div>
              
			</div>
          
		</div>
	);
};

export default CommentItem;
