import { useFormik } from 'formik';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { getRutas } from '../../controllers/rutas/rutas';
import * as yup from "yup";
import CustomSelectRutas from '../Custom/CustomSelectRutas';
import { addRuta } from '../../controllers/escuelas/escuelas';
const ListaRutas = ({id_escuela, handleShow}) => {
    const {uId} = useParams()

    const{data, error , isLoading } = useQuery(["getRutas"], getRutas ,
    {
        refetchOnWindowFocus:false
    }
    )
    const queryClient = useQueryClient()
    const {mutate, error : errorCreate , isLoading : isLoadingCreate } = useMutation(addRuta,
        {
            onSuccess:()=>{
                queryClient.invalidateQueries(["getEscuela",uId])
                handleShow(false)
            }
        })
    const initialValues={
        unique:uId,
        id_escuela:id_escuela,
        id_ruta:''
    }

    const validationSchema = yup.object({
		id_ruta: yup
			.string()
			.required("El campo es requerido"),
	});
	

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values) => {
            const datos = {...values}
           // datos.contenido=contenidoHTML
          //  console.log(datos)
			mutate(datos)
		},
	});

    return (
        <form onSubmit={formik.handleSubmit}>
        <div className='row'>
                <div className='col-md-12'>
                  

                            <div className="form-group mb-3">
                                    <label>Ruta:</label>
                                    <CustomSelectRutas
                                        className=''
                                        onChange={value=>formik.setFieldValue('id_ruta',value.id_ruta)}
                                        value={formik.values.id_ruta}
                                        options={data}
                                        />
                                    <p> {formik.errors.id_ruta} </p>
                            </div>
        
                                    <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn ge-btn-primary btn-block"
                                    >
                                        Agregar ruta
                                    </button>
                                    </div>
                     
                </div>
           </div>
        </form>
    );
};

export default ListaRutas;