import React from "react";
import CardDocenteBlog from "./Cards/CardDocenteBlog";
import CardDocenteComments from "./Cards/CardDocenteComments";
import CardDocenteCourses from "./Cards/CardDocenteCourses";
import CardDocentePerformance from "./Cards/CardDocentePerformance";
import TablePagosDocentes from "./TablePagosDocentes";

const DetailsDocente = React.forwardRef((props, ref) => {
    
    const {
        idDocente = null,
        nombres,
        apellidos,
        imagen,
        eliminado
    } = props;
    
    if(!idDocente) return null;
	return (
		<div
            className="row"
            ref={ref}
        >
			<div className="col-12 col-md-8">
				<CardDocenteCourses
                    idDocente={idDocente}
                    nombres={nombres}
                    apellidos={apellidos}
                    imagen={imagen}
                    eliminado={eliminado}
                />
			</div>
			<div className="col-12 col-md-4">
				<CardDocentePerformance
                    idDocente={idDocente}
                    nombres={nombres}
                    apellidos={apellidos}
                    imagen={imagen}
                />
			</div>
			<div className="col-12 col-md-6">
				<CardDocenteComments
                    idDocente={idDocente}
                />
			</div>
			<div className="col-12 col-md-6">
				<CardDocenteBlog
                    idDocente={idDocente}
                />
			</div>
            <div className="col-md-12">
                <TablePagosDocentes idDocente={idDocente}/>
            </div>
		</div>
	);
});

export default DetailsDocente;
