import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useMutateCreateUser } from '../../hooks/userRoles';
import PhoneInput from "react-phone-input-2";
import { SwalToast } from '../../utils/toasts';

const CreateUserModal = ({ handleShow }) => {
    const [roles, setRoles ] = useState([]);

    const { mutate: mutateCreateUser } = useMutateCreateUser();

    const initialValues = {
        Nombres: "",
        Apellidos: "",
        Correo: "",
        Contraseña: "",
        Celular: "",
        id_pais: "",
        Roles: [],
    };

    const validationSchema = Yup.object({
        Nombres: Yup.string().required('Este campo es requerido'),
        Apellidos: Yup.string().required('Este campo es requerido'),
        Correo: Yup.string().email('Debe ser un correo valido').required('Este campo es requerido'),
        Contraseña: Yup.string().min(8, 'Debe ser minimo 8 caracteres').required('Este campo es requerido'),
        Celular: Yup.string().required('Este campo es requerido'),
        id_pais: Yup.string().required("El país es requerido"),
        Roles: Yup.array().required('Este campo es requerido')
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            values.Roles = JSON.stringify(roles);
            mutateCreateUser(values, {
                onSuccess: (data) => {
                    if(data.status)
                    {
                        handleShow(false)
                        formik.resetForm()
                        SwalToast.fire({
                            icon: "success",
                            title: "Usuario creado con exito",
                        });
                    }
                    else
                    {
                        SwalToast.fire({
                            icon: "error",
                            title: data.message,
                        });
                    }
                }
            })
        }
    })

    const check = (value) => {
        const rolesSelected = [...roles]
        if(rolesSelected.includes(value)){
             rolesSelected.splice(rolesSelected.indexOf(value),1)
        } else {
            rolesSelected.push(value)
        }
        setRoles(rolesSelected)
    }

  return (
    <div className="row">
	    <div className="col-12">
            <Form 
                className='row'
                onSubmit={formik.handleSubmit}
            >
                <Form.Group className='my-2 col-md-6'>
                    <Form.Label>
                        Nombres
                    </Form.Label>
                        <Form.Control 
                            name='Nombres'
                            onChange={formik.handleChange}
                            value={formik.values.Nombres}
                            placeholder="Escribe los nombres"
                            type='text'
                            isValid={
                                formik.touched.Nombres &&
                                !formik.errors.Nombres
                            }
                            isInvalid={
                                formik.touched.Nombres &&
                                !!formik.errors.Nombres
                            }
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.Nombres}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='my-2 col-md-6'>
                    <Form.Label>
                        Apellidos
                    </Form.Label>
                        <Form.Control 
                            name='Apellidos'
                            onChange={formik.handleChange}
                            value={formik.values.Apellidos}
                            placeholder="Escribe los Apellidos"
                            type='text'
                            isValid={
                                formik.touched.Apellidos &&
                                !formik.errors.Apellidos
                            }
                            isInvalid={
                                formik.touched.Apellidos &&
                                !!formik.errors.Apellidos
                            }
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.Apellidos}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='my-2 col-md-6'>
                        <Form.Label>
                            Correo
                        </Form.Label>
                            <Form.Control 
                                name='Correo'
                                onChange={formik.handleChange}
                                value={formik.values.Correo}
                                placeholder="Escribe el correo"
                                type='email'
                                isValid={
                                    formik.touched.Correo &&
                                    !formik.errors.Correo
                                }
                                isInvalid={
                                    formik.touched.Correo &&
                                    !!formik.errors.Correo
                                }
                            />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.Correo}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='my-2 col-md-6'>
                        <Form.Label>
                            Contraseña
                        </Form.Label>
                            <Form.Control 
                                name='Contraseña'
                                onChange={formik.handleChange}
                                value={formik.values.Contraseña}
                                placeholder="Escribe la Contraseña"
                                type='password'
                                isValid={
                                    formik.touched.Contraseña &&
                                    !formik.errors.Contraseña
                                }
                                isInvalid={
                                    formik.touched.Contraseña &&
                                    !!formik.errors.Contraseña
                                }
                            />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.Contraseña}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                        controlId="formBasicPhone"
                        className="col-md-6 my-2"
                    >
                        <Form.Label>Teléfono</Form.Label>
                        <PhoneInput
                            regions={["south-america"]}
                            country={"pe"}
                            value={formik.values.Celular}
                            onChange={(phone, country) => {
                                formik.setFieldValue(
                                    "Celular",
                                    phone
                                );
                                formik.setFieldValue(
                                    "id_pais",
                                    country.name.toUpperCase()
                                );
                            }}
                            autoFormat
                            inputClass="form-control my-1 w-100"
                            countryCodeEditable={false}
                            masks={{
                                pe: "... ... ...",
                            }}
                            isValid={(value, country) => {
                                if (
                                    formik.touched.Celular &&
                                    !!formik.errors.Celular
                                ) {
                                    return "Número de celular inválido";
                                } else {
                                    return true;
                                }
                            }}
                        />
                    </Form.Group>
                    <Form.Group className='my-2 col-md-6'>
                        <Form.Label>
                            Roles
                        </Form.Label>
                        <Form.Check type="checkbox" label="ADMIN" onChange={() => check(2)}/>
                        <Form.Check type="checkbox" label="DOCENTE" onChange={() => check(3)}/>
                        <Form.Check type="checkbox" label="MARKETING" onChange={() => check(4)}/>
                        <Form.Check type="checkbox" label="MANAGER" onChange={() => check(5)}/>
                        <Form.Check type="checkbox" label="CONTABILIDAD" onChange={() => check(6)}/>
                    </Form.Group>
                    <button type='submit' className="btn ge-btn-primary btn-block mt-3">
                            Crear Usuario
                    </button>
            </Form>
        </div>
    </div>
  )
}

export default CreateUserModal;