import React, {useState, useEffect} from "react";
import { Doughnut } from "react-chartjs-2";

const getData = () => {
  setTimeout(() => {
    
  },2000)
  const option = {
    labels: ["Utilidad Neta", "Ingresos", "Gastos"],
    datasets: [
      {
        label: "Ventas",
        data: [55, 112, 91],
        backgroundColor: [
          "rgba(259, 99, 132, 0.2)",
          "rgba(122, 111, 223, 0.2)",
          "rgba(222, 22, 666, 0.2)",
        ],
        borderColor: [
          "rgba(259, 99, 132)",
          "rgba(122, 111, 223)",
          "rgba(222, 22, 666)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return option;
};

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

const GananciaPerdida = () => {
    const [grafico, setGrafico] = useState(false);

    const getdataa = async () => {
        const response = await getData()
        setGrafico(response);
    }

    useEffect(() => {
        getdataa()
        console.log(grafico)
    },[])
  return (
    <div className="card-ingresos-gastos col-md-3">
      <div className="d-flex align-items-center w-100 justify-content-between">
        <span>GANANCIA Y PERDIDA</span>
        <span>Last motnh -</span>
      </div>
      <div className="d-flex flex-column mt-2">
        <span>S/. 0.00</span>
        <span>Utilidad neta de Abril</span>
      </div>
      <div className="d-flex col-md-12 mt-4 p-0">
        <div className="col-md-5 p-0">
          <div className="d-flex flex-column">
            <span>S/. 0.00</span>
            <span>Ingreso</span>
          </div>
          <div className="d-flex flex-column mt-3 pb-5">
            <span>S/. 0.00</span>
            <span>Gastos</span>
          </div>
        </div>
        <div className="col-md-7 p-0">
          {grafico && <Doughnut data={grafico} options={options} />}
        </div>
      </div>
    </div>
  );
};

export default GananciaPerdida;
