import React, { useEffect, useMemo, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import HeaderSearchTable from "../../Custom/HeaderSearchTable";
import { useQueryOrganicTraffic } from "../../../hooks/marketing/googleAnalytics/googleAnalytics.hooks";
import LoaderSmall from "../../Loaders/LoaderSmall";
import ReactDatePicker from "react-datepicker";
import CustomInputDate from "../../Custom/CustomInputDate";
import { decimalToTime, formatSecondsHMS } from "../../../utils/time";

const TraficoOrganico = () => {
    
    //SETUP initial date range
    const actualDay = new Date();
	let daysAgo7 = new Date();
	daysAgo7.setDate(actualDay.getDate() - 6);
    
	const initialState = [daysAgo7, actualDay];
    const [dateRange, setDateRange] = useState(initialState);
    const [startDate, endDate] = dateRange;
    
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
    
	/* Queries */
	const { data, isLoading, refetch } = useQueryOrganicTraffic(startDate, endDate);
    
	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={data}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
				placeholder="Buscar palabra clave"
				columnsToFilter={["ga:keyword"]}
			/>
		);
	}, [filterText, resetPagination]);
    
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "#",
				cell: (row, index) => index + 1,
				width: "5%",
			},
			{
				name: "Palabra clave",
				selector: (row) => row["ga:keyword"],
			},
			{
				name: "Sesiones",
				selector: (row) => row["ga:sessions"],
			},
			{
				name: "Usuarios",
				selector: (row) => row["ga:users"],
			},
			{
				name: "Nuevos usuarios",
                selector: (row) => row["ga:newUsers"],
			},
			{
				name: "Duración media de la sesión",
                cell: (row) => formatSecondsHMS(row["ga:avgSessionDuration"]),
				width: "200px",
			},
			{
				name: "Porcentaje de rebote",
                cell: (row) => Number(row["ga:bounceRate"]).toFixed(2) + "%",
				width: "200px",
			},
		],
		[]
	);
    
    useEffect(() => {
        if(startDate && endDate) {
            refetch();
        }
    }, [dateRange]);
    
	return (
		<div className="card p-2">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column flex-lg-row justify-content-lg-between mb-lg-3 align-items-center">
						<h5 className="ge-title txt-bold">
							Tráfico orgánico de la página
						</h5>
						<div className="my-2">
							{/* Range Picker */}
							<span className="txt-bold ge-title">Periodo:</span>
							<ReactDatePicker
								locale={"es"}
								selectsRange={true}
								startDate={startDate}
								endDate={endDate}
								onChange={(update) => {
									setDateRange(update);
								}}
								isClearable={true}
								// className="form-control ge-form my-2"
								placeholderText="Seleccione un rango"
								dateFormat="yyyy-MM-dd"
								maxDate={actualDay}
								customInput={<CustomInputDate />}
							/>
						</div>
					</div>
					<div className="col-12">
						<DataTable
							className="custom-datatable"
							columns={columns}
							data={
								filteredItems.length > 0 ? filteredItems : data
							}
							subHeader
							subHeaderComponent={searchComponentMemo}
							pagination
							responsive
							fixedHeader
							fixedHeaderScrollHeight="300px"
							progressPending={isLoading}
							progressComponent={<LoaderSmall />}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TraficoOrganico;
