import { useMutation, useQuery, useQueryClient } from "react-query";
import { createHabilidad, deleteHabilidad, getHabilidades } from "../controllers/cursos/habilidades/habilidades.controller";

const KEY_GLOSARY_COURSE = 'habilidades-cursos';


export const useQueryHabilidades = (idCourse) => {
    return useQuery(
        [KEY_GLOSARY_COURSE, idCourse],
        () => getHabilidades(idCourse),
        {
            retry: 1,
            refetchOnWindowFocus: false,
        }
    );
}


export const useMutateCreateHabilidad= (idCourse) => {
    const queryClient = useQueryClient();
    return useMutation(createHabilidad, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_GLOSARY_COURSE, idCourse]);
        }
    });
}



export const useMutateDeleteHabilidad = (idCourse) => {
    const queryClient = useQueryClient();
    return useMutation(deleteHabilidad, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_GLOSARY_COURSE, idCourse]);
        }
    });
}


