import { userTypes } from "../types";



const initialState = {
    user: {},
    isAuthenticated:false,
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case userTypes.LOGIN_USER:
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true
            }
        
        case userTypes.LOGOUT_USER:
            return {
                ...state,
                user: {},
                isAuthenticated: false
            }
        default:
            return state;
    }
}