

export const userTypes = {
    SET_USER: 'SET_USER',
    LOGOUT_USER: 'LOGOUT_USER',
    LOGIN_USER: 'LOGIN_USER'
}


export const docenteTypes = {
    SET_DOCENTE: 'SET_DOCENTE',
    SET_DOCENTES: 'SET_DOCENTES',
    SET_DOCENTE_EDIT: 'SET_DOCENTE_EDIT',
    CLEAR_DOCENTE_EDIT: 'CLEAR_DOCENTE_EDIT',
}


export const uiTypes = {
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING'
}