import React, { useMemo, useState } from 'react';
import { Badge } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import HeaderSearchTable from '../../components/Custom/HeaderSearchTable';
import LoaderSmall from '../../components/Loaders/LoaderSmall';
const VistaDePosts = ({postsList,isLoading}) => {

    const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);

        	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={postsList}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
                columnsToFilter={["titulo","nombres","fecha_creacion"]}
				placeholder="Buscar por Título, Autor o Fecha registro"
			/>
		);
	}, [filterText, resetPagination]);

	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: '#',
				cell: (row) => row.id_post,
				width: '5%',
			},
            {
				name: "Fecha registro",
				selector: (row) => row.fecha_creacion,
				sortable: true,
			},
			{
				name: "Titulo",
				selector: (row) => <div style={{textAlign:'left'}}>{row.titulo}</div>,
				sortable: true,
                wrap:true
			},
			{
				name: "Autor",
				selector: (row) => row.nombres,
				sortable: true,
			},
			{
				name: "Status",
				cell: (row) =>
					parseInt(row.estado) === 1 ? (
						<Badge className="ge-deg-primary p-1">Público</Badge>
					) : (
						<Badge bg="danger">Sin publicar</Badge>
					),
				sortable: true,
			},
            {
				name: "Vistas",
				cell: (row) => row.views,
				sortable: true,
			},
		],
		[]
	);



    return (
        <div className='row'>
        <div className='col-md-12'>
                <DataTable
                        className="custom-datatable"
                        columns={columns}
                        data={
                            filteredItems.length > 0
                                ? filteredItems
                                : postsList
                        }
                        subHeader
                        subHeaderComponent={searchComponentMemo}
                        pagination
                        responsive
                        fixedHeader
                        fixedHeaderScrollHeight="300px"
                        progressPending={isLoading}
                        progressComponent={<LoaderSmall />}
                    />
        </div>
   </div>
    );
};

export default VistaDePosts;