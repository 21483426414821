import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import HeaderSearchTable from "../../components/Custom/HeaderSearchTable";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import LoaderSmall from "../../components/Loaders/LoaderSmall";
import { FaIcons, BsIcons } from "../../icons/export-reacticons";
import { ToastToConfirm } from "../../utils/toasts";
import ModalCreateEditCupon from "../../components/Cupones/ModalCreateEditCupon";
import { useMutateDeleteCupon, useMutatePublishCupon, useQueryCupones } from "../../hooks/cupones.hooks";
import { Badge } from "react-bootstrap";


const ListarCupones = () => {
	
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [editData, setEditData] = useState(null);
	
	//React Query Hooks
	const { data: cupones, isLoading } = useQueryCupones();
	const { mutate: deteleCupon } =  useMutateDeleteCupon();
	const {mutate: toggleCupon} = useMutatePublishCupon();

	const handleDelete = (id_registro) => {
		const params = {
			id_registro
		}
		
		const text = "¿Deseas eliminar este cupón?";
		
		ToastToConfirm(deteleCupon, params, text);
	}

	const handleToggle = (id_registro,estado) => {
		const params = {
			id_registro,
			estado
		}
		
		const text = "El cupón será actualizado";
		
		ToastToConfirm(toggleCupon, params, text);
	}
	
	
	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={cupones}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
				placeholder="Buscar por descripción o codigo cupón"
				columnsToFilter={["descripcion","cupon"]}
			/>
		);
	}, [filterText, resetPagination]);
	
	
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "ID",
				cell: (row) => row.id_registro,
				width: "10%",
			},
			{
				name: "Cupón",
				width: "200px",
				selector: (row) => row.cupon,
		
			},
			{
				name: "Descripción",
				width: "200px",
				selector: (row) => row.descripcion,
			},
			{
				name: "% Dscto.",
				width: "200px",
				selector: (row) => row.descuento,
				sortable: true,
			},
			{
				name: "Fecha vencimiento",
				cell: (row) => dayjs(row.fecha_vencimiento).format("DD/MM/YYYY h:mm a"),
				sortable: true,
			},
			{
				name: "Fecha registro",
				cell: (row) => dayjs(row.fecha_registro).format("DD/MM/YYYY"),
				sortable: true,
			},
			{
				name: 'Estado',
				width: '10%',
				cell: (row) =>
				  parseInt(row.estado) === 1 ? (
					<Badge className='ge-deg-primary p-1'>Habilitado</Badge>
				  ) : (
					<Badge className='bg-danger'>Deshabilitado</Badge>
				  ),
			  },
			{
				name: "Acciones",
				cell: (row) => (
					<div className="d-flex p-3">
						<button
							className="btn ge-btn-deg-primary btn-sm mx-2"
							onClick={() =>
								{setEditData(row)
								 setShowModal(!showModal)}
							}
						>
							<FaIcons.FaEdit size={20} />
						</button>
						{parseInt(row.estado) === 1 ? (
							<button
								className='btn btn-light btn-sm'
								onClick={() => handleToggle(row.id_registro, 0)}>
								<BsIcons.BsToggleOn color='#37af48' size={20} />
							</button>
							) : (
							<button
								className='btn btn-light btn-sm'
								onClick={() => handleToggle(row.id_registro, 1)}>
								<BsIcons.BsToggleOff color='#ff0000' size={20} />
							</button>
							)}
						<button
							className="btn btn-danger btn-sm mx-2"
							title="Eliminar"
							onClick={() => handleDelete(row.id_registro)}
						>
							<FaIcons.FaTrashAlt size={20} />
						</button>
					</div>
				),
			},
		],
		[]
	);
	
	
	return (
		<LayoutAdmin contentTitle="" routeTitle="Cupones">
			<div className="row">
				<ModalCreateEditCupon
					show={showModal}
					setShow={setShowModal}
					editData={editData}
					setEditData={setEditData}
				/>
				<div className="col-12">
					<div className="card p-2">
						<div className="d-flex justify-content-between card-header card-no-after ge-noborder">
							<div>
								<img
									src="/images/file-icon.png"
									className="mr-2"
									style={{ width: "40px" }}
									alt="file-icon"
								/>
								<span className="ge-h3 ge-title">
									Cupones
								</span>
							</div>
							<div className="">
								<button
									className="btn ge-btn-deg-primary btn-sm"
									onClick={() => setShowModal(true)}
								>
									<FaIcons.FaPlus />
									<span className="ml-2">Crear cupón</span>
								</button>
							</div>
						</div>
						<div className="card-body">
							<DataTable
								className="custom-datatable"
								columns={columns}
								data={
									filteredItems.length > 0
										? filteredItems
										: cupones
								}
								subHeader
								subHeaderComponent={searchComponentMemo}
								pagination
								responsive
								progressPending={isLoading}
								progressComponent={<LoaderSmall />}
							/>
						</div>
					</div>
				</div>
			</div>
		</LayoutAdmin>
	);
};

export default ListarCupones;
