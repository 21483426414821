import dayjs from "dayjs";
import { getAuthAxios } from "../../axios/axiosCustom";

export const getReportAdmin = async (startDate) => {
    
    const month = dayjs(startDate).format("MM");
    const year = dayjs(startDate).format("YYYY");
    
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/admin/dashboard_superadmin/${month}/${year}`);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}