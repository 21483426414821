import React, { useEffect, useRef, useState } from 'react';
import { useQuery, useMutation,useQueryClient } from "react-query";
import { useFormik } from "formik";
import * as yup from "yup";
import { createHistoria, updateHistoria } from '../../controllers/historias/historias';
import { getSelectUsers } from '../../controllers/blog/posts';
import CustomSelectUsuariosFormik from '../../components/Custom/CustomSelectUsuariosFormik';
const FormularioHistoriaVideo = ({datosContenido=null,isNew=false}) => {


    const {data : listaUsers,error,isLoading} = useQuery(['UsersSelect'],getSelectUsers,{
		refetchOnWindowFocus:false,
	})

    const queryClient = useQueryClient()
    
    const {mutate:insertarHistoria } = useMutation(createHistoria,{
        onSuccess: ()=>{
            queryClient.invalidateQueries(["historias"])
            queryClient.refetchQueries(["getHistoria"])
        }
    })


    const {mutate:actualizarHistoria } = useMutation(updateHistoria,{
        onSuccess: ()=>{
            queryClient.invalidateQueries(["historias"])
            queryClient.refetchQueries(["getHistoria"])
        }
    })

    const initialValues = {
        unique_id:'',
        titulo:'',
        url_video:'',
        tipo_historia:2,
        autor:'',
	};
	
    const [datosPost,setDatosPost]=useState(initialValues)

	const validationSchema = yup.object({
		titulo: yup
			.string()
			.required("El campo es requerido"),
        url_video: yup
        .string()
        .required("El campo es requerido"),
        autor: yup
        .string()
        .required("El campo es requerido"),
	});
	
	const formik = useFormik({
		initialValues:datosPost,
		validationSchema,
		onSubmit: async (values) => {
            const datos = {...values}
           // console.log(values)
            if(!isNew)
            actualizarHistoria(datos)
            else
            insertarHistoria(datos)

		},
	});

   useEffect(()=>{
        if(datosContenido)
        {    
            setDatosPost(datosContenido)
        }
    },[datosContenido])

    useEffect(()=>{
        if(datosPost.titulo!=='')
        {
            formik.setValues(datosPost)
        }
    },[datosPost])

    return (
        <form onSubmit={formik.handleSubmit}>
        <div className='row'>
             <div className='col-md-12'>
                 <div className="card">
                     <div className="card-header p-3" style={{backgroundColor:'#842eff', color:'#fff'}}>
                         <h4>Datos de publicación</h4>
                     </div>
                     <div className="card-body">
                         <div className="row">
                             <div className="col-lg-12 p-2">
                                 <div className="form-group mb-3">
                                         <label>
                                             Titulo
                                         </label>
                                         <input
                                             type="text"
                                             className="form-control"
                                             placeholder="Titulo"
                                             name="titulo"
                                             onChange={formik.handleChange}
                                             value={formik.values.titulo}
                                         />
                                         <p> {formik.errors.titulo} </p>
                                </div>
                                 <div className="form-group mb-3">
                                         <label>Link del Video:</label>
                                         <input
                                            type="text"
                                             className="form-control"
                                             placeholder="Url del video"
                                             name="url_video"
                                             onChange={formik.handleChange}
                                             value={formik.values.url_video}
                                         />
                                         <p> {formik.errors.url_video} </p>
                                 </div> 
                                 <div className="form-group mb-3">
                                         <label>Autor:</label>
                                         <CustomSelectUsuariosFormik options={listaUsers} onChange={value=>formik.setFieldValue('autor',value.id_user)} value={formik.values.autor} isLoading={isLoading} />
                                         <p> {formik.errors.autor} </p>
                                 </div> 

                             </div>
                         </div>
                     </div>
                 </div>        
             </div>
             <div className='col-md-12'>
                 <div className="col-md-12 d-flex justify-content-end my-3">
                                 <button
                                     type="submit"
                                     className="btn ge-btn-primary"
                                 >
                                     Guardar
                                 </button>
                 </div>       
             </div>
        </div>
     </form>
    );
};

export default FormularioHistoriaVideo;