import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { SIDEBAR_MENU } from "../SideMenu/Config/index";
import ShowMenuItem from "../SideMenu/ShowMenuItem";

const AdminSidebar = () => {
	
	const {user} = useSelector(state => state.user);
	const {nombres, apellidos, img_perfil} = user;
	const urlImg = img_perfil ? `${process.env.REACT_APP_API_URL}${img_perfil}` : "/dist/img/user2-160x160.jpg";
	
	//Initialize Treeview Plugin
	useEffect(() => {
		const $ = window.$;
		$(document).ready(() => {
			// $('ul').Treeview('init');
			$('[data-widget="treeview"] .nav-link').Treeview('init');
		});
	} , [])
	
	
	return (
		<aside className="main-sidebar sidebar-dark-primary elevation-4 sidebar-gestora">
			{/* Brand Logo */}
			<div className="brand-link">
				<img
					src="/images/icono_gestora.png"
					alt="AdminLTE Logo"
					className="brand-image img-circle"
					style={{ opacity: ".8" }}
				/>
				<span className="brand-text text-light">Admin Gestora</span>
			</div>
			{/* Sidebar */}
			<div className="sidebar">
				{/* Sidebar user panel (optional) */}
				<div className="user-panel mt-3 mb-3 d-flex justify-content-center">
					<div className="image">
						<img
							src={urlImg}
							className="img-circle"
							alt="User"
						/>
					</div>
					<div className="info">
						<p className="d-block text-light">
							{nombres}{" "}{apellidos}
						</p>
					</div>
				</div>
				{/* Sidebar Menu */}
				<nav className="mt-2">
					<ul
						className="nav nav-pills nav-sidebar flex-column"
						data-widget="treeview"
						id="main-menu"
						role="menu"
						data-accordion="false"
					>
						{/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
						{
							SIDEBAR_MENU.map((menuModule, index) => (
								<ShowMenuItem 
									menuModule={menuModule} 
									key={index} 
								/>
							))
						}
					</ul>
				</nav>
				{/* /.sidebar-menu */}
			</div>
			{/* /.sidebar */}
		</aside>
	);
};

export default AdminSidebar;
