import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import { useMutation, useQueryClient } from "react-query";
import { createEscuela } from "../../controllers/escuelas/escuelas";
import { useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import { useRef } from "react";

const CreateEscuela = () => {
    
    const btnImageRef = useRef(null);
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const {
		mutate,
		error: errorCreate,
		isLoading: isLoadingCreate,
	} = useMutation(createEscuela, {
		onSuccess: () => {
			queryClient.invalidateQueries("getEscuelas");
			navigate("/listar-escuelas", { replace: true });
		},
	});

	// const {user} = useSelector(state=>state.user)

	const initialValues = {
		nombre_escuela: "",
		descripcion: "",
		archivo: {},
	};

	const [datosEscuela, setDatosEscuela] = useState(initialValues);
	const [renderImage, setRenderImage] = useState(null);
	const SUPPORTED_FORMATS = [
		"image/jpg",
		"image/jpeg",
		"image/gif",
		"image/png",
		"application/pdf",
	];

	const validationSchema = yup.object({
		nombre_escuela: yup.string().required("El campo es requerido"),
		descripcion: yup.string().required("El campo es requerido").max(250, 'Debe tener 250 caracteres o menos'),
		archivo: yup
			.mixed()
			.required("Debes seleccionar una imagen")
			.test(
				"fileFormat",
				"Archivo no permitido",
				(value) => value && SUPPORTED_FORMATS.includes(value.type)
			),
	});

	const formik = useFormik({
		initialValues: datosEscuela,
		validationSchema,
		onSubmit: async (values) => {
			const datos = { ...values };
			//console.log(datos);
			mutate(datos);
		},
	});

	useEffect(() => {
		if (formik.values.archivo.name) {
			setRenderImage(URL.createObjectURL(formik.values.archivo));
		}
	}, [formik.values.archivo]);

	return (
		<LayoutAdmin contentTitle="" routeTitle="Crear escuela">
			<form onSubmit={formik.handleSubmit}>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
                            <div className="card-header ge-bg-primary txt-white">
                                <h3 className="card-title">Crear escuela</h3>
                            </div>
							<div className="card-body">
								<div className="d-flex flex-column flex-md-row justify-content-md-between">
									<div className="col-md-6">
                                        <div className="d-flex justify-content-center align-items-center img-form-preview">
                                            {formik.values.archivo ? (
                                                formik.values.archivo
                                                    .name ? (
                                                    <img
                                                        src={renderImage}
                                                        alt={"..."}
                                                        style={{
                                                            objectFit:"cover",
                                                            maxWidth:"100%",
                                                            borderRadius:"10px",
                                                        }}
                                                    />
                                                ) : (
                                                    <img
                                                        src="images/gestora-placeholder.png"
                                                        alt={"..."}
                                                        style={{
                                                            objectFit:"cover",
                                                            maxWidth:"100%",
                                                            borderRadius:"10px",
                                                        }}
                                                    />
                                                )
                                            ) : null}
                                            <div
                                                title="Seleccionar imagen"
                                                className="btn-ref-file"
                                                onClick={()=>btnImageRef.current.click()}
                                            >
                                                <FaIcons.FaFileUpload />
                                            </div>
                                        </div>
                                        <p> {formik.errors.archivo} </p>
										<div className="d-none form-group mb-3">
											<label>Imagen de Portada:</label>
											<input
                                                ref={btnImageRef}
												className="form-control"
												id="archivo"
												type="file"
												name="archivo"
												onChange={(e) => {
													formik.setFieldValue(
														"archivo",
														e.target.files[0]
													);
												}}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group mb-3">
											<label>Nombre escuela:</label>
											<input
												type="text"
												className="form-control ge-form"
												placeholder="Nombre escuela"
												name="nombre_escuela"
												onChange={formik.handleChange}
												value={
													formik.values.nombre_escuela
												}
											/>
											<p>
												{" "}
												{
													formik.errors.nombre_escuela
												}{" "}
											</p>
										</div>
										<div className="form-group mb-3">
											<label>Descripción</label>
											<textarea
												className="form-control ge-form"
												placeholder="Descripción"
												name="descripcion"
												onChange={formik.handleChange}
												value={
													formik.values.descripcion
												}
												maxLength="250"
											/>
											<p className={formik.values.descripcion.length != 250 ? "" : "text-danger"}>
												{formik.values.descripcion.length}/250
											</p>
										</div>
										<div className="form-group mb-3">
											<button
												type="submit"
												className="btn ge-btn-deg-primary btn-block"
											>
												Crear escuela
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</LayoutAdmin>
	);
};

export default CreateEscuela;
