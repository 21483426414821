import React, { useEffect, useState } from "react";
import { getWeekCommentsPosts } from "../../../controllers/docentes/docentes";

const CardWeekCommentsPost = ({ idDocente = null }) => {
    
	const [commentsPost, setCommentsPost] = useState([]);
	const fetchData = async () => {
		const data = await getWeekCommentsPosts(idDocente);
		if (data) {
			setCommentsPost(data);
		}
	};
    
	useEffect(() => {
		if (idDocente) {
			fetchData();
		}
	}, [idDocente]);
    
	if (!idDocente) return null;
	return (
		<div className="card">
			<div className="card-body">
				{/* Header */}
				<div className="d-flex col-12 align-items-center header-details mb-3">
					<img
						src="/images/file-icon.png"
						alt="icon-file"
						className="mr-2"
						style={{ maxWidth: "25px", minWidth: "25px" }}
					/>
					<span className="ge-title ge-h3">Comentarios del blog</span>
				</div>
				<div className="list-items-details">
					{commentsPost.length > 0 ? (
						commentsPost.map(
							(comment, index) => (
								<div
									className="d-flex flex-column item-detail"
									key={index}
								>
									<span className="my-3">
										{comment.comentario}
									</span>
									<div className="d-flex justify-content-between">
										<div className="d-flex">
											<button className="btn ge-btn-primary btn-sm mr-2 my-2">
												<i className="fas fa-heart mr-2"></i>
												{comment.likes}
											</button>
											<button className="btn ge-btn-primary btn-sm my-2">
												<i className="fas fa-edit mr-2"></i>
												Editar
											</button>
										</div>
									</div>
								</div>
							)
						)
					) : (
						<div className="d-flex justify-content-center align-items-center w-100 rounded">
							<span className="ge-title">
								No tiene comentarios
							</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CardWeekCommentsPost;
