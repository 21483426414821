import { getAuthAxios } from "../../axios/axiosCustom";
import dayjs from "dayjs";

export const getIncidentsList = async () => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get("/clases/traer_reportes");
        if(response.data.status){
            return response.data.data;
        }else{
            throw new Error(response.data.message);
        }
    }catch(error){
        console.log(error)
    }
}



export const markIncidentAsResolved = async ({idIncident}) => {
    try{
        const axios = getAuthAxios();
        const response = await axios.post(`/clases/aceptar_reporte/${idIncident}`);
        if(response.data.status){
            return response.data;
        }else{
            throw new Error('Error al marcar como resuelto');
        }
    }catch(error){
        console.log(error)
    }
}



export const getInfoIncident = async (codIncident) => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/clases/traer_reporte/${codIncident}`);
        if(response.data.status){
            return response.data.data;
        }else{
            throw new Error('Error al traer información del reporte');
        }
    }catch(error){
        console.log(error)
    }
}


export const getSelectStatusIncident = async () => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get("/tickets_reporte_problemas/traer_estados_tickets");
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}


export const reponseIncident = async ({cod_incident, data}) => {
    
    const newData = {
        id_user: data.id_user,
        respuesta: data.answer,
        img: data.file,
    }
    
    const form = new FormData();
    Object.keys(newData).forEach((key) => {
        form.append(key, newData[key]);
    });
    
    try{
        const axios = getAuthAxios();
        const response = await axios.post(`/tickets_reporte_problemas/registrar_repuesta_soporte/${cod_incident}`,form,{
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        
        if(response.data.status){
            return response.data;
        }else{
            throw new Error('Error al responder al reporte');
        }
    }catch(error){
        console.log(error)
    }
}



export const closeTicket = async ({cod_incident}) => {
    try{
        const axios = getAuthAxios();
        const response = await axios.post(`/tickets_reporte_problemas/cerrar_ticket/${cod_incident}`);
        if(response.data.status){
            return response.data;
        }else{
            throw new Error('Error al cerrar el ticket');
        }
    }catch(error){
        console.log(error)
    }
}


export const archiveTicket = async ({cod_incident}) => {
    try{
        const axios = getAuthAxios();
        const response = await axios.post(`/tickets_reporte_problemas/archivar_ticket/${cod_incident}`);
        if(response.data.status){
            return response.data;
        }else{
            throw new Error('Error al archivar el ticket');
        }
    }catch(error){
        console.log(error)
    }
}


/* Dashboard Tickets */

export const getNewTicketsDataset = async (startDate, endDate) => {
    
    const startDate_ = dayjs(startDate).format("YYYY-MM-DD");
    const endDate_ = dayjs(endDate).format("YYYY-MM-DD");
    
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/tickets_reporte_problemas/grafico_tickets_nuevos/${startDate_}/${endDate_}`);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}


export const getResolvedTicketsDataset = async (startDate, endDate) => {
    
    const startDate_ = dayjs(startDate).format("YYYY-MM-DD");
    const endDate_ = dayjs(endDate).format("YYYY-MM-DD");
    
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/tickets_reporte_problemas/grafico_tickets_resueltos/${startDate_}/${endDate_}`);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}

export const getOpenTicketsDataset = async (startDate, endDate) => {
    
    const startDate_ = dayjs(startDate).format("YYYY-MM-DD");
    const endDate_ = dayjs(endDate).format("YYYY-MM-DD");
    
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/tickets_reporte_problemas/grafico_tickets_estado_abierto/${startDate_}/${endDate_}`);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}


export const getTimeResolvedTicket = async () => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get("/tickets_reporte_problemas/tiempo_resolucion");
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }
    catch(error){
        console.log(error)
        return null;
    }
}