import Swal from "sweetalert2";
import { getAuthAxios } from "../../../axios/axiosCustom";

export const getHabilidades = async (id_curso) => {
    const axios = getAuthAxios();
    const response = await axios.get(`/curso/traerHabilidades/${id_curso}`);
    if(response.data.status){
        return response.data.data;
    }
    throw new Error(response.data.message);
}


export const createHabilidad = async (data) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
    const axios = getAuthAxios();
    const response = await axios.post(`/curso/guardarHabilidad`,data);
    if(response.data.status){
        Toast.fire({
            icon: 'success',
            title: response.data.message
          })
        return response.data;
    }
    throw new Error(response.data.message);
}


export const deleteHabilidad = async (id_habilidad) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

    const axios = getAuthAxios();
    const response = await axios.delete(`/curso/eliminarHabilidad/${id_habilidad}`);
    if(response.data.status){
        Toast.fire({
            icon: 'success',
            title: response.data.message
          })
        return response.data;
    }
    throw new Error(response.data.message);
}
