import React, { useEffect, useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import CustomInputDate from "../Custom/CustomInputDate";
registerLocale("es", es);

const RangePicker = ({ handleChange }) => {
	
	const actualDay = new Date();
	let daysAgo7 = new Date();
	daysAgo7.setDate(actualDay.getDate() - 6);
	
	const initialState = [daysAgo7, actualDay];
	const [dateRange, setDateRange] = useState(initialState);
	const [startDate, endDate] = dateRange;
	
	useEffect(() => {
		if (startDate && endDate) {
			handleChange(dateRange);
		}
	}, [dateRange]);
	
	return (
		<ReactDatePicker
			locale={"es"}
			selectsRange={true}
			startDate={startDate}
			endDate={endDate}
			onChange={(update) => {
				setDateRange(update);
			}}
			isClearable={true}
			className="form-control ge-form"
			placeholderText="Seleccione un rango"
			dateFormat="dd/MM/yyyy"
			customInput={<CustomInputDate />}
		/>
	);
};

export default RangePicker;
