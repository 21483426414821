import { useFormik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useMutateEditDocente } from "../../../hooks/docentes.hooks";
import { clearDocenteEditAction } from "../../../redux/actions/docenteActions";
import { compareObjects } from "../../../utils/helpers";
import { SwalToast } from "../../../utils/toasts";
import * as FaIcons from "react-icons/fa";

const EditDocente = (props) => {
	
	const { shown,show, setShow } = props;
	const { docenteEdit } = useSelector((state) => state.docente);
	const [btnDisabled, setBtnDisabled] = useState(false);
	const [loading, setLoading] = useState(false);
	const [imagePreview, setImagePreview] = useState(docenteEdit?.imgPerfil || null);
	const imgInputRef = useRef();
	const dispatch = useDispatch();
	const { mutate, isLoading } = useMutateEditDocente();
	
	const initialValues = useMemo(() => {
		return {
			nombres: docenteEdit.nombres,
			apellidos: docenteEdit.apellidos,
			correo: docenteEdit.correo,
      password: docenteEdit.password,
			telefono: docenteEdit.telefono,
			imgPerfil: docenteEdit.imgPerfil,
			about: docenteEdit.about,
		};
	}, [docenteEdit]);
	
	const validationSchema = yup.object({
		nombres: yup.string().min(2).required("El nombre es requerido"),
		apellidos: yup.string().min(2).required("El apellido es requerido"),
		correo: yup
			.string()
			.email("El correo no es válido")
			.required("El correo es requerido"),
		telefono: yup.string(),
		about: yup.string(),
	});
	
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			let valuesToSubmit = { ...values };
			if(typeof valuesToSubmit.imgPerfil === "string"){
				delete valuesToSubmit.imgPerfil;
			}
			console.log("Contraseña:", values.password);
			mutate(
				{
					idDocente: docenteEdit.idDocente,
					dataDocente: valuesToSubmit,
				},
				{
					onSuccess: (data) => {
						//show sweet alert toast
						SwalToast.fire({
							icon: "success",
							title: data.message || "Docente editado correctamente",
						});
					},
					onError: (error) => {
						//show sweet alert toast
						SwalToast.fire({
							icon: "error",
							title: error.message || "Error al editar el docente",
						});
					}
				}
			);
		},
	});
	
	const handleClose = () => {
		dispatch(clearDocenteEditAction());
		setShow(false);
	};
	
	//Cambia la imagen de perfil
	const handleChangeImage = (e) => {
		e.preventDefault();
		setLoading(true);
		if (imgInputRef.current.files.length > 0) {
			//validar solo imagenes
			const file = e.target.files[0];
			if (file.type.indexOf("image") < 0) {
				setLoading(false);
				return;
			}
			// console.log(file);
			setImagePreview(URL.createObjectURL(file));
			formik.setFieldValue("imgPerfil", file);
			setLoading(false);
		}
	};
	
	//Disabled or enabled Update button when some field is changed
	useEffect(() => {
		if (compareObjects(initialValues, formik.values)) {
			setBtnDisabled(true);
		} else {
			setBtnDisabled(false);
		}
	}, [initialValues, formik.values]);
	
	return (
    <Modal
      // className="dark-modal"
      show={show}
      size="md"
      centered
      backdrop="static"
      keyboard={false}
      onHide={handleClose}
    >
      <Modal.Header className="ge-bg-primary txt-white">
        <Modal.Title>Editar Docente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <form className="row" onSubmit={formik.handleSubmit}>
            <div className="col-12">
              <div className="d-flex align-items-center img-form-preview">
                <img
                  src={
                    imagePreview
                      ? imagePreview.includes("files")
                        ? // ? process.env.REACT_APP_API_URL + imagePreview
                          process.env.REACT_APP_API_URL + imagePreview
                        : imagePreview
                      : "/images/gestora-placeholder.png"
                  }
                  alt="Imagen de perfil"
                  style={{
                    objectFit: "cover",
                    maxWidth: "100%",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                />
                <div className="btn-ref-file">
                  <input
                    type="file"
                    ref={imgInputRef}
                    onChange={(e) => {
                      handleChangeImage(e);
                    }}
                    className="d-none"
                    disabled={loading}
                    accept="image/*"
                  />
                  {loading ? (
                    <span>
                      <Spinner
                        animation="border"
                        variant="primary"
                        className="mt-2"
                      />
                    </span>
                  ) : (
                    <span
                      className="img-profile-button text-center p-1"
                      onClick={() => imgInputRef.current.click()}
                    >
                      <FaIcons.FaPencilAlt />
                    </span>
                  )}
                </div>
              </div>
            </div>
            <Form.Group className="col-12 col-md-6 my-2">
              <Form.Label>Nombres</Form.Label>
              <Form.Control
                className="ge-form"
                type="text"
                name="nombres"
                placeholder="Nombres"
                onChange={formik.handleChange}
                value={formik.values.nombres}
                isInvalid={formik.touched.nombres && formik.errors.nombres}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.nombres}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-12 col-md-6 my-2">
              <Form.Label>Apellidos</Form.Label>
              <Form.Control
                className="ge-form"
                type="text"
                name="apellidos"
                placeholder="Apellidos"
                onChange={formik.handleChange}
                value={formik.values.apellidos}
                isInvalid={formik.touched.apellidos && formik.errors.apellidos}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.apellidos}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-12 col-md-6 my-2">
              <Form.Label>Correo</Form.Label>
              <Form.Control
                className="ge-form"
                type="email"
                name="correo"
                placeholder="Correo"
                onChange={formik.handleChange}
                value={formik.values.correo}
                isInvalid={formik.touched.correo && formik.errors.correo}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.correo}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-12 col-md-6 my-2">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                className="ge-form"
                type="password"
                name="password"
                placeholder="Contraseña"
                onChange={formik.handleChange}
                value={formik.values.password}
                isInvalid={formik.touched.password && formik.errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="form-group col-12 col-md-6 my-2">
              <Form.Label>Teléfono</Form.Label>
              <PhoneInput
                regions={["south-america"]}
                country={"pe"}
                value={formik.values.telefono}
                onChange={(phone, country) => {
                  formik.setFieldValue("telefono", phone);
                }}
                autoFormat
                inputClass="form-control my-1 w-100"
                dropdownClass="bg-dark"
                countryCodeEditable={false}
                masks={{
                  pe: "... ... ...",
                }}
                isValid={(value, country) => {
                  if (formik.touched.telefono && !!formik.errors.telefono) {
                    return "Teléfono inválido";
                  } else {
                    return true;
                  }
                }}
              />
            </div>
            <Form.Group
              controlId="formBasicDescription"
              className="col-md-12 my-2"
            >
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                className="ge-form"
                rows="3"
                placeholder="Descripción"
                name="about"
                onChange={formik.handleChange}
                value={formik.values.about}
                isValid={formik.touched.about && !formik.errors.about}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.about}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-flex w-100 my-3 justify-content-around">
              <button
                type="submit"
                className="btn ge-bg-primary txt-white"
                disabled={!formik.isValid || isLoading || btnDisabled}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Editar docente"
                )}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                disabled={isLoading}
                onClick={handleClose}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditDocente;
