import { uiTypes } from "../types"


export const startLoading = () => {
    return {
        type: uiTypes.START_LOADING
    }
}

export const stopLoading = () => {
    return {
        type: uiTypes.STOP_LOADING
    }
}