
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation, useQuery,useQueryClient } from "react-query";
import { Spinner } from "react-bootstrap";
import { getFrecuenciasPago, getPlanById, getTiposPlan, updatePlan } from "../../controllers/planes/planes";

const EditarPlan = ({idPlan,handleShow,handleCurrentId}) => {

    const{data : dataPlan, error : errorPlan , isLoading : isLoadingPlan} = useQuery(["getPlan",idPlan], ()=> getPlanById(idPlan),
        {
            refetchOnWindowFocus:false
        }
    )

    const{data, error , isLoading } = useQuery(["getFrecuenciasPago"], getFrecuenciasPago ,
    {
        refetchOnWindowFocus:false
    }
    )

    const{data : dataTipoPlan, error:errorTipoPlan , isLoading : isLoadingTipoPlan } = useQuery(["getTiposPlan"], getTiposPlan ,
    {
    refetchOnWindowFocus:false
    }
    )


    const queryClient = useQueryClient()
    
    const {mutate, error : errorUpdate , isLoading : isLoadingUpdate } = useMutation(updatePlan,{
        onSuccess: ()=>{
            queryClient.invalidateQueries(["planes"])
            queryClient.invalidateQueries(["getPlan"])
            handleShow(false)
            handleCurrentId(null)
        }
    })

    const initialValues = {
        id_tipo_plan:'',
        intervalo:'',
        intervalo_count:'',
        limite:'',
        currency_code: "PEN",
        nombre_plan:'',
        amount:'',
        n_estudiantes:'',
        precio_regular:'',
	};
	
    const [datosPost,setDatosPost]=useState(initialValues)

    const validationSchema = yup.object({
		id_tipo_plan: yup
			.string()
			.required("El campo es requerido"),
        intervalo: yup
			.string()
			.required("El campo es requerido"),
        intervalo_count: yup
            .number("Debe ser un número válido")
			.required("El campo es requerido"),
        amount: yup
            .number("Debe ser un número válido")
            .required("El campo es requerido"),
        precio_regular: yup
            .number("Debe ser un número válido")
            .required("El campo es requerido"),
        n_estudiantes: yup
            .number("Debe ser un número válido")
            .required("El campo es requerido"),
        limite: yup
            .number("Debe ser un número válido")
			.required("El campo es requerido"),
        nombre_plan: yup
            .string()
            .required("El campo es requerido"),
	});
	
	
	const formik = useFormik({
		initialValues:datosPost,
		validationSchema,
		onSubmit: async (values) => {
            const datos = {...values}
           // datos.contenido=contenidoHTML
           console.log(datos)
			mutate(datos)
		},
	});

   useEffect(()=>{
        if(!isLoadingPlan && !errorPlan)
        {
            setDatosPost(dataPlan)
        }
    },[isLoadingPlan])

    useEffect(()=>{
        if(datosPost?.nombre_plan!=='')
        {
            formik.setValues(datosPost)
        }
    },[datosPost])

    return (
        <>
        { isLoadingPlan ? <div className="text-center"> <Spinner animation="border" /></div> :  <form onSubmit={formik.handleSubmit}>
           <div className='row'>
                <div className='col-md-12'>
                    <div className="card">
                        <div className="card-body">
                                    <div className="form-group mb-3">
                                        <label>
                                            Nombre
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Nombre del Plan"
                                            name="nombre_plan"
                                            onChange={formik.handleChange}
                                            value={formik.values.nombre_plan}
                                            readOnly
                                        />
                                        <p> {formik.errors.nombre_plan} </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>Tipo de plan:</label>
                                        <select className="form-control"
                                                name="id_tipo_plan"
                                                onChange={formik.handleChange}
                                                value={formik.values.id_tipo_plan}>
                                                <option value="">{isLoadingTipoPlan ? 'Cargando tipos': !error ? 'Seleccione una tipo...': 'Error al traer datos...'}</option>
                                            {
                                                !isLoadingTipoPlan ? dataTipoPlan.length ? dataTipoPlan.map((tipo,i)=>(
                                                    <option key={i} value={tipo.id}>{tipo.nombre}</option>
                                                )) : null : null
                                            }
                                        </select>
                                        <p> {formik.errors.id_tipo_plan} </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>Frecuencia de pago:</label>
                                        <select className="form-control"
                                                name="intervalo"
                                                onChange={formik.handleChange}
                                                value={formik.values.intervalo}
                                                readOnly
                                                disabled>
                                                <option value="">{isLoading ? 'Cargando frecuencias': !error ? 'Seleccione una frecuencia...': 'Error al traer escuelas'}</option>
                                            {
                                                !isLoading ? data.length ? data.map((frecuencia,i)=>(
                                                    <option key={i} value={frecuencia.id}>{frecuencia.frecuencia}</option>
                                                )) : null : null
                                            }
                                        </select>
                                        <p> {formik.errors.intervalo} </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>
                                            Cantidad de frecuencia:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="1"
                                            name="intervalo_count"
                                            onChange={formik.handleChange}
                                            value={formik.values.intervalo_count}
                                            readOnly
                                        />
                                        <p> {formik.errors.intervalo_count} </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>
                                            Límite:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="1-12"
                                            name="limite"
                                            onChange={formik.handleChange}
                                            value={formik.values.limite}
                                            readOnly
                                        />
                                        <p> {formik.errors.limite} </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>
                                            Monto:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="1-12"
                                            name="amount"
                                            onChange={formik.handleChange}
                                            value={formik.values.amount}
                                            readOnly
                                        />
                                        <p> {formik.errors.amount} </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>
                                            Monto Regular:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="1-12"
                                            name="precio_regular"
                                            onChange={formik.handleChange}
                                            value={formik.values.precio_regular}
                                        />
                                        <p> {formik.errors.precio_regular} </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label>
                                            N° Estudiantes:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="1-12"
                                            name="n_estudiantes"
                                            onChange={formik.handleChange}
                                            value={formik.values.n_estudiantes}
                                        />
                                        <p> {formik.errors.n_estudiantes} </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <button
                                            type="submit"
                                            className="btn ge-btn-primary btn-block"
                                        >
                                            Guardar plan
                                        </button>
                                    </div>
                                    
                        </div>
                    </div>        
                </div>
           </div>
        </form>}
        </>
    );
};

export default EditarPlan;