import React, { useMemo, useState } from 'react';
import { useMutateDeleteUser, useQueryAllUsers } from '../../hooks/userRoles';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import HeaderSearchTable from '../../components/Custom/HeaderSearchTable';
import DataTable from "react-data-table-component";
import * as FaIcons from 'react-icons/fa'
import LoaderSmall from '../../components/Loaders/LoaderSmall';
import CustomModal from '../../components/Modal/CustomModal'; 
import CreateUserModal from '../../components/userRoles/CreateUserModal'; 
import EditUserModal from '../../components/userRoles/EditUserModal';
import RestablecerPasswordModal from '../../components/userRoles/RestablecerPasswordModal';

const ListaUsuariosRoles = () => {
    const [filterText, setFilterText] = useState("");
    const [filteredItems, setFilteredItems] = useState();
    const [resetPagination, setResetPagination] = useState(false);
    const [ showCreate, setShowCreate ] = useState(false);
    const [ showEdit, setShowEdit ] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [ user, setUser ] = useState();


    const { data: users, isLoading } = useQueryAllUsers();

    const { mutate: mutateDeleteUser } = useMutateDeleteUser();

      	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={users}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
        columnsToFilter={["rol","correo"]}
				placeholder="Buscar por rol o correo"
			/>
		);
	}, [filterText, resetPagination]);


    /* Config Table */
	const columns = useMemo(
        () => [
          {
            name: "#",
            cell: (row, index) => index + 1,
            width: "5%",
          },
          {
            name: "Nombre de usuario",
            selector: (row) => `${row.nombres} ${row.apellidos}`,
            sortable: true,
          },
          {
            name: "Correo",
            selector: (row) => row.correo,
            sortable: true
          },
          {
            name: "Roles",
            selector: (row) => row.roles.map((v) => `${v.nombre_rol.toUpperCase()} `),
            sortable: true
          },
          {
            name: "Acciones",
            cell: (row) => (
              <div className="d-flex">
                <button
                    className="btn ge-btn-primary btn-sm mr-2"
                    onClick={()=> handleEditUser(row)}
                >
                    <FaIcons.FaEdit />
                </button>   
                <button
                    className="btn ge-btn-primary btn-sm mr-2"
                    onClick={()=> handleResetPassword(row.id_user)}
                >
                    <FaIcons.FaUnlockAlt />
                </button> 
                <button
                  className="btn btn-danger btn-sm mr-2"
                  onClick={()=> handleDeleteUser(row.id_user)}
                >
                  <FaIcons.FaTrash />
                </button>
              </div>
            )}
        ],[]);

    const handleDeleteUser = (id_user) => {
      mutateDeleteUser(id_user)
    };
    
    const handleResetPassword = (id_user) => {
      setUser(id_user)
      setShowPassword(true)
    };

    const handleEditUser = (data_user) => {
      setUser(data_user)
      setShowEdit(true)
    };

  return (
    <LayoutAdmin contentTitle = "" routeTitle="Lista de Usuarios con roles">
        <CustomModal title={'Crear usuario'} show={showCreate} handleShow={setShowCreate}>
            <CreateUserModal handleShow={setShowCreate} />
        </CustomModal>
        <CustomModal title={'Editar Usuario'} show={showEdit} handleShow={setShowEdit}>
            <EditUserModal handleShow={setShowEdit} dataUser={user}/>
        </CustomModal>
        <CustomModal title={'Restablecer contraseña'} show={showPassword} handleShow={setShowPassword}>
          <RestablecerPasswordModal handleShow={setShowPassword} idUser={user} />
        </CustomModal>
        <div className='row'>
                <div className='col-12'>
                    <div className="card p-2">
                      <div className="card-header ge-noborder">
                        <div className="d-flex justify-content-between">
                          <div>
                            <img
                              src="/images/file-icon.png"
                              className="mr-2"
                              style={{ width: "40px" }}
                              alt="file-icon"
                            />
                            <span className="ge-h3 ge-title">
                              Lista de Usuarios
                            </span>
                          </div>
                          <div className="">
                            <button
                              className="btn ge-btn-primary btn-sm"
                              onClick={() => setShowCreate(true)}
                            >
                              <FaIcons.FaPlus />
                              <span className="ml-2">Nuevo usuario</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <DataTable
                          className="custom-datatable"
                          columns={columns}
                          data={
                            filteredItems?.length > 0
                              ? filteredItems
                              : users
                          }
                          subHeader
                          subHeaderComponent={searchComponentMemo}
                          pagination
                          responsive
                          fixedHeader
                          fixedHeaderScrollHeight="300px"
                          progressPending={isLoading}
                          progressComponent={<LoaderSmall />}
                        />
                        </div>
                    </div>
                </div>
           </div>
    </LayoutAdmin>
  )
}

export default ListaUsuariosRoles