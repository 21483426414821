import { useFormik } from "formik";
import * as yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import EditorGeneral from "../Editor/EditorGeneral";
import { updateRuta } from "../../controllers/rutas/rutas";
import * as FaIcons from "react-icons/fa";
import { SwalToast } from "../../utils/toasts";
import { SUPPORTED_IMG_FORMATS } from "../../utils/helpers";
import dayjs from "dayjs";
import { Form, Spinner } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { filterPassedTime } from "../../utils/time";
import CustomInputDate from "../Custom/CustomInputDate";
import { useParams } from "react-router-dom";


const validationSchema = yup.object({
	nombre_ruta: yup.string().required("El campo es requerido"),
	rutaTitle: yup.string().required("El campo es requerido").max(100,'Debe tener 100 carecteres o menos'),
	descripcion: yup.string().required("El campo es requerido").max(350,'Debe tener 350 carecteres o menos'),
	img_portada: yup.mixed().when("imageIsUrl", {
		is: true,
		then: yup
			.string()
			.required("El campo es requerido")
			.test("FilenameUrl", "La url de la imagen debe contener 'files/'", (value) => {
			return value && value.includes("files");
			}),
		otherwise: yup
			.mixed()
			.required("El campo es requerido")
			.nullable()
			.test("fileType", "Solo se permiten imagenes", (value) => {
				return value && SUPPORTED_IMG_FORMATS.includes(value.type);
			}),
	}),
	live: yup.number().required("El campo es requerido"),
	enlace: yup.string().when("live", {
		is: 1,
		then: yup.string().url("La URL debe ser válida").required("El campo es requerido"),
		otherwise: yup.string().notRequired(),
	}),
	fecha_inicio: yup.date().when("live", {
		is: 1,
		then: yup.date().required("El campo es requerido"),
		otherwise: yup.date().notRequired().nullable(),
	}),
	imageIsUrl: yup.boolean().required("El campo es requerido"),
});

const DatosRuta = ({ ruta = null }) => {
	
	const { uId } = useParams();
	
	const [imagePreview, setImagePreview] = useState(null);
	const imgInputRef = useRef();
	const queryClient = useQueryClient();
	const [idRuta, setIdRuta] = useState(ruta?.id_ruta || null);
	
	const {
		mutate: mutateUpdateRuta,
		isLoading,
	} = useMutation(updateRuta, {
		onSuccess: () => {
			queryClient.invalidateQueries(["getRutas"]);
			queryClient.invalidateQueries(["getRuta", uId]);
		},
	});
	
	const initialValues = {
		nombre_ruta: "",
		rutaTitle: "",
		descripcion: "",
		img_portada: null,
		live: 0,
		enlace: "",
		fecha_inicio: null,
		imageIsUrl: false,
		contenido_html: "",
	}
	
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values) => {
			const datos = { ...values, id_ruta: idRuta };
			
			// console.log(datos)
			mutateUpdateRuta(datos,{
				onSuccess: () => {
					SwalToast.fire({
						icon: "success",
						title: "Ruta actualizada",
					});
				},
				onError: (error) => {
					SwalToast.fire({
						icon: "error",
						title: "Error al actualizar la ruta",
					});
				}
			});
		},
	});
	
	//Cambia la imagen de perfil
	const handleChangeImage = (e) => {
		e.preventDefault();
		if (imgInputRef.current.files.length > 0) {
			//validar solo imagenes
			const file = e.target.files[0];
			if (file.type.indexOf("image") < 0) {
				return;
			}
			// console.log(file);
			setImagePreview(URL.createObjectURL(file));
			formik.setFieldValue("imageIsUrl", false);
			formik.setFieldValue("img_portada", file);
		}
	};
	
	const onChangeEditor = (contenidoHTML) => {
		formik.setFieldValue("contenido_html", contenidoHTML);
	}
	
	useEffect(() => {
		if(ruta){
			const newValues = {
				nombre_ruta: ruta.nombre_ruta || "",
				rutaTitle: ruta.rutaTitle || "",
				descripcion: ruta.descripcion || "",
				img_portada: (ruta.img_portada && ruta.img_portada !== "") ? ruta.img_portada : null,
				live: parseInt(ruta.id_tipo_ruta) === 3 ? 1 : 0,
				enlace: ruta.enlace || "",
				fecha_inicio: ruta?.fecha_inicio ? dayjs(ruta.fecha_inicio).$d : null,
				imageIsUrl: (ruta.img_portada && ruta.img_portada.includes("files")) ? true : false,
				contenido_html: ruta.contenido_html || "",
			}
			formik.setValues(newValues);
			
			setIdRuta(ruta.id_ruta);
		}
		if(ruta.img_portada){
			setImagePreview(process.env.REACT_APP_API_URL + ruta.img_portada);
		}
	}, [ruta]);
	
	return (
		<div className="card">
			<div className="card-body">
				<Form className="form-add-docente row" onSubmit={formik.handleSubmit}>
					{/* Img perfil */}
					<div className="col-12">
						<div className="d-flex justify-content-center w-100 mb-md-3 img-profile-container">
							<img
								src={
									!imagePreview
										? "https://via.placeholder.com/300?text=Imagen+de+portada"
										: imagePreview
								}
								alt="..."
								className={!imagePreview ? "img-profile" : "w-100"}
								style={{
									borderRadius: "5px",
								}}
							/>
							<div className="button-container">
								<input
									type="file"
									ref={imgInputRef}
									onChange={(e) => {
										handleChangeImage(e);
									}}
									className="d-none"
									accept="image/*"
								/>
								<span
									className="img-profile-button text-center"
									onClick={() =>
										imgInputRef.current.click()
									}
								>
									<FaIcons.FaPencilAlt />
								</span>
							</div>
						</div>
						{
							formik.errors.img_portada && formik.touched.img_portada && (
								<div className="text-danger">
									{formik.errors.img_portada}
								</div>
							)
						}
					</div>
					
					{/* Datos generales */}
					<div 
						className={
							parseInt(formik.values.live) === 1 && 0 ? "col-12" : "col-md-4"
						}
					>
						<Form.Group className="mb-3">
							<Form.Label>Nombre Ruta:</Form.Label>
							<Form.Control
								type="text"
								className="ge-form"
								placeholder="Nombre ruta"
								name="nombre_ruta"
								onChange={formik.handleChange}
								value={formik.values.nombre_ruta}
								isValid={formik.touched.nombre_ruta && !formik.errors.nombre_ruta}
								isInvalid={formik.touched.nombre_ruta && formik.errors.nombre_ruta}
							/>
							<Form.Control.Feedback type="invalid">
								{formik.errors.nombre_ruta}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Subtítulo</Form.Label>
							<Form.Control
								as="textarea"
								className="ge-form"
								placeholder="Sub-título"
								rows={5}
								name="rutaTitle"
								onChange={formik.handleChange}
								value={formik.values.rutaTitle}
								isValid={formik.touched.rutaTitle && !formik.errors.rutaTitle}
								isInvalid={formik.touched.rutaTitle && formik.errors.rutaTitle}
								maxLength="100"
							/>
							<p className={formik.values.rutaTitle.length != 100 ? "" : "text-danger"}>
								{formik.values.rutaTitle.length}/100
							</p>
							<Form.Control.Feedback type="invalid">
								{formik.errors.rutaTitle}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Descripción</Form.Label>
							<Form.Control
								as="textarea"
								className="form-control ge-form"
								placeholder="Descripción"
								rows={5}
								name="descripcion"
								onChange={formik.handleChange}
								value={formik.values.descripcion}
								isValid={formik.touched.descripcion && !formik.errors.descripcion}
								isInvalid={formik.touched.descripcion && formik.errors.descripcion}
								maxLength="350"
							/>
							<p className={formik.values.descripcion.length != 350 ? "" : "text-danger"}>
								{formik.values.descripcion.length}/350
							</p>
							<Form.Control.Feedback type="invalid">
								{formik.errors.descripcion}
							</Form.Control.Feedback>
						</Form.Group>
						{
							parseInt(formik.values.live) === 1 && (
								<>
									<Form.Group className="my-3">
										<Form.Label>
											URL del video:
										</Form.Label>
										<Form.Control
											type="text"
											placeholder="URL del video"
											className="ge-form"
											name="enlace"
											onChange={formik.handleChange}
											value={formik.values.enlace}
											autoComplete="off"
											isValid={
												formik.touched.enlace && !formik.errors.enlace
											}
											isInvalid={
												formik.touched.enlace && !!formik.errors.enlace
											}
										/>
										<Form.Control.Feedback type="invalid">
											{formik.errors.enlace}
										</Form.Control.Feedback>
									</Form.Group>
									
									<Form.Group className="my-3">
										<Form.Label>Fecha de inicio:</Form.Label>
										<ReactDatePicker
											locale={"es"}
											selected={formik.values.fecha_inicio}
											onChange={(date) => {
												formik.setFieldValue(
													"fecha_inicio",
													date
												);
											}}
											isClearable={true}
											dateFormat="dd/MM/yyyy h:mm aa"
											showTimeInput
											timeInputLabel="Hora"
											filterTime={filterPassedTime}
											minDate={new Date()}
											customInput={<CustomInputDate />}
											name="fecha_inicio"
										/>
										{
											formik.touched.fecha_inicio && formik.errors.fecha_inicio && (
												<div className="invalid-feedback">
													{formik.errors.fecha_inicio}
												</div>
											)
										}
									</Form.Group>
								</>
							)
						}
					</div>
					{/* {
						parseInt(formik.values.live) === 0 && ( */}
							<div className="col-md-8">
								<EditorGeneral
									contenido={formik.values.contenido_html}
									onChange={onChangeEditor}
								/>
							</div>
						{/* )
					} */}
					<div className="col-12 my-3">
						<button
							type="submit"
							className="btn ge-btn-deg-primary btn-block"
							disabled={isLoading}
						>
							{
								isLoading ? (
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
								) : (
									"Actualizar datos"
								)
							}
						</button>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default DatosRuta;