import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import LineChartDataset from "../../components/Charts/LineChartDataset";
import CustomInputDate from "../../components/Custom/CustomInputDate";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import LoaderSmall from "../../components/Loaders/LoaderSmall";
import TablaReporte from "../../components/Tablas/TablaReporte";
import { getReportDataSet } from "../../controllers/estudiantes/estudiantes.controller";
import { SwalToast } from "../../utils/toasts";
// import { getDateTimeObject } from "../../utils/dateTime";

const DashboardEstudiantes = () => {
	const actualDay = new Date();
	let daysAgo7 = new Date();
	daysAgo7.setDate(actualDay.getDate() - 6);
	
	const initialState = [daysAgo7, actualDay];
	
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [dateRange, setDateRange] = useState(initialState);
	const [startDate, endDate] = dateRange;
	const chartRef = useRef(null);
	
	const getData = async () => {
		setLoading(true);
		const startDate_ = dayjs(startDate).format("YYYY-MM-DD");
		const endDate_ = dayjs(endDate).format("YYYY-MM-DD");
		const data_ = await getReportDataSet(startDate_, endDate_);
		if (data_) {
			if (data_.status) {
				setData(data_.data);
			} else {
				SwalToast.fire({
					icon: "error",
					title: "Error",
					text: data_.message,
				});
				setDateRange([null, null]);
				setData(null);
			}
		} else {
			SwalToast.fire({
				icon: "error",
				title: "Error",
				text: "Error al obtener los datos",
			});
			setDateRange([null, null]);
			setData(null);
		}
		setLoading(false);
	};
	
	const downloadReport = async () => {
		if (chartRef) {
			const link = document.createElement("a");
			link.download = "chart";
			link.href = chartRef.current.toBase64Image();
			link.click();
		}
	};
	
	useEffect(() => {
		if (startDate && endDate) {
			getData();
		}
	}, [dateRange]);
	
	return (
		<LayoutAdmin
			contentTitle="Dashboard Estudiantes"
			routeTitle="Dashboard Estudiantes"
		>
			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="d-flex flex-column flex-md-row justify-content-md-between col-12 align-items-center mb-3 mb-md-0">
							<div className="col-12 col-md-4">
								{/* Range Picker */}
								<span className="txt-bold ge-title">
									Periodo:
								</span>
								<ReactDatePicker
									locale={"es"}
									selectsRange={true}
									startDate={startDate}
									endDate={endDate}
									onChange={(update) => {
										setDateRange(update);
									}}
									isClearable={true}
									className="form-control ge-form my-2"
									placeholderText="Seleccione un rango"
									dateFormat="yyyy-MM-dd"
									maxDate={actualDay}
									customInput={<CustomInputDate />}
								/>
							</div>
							{data && (
								<button
									className="btn ge-btn-primary btn-sm"
									onClick={downloadReport}
								>
									<i
										className="fa fa-download mr-2"
										aria-hidden="true"
									></i>
									Descargar gráfico
								</button>
							)}
						</div>
						
						<div className="col-12 mt-4">
							{/* Grafico */}
							{loading ? (
								<LoaderSmall />
							) : data ? (
								<LineChartDataset ref={chartRef} datos={data} />
							) : (
								<div className="d-flex justify-content-center align-items-center w-100">
									<span className="ge-txt-muted-dark">
										No hay datos para mostrar
									</span>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{data && (
				<div className="card">
					<div className="card-body d-flex justify-content-center">
						<TablaReporte datos={data} />
					</div>
				</div>
			)}
		</LayoutAdmin>
	);
};

export default DashboardEstudiantes;
