import _ from "lodash";

export default function ClickHandler(e) {
	e.preventDefault();
	const href = e.target.getAttribute("href");
	if (document.querySelector(href))
		document.querySelector(href).scrollIntoView({
			behavior: "smooth",
		});
}

export const GenerateYears = () => {
	const max = new Date().getFullYear();
	const min = max - 10;
	const years = [];

	for (let i = max; i >= min; i--) {
		const year = {
			id: i.toString(),
			value: i.toString(),
		};
		years.push(year);
	}
	return years;
};
export const GenerateMeses = () => {
	const meses = [
		{
			id: "01",
			value: "Enero",
		},
		{
			id: "02",
			value: "Febrero",
		},
		{
			id: "03",
			value: "Marzo",
		},
		{
			id: "04",
			value: "Abril",
		},
		{
			id: "05",
			value: "Mayo",
		},
		{
			id: "06",
			value: "Junio",
		},
		{
			id: "07",
			value: "Julio",
		},
		{
			id: "08",
			value: "Agosto",
		},
		{
			id: "09",
			value: "Septiembre",
		},
		{
			id: "10",
			value: "Octubre",
		},
		{
			id: "11",
			value: "Noviembre",
		},
		{
			id: "12",
			value: "Diciembre",
		},
	];
	return meses;
};

//Compare 2 objects
export const compareObjects = (obj1, obj2) => {
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) return false;

	for (let key of keys1) {
		let val1 = obj1[key];
		let val2 = obj2[key];
		let areObjects = _.isObject(val1) && _.isObject(val2);

		if (
			(areObjects && !compareObjects(val1, val2)) ||
			(!areObjects && val1 !== val2)
		)
			return false;
	}

	return true;
};


export const SUPPORTED_IMG_FORMATS = [
	"image/jpeg",
	"image/png",
	"image/jpg"
];
