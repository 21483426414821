import { useMutation, useQuery, useQueryClient } from "react-query";
import { createGlosary, deleteGlosary, getGlosariesByCourse, updateGlosary } from "../controllers/cursos/glosario/glosario.controller";

const KEY_GLOSARY_COURSE = 'glosario-by-course';


export const useQueryGlosaryByCourse = (idCourse) => {
    return useQuery(
        [KEY_GLOSARY_COURSE, idCourse],
        () => getGlosariesByCourse(idCourse),
        {
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}


export const useMutateCreateGlosary = (idCourse) => {
    const queryClient = useQueryClient();
    return useMutation(createGlosary, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_GLOSARY_COURSE, idCourse]);
        }
    });
}


export const useMutateUpdateGlosary = (idCourse) => {
    const queryClient = useQueryClient();
    return useMutation(updateGlosary, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_GLOSARY_COURSE, idCourse]);
        }
    });
}

export const useMutateDeleteGlosary = (idCourse) => {
    const queryClient = useQueryClient();
    return useMutation(deleteGlosary, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_GLOSARY_COURSE, idCourse]);
        }
    });
}


