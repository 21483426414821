import { useMutation, useQuery, useQueryClient } from "react-query";
import { createModule, deleteModuloById, editNameModule, findModuleById, getModulesByCourse, toggleActiveModule } from "../controllers/cursos/modulos/modulos.controller";

const KEY_MODULES = "MODULOS";
const KEY_MODULE = "ONEMODULE"

export const useQueryModulesByCourse = (idCourse) => {
    // console.log('idCourse', idCourse);
	return useQuery(
		[KEY_MODULES, idCourse],
		() => getModulesByCourse(idCourse),
		{
			retry: 2,
			refetchOnWindowFocus: false,
		}
	);
};


export const useQueryFindModuleById = (idModule) => {
    return useQuery(
        [KEY_MODULE, idModule],
        () => findModuleById(idModule),
        {
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}





/* Need the idCourse to invalidate the correct query */
export const useMutateCreateModule = (idCourse) => {
    const queryClient = useQueryClient();
    return useMutation(createModule, {
        onSuccess: (data) => {
            /* queryClient.setQueryData("getAllDocentes", (prev) => {
                return [...prev, data];
            }); */
            queryClient.invalidateQueries([KEY_MODULES, idCourse]);
        }
    });
}


export const useMutateEditModuleName = (idModule) => {
    const queryClient = useQueryClient();
    return useMutation(editNameModule, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_MODULE, idModule]);
        }
    });
}

export const useMutateActiveModule = (idCourse) => {
    const queryClient = useQueryClient();
    return useMutation(toggleActiveModule, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_MODULES, idCourse]);
        }
    });
}


export const useMutateDeleteModuloById = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteModuloById, {
        onSuccess: () => {
            queryClient.invalidateQueries([KEY_MODULES]);
        }
    });
}