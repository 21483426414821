import React, { useEffect, useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import * as FaIcons from "react-icons/fa";
import { SwalToast } from "../../utils/toasts";
import { editarPortadaEscuela, getPortadaEscuela } from "../../controllers/escuelas/escuelas";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import { useSchema } from "../../hooks/custom/useSchema";

const initialValues = {
	titulo: "",
	subtitulo: "",
	url_video: "",
	img_fondo: null,
};

let schemaFileString = {
	titulo: yup.string().required("El titulo es requerido"),
	url_video: yup.string().required("La url del video es requerida"),
	img_fondo: yup
		.string()
        .nullable()
		.test("imageUrl", "URL no contiene 'files'", (value) => {
			return value && value.includes("files");
		}),
};

const EditarPortadaEscuela = () => {
    
	const [fetch, setFetch] = useState(true);
	const [fieldSchema, setFieldSchema] = useState(null);
	const [typeSchema, setTypeSchema] = useState("withFile");
    const [schema, setFieldStringFile, setFieldFile, resetSchema] = useSchema(schemaFileString)
    
	//states
	const [renderImageFondo, setRenderImageFondo] = useState(null);
	const [dataPortada, setDataPortada] = useState(null);
    
	//refs
	const btnImgFondoRef = useRef(null);
    
	//Memo schema
	const validationSchema = useMemo(() => {
		if (typeSchema === "withFile") {
			return setFieldFile(fieldSchema);
		} else {
			return setFieldStringFile(fieldSchema);
		}
	}, [fieldSchema, typeSchema]);
    
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values) => {
			console.log(values);
			const response = await editarPortadaEscuela(
				values,
				dataPortada.id_registro
			);
			if (response) {
				resetSchema();
                setFieldSchema(null);
                setTypeSchema("withString");
				setFetch(true);
				SwalToast.fire({
					title: "Actualizado",
					text: "Se actualizo correctamente",
					icon: "success",
				});
			} else {
				SwalToast.fire({
					title: "Error",
					text: "No se pudo actualizar",
					icon: "error",
				});
			}
		},
	});
    
	const fetchData = async () => {
		const response = await getPortadaEscuela();
		if (response) {
			setDataPortada(response);
			formik.setValues({
				...response,
			});
		}
	};
    
	const onChangeImage = (e) => {
		if (e.target.files.length > 0) {
			const file = e.target.files[0];
			formik.setFieldValue(e.target.name, file);
			setFieldSchema(e.target.name);
			setTypeSchema("withFile");
		} else {
			setFieldSchema(e.target.name);
			setTypeSchema("withString");
		}
	};
    
	useEffect(() => {
		if (formik.values.img_fondo?.name) {
			setRenderImageFondo(URL.createObjectURL(formik.values.img_fondo));
		} else {
			if (
				formik.values.img_fondo &&
				formik.values.img_fondo.includes("files")
			) {
				setRenderImageFondo(
					process.env.REACT_APP_API_URL + formik.values.img_fondo
				);
			}
		}
	}, [formik.values.img_fondo]);
    
	useEffect(() => {
		if (fetch) {
			fetchData();
			setFetch(false);
		}
	}, [fetch]);
    
	useEffect(() => {
		formik.validateForm();
	}, [schema]);
    
	return (
		<LayoutAdmin contentTitle="Editar portada" routeTitle="Editar portada">
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<form
								className="row"
								onSubmit={formik.handleSubmit}
							>
								{/* Img fondo */}
								<div className="col-12 my-2 h-100">
									<label>Imagen de fondo:</label>
									<div className="d-flex justify-content-center align-items-center img-form-preview">
										{formik.values.img_fondo ? (
											formik.values.img_fondo.name ||
											formik.values.img_fondo.includes(
												"files"
											) ? (
												<img
													src={renderImageFondo}
													alt={"..."}
													className="w-100"
													style={{
														objectFit: "fill",
														maxHeight: "400px",
														borderRadius: "10px",
													}}
												/>
											) : (
												<img
													src="/images/gestora-placeholder.png"
													alt={"..."}
													// className="w-100"
													style={{
														objectFit: "fill",
														maxWidth: "100%",
														borderRadius: "10px",
													}}
												/>
											)
										) : (
											<img
												src="/images/gestora-placeholder.png"
												alt={"..."}
												// className="w-100"
												style={{
													objectFit: "fill",
													maxWidth: "100%",
													borderRadius: "10px",
												}}
											/>
										)}
										<div
											title="Seleccionar imagen"
											className="btn-ref-file"
											onClick={() =>
												btnImgFondoRef.current.click()
											}
										>
											<FaIcons.FaFileUpload />
										</div>
									</div>
									<p
										className={
											formik.errors.img_fondo
												? "text-danger"
												: "d-none"
										}
									>
										{" "}
										{formik.errors.img_fondo
											? formik.errors.img_fondo
											: null}{" "}
									</p>
									<div className="d-none form-group mb-3">
										<input
											ref={btnImgFondoRef}
											className="form-control"
											type="file"
											accept="image/*"
											name="img_fondo"
											onChange={onChangeImage}
										/>
									</div>
								</div>
								<div className="col-12 my-2">
									<Form.Group className="my-2">
										<Form.Label>Titulo</Form.Label>
										<Form.Control
											type="text"
											placeholder="Titulo"
											name="titulo"
											value={formik.values.titulo}
											onChange={formik.handleChange}
											isValid={
												formik.touched.titulo &&
												!formik.errors.titulo
											}
											isInvalid={
												formik.touched.titulo &&
												formik.errors.titulo
											}
										/>
										<Form.Control.Feedback type="invalid">
											{formik.errors.titulo}
										</Form.Control.Feedback>
									</Form.Group>
									<Form.Group className="my-2">
										<Form.Label>Subtitulo</Form.Label>
										<Form.Control
											type="text"
											placeholder="Subtitulo"
											name="subtitulo"
											value={formik.values.subtitulo}
											onChange={formik.handleChange}
											isValid={
												formik.touched.subtitulo &&
												!formik.errors.subtitulo
											}
											isInvalid={
												formik.touched.subtitulo &&
												formik.errors.subtitulo
											}
										/>
										<Form.Control.Feedback type="invalid">
											{formik.errors.subtitulo}
										</Form.Control.Feedback>
									</Form.Group>
									<Form.Group className="my-2">
										<Form.Label>URL video</Form.Label>
										<Form.Control
											type="text"
											placeholder="URL video"
											name="url_video"
											value={formik.values.url_video}
											onChange={formik.handleChange}
											isValid={
												formik.touched.url_video &&
												!formik.errors.url_video
											}
											isInvalid={
												formik.touched.url_video &&
												formik.errors.url_video
											}
										/>
										<Form.Control.Feedback type="invalid">
											{formik.errors.url_video}
										</Form.Control.Feedback>
									</Form.Group>
								</div>
								<div className="col-12 d-flex justify-content-center justify-content-md-end">
									<button
										type="submit"
										className="btn ge-btn-primary"
									>
										Guardar
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</LayoutAdmin>
	);
};

export default EditarPortadaEscuela;
