import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { SwalToast } from "../../utils/toasts";
import BarChartDataset from "../Charts/BarChartDataset";
import CustomInputDate from "../Custom/CustomInputDate";
import LoaderSmall from "../Loaders/LoaderSmall";
import dayjs from "dayjs";
import { getDiffDays } from "../../utils/time";
import { parseDataset } from "../../utils/parserDatasets";
import {  getDatasetsUsersPlan } from "../../controllers/metricas/metricas.controller";

const ChartBarUsersPlan = () => {
    
	const actualDay = new Date();
	let daysAgo7 = new Date();
	daysAgo7.setDate(actualDay.getDate() - 6);
    
	const initialState = [daysAgo7, actualDay];
    
	const [type, setType] = useState("day");
	const [numberDays, setNumberDays] = useState(0);
	const [data, setData] = useState(null);
    const [dataParsed, setDataParsed] = useState(null);
	const [loading, setLoading] = useState(false);
	const [dateRange, setDateRange] = useState(initialState);
	const [startDate, endDate] = dateRange;
	const chartRef = useRef(null);
	
	const getData = async () => {
		setLoading(true);
		const data_ = await getDatasetsUsersPlan(startDate, endDate);
		if (data_) {
			setData(data_);
		} else {
			SwalToast.fire({
				icon: "error",
				title: "Error",
				text: "Error al obtener los datos",
			});
			/* Setting the dateRange to null, null. */
            setData(null);
		}
		setLoading(false);
	};
    
    
	useEffect(() => {
		if (startDate && endDate) {
			setNumberDays(getDiffDays(startDate, endDate));
            getData();
		}
	}, [dateRange]);
    
    useEffect(() => {
        if (data) {
            const dataParsed_ = parseDataset(data, type, startDate, endDate);
            console.log('dataParsed_', dataParsed_);
            setDataParsed(dataParsed_);
        }
    }, [data, type]);
    
	return (
		<div className="card">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column flex-sm-row justify-content-between align-items-center">
						<div className="mb-3 mb-md-0">
							<img
								src="/images/file-icon.png"
								className="mr-2"
								style={{ width: "40px" }}
								alt="file-icon"
							/>
							<span className="ge-h3 ge-title">
								Reporte de usuarios con plan
							</span>
						</div>
						<div>
							{/* Range Picker */}
							<span className="txt-bold ge-title">Periodo:</span>
							<ReactDatePicker
								locale={"es"}
								selectsRange={true}
								startDate={startDate}
								endDate={endDate}
								onChange={(update) => {
									setDateRange(update);
								}}
								isClearable={true}
								// className="form-control ge-form my-2"
								placeholderText="Seleccione un rango"
								dateFormat="yyyy-MM-dd"
								maxDate={actualDay}
								customInput={<CustomInputDate />}
							/>
						</div>
					</div>
					{/* Opciones Diario - semanal - mensual */}
					<div className="col-12 mt-3">
						<div className="d-flex flex-column flex-sm-row justify-content-md-start align-items-center">
							<button
								className={`btn ge-btn-primary-alt mr-2 my-2 my-sm-0 ${
									type === "day" ? "active" : ""
								}`}
								onClick={() => setType("day")}
								title="Reporte diario"
								type="button"
								disabled={numberDays < 1 ? true : false}
							>
								Diario
							</button>
							<button
								className={`btn ge-btn-primary-alt mr-2 my-2 my-sm-0 ${
									type === "week" ? "active" : ""
								}`}
								onClick={() => setType("week")}
								title="Reporte semanal"
								type="button"
								disabled={numberDays <= 6 ? true : false}
							>
								Semanal
							</button>
							<button
								className={`btn ge-btn-primary-alt mr-2 my-2 my-sm-0 ${
									type === "month" ? "active" : ""
								}`}
								onClick={() => setType("month")}
								title="Reporte mensual"
								type="button"
								disabled={numberDays <= 29 ? true : false}
							>
								Mensual
							</button>
						</div>
					</div>
					<div className="col-12 mt-4">
						{/* Grafico */}
						{loading ? (
							<LoaderSmall />
						) : data ? (
							<BarChartDataset
								ref={chartRef}
								datos={dataParsed}
								showLegend={false}
								showTitle={false}
							/>
						) : (
							<div className="mt-3 d-flex justify-content-center align-items-center w-100">
								<h5 className="ge-txt-muted-dark">
									No hay información disponible
								</h5>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChartBarUsersPlan;
