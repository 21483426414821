
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addGlosary, getGlosaryByRoute, deleteRutaById } from "../controllers/rutas/rutas";

const KEY_GLOSARY_BY_ROUTE = 'glosary';
const KEY_RUTA = "getRutas";

export const useQueryRouteGlosario = (idRoute) => {
    return useQuery(
        [KEY_GLOSARY_BY_ROUTE, idRoute],
        () => getGlosaryByRoute(idRoute),
        {
            refetchOnWindowFocus: false,
            retry: 2,
        }
    );
}

export const useMutateAddGlosary = (idRoute) => {
    const queryClient = useQueryClient();
    return useMutation(
        addGlosary,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([KEY_GLOSARY_BY_ROUTE, idRoute]);
            }
        }
    )
}

export const useMutateDeleteRutaById = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteRutaById, {
        onSuccess: () => {
            queryClient.invalidateQueries([KEY_RUTA]);
        }
    });
}