import { useMutation, useQuery, useQueryClient } from "react-query";
import { SwalToast } from "../utils/toasts";
import { deleteCupon, getCupones, updateCupon } from "../controllers/cupones/cupones.controller";

const KEY_CUPONES = "cupones";

export const useQueryCupones = () => {
    return useQuery(
        [KEY_CUPONES],
        () => getCupones(),
        {
            refetchOnWindowFocus: false,
            retry: 2,
        }
    )
}

export const useMutatePublishCupon = () => {
    const queryClient = useQueryClient();
    return useMutation(
        updateCupon,
        {
            onSuccess: () => {
                queryClient.invalidateQueries([KEY_CUPONES]);
                SwalToast.fire({
                    icon: "success",
                    title: "Cupón actualizado correctamente",
                });
            },
            onError: () => {
                SwalToast.fire({
                    icon: "error",
                    title: "Error al actualizar la convocatoria",
                });
            }
        }
    )
}


export const useMutateDeleteCupon = () => {
    const queryClient = useQueryClient();
    return useMutation(
        deleteCupon,
        {
            onSuccess: () => {
                queryClient.invalidateQueries([KEY_CUPONES]);
                SwalToast.fire({
                    icon: "success",
                    title: "Cupón eliminado correctamente",
                });
            },
            onError: () => {
                SwalToast.fire({
                    icon: "error",
                    title: "Error al eliminar la el cupon",
                });
            }
        }
    )
}