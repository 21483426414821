import { useFormik } from "formik";
import React from "react";
import { Form, Modal } from "react-bootstrap";
import * as yup from "yup";
import { useMutateCreateGlosary } from "../../../hooks/glosario.hooks";
import { SwalToast } from "../../../utils/toasts";

const ModalCreateGlosary = ({ showModal, setShowModal, idCourse }) => {
    
    const initialValues = {
        title: "",
        description: "",
        idCourse: idCourse,
    }
    
    const {mutate} = useMutateCreateGlosary(idCourse);
    
    const validationSchema = yup.object({
        title: yup.string().min(1, 'Escribe un término').required("El término es requerido"),
        description: yup.string().min(1,'Escribe una descripción').required("La descripción es requerida"),
    });
    
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            mutate(values,{
                onSuccess: () => {
                    SwalToast.fire({
                        icon: 'success',
                        title: 'Término creado con éxito',
                    });
                },
                onError: () => {
                    SwalToast.fire({
                        icon: 'error',
                        title: 'Error al crear el término.',
                    });
                }
            });
            formik.resetForm();
            setShowModal(false);
        }
    })
    
    const onClose = () => {
        formik.resetForm();
        setShowModal(false);
    }
    
    
	return (
		<Modal
			show={showModal}
			onHide={() => onClose()}
			centered
			backdrop="static"
		>
			<Modal.Header className="ge-bg-primary txt-white">
				<Modal.Title className="txt-bold">Crear término</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<Form 
                        className="w-100"
                        onSubmit={formik.handleSubmit}
                    >
						<Form.Group className="col-12 my-2">
							<Form.Label>Término</Form.Label>
							<Form.Control 
                                type="text"
                                placeholder="Término"
                                name="title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                isInvalid={formik.errors.title && formik.touched.title}
                                isValid={!formik.errors.title && formik.touched.title}
                                className="ge-form"
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.title}
                            </Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="col-12 my-2">
							<Form.Label>Descripción</Form.Label>
							<Form.Control
								as="textarea"
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
								rows="3"
								placeholder="Descripción"
                                isInvalid={formik.errors.description && formik.touched.description}
                                isValid={!formik.errors.description && formik.touched.description}
                                className="ge-form"
							/>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.description}
                            </Form.Control.Feedback>
						</Form.Group>
                        <div className="d-flex flex-column flex-md-row justify-content-md-between">
                            <button 
                                className="btn ge-btn-deg-primary mt-3"
                                type="submit"
                            >
                                Crear término
                            </button>
                            <button type="button" className="btn mt-3 btn-secondary" onClick={() => onClose()}>Cancelar</button>
                        </div>
					</Form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalCreateGlosary;
