import React from "react";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import CardHorasReproduccion from "../../components/Metricas/CardHorasReproduccion";
import CardInscripciones from "../../components/Metricas/CardInscripciones";
import CardNewUsers from "../../components/Metricas/CardNewUsers";
import CardUsersPlan from "../../components/Metricas/CardUsersPlan";
import TopCourseContainer from "../../components/Metricas/TopCourseContainer";

const MetricasDashboard = () => {
    
	return (
		<LayoutAdmin contentTitle="" routeTitle="Metricas Dashboard">
            <div
                className="row p-4"
            >
                <div className="col-md-6 my-2">
                    <CardNewUsers />
                </div>
                <div className="col-md-6 my-2">
                    <CardUsersPlan />
                </div>
                <div className="col-md-6 my-2">
                    <CardInscripciones />
                </div>
                <div className="col-md-6 my-2">
                    <CardHorasReproduccion />
                </div>
                <div className="col-12">
                    <TopCourseContainer />
                </div>
            </div>
		</LayoutAdmin>
	);
};

export default MetricasDashboard;
