import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { getPercentageofRebound } from "../../../controllers/marketing/google_analytics/googleAnalytics.controller";
import { SwalToast } from "../../../utils/toasts";
import CustomInputDate from "../../Custom/CustomInputDate";

const CantidadRebote = () => {
    
    const actualDay = new Date();
	let daysAgo7 = new Date();
	daysAgo7.setDate(actualDay.getDate() - 6);
    
	const initialState = [daysAgo7, actualDay];
    
	const [data, setData] = useState(0);
	const [loading, setLoading] = useState(false);
	const [dateRange, setDateRange] = useState(initialState);
	const [startDate, endDate] = dateRange;
	
	const getData = async () => {
		setLoading(true);
		const data_ = await getPercentageofRebound(startDate, endDate);
		if (data_) {
			setData(data_.value);
		} else {
			SwalToast.fire({
				icon: "error",
				title: "Error",
				text: "Error al obtener los datos",
			});
			/* Setting the dateRange to null, null. */
            setData(null);
		}
		setLoading(false);
	};
    
    
	useEffect(() => {
		if (startDate && endDate) {
            getData();
		}
	}, [dateRange]);
    
	return (
		<div className="card">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column align-items-center">
						<h5 className="ge-title txt-bold">
							% de rebote
						</h5>
						<div className="my-2">
							{/* Range Picker */}
							<span className="txt-bold ge-title">Periodo:</span>
							<ReactDatePicker
								locale={"es"}
								selectsRange={true}
								startDate={startDate}
								endDate={endDate}
								onChange={(update) => {
									setDateRange(update);
								}}
								isClearable={true}
								// className="form-control ge-form my-2"
								placeholderText="Seleccione un rango"
								dateFormat="yyyy-MM-dd"
								maxDate={actualDay}
								customInput={<CustomInputDate />}
							/>
						</div>
					</div>
                    <div className="col-12 d-flex flex-column justify-content-center align-items-center mt-4">
                        {
                            loading ? (
                                    <Spinner
                                        animation="border"
                                        variant="primary"
                                        className="mx-auto"
                                    />
                                ) : (
                                    <>
                                        <img
                                            src="/images/porcentaje-de-rebote.png"
                                            alt="Cantidad de rebote"
                                            style={{ maxWidth: "150px" }}
                                        />
                                        <span className="ge-h1 ge-title txt-bold text-center">{data ? Number(data).toFixed(2) : 0}{" "}%</span>
                                    </>
                                )
                        }
                    </div>
				</div>
			</div>
		</div>
	);
};

export default CantidadRebote;
