import React, { useEffect, useState } from "react";
import {
	convertFromHTML,
	convertToRaw,
	ContentState,
	EditorState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.css";
import { uploadImage } from "../../controllers/Editor";


const EditorGeneral = ({ contenido, onChange }) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	
	const getInitialHTML = () => {
		const contentBlock = htmlToDraft(contenido);
		const contentState = ContentState.createFromBlockArray(
			contentBlock.contentBlocks
		);
		const editorState_ = EditorState.createWithContent(contentState);
		return editorState_;
	};


	const onEditorStateChange = (contentState) => {
		setEditorState(contentState);
		onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
	};
	
	useEffect(() => {
		if (contenido) {
			setEditorState(getInitialHTML);
		}
	}, [contenido]);
	
	const uploadCallback = (file, callback) => {
		//console.log(file);
		return new Promise((resolve, reject) => {
			const reader = new window.FileReader();
			//console.log(reader);
			reader.onloadend = async () => {
				const res = await uploadImage(file);
				//setValue("thumbnail", res.data);
				resolve({
					data: { link: process.env.REACT_APP_API_URL + res.url },
				});
			};
			reader.readAsDataURL(file);
		});
	};
	
	const config = {
		image: {
			uploadCallback: uploadCallback,
			previewImage: true,
			inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
			defaultSize: {
				height: "auto",
				width: "100%",
			},
		},
	};

	// const currentStyle = editorState.getCurrentInlineStyle();
  // console.log(currentStyle.get("fontSize"),currentStyle.get("BOLD"),'estilos',currentStyle);

	return (
		<>
			<Editor
				toolbar={config}
				editorState={editorState}
				wrapperClassName="ge-editor demo-wrapper wrapper-custom"
				editorClassName="demo-edito editor-custom ge-editor-input"
				onEditorStateChange={onEditorStateChange}
				placeholder="Escribe aquí..."
			/>
		</>
	);
};

export default EditorGeneral;
