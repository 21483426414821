import { getAuthAxios } from "../../axios/axiosCustom";
import { mapCourseDetailsToEdit, mapCourseModulesToEdit } from "../../mappers/curso.mapper";
import { parseFormData } from "../../utils/parsers";
import dayjs from "dayjs";
import Swal from "sweetalert2";

export const getAllCursos = async () => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get("/curso/getAll");
        if(response.data.status){
            return response.data.data;
        }
        throw new Error('Error al obtener los cursos');
    }catch(e){
        throw new Error('Error del servidor');
    }
}

export const getAllCursosNET = async () => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get("/curso/getCoursesNet");
        if(response.data.status){
            return response.data.data;
        }
        throw new Error('Error al obtener los cursos');
    }catch(e){
        throw new Error('Error del servidor');
    }
}

export const getTotalDuracion = async (idCourse) => {
  const axios = getAuthAxios();
  try{
      const response = await axios.get(`/clases/total_duracion/${idCourse}`);
      if(response.data){
        return response.data;
      }
      throw new Error('Error al obtener los cursos');
  }catch(e){
      throw new Error('Error del servidor');
  }
}

export const getSelectCursos = async () => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get("/curso/select-cursos");
        if(response.data.status){
            return response.data.data;
        }
        throw new Error('Error al obtener los cursos');
    }catch(e){
        throw new Error('Error del servidor');
    }
}

export const getCursosComprados = async (datos) => {
    if(datos.fecha)
     {const axios = getAuthAxios();
    try{
        const response = await axios.get(`/compra/all_cursos_vendidos/${datos.fecha.startDate}/${datos.fecha.endDate}/${datos.producto!==''?datos.producto:null}`);
        if(response.data.status){
            return response.data.data;
        }
        throw new Error('Error al obtener los cursos');
    }catch(e){
        throw new Error('Error del servidor');
    }}
}

export const getOneCourseDetails = async (slugCurso) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/curso/find_curso/${slugCurso}/-1`);
        if(response.data){
            return  mapCourseDetailsToEdit(response.data);
        }
        throw new Error('Error al obtener el curso');
    }catch(e){
        throw new Error('Error del servidor');
    }
}

/* 

{
    "nombre_curso":"",
    "descripcion":"",
    "id_nivel":,
    "video_presentacion":"”
}

*/
export const createCurso = async (curso) => {
    const axios = getAuthAxios();
    const form = new FormData();
    
    Object.keys(curso).forEach(key => {
        if(key === 'fecha_lanzamiento'){
            form.append(key, dayjs(curso[key]).format('YYYY-MM-DD HH:mm:ss'));
        }else{
            form.append(key, curso[key]);
        }
    });
    
    // console.log('fecha_lanzamiento12342');
    try{
        const response = await axios.post("/curso/create_curso", form,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if(response.data.status){
            return response.data;
        }
        console.log(form);
        throw new Error('Error al crear el curso');
    }catch(e){
        throw new Error('Error del servidor');
    }
}


/*   Editar
  /curso/edit_curso/{id_curso} - POST

  Eliminar
  /curso/delete_curso/{id_curso} - DELETE
   
*/

export const editCurso = async ({idCurso, curso}) => {
   // console.log(curso);
    const axios = getAuthAxios();
    const curso_ = {
        ...curso,
        fecha_lanzamiento: dayjs(curso.fecha_lanzamiento).format('YYYY-MM-DD HH:mm:ss')
    }
    
    let cursoFormData;
    if(curso_.imagen !== "" && typeof curso_.imagen !== "string"){
        cursoFormData = parseFormData(curso_);
    }else{
        delete curso_.imagen;
        cursoFormData = curso_;
    }
    try{
       // console.log('intry',cursoFormData);
        const response = await axios.post(`/curso/edit_curso/${idCurso}`, cursoFormData,{
            headers: {
                'Content-Type': cursoFormData.imagen ? 'multipart/form-data' : 'application/json'
            }
        });
        if(response.data.status){
            return response.data;
        }
        throw new Error('Error al editar el curso');
    }catch(e){
        throw new Error('Error del servidor');
    }
}

export const toggleCurso = async ({idCurso, eliminated}) => {
    const axios = getAuthAxios();
    const data = {
        eliminado: eliminated
    }
    try{
        const response = await axios.put(`/curso/delete_curso/${idCurso}`, data);
        if(response.data.status){
            return response.data;
        }
        throw new Error('Error al eliminar el curso');
    }catch(e){
        throw new Error('Error del servidor');
    }
}

export const deleteCourseById = async (id_curso) => {
    const axios = getAuthAxios();
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
  
    await  Swal.fire({
        title: '¿Estás seguro?',
        text: "Una vez eliminado, no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
            const response = await axios.delete(`/curso/delete_curso_admin/${id_curso}`);
              
            if(response.data.status){
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  })
            }
            else{
                Toast.fire({
                    icon: 'warning',
                    title: response.data.message
                  })
            }
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      })
  }