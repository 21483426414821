import React from "react";
import { Line } from "react-chartjs-2";
import {
	Chart as ChartJS,
	LineElement,
	PointElement,
	LinearScale,
	Title,
} from "chart.js";

//Registrar los elementos cada vez que se usen - (a partir de v4.0.0)
ChartJS.register(LineElement, PointElement, LinearScale, Title);

const LineChartDataset = React.forwardRef(
	({ datos, showTitle = true, showLegend = true, showGrid = true }, ref) => {
		//console.log(datos.dataSets)
		const state = {
			labels: datos.labels,
			datasets: datos.dataSets,
		};
		
		return (
			<Line
				ref={ref}
				datasetIdKey={`id-${datos.title}`}
				data={state}
				options={{
					responsive: true,
					plugins: {
						title: {
							display: showTitle,
							text: datos.title,
							fontSize: 20,
						},
						legend: {
							display: showLegend,
							position: "top",
						},
					},
					scales: {
						y: {
							// display: false,
							beginAtZero: true,
							grid: {
								display: showGrid,
							},
							ticks:{
								stepSize: 1,
							}
						},
						x: {
							beginAtZero: true,
							grid: {
								display: showGrid,
							},
						},
					},
				}}
			/>
		);
	}
);

export default LineChartDataset;
