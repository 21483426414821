import { useMutation, useQuery, useQueryClient } from "react-query";
import { createTwitterPost, editTwitterPost, getTwitterPostById, getTwitterPosts, toggleTwitterPostActive } from "../../../controllers/marketing/posts/twitterPosts.controller";


const KEY_TWITTER_POSTS = 'TwitterPosts';

export const useQueryTwitterPosts = () => {
    return useQuery(
        [KEY_TWITTER_POSTS],
        () => getTwitterPosts(), 
        {
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}

export const useQueryOneTwitterPost = (id) => {
    return useQuery(
        [KEY_TWITTER_POSTS, id],
        () => getTwitterPostById(id),
        {
            enabled: (id && id !== "") ? true : false,
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}


export const useMutateCreateTwitterPost = () => {
    const queryClient = useQueryClient();
    return useMutation(
        createTwitterPost,
        {
            onSuccess: () => {
                queryClient.invalidateQueries([KEY_TWITTER_POSTS]);
            }
        }
    );
}

export const useMutateEditTwitterPost = (id) => {
    const queryClient = useQueryClient();
    return useMutation(
        editTwitterPost,
        {
            onSuccess: () => {
                queryClient.invalidateQueries([KEY_TWITTER_POSTS]);
                queryClient.invalidateQueries([KEY_TWITTER_POSTS, id]);
            }
        }
    );
}

export const useMutateToggleTwitterPost = (id) => {
    const queryClient = useQueryClient();
    return useMutation(
        toggleTwitterPostActive,
        {
            onSuccess: () => {
                queryClient.invalidateQueries([KEY_TWITTER_POSTS]);
            }
        }
    );
}