import { getAuthAxios } from "../../axios/axiosCustom";

export const getAllEncuestas = async () => {
    const axios = getAuthAxios();

    try {
        const response = await axios.get('/encuesta_NPS/getAll_encuestas');

        if(response.data.status){
            return response.data.data
        }
    } catch (error) {
        console.log(error)
    }
};

export const getEncuestasById = async (id_curso) => {
    const axios = getAuthAxios();

    try {
        const response = await axios.get(`/encuesta_NPS/getCurso_encuestas/${id_curso}`);
        if(response.data.status){
            return response.data.data
        }
    } catch (error) {
        console.log(error)
    }
}