import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import LoaderSmall from "../Loaders/LoaderSmall";
import { getUsersBySchool } from "../../controllers/metricas/metricas.controller";

const CardInscripciones = () => {
	const [inscritosList, setInscritosList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
    
    
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "#",
				cell: (row, index) => index + 1,
				width: "5%",
			},
			{
				name: "Escuelas",
				selector: (row) => row.nombre_escuela,
				sortable: true,
			},
			{
				name: "Inscritos",
				selector: (row) => row.inscritos,
				sortable: true,
			},
		],
		[]
	);
    
	const fetchInscritos = async () => {
		setIsLoading(true);
		const response = await getUsersBySchool();
		if (response) {
			setInscritosList(response);
		}
		setIsLoading(false);
	};
    
	useEffect(() => {
		fetchInscritos();
	}, []);
    
    
	return (
		<div className="card h-100">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column flex-md-row justify-content-between align-items-center">
						<h5 className="ge-title txt-bold">
							Inscripciones
						</h5>
					</div>
					<div className="col-12 mt-2">
						<DataTable
							className="custom-datatable"
							columns={columns}
							data={inscritosList}
							responsive
							fixedHeader
							fixedHeaderScrollHeight="300px"
							progressPending={isLoading}
							progressComponent={<LoaderSmall />}
							noDataComponent={
								<div className="text-center mt-4">
									<h5 className="ge-title txt-bold">
										No hay información disponible
									</h5>
								</div>
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardInscripciones;
