import React from "react";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import ChartBarNewUsers from "../../components/Metricas/ChartBarNewUsers";
import NewUsersTable from "../../components/Metricas/NewUsersTable";

const RegistroUsuarios = () => {
	return (
		<LayoutAdmin contentTitle="" routeTitle="Registro de Usuarios">
			<div className="row">
                <div className="col-12">
                    <NewUsersTable />
                </div>
				<div className="col-12">
					<ChartBarNewUsers />
				</div>
            </div>
		</LayoutAdmin>
	);
};

export default RegistroUsuarios;
