import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TimeLineTicket from "../../components/Incidencias/TimeLineTicket";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import Loader from "../../components/Loaders/Loader";
import { useMutationArchiveIncident, useMutationCloseIncident, useQueryIncidentInfo } from "../../hooks/incidencias.hooks";
import dayjs from "dayjs";
import * as MdIcons from "react-icons/md";
import * as TiIcons from "react-icons/ti";
import FormAnswerTicket from "../../components/Incidencias/FormAnswerTicket";
import TimeLineContainer from "../../components/Incidencias/TimeLineContainer";
import { Badge } from "react-bootstrap";
import { SwalToast } from "../../utils/toasts";
import Swal from "sweetalert2";

const DetalleIncidenciaScreen = () => {
	
	const { cod_unico } = useParams();
	const navigate = useNavigate();
	
	/* States */
	const [showFormResponse, setShowFormResponse] = useState(false);
	
	/* React Query */
	const {
		data: ticketInfo,
		isLoading,
		isError,
	} = useQueryIncidentInfo(cod_unico);
	
	const { mutate: closeIncident, isLoading: loadingClose } = useMutationCloseIncident(cod_unico);
	
	const { mutate:archiveIncident, isLoading: loadingArchive } = useMutationArchiveIncident(cod_unico);
	
	const handleCloseIncident = () => {
		Swal.fire({
			title: "¿Estás seguro?",
			text: "El cambio del estado se verá reflejado en el sistema",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si, cambiar!",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if(result.isConfirmed){
				closeIncident(
					{
						cod_incident: cod_unico,
					},
					{
						onSuccess: () => {
							SwalToast.fire({
								icon: "success",
								title: "El ticket ha sido cerrado",
							});
						},
						onError: (error) => {
							SwalToast.fire({
								icon: "error",
								title: "Error al cerrar el ticket",
								text: error.message,
							});
						}
					}
				);
			}
		})
	}
	
	const handleArchiveIncident = () => {
		Swal.fire({
			title: "¿Estás seguro?",
			text: "El cambio del estado se verá reflejado en el sistema",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si, cambiar!",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if(result.isConfirmed){
				archiveIncident(
					{
						cod_incident: cod_unico,
					},
					{
						onSuccess: () => {
							SwalToast.fire({
								icon: "success",
								title: "El ticket ha sido archivado",
							});
						}
					}
				);
			}
		})
	}
	
	if (isLoading) {
		return <Loader />;
	}
	
	if (isError) {
		navigate("/incidencias/listar");
	}
	
	return (
		<LayoutAdmin
            contentTitle=""
            routeTitle="Incidencia"
        >
			<div className="row">
				<div className="col-12">
					<div className="card p-2">
						<div className="d-flex flex-column flex-md-row justify-content-between card-header card-no-after ge-noborder ge-bg-white align-items-center">
							<div className="d-flex align-items-center">
								<img
									src="/images/file-icon.png"
									className="mr-2"
									style={{ width: "40px" }}
									alt="file-icon"
								/>
								<span className="ge-h3 mx-2 ge-title">
									Ticket N°:
								</span>
								<span className="ge-h3">
									{cod_unico.toUpperCase()}
								</span>
							</div>
						</div>
						<div className="card-body">
							{/* Conversación Component */}
							<div className="row">
								{/* Datos generales del ticket */}
								<div className="col-12">
									<p className="ge-h4">Datos del ticket:</p>
									<p>
										<span className="txt-bold">Estado del ticket:{" "}</span>
										{
											parseInt(ticketInfo?.reporte.id_estado) === 1 ? (
												<Badge bg="warning">
													ABIERTO
												</Badge>
											) : parseInt(ticketInfo?.reporte.id_estado) === 2
												? (
													<Badge bg="success">
														RESUELTO
													</Badge>
												):(
													<Badge bg="danger">
														ARCHIVADO
													</Badge>
												)
										}
									</p>
									<p>
										<span className="txt-bold">Estudiante:{" "}</span>
										{ticketInfo?.reporte.usuario_ticket}
									</p>
									<p>
										<span className="txt-bold">Asunto:{" "}</span>
										{ticketInfo?.reporte.nombre_asunto}
									</p>
									<p>
										<span className="txt-bold">Fecha del reporte:{" "}</span>
										{dayjs(
											ticketInfo?.reporte.fecha_registro
										).format("DD/MM/YYYY")}
									</p>
									{ticketInfo?.reporte.id_clase && (
										<p>
											<span className="txt-bold">Clase:{" "}</span>
											{ticketInfo?.reporte.titulo_clase}
										</p>
									)}
								</div>
								
								{/* TimeLine de la conversación */}
								<div className="col-12 my-2">
									<TimeLineContainer
										ticketInfo={ticketInfo}
									/>
								</div>
								{
									showFormResponse && (
										<div className="col-12">
											<FormAnswerTicket
												cod_ticket={cod_unico}
											/>
										</div>
									)
								}
								<div className="col-12 my-2 d-flex flex-column flex-md-row justify-content-md-end">
									{
										parseInt(ticketInfo?.reporte.id_estado) === 1 && (
											<>
												<button
													className="btn btn-warning btn-sm mr-md-2 my-2"
													onClick={handleCloseIncident}
												>
													<MdIcons.MdClose size={20} />
													<span className="ml-2">Cerrar ticket</span>
												</button>
												
												<button
													className="btn btn-danger btn-sm mr-md-2 my-2"
													onClick={handleArchiveIncident}
												>
													<TiIcons.TiFolderDelete size={20} />
													<span className="ml-2">Archivar ticket</span>
												</button>
												
												<button
													className="btn ge-btn-primary-alt btn-sm my-2"
													onClick={() => setShowFormResponse(!showFormResponse)}
												>
													<MdIcons.MdQuestionAnswer size={20} />
													<span className="ml-2">
														Responder ticket
													</span>
												</button>
												
											</>
										)
									}
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</LayoutAdmin>
	);
};

export default DetalleIncidenciaScreen;
