import React from "react";
import LayoutAdmin from "../components/layouts/LayoutAdmin";

const AdminScreen = () => {
	return (
		<LayoutAdmin
            routeTitle="Admin"
            contentTitle="Administrador"
        >
            <h1>Solo admin</h1>
        </LayoutAdmin>
	);
};

export default AdminScreen;
