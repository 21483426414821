import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Rating from "react-rating";
import { obtenerIndiceSatisfaccion } from "../../../controllers/dashboard_roles/manager.controller";
import { FaIcons } from "../../../icons/export-reacticons";

const IndiceSatisfaccion = () => {
    
    const [data, setData] = useState({
		promedio: 0,
		porcentaje: 0,
	});
	
	const { promedio, porcentaje } = data;
    
    const getData = async () => {
        const data_ = await obtenerIndiceSatisfaccion();
        if(data_){
            setData(data_);
        }
    }
    
    useEffect(() => {
		getData();
        
        return () => {
            setData({
				promedio: 0,
				porcentaje: 0,
			});
        }
    }, []);
    
	return (
		<div className="card ge-bl-2 h-100">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column align-items-center mb-4">
                        <h5 className="ge-title txt-bold">
                            Índice de satisfacción de clientes
                        </h5>
					</div>
                    <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                        <span className="ge-h1 txt-bold ge-title text-center">{porcentaje} %</span>
						<span className="txt-ge-muted text-center my-2">({promedio}/5 promedio de valoraciones) </span>
						<Rating
							className="rating-stars mb-1"
							initialRating={promedio}
							emptySymbol={
								<FaIcons.FaRegStar
									visibility={"visible"}
									color="#547397"
								/>
							}
							fullSymbol={
								<FaIcons.FaStar
									visibility={"visible"}
									color="#992AFF"
								/>
							}
							fractions={10}
							readonly={true}
						/>
                    </div>
				</div>
			</div>
		</div>
	);
};

export default IndiceSatisfaccion;
