import { combineReducers } from "redux";
import { uiReducer } from "./uiReducer";
import { userReducer } from "./userReducer";
import { docenteReducer } from "./docenteReducer";


export const reducers = combineReducers({
    user: userReducer,
    ui: uiReducer,
    docente: docenteReducer,
});