

export const parseMinToSec = (min) => {
	if (min === 0) {
		return 0;
	}
	return (parseInt(min) * 60);
}


export const parseSecToMin = (sec) => {
	if (sec === 0) {
		return 0;
	}
	return Math.floor(parseInt(sec) / 60);
}

export const parseInteger = (value) => {
	if (!value) {
		return 0;
	}else{
		const intParsed = parseInt(value);
		if (isNaN(intParsed)) {
			return 0;
		}
		return intParsed;
	}
}



export const parseFormData = (data) => {
	const formData = new FormData();
	Object.keys(data).forEach((key) => {
		formData.append(key, data[key]);
	});
	return formData;
}


//convertir minutos a horas y minutos
export const parseMinToHM = (min) => {
	
	const minParsed = parseInteger(min);
	if (minParsed === 0 || isNaN(minParsed)) {
		return "0 minutos";
	}
	if (minParsed < 60) {
		return `${minParsed} minutos`;
	}
	
	const horas = Math.floor(minParsed / 60);
	const minutos = minParsed % 60;
	if (horas === 0) {
		return `${minutos} minutos`;
	}
	return `${horas} horas y ${minutos} minutos`;
}

