import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const PagosDocente = ({ data }) => {
  const [pagoUno, setPagoUno] = useState();
  const [pagoDos, setPagoDos] = useState();
  const [pagoTres, setPagoTres] = useState();
  const [select, setSelect] = useState()

  useEffect(() => {
    if (data) {
      const uno = data.filter((v) => v.id_tipo_pago_docente === "1");
      const dos = data.filter((v) => v.id_tipo_pago_docente === "2");
      const tres = data.filter((v) => v.id_tipo_pago_docente === "3");
      setPagoUno(uno)
      setPagoDos(dos[0]);
      setPagoTres(tres[0]);
    }
  },[data]);

  const handleSelect = (e) => {
    const {value} = e.target;
    setSelect(pagoUno[value]?.monto)
  }

  return (
    <div className="container p-3">
      <div className="d-flex justify-content-center align-items-center col-md-12 p-2">
        <div className="container-pagos-docente col-md-4">
          <div className="d-flex justify-content-start align-items-center px-4">
            <div className="cuadro-pagos-docentes mx-2 mb-2"></div>
            <h5 className="font-weight-bold">Pago Fijo</h5>
          </div>
          <div className="d-flex jusify-content-center align-items-center py-4 pe-4">
            <div className="pago-uno-docente">
                <span>PEN</span>
                <span className="font-weight-bold">{select ? select + ".00" : "00.00"}</span>
            </div>
            <Form.Select className="select-cursos-pagos" onChange={(e) => handleSelect(e)}>
                <option value="">Seleccionar curso</option>
                {pagoUno?.map((v,i) => (
                    <option value={i} key={i}>{v.nombre_curso}</option>
                ))}
            </Form.Select>
          </div>
        </div>
        <div className="container-pagos-docente col-md-4">
          <div className="d-flex justify-content-start align-items-center px-4">
            <div className="cuadro-pagos-docentes mx-2 mb-2"></div>
            <h5 className="font-weight-bold">% de venta directa de cursos</h5>
          </div>
          <div className="porcentaje-pagos py-4">
            <span>%</span>
            <span>{pagoDos?.porcentaje ? pagoDos.porcentaje : "0"}</span>
          </div>
        </div>
        <div className="container-pagos-docente col-md-4">
          <div className="d-flex justify-content-center align-items-center px-4">
            <div className="cuadro-pagos-docentes mx-2 mb-2"></div>
            <h5 className="font-weight-bold">% de ventas de membresías</h5>
          </div>
          <div className="porcentaje-pagos py-4">
            <span>%</span>
            <span>{pagoTres?.porcentaje ? pagoTres.porcentaje : "0"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagosDocente;
