import { useFormik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Alert, Form, Spinner, Tab, Tabs } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import * as yup from "yup";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import InputModalIcons from "../../components/Custom/InputModalIcons";
import { useParams } from "react-router-dom";
import { useMutateEditCurso, useQueryOneCourseDetails } from "../../hooks/cursos.hooks";
import { useQueryDocentesSelect } from "../../hooks/docentes.hooks";
import { SwalToast } from "../../utils/toasts";
import ListaModulosScreen from "./Modulos/ListaModulosScreen";
import ListaGlosarioScreen from "./Glosario/ListaGlosarioScreen";
import Examen from "./Examen/Examen";
import ListaHabilidades from "./Habilidades/ListaHabilidades";
import dayjs from "dayjs";
import ReactDatePicker from "react-datepicker";
import { filterPassedTime } from "../../utils/time";
import CustomInputDate from "../../components/Custom/CustomInputDate";

const validationSchema = yup.object({
	nombre: yup.string().min(2).required("El nombre es requerido"),
	docente: yup.string().required("El docente es requerido."),
	descripcion: yup.string().required("La descripción es requerida."),
	videoPresentacion: yup.string().url("Ingrese una URL válida.").required("El video es requerido."),
	duracion: yup.string(),
	idNivel: yup.string().required("El nivel es requerido."),
	icono: yup.string().required("El icono es requerido."),
	precio: yup.number().required("El precio es requerido."),
	fecha_lanzamiento: yup.date().required("La fecha de lanzamiento es requerida."),
});

const EditarCursoScreen = () => {
	
	const [showModalIcon, setShowModalIcon] = useState(false);
	const [imagePreview, setImagePreview] = useState(null);
	const [key, setKey] = useState("courseDetails");
	const imgInputRef = useRef();
	const params = useParams();
	const idCourse = useRef();
	const { data: dataCourse, isLoading, isSuccess } = useQueryOneCourseDetails(
		params.slugCurso
	);
	
	const {mutate: mutateEditCurso } = useMutateEditCurso();
	
	const {data: docentesSelect} = useQueryDocentesSelect();
	
	const initialValues = {
		nombre: "",
		docente: "",
		descripcion: "",
		imagen: "",
		videoPresentacion: "",
		duracion: "",
		idNivel:"",
		icono: "",
		precio: "",
		fecha_lanzamiento: new Date(),
	}
	
	
	const formik = useFormik({
		initialValues,
		validationSchema,
		// validateOnMount: true,
		onSubmit: (values) => {
			mutateEditCurso({
				idCurso:idCourse.current,
				curso: values
			},{
				onSuccess: (data) => {
				//	console.log(data);
					SwalToast.fire({
						icon: "success",
						title: "Curso editado correctamente",
					});
				},
				onError: (error) => {
					//console.log(error);
					SwalToast.fire({
						icon: "error",
						title: "Error al editar el curso",
					});
				}
			})
		},
		validateOnChange: true,
	});
	
	
	useEffect(() => {
		if(isSuccess) {
			idCourse.current = dataCourse.id;
			const dataFormik = {
				nombre: dataCourse.title ? dataCourse.title : "",
				docente: dataCourse.id_docente ? dataCourse.id_docente : "",
				descripcion: dataCourse.description ? dataCourse.description : "",
				videoPresentacion: dataCourse.urlPresentation ? dataCourse.urlPresentation : "",
				duracion: dataCourse.hours ? dataCourse.hours : "",
				idNivel: dataCourse.level === "Básico" ? "1" : dataCourse.level === "Intermedio" ? "2" : "3",
				icono: dataCourse.iconoCurso ? dataCourse.iconoCurso : "",
				precio: dataCourse.precio ? dataCourse.precio : "",
				imagen: dataCourse.img_curso ? dataCourse.img_curso : "",
				fecha_lanzamiento: dataCourse.fecha_lanzamiento ? dayjs(dataCourse.fecha_lanzamiento).$d : new Date(),
			}
			
			if(dataCourse.img_curso) {
				setImagePreview(`${process.env.REACT_APP_API_URL}/${dataCourse.img_curso}`);
			}
			
			formik.setValues(dataFormik);
		}
	}, [dataCourse, isSuccess]);
	
	//Cambia la imagen de perfil
	const handleChangeImage = (e) => {
		e.preventDefault();
		if (imgInputRef.current.files.length > 0) {
			//validar solo imagenes
			const file = e.target.files[0];
			if (file.type.indexOf("image") < 0) {
				return;
			}
			// console.log(file);
			setImagePreview(URL.createObjectURL(file));
			formik.setFieldValue("imagen", file);
		}
	};
	
	return (
		<LayoutAdmin contentTitle="" routeTitle="Crear Curso">
		{isLoading ? <div className="text-center"> <Spinner animation="border" /></div> : dataCourse ? 	
			<div className="row">
				<div className="col-12">
					{/* Tabs */}
					<Tabs
						id="createCourseTabs"
						activeKey={key}
						onSelect={(k) => setKey(k)}
						className=""
					>
						<Tab eventKey="courseDetails" title="Curso">
							{/* Componente de aspectos generales */}
							<div className="card">
								<div className="card-header ge-noborder">
									<span className="ge-h3 ge-title">
										Aspectos generales
									</span>
								</div>
								<div className="card-body">
									<Form 
										className="row form-add-docente"
										onSubmit={formik.handleSubmit}
									>
										<Form.Group
											className="col-12"
										>
											{/* Img perfil */}
											<div className="d-flex justify-content-center w-100 mb-md-3 img-profile-container">
												<img
													src={
														!imagePreview
															? "https://via.placeholder.com/150?text=Imagen+de+portada"
															: imagePreview
													}
													alt="..."
													className="img-profile"
												/>
												<div className="button-container">
													<input
														type="file"
														ref={imgInputRef}
														onChange={(e) => {
															handleChangeImage(e);
														}}
														className="d-none"
														accept="image/*"
													/>
													<span
														className="img-profile-button text-center"
														onClick={() =>
															imgInputRef.current.click()
														}
													>
														<FaIcons.FaPencilAlt />
													</span>
												</div>
											</div>
											{
												formik.errors.imagen && formik.touched.imagen && (
													<div className="text-center">
														<span className="text-danger">
															{formik.errors.imagen}
														</span>
													</div>
												)
											}
										</Form.Group>
										
										<Form.Group
											controlId="editName"
											className="col-12 col-md-6 my-2"
										>
											<Form.Label>
												Nombre Curso *
											</Form.Label>
											<Form.Control
												className="ge-form"
												type="text"
												placeholder="Nombres"
												name="nombre"
												onChange={formik.handleChange}
												value={formik.values.nombre}
												autoComplete="off"
												isValid={
													formik.touched.nombre &&
													!formik.errors.nombre
												}
												isInvalid={
													formik.touched.nombre &&
													!!formik.errors.nombre
												}
											/>
											<Form.Control.Feedback type="invalid">
												{formik.errors.nombre}
											</Form.Control.Feedback>
										</Form.Group>
										
										<Form.Group
											controlId="editPrice"
											className="col-12 col-md-6 my-2"
										>
											<Form.Label>
												Precio *
											</Form.Label>
											<Form.Control
												className="ge-form"
												type="text"
												placeholder="Precio"
												name="precio"
												onChange={formik.handleChange}
												value={formik.values.precio}
												autoComplete="off"
												isValid={
													formik.touched.precio &&
													!formik.errors.precio
												}
												isInvalid={
													formik.touched.precio &&
													!!formik.errors.precio
												}
											/>
											<Form.Control.Feedback type="invalid">
												{formik.errors.precio}
											</Form.Control.Feedback>
										</Form.Group>
										
										<Form.Group
											controlId="formVideoUrl"
											className="col-12 col-md-6 my-2"
										>
											<Form.Label>
												Video Presentación *
											</Form.Label>
											<Form.Control
												className="ge-form"
												type="text"
												placeholder="Eg: youtube.com/etc"
												name="videoPresentacion"
												onChange={formik.handleChange}
												value={
													formik.values
														.videoPresentacion
												}
												autoComplete="off"
												isValid={
													formik.touched
														.videoPresentacion &&
													!formik.errors
														.videoPresentacion
												}
												isInvalid={
													formik.touched
														.videoPresentacion &&
													formik.errors
														.videoPresentacion
												}
											/>
											<Form.Control.Feedback type="invalid">
												{
													formik.errors
														.videoPresentacion
												}
											</Form.Control.Feedback>
										</Form.Group>
										
										<Form.Group
											controlId="formBasicNivel"
											className="col-12 col-md-6 my-2"
										>
											<Form.Label>Nivel *</Form.Label>
											<Form.Select
												className="form-control ge-form"
												name="idNivel"
												onChange={formik.handleChange}
												value={formik.values.idNivel}
												isValid={
													formik.touched.idNivel &&
													!formik.errors.idNivel
												}
												isInvalid={
													formik.touched.idNivel &&
													!!formik.errors.idNivel
												}
											>
												<option value="">
													Seleccione un nivel
												</option>
												<option value="1">
													Básico
												</option>
												<option value="2">
													Intermedio
												</option>
												<option value="3">
													Avanzado
												</option>
											</Form.Select>
											<Form.Control.Feedback type="invalid">
												{formik.errors.idNivel}
											</Form.Control.Feedback>
										</Form.Group>
										
										<Form.Group
											controlId="formBasicDuracion"
											className="col-12 col-md-6 my-2"
										>
											<Form.Label>
												Duración del curso
											</Form.Label>
											<Form.Control
												className="ge-form"
												type="text"
												placeholder="Eg: 60"
												name="duracion"
												onChange={formik.handleChange}
												value={formik.values.duracion}
												autoComplete="off"
												isValid={
													formik.touched.duracion &&
													!formik.errors.duracion
												}
												isInvalid={
													formik.touched.duracion &&
													!!formik.errors.duracion
												}
											/>
											<Form.Control.Feedback type="invalid">
												{formik.errors.duracion}
											</Form.Control.Feedback>
										</Form.Group>
										
										<Form.Group
											controlId="formBasicIcon"
											className="col-12 col-md-6 my-2"
										>
											<Form.Label>Icono *</Form.Label>
											<InputModalIcons
												show={showModalIcon}
												setShow={setShowModalIcon}
												icon={formik.values.icono}
												setIcon={formik.setFieldValue}
											/>
											<Form.Control.Feedback type="invalid">
												{formik.errors.icono}
											</Form.Control.Feedback>
										</Form.Group>
										
										<Form.Group
											controlId="formBasicDocente"
											className="col-12 col-md-6 my-2"
										>
											<Form.Label>Docente *</Form.Label>
											<Form.Select
												className="form-control ge-form"
												name="docente"
												onChange={formik.handleChange}
												value={formik.values.docente}
												isValid={
													formik.touched.docente &&
													!formik.errors.docente
												}
												isInvalid={
													formik.touched.docente &&
													!!formik.errors.docente
												}
											>
												<option value="">
													Seleccione un docente
												</option>
												{
													docentesSelect &&(
														docentesSelect.map(docente => (
															<option
																value={docente.id}
																key={docente.id}
															>
																{`${docente.nombres} ${docente.apellidos}`}
															</option>
														))
													)
												}
											</Form.Select>
											<Form.Control.Feedback type="invalid">
												{formik.errors.docente}
											</Form.Control.Feedback>
										</Form.Group>
										
										<Form.Group
											className="col-12 col-md-6 my-2"
										>
											<Form.Label>Fecha de lanzamiento:</Form.Label>
											<ReactDatePicker
												locale={"es"}
												selected={formik.values.fecha_lanzamiento}
												onChange={(date) =>{
													formik.setFieldValue('fecha_lanzamiento', date)
												}}
												isClearable={true}
												dateFormat="dd/MM/yyyy h:mm aa"
												showTimeInput
												timeInputLabel="Hora"
												filterTime={filterPassedTime}
												minDate={new Date()}
												customInput={<CustomInputDate />}
												name="fecha_lanzamiento"
											/>
											{
												formik.touched.fecha_lanzamiento && formik.errors.fecha_lanzamiento && (
													<div className="text-center">
														<span className="text-danger">
															{formik.errors.fecha_lanzamiento}
														</span>
													</div>
												)
											}
										</Form.Group>
										
										<Form.Group
											controlId="formBasicDescription"
											className="col-12 my-2"
										>
											<Form.Label>Descripción</Form.Label>
											<Form.Control
												className="ge-form"
												as="textarea"
												rows="3"
												placeholder="Descripción"
												name="descripcion"
												onChange={formik.handleChange}
												value={
													formik.values.descripcion
												}
												isValid={
													formik.touched
														.descripcion &&
													!formik.errors.descripcion
												}
											/>
											<Form.Control.Feedback type="invalid">
												{formik.errors.descripcion}
											</Form.Control.Feedback>
										</Form.Group>
										<button
											type="submit"
											className="btn ge-btn-primary my-2"
											// disabled={!formik.isValid}
										>
											Guardar
										</button>
									</Form>
								</div>
							</div>
						</Tab>
						<Tab 
							eventKey="classesModule" 
							title="Módulos"
							disabled={!idCourse.current}
						>
							{/* Componente de modulos CRUD */}
							{
								idCourse.current && (
									<ListaModulosScreen
										idCourse={idCourse.current}
                    slugCurso={params.slugCurso}
									/>
								)
							}
						</Tab>
						<Tab
							eventKey="glosaryCourse"
							title="Glosario"
							disabled={!idCourse.current}
						>
							{/* Componente de glosario CRUD */}
							{
								idCourse.current && (
									<ListaGlosarioScreen 
										idCourse={idCourse.current}
									/>
								)
							}
						</Tab>
						<Tab
							eventKey="examCourse"
							title="Exámen"
							disabled={!idCourse.current}
						>
							{/* Componente de glosario CRUD */}
							{
								idCourse.current && (
								<Examen id_curso={idCourse.current} />
								)
							}
						</Tab>
						<Tab
							eventKey="habilidades"
							title="Habilidades"
							disabled={!idCourse.current}
						>
							{/* Componente de habilidades */}
							{
								idCourse.current && (
									<ListaHabilidades 
										idCourse={idCourse.current}
									/>
								)
							}
						</Tab>
					</Tabs>
				</div>
			</div>:  <Alert key={'info'} variant={'info'}>
       <FaIcons.FaExclamationTriangle />  No existe informacion relacionada....
    </Alert>}
		</LayoutAdmin>
	);
};

export default EditarCursoScreen;
