import { useMutation, useQuery, useQueryClient } from "react-query";
import { createGoogleAdsCampaign, editGoogleAdsCampaign, getGoogleAdsCampaigndetails, getGoogleAdsCampaigns, toggleActiveGoogleAdsCampaign } from "../controllers/marketing/google_ads/googleAds.controller";

const GGADS_CAMPAIGNS_KEY = "GOOGLEADS_CAMPAIGNS";

export const useQueryGoogleAdsCampaigns = () => {
    return useQuery(
        [GGADS_CAMPAIGNS_KEY],
        () => getGoogleAdsCampaigns(),
        {
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}

export const useQueryOneGoogleAdsCampaign = (id) => {
    return useQuery(
        [GGADS_CAMPAIGNS_KEY, id],
        () => getGoogleAdsCampaigndetails(id),
        {
            enabled: id !== null,
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}


export const useMutateCreateGoogleAdsCampaign = () => {
    const queryClient = useQueryClient();
    return useMutation(
        createGoogleAdsCampaign,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([GGADS_CAMPAIGNS_KEY]);
            }
        }
    );
}

export const useMutateUpdateGoogleAdsCampaign = (id) => {
    const queryClient = useQueryClient();
    return useMutation(
        editGoogleAdsCampaign,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([GGADS_CAMPAIGNS_KEY]);
                queryClient.invalidateQueries([GGADS_CAMPAIGNS_KEY, id]);
            }
        }
    );
}

export const useMutateToggleGoogleAdsCampaign = () => {
    const queryClient = useQueryClient();
    return useMutation(
        toggleActiveGoogleAdsCampaign,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([GGADS_CAMPAIGNS_KEY]);
            }
        }
    );
}