
import dayjs from "dayjs";
import { getAuthAxios } from "../../axios/axiosCustom";


export const getNewUsersByRange = async (startDate, endDate) => {
    const startDate_ = dayjs(startDate).format("YYYY-MM-DD");
    const endDate_ = dayjs(endDate).format("YYYY-MM-DD");
    const axios = getAuthAxios();
    
    try{
        /* const data = {
            fecha_inicio: startDate_,
            fecha_fin: endDate_
        } */
        const response = await axios.get(`/metricas/usuarios_registrados_dia/${startDate_}/${endDate_}`);
        // const response = await axios.get('/metricas/usuarios_registrados_dia/',data);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(e){
        console.log(e);
        return null;
    }
}

export const getBoughtPlans = async (startDate, endDate) => {
    const startDate_ = dayjs(startDate).format("YYYY-MM-DD");
    const endDate_ = dayjs(endDate).format("YYYY-MM-DD");
    const axios = getAuthAxios();
    
    try{
        /* const data = {
            fecha_inicio: startDate_,
            fecha_fin: endDate_
        } */
        const response = await axios.get(`/metricas/planes_comprados_dia/${startDate_}/${endDate_}`);
        // const response = await axios.get('/metricas/usuarios_registrados_dia/',data);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(e){
        console.log(e);
        return null;
    }
}


export const getUsersBySchool = async () => {
    const axios = getAuthAxios();
    
    try{
        const response = await axios.get(`/metricas/incritos_escuela`);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(e){
        console.log(e);
        return null;
    }
}


export const getPlayHours = async (startDate, endDate) => {
    const startDate_ = dayjs(startDate).format("YYYY-MM-DD");
    const endDate_ = dayjs(endDate).format("YYYY-MM-DD");
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/metricas/horas_reproduccion/${startDate_}/${endDate_}`);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(e){
        console.log(e);
        return null;
    }
}


export const getTop10Courses = async () => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/metricas/reviews_mejores_cursos`);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(e){
        console.log(e);
        return null;
    }
}


export const getNewStudents = async (startDate, endDate) => {
    const startDate_ = dayjs(startDate).format("YYYY-MM-DD");
    const endDate_ = dayjs(endDate).format("YYYY-MM-DD");
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/metricas/traer_usuarios_registrados/${startDate_}/${endDate_}`);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(e){
        console.log(e);
        return null;
    }
}



export const getDatasetsNewStudents = async (startDate, endDate) => {
    const startDate_ = dayjs(startDate).format("YYYY-MM-DD");
    const endDate_ = dayjs(endDate).format("YYYY-MM-DD");
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/metricas/datasets_nuevos_registros/${startDate_}/${endDate_}`);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(e){
        console.log(e);
        return null;
    }
}

export const getDatasetsUsersPlan = async (startDate, endDate) => {
    const startDate_ = dayjs(startDate).format("YYYY-MM-DD");
    const endDate_ = dayjs(endDate).format("YYYY-MM-DD");
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/metricas/datasets_usuarios_plan/${startDate_}/${endDate_}`);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(e){
        console.log(e);
        return null;
    }
}

export const getBoughtPlansTable = async (startDate, endDate) => {
    const startDate_ = dayjs(startDate).format("YYYY-MM-DD");
    const endDate_ = dayjs(endDate).format("YYYY-MM-DD");
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/metricas/planes_comprados_dia_tabla/${startDate_}/${endDate_}`);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(e){
        console.log(e);
        return null;
    }
}