import React, { useEffect, useMemo, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import HeaderSearchTable from '../../components/Custom/HeaderSearchTable';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import * as BsIcons from 'react-icons/bs'
import DataTable from "react-data-table-component";
import LoaderSmall from '../../components/Loaders/LoaderSmall';
import { disableSuscripcion, getSuscripciones } from '../../controllers/Reportes/suscripciones';
import { getPlanes } from '../../controllers/planes/planes';
import CustomSelectPlanes from '../../components/Custom/CustomSelectPlanes';
import RangePicker from '../../components/DatePickers/RangePicker';
import dayjs from 'dayjs';
const ListarSuscripciones = () => {
    const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);

	const [dataConsulta,setDataconsulta]=useState({
		fecha:null,
		producto:'',
	})

	const{data:dataPlanes, error :errorCursos , isLoading:isLoadingCursos } =  useQuery(['planes'],getPlanes,
		{
			refetchOnWindowFocus:false
		}
	)
	const {data : suscripcionesList,error,isLoading} = useQuery(['suscripciones',dataConsulta],()=>getSuscripciones(dataConsulta),{
		refetchOnWindowFocus:false,
		retry:0,
	})

	const queryClient = useQueryClient()

	const {mutate, error : errorUpdate , isLoading : isLoadingUpdate } = useMutation(disableSuscripcion,{
		onSuccess: (data)=>{
			if(data)
			queryClient.invalidateQueries(["suscripciones"])
		}
	})
    	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={suscripcionesList}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
                columnsToFilter={["producto","fecha_compra","nombres","apellidos","correo"]}
				placeholder="Buscar por Plan, Fecha compra, Nombres, Apellidos, Correo"
			/>
		);
	}, [filterText, resetPagination]);


	const handleDisableSuscripcion = (id,eliminado)=>{
		mutate({id:id,eliminado:eliminado})
	}

	const handleChangeFecha = (date)=>{
		const fechaFormat={
			startDate:dayjs(date[0]).format('YYYY-MM-DD'),
			endDate:dayjs(date[1]).format('YYYY-MM-DD')
		}
		setDataconsulta({
			...dataConsulta,
			fecha:fechaFormat
		})
	}

	const handleChangeProducto = (value)=>{
		setDataconsulta({
			...dataConsulta,
			producto:value.id
		})
	}

	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: '#',
				cell: (row)=> row.id_suscripcion,
				width: '5%',
			},
            {
				name: "Fecha compra",
				selector: (row) => row.fecha_compra,
				sortable: true,
				wrap:true
			},
			{
				name: "Nombres",
				selector: (row) => row.nombres,
				sortable: true,
				wrap:true
			},
			{
				name: "Apellidos",
				selector: (row) => row.apellidos,
				sortable: true,
				wrap:true
			},
			{
				name: "Correo",
				selector: (row) => row.correo,
				sortable: true,
				wrap:true
			},
			{
				name: "Id. Culqi",
				selector: (row) => <div style={{textAlign:'left'}}>{row.id_culqui}</div>,
				sortable: true,
                wrap:true
			},
			{
				name: "Producto",
				selector: (row) => <div style={{textAlign:'left'}}>{row.producto}</div>,
				sortable: true,
                wrap:true
			},
			{
				name: "Status",
				cell: (row) =>
					row.status === "1" ? (
						<Badge className="ge-deg-primary p-1">Activo</Badge>
					) : (
						<Badge bg="danger">Cancelado</Badge>
					),
				sortable: true,
			},
			{
				name: "Acciones",
				cell: (row) => (
					<div className="d-flex my-2">
						{
								row.status === "1" ? (
									<button 
										className="btn btn-light btn-sm mr-2"
										onClick={() => handleDisableSuscripcion(row.id_suscripcion, "0")}
										title="Desactivar suscripción - Culqi"
									>
										<BsIcons.BsToggleOn color="#37af48" size={20} />
									</button>
								):null
						}				
					</div>
				),
			},
		],
		[]
	);


    return (
        <LayoutAdmin
        contentTitle="" routeTitle="Lista de suscripciones"
        >
		{/* 	<CustomModal title={'Editar Plan'} show={showEdit} handleShow={setShowEdit} >
				<EditarPlan idPlan={currentId} handleShow ={setShowEdit} handleCurrentId={setCurrentId} />
			</CustomModal> */}
           <div className='row'>
                <div className='col-12'>
                    <div className="card p-2">
						<div className="card-header ge-noborder">
							<div className="d-flex justify-content-between">
								<div>
									<img
										src="/images/file-icon.png"
										className="mr-2"
										style={{ width: "40px" }}
										alt="file-icon"
									/>
									<span className="ge-h3 ge-title">
										Lista de Suscripciones
									</span>
								</div>
								<div className="">
							
								</div>
							</div>
						</div>
                        <div className='card-body'>
						<div className="d-flex">
										<div style={{marginRight:'10px'}}>
										<label style={{color:'#842eff'}}>Rango de Fecha</label>
										<RangePicker handleChange={handleChangeFecha} />
										</div>
										<CustomSelectPlanes
                                        className=''
                                        onChange={handleChangeProducto}
                                        value={dataConsulta.producto}
                                        options={dataPlanes}
                                        />
								</div>
                        		<DataTable
								className="custom-datatable"
								columns={columns}
								data={
									filteredItems.length > 0
										? filteredItems
										: suscripcionesList
								}
								subHeader
								subHeaderComponent={searchComponentMemo}
								pagination
								responsive
								fixedHeader
								fixedHeaderScrollHeight="300px"
								progressPending={isLoading}
								progressComponent={<LoaderSmall />}
							/>
                        </div>
                    </div>
                </div>
           </div>
        </LayoutAdmin>
    );
};

export default ListarSuscripciones;