import React, { useState, useEffect, useMemo } from "react";
import { Badge, Modal, Tab, Tabs } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import DataTable from "react-data-table-component";
import HeaderSearchTable from "../Custom/HeaderSearchTable";
import * as BsIcons from "react-icons/bs";
import LoaderSmall from "../Loaders/LoaderSmall";
import { useQueryAceptDeclineComisiones, useQueryGetAfiliadosById } from "../../hooks/afiliados.hooks";
const ModalDetailAfiliados = ({ show, setShow, idUser }) => {
  const [info, setInfo] = useState([]);
  const [comisiones, setComisiones] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filteredItems, setFilteredItems] = useState();
  const [resetPagination, setResetPagination] = useState(false);


  const { data, isLoading } = useQueryGetAfiliadosById(idUser ? idUser : 'null');
  const {mutate} = useQueryAceptDeclineComisiones()
  /*const loadDetailAfiliado = async (idUser) => {
    setIsLoading(true)
    const response = await getAfiliadoById(idUser);
    if (response) {
      setInfo(response.data[0]);
      setComisiones(response.comisiones)
      setIsLoading(false)
    } else {
      setInfo([]);
    }
  };*/

  
  const handleAceptDeclineComision = (comision, id_user, estado) => {
    mutate({comision,id_user, data: {"aprobar_comision": estado}})
  }
  useEffect(() => {
    if (data) {
      setInfo(data.data[0]);
      setComisiones(data.comisiones)
    } else {
      setInfo([]);
    }
  }, [data]);

  /* Search Component */
  const searchComponentMemo = useMemo(() => {
    return (
      <HeaderSearchTable
        filterText={filterText}
        setFilterText={setFilterText}
        arrayData={comisiones}
        setArrayFiltered={setFilteredItems}
        setResetPagination={setResetPagination}
        columnsToFilter={[
          "Producto",
        ]}
        placeholder="Búsqueda en registros"
      />
    );
  }, [filterText, resetPagination]);

/* Config Table */
const columns = useMemo(
() => [
{
  name: "#",
  cell: (row, index) => index + 1,
  width: "5%",
},
{
  name: "Producto",
  selector: (row) => row.nombre_producto,
  sortable: true,
  wrap:true
},
{
  name: "Comprador",
  selector: (row) => row.nombre_comprador,
  sortable: true,
},
{
  name: "Comision",
  selector: (row) => <span>S/. {row.comision} </span>,
  sortable: true,
},
{
  name: "Estado",
  selector: (row) =>
    row.estado === "1" ? <Badge bg="success">Aprobado</Badge> : row.estado === "0"? <Badge bg="warning">Pendiente</Badge>: <Badge bg="danger">Rechazado</Badge>,
},
{
  name: "Acciones",
  cell: (row) => (
    <div className="d-flex">
      {row.estado === "0" ? ( 
      <div className="d-flex">
      <button
          className="btn btn-light btn-sm mr-2"
          style={{background: "transparent"}}
          onClick={() => handleAceptDeclineComision(row.id_registro, row.id_user, 1)}
        >
          <BsIcons.BsFillCheckCircleFill color="#37af48" size={20} />
        </button>
        <button
          className="btn btn-light btn-sm mr-2"
          style={{background: "transparent"}}
          onClick={() => handleAceptDeclineComision(row.id_registro, row.id_user, 2)}
        >
          <BsIcons.BsFillXCircleFill color="#ff0000" size={20} />
        </button>
      </div>): '-'}
    </div>
  ),
},
],
[]
);

  return (
    <>
      <Modal
        size="lg"
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton className="ge-bg-primary txt-white">
          <Modal.Title id="contained-modal-title-vcenter">
            Detalle del afiliado
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Tabs
          defaultActiveKey="detail"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="detail" title="Detalle">
              {isLoading ? <LoaderSmall /> :  <div className="card d-flex flex-column justify-content-center align-items-center p-1 col-md-12">
              <div className="card-body col-md-12">
                <div className="container-datos col-md-12">
                  <div className="container-datos-afiliados col-md-6">
                    <span>Nombres: </span>
                    <div className="box-datos-afiliados">{info ? info.nombre : ""}</div>
                  </div>
                  <div className="container-datos-afiliados col-md-6">
                    <span>Apellidos: </span>
                    <div className="box-datos-afiliados">{info? info.apellido : ""}</div>
                  </div>
                </div>
                <div className="container-datos col-md-12 mt-4">
                  <div className="container-datos-afiliados col-md-7">
                    <span>Número de cuenta: </span>
                    <div className="box-datos-afiliados">
                      {info ? info.cuenta : ""}
                    </div>
                  </div>
                  <div className="container-datos-afiliados col-md-5">
                    <span>CCI: </span>
                    <div className="box-datos-afiliados">{info ? info.CCI : ""}</div>
                  </div>
                </div>
                <div className="container-datos col-md-12 mt-4">
                  <div className="container-datos-afiliados col-md-4">
                    <div className="box-redes-sociales">
                      <FaIcons.FaInstagram />
                    </div>
                    <div className="box-datos-afiliados">{info ? info.instagram : ""}</div>
                  </div>
                  <div className="container-datos-afiliados col-md-4">
                    <div className="box-redes-sociales">
                      <FaIcons.FaFacebookF />
                    </div>
                    <div className="box-datos-afiliados">{info ? info.facebook : ""}</div>
                  </div>
                  <div className="container-datos-afiliados col-md-4">
                    <span>Teléfono: </span>
                    <div className="box-datos-afiliados">{info ? info.telefono : ""}</div>
                  </div>
                </div>
                <div className="container-datos col-md-12 mt-4">
                  <div className="container-datos-afiliados col-md-4">
                    <div className="box-redes-sociales">
                      <FaIcons.FaTiktok />
                    </div>
                    <div className="box-datos-afiliados">{info ? info.tiktok : ""}</div>
                  </div>
                  <div className="container-datos-afiliados col-md-4">
                    <div className="box-redes-sociales">
                      <FaIcons.FaYoutube />
                    </div>
                    <div className="box-datos-afiliados">{info ? info.youtube : ""}</div>
                  </div>
                </div>
                <div className="d-flex flex-column col-md-12 mt-4">
                  <span>¿Por qué se quiere unir al programa de afiliados?</span>
                  <textarea
                    className="text-area-afiliados mt-3"
                    value={info ? info.comentario : ""}
                    maxLength={350}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>}
          </Tab>
          <Tab eventKey="comision" title="Comisiones">
          <div className="card p-4">
            <div className="card-body">
              <DataTable
                columns={columns}
                data={filteredItems?.length > 0 ? filteredItems : comisiones}
                subHeader
                subHeaderComponent={searchComponentMemo}
                pagination
                responsive
                fixedHeader
                fixedHeaderScrollHeight="300px"
                progressPending={isLoading}
                progressComponent={<LoaderSmall />}
              />
            </div>
          </div>
          </Tab>
        </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalDetailAfiliados;
