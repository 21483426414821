import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { tasaCancelacion } from "../../../controllers/dashboard_roles/manager.controller";
import CustomInputDate from "../../Custom/CustomInputDate";

const TasaCancelacion = () => {
    
    const [startDate, setStartDate] = useState(new Date());
    const [data, setData] = useState({
		tasa_cancelacion: 0,
		total_suscripciones: 0,
		total_cancelaciones: 0,
	});
	
	const { tasa_cancelacion, total_suscripciones, total_cancelaciones } = data;
	
    const getData = async () => {
        const data_ = await tasaCancelacion(startDate);
        if(data_){
            setData(data_);
        }
    }
    
    useEffect(() => {
        if(startDate){
            getData();
        }
        
        return () => {
            setData({
				tasa_cancelacion: 0,
				total_suscripciones: 0,
				total_cancelaciones: 0,
			});
        }
    }, [startDate]);
	
	return (
		<div className="card ge-bl-2 h-100">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column align-items-center mb-4">
                        <h5 className="ge-title txt-bold">
                            Tasa de cancelación de clientes
                        </h5>
						<div className="my-2">
							{/* Range Picker */}
							<span className="txt-bold ge-title">Mes:</span>
							<ReactDatePicker
								locale={"es"}
								selected={startDate}
								onChange={(date) => setStartDate(date)}
								isClearable={true}
								showMonthYearPicker
								placeholderText="Seleccione un mes"
								dateFormat="MM/yyyy"
								maxDate={new Date()}
								customInput={<CustomInputDate />}
							/>
						</div>
					</div>
                    <div className="col-12 d-flex flex-column justify-content-center">
                        <span className="ge-h1 txt-bold ge-title text-center my-2">{tasa_cancelacion} %</span>
						<div className="d-flex align-items-center justify-content-center my-2">
							<span className="txt-ge-muted">Total suscripciones: {total_suscripciones}</span>
							<span className="txt-ge-muted mx-2">|</span>
							<span className="txt-ge-muted">Total cancelaciones: {total_cancelaciones}</span>
						</div>
                    </div>
				</div>
			</div>
		</div>
	);
};

export default TasaCancelacion;
