import React, {useState} from 'react';
import { useQuery } from "react-query";
import { Spinner } from "react-bootstrap";
import dayjs from 'dayjs';
import RangePicker from '../../components/DatePickers/RangePicker';
import VistaDeHistorias from './VistaDeHistorias';
import { getHistoriasByParams } from '../../controllers/historias/historias';
const ResumenHistorias = () => {

	const [dataConsulta,setDataconsulta]=useState({
		fecha:null,
	})

    const {data : historiasList,error,isLoading} = useQuery(['historiasView',dataConsulta],()=>getHistoriasByParams(dataConsulta),{
		refetchOnWindowFocus:false,
		retry:0,
	})

    const handleChangeFecha = (date)=>{
		const fechaFormat={
			startDate:dayjs(date[0]).format('YYYY-MM-DD'),
			endDate:dayjs(date[1]).format('YYYY-MM-DD')
		}
		setDataconsulta({
			...dataConsulta,
			fecha:fechaFormat
		})
	}

    return (
        <div className='row'>
            <div className='col-12'>
                <div className='card'>
                    <div className="d-flex justify-content-between p-2">
                        <div className='d-flex align-items-center p-2'>
                            <img src='/images/folder.png' alt='...' style={{objectFit:'cover', width:'50px', height:'50px', marginRight:'10px'}} />
                            <h2>Historias</h2>
                        </div>
                        <div className='d-flex justify-content-start p-3'>
                            <div style={{marginRight:'10px'}}>
                                <label style={{color:'#842eff'}}>Rango de Fecha</label>
                                <RangePicker handleChange={handleChangeFecha}/>
                            </div>
                        </div>
                    </div>
                    <div className='card-body'>
                    { isLoading ? <div style={{textAlign:'center',color:'#842eff'}}> <Spinner animation="border" />  </div> : historiasList ?  <VistaDeHistorias historiasList={historiasList} isLoading={isLoading} /> : <p>No es posible obtener datos</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResumenHistorias;