import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { customResponsive } from "./breakpoints";
import CarouselButtons from "./CarouselButtons/CarouselButtons";
import CarouselButtonsAlt from "./CarouselButtons/CarouselButtonsAlt";


/* DEFAULT PROPS
    withButtons : Render custom button group with carousel (default: true)
    rest: props for react-multi-carousel
    itemsBySlide : {
        superLargeDesktop: number of items per slide (Default 4),
        desktop: number of items per slide (Default 3),
        tablet: number of items per slide (Default 2),
        mobile: number of items per slide (Default 2),
    }
    
    setRef : function to set ref to carousel (setState hook)
*/

const defaultCarouselProps = {
    swipeable: true,
    draggable: false,
    showDots: false,
    renderDotsOutside: true,
    arrows: false,
    ssr: false,
    infinite: true,
    autoPlay: true,
    autoPlaySpeed: 3000,
    keyBoardControl: true,
    transitionDuration: 10,
    containerClass: "carousel-container",
    removeArrowOnDeviceType: ["tablet", "mobile", "desktop"],
    dotListClass: "dot-list-style",
    itemClass: "p-2 p-lg-2"
}

const CarouselCustom = ({children, withButtons = false, altButtons = false, setRef = null, itemsBySlide, ...rest}) => {
    
    const setRefState = setRef ? setRef : () => {};
    
    const buttonGroup = withButtons ? {
        customButtonGroup: <CarouselButtons />,
        renderButtonGroupOutside: true
    }: altButtons ? {
        customButtonGroup: <CarouselButtonsAlt />,
        renderButtonGroupOutside: true
    }: {};
    
    
    const responsive = customResponsive(itemsBySlide?.superLargeDesktop, itemsBySlide?.desktop, itemsBySlide?.tablet, itemsBySlide?.mobile);
    
    const props = {
        ...buttonGroup,
        ...defaultCarouselProps,
        responsive,
        ...rest,
    }
    
	return (
		<Carousel
            ref={(el) => setRefState(el)}
            {...props}
		>
            {children}
        </Carousel>
	);
};

export default CarouselCustom;
