import React from 'react';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import GraficoCursos from './GraficoCursos';
import GraficoSuscripciones from './GraficoSuscripciones';


const Dashboard = () => {


    return (
        <LayoutAdmin
        contentTitle="Dashboard" routeTitle="Suscripciones y cursos"
        >
            <GraficoSuscripciones />
            <GraficoCursos />
        </LayoutAdmin>
    );
};

export default Dashboard;