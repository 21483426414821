import React, { useEffect, useMemo, useState } from "react";
import { FilePond } from "react-filepond";
import DarkModal from "../Custom/DarkModal";
import { Form, Spinner } from "react-bootstrap";
import { SwalToast } from "../../utils/toasts";
import ReactDatePicker from "react-datepicker";
import * as dayjs from "dayjs";
import DropZoneCustom from "../Dropzone/DropZoneCustom";
import { useMutateUploadFacturas } from "../../hooks/factura.hooks";

const SubirFacturasModal = ({ showModal, setShowModal }) => {
    
    const [month, setMonth] = useState(new Date());
    const [year, setYear] = useState(new Date());
    const { mutate: mutateUpload, isLoading } = useMutateUploadFacturas(); 
    const [filesResult, setFilesResult] = useState({
        filesSuccess: [],
        filesError: []
    });
    const {filesSuccess, filesError} = filesResult;
    
    
	const handleCloseModal = () => {
        setFilesResult({
            filesSuccess: [],
            filesError: []
        });
		setShowModal(false);
	};
    
    
	const handleSubmit = (e) => {
		e.preventDefault();
        // console.log('files',filesResult);
        if(filesError.length > 0){
            SwalToast.fire({
                title: "Error",
                text: "Por favor, verifique que todos los archivos sean validos",
                icon: "error"
            });
            return;
        }
        if(filesSuccess.length > 0 && filesError.length === 0){
            const filesToApi = filesSuccess.map((el) => el.file);
            mutateUpload({
                files: filesToApi,
            },{
                onSuccess: (data) => {
                    SwalToast.fire({
                        title: "Facturas subidas correctamente",
                        icon: "success"
                    });
                    handleCloseModal();
                },
                onError: (error) => {
                    SwalToast.fire({
                        title: "Error al subir las facturas",
                        icon: "error",
                        text: error.message
                    });
                }
            });
            return;
        }
	};
    
    
	return (
		<DarkModal showModal={showModal} onHide={handleCloseModal}>
			<div className="container">
				<div className="row">
					<Form className="w-100" onSubmit={handleSubmit}>
                        {/* <Form.Group
                            className="col-12 my-2"
                        >
                            <Form.Label>Periodo:</Form.Label>
                            <ReactDatePicker
                                locale="es"
                                selected={year}
                                onChange={(date) => setYear(date)}
                                dateFormat="yyyy"
                                showYearPicker
                                className="form-control ge-form"
                            />
                        </Form.Group>
                        <Form.Group
                            className="col-12 my-2"
                        >
                            <Form.Label>Mes:</Form.Label>
                            <ReactDatePicker
                                locale="es"
                                selected={month}
                                onChange={(date) => setMonth(date)}
                                showMonthYearPicker
                                showFullMonthYearPicker
                                dateFormat="MMMM"
                                className="form-control ge-form"
                            />
                        </Form.Group> */}
						<div className="col-12 my-4">
                            <DropZoneCustom
                                filesResult={filesResult}
                                setFilesResult={setFilesResult}
                            />
						</div>
						<div className="d-flex w-100 justify-content-around">
							<button
								type="submit"
								className="btn btn-primary my-2"
								disabled={isLoading}
							>
								{isLoading ? (
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
								) : (
									"Subir Factura"
								)}
							</button>
							<button
								type="button"
								className="btn btn-secondary my-2"
								disabled={isLoading}
								onClick={handleCloseModal}
							>
								Cancelar
							</button>
						</div>
					</Form>
				</div>
			</div>
		</DarkModal>
	);
};

export default SubirFacturasModal;
