import axios from "axios";
import { deleteTokenLS, getTokenLS } from "../utils/localStorage";

export const getAuthAxios = () => {
	// const tokenLS = getTokenLS();
	const tokenCookie = getTokenLS();
	const tokenAuth = tokenCookie ? `${tokenCookie}` : "";
    
	//crear instancia de axios con header
	let axiosTemp = axios.create({
		  baseURL: process.env.REACT_APP_API_URL,
		//baseURL: process.env.REACT_APP_API_LOCAL,
		headers: {
			"Content-Type": "application/json",
			ACCESSTOKEN: tokenAuth,
		},
	});
    
	//agregar interceptor de errores
	axiosTemp.interceptors.response.use(
		(response) => {
            // console.log('response Interceptor', response);
			if (response.data.status === 403) {
				//eliminar la cookie
				deleteTokenLS();
				//redireccionar a login
				window.location = "/login";
			}
			return response;
		},
		(error) => {
			if (error.status === 403) {
				//eliminar la cookie
				deleteTokenLS();
				//redireccionar a login
				window.location = "/login";
			}
			if (error.status === 400) {
				console.log(error);
			}
			return Promise.reject(error);
		}
	);
    
	return axiosTemp;
};

export const getPublicAxios = () => {
	return axios.create({
		 baseURL: process.env.REACT_APP_API_URL,
		//baseURL: process.env.REACT_APP_API_LOCAL,
		headers: {
			"Content-Type": "application/json",
		},
	});
};
