import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createDocente,
  toggleActiveDocente,
  editDocente,
  getAllDocentes,
  selectDocentes,
  getTopDocentes,
  getHabilidadDocente,
  createHabilidadDocente,
  deleteHabilidadDocente,
  deleteDocenteById,
  getPagosDocentes,
  realizarPagoDocente,
  getReportePagosDocente,
  getUltimoReporteDocente
} from "../controllers/docentes/docentes";

const KEY_ALL_DOCENTES = "getAllDocentes";
const KEY_SELECT_DOCENTES = "selectDocentes";
const KEY_TOP_DOCENTES = "getTopDocentes";
const KEY_HABILITY_DOCENTE = "gethabilitydocente";
const KEY_PAGOS_DOCENTES = "KEY_PAGOS_DOCENTES"
const KEY_REPORTE_PAGOS_DOCENTE = "KEY_REPORTES_PAGOS_DOCENTE"

export const useMutateDocente = () => {
  const queryClient = useQueryClient();
  return useMutation(createDocente, {
    onSuccess: (data) => {
      /* queryClient.setQueryData("getAllDocentes", (prev) => {
                return [...prev, data];
            }); */
      queryClient.invalidateQueries([KEY_ALL_DOCENTES]);
    },
  });
};

export const useQueryTopDocentes = () => {
  return useQuery([KEY_TOP_DOCENTES], () => getTopDocentes(), {
    retry: 1,
    refetchOnWindowFocus: false,
  });
};

export const useQueryDocentes = () => {
  return useQuery(KEY_ALL_DOCENTES, getAllDocentes, {
    retry: 2,
    refetchOnWindowFocus: false,
  });
};

export const useQueryPagosDocentes = (id_user) => {
  return useQuery(
    [KEY_PAGOS_DOCENTES, id_user],
    async () => await getPagosDocentes(id_user),
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );
};

export const useQueryReportePagosDocente = (id_user) => {
  return useQuery(
    ["ULTIMO_REPORTE_PAGO", id_user],
    async () => await getReportePagosDocente(id_user),
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );
};

export const useQueryUltimoReporteDocente = (id_docente) => {
  return useQuery(
    [KEY_REPORTE_PAGOS_DOCENTE, id_docente],
    async () => await getUltimoReporteDocente(id_docente),
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );
};

export const useQueryPagarDocente = () => {
  const queryClient = useQueryClient();
  return useMutation(realizarPagoDocente, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_PAGOS_DOCENTES])
    }
  })
};

export const useQueryDocentesSelect = () => {
  return useQuery(KEY_SELECT_DOCENTES, selectDocentes, {
    retry: 2,
    refetchOnWindowFocus: false,
  });
};
/* 
    activo = 0

*/
export const useMutateToggleActiveDocente = () => {
  const queryClient = useQueryClient();
  return useMutation(toggleActiveDocente, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_ALL_DOCENTES]);
    },
  });
};

export const useMutateEditDocente = () => {
  const queryClient = useQueryClient();
  return useMutation(editDocente, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([KEY_ALL_DOCENTES]);
    },
  });
};

export const useMutateDeleteDocenteById = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteDocenteById, {
    onSuccess: () => {
      queryClient.invalidateQueries([KEY_ALL_DOCENTES]);
    },
  });
};

/* QUERYS Habilidades docentes*/

export const useQueryHabilidadDocente = (id_docente) => {
  return useQuery(
    [KEY_HABILITY_DOCENTE, id_docente],
    () => getHabilidadDocente(id_docente),
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );
};

export const useMutateCreateHabilidadDocente = () => {
  const queryClient = useQueryClient();
  return useMutation(createHabilidadDocente, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([KEY_HABILITY_DOCENTE]);
    },
  });
};

export const useMutateDeleteHabilidadDocente = (id_habilidad) => {
  const queryClient = useQueryClient();
  return useMutation(deleteHabilidadDocente, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([KEY_HABILITY_DOCENTE, id_habilidad]);
    },
  });
};
