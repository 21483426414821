import React from "react";
import CardGestora from "../../Cards/CardGestora";
import _ from 'lodash';

const MySchoolsCard = ({schools = []}) => {
	return (
		<CardGestora title={"Escuelas"}>
			<div className="row">
                {
                    schools.length > 0 ? (
                        schools.map((school, index) => (
                            <div className="d-flex col-12 my-1" key={index}>
                                <img
                                    src="/images/check-morado.png"
                                    alt="check"
                                    style={{ width: "20px" }}
                                    className="mr-2"
                                />
                                <span className="ge-title txt-bold">{_.capitalize(school.nombre_escuela)}</span>
                            </div>
                        ))
                    ) : (
                        <div className="col-12 no-tiene-escuelas-asignados text-light py-2 ge-radius">
                            <span>No tiene escuelas asignadas</span>
                        </div>
                    )
                }
            </div>
		</CardGestora>
	);
};

export default MySchoolsCard;
