import React, { useEffect, useState } from "react";
import { getSelectStatusIncident } from "../../controllers/incidencias/incidencias.controller";
import HeaderSearchTable from "../Custom/HeaderSearchTable";

const CustomHeader = ({
	filterText,
	setFilterText,
	arrayData = [],
	setArrayFiltered,
	setResetPagination,
	placeholder = "Buscar",
	columnsToFilter = ["nombres", "apellidos", "correo"],
	className="row w-100 mb-4",
}) => {
    
    
    const [selectStatus, setSelectStatus] = useState([]);
    const [optionStatus, setOptionStatus] = useState("");
    
    const fetchData = async () => {
        const response = await getSelectStatusIncident();
        if(response) {
            setSelectStatus(response);
        }
    }
    
    
	const onFilterItems = () => {
		if (optionStatus && optionStatus !== "all") {
            const newFilteredItems = arrayData.filter(
                (item) => 
                    String(item.id_estado) === String(optionStatus)
            );
            if(newFilteredItems.length > 0) {
                setArrayFiltered(newFilteredItems);
            }else{
                setArrayFiltered([]);
                setResetPagination(true);
            }
        } else {
			setArrayFiltered([]);
			setResetPagination(true);
		}
	};
    
	useEffect(() => {
		if(arrayData.length > 0) {
			onFilterItems();
		}
        console.log(arrayData)
        console.log("Entro al useEffect")
	}, [optionStatus]);
    
    useEffect(() => {
        fetchData();
    }, [])
    
	return (
		<div className={className}>
			<div className="col-12 col-md-6">
                <label className="txt-small" htmlFor="status">Filtrar por estado:</label>
                <select 
                    name="status"
                    className="form-control ge-form"
                    onChange={(e) => setOptionStatus(e.target.value)}
                    value={optionStatus}
                >
                    <option disabled value="">--- Seleccione una opción ---</option>
                    <option value="all"> Todos </option>
                    {
                        selectStatus.length > 0 && selectStatus.map((item, index) => (
                            <option key={item.id_estado} value={item.id_estado}>{item.nombre_estado}</option>
                        ))
                    }
                </select>
            </div>
            <HeaderSearchTable
                filterText={filterText}
                setFilterText={setFilterText}
                arrayData={arrayData}
                setArrayFiltered={setArrayFiltered}
                setResetPagination={setResetPagination}
                placeholder={placeholder}
                columnsToFilter={columnsToFilter}
                className="col-12 col-md-6 d-flex align-items-center mt-4"
            />
		</div>
	);
};

export default CustomHeader;
