import { useMutation, useQuery, useQueryClient } from "react-query";
import { createFacebookCampaign, editFacebookCampaign, getFacebookCampaigndetails, getFacebookCampaigns, toggleFacebookCampaign } from "../controllers/marketing/facebook/facebook.controller";

const FB_CAMPAIGNS_KEY = "fb_campaigns";

export const useQueryFacebookCampaigns = () => {
    return useQuery(
        [FB_CAMPAIGNS_KEY],
        () => getFacebookCampaigns(),
        {
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}

export const useQueryOneFacebookCampaign = (id) => {
    return useQuery(
        [FB_CAMPAIGNS_KEY, id],
        () => getFacebookCampaigndetails(id),
        {
            enabled: id !== null,
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}


export const useMutateCreateFacebookCampaign = () => {
    const queryClient = useQueryClient();
    return useMutation(
        createFacebookCampaign,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([FB_CAMPAIGNS_KEY]);
            }
        }
    );
}

export const useMutateUpdateFacebookCampaign = (id) => {
    const queryClient = useQueryClient();
    return useMutation(
        editFacebookCampaign,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([FB_CAMPAIGNS_KEY]);
                queryClient.invalidateQueries([FB_CAMPAIGNS_KEY, id]);
            }
        }
    );
}

export const useMutateToggleFacebookCampaign = () => {
    const queryClient = useQueryClient();
    return useMutation(
        toggleFacebookCampaign,
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([FB_CAMPAIGNS_KEY]);
            }
        }
    );
}