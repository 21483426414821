import React from "react";
import Select from "react-select";

export default ({ onChange, options, value, id, lbl, seleccion, className, lblColor="#842eff" , isMulti=false}) => {
    
	const defaultValue = (options, value) => {
		return options
			? !isMulti ? options.find((option) => option[id] === value) : value
			: "";
	};
    
	return (
		<div className={className} style={{ zIndex: 4 }}>
			<label style={{ color: lblColor }}>{seleccion}:</label>
			<Select
				isMulti={isMulti}
                placeholder={`${seleccion}`}
				noOptionsMessage={() => "No hay opciones"}
				value={defaultValue(options, value)}
				onChange={(value) => {
					if(!value){
						value={
							[id]:''
						}
					}
					//console.log(value)
					onChange(value);
				}}
				options={options}
				getOptionValue={(cursos) => cursos[id]}
				getOptionLabel={(cursos) => cursos[lbl]}
				isClearable
			/>
		</div>
	);
};
