import { getAuthAxios } from "../../../axios/axiosCustom";
import dayjs from "dayjs";


export const getSelectCampaigns = async () => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get('/ads/select_tipo_campaign');
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}


export const getFacebookCampaigns = async () => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get('/ads/facebook/campaigns');
        if(response.data.status){
            return response.data.data;
        }
        throw new Error(response.data.message);
    }catch(error){
        console.log(error)
    }
}

export const getFacebookCampaigndetails = async (id) => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/ads/facebook/one_campaign/${id}`);
        if(response.data.status){
            return response.data.data;
        }
        throw new Error(response.data.message);
    }catch(error){
        console.log(error)
    }
}

export const createFacebookCampaign = async (data) => {
    try{
        const data_ = {
            ...data,
            fecha_inicio: dayjs(data.fecha_inicio).format('YYYY-MM-DD'),
            fecha_fin: dayjs(data.fecha_fin).format('YYYY-MM-DD'),
        }
        
        const axios = getAuthAxios();
        const response = await axios.post('/ads/facebook/crear_campaign', data_);
        if(response.data.status){
            return response.data;
        }
        throw new Error(response.data.message);
    }catch(error){
        console.log(error)
    }
}


export const editFacebookCampaign = async (data) => {
    try{
        const data_ = {
            ...data,
            fecha_inicio: dayjs(data.fecha_inicio).format('YYYY-MM-DD'),
            fecha_fin: dayjs(data.fecha_fin).format('YYYY-MM-DD'),
        }
        const axios = getAuthAxios();
        const response = await axios.post('/ads/facebook/editar_campaign', data_);
        if(response.data.status){
            return response.data;
        }
        throw new Error(response.data.message);
    }catch(error){
        console.log(error)
    }
}


export const toggleFacebookCampaign = async ({
    id,
    status,
}) => {
    try{
        const axios = getAuthAxios();
        const response = await axios.put(`/ads/facebook/toggle_active_campaign/${id}/${status}`);
        if(response.data.status){
            return response.data;
        }
        throw new Error(response.data.message);
    }catch(error){
        console.log(error)
    }
}