import Swal from "sweetalert2";
import { getAuthAxios } from "../../axios/axiosCustom";

export const getSuscripciones = async (datos) => {
    //console.log(datos)
    if(datos.fecha)
    { 
    const axios = getAuthAxios();
    const response = await axios.get(`/compra/all_suscripciones_vendidos/${datos.fecha.startDate}/${datos.fecha.endDate}/${datos.producto!==''?datos.producto:null}`);
    if(response.data.status){
        return response.data.data;
    }
    throw new Error(response.data.message);
    }
}

export const disableSuscripcion = async (datos) => {

  Swal.fire({
    title: '¿Estás seguro?',
    text: "Esta acción, cancelará la suscripción creada en Culqi y no se puede revertir.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Si',
    cancelButtonText: 'Cancelar',
    reverseButtons: true
  }).then(async(result) => {
    if (result.isConfirmed) {
        const axios = getAuthAxios();
        const response = await axios.put(`/compra/disableSuscripcion/${datos.id}`,datos);
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
    
        if(response.data.status){
            Toast.fire({
                icon: 'success',
                title: response.data.message
              })
            return response.data;
        }
        throw new Error(response.data.error);
    }
  })   
}


export const deleteSuscripcionCulqi = async ({id_culqi}) => {
    const axios = getAuthAxios();
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

    await  Swal.fire({
        title: '¿Estás seguro?',
        text: "Una vez eliminado, no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
            const response = await axios.delete(`/plan/delete_plan/${id_culqi}`);
              
            if(response.data.status){
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  })
            }
            else{
                Toast.fire({
                    icon: 'warning',
                    title: response.data.message
                  })
            }
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      })


 
}