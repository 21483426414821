
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import { getPostsByUid, updatePost } from "../../controllers/blog/posts";
import EditorGeneral from "../../components/Editor/EditorGeneral";
import { useMutation, useQuery,useQueryClient } from "react-query";
import { Spinner } from "react-bootstrap";
import * as FaIcons from 'react-icons/fa'
import CommentList from "../../components/Posts/CommentList";
import { getEscuelas } from "../../controllers/escuelas/escuelas";
const EditPost = () => {
    const {uId} = useParams()

    const{data:dataEscuelas, error:errorEscuelas , isLoading:isLoadingEscuelas } = useQuery(["getEscuelas"], getEscuelas ,
        {
            refetchOnWindowFocus:false
        }
    )

    const{data, error , isLoading } = useQuery(["getPost",uId], ()=> getPostsByUid(uId),
        {
            refetchOnWindowFocus:false,
            retry:0
        }
    )

    const queryClient = useQueryClient()
    
    const {mutate, error : errorUpdate , isLoading : isLoadingUpdate } = useMutation(updatePost,{
        onSuccess: ()=>{
            queryClient.invalidateQueries(["posts"])
            queryClient.refetchQueries(["getPost"])
        }
    })

    const initialValues = {
        unique_id:'',
        titulo:'',
        descripcion_corta:'',
        imagen:'',
        id_escuela:'',
        archivo:{}
	};
	
    const [datosPost,setDatosPost]=useState(initialValues)
    const [contenidoHTML,setContenidoHTML] = useState('')

    const [loading, setLoading] = useState(false);
	const [imagePreview, setImagePreview] = useState(null);
	const imgInputRef = useRef();

    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png",
        "application/pdf"
      ];

	const validationSchema = yup.object({
		titulo: yup
			.string()
			.required("El campo es requerido"),
        descripcion_corta: yup
			.string()
			.required("El campo es requerido")
            .max(350,'Debe tener 350 carecteres o menos'),
        id_escuela: yup
            .string()
			.required("El campo es requerido"),
        archivo: yup
            .mixed()
            .notRequired()
            .test(
              "fileFormat",
              "Archivo no permitido",
              value => value ? SUPPORTED_FORMATS.includes(value.type) : true
            )
	});
	
	const formik = useFormik({
		initialValues:datosPost,
		validationSchema,
		onSubmit: async (values) => {
            const datos = {...values}
            delete datos.comments
            datos.contenido=contenidoHTML
			mutate(datos)
		},
	});

   useEffect(()=>{
        if(!isLoading)
        {
            setDatosPost(data[0])
            setContenidoHTML(data[0].contenido)
           
        }
    },[isLoading])

    useEffect(()=>{
        if(datosPost.titulo!=='')
        {
            formik.setValues(datosPost)
            setImagePreview(datosPost.imagen!=='' ? process.env.REACT_APP_API_URL+datosPost.imagen:null)
        }
    },[datosPost])

    	//Cambia la imagen de portada
	const handleChangeImage = (e) => {
		e.preventDefault();
		setLoading(true);
		if (imgInputRef.current.files.length > 0) {
			//validar solo imagenes
			const file = e.target.files[0];
			if (file.type.indexOf("image") < 0) {
				setLoading(false);
				return;
			}
			 //console.log(file);
			setImagePreview(URL.createObjectURL(file));
			formik.setFieldValue("archivo", file);
			setLoading(false);
		}
	};


    return (
        <LayoutAdmin
        contentTitle="Editar post"
        routeTitle="Post"
        >
        { isLoading ? <div className="text-center"> <Spinner animation="border" /></div> : <form onSubmit={formik.handleSubmit}>
           <div className='row'>
                <div className='col-md-12'>
                    <div className="card">
                        <div className="card-header p-3" style={{backgroundColor:'#842eff', color:'#fff'}}>
                            <h4>Editar publicación</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4 p-2">
                                    <div className="form-group mb-3">
                                        <div className="img-portada-container">
                                            <label>Imagen de portada</label>
                                            <img
                                                src={
                                                    !imagePreview
                                                        ? "https://via.placeholder.com/150?text=Gestora+Portada+Post"
                                                        : imagePreview
                                                }
                                                alt="..."
                                                className="img-profile mw-100"
                                            />
                                            <div className="button-container">
                                                <input
                                                    type="file"
                                                    ref={imgInputRef}
                                                    onChange={(e) => {
                                                        handleChangeImage(e);
                                                    }}
                                                    className="d-none"
                                                    disabled={loading}
                                                    name="archivo"
                                                />
                                                {/* <p> {formik.errors.archivo} </p> */}
                                                {loading ? (
                                                    <span>
                                                        <Spinner
                                                            animation="border"
                                                            variant="primary"
                                                            className="mt-2"
                                                        />
                                                    </span>
                                                ) : (
                                                    <span
                                                        className="img-profile-button text-center"
                                                        onClick={() =>
                                                            imgInputRef.current.click()
                                                        }
                                                    >
                                                        <FaIcons.FaPencilAlt />
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 p-2">
                                    <div className="form-group mb-3">
                                            <label>
                                                Titulo
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Titulo"
                                                name="titulo"
                                                onChange={formik.handleChange}
                                                value={formik.values.titulo}
                                            />
                                            <p> {formik.errors.titulo} </p>
                                        </div>
                                    <div className="form-group mb-3">
                                            <label>Descripción Corta</label>
                                            <textarea
                                            
                                                className="form-control"
                                                placeholder="Titulo"
                                                name="descripcion_corta"
                                                onChange={formik.handleChange}
                                                value={formik.values.descripcion_corta}
                                                maxLength="350"
                                            />
                                            <p className={formik.values.descripcion_corta.length != 350 ? "" : "text-danger"}>
												{formik.values.descripcion_corta.length}/350
											</p>
                                    </div>  
                                    <div className="form-group mb-3">
                                        <label>Escuela:</label>
                                        <select className="form-control"
                                                name="id_escuela"
                                                onChange={formik.handleChange}
                                                value={formik.values.id_escuela}>
                                                <option value="">{isLoadingEscuelas ? 'Cargando escuelas': !error ? 'Seleccione una escuela...': 'Error al traer escuelas'}</option>
                                            {
                                                !isLoadingEscuelas ? dataEscuelas.length ? dataEscuelas.map((escuela,i)=>(
                                                    <option key={i} value={escuela.id_escuela}>{escuela.nombre_escuela}</option>
                                                )) : null : null
                                            }
                                        </select>
                                        <p> {formik.errors.id_escuela} </p>
                                    </div>                           
                                </div>
                            </div>
                        </div>
                    </div>        
                </div>
                 <div className='col-md-12'>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="p-1">
                                <h4><b>Cuerpo de la publicación</b></h4>
                                </div>
                                <div className="col-md-12 mb-2"><EditorGeneral contenido={formik.values.contenido} onChange={setContenidoHTML} /></div>
                            </div>
                      
                        </div>
                    </div>        
                </div>
                <div className='col-md-12'>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="p-1">
                                    <div>
                                    <img
                                        src="/images/file-icon.png"
                                        className="mr-2"
                                        style={{ width: "40px" }}
                                        alt="file-icon"
                                    />
                                    <span className="ge-h3 ge-title">
                                        Comentarios
                                    </span>
                                    </div>
                                </div> 
                            </div>
                            <div>
                                <CommentList comments={datosPost.comments} />
                            </div>                           
                        </div>
                    </div> 
                    <div className="col-md-12 d-flex justify-content-end my-3">
                                    <button
                                        type="submit"
                                        className="btn ge-btn-primary"
                                    >
                                        Guardar
                                    </button>
                    </div>       
                </div>
           </div>
        </form>}
        </LayoutAdmin>
    );
};

export default EditPost;