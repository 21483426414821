import { getAuthAxios } from "../../axios/axiosCustom";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});


export const getCupones = async () => {
    
    const axios = getAuthAxios();
    
    try{
        const response = await axios.get('/cupones/traerCupones');
        
        if(response.data.status){
            return response.data.data;
        }
        throw new Error(response.data);
    }catch(error){
        console.log(error);
    }
    
}

export const getCupon = async (cupon) => {
    
    const axios = getAuthAxios();
    
    try{
        const response = await axios.get(`/cupones/traerCupones/${cupon}`);
        
        if(response.data.status){
            return response.data.data;
        }
        throw new Error(response.data);
    }catch(error){
        console.log(error);
    }
    
}



export const createCupon = async (data) => {
    const axios = getAuthAxios();
  
    
    try{
        const response = await axios.post('/cupones/crearCupon', data);
        
        if(response.data.status){
            Toast.fire({
                icon: "success",
                title: response.data.message,
            });
            return response.data;
        }
        Toast.fire({
			icon: "error",
			title: response.data.message,
		});
       // throw new Error(response.data);
    }catch(error){
        console.log(error);
    }
}



export const editCupon = async (data) => {
    const axios = getAuthAxios();   
    try{
        const response = await axios.put(`/cupones/editarCupon`, data);
        
        if(response.data.status){
            Toast.fire({
                icon: "success",
                title: response.data.message,
            });
            return response.data;
        }
        throw new Error(response.data);
    }catch(error){
        console.log(error);
    }
}

export const deleteCupon = async ({ id_registro }) => {
    const axios = getAuthAxios();
    
    try{
        const response = await axios.delete(`/cupones/eliminarCupon/${id_registro}`);
        
        if(response.data.status){
            return response.data;
        }
        throw new Error(response.data);
    }catch(error){
        console.log(error);
    }
}
export const updateCupon = async ({ id_registro,estado }) => {
    const axios = getAuthAxios();
    
    try{
        const response = await axios.put(`/cupones/habilitarCupon/${id_registro}/${estado}`);
        
        if(response.data.status){
            return response.data;
        }
        throw new Error(response.data);
    }catch(error){
        console.log(error);
    }
}