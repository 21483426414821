import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import * as FaIcons from "react-icons/fa";
import { Badge } from "react-bootstrap";
import Swal from "sweetalert2";
import CustomHeader from "../CustomHeader";
import LoaderSmall from "../../../Loaders/LoaderSmall";
import ModalCreatePost from "../ModalCreatePost";
import ModalEditPost from "../ModalEditPost";
import { useMutateCreateTwitterPost, useMutateEditTwitterPost, useMutateToggleTwitterPost, useQueryOneTwitterPost, useQueryTwitterPosts } from "../../../../hooks/marketing/posts/twitterPosts.hooks";

const TwitterPostsList = () => {
    
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [idPostToEdit, setIdPostToEdit] = useState(null);
    const { data, isLoading } = useQueryTwitterPosts();
    const { mutate: toggleActive } = useMutateToggleTwitterPost();
    
    const handleToEdit = (idPost) => {
        setIdPostToEdit(idPost);
        setShowModalEdit(true);
    }
    
    const handleToggleActive = (idPost, active) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: `Estás a punto de ${active===0 ? 'desactivar' : 'activar'} esta publicación`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro'
        }).then((result) => {
            if (result.isConfirmed) {
                toggleActive(
                    {
                        id_registro: idPost,
                        activo: active
                    },
                    {
                        onSuccess: () => {
                            Swal.fire(
                                '¡Hecho!',
                                `La publicación ha sido ${active===0 ? 'desactivada' : 'activada'} con éxito`,
                                'success'
                            );
                        },
                        onError: (error) => {
                            Swal.fire(
                                'Error',
                                `Error al ${active===0 ? 'desactivar' : 'activar'} la publicación`,
                                'error'
                            );
                        }
                    }
                );
            }
        });
    }
    
    
	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<CustomHeader
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={data}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
				placeholder="Buscar por titulo de post"
				columnsToFilter={["titulo"]}
			/>
		);
	}, [filterText, resetPagination]);
    
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "#",
				cell: (row, index) => row.id_registro,
				width: "5%",
			},
            {
                name: "Título",
                selector: (row) => row.titulo,
                width: "200px",
            },
            {
                name: "Descripción",
                selector: (row) => row.descripcion,
                width: "250px",
            },
            {
				name: "Estado publicación",
				selector: (row) => row.nombre_estado,
                width: "150px",
			},
			{
				name: "# Likes",
				selector: (row) => row.public_metrics ? row.public_metrics.like_count : 0,
                width: "150px",
			},
            {
                name: "# Comentarios",
                selector: (row) => row.public_metrics ? row.public_metrics.reply_count : 0,
                width: "150px",
            },
            {
                name: "# Retweets",
                selector: (row) => row.public_metrics ? row.public_metrics.retweet_count : 0,
                width: "150px",
            },
            {
                name: "Estado",
                cell: (row, index) => parseInt(row.activo) === 1
                    ? <Badge bg="success" className="p-1">Activo</Badge>
                    : <Badge bg="danger" className="p-1">Inactivo</Badge>,
            },
            {
				name: "Acciones",
				cell: (row) => (
					<div className="d-flex justify-content-center align-items-center">
						<button
							className={`btn btn-sm btn-${parseInt(row.activo) === 1 ? "success" : "danger"} mx-1`}
							title={
                                parseInt(row.activo) === 1 ? "Desactivar" : "Activar"  
                            }
							onClick={() => handleToggleActive(row.id_registro, parseInt(row.activo) === 1 ? 0 : 1)}
						>
                            {
                                parseInt(row.activo) === 1 ? <FaIcons.FaToggleOn size={20} /> : <FaIcons.FaToggleOff  size={20} />
                            }
						</button>
                        <button
                            className="btn btn-warning btn-sm mx-1 text-white"
                            title="Editar"
                            onClick={() => handleToEdit(row.id_registro)}
                        >
                            <FaIcons.FaEdit size={20} />
                        </button>
                        <a
                            className="btn btn-sm btn-primary mx-1"
                            title="Ver detalle"
                            href={`https://twitter.com/${row.author_id}/status/${row.id}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FaIcons.FaEye size={20} />
                        </a>
					</div>
				),
			},
		],
		[]
	);
    
	return (
		<div className="card">
            <ModalCreatePost
                show={showModal}
                setShow={setShowModal}
                useMutateCreate={useMutateCreateTwitterPost}
            />
            <ModalEditPost
                show={showModalEdit}
                setShow={setShowModalEdit}
                idPost={idPostToEdit}
                setIdPost={setIdPostToEdit}
                useQueryGetData={useQueryOneTwitterPost}
                useMutateEditData={useMutateEditTwitterPost}
            />
            <div className="d-flex flex-column align-items-center flex-md-row justify-content-md-between card-header card-no-after ge-noborder p-4">
                <div className="d-flex flex-column flex-sm-row align-items-center">
                    <img
                        src="/images/file-icon.png"
                        className="mr-2"
                        style={{ width: "40px" }}
                        alt="file-icon"
                    />
                    <span className="ge-h3 ge-title">
                        Publicaciones de Twitter
                    </span>
                </div>
                <div className="my-2 my-md-0">
                    <button
                        className="btn ge-btn-deg-primary btn-sm"
                        onClick={() => setShowModal(true)}
                    >
                        <FaIcons.FaPlus />
                        <span className="ml-2">Registrar publicación</span>
                    </button>
                </div>
            </div>
			<div className="card-body">
				<div className="row">
					<div className="col-12">
						<DataTable
							className="custom-datatable"
							columns={columns}
							data={
								filteredItems.length > 0
									? filteredItems
									: data
							}
							subHeader
							subHeaderComponent={searchComponentMemo}
							pagination
							responsive
							fixedHeader
							fixedHeaderScrollHeight="600px"
							progressPending={isLoading}
							progressComponent={<LoaderSmall />}
                            noDataComponent={
                                <div className="text-center mt-4">
                                    <span className="ge-h5">
                                        No se encontraron registros
                                    </span>
                                </div>
                            }
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TwitterPostsList;
