import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import EditarPortada from "../../components/Historias/EditarPortada";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import ListarHistorias from "./ListarHistorias";

const TabHistorias = () => {
	return (
		<LayoutAdmin contentTitle="" routeTitle="Historias">
			<Tabs>
				<Tab eventKey="home" title="Portada">
                    <EditarPortada />
                </Tab>
				<Tab eventKey="profile" title="Historias">
					<ListarHistorias />
				</Tab>
			</Tabs>
		</LayoutAdmin>
	);
};

export default TabHistorias;
