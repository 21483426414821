import { useFormik } from "formik";
import React from "react";
import { Form, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useMutationResponseIncident } from "../../hooks/incidencias.hooks";
import { SwalToast } from "../../utils/toasts";

/* El campo file no es requerido y puede ser null */
const validationSchema = Yup.object({
    answer: Yup.string().required("La respuesta es requerida"),
    file: Yup.mixed()
    .nullable()
    .default(null)
    .test("fileType", "Solo se permiten archivos de imagen", value => {
        // si el campo file existe validar que el archivo tenga el formato permitido
        // si el campo file no existe no validar nada y establecerlo como null
        if(value) {
            return SUPPORTED_FORMATS.includes(value.type);
        }else{
            return true;
        }
	}),
});

const SUPPORTED_FORMATS = [
	"image/jpg",
	"image/jpeg",
	"image/gif",
	"image/png"
];


const FormAnswerTicket = ({ cod_ticket }) => {
    
    const { user } = useSelector(state => state.user);
    const initialValues = {
        answer: "",
        file: null,
    }
    
    const { mutate: sendAnswer, isLoading: loadingSubmit } = useMutationResponseIncident(cod_ticket);
    
    const onChangeFile = (e) => {
		if(e.target.files.length > 0) {
			const file = e.target.files[0];
			formik.setFieldValue("file", file);
		}
	}
    
	const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            sendAnswer({
                cod_incident: cod_ticket,
                data: {
                    ...values,
                    id_user: user?.id_user,
                }
            },{
                onSuccess: () => {
                    formik.resetForm();
                    SwalToast.fire({
                        title: "Respuesta enviada",
                        icon: "success",
                    });
                },
                onError: () => {
                    SwalToast.fire({
                        title: "Error al enviar la respuesta",
                        icon: "error",
                    });
                },
            });
        }
    });
    
	return (
		<Form
            onSubmit={formik.handleSubmit}
        >
            <fieldset>
                <legend>Responder ticket</legend>
                <Form.Group className="col-12 my-2">
                    <Form.Label>Respuesta:</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Escriba su respuesta al problema, sea detallado"
                        name="answer"
                        value={formik.values.answer}
                        onChange={formik.handleChange}
                        isValid={formik.touched.answer && !formik.errors.answer}
                        isInvalid={formik.touched.answer && formik.errors.answer}
                    />
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.answer}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-12 my-3">
                    <Form.Label>Archivo:</Form.Label>
                    <small className="ml-2 text-muted">
                        Solo se permite 1 archivo de imagen
                    </small>
                    <Form.Control
                        type="file"
                        name="file"
                        accept={SUPPORTED_FORMATS.join(",")}
                        onChange={onChangeFile}
                        isValid={formik.touched.file && !formik.errors.file}
                        isInvalid={formik.touched.file && formik.errors.file}
                    />
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.file}
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="col-12 my-3">
                    <button 
                        type="submit" 
                        className="btn btn-primary"
                        disabled={loadingSubmit}
                    >
                        {
                            loadingSubmit ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : (
                                "Enviar"
                            )
                        }
                    </button>
                </div>
            </fieldset>
		</Form>
	);
};

export default FormAnswerTicket;
