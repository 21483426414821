import React, { useMemo, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useQuery,useQueryClient,useMutation} from 'react-query';
import { NavLink, useNavigate } from 'react-router-dom';
import HeaderSearchTable from '../../components/Custom/HeaderSearchTable';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import { disablePost, getPostsByParams } from '../../controllers/blog/posts';
import * as FaIcons from 'react-icons/fa'
import * as BsIcons from 'react-icons/bs'
import DataTable from "react-data-table-component";
import LoaderSmall from '../../components/Loaders/LoaderSmall';
import RangePicker from '../../components/DatePickers/RangePicker';
import dayjs from 'dayjs';
import { useSelector } from "react-redux";
import { useMutateDeletePostById } from '../../hooks/blog.hooks';


const ListarPosts = () => {

	const [dataConsulta,setDataconsulta]=useState({
		fecha:null,
		escuela:'',
	})

    const {data : postsList,error,isLoading} = useQuery(['posts',dataConsulta],()=>getPostsByParams(dataConsulta),{
		refetchOnWindowFocus:false,
		retry:0,
	})

	const queryClient = useQueryClient()
    
    const {mutate, error : errorUpdate , isLoading : isLoadingUpdate } = useMutation(disablePost,{
        onSuccess: ()=>{
            queryClient.invalidateQueries(['posts',dataConsulta])
        }
    })


    const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);

	const handleChangeFecha = (date)=>{
		const fechaFormat={
			startDate:dayjs(date[0]).format('YYYY-MM-DD'),
			endDate:dayjs(date[1]).format('YYYY-MM-DD')
		}
		setDataconsulta({
			...dataConsulta,
			fecha:fechaFormat
		})
	}

	const navigate = useNavigate()

	const handleDisablePost = (id,eliminado)=>{
		//console.log(id)
		mutate({id:id,eliminado:eliminado})
	}


			/* Traer datos del Redux */
	const { user } = useSelector(( state ) => state.user)

	const { mutate: mutateDeletePost } = useMutateDeletePostById()


    	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={postsList}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
                columnsToFilter={["titulo","nombre_escuela","fecha_creacion"]}
				placeholder="Buscar por Título o Escuela o Fecha registro"
			/>
		);
	}, [filterText, resetPagination]);

	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: '#',
				cell: (row, index) => index+1,
				width: '5%',
			},
            {
				name: "Fecha registro",
				selector: (row) => row.fecha_creacion,
				sortable: true,
			},
			{
				name: "Titulo",
				selector: (row) => <div style={{textAlign:'left'}}>{row.titulo}</div>,
				sortable: true,
                wrap:true
			},
            {
				name: "Escuela",
				selector: (row) => row.nombre_escuela,
				sortable: true,
			},
			{
				name: "Autor",
				selector: (row) => row.nombres,
				sortable: true,
			},
			{
				name: "Rol",
				selector: (row) =>row.nombre_rol
			},
			{
				name: "Status",
				cell: (row) =>
					parseInt(row.estado) === 1 ? (
						<Badge className="ge-deg-primary p-1">Público</Badge>
					) : (
						<Badge bg="danger">Sin publicar</Badge>
					),
				sortable: true,
			},
			{
				name: "Acciones",
				cell: (row) => (
					<div className="d-flex">
						<NavLink  to={`/editar-post/${row.unique_id}`}
							className="btn ge-btn-primary btn-sm mr-2"
						>
							<FaIcons.FaEdit />
						</NavLink>

					
						{
							parseInt(row.estado) === 1 ? (
									<button 
										className="btn btn-light btn-sm"
										onClick={() => handleDisablePost(row.id_post, 0)}
									>
										<BsIcons.BsToggleOn color="#37af48" size={20} />
									</button>
								):(
									<button 
										className="btn btn-light btn-sm"
										onClick={() => handleDisablePost(row.id_post, 1)}
										>
										<BsIcons.BsToggleOff color="#ff0000" size={20} />
									</button>
								)
							}
							{ user.roles.includes("2") && (
								<button
									className="btn btn-danger btn-sm ml-2"
									onClick={()=>  handleDeletePostById(row.id_post)}
								>
									<FaIcons.FaTrash />
								</button>
							)}
					</div>
				),
			},
		],
		[]
	);


	const handleDeletePostById = (id_post) => {
		mutateDeletePost(id_post)
	};

    return (
        <LayoutAdmin
        contentTitle="" routeTitle="Lista de Posts"
        >
           <div className='row'>
                <div className='col-md-12'>
                    <div className="card">
                            <div className="d-flex justify-content-between p-3">
                                <div>
                                    <img
                                        src="/images/file-icon.png"
                                        className="mr-2"
                                        style={{ width: "40px" }}
                                        alt="file-icon"
                                    />
                                    <span className="ge-h3 ge-title">
                                        Lista de Posts
                                    </span>
                                </div>
								<div>
									<button className='btn ge-btn-primary' onClick={()=>navigate('/crear-post')}>Crear post</button>
								</div>
                            </div>
							<div className='d-flex justify-content-start p-3'>
									<div style={{marginRight:'10px'}}>
										<label style={{color:'#842eff'}}>Rango de Fecha</label>
										<RangePicker handleChange={handleChangeFecha}/>
									</div>
							</div>
                        <div className='card-body'>
                        <DataTable
								className="custom-datatable"
								columns={columns}
								data={
									filteredItems.length > 0
										? filteredItems
										: postsList
								}
								subHeader
								subHeaderComponent={searchComponentMemo}
								pagination
								responsive
								fixedHeader
								fixedHeaderScrollHeight="300px"
								progressPending={isLoading}
								progressComponent={<LoaderSmall />}
							/>
                        </div>
                    </div>
                </div>
           </div>
        </LayoutAdmin>
    );
};

export default ListarPosts;