import React, { useMemo, useState } from 'react';
import HeaderSearchTable from '../Custom/HeaderSearchTable';
import * as FaIcons from 'react-icons/fa'
import LoaderSmall from '../Loaders/LoaderSmall';
import { NavLink, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import ListaRutas from './ListaRutas';
import CustomModal from '../Modal/CustomModal';
import { deleteRuta } from '../../controllers/escuelas/escuelas';
import { useMutation, useQueryClient } from 'react-query';


const DatosRutas = ({id_escuela,rutas}) => {

	const {uId} = useParams()

	const queryClient = useQueryClient()
    const {mutate, error : errorCreate , isLoading : isLoadingCreate } = useMutation(deleteRuta,
        {
            onSuccess:()=>{
				queryClient.invalidateQueries(["getEscuela",uId])
            }
        })


    const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
	const[show, setShow] = useState(false)

    	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={rutas}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
                columnsToFilter={["nombre"]}
				placeholder="Buscar por nombre ruta"
			/>
		);
	}, [filterText, resetPagination]);

	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: '#',
				cell: (row, index) => index+1,
				width: '5%',
			},
            {
				name: "Nombre ruta",
				selector: (row) => row.nombre,
				sortable: true,
			},
            {
				name: "Cant. Cursos",
				selector: (row) => row.cursos,
				sortable: true,
			},
			{
				name: "Acciones",
				cell: (row) => (
					<div className="d-flex">

                        <NavLink to={`/editar-ruta/${row.cod_unico}`}
                            className="btn ge-btn-primary btn-sm mr-2"
                        >
                            <FaIcons.FaCogs />
                        </NavLink>
						<button className="btn btn-danger btn-sm" title='Eliminar' onClick={()=> mutate({id_escuela:id_escuela,id_ruta:row.id})} >
						<FaIcons.FaTrash />
						</button>
					</div>
				),
			},
		],
		[]
	);




    return (
		<>
			<CustomModal title={'Agregar ruta'} show={show} handleShow={setShow} >
				<ListaRutas id_escuela={id_escuela} handleShow ={setShow} />
			</CustomModal>
			<div className='card'>
				<div className='card-body'>
				<div className='d-flex justify-content-start my-3'> <button className='btn ge-btn-primary' onClick={()=>setShow(!show)}>Agregar ruta</button> </div>
						<DataTable
							className="custom-datatable"
							columns={columns}
							data={
								filteredItems.length > 0
									? filteredItems
									: rutas
							}
							subHeader
							subHeaderComponent={searchComponentMemo}
							pagination
							responsive
							fixedHeader
							fixedHeaderScrollHeight="300px"
							progressComponent={<LoaderSmall />}
								/>
				</div>
        	</div>
		</>
    );
};

export default DatosRutas;