import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import * as FaIcons from "react-icons/fa";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import { useQueryClient } from "react-query";
import { useMutateDocente } from "../../hooks/docentes.hooks";
import { SwalToast } from "../../utils/toasts";
import { getEscuelas } from "../../controllers/escuelas/escuelas";
import { useQuery } from "react-query";
import { useQuerySelectCursos } from "../../hooks/cursos.hooks";

const CreateDocenteScreen = () => {
  const [shown, setShown] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const imgInputRef = useRef();
  const queryClient = useQueryClient();
  const { data, isError, isLoading, isSuccess, mutate } = useMutateDocente();
  const { data: escuelaSelect, error } = useQuery(
    ["getEscuela"],
    () => getEscuelas(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: cursosSelect } = useQuerySelectCursos();

  const [tipoPago, setTipoPago] = useState([]);

  const checkPagos = (value) => {
    const pagosSelected = [...tipoPago];
    if (pagosSelected.includes(value)) {
      pagosSelected.splice(pagosSelected.indexOf(value), 1);
    } else {
      pagosSelected.push(value);
    }
    setTipoPago(pagosSelected);
  };
	const initialValues = {
		nombres: "",
		apellidos: "",
		correo: "",
		telefono: "",
		pais: "",
		descripcion: "",
		escuela: "",
		video_presentacion:"",
		linkedinDocente: "",//nuevas funcionalidades del docente
		password: "",
		imagen: "",
	};

  const [pagos, setPagos] = useState([{ id_curso: null, monto: null }]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...pagos];
    list[index][name] = parseInt(value);
    return setPagos(list);
  };

  const addInput = () => {
    setPagos([...pagos, { id_curso: null, monto: null }]);
  };

  const removeInput = (index) => {
    const list = [...pagos];
    list.splice(index, 1);
    return setPagos(list);
  };

  const switchShown = () => setShown(!shown);

  const validationSchema = yup.object({
    nombres: yup.string().min(2).required("El nombre es requerido"),
    apellidos: yup.string().min(2).required("El apellido es requerido"),
    correo: yup
      .string()
      .email("El correo no es válido")
      .required("El correo es requerido"),
    pais: yup.string().required("El país es requerido"),
    telefono: yup.string().min(5).required("El teléfono es requerido"),
    descripcion: yup.string(),
    escuela: yup.string().required("La escuela es requerido."),
    video_presentacion: yup.string().url("Ingrese una URL válida."),
		linkedinDocente: yup.string().url("Ingrese una URL válida."),
    password: yup
      .string()
      .min(8, "La contraseña debe tener una longitud mínima de 8 caracteres")
      .required("La contraseña es requerido"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data = [
        tipoPago.includes(1) && {tipo_pago: 1, cursos: pagos},
        tipoPago.includes(2) && {tipo_pago: 2,porcentaje: restoPagos[0].porcentaje},
        tipoPago.includes(3) && {tipo_pago: 3,porcentaje: restoPagos[1].porcentaje},
      ]
      const filterData = data.filter(v => v != false);
      values.pago = filterData;
      mutate(values, {
        onSuccess: (data) => {
          formik.resetForm();
          setImagePreview(null);
          SwalToast.fire({
            icon: "success",
            title: "Docente creado correctamente",
          });
        },
      });
    },
  });

  //Cambia la imagen de perfil
  const handleChangeImage = (e) => {
    e.preventDefault();
    setLoading(true);
    if (imgInputRef.current.files.length > 0) {
      //validar solo imagenes
      const file = e.target.files[0];
      if (file.type.indexOf("image") < 0) {
        setLoading(false);
        return;
      }
      // console.log(file);
      setImagePreview(URL.createObjectURL(file));
      formik.setFieldValue("imagen", file);
      setLoading(false);
    }
  };

  const [restoPagos, setRestoPagos] = useState([
    { tipo_pago: null, porcentaje: null },
    { tipo_pago: null, porcentaje: null },
  ]);
  
  const handleRestoPagos = (e,id,index) => {
    const {value} = e.target;
    const pago = [...restoPagos]
    pago[index].porcentaje = value;
    pago[index].tipo_pago = id;
    setRestoPagos(pago);
  }

  return (
    <LayoutAdmin contentTitle="" routeTitle="Crear Docente">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header ge-noborder">
              <>
                <img
                  src="/images/file-icon.png"
                  className="mr-2"
                  style={{ width: "40px" }}
                  alt="file-icon"
                />
                <span className="ge-h3 ge-title">Crear docente</span>
              </>
            </div>
            <div className="card-body">
              <Form onSubmit={formik.handleSubmit}>
                {/* Img perfil */}
                <div className="d-flex flex-column flex-md-row w-100 mb-2">
                  <div className="col-12 col-md-4">
                    <div className="d-flex align-items-center img-form-preview">
                      <img
                        src={
                          !imagePreview
                            ? "/images/gestora-placeholder.png"
                            : imagePreview
                        }
                        alt="..."
                        style={{
                          objectFit: "cover",
                          maxWidth: "100%",
                          width: "100%",
                          borderRadius: "10px",
                        }}
                      />
                      <div className="btn-ref-file">
                        <input
                          type="file"
                          ref={imgInputRef}
                          onChange={(e) => {
                            handleChangeImage(e);
                          }}
                          className="d-none"
                          disabled={loading}
                          accept="image/*"
                        />
                        {loading ? (
                          <span>
                            <Spinner
                              animation="border"
                              variant="primary"
                              className="mt-2"
                            />
                          </span>
                        ) : (
                          <span
                            className="img-profile-button text-center p-1"
                            onClick={() => imgInputRef.current.click()}
                          >
                            <FaIcons.FaPencilAlt />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-8">
                    <div className="row">
                      <Form.Group
                        controlId="formBasicName"
                        className="col-12 col-lg-6 my-2"
                      >
                        <Form.Label>Nombres</Form.Label>
                        <Form.Control
                          className="ge-form"
                          type="text"
                          placeholder="Nombres"
                          name="nombres"
                          onChange={formik.handleChange}
                          value={formik.values.nombres}
                          autoComplete="off"
                          isValid={
                            formik.touched.nombres && !formik.errors.nombres
                          }
                          isInvalid={
                            formik.touched.nombres && !!formik.errors.nombres
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.nombres}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        controlId="formBasicLastName"
                        className="col-12 col-lg-6 my-2"
                      >
                        <Form.Label>Apellidos</Form.Label>
                        <Form.Control
                          className="ge-form"
                          type="text"
                          placeholder="Apellidos"
                          name="apellidos"
                          autoComplete="off"
                          onChange={formik.handleChange}
                          value={formik.values.apellidos}
                          isValid={
                            formik.touched.apellidos && !formik.errors.apellidos
                          }
                          isInvalid={
                            formik.touched.apellidos &&
                            !!formik.errors.apellidos
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.apellidos}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        controlId="formBasicEmail"
                        className="col-md-6 my-2"
                      >
                        <Form.Label>Correo</Form.Label>
                        <Form.Control
                          className="ge-form"
                          type="email"
                          placeholder="Correo"
                          name="correo"
                          onChange={formik.handleChange}
                          value={formik.values.correo}
                          isValid={
                            formik.touched.correo && !formik.errors.correo
                          }
                          isInvalid={
                            formik.touched.correo && !!formik.errors.correo
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.correo}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        controlId="formBasicPhone"
                        className="col-md-6 my-2"
                      >
                        <Form.Label>Teléfono</Form.Label>
                        <PhoneInput
                          regions={["south-america"]}
                          country={"pe"}
                          value={formik.values.telefono}
                          onChange={(phone, country) => {
                            formik.setFieldValue("telefono", phone);
                            formik.setFieldValue(
                              "pais",
                              country.name.toUpperCase()
                            );
                          }}
                          autoFormat
                          inputClass="form-control my-1 w-100"
                          countryCodeEditable={false}
                          masks={{
                            pe: "... ... ...",
                          }}
                          isValid={(value, country) => {
                            if (
                              formik.touched.telefono &&
                              !!formik.errors.telefono
                            ) {
                              return "Número de celular inválido";
                            } else {
                              return true;
                            }
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="formBasicEscuela"
                        className="col-12 col-md-6 my-2"
                      >
                        <Form.Label>Escuela</Form.Label>
                        <Form.Select
                          className="form-control ge-form"
                          name="escuela"
                          onChange={formik.handleChange}
                          value={formik.values.escuela}
                          isValid={
                            formik.touched.escuela && !formik.errors.escuela
                          }
                          isInvalid={
                            formik.touched.escuela && !!formik.errors.escuela
                          }
                        >
                          <option value="">Seleccione una escuela</option>
                          {escuelaSelect &&
                            escuelaSelect.map((escuela) => (
                              <option
                                value={escuela.id_escuela}
                                key={escuela.id_escuela}
                              >
                                {`${escuela.nombre_escuela}`}
                              </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.escuela}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="col-md-5 my-2"
                      >
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control
                          className="ge-form"
                          type={shown ? "text" : "password"}
                          placeholder="Contraseña"
                          name="password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          isValid={
                            formik.touched.password && !formik.errors.password
                          }
                          isInvalid={
                            formik.touched.password && !!formik.errors.password
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div className="align-items-end d-flex justify-content-center mb-2">
                        <button
                          className="btn ge-btn-primary"
                          type="button"
                          onClick={switchShown}
                          onSubmit={false}
                        >
                          {shown ? <FaIcons.FaEyeSlash /> : <FaIcons.FaEye />}
                        </button>
                      </div>
                      <Form.Group 
												className="col-md-6 my-2"												
												>
												<Form.Label>Video de Presentación</Form.Label>
												<Form.Control 
													className="ge-form"
													type="text" 
													placeholder="Ej: https://www.youtube.com" 
													name="video_presentacion"
													onChange={formik.handleChange}
													value={formik.values.video_presentacion}
													isValid={
														formik.touched.video_presentacion &&
														!formik.errors.video_presentacion
													}
													isInvalid={
														formik.touched.video_presentacion &&
														!!formik.errors.video_presentacion
													}
												/>
												<Form.Control.Feedback type="invalid">
													{formik.errors.video_presentacion}
												</Form.Control.Feedback>
												<Form.Text className="text-muted">
												Ingrese el enlace del video de presentación que se vera en la plataforma de usuario
												</Form.Text>
											</Form.Group>
											<Form.Group
												className="col-md-6 my-2"
											>
												<Form.Label>LinkedIn</Form.Label>
												<Form.Control
													className="ge-form"
													type="text"
													placeholder="Ej: https://www.linkedin.com/in/ricardo-santos-987b963d52"
													name="linkedinDocente"
													onChange={formik.handleChange}
													value={formik.values.linkedinDocente}
													isValid={
														formik.touched.linkedinDocente &&
														!formik.errors.linkedinDocente
													}
													isInvalid={
														formik.touched.linkedinDocente &&
														!!formik.errors.linkedinDocente
													}
												/>
												<Form.Control.Feedback type="invalid">
													{formik.errors.linkedinDocente}
												</Form.Control.Feedback>
											</Form.Group>
                      <Form.Group
                        controlId="formBasicDescription"
                        className="col-md-12 my-2"
                      >
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                          as="textarea"
                          className="ge-form"
                          rows="3"
                          placeholder="Descripción"
                          name="descripcion"
                          onChange={formik.handleChange}
                          value={formik.values.descripcion}
                          isValid={
                            formik.touched.descripcion &&
                            !formik.errors.descripcion
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.descripcion}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="d-flex col-md-12 justify-content-center align-items-center mt-4">
                  <div className="d-flex flex-column justify-content-center align-items-center col-md-4">
                    <Form.Check
                      type="checkbox"
                      label="Pago fijo"
                      value={1}
                      onChange={() => checkPagos(1)}
                    />
                    {tipoPago.includes(1) &&
                      pagos.map((value, index) => (
                        <div
                          className="d-flex justify-content-around col-md-12 p-2"
                          key={index}
                        >
                          <Form.Control
                            className="ge-form"
                            style={{ maxWidth: "20%" }}
                            placeholder="Monto"
                            name="monto"
                            onChange={(e) => handleInputChange(e, index)}
                          />
                          <Form.Select
                            className="ge-form form-control"
                            style={{ maxWidth: "65%" }}
                            name="id_curso"
                            onChange={(e) => handleInputChange(e, index)}
                          >
                            <option value="">Seleccione un Curso</option>
                            {cursosSelect &&
                              cursosSelect.map((curso) => (
                                <option value={curso.id} key={curso.id}>
                                  {`${curso.nombre}`}
                                </option>
                              ))}
                          </Form.Select>
                          {index === 0 ? (
                            <span
                              className="d-flex align-items-center justify-content-center ml-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => addInput()}
                            >
                              <FaIcons.FaPlusCircle
                                color="#842eff"
                                size="20px"
                              />
                            </span>
                          ) : (
                            <span
                              className="d-flex align-items-center justify-content-center ml-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => removeInput(index)}
                            >
                              <FaIcons.FaTrash color="red" size="20px" />
                            </span>
                          )}
                        </div>
                      ))}
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center col-md-4">
                    <Form.Check
                      type="checkbox"
                      label="% de Venta Directa de Cursos"
                      onChange={() => checkPagos(2)}
                    />
                    {tipoPago.includes(2) && (
                      <div className="d-flex justify-content-around col-md-12 p-2">
                        <Form.Control
                          className="ge-form"
                          style={{ maxWidth: "40%" }}
                          placeholder="Porcentaje %"
                          onChange={(e) => handleRestoPagos(e,2,0)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center col-md-4">
                    <Form.Check
                      type="checkbox"
                      label="% de Ventas Membresías"
                      onChange={() => checkPagos(3)}
                    />
                    {tipoPago.includes(3) && (
                      <div className="d-flex justify-content-around col-md-12 p-2">
                        <Form.Control
                          className="ge-form"
                          style={{ maxWidth: "40%" }}
                          placeholder="Porcentaje %"
                          onChange={(e) => handleRestoPagos(e,3,1)}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn ge-btn-primary btn-block my-2 mt-md-4"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Crear docente"
                  )}
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default CreateDocenteScreen;