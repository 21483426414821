import React, { useEffect, useRef, useState } from 'react';
import EditorGeneral from "../../components/Editor/EditorGeneral";
import { useMutation,useQueryClient, useQuery } from "react-query";
import { useFormik } from "formik";
import * as yup from "yup";
import { createHistoria, updateHistoria } from '../../controllers/historias/historias';
import * as FaIcons from 'react-icons/fa'
import { Spinner } from "react-bootstrap";
import { getSelectUsers } from '../../controllers/blog/posts';
import CustomSelectUsuariosFormik from '../../components/Custom/CustomSelectUsuariosFormik';
import { getCursos } from '../../controllers/rutas/rutas';
import CustomSelectCursos from '../../components/Custom/CustomSelectCursos';
import HistoriasCursos from './HistoriasCursos';
const FormularioHistoriaContenido = ({datosContenido=null,isNew=false}) => {

    const {data : listaUsers,isLoading} = useQuery(['UsersSelect'],getSelectUsers,{
		refetchOnWindowFocus:false,
	})

    const { data, isLoading : isLoadingCursos } = useQuery(["getCursos"], getCursos, {
		refetchOnWindowFocus: false,
	});
    
    const queryClient = useQueryClient()
    
    const {mutate:insertarHistoria, error : errorCreate , isLoading : isLoadingCreate } = useMutation(createHistoria,{
        onSuccess: ()=>{
            queryClient.invalidateQueries(["historias"])
            queryClient.refetchQueries(["getHistoria"])
        }
    })

    const {mutate:actualizarHistoria, error : errorUpdate , isLoading : isLoadingUpdate } = useMutation(updateHistoria,{
        onSuccess: ()=>{
            queryClient.invalidateQueries(["historias"])
            queryClient.refetchQueries(["getHistoria"])
        }
    })

    const initialValues = {
        unique_id:'',
        titulo:'',
        imagen:'',
        autor:'',
        tipo_historia:1,
        cursos:[],
        archivo:{}
	};
	
    const [datosPost,setDatosPost]=useState(initialValues)
    const [contenidoHTML,setContenidoHTML] = useState('')

    const [loading, setLoading] = useState(false);
	const [imagePreview, setImagePreview] = useState(null);
	const imgInputRef = useRef();

    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png",
        "application/pdf"
      ];

	const validationSchema = yup.object({
		titulo: yup
			.string()
			.required("El campo es requerido"),
        archivo: yup
            .mixed()
            .notRequired()
            .test(
              "fileFormat",
              "Archivo no permitido",
              value => value ? SUPPORTED_FORMATS.includes(value.type) : true
            ),
        autor: yup
            .string()
            .required("El campo es requerido"),
        cursos: yup
            .mixed()
            .required('Debes seleccionar un curso como mínimo')
            .test(
              "cursosNumber",
              "Debes seleccionar un curso",
              value => value ? value.length>0 : true
            ),
	});
	
	const formik = useFormik({
		initialValues:datosPost,
		validationSchema,
		onSubmit: async (values) => {
            const datos = {...values}
            datos.contenido=contenidoHTML
            datos.cursos = values.cursos.map((curso)=>({id_curso:curso.id_curso}))
             if(!isNew)
			actualizarHistoria(datos)
            else
            insertarHistoria(datos)   
		},
	});

   useEffect(()=>{
        if(datosContenido)
        {    
            setDatosPost(datosContenido)
            setContenidoHTML(datosContenido.contenido)
        }
    },[datosContenido])

    useEffect(()=>{
        if(datosPost.titulo!=='')
        {
            formik.setValues(datosPost)
            setImagePreview(datosPost.imagen!=='' ? process.env.REACT_APP_API_URL+datosPost.imagen:null)
        }
    },[datosPost])

    	//Cambia la imagen de portada
	const handleChangeImage = (e) => {
		e.preventDefault();
		setLoading(true);
		if (imgInputRef.current.files.length > 0) {
			//validar solo imagenes
			const file = e.target.files[0];
			if (file.type.indexOf("image") < 0) {
				setLoading(false);
				return;
			}
			 //console.log(file);
			setImagePreview(URL.createObjectURL(file));
			formik.setFieldValue("archivo", file);
			setLoading(false);
		}
	};



    return (
        <form onSubmit={formik.handleSubmit}>
        <div className='row'>
             <div className='col-md-12'>
                 <div className="card">
                     <div className="card-header p-3" style={{backgroundColor:'#842eff', color:'#fff'}}>
                         <h4>Datos de publicación</h4>
                     </div>
                     <div className="card-body">
                         <div className="row">
                             <div className="col-lg-4 p-2">
                                 <div className="form-group mb-3">
                                     <div className="img-portada-container">
                                         <label>Imagen de portada</label>
                                         <img
                                             src={
                                                 !imagePreview
                                                     ? "https://via.placeholder.com/340x120?text=Gestora+Portada+Post"
                                                     : imagePreview
                                             }
                                             alt="..."
                                             className="img-profile"
                                         />
                                         <div className="button-container">
                                             <input
                                                 type="file"
                                                 ref={imgInputRef}
                                                 onChange={(e) => {
                                                     handleChangeImage(e);
                                                 }}
                                                 className="d-none"
                                                 disabled={loading}
                                                 name="archivo"
                                             />
                                          
                                             {loading ? (
                                                 <span>
                                                     <Spinner
                                                         animation="border"
                                                         variant="primary"
                                                         className="mt-2"
                                                     />
                                                 </span>
                                             ) : (
                                                 <span
                                                     className="img-profile-button text-center"
                                                     onClick={() =>
                                                         imgInputRef.current.click()
                                                     }
                                                 >
                                                     <FaIcons.FaPencilAlt />
                                                 </span>
                                             )}
                                         </div>
                                     </div>
                                     <p> {formik.errors.archivo} </p>
                                 </div>
                             </div>
                             <div className="col-lg-8 p-2">
                                 <div className="form-group mb-3">
                                         <label>
                                             Titulo
                                         </label>
                                         <input
                                             type="text"
                                             className="form-control"
                                             placeholder="Titulo"
                                             name="titulo"
                                             onChange={formik.handleChange}
                                             value={formik.values.titulo}
                                         />
                                         <p> {formik.errors.titulo} </p>
                                </div>  
                                 <div className="form-group mb-3">
                                        <label>Autor:</label>
                                         <CustomSelectUsuariosFormik options={listaUsers} onChange={value=>formik.setFieldValue('autor',value.id_user)} value={formik.values.autor} isLoading={isLoading} />
                                         <p> {formik.errors.autor} </p>
                                 </div> 
                        {   !datosContenido ?  <div className="form-group mb-3">
                                        <CustomSelectCursos
                                            className=""
                                            onChange={(value) =>
                                                formik.setFieldValue("cursos", value)
                                            }
                                            value={formik.values.cursos}
                                            options={data}
                                            isMulti={true}
                                        />
                                        <p> {formik.errors.cursos} </p>   
                                 </div> : null }
                                                      
                             </div>
                         </div>
                     </div>
                 </div>        
             </div>
             <div className='col-md-12'>
              {datosContenido && <HistoriasCursos cursos={datosContenido.cursos} id_historia={datosContenido.id_historia} /> }
             </div>
              <div className='col-md-12'>
                 <div className="card">
                     <div className="card-body">
                         <div className="row">
                             <div className="p-1">
                             <h4><b>Cuerpo de la publicación</b></h4>
                             </div>
                             <div className="col-md-12 mb-2"><EditorGeneral contenido={formik.values.contenido} onChange={setContenidoHTML} /></div>
                         </div>
                   
                     </div>
                 </div>        
             </div>
             <div className='col-md-12'>
                 <div className="col-md-12 d-flex justify-content-end my-3">
                                 <button
                                     type="submit"
                                     className="btn ge-btn-primary"
                                 >
                                     Guardar
                                 </button>
                 </div>       
             </div>
        </div>
     </form>
    );
};

export default FormularioHistoriaContenido;