import dayjs from "dayjs";
import { getAuthAxios } from "../../../axios/axiosCustom";


export const getNumberOfVisits = async (fecha_inicio, fecha_fin) => {
    try{
        const fecha_inicio_formateada = dayjs(fecha_inicio).format('YYYY-MM-DD');
        const fecha_fin_formateada = dayjs(fecha_fin).format('YYYY-MM-DD');
        
        const axios = getAuthAxios();
        const response = await axios.get(`/analytics/cantidad_visitas/${fecha_inicio_formateada}/${fecha_fin_formateada}`);
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}

export const getPercentageofRebound = async (fecha_inicio, fecha_fin) => {
    try{
        const fecha_inicio_formateada = dayjs(fecha_inicio).format('YYYY-MM-DD');
        const fecha_fin_formateada = dayjs(fecha_fin).format('YYYY-MM-DD');
        
        const axios = getAuthAxios();
        const response = await axios.get(`/analytics/rebote_porcent/${fecha_inicio_formateada}/${fecha_fin_formateada}`);
        
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}


export const getOrganicTraffic = async (fecha_inicio, fecha_fin) => {
    try{
        const fecha_inicio_formateada = dayjs(fecha_inicio).format('YYYY-MM-DD');
        const fecha_fin_formateada = dayjs(fecha_fin).format('YYYY-MM-DD');
        
        const axios = getAuthAxios();
        const response = await axios.get(`/analytics/trafico_organico/${fecha_inicio_formateada}/${fecha_fin_formateada}`);
        
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}


export const getReferralTraffic = async (fecha_inicio, fecha_fin) => {
    try{
        const fecha_inicio_formateada = dayjs(fecha_inicio).format('YYYY-MM-DD');
        const fecha_fin_formateada = dayjs(fecha_fin).format('YYYY-MM-DD');
        
        const axios = getAuthAxios();
        const response = await axios.get(`/analytics/trafico_referido/${fecha_inicio_formateada}/${fecha_fin_formateada}`);
        
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}