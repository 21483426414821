import React, { useMemo, useState } from "react";
import { Badge, Form } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import DataTable from "react-data-table-component";
import HeaderSearchTable from "../../../components/Custom/HeaderSearchTable";
import LoaderSmall from "../../../components/Loaders/LoaderSmall";
import { useMutateActiveModule, useMutateDeleteModuloById, useQueryModulesByCourse } from "../../../hooks/curso-modulos.hooks";
import ModalCreateModule from "../../../components/Cursos/Modulos/ModalCreateModule";
import { SwalToast } from "../../../utils/toasts";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const ListaModulosScreen = ({ idCourse,slugCurso }) => {
	
	const [filteredModules, setFilteredModules] = useState([]);
	// const [idCourse_, setIdCourse_] = useState(idCourse);
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
    const [showModalCreateModule, setShowModalCreateModule] = useState(false);
	const navigate = useNavigate();
	const { data: modulesList, isLoading } = useQueryModulesByCourse(idCourse);
	const {mutate: mutateToggleModule, isLoading: loadingToggle} = useMutateActiveModule(idCourse);
    
	const handleEditModule = (idModule) => {
		// console.log(idModule,idCourse);
		navigate(`/editar-modulo/${idModule}`,{
      state: {
        slugCurso: slugCurso,
        idCourse: idCourse
      }
    });
	}
	
	/* Traer datos del Redux */
	const { user } = useSelector(( state ) => state.user)

	const {mutate: mutateDeleteModulo } = useMutateDeleteModuloById();
	
	const handleToggleActiveModule = (idModule, active) => {
		Swal.fire({
			title: '¿Estás seguro?',
			text: "Cambiar el estado del módulo se verá reflejado en el sistema",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, cambiar!',
			cancelButtonText: 'Cancelar'
		}).then((result) => {
			if (result.isConfirmed) {
				mutateToggleModule({
					idModule,
					active
				},{
					onSuccess: () => {
						SwalToast.fire({
							icon: 'success',
							title: 'Estado cambiado con éxito',
						});
					},
					onError: () => {
						SwalToast.fire({
							icon: 'error',
							title: 'Error al cambiar el estado.',
						});
					}
				});
			}
		})
	}
    
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "#",
				selector: (row) => row.id_modulo,
				width: "10%",
			},
			{
				name: "Nombre del módulo",
				// width: '250px',
				selector: (row) => row.nombre_modulo,
				sortable: true,
			},
			{
				name: "Estado",
                width: "100px",
				cell: (row) =>
					parseInt(row.estado) === 1 ? (
						<Badge className="ge-deg-primary p-1">Activo</Badge>
					) : (
						<Badge className="modulo-inactivo">Inactivo</Badge>
					),
			},
			{
				name: "Acciones",
				width: "100px",
				cell: (row) => (
					<div className="d-flex">
						<button 
							className="btn ge-btn-primary btn-sm mr-2"
							onClick={() => handleEditModule(row.id_modulo)}
						>
							<FaIcons.FaCogs />
						</button>
                        {
							parseInt(row.estado) === 1 ?(
								<button 
									className="btn btn-light btn-sm"
									onClick={() => handleToggleActiveModule(row.id_modulo, 0)}
								>
									<BsIcons.BsToggleOn color="#37af48" size={20} />
								</button>
							):(
								<button 
									className="btn btn-light btn-sm"
									onClick={() => handleToggleActiveModule(row.id_modulo, 1)}
									>
									<BsIcons.BsToggleOff color="#ff0000" size={20} />
								</button>
							)
						}
						{ user.roles.includes("2") && (
						<button
							className="btn btn-danger btn-sm ml-2"
							onClick={()=>  handleDeleteModuloByAdmin(row.id_modulo)}
						>
							<FaIcons.FaTrash />
						</button>
						)}
					</div>
				),
			},
		],
		[]
	);

	const handleDeleteModuloByAdmin = (id_modulo) => {
		mutateDeleteModulo(id_modulo)
	};
		
	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={modulesList}
				setArrayFiltered={setFilteredModules}
				setResetPagination={setResetPagination}
				placeholder="Buscar por nombre"
				columnsToFilter={["nombre_modulo"]}
			/>
		);
	}, [filterText, resetPagination]);
    
	return (
		<div className="card p-2">
            {
                showModalCreateModule && (
                    <ModalCreateModule
                        showModal={showModalCreateModule}
                        setShowModal={setShowModalCreateModule}
                        idCourse={idCourse}
                    />
                )
            }
			<div className="d-flex justify-content-between card-header card-no-after ge-noborder">
				<div>
					<span className="ge-h3 ge-title">Módulos</span>
				</div>
				<div className="">
					<button 
                        className="btn ge-btn-primary btn-sm"
                        onClick={() => setShowModalCreateModule(true)}
                    >
						<FaIcons.FaPlus />
						<span className="ml-2">Nuevo módulo</span>
					</button>
				</div>
			</div>
			<div className="card-body">
				<DataTable
					className="custom-datatable"
					columns={columns}
					data={
						filteredModules.length > 0
							? filteredModules
							: modulesList
					}
					subHeader
					subHeaderComponent={searchComponentMemo}
					pagination
					responsive
					progressPending={isLoading}
					progressComponent={<LoaderSmall />}
                    noDataComponent={<div className="w-100 text-center">No hay módulos, crea uno.</div>}
				/>
			</div>
		</div>
	);
};

export default ListaModulosScreen;
