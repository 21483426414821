import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import * as yup from 'yup';
import DarkModal from '../Custom/DarkModal';
import { useMutateCreateClass } from '../../hooks/clase.hooks';
import { useParams } from 'react-router-dom';
import { SwalToast } from '../../utils/toasts';
import ReactDatePicker from 'react-datepicker';
import CustomInputDate from '../Custom/CustomInputDate';
import { filterPassedTime } from '../../utils/time';
import { YOUTUBE_REGEX, ZOOM_REGEX } from '../../utils/regex';
import ReactPlayer from 'react-player';
import {
  useMutateEditCurso,
  useQueryDuracionCurso,
} from '../../hooks/cursos.hooks';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';

const validationSchema = yup.object({
  titulo: yup.string().min(2).required('El nombre es requerido'),
  urlVideo: yup
    .string()
    .url('Ingrese una URL válida.')
    .when('live', {
      is: 1,
      then: yup
        .string()
        .url('Ingrese una URL válida.')
        .required('La URL es requerida')
        .test('url-zoom', 'Ingrese una URL válida de zoom', (value) => {
          return ZOOM_REGEX.test(value);
        }),
      otherwise: yup
        .string()
        .url('Ingrese una URL válida.')
        .required('La URL es requerida'),
    }),

  duracion: yup.number(),
  claseAbierta: yup.number().required('Seleccione una opción'),
  live: yup.number().required('Seleccione una opción'),
  fecha_inicio: yup.date().when('live', {
    is: 1,
    then: yup.date().required('La fecha de inicio es requerida'),
    otherwise: yup.date().notRequired(),
  }),
});

const ModalAddClass = ({ show, setShow, idCourse, dataCourse }) => {
  const [minutos, setMinutos] = useState(0);
  const [segundos, setSegundos] = useState(0);
  const [duration, setDuration] = useState();
  const { idModulo } = useParams();
  const { mutate: mutateCreateClass, isLoading } =
    useMutateCreateClass(idModulo);
  const initialValues = {
    titulo: '',
    urlVideo: '',
    duracion: 0.0,
    claseAbierta: 1,
    live: 0,
    fecha_inicio: new Date(),
  };

  const agregarCeros = (number) => {
    if (number >= 10) {
      return number;
    } else {
      return '0' + number;
    }
  };

  const handleDuration = (duration) => {
    const seconds = Math.round(duration % 0x3c).toString();
    const minutos = (Math.floor(duration / 0x3c) % 0x3c).toString();
    setMinutos(agregarCeros(minutos));
    setSegundos(agregarCeros(seconds));
    setDuration(parseInt(minutos));
  };

  const { mutate: mutateEditCurso } = useMutateEditCurso();

  const {
    data: totalDuracion,
    isLoading: isLoading2,
    refetch: refetchDuracionCurso,
  } = useQueryDuracionCurso(idCourse);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      // actualiza la duracion total del curso
      await refetchDuracionCurso();
      const dataCurso = {
        duracion: ((Number(totalDuracion) + Number(duration)) / 60)
          .toFixed(2)
          .toString(),
      };

      fetch(
        `${process.env.REACT_APP_API_URL}curso/edit_duracion_curso/${idCourse}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataCurso),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          // Maneja la respuesta del servidor
          // console.log('Respuesta del servidor:', data);
        })
        .catch((error) => {
          // Maneja cualquier error ocurrido durante la solicitud
          console.error('Error:', error);
        });

      // crea la clase despues de modificar la duracion total del curso
      values.duracion = duration;
      mutateCreateClass(
        {
          classData: values,
          idModule: idModulo,
        },
        {
          onSuccess: (data) => {
            formik.resetForm();
            // setFiles([]);
            setShow(false);
            SwalToast.fire({
              icon: 'success',
              title: 'Clase creada correctamente',
            });
          },
          onError: (error) => {
            SwalToast.fire({
              icon: 'error',
              title: 'Error al crear la clase',
            });
          },
        }
      );
    },
  });

  const handleCloseModal = () => {
    formik.resetForm();
    setShow(false);
  };

  return (
    <DarkModal
      showModal={show}
      onHide={handleCloseModal}
      title={`Crear clase`}
      headerProps={{
        className: 'ge-bg-primary text-white',
      }}>
      <Form className='row' onSubmit={formik.handleSubmit}>
        <Form.Group className='col-12 my-2'>
          <Form.Label>Nombre de la clase: *</Form.Label>
          <Form.Control
            type='text'
            placeholder='Nombre'
            name='titulo'
            onChange={formik.handleChange}
            value={formik.values.titulo}
            autoComplete='off'
            isValid={formik.touched.titulo && !formik.errors.titulo}
            isInvalid={formik.touched.titulo && !!formik.errors.titulo}
          />
          <Form.Control.Feedback type='invalid'>
            {formik.errors.titulo}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='col-12 my-2'>
          <Form.Label>
            {formik.values.live === 1 ? 'URL del zoom: *' : 'URL del video: *'}
          </Form.Label>
          <Form.Control
            type='text'
            placeholder={formik.values.live === 1 ? 'Zoom' : 'Video'}
            name='urlVideo'
            onChange={formik.handleChange}
            value={formik.values.urlVideo}
            autoComplete='off'
            isValid={formik.touched.urlVideo && !formik.errors.urlVideo}
            isInvalid={formik.touched.urlVideo && !!formik.errors.urlVideo}
          />
          <Form.Control.Feedback type='invalid'>
            {formik.errors.urlVideo}
          </Form.Control.Feedback>
        </Form.Group>
        {formik.values.live === 0 && (
          <div className='col-12 my-2 d-inline-block'>
            <span className='font-weight-bold'>Duración del video: </span>
            <span className='txt-ge-muted'>({minutos + ':' + segundos})</span>
            {formik.values.urlVideo && (
              <div className='mt-2'>
                <ReactPlayer
                  url={formik.values.urlVideo}
                  width='240px'
                  height='140px'
                  onDuration={handleDuration}
                />
              </div>
            )}
          </div>
        )}
        {formik.values.live === 1 && (
          <Form.Group className='col-12 my-2'>
            <Form.Label>Fecha de inicio:</Form.Label>
            <ReactDatePicker
              locale={'es'}
              selected={formik.values.fecha_inicio}
              onChange={(date) => {
                formik.setFieldValue('fecha_inicio', date);
              }}
              isClearable={true}
              dateFormat='dd/MM/yyyy h:mm aa'
              showTimeInput
              timeInputLabel='Hora'
              filterTime={filterPassedTime}
              minDate={new Date()}
              customInput={<CustomInputDate />}
              name='fecha_inicio'
            />
            {formik.touched.fecha_inicio && !!formik.errors.fecha_inicio && (
              <div className='invalid-feedback'>
                {formik.errors.fecha_inicio}
              </div>
            )}
          </Form.Group>
        )}
        <Form.Group className='col-12 col-md-6 my-2'>
          <Form.Label className='mr-3'>¿Es clase abierta?:</Form.Label>
          <Form.Check
            type='radio'
            label='Si'
            name='claseAbierta'
            id='claseAbiertaSi'
            className='ml-3'
            value={1}
            onChange={() => formik.setFieldValue('claseAbierta', 1)}
            checked={formik.values.claseAbierta}
          />
          <Form.Check
            type='radio'
            label='No'
            name='claseAbierta'
            id='claseAbiertaNo'
            className='ml-3'
            value={0}
            onChange={() => formik.setFieldValue('claseAbierta', 0)}
            checked={!formik.values.claseAbierta}
          />
        </Form.Group>
        <Form.Group className='col-12 col-md-6 my-2'>
          <Form.Label className='mr-3'>¿Es clase en vivo?:</Form.Label>
          <Form.Check
            type='radio'
            label='Si'
            name='live'
            id='liveSi'
            className='ml-3'
            value={1}
            onChange={() => formik.setFieldValue('live', 1)}
            checked={formik.values.live === 1}
          />
          <Form.Check
            type='radio'
            label='No'
            id='liveNo'
            name='live'
            className='ml-3'
            value={0}
            onChange={() => formik.setFieldValue('live', 0)}
            checked={formik.values.live === 0}
          />
        </Form.Group>
        <div className='d-flex w-100 justify-content-around'>
          <button
            type='submit'
            className='btn ge-btn-primary my-2'
            disabled={isLoading}>
            {isLoading ? (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            ) : (
              'Crear clase'
            )}
          </button>
          <button
            type='button'
            className='btn btn-secondary my-2'
            disabled={isLoading}
            onClick={handleCloseModal}>
            Cancelar
          </button>
        </div>
      </Form>
    </DarkModal>
  );
};

export default ModalAddClass;
