import React from 'react';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import ResumenHistorias from './ResumenHistorias';
import ResumenPosts from './ResumenPosts';


const DashboardPH = () => {


    return (
        <LayoutAdmin
        contentTitle="Dashboard" routeTitle="Historias y Posts"
        >
            <ResumenPosts />
            <ResumenHistorias />

        </LayoutAdmin>
    );
};

export default DashboardPH;