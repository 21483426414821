import dayjs from "dayjs";
import { getAuthAxios } from "../../axios/axiosCustom";
import { toastErrors } from "../../utils/react_query/manageErrors";

export const getConvocatorias = async () => {
    
    const axios = getAuthAxios();
    
    try{
        const response = await axios.get('/convocatorias_laborales/traer_convocatorias_admin');
        
        if(response.data.status){
            return response.data.data;
        }
        throw new Error(response.data);
    }catch(error){
        console.log(error);
    }
    
}



export const createConvocatoria = async (data) => {
    const axios = getAuthAxios();
    
    const data_ = {
        ...data,
        fecha_fin: dayjs(data.fecha_fin).format("YYYY-MM-DD HH:mm:ss"),
    }
    
    try{
        const response = await axios.post('/convocatorias_laborales/crear_convocatoria', data_);
        
        if(response.data.status){
            return response.data;
        }
        throw new Error(response.data);
    }catch(error){
        console.log(error);
    }
}



export const updateConvocatoria = async ({
    id_registro,
    data
}) => {
    const axios = getAuthAxios();
    
    const data_ = {
        ...data,
        fecha_fin: dayjs(data.fecha_fin).format("YYYY-MM-DD HH:mm:ss"),
    }
    
    try{
        const response = await axios.post(`/convocatorias_laborales/actualizar_convocatoria/${id_registro}`, data_);
        
        if(response.data.status){
            return response.data;
        }
        throw new Error(response.data);
    }catch(error){
        console.log(error);
    }
}


export const getConvocatoriaId = async (id_registro) => {
    const axios = getAuthAxios();
    
    try{
        const response = await axios.get(`/convocatorias_laborales/traer_convocatoria/${id_registro}`);
        
        if(response.data.status){
            return response.data.data;
        }
        throw new Error(response.data);
    }catch(error){
        console.log(error);
    }
}

export const togglePublicarConvocatoria = async ({
    id_registro,
    publicado
}) => {
    const axios = getAuthAxios();
    
    try{
        const response = await axios.post(`/convocatorias_laborales/publicar/${id_registro}`,{
            publicado
        });
        
        if(response.data.status){
            return response.data;
        }
        throw new Error(response.data);
    }catch(error){
        console.log(error);
    }
}


export const deleteConvocatoria = async ({ id_registro }) => {
    const axios = getAuthAxios();
    
    try{
        const response = await axios.delete(`/convocatorias_laborales/eliminar/${id_registro}`);
        
        if(response.data.status){
            return response.data;
        }
        throw new Error(response.data);
    }catch(error){
        console.log(error);
    }
}