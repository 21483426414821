import { getAuthAxios } from "../../axios/axiosCustom";
import Swal from "sweetalert2";

export const getAllUsers = async () => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/usuario/getALL_usuario_admin`);
        if(response.data.status){
            return response.data.data;
        }
        throw new Error('Error al obtener los usuarios');
    }catch(e){
        throw new Error('Error del servidor');
    }
};

export const createUserRoles = async (datos) => {
    const axios = getAuthAxios()
    const isAdmin = datos.Roles.includes('2')
    try {
        const response = await axios.post('/usuario/create_usuario_admin', datos)
        if(isAdmin){
          // datos.id_role = 2
          // console.log('admin function, crear usuario en crececongestora',datos,isAdmin);         
          // const response = await axios.post('/usuario/register', datos)
          // console.log(datos.Roles, 'falta arreglar la creacion de la cuenta en crececongestora.com');
        }
        return response.data
        //throw new Error('Error al crear el usuario');
    } catch (error) {
        throw new Error('Error del servidor');
    }
};

export const createFreeAccount = async (datos) => {
    const axios = getAuthAxios()
    try {
        const response = await axios.post('/usuario/registrar_usuario_byAdmin', datos)
        return response.data
    } catch (error) {
        throw new Error('Error del servidor');
    }
};


export const deleteUsuarioRoles = async (id_user) => {
    const axios = getAuthAxios();
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

  await  Swal.fire({
      title: '¿Estás seguro?',
      text: "Una vez eliminado, no se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
          const response = await axios.delete(`/usuario/delete_usuario_admin/${id_user}`);
            
          if(response.status){
              Toast.fire({
                  icon: 'success',
                  title: "Usuario eliminado con exito"
                })
          }
          else{
              Toast.fire({
                  icon: 'warning',
                  title: response.data.message
                })
          }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
      }
    })
}

export const editUser = async (id_user, values) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.put(`/usuario/edit_usuario_admin/${id_user}`, values);
        if(response.data.status){
            return response.data;
        }
        throw new Error('Error al editar la clase');
    }catch(e){
        throw new Error('Error del servidor');
    }
}

export const resetPassword = async ({id_user, values}) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.post(`/usuario/reset_password/${id_user}`, values);
        if(response.data.status){
            return response.data;
        }
        throw new Error('Error al actualizar la password');
    }catch(e){
        throw new Error('Error del servidor');
    }
}