import React from "react";

const AdminFooter = () => {
	
	const actualYear = new Date().getFullYear();
	
	return (
		<footer className="main-footer">
			<strong>
				Copyright Gestora© {" "}{actualYear}
			</strong>
			<div className="float-right d-none d-sm-inline-block">
				<b>Version</b> 1.0.0
			</div>
		</footer>
	);
};

export default AdminFooter;
