import React, { useState } from "react";
import { useMemo } from "react";
import DataTable from "react-data-table-component";
import Rating from "react-rating";
import { useQueryTopDocentes } from "../../hooks/docentes.hooks";
import { FaIcons } from "../../icons/export-reacticons";
import HeaderSearchTable from "../Custom/HeaderSearchTable";
import LoaderSmall from "../Loaders/LoaderSmall";

const TableTopDocentes = () => {
    
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
	const { data, isLoading } = useQueryTopDocentes();
    
	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={data}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
				placeholder="Buscar por nombre del docente"
				columnsToFilter={["nombres", "apellidos"]}
			/>
		);
	}, [filterText, resetPagination]);
    
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "#",
				cell: (row, index) => index + 1,
				width: "10%",
			},
			{
				name: "Nombres",
				selector: (row) => row.nombres,
			},
			{
				name: "Apellidos",
				selector: (row) => row.apellidos,
			},
			{
				name: "Promedio de calificación",
				cell: (row) => (
					<div className="d-flex flex-column align-items-center justify-content-center">
						<span className="text-2xl font-bold">
							{row.promedio_valoraciones}
						</span>
						<Rating
							className="rating-stars mb-1"
							initialRating={parseInt(row.promedio_valoraciones)}
							emptySymbol={
								<FaIcons.FaRegStar
									visibility={"visible"}
									color="#547397"
								/>
							}
							fullSymbol={
								<FaIcons.FaStar
									visibility={"visible"}
									color="#992AFF"
								/>
							}
							fractions={10}
							readonly={true}
						/>
					</div>
				),
			},
		],
		[]
	);
    
	return (
		<div className="card">
			<div className="card-body">
				<div className="row">
					<div className="d-flex align-items-center col-12">
						<img
							src="/images/estudiante.png"
							className="mr-2"
							style={{ width: "25px" }}
							alt="estudiante"
						/>
						<span className="ge-title ge-h3">
							Docentes Destacados
						</span>
					</div>
					<div className="col-12">
						<DataTable
							className="custom-datatable"
							columns={columns}
							data={
								filteredItems.length > 0 ? filteredItems : data
							}
							subHeader
							subHeaderComponent={searchComponentMemo}
							pagination
							responsive
							progressPending={isLoading}
							progressComponent={<LoaderSmall />}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableTopDocentes;
