import { uiTypes } from "../types";


const initialState = {
    isLoading: false,
}


export const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case uiTypes.START_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case uiTypes.STOP_LOADING:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state;
    }
}