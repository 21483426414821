import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { useQuery } from "react-query";
import { getCoursesFromDocente } from "../../../controllers/docentes/docentes";
import _ from "lodash";
import LoaderSmall from "../../Loaders/LoaderSmall";

const CardDocenteCourses = ({
    idDocente = null,
    nombres,
    apellidos,
    imagen,
    eliminado
}) => {
    
    const [courses, setCourses] = useState([]);
    const {data, isLoading, isSuccess} = useQuery(
        ["CoursesDocente", idDocente],
        () => getCoursesFromDocente(idDocente),{
            refetchOnWindowFocus: false,
        }
    )
    
    useEffect(() => {
        if(isSuccess){
            setCourses(data);
        }
    }, [isSuccess, data])
    
    
    if(!idDocente) return null;
	return (
		<div className="card card-docente-courses">
			<div className="card-body">
                {/* Header */}
				<div className="d-flex flex-column flex-md-row col-12 justify-content-md-between">
					{/* Avatar docente */}
					<div className="d-flex flex-column flex-md-row align-items-center">
						<img
							src={
                                !imagen ? `https://ui-avatars.com/api/?name=${nombres}+${apellidos}&background=random`
                                : imagen.includes("files") 
                                    // ? `${process.env.REACT_APP_API_URL}${imagen}`
                                    ? `${process.env.REACT_APP_API_URL}${imagen}`
                                    : imagen
                            }
							className="docente-avatar mr-md-3 mb-3 mb-md-0"
							alt="docente-avatar"
						/>
						<div className="d-flex flex-column align-items-center align-items-md-left">
							<Badge className="ge-bg-primary">
                                {eliminado === 1 ? "Inactivo" : "Activo"}
                            </Badge>
							<span className="ge-title ge-h3">
                                {_.capitalize(nombres)} {_.capitalize(apellidos)}
                            </span>
							<span className="ge-title ge-weight-medium-alt">
								Docente
							</span>
						</div>
					</div>
                    
                    {/* Nro cursos */}
                    <Badge 
                        className="ge-deg-primary p-2 mt-3"
                        style={{
                            alignSelf: "flex-end"
                        }}
                    >
                        {courses.length} {
                            courses.length === 1 ? "Curso" : "Cursos"
                        }
                    </Badge>
				</div>
                {/* Content */}
                <div className="col-12 list-courses mt-3">
                    {
                        isLoading ?(
                            <div className="d-flex justify-content-center align-items-center h-100 w-100">
                                <LoaderSmall />
                            </div>
                        ): courses.length === 0 ? (
                            <div className="d-flex justify-content-center align-items-center h-100 w-100">
                                <span className="ge-title">No tiene cursos</span>
                            </div>
                        ):(
                            courses.map(course => (
                                <div 
                                    className="d-flex mb-3 course-item align-items-center"
                                    key={course.id_curso}
                                >
                                    <img
                                        src="/images/file-icon.png"
                                        className="course-item-icon mr-2"
                                        alt="course-icon"
                                    />
                                    <span className="ge-title">
                                        {course.nombre_curso}
                                    </span>
                                </div>
                            ))
                        )
                    }
                </div>
			</div>
		</div>
	);
};

export default CardDocenteCourses;
