import React from "react";
import { Bar } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);
const BarChart = ({
	datos,
	showTitle = true,
	showLegend = true,
	showGrid = true,
}) => {
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top",
				display: showLegend,
			},
			title: {
				display: showTitle,
				text: datos.title,
				fontSize: 20,
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				grid: {
					display: showGrid,
				},
				ticks:{
					stepSize: 1,
				}
			},
			x: {
				beginAtZero: true,
				grid : {
					display: showGrid,
				}
			}
		},
	};

	const data = {
		labels: datos.labels,
		datasets: [
			{
				label: datos.label,
				data: datos.data,
				backgroundColor: "#842eff",
			},
		],
	};

	return <Bar data={data} options={options} />;
};

export default BarChart;
