import React, { useEffect, useState } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import { useFormik } from "formik";

import * as yup from "yup";
import ReactDatePicker from "react-datepicker";
import CustomInputDate from "../../Custom/CustomInputDate";
import { getSelectCampaigns } from "../../../controllers/marketing/facebook/facebook.controller";
import OverlayIdCampaign from "./OverlayIdCampaign";
import { useMutateUpdateFacebookCampaign, useQueryOneFacebookCampaign } from "../../../hooks/facebook.hooks";
import { SwalToast } from "../../../utils/toasts";
import dayjs from "dayjs";

const validationSchema = yup.object({
    titulo: yup.string().required("El titulo es requerido"),
    id_campaign: yup.string().required("El id de la campaña es requerido"),
    fecha_inicio: yup.date().required("La fecha de inicio es requerida"),
    fecha_fin: yup.date().required("La fecha de fin es requerida"),
    id_tp_campaign: yup.string().required("El tipo de campaña es requerido"),
});

const ModalEditCampaign = ({ show, setShow, idCampaign }) => {
    
    const [selectTypeCampaign, setSelectTypeCampaign] = useState([]);
    const { data, isSuccess, refetch } = useQueryOneFacebookCampaign(idCampaign);
    const { mutate: editCampaign, isLoading } = useMutateUpdateFacebookCampaign(idCampaign);
    
    /* Traer select de tipos de campaña */
    const fetchData = async () => {
        const response = await getSelectCampaigns();
        if(response) {
            setSelectTypeCampaign(response);
        }
    }
    
    
    /* Formik hook */
    const formik = useFormik({
        initialValues: {
            titulo: "",
            id_campaign: "",
            fecha_inicio: new Date(),
            fecha_fin: new Date(),
            id_tp_campaign: "",
        } ,
        validationSchema,
        onSubmit: (values) => {
            
            const valuesEdit = {
                ...values,
                id_registro: idCampaign,
            }
            
            editCampaign(
                valuesEdit,
                {
                    onSuccess: () => {
                        SwalToast.fire({
                            icon: "success",
                            title: "Datos actualizados correctamente",
                        });
                        setShow(false);
                    },
                    onError: (error) => {
                        SwalToast.fire({
                            icon: "error",
                            title: "Error al actualizar los datos",
                        });
                    }
                }
            );
        }
    });
    
    
    useEffect(() => {
        fetchData();
        return () => {
            setSelectTypeCampaign([]);
        }
    } , []);
    
    useEffect(() => {
        if(data) {
            formik.setValues({
                titulo: data.titulo,
                id_campaign: data.id_campaign,
                fecha_inicio: dayjs(data.fecha_inicio).$d,
                fecha_fin: dayjs(data.fecha_fin).$d,
                id_tp_campaign: data.id_tp_campaign,
            });
        }
    } , [data]);
    
    // si el id cambia, se refresca la data
    useEffect(() => {
        if(idCampaign && idCampaign !== "") {
            refetch();
        }
    }, [idCampaign]);
    
	return (
		<Modal
			show={show}
			onHide={()=> setShow(false)}
			backdrop="static"
			size="md"
			centered
		>
            <Modal.Header className="ge-bg-primary text-white" closeButton>
                <h5>Editar campaña</h5>
            </Modal.Header>
			<Modal.Body>
				<Form 
                    className="row"
                    onSubmit={formik.handleSubmit}
                >
                    <Form.Group className="col-12 my-2">
                        <Form.Label className="mb-1">Título:</Form.Label>
                        <Form.Control
                            className="ge-form"
                            type="text" 
                            placeholder="Título de la campaña"
                            autoComplete="off"
                            name="titulo"
                            onChange={formik.handleChange}
                            value={formik.values.titulo}
                            isValid={formik.touched.titulo && !formik.errors.titulo}
                            isInvalid={formik.touched.titulo && formik.errors.titulo}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.titulo}
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group className="col-12 my-2">
                        <Form.Label className="mb-1">ID de la campaña: <OverlayIdCampaign /></Form.Label>
                        <Form.Control
                            className="ge-form"
                            type="text"
                            placeholder="ID de la campaña"
                            name="id_campaign"
                            onChange={formik.handleChange}
                            value={formik.values.id_campaign}
                            isValid={formik.touched.id_campaign && !formik.errors.id_campaign}
                            isInvalid={formik.touched.id_campaign && formik.errors.id_campaign}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.id_campaign}
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group
                        className="col-12 my-2"
                    >
                        <Form.Label className="mb-1">Fecha de inicio:</Form.Label>
                        <ReactDatePicker
                            selected={formik.values.fecha_inicio}
                            onChange={(date) => {
                                formik.setFieldValue("fecha_inicio", date);
                            }}
                            dateFormat="yyyy-MM-dd"
                            customInput={<CustomInputDate />}
                        />
                    </Form.Group>
                    
                    <Form.Group
                        className="col-12 my-2"
                    >
                        <Form.Label className="mb-1">Fecha de fin:</Form.Label>
                        <ReactDatePicker
                            selected={formik.values.fecha_fin}
                            onChange={(date) => {
                                formik.setFieldValue("fecha_fin", date);
                            }}
                            dateFormat="yyyy-MM-dd"
                            customInput={<CustomInputDate />}
                        />
                    </Form.Group>
                    
                    <Form.Group
						className="col-12 my-2"
					>
						<Form.Label className="mb-1">Tipo de campaña</Form.Label>
						<Form.Select
							className="form-control ge-form"
							name="id_tp_campaign"
                            onChange={formik.handleChange}
                            value={formik.values.id_tp_campaign}
                            isValid={formik.touched.id_tp_campaign && !formik.errors.id_tp_campaign}
                            isInvalid={formik.touched.id_tp_campaign && formik.errors.id_tp_campaign}
						>
							<option 
                                value=""
                                disabled
                            >
                                -- Seleccione una campaña --
                            </option>
							{
                                selectTypeCampaign.length > 0 && selectTypeCampaign.map((item, index) => (
                                    <option key={item.id_registro} value={item.id_registro}>{item.nombre}</option>
                                ))
                            }
						</Form.Select>
						<Form.Control.Feedback type="invalid">
							{formik.errors.id_tp_campaign}
						</Form.Control.Feedback>
					</Form.Group>
                    
                    <div className="col-12 my-3 d-flex justify-content-center justify-content-md-end">
                        <button 
                            className="btn ge-btn-primary"
                            type="submit"
                            disabled={isLoading}
                        >
                            {
                                isLoading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </>
                                ) : (
                                    <span>
                                        Guardar cambios
                                    </span>
                                )
                            }
                        </button>
                    </div>
                </Form>
			</Modal.Body>
		</Modal>
	);
};

export default ModalEditCampaign;
