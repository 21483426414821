import { getAuthAxios } from "../../axios/axiosCustom";
import Swal from "sweetalert2";


export const getAllRoles = async () => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/roles/getAll_roles`);
        if(response.data.status){
            return response.data.data;
        }
        throw new Error('Error al obtener los usuarios');
    }catch(e){
        throw new Error('Error del servidor');
    }
};

export const createRoles = async (datos) => {
    const axios = getAuthAxios()
    try {
        const response = await axios.post('/roles/create_rol', datos)
        if(response.data.status){
            return response.data
        }
        throw new Error('Error al crear el usuario');
    } catch (error) {
        throw new Error('Error del servidor');
    }
};

export const editRol = async (id_rol, values) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.put(`/roles/edit_rol/${id_rol}`, values);
        if(response.data.status){
            return response.data;
        }
        throw new Error('Error al editar la clase');
    }catch(e){
        throw new Error('Error del servidor');
    }
}