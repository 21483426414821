import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import DataTable from "react-data-table-component";
import ReactDatePicker from "react-datepicker";
import { useQueryIngresos } from "../../../hooks/contabilidad/ingresos/ingresos.hooks";
import * as FaIcons from 'react-icons/fa'
import CustomInputDate from "../../Custom/CustomInputDate";
import HeaderSearchTable from "../../Custom/HeaderSearchTable";
import LoaderSmall from "../../Loaders/LoaderSmall";

const TablaIngresos = () => {
    
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
	const [startDate, setStartDate] = useState(new Date());
    const { data: ingresosList, isLoading, refetch  } = useQueryIngresos(startDate);
    
	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={ingresosList}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
				placeholder="Buscar por titulo"
				columnsToFilter={["titulo"]}	
			/>
		);
	}, [filterText, resetPagination]);
    
    
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "#",
				cell: (row, index) => index + 1,
				width: "60px"
			},
			{
				name: "ID",
				cell: (row) => row.id_compra,
				width: "60px"
			},
			{
				name: "Nombre y apellidos",
				selector: (row) => row.nombre_apellido,
				sortable: true,
				width: "15%"
			},
			{
				name: "DNI",
				selector: (row) => row.DNI,
			},
			{
				name: "Correo",
				selector: (row) => row.correo,
				width: "15%"
			},
			{
				name: "fecha",
				selector: (row) => new Date(row.fecha).toLocaleDateString()
			},
			{
				name: "Producto/plan",
				selector: (row) => row.curso_o_plan,
				width: "15%"
			},
			{
				name: "Total ingreso",
				selector: (row) => row.total_ingreso,
			},
			{
				name: "Comprobante",
				width: "200px",
				cell: (row) => (
					<a
						href={process.env.REACT_APP_API_URL + row.comprobante}
						target="_blank"
						rel="noreferrer"
						className="text-blue-500"
					>
						<FaIcons.FaFilePdf color="#812cff" size={"26px"}/>
					</a>
				),
			},
		],
		[]
	);
    
	return (
		<div className="card">
			<div className="d-flex justify-content-between card-header card-no-after ge-noborder mt-3">
				<div>
					<img
						src="/images/file-icon.png"
						className="mr-2"
						style={{ width: "40px" }}
						alt="file-icon"
					/>
					<span className="ge-h3 ge-title">Ingresos</span>
				</div>
				{/* <div className="">
					<button
						className="btn ge-btn-deg-primary btn-sm"
						onClick={() => {}}
					>
						<FaIcons.FaPlus />
						<span className="ml-2">Generar ingreso</span>
					</button>
				</div> */}
			</div>
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column flex-md-row justify-content-between align-items-center">
						<div>
							{/* Range Picker */}
							<span className="txt-bold ge-title">Mes:</span>
							<ReactDatePicker
								locale={"es"}
								selected={startDate}
								onChange={(date) => {
									setStartDate(date);
								}}
								isClearable={true}
								placeholderText="Seleccione en mes y año"
								dateFormat="MM/yyyy"
								customInput={<CustomInputDate />}
								showMonthYearPicker
								showFullMonthYearPicker
								showTwoColumnMonthYearPicker
								maxDate={new Date()}
							/>
						</div>
					</div>
					<div className="col-12">
						<DataTable
							className="custom-datatable"
							columns={columns}
							data={
								filteredItems.length > 0
									? filteredItems
									: ingresosList
							}
							subHeader
							subHeaderComponent={searchComponentMemo}
							pagination
							responsive
							progressPending={isLoading}
							progressComponent={<LoaderSmall />}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TablaIngresos;
