import React from "react";
import CardCloseTickets from "../../components/Incidencias/Dashboard/CardCloseTickets";
import CardNewTickets from "../../components/Incidencias/Dashboard/CardNewTickets";
import CardOpenTickets from "../../components/Incidencias/Dashboard/CardOpenTickets";
import CardTimeCloseTicket from "../../components/Incidencias/Dashboard/CardTimeCloseTicket";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";

const DashboardIncidencias = () => {
	return (
		<LayoutAdmin
            contentTitle=""
            routeTitle="Incidencias - Dashboard"
        >
			<div className="row">
                <div className="col-12 col-md-6">
                    <CardNewTickets />
                </div>
                <div className="col-12 col-md-6">
                    <CardCloseTickets />
                </div>
                <div className="col-12 col-md-6">
                    <CardOpenTickets />
                </div>
                <div className="col-12 col-md-6">
                    <CardTimeCloseTicket />
                </div>
            </div>
		</LayoutAdmin>
	);
};

export default DashboardIncidencias;
