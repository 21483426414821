import dayjs from "dayjs";
import { getAuthAxios } from "../../axios/axiosCustom";


export const tasaCancelacion = async (mes) => {
    try{
        const fechaFormateada = dayjs(mes).format('YYYY-MM');
        const axios = getAuthAxios();
        const response = await axios.get(`/customer/cancelacion/${fechaFormateada}`);
        
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}


export const obtenerTasaDeRetencion = async () => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/customer/tasa_retencion`);
        
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}


export const obtenerIndiceSatisfaccion = async () => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/customer/satisfaccion_cliente`);
        
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}


export const obtenerTiempoVisualizacion = async () => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/customer/tiempo_visualizacion`);
        
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}


export const obtenerTiempoPermanencia = async (fechaInicio, fechaFin) => {
    try{
        const fechaInicioFormateada = dayjs(fechaInicio).format('YYYY-MM-DD');
        const fechaFinFormateada = dayjs(fechaFin).format('YYYY-MM-DD');
        const axios = getAuthAxios();
        const response = await axios.get(`/customer/tiempo_permanencia/${fechaInicioFormateada}/${fechaFinFormateada}`);
        
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}

