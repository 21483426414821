import React, { useEffect, useMemo, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { getStudentsBySchool } from "../../controllers/estudiantes/estudiantes.controller";
import HeaderSearchTable from "../Custom/HeaderSearchTable";
import LoaderSmall from "../Loaders/LoaderSmall";
import * as FaIcons from "react-icons/fa";

const EstudiantesPorEscuela = ({selectOption, handleViewStudent}) => {
	
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [dataTable, setDataTable] = useState([]);
	const [dataTableFiltered, setDataTableFiltered] = useState([]);
	const [loading, setLoading] = useState(false);
	
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={dataTable}
				setArrayFiltered={setDataTableFiltered}
				setResetPagination={setResetPagination}
				placeholder="Buscar estudiante por nombre o apellido"
				columnsToFilter={["nombres", "apellidos"]}
			/>
		);
	}, [filterText, resetPagination]);
	
	const columns = useMemo(
		() => [
			{
				name: "#",
				selector: (row, rowIndex) => rowIndex + 1,
				width: "10%",
			},
			{
				name: "Nombres",
				// width: '250px',
				selector: (row) => row.nombres,
				sortable: true,
			},
			{
				name: "Apellidos",
				// width: '250px',
				selector: (row) => row.apellidos,
				sortable: true,
			},
			{
				name: "Progreso de la escuela",
				width: "250px",
				cell: (row) => (
					<div className="row w-100">
						<div className="col-9">
							<ProgressBar
								className="ge-progress-primary"
								now={row.progreso}
								// now={50}
								max={100}
								min={0}
							/>
						</div>
						<div className="col-3">
							<span
							// className="ml-2"
							>
								{row.progreso}%
							</span>
						</div>
					</div>
				),
			},
			{
				name: "Acciones",
				cell: (row) => (
					<div className="d-flex">
						<button
							className="btn ge-btn-deg-primary btn-sm mr-2"
							onClick={() => handleViewStudent(row.id_user)}
						>
							<FaIcons.FaEye />
						</button>
					</div>
				)
			}
		],
		[]
	);
	
	useEffect(() => {
		if (selectOption?.panelTitle === "escuela" && selectOption?.value) {
			getStudents(selectOption.value);
		}
	}, [selectOption]);
	
	const getStudents = async (idSchool) => {
		setLoading(true);
		try {
			const response = await getStudentsBySchool(idSchool);
			if (response) {
				setDataTable(response);
			}
			setLoading(false);
		} catch (error) {
		//	console.log(error);
			setLoading(false);
		}
	};
	
	return (
		<>
			<DataTable
				className="custom-datatable"
				columns={columns}
				data={
					dataTableFiltered.length > 0 ? dataTableFiltered : dataTable
				}
				subHeader
				subHeaderComponent={searchComponentMemo}
				pagination
				responsive
				progressPending={loading}
				progressComponent={<LoaderSmall />}
				noDataComponent={
					<div className="mt-5 w-100 text-center">
						No hay estudiantes con progreso para esta escuela.
					</div>
				}
			/>
		</>
	);
};

export default EstudiantesPorEscuela;
