import React, { useEffect, useState } from "react";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import * as yup from "yup";
import { Formik, useFormik } from "formik";
import { Form } from "react-bootstrap";
import { addRolesUser, getAllRoles, getRolesUser } from "../../controllers/ajustes/roles.controller";
import { SwalToast } from "../../utils/toasts";
import Swal from "sweetalert2";

const validationSchema = yup.object({
	correo: yup
		.string()
		.email("El correo no es válido")
		.required("El correo es obligatorio"),
});

const Roles = () => {
    
    const [dataRoles, setDataRoles] = useState([]);
    const [infoUser, setInfoUser] = useState(null);
    const [rolesSelected, setRolesSelected] = useState([]);
    
    
	const initialValues = {
		correo: "",
	};
    
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values) => {
			const data = await getRolesUser(values.correo);
            if(data){
                setInfoUser(data);
            }
            formik.resetForm();
		},
	});
    
    const onChangeRole = (idRol) => {
        const newRolesSelected = [...rolesSelected];
        if(newRolesSelected.includes(idRol)){
            newRolesSelected.splice(newRolesSelected.indexOf(idRol), 1);
        }else{
            newRolesSelected.push(idRol);
        }
        setRolesSelected(newRolesSelected);
    }
    
    const fetchRoles = async () => {
        const response = await getAllRoles();
        setDataRoles(response);
    }
    
    const onSubmitChangeRoles = async (e) => {
        e.preventDefault();
        
        Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir los cambios",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, guardar!',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await addRolesUser({
                    roles: rolesSelected,
                    idUser: infoUser.id_user
                });
                
                SwalToast.fire({
                    icon: response.status ? 'success' : 'error',
                    title: response.message
                })
            }
        })
        
    }
    
    useEffect(() => {
        
        fetchRoles();
        
        return () => {
            setDataRoles([]);
        }
    }, []);
    
    useEffect(() => {
        if(infoUser){
            setRolesSelected(infoUser.roles);
        }
    }, [infoUser]);
    
	return (
		<LayoutAdmin 
            contentTitle=""
            routeTitle="Roles"
        >
			<div className="card">
                <div className="card-header ge-noborder">
                    <>
                        <img
                            src="/images/file-icon.png"
                            className="mr-2"
                            style={{ width: "40px" }}
                            alt="file-icon"
                        />
                        <span className="ge-h3 ge-title">
                            Mantenimiento de Roles
                        </span>
                    </>
                </div>
				<div className="card-body">
					<div className="container">
						<form className="row" onSubmit={formik.handleSubmit}>
							<Form.Group className="col-12 col-md-5">
								<Form.Label className="ml-2">Correo:</Form.Label>
								<Form.Control
                                    className="ge-form mx-2"
                                    placeholder="example@example.com"
                                    autoComplete="off"
									type="text"
									name="correo"
									value={formik.values.correo}
									onChange={formik.handleChange}
									isValid={
										formik.touched.correo &&
										!formik.errors.correo
									}
									isInvalid={
										formik.touched.correo &&
										formik.errors.correo
									}
								/>
								<Form.Control.Feedback type="invalid" className="ml-2" >
									{formik.errors.correo}
								</Form.Control.Feedback>
							</Form.Group>
                            <div className="col-2 my-2 ml-2 my-md-0 ml-md-0 d-flex align-items-end">
                                <button 
                                    type="submit"
                                    className="btn ge-btn-primary"
                                >
                                    Buscar
                                </button>
                            </div>
						</form>
                        
                        {
                            infoUser && (
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <h4 className="ml-2">Datos del usuario:</h4>
                                    </div>
                                    <div className="col-6">
                                        <p className="ml-2">Nombres y apellidos:<b> {`${infoUser?.nombres} ${infoUser?.apellidos}`} </b></p>
                                    </div>
                                    <div className="col-12">
                                        <form 
                                            onSubmit={(e) => onSubmitChangeRoles(e)}
                                            className="d-md-flex flex-column p-2"
                                        >
                                            {
                                                dataRoles.map((item, index) => (
                                                    <Form.Check
                                                        className="mb-2 mb-md-0 mr-md-4"
                                                        key={`role-${item.id_rol}`}
                                                        type="checkbox"
                                                        id={`role-${item.id_rol}`}
                                                        label={item.nombre_rol}
                                                        value={item.id_rol}
                                                        checked={rolesSelected.includes(item.id_rol)}
                                                        onChange={() => onChangeRole(item.id_rol)}
                                                    />
                                                ))
                                            }
                                            <div className="my-3">
                                                <button
                                                    type="submit"
                                                    className="btn ge-btn-primary"
                                                >
                                                    Guardar
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )
                        }
					</div>
				</div>
			</div>
		</LayoutAdmin>
	);
};

export default Roles;
