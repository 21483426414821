import { useState } from "react";
import * as yup from "yup";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

export const useSchema = (initialSchema, formats = SUPPORTED_FORMATS) => {
    
	const [schema, setSchema] = useState(initialSchema);
    // const [fieldChange, setFieldChange] = useState(null);
    // const [typeChange, setTypeChange] = useState(null);
    
    
	const setFieldStringFile = (field) => {
		if (field === null) {
			return yup.object(schema);
		}
        
		const newSchema = {
			...schema,
			[field]: yup
				.string()
				.test("imageUrl", "La URL debe ser una imagen", (value) => {
					return value && value.includes("files");
				}),
		};
        
		setSchema(newSchema);
        
		return yup.object({
			...newSchema,
		});
	};
    
	const setFieldFile = (field) => {
		if (field === null) {
			return yup.object(schema);
		}
        
		const newSchema = {
			...schema,
			[field]: yup
				.mixed()
				/* .test("fileSize", "El archivo debe ser menor a 5MB", (value) => {
                    return value && value.size < 5000000;
                }) */
				.test(
					"fileFormat",
					"El formato debe ser una imagen",
					(value) => {
						return value && formats.includes(value.type);
					}
				),
		};
        
		setSchema(newSchema);
        
		return yup.object({
			...newSchema,
		});
	};
    
    const resetSchema = () => {
        setSchema(initialSchema);
    }
    
    return [schema, setFieldStringFile, setFieldFile, resetSchema];
};
