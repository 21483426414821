import React, { useEffect, useState } from "react";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import * as FaIcons from "react-icons/fa";
import CardTopDocente from "../../components/Docentes/Cards/CardTopDocente";
import { getTop5Docentes } from "../../controllers/docentes/docentes";

const DashboardDocentesScreen = () => {
	
	const [topDocentes, setTopDocentes] = useState([]);
	
	const handleGetTopDocentes = async() => {
		const data = await getTop5Docentes();
		if(data) {
			setTopDocentes(data);
		}
	}
	
	useEffect(() => {
		handleGetTopDocentes();
	}, []);
	
	return (
		<LayoutAdmin
			contentTitle={
				<>
					<FaIcons.FaChartBar className="mr-2" />
					<span className="ge-h3 ge-title">Dashboard Docentes</span>
				</>
			}
			routeTitle="Dashboard"
		>
			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="d-flex align-items-center col-12">
							<img 
								src="/images/estudiante.png"
								className="mr-2"
								style={{ width: "25px" }}
								alt="estudiante"
							/>
							<span className="ge-title ge-h3">Docentes Destacados</span>
						</div>
						<div className="row d-flex w-100 justify-content-md-between">
							{
								topDocentes.length > 0 ? (
									topDocentes.map((docente, index) => (
										<div
											key={index}
											// className="col-12 col-sm-6 col-md-3 col-xl-2 my-2"
											className="col-12 col-sm-6 col-md-4 my-2"
										>
											<CardTopDocente docenteData={docente}/>
										</div>
									))
								): (
									<div className="col-12">
										<span className="ge-text-muted">
											No hay docentes destacados
										</span>
									</div>
								)
							}
						</div>
					</div>
				</div>
			</div>
		</LayoutAdmin>
	);
};

export default DashboardDocentesScreen;
