import { getAuthAxios, getPublicAxios } from "../../axios/axiosCustom"



export const getAllRoles = async () => {
    
    try{
        const axios = getPublicAxios();
        
        const response = await axios.get('/admin/all_roles');
        
        if(response.data.status){
            return response.data.data;
        }
        
        return [];
        
    }catch(error){
        console.log(error);
        return [];
    }
}


export const getRolesUser = async (correo) => {
    try{
        const axios = getAuthAxios();
        
        const response = await axios.get(`/admin/infoUser/${correo}`);
        
        if(response.data.status){
            return response.data.data;
        }
        
        return null;
        
    }catch(error){
        console.log(error);
        return null;
    }
}


export const addRolesUser = async ({
    roles,
    idUser
}) => {
    try{
        const axios = getAuthAxios();
        
        const response = await axios.post(`/admin/add_roles/${idUser}`, {
            roles: JSON.stringify(roles)
        });
        
        return {
            status: response.data.status,
            message: response.data.message
        };
        
    }catch(error){
        console.log(error);
        return {
            status: false,
            message: "Error al agregar roles"
        }
    }
}