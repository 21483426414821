import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import EditorGeneral from '../../components/Editor/EditorGeneral';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import { createAnounce } from '../../controllers/anuncios/anuncios';
import ReactDatePicker from 'react-datepicker';
import { filterPassedTime } from '../../utils/time';
import CustomInputDate from '../../components/Custom/CustomInputDate';
import dayjs from 'dayjs';
const CreateAnuncio = () => {

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    mutate,
  } = useMutation(createAnounce, {
    onSuccess: () => {
      queryClient.invalidateQueries('anuncios');
      navigate('/listar-banners', { replace: true });
    },
  });
  const initialValues = {
    color_inicial: "",
    color_final: "",
    texto_boton: "",
		enlace_boton: "",
    color_boton:"",
    inclinacion: 0,
    fecha_fin:"",
    archivo: null,
  };

  const [datosPost, setDatosPost] = useState(initialValues);
  const [contenidoHTML, setContenidoHTML] = useState('');
  const [renderImage, setRenderImage] = useState(null);

  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const imgInputRef = useRef();

  //Cambia la imagen de portada
  const handleChangeImage = (e) => {
    e.preventDefault();
    setLoading(true);
    if (imgInputRef.current.files.length > 0) {
      //validar solo imagenes
      const file = e.target.files[0];
      if (file.type.indexOf('image') < 0) {
        setLoading(false);
        return;
      }
      //console.log(file);
      setImagePreview(URL.createObjectURL(file));
      formik.setFieldValue('archivo', file);
      setLoading(false);
    }
  };

  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png',
  ];

  const validationSchema = yup.object({
    texto_boton: yup.string().required("El campo es requerido"),
		enlace_boton: yup.string().required("El campo es requerido"),
    color_inicial: yup.string().required("El campo es requerido"),
		color_final: yup.string().required("El campo es requerido"),
    color_boton: yup.string().required("El campo es requerido"),
    inclinacion: yup.string().required("El campo es requerido"),
    archivo: yup
    .mixed()
    .notRequired()
    .test(
      "fileFormat",
      "Archivo no permitido",
      value => value ? SUPPORTED_FORMATS.includes(value.type) : true
    )
  });

  const formik = useFormik({
    initialValues: datosPost,
    validationSchema,
    onSubmit: async (values) => {
      const datos = { ...values };
      datos.anuncio = contenidoHTML;
      console.log(datos)
      datos.fecha_fin=dayjs(datos.fecha_fin).format('YYYY-MM-DD H:mm:ss')
      mutate(datos);
    },
  });

  useEffect(() => {
    if (formik.values.archivo?.name) {
      setRenderImage(URL.createObjectURL(formik.values.archivo));
    }
  }, [formik.values.archivo]);

  return (
    <LayoutAdmin contentTitle='Crear banner' routeTitle='Banner'>
      <form onSubmit={formik.handleSubmit}>
        <div className='row'>
          <div className='col-md-12'>
            <div className='card'>
              <div
                className='card-header p-3'
                style={{ backgroundColor: '#842eff', color: '#fff' }}>
                <h4>Crear Banner</h4>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-lg-4 p-2'>
                    <div className='form-group mb-3'>
                      <label>Imagen</label>
                      <div className='img-portada-container'>
                        <img
                          src={
                            !imagePreview
                              ? 'https://via.placeholder.com/340x130?text=Gestora+Portada+Post'
                              : imagePreview
                          }
                          alt='...'
                          className='img-profile'
                        />
                        <div className='button-container'>
                          <input
                            type='file'
                            ref={imgInputRef}
                            onChange={(e) => {
                              handleChangeImage(e);
                            }}
                            className='d-none'
                            disabled={loading}
                            name='archivo'
                          />
                          {/* <p> {formik.errors.archivo} </p> */}
                          {loading ? (
                            <span>
                              <Spinner
                                animation='border'
                                variant='primary'
                                className='mt-2'
                              />
                            </span>
                          ) : (
                            <span
                              className='img-profile-button text-center'
                              onClick={() => imgInputRef.current.click()}>
                              <FaIcons.FaPencilAlt />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-8'>
                    <div className='col-md-12 mb-2 p-0'>
                      <label>Texto del banner</label>
                      <EditorGeneral
                        contenido={formik.values.contenido}
                        onChange={setContenidoHTML}
                      />
                    </div>
                    <div className='row'>
                            <div className='col-12 p-0'>
                              <h5>Configuración del botón</h5>
                            </div>
                            <div className="form-group mb-3  col-md-3 p-0">
                                  <label>Enlace botón:</label>
                                  <input
                                    type="text"
                                    className="form-control ge-form"
                                    placeholder="Enlace del botón"
                                    id="enlace_boton"
                                    name="enlace_boton"
                                    onChange={formik.handleChange}
                                    value={
                                      formik.values.enlace_boton
                                    }
                                  />
                                  <p>
                                    {" "}
                                    {
                                      formik.errors.enlace_boton
                                    }{" "}
                                  </p>
                                </div>
                                <div className="form-group mb-3  col-md-3 ">
                                  <label>Texto botón:</label>
                                  <input
                                    type="text"
                                    className="form-control ge-form"
                                    placeholder="Texto del botón"
                                    id="texto_boton"
                                    name="texto_boton"
                                    onChange={formik.handleChange}
                                    value={
                                      formik.values.texto_boton
                                    }
                                  />
                                  <p>
                                    {" "}
                                    {
                                      formik.errors.texto_boton
                                    }{" "}
                                  </p>
                                </div>
                      <div className="form-group mb-3 col-md-3 crear-live-color-picker p-0 mr-2">
											<label>Color Botón</label>
											<div>
												<input
												
												type='color'
												name="color_boton"
												id="color_boton"
												onChange={formik.handleChange}
												value={
													formik.values.color_boton
												}
													/>
												<span>{formik.values.color_boton ==="" ? "Escoge un color":formik.values.color_boton}</span>
	
											</div>
											<p>
													{" "}
													{
														formik.errors.color_boton
													}{" "}
												</p>
										</div>
                         
                    </div>
                    <div className='row'>
                    <div className="form-group col-md-6 mb-3 p-0">
											<label>Fecha fin de banner:</label>
											<ReactDatePicker
												locale={"es"}
												selected={formik.values.fecha_fin}
												onChange={(date) => {
													formik.setFieldValue(
														"fecha_fin",
														date
													);
												}}
												isClearable={true}
												dateFormat="dd/MM/yyyy h:mm aa"
												showTimeInput
												timeInputLabel="Hora"
												filterTime={filterPassedTime}
												minDate={new Date()}
												customInput={<CustomInputDate />}
												name="fecha_fin"
											/>
											{
												formik.touched.fecha_fin && !!formik.errors.fecha_fin && (
													<div className="invalid-feedback">
														{formik.errors.fecha_fin}
													</div>
												)
											}
							      </div>
                    </div>
                    <div className='row'>
                    <div className='col-12 p-0'>
                      <h5>Configuración del degradado</h5>
                    </div>
                    <div className="form-group mb-3 col-md-3 crear-live-color-picker p-0 mr-2">
											<label>Color Inicial</label>
											<div>
												<input
												
												type='color'
												name="color_inicial"
												id="color_inicial"
												onChange={formik.handleChange}
												value={
													formik.values.color_inicial
												}
													/>
												<span>{formik.values.color_inicial ==="" ? "Escoge un color":formik.values.color_inicial}</span>
	
											</div>
											<p>
													{" "}
													{
														formik.errors.color_inicial
													}{" "}
												</p>
										</div>
                    <div className="form-group mb-3 col-md-3 crear-live-color-picker p-0 mr-2">
											<label>Color final</label>
											<div>
												<input
												type='color'
												name="color_final"
												id="color_final"
												onChange={formik.handleChange}
												value={
													formik.values.color_final
												}
													/>
												<span>{formik.values.color_final ==="" ? "Escoge un color":formik.values.color_final}</span>
	
											</div>
											<p>
													{" "}
													{
														formik.errors.color_final
													}{" "}
												</p>
										</div>
                    <div className="form-group mb-3 col-md-3 p-0">
                      <label>Inclinacion del degradado:</label>
											<input
												type="text"
												className="form-control ge-form"
												placeholder="Grados de inclinación"
												id="inclinacion"
												name="inclinacion"
												onChange={formik.handleChange}
												value={
													formik.values.inclinacion
												}
											/>
											<p>
												{" "}
												{
													formik.errors.inclinacion
												}{" "}
											</p>
										</div>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <button
                      type='submit'
                      className='btn ge-btn-primary btn-block'>
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </LayoutAdmin>
  );
};

export default CreateAnuncio;
