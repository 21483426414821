import _ from "lodash";
import React, { useEffect } from "react";
import * as HiIcons from "react-icons/hi";
import * as MdIcons from "react-icons/md";

const HeaderSearchTable = ({
	filterText,
	setFilterText,
	arrayData = [],
	setArrayFiltered,
	setResetPagination,
	placeholder = "Buscar",
	columnsToFilter = ["nombres", "apellidos", "correo"],
	className="col-12 col-md-6 d-flex align-items-center"
}) => {
	const onFilterItems = () => {
		if (filterText.length > 0) {
			const newFilteredItems = arrayData.filter(
				(item) => 
					columnsToFilter.some((column) =>
						_.toLower(item[column]).includes(_.toLower(filterText))
					)
			);
			setArrayFiltered(newFilteredItems);
		} else {
			setArrayFiltered([]);
			setResetPagination(true);
		}
	};
	
	const handleClear = () => {
		if(filterText.length > 0) {
			setFilterText("");
			setResetPagination(true);
		}
	};
	
	useEffect(() => {
		if(arrayData.length > 0) {
			onFilterItems();
		}
	}, [filterText]);
	
	return (
		<div className={className}>
			<div className="d-flex custom-input-container align-items-center p-2 w-100">
				<HiIcons.HiOutlineSearch size={20} className="mr-2" />
				<input
					type="text"
					className="custom-input w-100"
					// id="searchDocente"
					placeholder={placeholder}
					value={filterText}
					onChange={(e) => setFilterText(e.target.value)}
				/>
				<button 
					className="btn-clear"
					onClick={handleClear}
				>
					<MdIcons.MdClose size={20} />
				</button>
			</div>
		</div>
	);
};

export default HeaderSearchTable;
