import { useQuery, useMutation } from "react-query";
import {
  getGastos,
  getTipoDeGastos,
  getDetallesGastos,
  getSubDetallesGastos,
  getDocumentosGastos,
  enviarDatosGastos,
} from "../../../controllers/contabilidad/gastos/gastos.controller";

const KEY_GASTOS = "gastos";
const KEY_GASTOS_TIPO = "GASTOS_TIPO";
const KEY_GASTOS_DETALLES = "GASTOS_DETALLES";
const KEY_GASTOS_SUBDETALLES = "GASTOS_SUBDETALLES";
const KEY_GASTOS_DOCUMENTOS = "GASTOS_DOCUMENTOS";
const KEY_GASTOS_CREATE = "GASTOS_CREATE";


export const useQueryGastos = (fecha_consulta) => {
  return useQuery(
    [KEY_GASTOS],
    () =>
      getGastos({
        fecha_consulta,
      }),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
};

/* Formularios gastos */

export const useQueryGetTipoDeGastos = () => {
  return useQuery([KEY_GASTOS_TIPO], getTipoDeGastos, {
    refetchOnWindowFocus: false,
  });
};

export const useQueryGetDocumentosGastos = () => {
  return useQuery([KEY_GASTOS_DOCUMENTOS], getDocumentosGastos, {
    refetchOnWindowFocus: false,
  });
};

export const useQueryGetDetallesGastos = (tipo) => {
    return useQuery(
      [KEY_GASTOS_DETALLES, tipo],
      () =>
      getDetallesGastos(tipo),
      {
        refetchOnWindowFocus: false,
        enabled: !!tipo
      }
    );
  };

  export const useQueryGetSubDetallesGastos = (id_detalle) => {
    return useQuery(
      [KEY_GASTOS_SUBDETALLES, id_detalle],
      () =>
      getSubDetallesGastos(id_detalle),
      {
        refetchOnWindowFocus: false,
        enabled: !!id_detalle
      }
    );
  };

  export const useMutationEnviarDatosGastos = () => {
    return useMutation(enviarDatosGastos)
  };