import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Spinner } from "react-bootstrap";
import ListarOpciones from "./ListarOpciones";
import * as BsIcons from "react-icons/bs";
import CustomSelectGeneral from "../Custom/CustomSelectGeneral";
import { useQuery } from "react-query";
import {
	getClassByCourse,
} from "../../controllers/cursos/examen/examen.controller";
import { useMutateAddOption, useMutateEditQuestion, useQueryQuestion } from "../../hooks/examen.hooks";
import { SwalToast } from "../../utils/toasts";

const EditarPregunta = ({ id_curso, id_examen, id_pregunta = null, setShow }) => {
    
    
    //React Query Hooks
	const {
		data: clasesList,
	} = useQuery(["ClasesCurso", id_curso], () => getClassByCourse(id_curso), {
		retry: 1,
		refetchOnWindowFocus: false,
	});
    
    const {data: questionData, refetch, isLoading: loadData, isSuccess } = useQueryQuestion(id_pregunta);
    
    //Mutation Hooks
	const { mutate: mutateEditQuestion, isLoading: loadingEdit } = useMutateEditQuestion(id_pregunta);
    
    const { mutate: mutateAddOption, isLoading: loadingAddOption } = useMutateAddOption();
    
    
	const initialState = () => ({
		id_examen: id_examen,
		pregunta: "",
		descripcion: "",
		explicacion_correcta: "",
		clase: "",
		opciones: [],
	});
    
	const initialStateOpcion = () => ({
		id_opcion: "",
		opcion_text: "",
		respuesta_correcta: 0,
	});
    
	const [infoPregunta, setInfoPregunta] = useState(initialState());
	const [opcion, setOpcion] = useState(initialStateOpcion());
    
	const { pregunta, descripcion, explicacion_correcta, opciones, clase } =
		infoPregunta;
	const { opcion_text, respuesta_correcta } = opcion;
    
	const toggleChecked = (status = 0) => {
		// 0 = false, 1 = true
		setOpcion({
			...opcion,
			respuesta_correcta: status
		});
	};
    
	const handleChange = (e) => {
		setInfoPregunta({
			...infoPregunta,
			[e.target.name]: e.target.value,
		});
	};
    
	const handleChangeClase = (value) => {
		setInfoPregunta({
			...infoPregunta,
			clase: value.id_clase,
		});
	};
    
	const handleChangeOpcion = (e) => {
		setOpcion({
			...opcion,
			[e.target.name]: e.target.value,
		});
	};
    
	const agregarOpcion = (op) => {
		if (op.opcion_text !== "") {
            
            //crear la variable como recibe el API
            const optionToApi = {
                opcion: op.opcion_text,
                opcion_correcto: op.respuesta_correcta,
            }
            
            //enviar la opcion al API
            mutateAddOption({
                id_pregunta: id_pregunta,
                data: optionToApi,
            },{
                onSuccess: (data) => {
                    
                    const { data: optionFromApi } = data;
                    
                    //si se agrega correctamente, agregar la opcion a la lista
                    const newOption = {
                        id_opcion: parseInt(optionFromApi.id_opcion),
                        opcion_text: optionFromApi.opcion_txt,
                        respuesta_correcta: parseInt(optionFromApi.opcion_correcta)
                    }
                    
                    const nuevoArreglo = [...infoPregunta.opciones, newOption];
                    setInfoPregunta({
                        ...infoPregunta,
                        opciones: nuevoArreglo,
                    });
                    
                    setOpcion(initialStateOpcion());
                    SwalToast.fire({
                        title: "Opcion agregada correctamente",
                        icon: "success"
                    });
                },
                onError: (error) => {
                    SwalToast.fire({
                        icon: "error",
                        title: "Error",
                        text: 'Ocurrió un error al agregar la opción'
                    });
                    setOpcion(initialStateOpcion());
                }
            })
            
		}
	};
    
	const eliminarOpcion = (id) => {
		const copiaOpciones = [...infoPregunta.opciones];
		const arregloActualizado = copiaOpciones.filter(
			(opcion) => opcion.id_opcion !== id
		);
		setInfoPregunta({
			...infoPregunta,
			opciones: arregloActualizado,
		});
	};
    
	const onSubmit = (e) => {
		e.preventDefault();
		mutateEditQuestion({
			id_pregunta: id_pregunta,
			data: {
				pregunta,
				descripcion,
				clase
			},
		},{
			onSuccess: (data) => {
				SwalToast.fire({
					icon: "success",
					title: "Pregunta editada correctamente",
				});
			},
			onError: (error) => {
				SwalToast.fire({
					icon: "error",
					title: "Error",
					text: 'Ocurrió un error al editar la pregunta'
				});
			}
		})
	};
	
	
    /* Effects */
	useEffect(() => {
		if(isSuccess){
            let parseData = {
                ...questionData,
                opciones: questionData.opciones.map((opcion) => ({
                    id_opcion: opcion.id_opcion,
                    opcion_text: opcion.opcion_txt,
                    respuesta_correcta: parseInt(opcion.opcion_correcta),
                }))
            }
            setInfoPregunta(parseData);
        }
	}, [questionData, isSuccess]);
    
    useEffect(() => {
        if(id_pregunta){
            refetch();
        }
    }, [id_pregunta]);
    
	return (
		<>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					{/* FieldSet Editar pregunta */}
					<fieldset className="mb-4 p-4">
                        <legend>Datos pregunta:</legend>
						<Form.Group className="my-2">
							<Form.Label className="mb-0">Pregunta</Form.Label>
							<Form.Control
								required
								name="pregunta"
								type="text"
								value={pregunta}
								onChange={handleChange}
								placeholder="Tu pregunta"
							/>
						</Form.Group>
						<Form.Group className="my-2">
							<Form.Label className="mb-0">Descripción de la pregunta</Form.Label>
							<Form.Control
								as="textarea"
								name="descripcion"
								value={descripcion}
								onChange={handleChange}
								placeholder={"Descripción de la pregunta"}
							/>
						</Form.Group>
						<Form.Group className="my-2">
							<Form.Label className="mb-0">
								Explicacion de Respuesta Correcta
							</Form.Label>
							<Form.Control
								as="textarea"
								name="explicacion_correcta"
								value={explicacion_correcta}
								onChange={handleChange}
								placeholder="Explicación de la respuesta"
							/>
						</Form.Group>
						<Form.Group className="my-2">
							<CustomSelectGeneral
								options={clasesList}
								id={"id_clase"}
								lbl={"titulo_clase"}
								seleccion={"Seleccione una clase"}
								value={infoPregunta.clase}
								onChange={handleChangeClase}
								lblColor={"#000"}
							/>
						</Form.Group>
                        <Form.Group className="d-flex justify-content-start justify-content-md-end align-items-center mt-3 mb-0">
                            <button
                                className="btn ge-btn-primary"
                                type="submit"
                            >
								{
									loadingEdit ? (
										<Spinner
											as="span"
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
										/>
									) : (
										<span>Guardar cambios</span>
									)
								}
                            </button>
                        </Form.Group>
					</fieldset>
					
					{/* Fieldset Editar opciones */}
					<fieldset className="p-4">
                        <legend>Opciones</legend>
						<Form.Group className="mb-2">
							<Form.Label className="mb-0">Opción</Form.Label>
							<Form.Control
								as="textarea"
								name="opcion_text"
								value={opcion_text}
								type="text"
								onChange={handleChangeOpcion}
								placeholder="Tu opción"
							/>
						</Form.Group>
						<Form.Group 
                            className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center mt-3 mb-2"
                        >
							<Form.Label className="mb-2 mb-sm-0">Marcar opción correcta</Form.Label>
							{parseInt(respuesta_correcta) === 1 ? (
								<span
									className="btn btn-light btn-sm my-sm-2"
									onClick={() => toggleChecked(0)}
								>
									<BsIcons.BsToggleOn
										color="#37af48"
										size={20}
									/>
								</span>
							) : (
								<span
									className="btn btn-light btn-sm my-sm-2"
									onClick={() => toggleChecked(1)}
								>
									<BsIcons.BsToggleOff
										color="#ff0000"
										size={20}
									/>
								</span>
							)}
                            <button
								type="button"
                                className="btn btn-success btn-sm"
                                onClick={() => {
                                    agregarOpcion(opcion);
                                }}
                            >
								{
									loadingAddOption ? (
										<Spinner
											as="span"
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
										/>
									) : (
										<span>Agregar Opción</span>
									)
								}
                            </button>
						</Form.Group>
						<div className="mt-3">
							<ListarOpciones
								opciones={opciones}
								eliminarOpcion={eliminarOpcion}
							/>
						</div>
					</fieldset>
				</Modal.Body>
				<Modal.Footer>
					<button
                        className="btn ge-btn-primary"
                        type="button"
                        onClick={() => setShow(false) }
                    >
                        Cerrar
                    </button>
				</Modal.Footer>
			</Form>
		</>
	);
};

export default EditarPregunta;
