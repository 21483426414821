import React, { useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import DataTable from "react-data-table-component";
import { SwalToast } from "../../../utils/toasts";
import Swal from "sweetalert2";
import { useMutateDeleteGlosary, useQueryGlosaryByCourse } from "../../../hooks/glosario.hooks";
import HeaderSearchTable from "../../../components/Custom/HeaderSearchTable";
import LoaderSmall from "../../../components/Loaders/LoaderSmall";
import ModalCreateGlosary from "../../../components/Cursos/Glosario/ModalCreateGlosary";
import ModalEditGlosary from "../../../components/Cursos/Glosario/ModalEditGlosary";

const ListaGlosarioScreen = ({ idCourse }) => {
	
	const [filteredGlosary, setFilteredGlosary] = useState([]);
	// const [idCourse_, setIdCourse_] = useState(idCourse);
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
    const [showModalCreate, setShowModalCreate] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [dataToEdit, setDataToEdit] = useState(null);
	const { data: glosaryList, isLoading } = useQueryGlosaryByCourse(idCourse);
	const {mutate: mutateDelete} = useMutateDeleteGlosary(idCourse);
    
	const handleEditGlosary = (dataGlosary) => {
		setDataToEdit(dataGlosary);
		setShowModalEdit(true);
	}
	
	const handleToggleDelete = (idGlosary) => {
		Swal.fire({
			title: '¿Estás seguro?',
			text: "Esta acción no se puede revertir.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, eliminar!',
			cancelButtonText: 'Cancelar'
		}).then((result) => {
			if (result.isConfirmed) {
				mutateDelete(idGlosary,
				{
					onSuccess: () => {
						SwalToast.fire({
							icon: 'success',
							title: 'Término eliminado con éxito',
						});
					},
					onError: () => {
						SwalToast.fire({
							icon: 'error',
							title: 'Error al eliminar el término.',
						});
					}
				});
			}
		})
	}
    
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "#",
				selector: (row) => row.id_glosario,
				width: "10%",
			},
			{
				name: "Término",
				width: '200px',
				selector: (row) => row.titulo,
				sortable: true,
			},
			{
				name: "Descripción",
                // width: "250px",
				selector: (row) => row.descripcion,
			},
			{
				name: "Acciones",
				width: "100px",
				cell: (row) => (
					<div className="d-flex">
						<button 
							className="btn ge-btn-primary btn-sm mr-2"
							onClick={() => handleEditGlosary(row)}
						>
							<FaIcons.FaCogs />
						</button>
                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => {
								handleToggleDelete(row.id_glosario)
							}}
                        >
                            <FaIcons.FaTrash />
                        </button>
					</div>
				),
			},
		],
		[]
	);
		
	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={glosaryList}
				setArrayFiltered={setFilteredGlosary}
				setResetPagination={setResetPagination}
				placeholder="Buscar por término"
				columnsToFilter={["titulo"]}
			/>
		);
	}, [filterText, resetPagination]);
    
	return (
		<div className="card p-2">
            {
                <ModalCreateGlosary
                    showModal={showModalCreate}
                    setShowModal={setShowModalCreate}
                    idCourse={idCourse}
                />
            }
			{
				<ModalEditGlosary
					showModal={showModalEdit}
					setShowModal={setShowModalEdit}
					idCourse={idCourse}
					dataGlosary={dataToEdit}
					setDataToEdit={setDataToEdit}
				/>
			}
			<div className="d-flex justify-content-between card-header card-no-after ge-noborder">
				<div>
					<span className="ge-h3 ge-title">Glosario</span>
				</div>
				<div className="">
					<button 
                        className="btn ge-btn-primary btn-sm"
                        onClick={() => setShowModalCreate(true)}
                    >
						<FaIcons.FaPlus />
						<span className="ml-2">Agregar término</span>
					</button>
				</div>
			</div>
			<div className="card-body">
				<DataTable
					className="custom-datatable"
					columns={columns}
					data={
						filteredGlosary.length > 0
							? filteredGlosary
							: glosaryList
					}
					subHeader
					subHeaderComponent={searchComponentMemo}
					pagination
					responsive
					progressPending={isLoading}
					progressComponent={<LoaderSmall />}
                    noDataComponent={<div className="w-100 text-center mt-4">No hay datos, agrega un término.</div>}
				/>
			</div>
		</div>
	);
};

export default ListaGlosarioScreen;
