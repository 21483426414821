

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 2,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
    },
};

const responsive_b = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
    },
};

export const defaultCarouselResponsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 4,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
    },
}


export const  customResponsive = (superLargeDesktop = 4, desktop = 3, tablet = 2, mobile = 2) => {
    return {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: superLargeDesktop,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: desktop,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: tablet,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: mobile,
        },
    }
}


export const getDefaultResponsive = () => {
    return defaultCarouselResponsive;
}

export const getResponsive = () => {
    return responsive;
}

export const getResponsiveB = () => {
    return responsive_b;
}