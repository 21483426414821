import React, { useEffect, useState } from "react";
import CardGestora from "../../Cards/CardGestora";
import _ from "lodash";
import Select from "react-select";
import { getRestCoursesFromUser } from "../../../controllers/select.controller";
const MyCoursesCard = ({courses = [], idStudent}) => {
    
    const [selectOptions, setSelectOptions] = useState([]);
    
    const loadOptions = async (idStudent) => {
        const response = await getRestCoursesFromUser(idStudent);
        if(response){
            const parseDataOption = response.map(item => ({
                value: item.id_curso,
                label: item.nombre_curso
            }))
            /* parseDataOption.unshift({
                value: "",
                label: "--- Seleccione un curso ---"
            }) */
            setSelectOptions(parseDataOption);
        }
    }
    
    const CustomOption = (props) => {
        const { label, value, isFocused, innerProps, innerRef } = props;
        return (
            <div
                className={`d-flex p-2 justify-content-between align-items-center ${isFocused ? "ge-bg-primary-light" : "bg-white"}`}
                ref={innerRef}
                style={{
                    borderRadius: "5px",
                }}
                {...innerProps}
            >
                <div>{label}</div>
                {
                    value !== "" && (
                        <button
                            className="btn btn-xs ge-btn-deg-primary float-right"
                            style={{
                                borderRadius: "5px",
                                height: "30px",
                            }}
                            onClick={() => console.log("value", value)}
                        >
                            Agregar
                        </button>
                    )
                }
            </div>
        )
    }
    
    
    useEffect(() => {
        loadOptions(idStudent);
        return () => {
            setSelectOptions([]);
        }
    }, [idStudent]);
    
	return (
		<CardGestora
            title={"Cursos"}
        >
            <div className="row">
                <div className="col-12 mb-3">
                    <Select
                        className="ge-select"
                        placeholder="Lista de cursos"
                        isSearchable={true}
                        options={selectOptions}
                        components={{
                            Option: CustomOption
                        }}
                    />
                </div>
                
                {
                    courses.length > 0 ? (
                        courses.map((course, index) => (
                            <div className="d-flex col-12 my-1" key={index}>
                                <img
                                    src="/images/check-morado.png"
                                    alt="check"
                                    style={{ width: "20px" }}
                                    className="mr-2"
                                />
                                <span className="ge-title txt-bold">{_.capitalize(course.nombre_curso)}</span>
                            </div>
                        ))
                    ) : (
                        <div className="col-12 bg-warning text-light py-2 ge-radius">
                            <span>No tiene cursos asignados</span>
                        </div>
                    )
                }
            </div>
        </CardGestora>
	);
};

export default MyCoursesCard;
