import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    getBenefitsByPlanId,
    deletePlanBenefits,
    createPlanBeneficio
} from '../controllers/planes/planes';

const KEY_PLAN_BENEFITS = "PLANBENEFITS";


/* Plan beneficios CRUD */


// traer beneficios por ide del plan
export const useQueryPlanById = (idPlan) => {
    return useQuery(
        [KEY_PLAN_BENEFITS, idPlan],
        () => getBenefitsByPlanId(idPlan),
        {
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}

// eliminar un beneficio por id del beneficio
export const useMutateDeletePlanBenefits = (id_beneficio) => {
    const queryClient = useQueryClient();
    return useMutation(deletePlanBenefits, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_PLAN_BENEFITS, id_beneficio]);
        }
    });
}

export const useMutateCreatePlanBenefits = () => {
    const queryClient = useQueryClient();
    return useMutation(createPlanBeneficio, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_PLAN_BENEFITS]);
        }
    })
}