import dayjs from "dayjs";
import { getAuthAxios } from "../../../axios/axiosCustom";

/** Traer metricas del mes por red social */
export const getMetricsByPlatform = async (mes, plataforma) => {
    try{
        const fechaFormateada = dayjs(mes).format('YYYY-MM');
        const axios = getAuthAxios();
        const response = await axios.get(`/marketing_ventas/redes_sociales_publicaciones_data/${fechaFormateada}/${plataforma}`);
        
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}

/** Traer redes sociales para el select */
export const getSelectPlatformsRS = async () => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/marketing_ventas/redes_sociales_publicaciones_select`);
        
        if(response.data.status){
            return response.data.data;
        }
        return null;
    }catch(error){
        console.log(error)
        return null;
    }
}