import React from "react";
import LayoutAdmin from "../components/layouts/LayoutAdmin";
import * as FaIcons from "react-icons/fa";
import HomeDocente from "../components/Home/HomeDocente";
import { useSelector } from "react-redux";
import HomeAdmin from "../components/Home/HomeAdmin";
import HomeMarketing from "../components/Home/HomeMarketing/HomeMarketing";
import HomeManager from "../components/Home/HomeManager/HomeManager";
import HomeContabilidad from "../components/Home/HomeContabilidad/HomeContabilidad";

const HomeScreen = () => {
	const { user } = useSelector((state) => state.user);
	let { roles } = user;
	roles = roles.map((role) => parseInt(role));
	
	return (
		<LayoutAdmin
			routeTitle="Home"
			contentTitle=""
		>
			{roles.includes(2) ? (
				<HomeAdmin />
			) : roles.includes(3) ? (
				<HomeDocente />
			) : roles.includes(4) ? (
				<HomeMarketing />
			) : roles.includes(5) ? (
				<HomeManager />
			)  : roles.includes(6) ? (
				<HomeContabilidad />
			) : (
				<div className="row">
					<div className="col-12">
						<div className="alert alert-info">
							<FaIcons.FaInfo className="mr-2" />
							<span>Sección en construcción</span>
						</div>
					</div>
				</div>
			)}
		</LayoutAdmin>
	);
};

export default HomeScreen;
