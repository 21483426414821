import React, { useEffect, useState } from 'react';
import Pagination from '../Pagination/Pagination';
import CommentItem from './CommentItem';

const CommentList = ({comments=[]}) => {

    return (
		<div className="comments p-2 my-4">
            {comments.length > 0 ? (
                <>
                <Pagination
                    data={comments}
                    RenderComponent={CommentItem}
                    title="Comentarios"
                    pageLimit={5}
                    dataLimit={5}
                />
                </>
            ) : (
            <h4>No hay comentarios recientes...</h4>
            )}
        </div>
    );
};

export default CommentList;