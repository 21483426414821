import React from 'react';
import Select from 'react-select';

export default ({ onChange, options, value, className,title=true }) => {

    const defaultValue = (options, value) => {
        return options ? options.find(option => option.id === value) : "";
    };

    return (
        <div className={className} style={{zIndex:4}}>
            {title ?    <label style={{color:'#842eff'}}>Plan:</label> : ''}
            <Select
                isClearable
                placeholder='--Seleccione--'
                noOptionsMessage={() => 'No hay opciones'}
                value={defaultValue(options, value)}
                onChange={value => {
                    if(!value)
                    {
                        value={
                            id:''
                        }
                    }
                    onChange(value)

                }} options={options}
                getOptionValue={planes => planes.id}
                getOptionLabel={planes => planes.nombre_plan + ' ('+planes.precio+')'} />
        </div>

    )
}