import { getAuthAxios } from "../../../axios/axiosCustom";

export const getSelectStatePosts = async () => {
    try {
        const axios = getAuthAxios();
        const response = await axios.get("/posts/select_estados_post");
        if(response.data.status){
            return response.data.data;
        }
        return [];
    }catch(error) {
        console.log(error);
    }
}


export const createTwitterPost = async (data) => {
    try{
        const axios = getAuthAxios();
        const response = await axios.post('/posts/registrar_publicacion_twitter', data);
        if(response.data.status){
            return response.data;
        }
        throw new Error(response.data.message);
    }catch(error){
        console.log(error)
    }
}


export const editTwitterPost = async ({
    id_registro,
    data,
}) => {
    try{
        const axios = getAuthAxios();
        const response = await axios.post(`/posts/editar_publicacion_twitter/${id_registro}`, data);
        if(response.data.status){
            return response.data;
        }
        throw new Error(response.data.message);
    }catch(error){
        console.log(error)
    }
}

export const getTwitterPosts = async () => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get('/posts/traer_publicaciones_twitter');
        if(response.data.status){
            return response.data.data;
        }
        throw new Error(response.data.message);
    }catch(error){
        console.log(error)
    }
}


export const getTwitterPostById = async (id) => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/posts/traer_publicacion_id/${id}`);
        if(response.data.status){
            return response.data.data;
        }
        throw new Error(response.data.message);
    }catch(error){
        console.log(error)
    }
}


export const toggleTwitterPostActive = async ({
    id_registro,
    activo,
}) => {
    try{
        const axios = getAuthAxios();
        const response = await axios.put(`/posts/toogle_active_post/${id_registro}/${activo}`);
        if(response.data.status){
            return response.data;
        }
        throw new Error(response.data.message);
    }catch(error){
        console.log(error)
    }
}