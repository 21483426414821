import React, { useState } from "react";
import LayoutAdmin from "../../../components/layouts/LayoutAdmin";
import FormularioGastos from "../../../components/Contabilidad/Gastos/FormularioGastos";

const CrearGasto = () => {
  const [showForm, setShowForm] = useState(false);

  return (
    <LayoutAdmin contentTitle="" routeTitle="Crear Gasto">
      <div className="card">
        <div className="d-flex justify-content-between card-header card-no-after ge-noborder mt-3">
          <div>
            <img
              src="/images/file-icon.png"
              className="mr-2"
              style={{ width: "40px" }}
              alt="file-icon"
            />
            <span className="ge-h3 ge-title">Crear gasto</span>
          </div>
        </div>
        <div className="card-body">
          <div>
            <button
              onClick={() => setShowForm(!showForm)}
              className={
                showForm
                  ? "btn ge-btn-select-gasto"
                  : "btn ge-btn-primary"
              }
            >
              Registrar Compra
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-start py-4 mt-3">
            {showForm && <FormularioGastos setShowForm={setShowForm} />}
          </div>
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default CrearGasto;
