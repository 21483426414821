import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { getQuestions } from "../../controllers/cursos/examen/examen.controller";
import CustomModal from "../Modal/CustomModal";
import NuevaPregunta from "./NuevaPregunta";
import Pregunta from "./Pregunta";
import * as FaIcons from "react-icons/fa";
import EditarPregunta from "./EditarPregunta";

const AgregarPreguntas = ({ id_curso, id_examen }) => {
    
	const [show, setShow] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [idPregunta, setIdPregunta] = useState("");
	
	const {
		data: preguntasList,
		error,
		isLoading,
	} = useQuery(["Preguntas", id_examen], () => getQuestions(id_examen), {
		refetchOnWindowFocus: false,
	});
    
	const onEdit = (id_question) => {
		setIdPregunta(id_question);
		setShowEdit(true);
	}
	
	return (
		<div className="col-md-7 px-3">
			<CustomModal
				title={"Agregar pregunta"}
				show={show}
				handleShow={setShow}
			>
				<NuevaPregunta
					id_curso={id_curso}
					id_examen={id_examen}
					setShow={setShow}
				/>
			</CustomModal>
            
			<CustomModal
				title={"Editar pregunta"}
				show={showEdit}
				handleShow={setShowEdit}
			>
				<EditarPregunta 
					id_curso={id_curso}
					id_examen={id_examen}
					id_pregunta={idPregunta}
					setShow={setShowEdit}
				/>
			</CustomModal>
			
			<div className="my-2">
				<h4>Lista de preguntas</h4>
			</div>
            
			<div className="d-flex justify-content-end my-2">
				<span
					className="btn ge-btn-primary"
					onClick={() => setShow(true)}
				>
					Agregar pregunta
				</span>
			</div>
			<div>
				{isLoading ? (
					<div style={{ textAlign: "center", color: "#842eff" }}>
						{" "}
						<Spinner animation="border" />{" "}
					</div>
				) : preguntasList ? (
					preguntasList.length > 0 ? (
						preguntasList.map((pregunta, i) => (
							<Pregunta 
								pregunta={pregunta} 
								key={i} 
								onEdit={onEdit}
							/>
						))
					) : (
						<div className="alert alert-warning">
							<FaIcons.FaExclamationTriangle className="mr-2" />
							<span>No hay preguntas aún</span>
						</div>
					)
				) : null}
			</div>
		</div>
	);
};

export default AgregarPreguntas;
