import React, { useEffect, useState } from "react";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { getPerformanceFromDocente } from "../../../controllers/docentes/docentes";

const CardDocentePerformance = ({
    idDocente = null,
    nombres,
    apellidos,
    imagen
}) => {
    
    const [progress, setProgress] = useState(0);
    
    const fecthData = async () => {
        const data = await getPerformanceFromDocente(idDocente);
        if(data){
            setProgress(data?.porcentaje_valoraciones || 0);
        }
    }
    useEffect(() => {
        if(idDocente){
            fecthData();
        }
    }, [idDocente])
    
    
    if(!idDocente) return null;
	return (
		<div className="card card-docente-performance">
			<div className="card-body">
				{/* Header */}
				<div className="d-flex justify-content-center align-items-center mb-3">
					<span className="ge-title ge-h3">Desempeño</span>
				</div>
				{/* Content */}
				<div
                    className="d-flex flex-column justify-content-center align-items-center progress-bar-docente"
                >
                    <div className="d-flex flex-column align-items-center justify-content-center avatar-docente mb-2">
                        <img
                            src={
                                !imagen ? `https://ui-avatars.com/api/?name=${nombres}+${apellidos}&background=random`
                                : imagen.includes("files") 
                                    // ? `${process.env.REACT_APP_API_URL}${imagen}`
                                    ? `${process.env.REACT_APP_API_URL}${imagen}`
                                    : imagen
                            }
                            alt="docente-avatar"
                        />
                        <span className="ge-title">{nombres}{" "}{apellidos}</span>
                    </div>
					<SemiCircleProgressBar
                        diameter={180}
						percentage={progress}
						strokeWidth={10}
						showPercentValue={true}
                        stroke="#9900ef"
					/>
                    <span className="progress-detail mt-3">Total desempeño en calificaciones</span>
				</div>
			</div>
		</div>
	);
};

export default CardDocentePerformance;
