import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQueryReportePagosDocente } from "../../hooks/docentes.hooks";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import CardIngresosDocentes from "../../components/Ingresos/CardIngresosDocentes";
import TablePagoDocente from "../../components/Ingresos/TablePagoDocente";

const IngresosDocente = () => {
  const { user } = useSelector((state) => state.user);
  const { data, isLoading } = useQueryReportePagosDocente(user.id_user);

  return (
    <LayoutAdmin routeTitle="Mis ingresos" contentTitle="Mis ingresos">
      <div className="container card p-4 col-12 col-md-12">
        <h3 className="font-weight-bold">Tus ingresos en Gestora</h3>
        <div className="d-flex justify-content-around align-items-center col-md-12 mt-4 mb-3">
            <CardIngresosDocentes ingresos={data} />
        </div>
        <div className="table-ingreso-docente col-md-12 mt-4">
          <TablePagoDocente reportes={data?.reportes} idDocente={user.id_user} />
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default IngresosDocente;
