import React, { useMemo, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useQuery,useQueryClient,useMutation } from 'react-query';
import { NavLink, useNavigate } from 'react-router-dom';
import HeaderSearchTable from '../../components/Custom/HeaderSearchTable';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import * as BsIcons from 'react-icons/bs'
import * as FaIcons from 'react-icons/fa'
import DataTable from "react-data-table-component";
import LoaderSmall from '../../components/Loaders/LoaderSmall';
import RangePicker from '../../components/DatePickers/RangePicker';
import dayjs from 'dayjs';
import { disableHistory, getHistoriasByParams } from '../../controllers/historias/historias';
import { parseInt } from 'lodash';

const ListarHistorias = () => {

	const [dataConsulta,setDataconsulta]=useState({
		fecha:null,
	})

    const {data : historiasList,error,isLoading} = useQuery(['historias',dataConsulta],()=>getHistoriasByParams(dataConsulta),{
		refetchOnWindowFocus:false,
		retry:0,
	})

	const queryClient = useQueryClient()
    
    const {mutate, error : errorUpdate , isLoading : isLoadingUpdate } = useMutation(disableHistory,{
        onSuccess: ()=>{
            queryClient.invalidateQueries(['historias',dataConsulta])
        }
    })


    const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);

	const handleChangeFecha = (date)=>{
		const fechaFormat={
			startDate:dayjs(date[0]).format('YYYY-MM-DD'),
			endDate:dayjs(date[1]).format('YYYY-MM-DD')
		}
		setDataconsulta({
			...dataConsulta,
			fecha:fechaFormat
		})
	}

	const handleDisableHistory = (id,eliminado)=>{
		//console.log(id)
		mutate({id:id,eliminado:eliminado})
	}


	const navigate = useNavigate()
    	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={historiasList}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
                columnsToFilter={["titulo","Autor","fecha_creacion"]}
				placeholder="Buscar por Título, Autor o Fecha registro"
			/>
		);
	}, [filterText, resetPagination]);

	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: '#',
				cell: (row, index) => index+1,
				width: '5%',
			},
            {
				name: "Fecha registro",
				selector: (row) => row.fecha_creacion,
				sortable: true,
			},
			{
				name: "Titulo",
				selector: (row) => <div style={{textAlign:'left'}}>{row.titulo}</div>,
				sortable: true,
                wrap:true
			},
			{
				name: "Autor",
				selector: (row) => row.nombres,
				sortable: true,
			},
			{
				name: "Status",
				cell: (row) =>
					parseInt(row.estado) === 1 ? (
						<Badge className="ge-deg-primary p-1">Público</Badge>
					) : (
						<Badge bg="danger">Sin publicar</Badge>
					),
				sortable: true,
			},
			{
				name: "Acciones",
				cell: (row) => (
					<div className="d-flex">
						<NavLink  to={`/editar-historia/${row.unique_id}`}
							className="btn ge-btn-primary btn-sm mr-2"
						>
							<FaIcons.FaEdit />
						</NavLink>

						{
							parseInt(row.estado) === 1 ? (
									<button 
										className="btn btn-light btn-sm"
										onClick={() => handleDisableHistory(row.id_historia, 0)}
									>
										<BsIcons.BsToggleOn color="#37af48" size={20} />
									</button>
								):(
									<button 
										className="btn btn-light btn-sm"
										onClick={() => handleDisableHistory(row.id_historia, 1)}
										>
										<BsIcons.BsToggleOff color="#ff0000" size={20} />
									</button>
								)
							}
					</div>
				),
			},
		],
		[]
	);


    return (
		<div className='row'>
			<div className='col-12'>
				<div className="card">
						<div className="d-flex justify-content-between p-3">
							<div>
								<img
									src="/images/file-icon.png"
									className="mr-2"
									style={{ width: "40px" }}
									alt="file-icon"
								/>
								<span className="ge-h3 ge-title">
									Lista de Historias
								</span>
							</div>
							<div>
								<button className='btn ge-btn-primary' onClick={()=>navigate('/crear-historia')}>Crear historia</button>
							</div>
						</div>
						<div className='d-flex justify-content-start p-3'>
								<div style={{marginRight:'10px'}}>
									<label style={{color:'#842eff'}}>Rango de Fecha</label>
									<RangePicker handleChange={handleChangeFecha}/>
								</div>
						</div>
					<div className='card-body'>
					<DataTable
							className="custom-datatable"
							columns={columns}
							data={
								filteredItems.length > 0
									? filteredItems
									: historiasList
							}
							subHeader
							subHeaderComponent={searchComponentMemo}
							pagination
							responsive
							fixedHeader
							fixedHeaderScrollHeight="300px"
							progressPending={isLoading}
							progressComponent={<LoaderSmall />}
						/>
					</div>
				</div>
			</div>
		</div>
    );
};

export default ListarHistorias;