import React from "react";
import TablaIngresos from "../../../components/Contabilidad/Ingresos/TablaIngresos";
import LayoutAdmin from "../../../components/layouts/LayoutAdmin";

const ListaIngresos = () => {
	return (
		<LayoutAdmin contentTitle="" routeTitle="Ingresos">
			<TablaIngresos />
		</LayoutAdmin>
	);
};

export default ListaIngresos;
