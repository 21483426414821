import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // load on demand
import updateLocale from "dayjs/plugin/updateLocale"; // load on demand
import "dayjs/locale/es"; // load on demand
dayjs.extend(relativeTime);
dayjs.locale("es"); // use Spanish locale globally
dayjs.extend(updateLocale);

dayjs.updateLocale("es", {
	relativeTime: {
		future: "dentro de %s",
		past: "hace %s",
		s: "unos segundos",
		m: "un minuto",
		mm: "%d minutos",
		h: "una hora",
		hh: "%d horas",
		d: "un día",
		dd: "%d dias",
		M: "un mes",
		MM: "%d meses",
		y: "un año",
		yy: "%d años",
	},
	months: [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	],
	weekdays: [
		"Domingo",
		"Lunes",
		"Martes",
		"Miércoles",
		"Jueves",
		"Viernes",
		"Sábado",
	],
	weekdaysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
	weekdaysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
	monthsShort: [
		"Ene",
		"Feb",
		"Mar",
		"Abr",
		"May",
		"Jun",
		"Jul",
		"Ago",
		"Sep",
		"Oct",
		"Nov",
		"Dic",
	],
});

export const getDateTimeObject = () => {
	return dayjs;
};
