import { getAuthAxios } from "../../axios/axiosCustom";



export const postResetPassword = async (idUser, password) => {
    const axios = getAuthAxios();
    try {
        const response = await axios.post(`usuario/reset_password/${idUser}`, {password});
        if (response.data.status) {
            return response.data.message;
        } else {
            return null;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
}