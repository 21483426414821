
import React, {useState } from "react";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import FormularioHistoriaContenido from "./FormularioHistoriaContenido";
import FormularioHistoriaVideo from "./FormularioHistoriaVideo";
const CreateHistoria = () => {
    const [tipoHistoria,setTipoHistoria]=useState("historiaContenido");

    const formulariosHistoria = {
        historiaContenido: <FormularioHistoriaContenido isNew={true} />,
        historiaVideo: <FormularioHistoriaVideo  isNew={true} />
    }

    return (
        <LayoutAdmin
        contentTitle="Crear historia"  routeTitle="Historia"
        >
        <div className="row">
            <div className="col-lg-12 p-3">
                <button className="btn ge-btn-primary" style={{marginRight:'10px'}} onClick={()=>setTipoHistoria("historiaContenido")}>
                    Historia con contenido
                </button>
                <button className="btn ge-btn-primary" onClick={()=>setTipoHistoria("historiaVideo")}>
                    Historia video
                </button>
            </div>
        </div>
        {
            tipoHistoria && formulariosHistoria[tipoHistoria]
        }
        </LayoutAdmin>
    );
};

export default CreateHistoria;