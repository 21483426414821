import { useEffect } from "react";
import { useState } from "react";
import { getSchoolSelect } from "../../controllers/select.controller";

export const useSchoolsSelect = () => {
    
    const [schools, setSchools] = useState([]);
    
    const fetchData = async () => {
        const response = await getSchoolSelect();
        if(response){
            setSchools(response);
        }
    }
    
    useEffect(() => {
        
        fetchData();
        
        return () => {
            setSchools([]);
        }
    }, []);
    
    return [schools];
}