import React, { useEffect, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import CustomInputDate from '../Custom/CustomInputDate';
registerLocale('es', es)

const MonthPicker = ({handleChange}) => {
    const [startDate, setStartDate] = useState(new Date());
    useEffect(()=>{
        if(startDate)
        {
            handleChange(startDate)
        }
    },[startDate])

    return (
    <div style={{zIndex:4}}>
            <label style={{color:'#842eff'}}>Mes - Año:</label>
            <ReactDatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                className='form-control'
                locale={'es'}
                customInput={<CustomInputDate />}
            />
    </div>
    );
};

export default MonthPicker;