import dayjs from "dayjs";

export const formatSeconds = (seconds) => {
	if (isNaN(seconds)) {
		return `00:00`;
	}
	const date = new Date(seconds * 1000);
	const hh = date.getUTCHours();
	const mm = date.getUTCMinutes();
	const ss = date.getUTCSeconds().toString().padStart(2, "0");
	if (hh) {
		return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
	}
	return `${mm}:${ss}`;
};

export const formatSecondsHMS = (seconds) => {
	if (isNaN(seconds)) {
		return `0h 0m 0s`;
	}
	const date = new Date(seconds * 1000);
    console.log(seconds,date);
	const hh = date.getUTCHours();
	const mm = date.getUTCMinutes();
	const ss = date.getSeconds();
	if (hh) {
		if (ss === "00") {
			return `${hh}h ${mm.toString().padStart(2, "0")}min`;
		}
		return `${hh}h ${mm.toString().padStart(2, "0")}min ${ss}seg`;
	}
	return `${mm}min ${ss}seg`;
};

export const secondsToString = (seconds) => {
	let hour = Math.floor(seconds / 3600);
	hour = hour < 10 ? "0" + hour : hour;
	let minute = Math.floor((seconds / 60) % 60);
	minute = minute < 10 ? "0" + minute : minute;
	let second = seconds % 60;
	second = second < 10 ? "0" + second : second;
	return hour + ":" + minute + ":" + second;
};

export const decimalToTime = (decimal) => {
	let horas = Math.floor(decimal), // Obtenemos la parte entera
		restoHoras = Math.floor((decimal % 1) * 100), // Obtenemos la parde decimal
		decimalMinutos = (restoHoras * 60) / 100, // Obtenemos los minutos expresado en decimal
		minutos = Math.floor(decimalMinutos), // Obtenemos la parte entera
		restoMins = Math.floor((decimalMinutos % 1) * 100), // Obtenemos la parde decimal
		segundos = Math.floor((restoMins * 60) / 100); // Obtenemos los segundos expresado en entero
    
	return `${("00" + horas).slice(-2)}:${("00" + minutos).slice(-2)}:${(
		"00" + segundos
	).slice(-2)}`;
};

//Formartear minutos en formato X Dias X Horas X Minutos
export const formatMinutes = (min) => {
	if (typeof min !== "number" || !Number.isInteger(min)) {
		return `0d 0h 0m`;
	}

	if (isNaN(min)) {
		return `0d 0h 0m`;
	}
	if (min < 0) {
		return `0d 0h 0m`;
	}

	let min_ = min;

	const days = Math.floor(min_ / 1440);
	min_ = min_ % 1440;

	const hours = Math.floor(min_ / 60);
	min_ = min_ % 60;

	const minutes = min_;

	if (days && days > 0) {
		return `${days}d ${hours}h ${minutes}m`;
	}
	if (hours && hours > 0) {
		return `${hours}h ${minutes}m`;
	}
	return `${minutes}m`;
};

export const formatSeconds_V2 = (seconds) => {
	if (isNaN(seconds)) {
		return `0h 0m 0s`;
	}
	let seconds_ = seconds;
	const days = Math.floor(seconds_ / 86400);
	if (days && days > 0) {
		seconds_ = seconds_ % 86400;
	}
	const hours = Math.floor(seconds_ / 3600);
	if (hours && hours > 0) {
		seconds_ = seconds_ % 3600;
	}
	const minutes = Math.floor(seconds_ / 60);
	if (minutes && minutes > 0) {
		seconds_ = seconds_ % 60;
	}
	const seconds__ = seconds_;

	if (days && days > 0) {
		return `${days}d ${hours}h ${minutes}m ${seconds__}s`;
	}
	if (hours && hours > 0) {
		return `${hours}h ${minutes}m ${seconds__}s`;
	}
	if (minutes && minutes > 0) {
		return `${minutes}m ${seconds__}s`;
	}
	return `${seconds__}s`;
};

export const getDiffDays = (startDate, endDate) => {
	const diffDays = dayjs(endDate).diff(dayjs(startDate), "day") + 1;
	return diffDays;
};

export const getDiffMonths = (startDate, endDate) => {
	const diffMonths = dayjs(endDate).diff(dayjs(startDate), "month") + 1;
	return diffMonths;
};

//Filtrar horas pasadas en el picker de fecha
export const filterPassedTime = (time) => {
	const currentDate = new Date();
	const selectedDate = new Date(time);

	return currentDate.getTime() < selectedDate.getTime();
};



// Format decimal seconds to time
export const formatDecimalSeconds = (seconds) => {
    if (isNaN(seconds)) {
        return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
        return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
}