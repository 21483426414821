import { useMutation, useQuery, useQueryClient } from "react-query";
import { 
    addResources, 
    createClass, 
    deleteResourceById, 
    editClass, 
    getClassById, 
    getResourcesByClass, 
    toggleActiveClass,
    addResourcesUrl,
    deleteClassById,
} from "../controllers/cursos/clases/clase.controller";


const KEY_MODULE = "ONEMODULE";
const KEY_CLASS = "ONECLASS";
const KEY_RESOURCES = "RESOURCES";
const KEY_RESOURCES_URL = "RESOURCESURL";

export const useMutateCreateClass = (idModule) => {
    const queryClient = useQueryClient();
    return useMutation(createClass, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_MODULE, idModule]);
        }
    });
}

export const useQueryClassById = (idClass) => {
    return useQuery(
        [KEY_CLASS, idClass],
        () => getClassById(idClass),
        {
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}

export const useMutateActiveClass = (idModule) => {
    const queryClient = useQueryClient();
    return useMutation(toggleActiveClass, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_MODULE, idModule]);
        }
    });
}

export const useMutateDeleteClassById = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteClassById, {
        onSuccess: () => {
            queryClient.invalidateQueries([KEY_MODULE]);
        }
    });
}

export const useMutateEditClass = (idClass) => {
    const queryClient = useQueryClient();
    return useMutation(editClass, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_CLASS, idClass]);
        }
    });
}

/* Recursos CRUD */
export const useMutateAddResources = (idClass) => {
    const queryClient = useQueryClient();
    return useMutation(addResources, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_RESOURCES, idClass]);
        }
    });
}

export const useMutateDeleteResources = (idClass) => {
    const queryClient = useQueryClient();
    return useMutation(deleteResourceById, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_RESOURCES, idClass]);
        }
    });
}

export const useQueryResourcesByClass = (idClass) => {
    return useQuery(
        [KEY_RESOURCES, idClass],
        () => getResourcesByClass(idClass),
        {
            retry: 2,
            refetchOnWindowFocus: false,
        }
    );
}

/* RESOURCES URL CRUD */

export const useMutateAddResourcesUrl = (idClass) => {
    const queryClient = useQueryClient();
    return useMutation(addResourcesUrl, {
        onSuccess: (data) => {
            queryClient.invalidateQueries([KEY_RESOURCES_URL, idClass]);
        }
    });
}