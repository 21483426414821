import React, { useMemo, useState } from 'react';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import HeaderSearchTable from '../../components/Custom/HeaderSearchTable';
import * as FaIcons from 'react-icons/fa'
import DataTable from "react-data-table-component";
import LoaderSmall from '../../components/Loaders/LoaderSmall';
import CustomModal from '../../components/Modal/CustomModal';
import CreateRoles from './Roles/CreateRoles';
import { useQueryAllRoles } from '../../hooks/userRoles';
import EditRoles from './Roles/EditRoles';

const ListaRoles = () => {
  const [filterText, setFilterText] = useState("");
  const [filteredItems, setFilteredItems] = useState();
  const [resetPagination, setResetPagination] = useState(false);
  const [ showCreate, setShowCreate ] = useState(false);
  const [ showEdit, setShowEdit ] = useState(false);
  const [ rol, setRol ] = useState()

  const {data: roles, isLoading} = useQueryAllRoles();

        	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={roles}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
        columnsToFilter={["nombre_rol", "modulos"]}
				placeholder="Buscar por rol o modulos"
			/>
		);
	}, [filterText, resetPagination]);


  /* Config Table */
	const columns = useMemo(
    () => [
      {
        name: "#",
        cell: (row, index) => index + 1,
        width: "5%",
      },
      {
        name: "Nombre del Rol",
        selector: (row) => row.nombre_rol.toUpperCase(),
        sortable: true,
      },
      {
        name: "Modulos asignados",
        selector: (row) => (row.modulos.length),
        sortable: true,
      },
      {
        name: "Acciones",
        cell: (row) => (
          <div className="d-flex">
            <button
                className="btn ge-btn-primary btn-sm mr-2"
                onClick={()=> handleEditRol(row)}
            >
                <FaIcons.FaEdit />
            </button>   
          </div>
        )}
    ],[]);


    const handleEditRol = (dataRol) => {
      setRol(dataRol)
      setShowEdit(true)
    };

  return (
    <LayoutAdmin contentTitle = "" routeTitle="Lista de roles">
      <CustomModal title={'Crear Rol'} show={showCreate} handleShow={setShowCreate}>
        <CreateRoles handleShow={setShowCreate}/>
      </CustomModal>
      <CustomModal title={'Editar Rol'} show={showEdit} handleShow={setShowEdit}>
            <EditRoles handleShow={setShowEdit} dataRol={rol}/>
        </CustomModal>
        <div className='row'>
                <div className='col-12'>
                    <div className="card p-2">
                      <div className="card-header ge-noborder">
                        <div className="d-flex justify-content-between">
                          <div>
                            <img
                              src="/images/file-icon.png"
                              className="mr-2"
                              style={{ width: "40px" }}
                              alt="file-icon"
                            />
                            <span className="ge-h3 ge-title">
                              Lista de Roles
                            </span>
                          </div>
                          <div className="">
                            <button
                              className="btn ge-btn-primary btn-sm"
                              onClick={() => setShowCreate(true)}
                            >
                              <FaIcons.FaPlus />
                              <span className="ml-2">Nuevo Rol</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <DataTable
                          className="custom-datatable"
                          columns={columns}
                          data={
                            filteredItems?.length > 0
                              ? filteredItems
                              : roles
                          }
                          subHeader
                          subHeaderComponent={searchComponentMemo}
                          pagination
                          responsive
                          fixedHeader
                          fixedHeaderScrollHeight="300px"
                          progressPending={isLoading}
                          progressComponent={<LoaderSmall />}
                        />
                        </div>
                    </div>
                </div>
           </div>
    </LayoutAdmin>
  )
}

export default ListaRoles