import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import { useMutation, useQueryClient } from "react-query";
import { createEscuela } from "../../controllers/escuelas/escuelas";
import { useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import { useRef } from "react";
import { createEnVivo } from "../../controllers/lives/lives.controller";

const CrearLivesV2 = () => {
    
    const btnImageRef = useRef(null);
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const {
		mutate,
		error: errorCreate,
		isLoading: isLoadingCreate,
	} = useMutation(createEnVivo, {
		onSuccess: () => {
			queryClient.invalidateQueries("getEnVivos");
			navigate("/lives/listar-envivos", { replace: true });
		},
	});

	const initialValues = {
		url_envivo: "",
		texto_boton: "",
		enlace_boton: "",
		color_boton:"",
		archivo: {},
	};

	const [datosEscuela, setDatosEscuela] = useState(initialValues);
	const [renderImage, setRenderImage] = useState(null);
	const [isLive,setIsLive]=useState(false);
	const SUPPORTED_FORMATS = [
		"image/jpg",
		"image/jpeg",
		"image/gif",
		"image/png",
	];

	const validationSchema = yup.object({
		texto_boton: yup.string().required("El campo es requerido"),
		enlace_boton: yup.string().required("El campo es requerido"),
		color_boton: yup.string().required("El campo es requerido"),
		url_envivo: yup.string().required("El campo es requerido"),
		archivo: yup
			.mixed()
			.required("Debes seleccionar una imagen")
			.test(
				"fileFormat",
				"Archivo no permitido",
				(value) => value && SUPPORTED_FORMATS.includes(value.type)
			),
	});

	const formik = useFormik({
		initialValues: datosEscuela,
		validationSchema,
		onSubmit: async (values) => {
			const datos = { ...values };
			datos.isLive=isLive?1:0;
			//console.log(datos);
			mutate(datos);
		},
	});

	useEffect(() => {
		if (formik.values.archivo.name) {
			setRenderImage(URL.createObjectURL(formik.values.archivo));
		}
	}, [formik.values.archivo]);

	return (
		<LayoutAdmin contentTitle="" routeTitle="Crear en vivo">
			<form onSubmit={formik.handleSubmit}>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
                            <div className="card-header ge-bg-primary txt-white">
                                <h3 className="card-title">Crear en vivo</h3>
                            </div>
							<div className="card-body">
								<div className="d-flex flex-column flex-md-row justify-content-md-between">
						
									<div className="col-md-12">
										<div className='crear-live-option'>
											<a className="btn ge-btn-deg-primary"
											onClick={() => setIsLive(false)}
											style={{ opacity: isLive ? '.60' : '1' }}>
											Video
											</a>
											<a className="btn ge-btn-deg-primary"
											onClick={() => setIsLive(true)}
											style={{ opacity: !isLive ? '.60' : '1' }}>
											Transmisión
											</a>
										</div>
										<div className="form-group mb-3">
											<label> {!isLive?'Link del video':'Link de la transmisión'} </label>
											<input
												type="text"
												className="form-control ge-form"
												placeholder={!isLive?'Link del video de Vimeo':'Link de la transmisión youtube'}
												id="url_envivo"
												name="url_envivo"
												onChange={formik.handleChange}
												value={
													formik.values.url_envivo
												}
											/>
											<p>
												{" "}
												{
													formik.errors.url_envivo
												}{" "}
											</p>

										</div>
										<div className="form-group mb-3">
											<label>Texto botón:</label>
											<input
												type="text"
												className="form-control ge-form"
												placeholder="Texto del botón"
												id="texto_boton"
												name="texto_boton"
												onChange={formik.handleChange}
												value={
													formik.values.texto_boton
												}
											/>
											<p>
												{" "}
												{
													formik.errors.texto_boton
												}{" "}
											</p>
										</div>
										<div className="form-group mb-3">
										<label>Banner publicitario:</label>
										<div className="d-flex justify-content-center align-items-center img-form-preview" style={{maxWidth:'50%',margin:'auto'}}>
                                            {formik.values.archivo ? (
                                                formik.values.archivo
                                                    .name ? (
                                                    <img
                                                        src={renderImage}
                                                        alt={"..."}
                                                        style={{
                                                            objectFit:"cover",
                                                            maxWidth:"100%",
                                                            borderRadius:"10px",
                                                        }}
                                                    />
                                                ) : (
                                                    <img
                                                        src="/images/gestora-placeholder.png"
                                                        alt={"..."}
                                                        style={{
                                                            objectFit:"cover",
                                                            maxWidth:"100%",
                                                            borderRadius:"10px",
                                                        }}
                                                    />
                                                )
                                            ) : null}
                                            <div
                                                title="Seleccionar imagen"
                                                className="btn-ref-file"
                                                onClick={()=>btnImageRef.current.click()}
                                            >
                                                <FaIcons.FaFileUpload />
                                            </div>
                                        </div>
                                        <p> {formik.errors.archivo} </p>
										<div className="d-none form-group mb-3">
											<label>Banner publicitario:</label>
											<input
                                                ref={btnImageRef}
												className="form-control"
												id="archivo"
												type="file"
												name="archivo"
												onChange={(e) => {
													formik.setFieldValue(
														"archivo",
														e.target.files[0]
													);
												}}
											/>
										</div>
										</div>
										<div className="row my-3">
										<div className="form-group mb-3 col-md-3 crear-live-color-picker p-0">
											<label>Color botón</label>
											<div>
												<input
												
												type='color'
												name="color_boton"
												id="color_boton"
												onChange={formik.handleChange}
												value={
													formik.values.color_boton
												}
													/>
												<span>{formik.values.color_boton =="" ? "Escoge un color":formik.values.color_boton}</span>
	
											</div>
											<p>
													{" "}
													{
														formik.errors.color_boton
													}{" "}
												</p>
										</div>
										<div className="form-group col-md-6 mb-3">
											<label>Enlace botón</label>
											<input
												type="text"
												id="enlace_boton"
												className="form-control ge-form"
												placeholder="Enlace del botón"
												name="enlace_boton"
												onChange={formik.handleChange}
												value={
													formik.values.enlace_boton
												}
											/>
											<p>
												{" "}
												{
													formik.errors.enlace_boton
												}{" "}
											</p>
										</div>
										<div className="form-group col-md-3 mb-3">
										<label> {" "} </label>
											<button
												type="submit"
												className="btn ge-btn-deg-primary btn-block mt-2"
											>
												Guardar
											</button>
										</div>
										</div>
									
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</LayoutAdmin>
	);
};

export default CrearLivesV2;
