import React, { useMemo } from "react";
import { useQueryUltimoReporteDocente } from "../../hooks/docentes.hooks";
import DataTable from "react-data-table-component";
import * as AiIcons from "react-icons/ai";
import LoaderSmall from "../../components/Loaders/LoaderSmall";

const TablePagoDocente = ({ reportes, idDocente }) => {

  const {data: ultimoReporte} = useQueryUltimoReporteDocente(idDocente)

  /* Config Table */
  const columns = useMemo(
    () => [
      {
        name: "Reporte",
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-center p-2">
            <img
              src="/images/file-icon.png"
              alt="document"
              style={{ width: "30px" }}
            />
            <p className="m-0 ml-2">{`${
              row.nombre_docente
            } - Reporte ${new Date(row.fecha_pago).toLocaleDateString("es-PE", {
              month: "long",
            })}`}</p>
          </div>
        ),
      },
      {
        name: "Fecha",
        selector: (row) => new Date(row.fecha_registro).toLocaleDateString(),
      },
      {
        name: "Ingreso total",
        selector: (row) => `s/.${row.monto}.00`,
      },
      {
        name: "",
        cell: (row) => (
          <a
            href={process.env.REACT_APP_API_URL + row.comprobante}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="d-flex jusitfy-content-start align-items-center">
              <AiIcons.AiOutlineDownload
                color="rgb(102, 16, 242)"
                size={"18px"}
              />
              <p
                className="m-0 p-0 ml-2"
                style={{ color: "rgb(102, 16, 242)", fontSize: "18px" }}
              >
                Descargar
              </p>
            </span>
          </a>
        ),
        width: "20%",
      },
    ],
    []
  );

  return (
    <div className="d-flex flex-column">
      <div className="px-5 mt-4">
        <div className="d-flex justify-content-between align-items-center px-5 pt-4">
          <h3 style={{ color: "#834ed8", fontWeight: "600" }}>
            Reporte de ingresos
          </h3>
          <a
            href={process.env.REACT_APP_API_URL+ultimoReporte}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              className="btn ge-btn-primary"
              style={{ borderRadius: "15px", width: "220px" }}
            >
              Descargar último reporte
            </button>
          </a>
        </div>
      </div>
      <div className="row px-5">
        <div className="card-body px-5">
          <DataTable
            className="custom-datatable"
            columns={columns}
            data={reportes}
            responsive
            pagination
            fixedHeader
            progressPending={""}
            progressComponent={<LoaderSmall />}
          />
        </div>
      </div>
    </div>
  );
};

export default TablePagoDocente;
