import React, { useEffect, useState } from "react";
import { getSelectCampaigns } from "../../../controllers/marketing/facebook/facebook.controller";
import HeaderSearchTable from "../../Custom/HeaderSearchTable";

const CustomHeader = ({
	filterText,
	setFilterText,
	arrayData = [],
	setArrayFiltered,
	setResetPagination,
	placeholder = "Buscar",
	columnsToFilter = ["nombres", "apellidos", "correo"],
	className="row w-100 mb-4",
}) => {
    
    
    const [selectTypeCampaign, setSelectTypeCampaign] = useState([]);
    const [optionSelected, setOptionSelected] = useState("");
    
    const fetchData = async () => {
        const response = await getSelectCampaigns();
        if(response) {
            setSelectTypeCampaign(response);
        }
    }
    
    
	const onFilterItems = () => {
		if (optionSelected && optionSelected !== "all") {
            const newFilteredItems = arrayData.filter(
                (item) => 
                    parseInt(item.id_tp_campaign) === parseInt(optionSelected)
            );
            if(newFilteredItems.length > 0) {
                setArrayFiltered(newFilteredItems);
            }else{
                setArrayFiltered([]);
                setResetPagination(true);
            }
        } else {
			setArrayFiltered([]);
			setResetPagination(true);
		}
	};
    
	useEffect(() => {
		if(arrayData.length > 0) {
			onFilterItems();
		}
	}, [optionSelected]);
    
    useEffect(() => {
        fetchData();
    }, [])
    
	return (
		<div className={className}>
			<div className="col-12 col-md-6">
                <label className="txt-small" htmlFor="status">Filtrar por tipo de campaña:</label>
                <select 
                    name="status"
                    className="form-control ge-form"
                    onChange={(e) => setOptionSelected(e.target.value)}
                    value={optionSelected}
                >
                    <option disabled value="">--- Seleccione una opción ---</option>
                    <option value="all"> Todos </option>
                    {
                        selectTypeCampaign.length > 0 && selectTypeCampaign.map((item, index) => (
                            <option key={item.id_registro} value={item.id_registro}>{item.nombre}</option>
                        ))
                    }
                </select>
            </div>
            <HeaderSearchTable
                filterText={filterText}
                setFilterText={setFilterText}
                arrayData={arrayData}
                setArrayFiltered={setArrayFiltered}
                setResetPagination={setResetPagination}
                placeholder={placeholder}
                columnsToFilter={columnsToFilter}
                className="col-12 col-md-6 d-flex align-items-center mt-4"
            />
		</div>
	);
};

export default CustomHeader;
