import React, { useEffect, useMemo, useRef, useState } from "react";
import LayoutAdmin from "../components/layouts/LayoutAdmin";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as GameIcons from 'react-icons/gi';
import EditDocente from "../components/Docentes/Modals/EditDocente";
import DataTable from "react-data-table-component";
import { Badge } from "react-bootstrap";
import HeaderSearchTable from "../components/Custom/HeaderSearchTable";
import LoaderSmall from "../components/Loaders/LoaderSmall";
import { useMutateDeleteDocenteById, useMutateToggleActiveDocente, useQueryDocentes } from "../hooks/docentes.hooks";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { setDocenteEditAction } from "../redux/actions/docenteActions";
import DetailsDocente from "../components/Docentes/DetailsDocente";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const ListaDocentesScreen = () => {
	const navigate = useNavigate();
	const refDetails = useRef(null);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
	const dispatch = useDispatch();
	const [showDetails, setShowDetails] = useState(false);
	const [propsDetails, setPropsDetails] = useState({
		idDocente: null,
		nombres: "",
		apellidos: "",
		imagen: "",
		eliminado: null,
	});

			/* Traer datos del Redux */
	const { user } = useSelector(( state ) => state.user)
	
	
	/* Queries */
	const {
		data: docentesList,
		isLoading,
	} = useQueryDocentes();
	
	const {mutate: mutateToggleActive} = useMutateToggleActiveDocente();

	const { mutate: mutateDeleteDocente } = useMutateDeleteDocenteById();
	
	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={docentesList}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
				placeholder="Buscar por nombre, apellido o correo"
			/>
		);
	}, [filterText, resetPagination]);
	
	const handleToggleActiveDocente = (idDocente, isEliminated) => {
		Swal.fire({
			title: '¿Estás seguro?',
			text: "El cambio del estado se verá reflejado en el sistema",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, cambiar!',
			cancelButtonText: 'Cancelar'
		}).then((result) => {
			if (result.isConfirmed) {
				//console.log('swal',idDocente, isEliminated);
				mutateToggleActive({
					idDocente,
					isEliminated
				},{
					onSuccess: () => {
						Swal.fire(
							'Éxito!!',
							'Estado cambiado correctamente',
							'success'
						)
					}
				});
				
			}
		})
	}
	
	const handleShowModalEdit = (dataRow) => {
		const docenteData_ = {
			idDocente: dataRow.id_user,
			nombres: dataRow.nombres,
			apellidos: dataRow.apellidos,
			correo: dataRow.correo,
			telefono: dataRow.contacto,
			imgPerfil: dataRow.img_perfil,
			about: dataRow.sobre_mi,
		}
		
		dispatch(setDocenteEditAction(docenteData_));
		setShowModalEdit(true);
	};
	
	
	const handleShowDetails = (dataRow) => {
		const docenteData_ = {
			idDocente: dataRow.id_user,
			nombres: dataRow.nombres,
			apellidos: dataRow.apellidos,
			imagen: dataRow.img_perfil,
			eliminado: dataRow.eliminado,
		}
		setPropsDetails(docenteData_);
		setShowDetails(true);
	}

	const HandleSendToHabilidadesDocente = (id_docente) => {
		navigate(`/listar-docentes/habilidades-docente/${id_docente}`);
	}
	
	useEffect(() => {
		if(showDetails && refDetails.current){
			refDetails.current.scrollIntoView({
				behavior: "smooth"
			});
		}
	}, [showDetails, propsDetails]);
	
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: '#',
				cell: (row, index) => index+1,
				width: '5%',
			},
			{
				name: "Ver detalle",
				cell: (row) => (
					<BsIcons.BsFillEyeFill
						size={20}
						color="#992AFF"
						className="column-details"
						onClick={() => handleShowDetails(row)}
					/>
				)
			},
			{
				name: "Nombre",
				selector: (row) => row.nombres,
				sortable: true,
			},
			{
				name: "Apellido",
				selector: (row) => row.apellidos,
				sortable: true,
			},
			{
				name: "Cargo",
				selector: (row) =>
					parseInt(row.cargo) === 3 ? "Docente" : "Otro Cargo",
			},
			{
				name: "Status",
				cell: (row) =>
					parseInt(row.eliminado) === 0 ? (
						<Badge className="ge-deg-primary p-1">Activo</Badge>
					) : (
						<Badge className="bg-danger">Inactivo</Badge>
					),
				sortable: true,
			},
			{
				name: "Contacto",
				cell: (row) => (
					row.contacto ? (
						<>
							<FaIcons.FaPhone className="text-primary mr-1" />
							{row.contacto}
						</>
					) : (
						<BsIcons.BsX className="text-danger" />
					)
				)
				// selector: (row) => row.contacto,
			},
			{
				name: "Correo",
				selector: (row) => row.correo,
				grow: 2,
			},
			{
				name: "Acciones",
				cell: (row) => (
					parseInt(row.eliminado) === 0 ? (
						<div className="d-flex">
							<button
								className="btn ge-btn-deg-primary btn-sm mr-2"
								onClick={() => handleShowModalEdit(row)}
							>
								<FaIcons.FaEdit />
							</button>
							<button
								className='btn ge-btn-primary btn-sm mr-2'
								onClick={() => HandleSendToHabilidadesDocente(row.id_user)}
							>
								<GameIcons.GiSkills/>
							</button>
							<button 
								className="btn btn-light btn-sm"
								onClick={() => handleToggleActiveDocente(row.id_user, 1)}
							>
								<BsIcons.BsToggleOn color="#37af48" size={20} />
							</button>
							{ user.roles.includes("2") && (
								<button
									className="btn btn-danger btn-sm ml-2"
									onClick={()=>  handleDeleteDocente(row.id_user)}
								>
									<FaIcons.FaTrash />
								</button>
							)}
						</div>
					) : (
						<div className="d-flex">
							<button
								className="btn ge-btn-primary btn-sm mr-2"
								onClick={() => handleShowModalEdit(row)}
							>
								<FaIcons.FaEdit />
							</button>
							<button
								className='btn ge-btn-primary btn-sm mr-2'
								onClick={() => HandleSendToHabilidadesDocente(row.id_user)}
							>
								<GameIcons.GiSkills/>
							</button>
							<button 
								className="btn btn-light btn-sm"
								onClick={() => handleToggleActiveDocente(row.id_user, 0)}
							>
								<BsIcons.BsToggleOff color="#ff0000" size={20} />
							</button>
							{ user.roles.includes("2") && (
								<button
									className="btn btn-danger btn-sm ml-2"
									onClick={()=>  handleDeleteDocente(row.id_user)}
								>
									<FaIcons.FaTrash />
								</button>
							)}
						</div>
					)
				),
			},
		],
		[]
	);
	
	const handleDeleteDocente = (id_docente) => {
		mutateDeleteDocente(id_docente)
	};
	
	return (
		<LayoutAdmin contentTitle="" routeTitle="Lista de docentes">
			<div className="row">
				<div className="col-12">
					<div className="card p-2">
						<div className="card-header ge-noborder">
							<>
								<img
									src="/images/file-icon.png"
									className="mr-2"
									style={{ width: "40px" }}
									alt="file-icon"
								/>
								<span className="ge-h3 ge-title">
									Lista de docentes
								</span>
							</>
						</div>
						<div className="card-body">
							{
								showModalEdit && (
									<EditDocente
										show={showModalEdit}
										setShow={setShowModalEdit}
									/>
								)
							}
							<DataTable
								className="custom-datatable"
								columns={columns}
								data={
									filteredItems.length > 0
										? filteredItems
										: docentesList
								}
								subHeader
								subHeaderComponent={searchComponentMemo}
								pagination
								responsive
								fixedHeader
								fixedHeaderScrollHeight="300px"
								progressPending={isLoading}
								progressComponent={<LoaderSmall />}
							/>
						</div>
					</div>
				</div>
			</div>
			{
				showDetails && (
					<DetailsDocente
						{...propsDetails}
						ref={refDetails}
					/>
				)
			}
		</LayoutAdmin>
	);
};

export default ListaDocentesScreen;
