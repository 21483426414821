import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { deleteQuestion } from "../../controllers/cursos/examen/examen.controller";
import * as FaIcons from "react-icons/fa";

const Pregunta = ({ pregunta, onEdit }) => {
	const queryClient = useQueryClient();
    
	const { mutate: eliminarPregunta } = useMutation(deleteQuestion, {
		onSuccess: () => {
			queryClient.refetchQueries(["Preguntas"]);
		},
	});
    
	return (
		<div
			className="py-3 px-2 d-flex justify-content-between my-2"
			style={{ borderBottom: "1px dashed #ebebeb" }}
		>
			<div className="me-2">
				<h5>{pregunta.pregunta}</h5>
				<p>Clase de Referencia: {pregunta.titulo_clase}</p>
			</div>
			<div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
                <button
                    className="btn btn-editar-pregunta text-light mb-2 mb-md-0 mr-md-2"
                    type="button"
                    title="Editar pregunta"
                    onClick={() => onEdit(pregunta.id_pregunta)}
                >
                    <FaIcons.FaEdit />
                </button>
				<span
					className="btn btn-eliminar-pregunta text-light"
					onClick={() => eliminarPregunta(pregunta.id_pregunta)}
                    title="Eliminar pregunta"
				>
					{" "}
					<i className="fa fa-trash" aria-hidden="true"></i>{" "}
				</span>
			</div>
		</div>
	);
};

export default Pregunta;
