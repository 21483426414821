import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getStudentInfo } from "../../controllers/estudiantes/estudiantes.controller";
import LoaderCard from "../Loaders/LoaderCard";
import MyCoursesCard from "./Cards/MyCoursesCard";
import MyProgressCard from "./Cards/MyProgressCard";
import MyRoutesCard from "./Cards/MyRoutesCard";
import MySchoolsCard from "./Cards/MySchoolsCard";
import ResetPasswordCard from "./Cards/ResetPasswordCard";

const ModalEstudianteInfo = ({ idStudent, show, setShow, handleClose }) => {
    
	const [studentInfo, setStudentInfo] = useState(null);
    // const [loading, setLoading] = useState(true);
    
	const loadStudentInfo = async (idStudent) => {
		const response = await getStudentInfo(idStudent);
		if (response) {
			setStudentInfo(response);
		} else {
			setStudentInfo([]);
		}
	};
    
	useEffect(() => {
        if (idStudent) {
            loadStudentInfo(idStudent);
        }else{
            setStudentInfo([]);
        }
	}, [idStudent]);
    
	return (
		<Modal
			show={show}
			onHide={handleClose}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			// backdrop="static"
		>
			<Modal.Body>
				{(studentInfo && studentInfo !== []) ? (
					<div className="row">
						<div className="col-12 col-md-6 my-2">
							<MySchoolsCard
                                schools={studentInfo.schools}
                            />
						</div>
						<div className="col-12 col-md-6 my-2">
							<MyCoursesCard
                                courses={studentInfo.courses}
								idStudent={idStudent}
                            />
						</div>
						<div className="col-12 col-md-6 my-2">
							<MyProgressCard
                                avgStudy={studentInfo.avgStudy}
                                examResults={studentInfo.examResults}
								endedCourses={studentInfo.endedCourses}
                            />
						</div>
						<div className="col-12 col-md-6 my-2">
							<MyRoutesCard
                                routes={studentInfo.routes}
                            />
						</div>
						<div className="col-12 col-md-6 my-2">
							<ResetPasswordCard
								idStudent={idStudent}
							/>
						</div>
					</div>
				) : studentInfo && studentInfo === [] ? (
					<div className="row">
						<div className="alert alert-info w-100 py-1">
							<p>No hay información disponible</p>
						</div>
					</div>
				) : (
					<div className="row d-flex justify-content-center">
						<LoaderCard />
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default ModalEstudianteInfo;
