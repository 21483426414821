import { useFormik } from "formik";
import React, { useMemo, useRef, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import * as FaIcons from "react-icons/fa";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import { useQueryClient } from "react-query";
import { useMutateDocente } from "../../hooks/docentes.hooks";
import { useSelector } from "react-redux";

const EditDocenteScreen = () => {
    
    
	const { docenteEdit } = useSelector((state) => state.docente);
	const [loading, setLoading] = useState(false);
	const [imagePreview, setImagePreview] = useState(null);
	const imgInputRef = useRef();
	const queryClient = useQueryClient();
	const { data, isError, isLoading, isSuccess, mutate } = useMutateDocente();
    
	const initialValues = useMemo(
		() => ({
			nombres: "",
			apellidos: "",
			correo: "",
			telefono: "",
			pais: "",
			escuela: "",
			descripcion: "",
			imagen: "",
		}),
		[]
	);
    
	const validationSchema = yup.object({
		nombres: yup.string().min(2).required("El nombre es requerido"),
		apellidos: yup.string().min(2).required("El apellido es requerido"),
		correo: yup
			.string()
			.email("El correo no es válido")
			.required("El correo es requerido"),
		pais: yup.string().required("El país es requerido"),
		telefono: yup.string().min(5).required("El teléfono es requerido"),
		escuela: yup.string().required("La escuela es requerida"),
		descripcion: yup.string(),
	});
    
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			//console.log(values);
			mutate(values, {
				onSuccess: (data) => {
					console.log("onsuccess", data);
				},
			});
			console.log("use", data);
		},
	});
    
	//Cambia la imagen de perfil
	const handleChangeImage = (e) => {
		e.preventDefault();
		setLoading(true);
		if (imgInputRef.current.files.length > 0) {
			//validar solo imagenes
			const file = e.target.files[0];
			if (file.type.indexOf("image") < 0) {
				setLoading(false);
				return;
			}
			// console.log(file);
			setImagePreview(URL.createObjectURL(file));
			formik.setFieldValue("imagen", file);
			setLoading(false);
		}
	};

	return (
		<LayoutAdmin contentTitle="" routeTitle="Crear Docente">
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-header ge-noborder">
							<span className="ge-h3 ge-title">
								Crear Docente
							</span>
						</div>
						<div className="card-body">
							<Form
								className="row form-add-docente"
								onSubmit={formik.handleSubmit}
							>
								{/* Img perfil */}
								<div className="col-12 d-flex justify-content-center mb-md-3 img-profile-container">
									<img
										src={
											!imagePreview
												? "https://via.placeholder.com/150?text=Sube+una+imagen"
												: imagePreview
										}
										alt="..."
										className="img-profile"
									/>
									<div className="button-container">
										<input
											type="file"
											ref={imgInputRef}
											onChange={(e) => {
												handleChangeImage(e);
											}}
											className="d-none"
											disabled={loading}
											accept="image/*"
										/>
										{loading ? (
											<span>
												<Spinner
													animation="border"
													variant="primary"
													className="mt-2"
												/>
											</span>
										) : (
											<span
												className="img-profile-button text-center"
												onClick={() =>
													imgInputRef.current.click()
												}
											>
												<FaIcons.FaPencilAlt />
											</span>
										)}
									</div>
								</div>
								<Form.Group
									controlId="formBasicName"
									className="col-md-6 my-2"
								>
									<Form.Label>Nombres</Form.Label>
									<Form.Control
										type="text"
										placeholder="Nombres"
										name="nombres"
										onChange={formik.handleChange}
										value={formik.values.nombres}
										isValid={
											formik.touched.nombres &&
											!formik.errors.nombres
										}
										isInvalid={
											formik.touched.nombres &&
											!!formik.errors.nombres
										}
									/>
									<Form.Control.Feedback type="invalid">
										{formik.errors.nombres}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group
									controlId="formBasicLastName"
									className="col-md-6 my-2"
								>
									<Form.Label>Apellidos</Form.Label>
									<Form.Control
										type="text"
										placeholder="Apellidos"
										name="apellidos"
										onChange={formik.handleChange}
										value={formik.values.apellidos}
										isValid={
											formik.touched.apellidos &&
											!formik.errors.apellidos
										}
										isInvalid={
											formik.touched.apellidos &&
											!!formik.errors.apellidos
										}
									/>
									<Form.Control.Feedback type="invalid">
										{formik.errors.apellidos}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group
									controlId="formBasicEmail"
									className="col-md-6 my-2"
								>
									<Form.Label>Correo</Form.Label>
									<Form.Control
										type="email"
										placeholder="Correo"
										name="correo"
										onChange={formik.handleChange}
										value={formik.values.correo}
										isValid={
											formik.touched.correo &&
											!formik.errors.correo
										}
										isInvalid={
											formik.touched.correo &&
											!!formik.errors.correo
										}
									/>
									<Form.Control.Feedback type="invalid">
										{formik.errors.correo}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group
									controlId="formBasicPhone"
									className="col-md-6 my-2"
								>
									<Form.Label>Teléfono</Form.Label>
									<PhoneInput
										regions={["south-america"]}
										country={"pe"}
										value={formik.values.telefono}
										onChange={(phone, country) => {
											formik.setFieldValue(
												"telefono",
												phone
											);
											formik.setFieldValue(
												"pais",
												country.name.toUpperCase()
											);
										}}
										autoFormat
										inputClass="form-control my-1 w-100"
										countryCodeEditable={false}
										masks={{
											pe: "... ... ...",
										}}
										isValid={(value, country) => {
											if (
												formik.touched.telefono &&
												!!formik.errors.telefono
											) {
												return "Número de celular inválido";
											} else {
												return true;
											}
										}}
									/>
								</Form.Group>
								<Form.Group
									controlId="formBasicSchool"
									className="col-12 my-2"
								>
									<Form.Label>Escuela</Form.Label>
									<Form.Select
										className="form-control"
										name="escuela"
										onChange={formik.handleChange}
										value={formik.values.escuela}
										isValid={
											formik.touched.escuela &&
											!formik.errors.escuela
										}
										isInvalid={
											formik.touched.escuela &&
											!!formik.errors.escuela
										}
									>
										<option value="">
											Seleccione una escuela
										</option>
										<option value="1">Escuela 1</option>
										<option value="2">Escuela 2</option>
										<option value="3">Escuela 3</option>
									</Form.Select>
									<Form.Control.Feedback type="invalid">
										{formik.errors.escuela}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group
									controlId="formBasicDescription"
									className="col-md-12 my-2"
								>
									<Form.Label>Descripción</Form.Label>
									<Form.Control
										as="textarea"
										rows="3"
										placeholder="Descripción"
										name="descripcion"
										onChange={formik.handleChange}
										value={formik.values.descripcion}
										isValid={
											formik.touched.descripcion &&
											!formik.errors.descripcion
										}
									/>
									<Form.Control.Feedback type="invalid">
										{formik.errors.descripcion}
									</Form.Control.Feedback>
								</Form.Group>
								<button
									type="submit"
									className="btn ge-btn-primary btn-block my-2"
									disabled={isLoading}
								>
									{isLoading ? (
										<Spinner
											as="span"
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
										/>
									) : (
										"Crear docente"
									)}
								</button>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</LayoutAdmin>
	);
};

export default EditDocenteScreen;
