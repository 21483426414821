import React, { useMemo, useState } from 'react';
import { Badge } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import HeaderSearchTable from '../../components/Custom/HeaderSearchTable';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import LoaderSmall from '../../components/Loaders/LoaderSmall';
import {
  ToggleRuta,
  getRutas,
} from '../../controllers/rutas/rutas';
import * as FaIcons from 'react-icons/fa';
import * as BsIcons from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useMutateDeleteRutaById } from '../../hooks/rutas.hooks';

const ListarRutas = () => {
  const {
    data: rutasList,
    error,
    isLoading,
  } = useQuery(['getRutas'], getRutas, {
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();

  const {
    mutate,
    error: errorUpdate,
    isLoading: isLoadingUpdate,
  } = useMutation(ToggleRuta, {
    onSuccess: () => {
      queryClient.invalidateQueries(['getRutas']);
    },
  });

  /* Traer datos del Redux */
  const { user } = useSelector((state) => state.user);

  const { mutate: mutateDeleteRuta } = useMutateDeleteRutaById();

  const [filterText, setFilterText] = useState('');
  const [resetPagination, setResetPagination] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  /* Search Component */
  const searchComponentMemo = useMemo(() => {
    return (
      <HeaderSearchTable
        filterText={filterText}
        setFilterText={setFilterText}
        arrayData={rutasList}
        setArrayFiltered={setFilteredItems}
        setResetPagination={setResetPagination}
        columnsToFilter={['nombre_ruta', 'fecha_creacion']}
        placeholder='Buscar por nombre de ruta o fecha de creación'
      />
    );
  }, [filterText, resetPagination]);
  console.log(rutasList);
  /* Config Table */
  const columns = useMemo(
    () => [
      {
        name: '#',
        cell: (row) => row.id_ruta,
        width: '50px',
      },
      {
        name: 'Nombre Ruta',
        selector: (row) => row.nombre_ruta,
        width: '250px',
      },
      {
        name: 'Status',
        cell: (row) =>
          <Badge className='ge-deg-primary p-1'>Activo</Badge>,
        width: '100px',
      },
      {
        name: 'Tipo ruta',
        cell: (row) =>
          parseInt(row.id_tipo_ruta) === 1 ? (
            <Badge className='ge-deg-primary p-1'>Normal</Badge>
          ) : (
            <Badge className='p-1' bg='danger'>
              Live
            </Badge>
          ),
          width: '100px',
        },
      {
        name: 'Fecha de estreno',
        cell: (row) =>
          row.fecha_inicio
            ? dayjs(row.fecha_inicio).format('DD/MM/YYYY h:mm A')
            : '-',
        width: '130px',
      },
      {
        name: 'Fecha registro',
        cell: (row) => dayjs(row.fecha_creacion).format('DD/MM/YYYY'),
        width: '120px',
      },
      {
        name: 'Publicado',
        cell: (row) => (
          <button
            className='btn btn-light btn-sm'
            title={parseInt(row.eliminado) === 0 ? 'Deshabilitar' : 'Activar'}
            onClick={() =>
              mutate({
                id_ruta: row.id_ruta,
                eliminado: parseInt(row.eliminado) === 1 ? 0 : 1,
              })
            }>
            {parseInt(row.eliminado) === 0 ? (
              <BsIcons.BsToggleOn color='#37af48' size={20} />
            ) : (
              <BsIcons.BsToggleOff color='#ff0000' size={20} />
            )}
          </button>
        ),
        width: '120px',
      },
      {
        name: 'Acciones',
        width: '100px',
        cell: (row) => (
          <div className='d-flex'>
            <NavLink
              to={`/editar-ruta/${row.cod_unico}`}
              className='btn ge-btn-primary btn-sm mr-2'>
              <FaIcons.FaCogs />
            </NavLink>
            {user.roles.includes('2') && (
              <button
                className='btn btn-danger btn-sm ml-2'
                onClick={() => handleDeleteRouteByAdmin(row.id_ruta)}>
                <FaIcons.FaTrash />
              </button>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const handleDeleteRouteByAdmin = (id_ruta) => {
    mutateDeleteRuta(id_ruta);
  };

  /** CODIGO AGREGADO */
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };
  /** FIN CODIGO */

  return (
    <LayoutAdmin contentTitle='' routeTitle='Lista de rutas'>
      <div className='row'>
        <div className='col-12'>
          <div className='card p-2'>
            <div className='card-header ge-noborder'>
              <>
                <img
                  src='/images/file-icon.png'
                  className='mr-2'
                  style={{ width: '40px' }}
                  alt='file-icon'
                />
                <span className='ge-h3 ge-title'>Lista de Rutas</span>
              </>
            </div>
            <div className='card-body'>
              <DataTable
                className='custom-datatable'
                columns={columns}
                data={filteredItems.length > 0 ? filteredItems : rutasList}
                subHeader
                subHeaderComponent={searchComponentMemo}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                responsive
                progressPending={isLoading}
                progressComponent={<LoaderSmall />}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default ListarRutas;
