import React, { useEffect, useMemo, useState } from "react";
import { getTop10Courses } from "../../../controllers/metricas/metricas.controller";
import LoaderSmall from "../../Loaders/LoaderSmall";
import DataTable from "react-data-table-component";
import Rating from "react-rating";
import * as FaIcons from "react-icons/fa";

const CardBestCourses = () => {
	
	const [topCourses, setTopCourses] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	
	const getData = async () => {
		const response = await getTop10Courses();
		if (response) {
			setTopCourses(response);
		}
	};
	
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "#",
				cell: (row, index) => index + 1,
				width: "10%",
			},
			{
				name: "Curso",
				width: "250px",
				selector: (row) => row.nombre_curso,
			},
			{
				name: "Calificación",
				cell: (row) => (
					<div className="d-flex flex-column flex-xl-row align-items-center">
						<Rating
							className="rating-stars mb-1"
							initialRating={parseInt(row.valoracion)}
							emptySymbol={
								<FaIcons.FaRegStar
									visibility={"visible"}
									color="#547397"
								/>
							}
							fullSymbol={
								<FaIcons.FaStar
									visibility={"visible"}
									color="#992AFF"
								/>
							}
							fractions={10}
							readonly={true}
						/>
						<span className="txt-bold ml-xl-2">{row.valoracion}</span>
					</div>
				),
			},
		],
		[]
	);
    
    
	useEffect(() => {
		getData();
		return () => {
			setTopCourses([]);
		}
	}, []);
    
    
	return (
		<div className="card ge-bt-primary">
			<div className="card-header ge-noborder my-2">
				<>
					<img
						src="/images/file-icon.png"
						className="mr-2"
						style={{ width: "40px" }}
						alt="file-icon"
					/>
					<span className="ge-h4 ge-title">Cursos con mejor review</span>
				</>
			</div>
			<div className="card-body">
				<div className="row">
					<div className="col-12">
						<DataTable
							className="custom-datatable"
							columns={columns}
							data={topCourses}
							pagination
							responsive
							progressPending={isLoading}
							progressComponent={<LoaderSmall />}
							noDataComponent={
								<div className="text-center mt-3">
									No hay datos
								</div>
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardBestCourses;
