import React, { useState } from "react";
import CardGestora from "../../Cards/CardGestora";
import { postResetPassword } from "../../../pages/Usuario/usuario.controller";
import Swal from "sweetalert2";
import { SwalToast } from "../../../utils/toasts";

const ResetPasswordCard = ({idStudent}) => {
    
    const [isDisabled, setIsDisabled] = useState(true);
    const [passwordText, setPasswordText] = useState("");
    
    
    const handleResetPassword = async () => {
        if (passwordText.trim().length > 0) {
            Swal.fire({
                title: "¿Estás seguro?",
                text: "¡Se restablecerá la contraseña del estudiante!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "¡Si, restablecer!"
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await postResetPassword(idStudent, passwordText.trim());
                    if (response) {
                        Swal.fire({
                            title: "¡Contraseña restablecida!",
                            text: "¡La contraseña del estudiante ha sido restablecida!",
                            icon: "success",
                            confirmButtonText: "¡Listo!"
                        });
                    } else {
                        Swal.fire({
                            title: "¡Error!",
                            text: "¡No se pudo restablecer la contraseña del estudiante!",
                            icon: "error",
                            confirmButtonText: "¡Listo!"
                        });
                    }
                    setPasswordText("");
                    setIsDisabled(true);
                }
            });
        }else{
            SwalToast.fire({
                icon: "error",
                title: "¡Error!",
                text: "¡La contraseña no puede estar vacía!",
            });
        }
    }
    
	return (
		<CardGestora title={"Contraseña"}>
			<div className="row">
                <div className="col-12 my-2">
                    <span>Establecer nueva contraseña</span>
                    <div className="d-flex custom-input-container my-1">
                        <input
                            className="custom-input w-100"
                            type="password"
                            placeholder="*****"
                            name="passwordReset"
                            value={passwordText}
                            onChange={(e) => setPasswordText(e.target.value)}
                            disabled={isDisabled}
                        />
                        <button
                            type="button"
                            className={`btn-clear ${isDisabled ? "d-block" : "d-none"}`}
                            onClick={() => setIsDisabled(!isDisabled)}
                        >
                            Restaurar
                        </button>
                    </div>
                </div>
                {
                    !isDisabled && (
                        <div className="d-flex col-12 justify-content-center justify-content-md-end my-2">
                            <button
                                type="button"
                                className="btn ge-btn-deg-primary"
                                onClick={() => {
                                    handleResetPassword();
                                }}
                            >
                                Restaurar
                            </button>
                        </div>
                    )
                }
            </div>
		</CardGestora>
	);
};

export default ResetPasswordCard;
