import React, { useMemo, useState } from 'react';
import { Badge } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import HeaderSearchTable from '../../components/Custom/HeaderSearchTable';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import LoaderSmall from '../../components/Loaders/LoaderSmall';
import {
  useMutateDeleteCourseById,
  useMutateToggleCourse,
  useQueryCursos,
} from '../../hooks/cursos.hooks';
import * as FaIcons from 'react-icons/fa';
import * as BsIcons from 'react-icons/bs';
import ModalCreateCourse from '../../components/Cursos/ModalCreateCourse';
import { useNavigate } from 'react-router-dom';
import { SwalToast } from '../../utils/toasts';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

const ListarCursosScreen = () => {
  const [filterText, setFilterText] = useState('');
  const [resetPagination, setResetPagination] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  /* Queries */
  const { data: cursosList, isLoading } = useQueryCursos();

  const { mutate: mutateToggleCourse } = useMutateToggleCourse();

  /* Traer datos del Redux */
  const { user } = useSelector((state) => state.user);

  const { mutate: mutateDeleteCourse } = useMutateDeleteCourseById();

  /* Search Component */
  const searchComponentMemo = useMemo(() => {
    return (
      <HeaderSearchTable
        filterText={filterText}
        setFilterText={setFilterText}
        arrayData={cursosList}
        setArrayFiltered={setFilteredItems}
        setResetPagination={setResetPagination}
        placeholder='Buscar por nombre'
        columnsToFilter={['nombre_curso']}
      />
    );
  }, [filterText, resetPagination]);

  const handleSendToEdit = (slugCurso) => {
    navigate(`/editar-curso/${slugCurso}`);
  };

  const handleToggleCourse = (idCourse, eliminated) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Cambiar el estado del curso se verá reflejado en el sistema',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, cambiar!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        mutateToggleCourse(
          {
            idCurso: idCourse,
            eliminated: eliminated,
          },
          {
            onSuccess: () => {
              SwalToast.fire({
                icon: 'success',
                title: 'Estado cambiado con éxito',
              });
            },
            onError: () => {
              SwalToast.fire({
                icon: 'error',
                title: 'Error al cambiar el estado.',
              });
            },
          }
        );
      }
    });
  };

  /* Config Table */
  const columns = useMemo(
    () => [
      {
        name: '#',
        cell: (row) => row.id_curso,
        width: '10%',
      },
      {
        name: 'Nombre Curso',
        width: '350px',
        selector: (row) => row.nombre_curso,
        sortable: true,
      },
      /* {
				name: "Descripción",
                // width: '20%',
				selector: (row) => row.descripcion,
			}, */
      {
        name: 'Nivel',
        width: '100px',
        cell: (row) =>
          parseInt(row.id_nivel) === 1
            ? 'Básico'
            : parseInt(row.id_nivel) === 2
            ? 'Intermedio'
            : 'Avanzado',
      },
      {
        name: 'Duración',
        selector: (row) => (row.duracion ? row.duracion + ' min' : '0 min'),
        width: '100px',
      },
      {
        name: 'Módulos',
        width: '100px',
        cell: (row) => (row.modulos.length ? row.modulos.length : 0),
      },
      {
        name: 'Estado',
        width: '10%',
        cell: (row) =>
          parseInt(row.habilitado) === 1 ? (
            <Badge className='ge-deg-primary p-1'>Activo</Badge>
          ) : (
            <Badge className='bg-danger'>Inactivo</Badge>
          ),
      },
      {
        name: 'Acciones',
        width: '150px',
        cell: (row) => (
          <div className='d-flex'>
            <button
              className='btn ge-btn-deg-primary btn-sm mr-2'
              onClick={() => handleSendToEdit(row.slug)}>
              <FaIcons.FaCogs />
            </button>
            {parseInt(row.habilitado) === 1 ? (
              <button
                className='btn btn-light btn-sm'
                onClick={() => handleToggleCourse(row.id_curso, 0)}>
                <BsIcons.BsToggleOn color='#37af48' size={20} />
              </button>
            ) : (
              <button
                className='btn btn-light btn-sm'
                onClick={() => handleToggleCourse(row.id_curso, 1)}>
                <BsIcons.BsToggleOff color='#ff0000' size={20} />
              </button>
            )}
            {user.roles.includes('2') && (
              <button
                className='btn btn-danger btn-sm ml-2'
                onClick={() => handleDeleteCourseByAdmin(row.id_curso)}>
                <FaIcons.FaTrash />
              </button>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const handleDeleteCourseByAdmin = (id_curso) => {
    mutateDeleteCourse(id_curso);
  };

  /** CODIGO AGREGADO */
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };
  /** FIN CODIGO */

  return (
    <LayoutAdmin contentTitle='' routeTitle='Lista de docentes'>
      <ModalCreateCourse show={showModal} setShow={setShowModal} />
      <div className='row'>
        <div className='col-12'>
          <div className='card p-2'>
            <div className='d-flex justify-content-between card-header card-no-after ge-noborder'>
              <div>
                <img
                  src='/images/file-icon.png'
                  className='mr-2'
                  style={{ width: '40px' }}
                  alt='file-icon'
                />
                <span className='ge-h3 ge-title'>Lista de cursos</span>
              </div>
              <div className=''>
                <button
                  className='btn ge-btn-deg-primary btn-sm'
                  onClick={() => setShowModal(true)}>
                  <FaIcons.FaPlus />
                  <span className='ml-2'>Nuevo curso</span>
                </button>
              </div>
            </div>
            <div className='card-body'>
              <DataTable
                className='custom-datatable'
                columns={columns}
                data={filteredItems.length > 0 ? filteredItems : cursosList}
                subHeader
                subHeaderComponent={searchComponentMemo}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                responsive
                progressPending={isLoading}
                progressComponent={<LoaderSmall />}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default ListarCursosScreen;
