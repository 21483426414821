
import jwt_decode from 'jwt-decode';
import { getTokenLS } from './localStorage';

export const hasExpiredToken = (token) =>{
    
    if(!token){
        return {
            isExpired: true,
            user: {},
        }
    }
    
    const decoded = jwt_decode(token);
    // console.log('jwt_decode',decoded);
    const currentTime = Date.now() / 1000;
    if(decoded.exp < currentTime){
        return {
            isExpired: true,
            user: {}
        };
    }
    return {
        isExpired: false,
        user: decoded.data
    };
}



export const validateUserToken = () => {
    const token = getTokenLS();
    const { isExpired, user } = hasExpiredToken(token);
    return { isExpired, user };
}