import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { getReportAdmin } from "../../../controllers/dashboard/admin.controller";
import { SwalToast } from "../../../utils/toasts";
import LineChartDataset from "../../Charts/LineChartDataset";
import CustomInputDate from "../../Custom/CustomInputDate";
import LoaderSmall from "../../Loaders/LoaderSmall";

const CardReport = () => {
    
    
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const chartRef = useRef(null);
	
	const getData = async () => {
		setLoading(true);
		const data_ = await getReportAdmin(startDate);
		if (data_) {
			setData(data_);
		} else {
			SwalToast.fire({
				icon: "error",
				title: "Error",
				text: "Error al obtener los datos",
			});
			setData(null);
		}
		setLoading(false);
	};
	
	const downloadReport = async () => {
		if (chartRef) {
			const link = document.createElement("a");
			link.download = "chart";
			link.href = chartRef.current.toBase64Image();
			link.click();
		}
	};
	
	useEffect(() => {
		if (startDate) {
			getData();
		}
	}, [startDate]);
    
	return (
		<div className="card ge-bt-primary">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column flex-md-row justify-content-between align-items-center">
						<h5 className="ge-title txt-bold">Reporte mensual</h5>
						<div>
							{/* Range Picker */}
							<span className="txt-bold ge-title">Mes:</span>
							<ReactDatePicker
								locale={"es"}
                                selected={startDate}
								onChange={(date) => {
									setStartDate(date);
								}}
								isClearable={true}
								placeholderText="Seleccione en mes y año"
								dateFormat="MM/yyyy"
								customInput={<CustomInputDate />}
                                showMonthYearPicker
                                showFullMonthYearPicker
                                showTwoColumnMonthYearPicker
                                maxDate={new Date()}
							/>
						</div>
					</div>
                    <div className="col-12 mt-4">
						{/* Grafico */}
						{loading ? (
							<LoaderSmall />
						) : data ? (
							<LineChartDataset 
								ref={chartRef} 
								datos={data} 
								showLegend={true}
								showTitle={false}
							/>
						) : (
							<div className="d-flex justify-content-center align-items-center w-100">
								<span className="ge-txt-muted-dark">
									No hay datos para mostrar
								</span>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardReport;
