import React, { useMemo, useState } from "react";
import { useQueryAllAfiliados, useQueryAceptDeclineAfiliados } from "../../hooks/afiliados.hooks";
import DataTable from "react-data-table-component";
import { Badge } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import HeaderSearchTable from "../../components/Custom/HeaderSearchTable";
import LoaderSmall from "../../components/Loaders/LoaderSmall";
import ModalDetailAfiliados from "../../components/Afiliados/ModalDetailAfiliados";

const ListarAfiliados = () => {
  const [filterText, setFilterText] = useState("");
  const [filteredItems, setFilteredItems] = useState();
  const [resetPagination, setResetPagination] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [idUser, setIdUser] = useState();

  const { data, isLoading } = useQueryAllAfiliados();
  const { mutate } = useQueryAceptDeclineAfiliados(); 

  /* Search Component */
  const searchComponentMemo = useMemo(() => {
    return (
      <HeaderSearchTable
        filterText={filterText}
        setFilterText={setFilterText}
        arrayData={data}
        setArrayFiltered={setFilteredItems}
        setResetPagination={setResetPagination}
        columnsToFilter={[
          "nombre_completo",
          "telefono",
          "correo",
          "estado_solicitud",
        ]}
        placeholder="Búsqueda en registros"
      />
    );
  }, [filterText, resetPagination]);

  /* Config Table */
  const columns = useMemo(
    () => [
      {
        name: "#",
        cell: (row, index) => index + 1,
        width: "5%",
      },
      {
        name: "Nombre y Apellidos",
        selector: (row) => row.nombre_completo,
        sortable: true,
      },
      {
        name: "Correo",
        selector: (row) => row.correo,
        sortable: true,
      },
      {
        name: "Telefono",
        selector: (row) => row.telefono,
        sortable: true,
      },
      {
        name: "Estado de solicitud",
        selector: (row) =>
          row.estado_solicitud != 0 ? (<Badge bg="success">Aprobado</Badge>) : (<Badge bg="warning">Pendiente</Badge>),
      },
      {
        name: "Acciones",
        cell: (row) => (
          <div className="d-flex">
            <button
              className="btn ge-btn-primary btn-sm mr-2"
              onClick={() => handleShowDetail(row.id_user)}
            >
              <FaIcons.FaFile />
            </button>
            {row.estado_solicitud === "0" && ( 
            <div className="d-flex">
            <button
                className="btn btn-light btn-sm mr-2"
                style={{background: "transparent"}}
                onClick={() => handleAceptDeclineAfiliados(row.id_user, 1)}
              >
                <BsIcons.BsFillCheckCircleFill color="#37af48" size={20} />
              </button>
              <button
                className="btn btn-light btn-sm mr-2"
                style={{background: "transparent"}}
                onClick={() => handleAceptDeclineAfiliados(row.id_user, 0)}
              >
                <BsIcons.BsFillXCircleFill color="#ff0000" size={20} />
              </button>
            </div>)}
          </div>
        ),
      },
    ],
    []
  );

  const handleShowDetail = (id_user) => {
      setIdUser(id_user)
      setShowDetail(true);
  };

  const handleAceptDeclineAfiliados = (id_user, estado) => {
    mutate({id_user, data: {"aprobar_solicitud": estado}})
  }

  return (
    <LayoutAdmin>
      <ModalDetailAfiliados
        show={showDetail}
        setShow={setShowDetail}
        idUser={idUser}
      />
      <div className="row">
        <div className="col-12">
          <div className="card p-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  src="/images/estudiante.png"
                  className="mr-2"
                  style={{ width: "40px" }}
                  alt="file-icon"
                />
                <span className="ge-h2 ge-title">Afiliados</span>
              </div>
            </div>
            <div className="card-body">
              <DataTable
                columns={columns}
                data={filteredItems?.length > 0 ? filteredItems : data}
                subHeader
                subHeaderComponent={searchComponentMemo}
                pagination
                responsive
                fixedHeader
                fixedHeaderScrollHeight="300px"
                progressPending={isLoading}
                progressComponent={<LoaderSmall />}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default ListarAfiliados;
