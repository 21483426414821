import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Loader from "../components/Loaders/Loader";
import { loginUserAction, validateSessionAction } from "../redux/actions/userActions";
import { getTokenLS } from "../utils/localStorage";

const Login = () => {
	
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { isLoading } = useSelector((state) => state.ui);
	const {isAuthenticated} = useSelector((state) => state.user);
	const token = getTokenLS();
	
	const initialValues = {
		correo: "",
		password: "",
	};
	
	const validationSchema = yup.object({
		correo: yup
			.string()
			.email("Ingrese un correo válido")
			.required("El correo es requerido"),
		password: yup.string().required("La contraseña es requerida"),
	});
	
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values) => {
			dispatch(loginUserAction(values));
		},
	});
	
	useEffect(() => {
		if(token && !isAuthenticated) {
			dispatch(validateSessionAction());
		}
	}, []);
	
	
	useEffect(() => {
		if(isAuthenticated) {
			navigate("/");
		}
	}, [isAuthenticated]);
	
	if (!isLoading && !isAuthenticated) {
		return (
			<div className="login-box">
				<div className="p-3">
					<div className="card card-outline card-primary">
						<div className="card-header text-center login-header">
							<p>Admin Gestora</p>
						</div>
						<div className="card-body login-body">
							<p className="login-box-msg">
								Inicia sesión para comenzar
							</p>
							<form onSubmit={formik.handleSubmit}>
								<div className="input-group mb-3">
									<input
										type="email"
										className="form-control"
										placeholder="Email"
										name="correo"
										onChange={formik.handleChange}
										value={formik.values.correo}
									/>
									<div className="input-group-append">
										<div className="input-group-text">
											<span className="fas fa-envelope" />
										</div>
									</div>
								</div>
								<div className="input-group mb-3">
									<input
										type="password"
										className="form-control"
										placeholder="Password"
										name="password"
										onChange={formik.handleChange}
										value={formik.values.password}
									/>
									<div className="input-group-append">
										<div className="input-group-text">
											<span className="fas fa-lock" />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<button
											type="submit"
											className="btn ge-btn-primary btn-block"
										>
											Iniciar sesión
										</button>
									</div>
								</div>
							</form>
							{/* /.social-auth-links */}
							<div className="mt-2">
								<p className="mb-1">Olvidé mi contraseña</p>
							</div>
						</div>
						{/* /.card-body */}
					</div>
				</div>
			</div>
		);
	}
	return(
		<Loader />
	)
};

export default Login;
