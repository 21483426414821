import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";

const InputModalIcons = ({ show, setShow, icon, setIcon }) => {
	
	const handleOpenModal = () => {
		setShow(true);
	};
	
	const allIcons = Object.keys(FaIcons);
	const [ filteredIcons, setFilteredIcons ] = useState(allIcons);
	const [inputFiltered, setInputFiltered] = useState("");
	
	const handleFilterIcons = (e) => {
		const value = e.target.value;
		setInputFiltered(value);
		const filtered = allIcons.filter((icon) => {
			return icon.toLowerCase().includes(value.toLowerCase());
		});
		setFilteredIcons(filtered);
	};
	
	const handleResetFilter = () => {
		setInputFiltered("");
		setFilteredIcons(allIcons);
	};
	
	return (
		<>
			<div className="d-flex custom-input-container">
				<input
					type="text"
					className="form-control custom-input input-disabled w-100"
					placeholder="Buscar un ícono"
					value={icon}
					onChange={(e) => setIcon(e.target.value)}
					disabled
				/>
				<button
					title="Buscar un ícono"
					className="input-icon-button btn-search"
					type="button"
					onClick={handleOpenModal}
				>
					<FaIcons.FaSearch />
				</button>
			</div>

			<Modal
				className="dark-modal"
				show={show}
				size="md"
				centered
				backdrop="static"
				onHide={() => setShow(false)}
			>
				<Modal.Header className="bg-primary" closeButton>
					<Modal.Title>Elige un ícono</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="container">
						<div className="row">
							<div className="card">
								<div className="card-body">
									<div className="d-flex w-100 mb-3">
										<input
											type="text"
											className="form-control w-100"
											placeholder="Buscar un ícono"
											value={inputFiltered}
											onChange={handleFilterIcons}
										/>
										<button
											className="btn btn-outline-primary btn-sm"
											type="button"
											onClick={handleResetFilter}
										>
											<FaIcons.FaTimes />
										</button>
									</div>
									<div className="text-center">
										{filteredIcons.map((icon, index) => {
											const Icon = FaIcons[icon];
											return (
												<button
													key={index}
													className="btn ge-deg-primary btn-sm m-1"
													type="button"
													onClick={() => {
														setIcon(
															"icono", icon
														);
														setShow(false);
													}}
												>
													<Icon />
												</button>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default InputModalIcons;
