import { useQuery } from "react-query";
import {
  getAllEncuestas,
  getEncuestasById,
} from "../controllers/nps/nps.controller";

const KEY_NPS = "NPS_KEY";
const KEY_NPS_ID = "NPS_BY_ID";

export const useQueryGetAllEncuestas = () => {
  return useQuery([KEY_NPS], getAllEncuestas, {
    refetchOnWindowFocus: false,
  });
};

export const useQueryGetEncuestasById = (id_curso) => {
  return useQuery([KEY_NPS_ID, id_curso], () => getEncuestasById(id_curso), {
    refetchOnWindowFocus: false,
    enabled: !!id_curso,
  });
};
