import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { getSchoolSelect } from "../../controllers/select.controller";
import ReactDatePicker from "react-datepicker";
import CustomInputDate from "../Custom/CustomInputDate";
import { useMutateEditLive, useQueryOneLive } from "../../hooks/lives.hooks";
import { getDateTimeObject } from "../../utils/dateTime";
import { filterPassedTime } from "../../utils/time";
import { SwalToast } from "../../utils/toasts";
import { FaIcons } from "../../icons/export-reacticons";

const validationSchema = yup.object({
    nombreLive: yup.string().required("El nombre del live es requerido"),
    escuela: yup.string().required("La escuela es requerida"),
    fecha: yup.date().required("La fecha es requerida"),
    url_live: yup.string().required("La url del live es requerida"),
});

const dayJs = getDateTimeObject();


const ModalEditLive = ({ show, setShow, idLive, setIdLive }) => {
    
    const [escuelasSelect, setEscuelasSelect] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    
    const { data, refetch } = useQueryOneLive(idLive);
    const { mutate: updateLive, isLoading } = useMutateEditLive(idLive);
    
    const initialValues = {
        nombreLive: "",
        escuela: "",
        fecha: "",
        url_live: "",
    }
    
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            if(idLive) {
                updateLive({
                    idLive,
                    data: values,
                }, {
                    onSuccess: () => {
                        SwalToast.fire({
                            icon: "success",
                            title: "El live se ha actualizado correctamente",
                        });
                    },
                    onError: (error) => {
                        SwalToast.fire({
                            icon: "error",
                            title: "Error al actualizar el live",
                        });
                    }
                });
            }
        }
    });
    
    const fetchEscuelas = async () => {
        try{
            const response = await getSchoolSelect();
            if(response){
                setEscuelasSelect(response);
            }
        }catch(error){
            console.log(error);
        }
    }
    
    const onDateChange = (date) => {
        setStartDate(date);
        formik.setFieldValue("fecha", date);
    }
    
    const onClose = () => {
        setIdLive(null);
        setShow(false);
    }
    
    //obtener las escuelas para el select
    useEffect(() => {
        fetchEscuelas();
    } ,[]);
    
    // si el live cambia, se vuelve a obtener el live
    useEffect(() => {
        if(idLive){
            refetch();
        }
    }, [idLive]);
    
    // si el live existe, se carga los datos del live en el formulario
    useEffect(() => {
        if(data){
            formik.setValues({
                nombreLive: data.nombre_live,
                escuela: data.escuela,
                url_live: data.enlace_youtube,
                fecha: dayJs(data.fecha_inicio).$d,
            });
            setStartDate(dayJs(data.fecha_inicio).$d);
        }
    } ,[data]);
    
	return (
		<Modal 
            show={show} 
            onHide={onClose} 
            backdrop="static"
            centered
        >
			<Modal.Header closeButton className="ge-bg-primary text-white">
				<Modal.Title className="txt-bold">Editar Live</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
                    className="row"
                    onSubmit={formik.handleSubmit}
                >
					<Form.Group
                        className="col-12 my-2"
                    >
						<Form.Label>Título LIVE:</Form.Label>
						<Form.Control
                            type="text"
                            name="nombreLive"
                            placeholder="Título del live"
                            onChange={formik.handleChange}
                            value={formik.values.nombreLive}
                            isValid={formik.touched.nombreLive && !formik.errors.nombreLive}
                            isInvalid={formik.touched.nombreLive && !!formik.errors.nombreLive}
                            autoComplete="off"
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.nombreLive}
                        </Form.Control.Feedback>
					</Form.Group>
                    <Form.Group
                        className="col-12 my-2"
                    >
                        <Form.Label>URL LIVE:</Form.Label>
                        <Form.Control
                            type="text"
                            name="url_live"
                            placeholder="Url del live"
                            onChange={formik.handleChange}
                            value={formik.values.url_live}
                            isValid={formik.touched.url_live && !formik.errors.url_live}
                            isInvalid={formik.touched.url_live && !!formik.errors.url_live}
                            autoComplete="off"
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.url_live}
                        </Form.Control.Feedback>
                    </Form.Group>
					<Form.Group
						className="col-12 my-2"
					>
						<Form.Label>Escuela:</Form.Label>
						<Form.Select
							className="form-control ge-form"
							name="escuela"
                            onChange={formik.handleChange}
                            value={formik.values.escuela}
                            isValid={formik.touched.escuela && !formik.errors.escuela}
                            isInvalid={formik.touched.escuela && !!formik.errors.escuela}
						>
							<option 
                                value=""
                                disabled
                            >
                                -- Seleccione una escuela --
                            </option>
							{escuelasSelect.length > 0 &&
								escuelasSelect.map((escuela) => (
									<option value={escuela.id} key={`esc-${escuela.id}`}>
										{escuela.nombre}
									</option>
                                ))
                            }
						</Form.Select>
						<Form.Control.Feedback type="invalid">
							{formik.errors.escuela}
						</Form.Control.Feedback>
					</Form.Group>
                    <Form.Group
                        className="col-12 my-2"
                    >
                        <Form.Label>Fecha de inicio:</Form.Label>
                        <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => onDateChange(date)}
                            showTimeSelect
                            dateFormat="yyyy-MM-dd HH:mm"
                            dateFormatCalendar="MMMM"
                            customInput={<CustomInputDate />}
                            filterTime={filterPassedTime}
                            minDate={new Date()}
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={15}
                        />
                    </Form.Group>
                    <div className="col-12 my-2 d-flex justify-content-center justify-content-md-end">
                        <button
                            type="submit"
                            className="btn ge-btn-primary"
                            disabled={isLoading}
                        >
                            {
                                isLoading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </>
                                ):(
                                    <>
                                        <FaIcons.FaSave size={20} />
                                        <span className="ml-2">Guardar</span>
                                    </>
                                )
                            }
                        </button>
                    </div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default ModalEditLive;
