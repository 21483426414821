import React, {useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import HeaderSearchTable from '../../components/Custom/HeaderSearchTable';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import DataTable from "react-data-table-component";
import LoaderSmall from '../../components/Loaders/LoaderSmall';
import { disableSuscripcion, getSuscripciones } from '../../controllers/Reportes/suscripciones';
import { useQueryCursos } from '../../hooks/cursos.hooks';
import { getCursosComprados } from '../../controllers/cursos/cursos.controller';
import CustomSelectCursos from '../../components/Custom/CustomSelectCursos';
import RangePicker from '../../components/DatePickers/RangePicker';
import dayjs from 'dayjs';
const ListarCursos = () => {

    const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);

	const [dataConsulta,setDataconsulta]=useState({
		fecha:null,
		producto:'',
	})

		const {
			data: cursosList,
			isLoading: isLoadingCursos,
		} = useQueryCursos();

		const {data : cursosCompradosList,error,isLoading} = useQuery(['cursosComprados',dataConsulta],()=>getCursosComprados(dataConsulta),{
			refetchOnWindowFocus:false,
			retry:0,
		})

    	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={cursosCompradosList}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
                columnsToFilter={["producto","fecha_compra","nombres","apellidos","correo"]}
				placeholder="Buscar por Producto, Fecha compra, Nombres, Apellidos, Correo"
			/>
		);
	}, [filterText, resetPagination]);

	const handleChangeFecha = (date)=>{
		const fechaFormat={
			startDate:dayjs(date[0]).format('YYYY-MM-DD'),
			endDate:dayjs(date[1]).format('YYYY-MM-DD')
		}
		setDataconsulta({
			...dataConsulta,
			fecha:fechaFormat
		})
	}

	const handleChangeProducto = (value)=>{
		setDataconsulta({
			...dataConsulta,
			producto:value.id_curso
		})
	}
	

	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: '#',
				cell: (row)=> row.id_orden_compra,
				width: '5%',
			},
            {
				name: "Fecha compra",
				selector: (row) => row.fecha_compra,
				sortable: true,
				wrap:true
			},
			{
				name: "Nombres",
				selector: (row) => row.nombres,
				sortable: true,
				wrap:true
			},
			{
				name: "Apellidos",
				selector: (row) => row.apellidos,
				sortable: true,
				wrap:true
			},
			{
				name: "Correo",
				selector: (row) => row.correo,
				sortable: true,
				wrap:true
			},
			{
				name: "Id. Culqi",
				selector: (row) => <div style={{textAlign:'left'}}>{row.cod_compra}</div>,
				sortable: true,
                wrap:true
			},
			{
				name: "Producto",
				selector: (row) => <div style={{textAlign:'left'}}>{row.producto}</div>,
				sortable: true,
                wrap:true
			}
		],
		[]
	);


    return (
        <LayoutAdmin
        contentTitle="" routeTitle="Lista de cursos vendidos"
        >
		{/* 	<CustomModal title={'Editar Plan'} show={showEdit} handleShow={setShowEdit} >
				<EditarPlan idPlan={currentId} handleShow ={setShowEdit} handleCurrentId={setCurrentId} />
			</CustomModal> */}
           <div className='row'>
                <div className='col-12'>
                    <div className="card p-2">
						<div className="card-header ge-noborder">
							<div className="d-flex justify-content-between">
								<div>
									<img
										src="/images/file-icon.png"
										className="mr-2"
										style={{ width: "40px" }}
										alt="file-icon"
									/>
									<span className="ge-h3 ge-title">
										Lista de Cursos
									</span>
								</div>
								<div className="">
								</div>
							</div>
						</div>
                        <div className='card-body'>
							<div className="d-flex my-2">
									<div style={{marginRight:'10px'}}>
										<label style={{color:'#842eff'}}>Rango de Fecha</label>
										<RangePicker handleChange={handleChangeFecha}/>
									</div>
									<CustomSelectCursos
									className=''
									onChange={handleChangeProducto}
									value={dataConsulta.producto}
									options={cursosList}
									/>
							</div>
							<DataTable
									className="custom-datatable"
									columns={columns}
									data={
										filteredItems.length > 0
											? filteredItems
											: cursosCompradosList
									}
									subHeader
									subHeaderComponent={searchComponentMemo}
									pagination
									responsive
									fixedHeader
									fixedHeaderScrollHeight="300px"
									progressPending={isLoading}
									progressComponent={<LoaderSmall />}
								/>
                        </div>
                    </div>
                </div>
           </div>
        </LayoutAdmin>
    );
};

export default ListarCursos;