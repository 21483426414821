import { getAuthAxios } from "../../axios/axiosCustom";



export const getStudentsByCourse = async (idCurso) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/dashboard/estudiantes/progreso_curso/${idCurso}`);
        if(response.data.status){
            return response.data.data;
        }else{
            return null;
        }
    }catch(error){
        console.log(error);
        return null;
    }
}


export const getStudentsByRoute = async (idRuta) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/dashboard/estudiantes/progreso_ruta/${idRuta}`);
        if(response.data.status){
            return response.data.data;
        }else{
            return null;
        }
    }catch(error){
        console.log(error);
        return null;
    }
}

export const getDataInscripciones = async (grupo,idRuta) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/metricas/getInscripciones/${grupo}/${idRuta}`);
        if(response.data.status){
            return response.data.data;
        }else{
            return null;
        }
    }catch(error){
        console.log(error);
        return null;
    }
}


export const getStudentsBySchool = async (idEscuela) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/dashboard/estudiantes/progreso_escuela/${idEscuela}`);
        if(response.data.status){
            return response.data.data;
        }else{
            return null;
        }
    }catch(error){
        console.log(error);
        return null;
    }
}


export const getStudentInfo = async (idUser) => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/dashboard/estudiantes/all_info/${idUser}`);
        if(response.data.status){
            return response.data.data;
        }else{
            return null;
        }
    }catch(error){
        console.log(error);
        return null;
    }
}



export const getCoursesCompleteFromWeek = async () => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/dashboard/estudiantes/courses_completed_week`);
        if(response.data.status){
            return response.data.data;
        }else{
            return null;
        }
    }catch(error){
        console.log(error);
        return null;
    }
}

export const getNewUsersFromWeek = async() => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/dashboard/estudiantes/new_users_week`);
        if(response.data.status){
            return response.data.data;
        }else{
            return null;
        }
    }catch(error){
        console.log(error);
        return null;
    }
}


export const getNewCertificatesFromWeek = async() => {
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/dashboard/estudiantes/new_certificates_week`);
        if(response.data.status){
            return response.data.data;
        }else{
            return null;
        }
    }catch(error){
        console.log(error);
        return null;
    }
}


export const getReportDataSet = async(startDate, endDate) => {
    
    const axios = getAuthAxios();
    try{
        const response = await axios.get(`/dashboard/estudiantes/reporte/${startDate}/${endDate}`);
        return response.data;
    }catch(error){
        console.log(error);
        return null;
    }
}