import Swal from "sweetalert2";
import { getAuthAxios } from "../../axios/axiosCustom";

export const getAnounces = async () => {
    const axios = getAuthAxios();
    const response = await axios.get("/anuncios/traerAnuncios");
    if(response.data.status){
        return response.data.data;
    }
    throw new Error(response.data.message);
}

export const getAnounceByUid = async (id) => {
    const axios = getAuthAxios();
    const response = await axios.get(`/anuncios/traerAnuncios/${id}`);
    if(response.data.status){
        return response.data.data;
    }
    throw new Error(response.data.message);
}

export const createAnounce = async (datos) => {
    const axios = getAuthAxios();
    const datos_file = new FormData();
    Object.keys(datos).forEach(key => datos_file.append(key, datos[key]));
    const response = await axios.post(`/anuncios/nuevoAnuncio`,datos_file);
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      

    if(response.data.status){
        Toast.fire({
            icon: 'success',
            title: response.data.message
          })
        return response.data;
    }
    throw new Error(response.data.error);
}

export const updateAnounce = async (datos) => {
    const axios = getAuthAxios();
    const datos_file = new FormData();
    Object.keys(datos).forEach(key => datos_file.append(key, datos[key]));
    const response = await axios.post(`/anuncios/editarAnuncio`,datos_file);
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      

    if(response.data.status){
        Toast.fire({
            icon: 'success',
            title: response.data.message
          })
        return response.data;
    }
    throw new Error(response.data.error);
}


export const disableAnounce = async (datos) => {
  const axios = getAuthAxios();
  const response = await axios.put(`/anuncios/disableAnounce/${datos.id}`,datos);
  const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    

  if(response.data.status){
      Toast.fire({
          icon: 'success',
          title: response.data.message
        })
      return response.data;
  }
  throw new Error(response.data.error);
}

export const deleteAnounceById = async (id_anuncio) => {
  const axios = getAuthAxios();
  const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

  await  Swal.fire({
      title: '¿Estás seguro?',
      text: "Una vez eliminado, no se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
          const response = await axios.delete(`/anuncios/deleteAnounceAdmin/${id_anuncio}`);
            
          if(response.data.status){
              Toast.fire({
                  icon: 'success',
                  title: response.data.message
                })
          }
          else{
              Toast.fire({
                  icon: 'warning',
                  title: response.data.message
                })
          }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
      }
    })
}