import { docenteTypes } from "../types"


const initialState = {
    docenteEdit: null,
    docentes: [],
}

/* 
    docenteEdit: {
        id,
        nombres,
        apellidos,
        correo,
        telefono,
    }

*/


export const docenteReducer = (state = initialState, action) => {
    
    switch(action.type){
        case docenteTypes.SET_DOCENTE_EDIT:
            return {
                ...state,
                docenteEdit: action.payload
            }
        case docenteTypes.CLEAR_DOCENTE_EDIT:
            return {
                ...state,
                docenteEdit: null
            }
        default:
            return state;
    }
    
}