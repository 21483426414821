import React, { useEffect, useMemo, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import HeaderSearchTable from '../../components/Custom/HeaderSearchTable';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import { deletePlan, disablePlan, getPlanes } from '../../controllers/planes/planes';
import * as FaIcons from 'react-icons/fa'
import * as BsIcons from 'react-icons/bs'
import DataTable from "react-data-table-component";
import LoaderSmall from '../../components/Loaders/LoaderSmall';
import CustomModal from '../../components/Modal/CustomModal';
import CrearPlan from './CrearPlan';
import EditarPlan from './EditarPlan';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ListarPlanes = () => {

    const {data : planesList,error,isLoading} = useQuery(['planes'],getPlanes,{
		retry: 2,
		refetchOnWindowFocus: false,
	  })
	const navigate = useNavigate();


	const queryClient = useQueryClient()
    
    const {mutate, error : errorUpdate , isLoading : isLoadingUpdate } = useMutation(disablePlan,{
        onSuccess: ()=>{
            queryClient.invalidateQueries(["planes"])
        }
    })

	const {mutate:mutateDelete, error : errorDelete , isLoading : isLoadingDelete } = useMutation(deletePlan,{
        onSuccess: ()=>{
            queryClient.invalidateQueries(["planes"])
        }
    })

    const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
	const [showCreate,setShowCreate] = useState(false)
	const [showEdit,setShowEdit] = useState(false)
	const [currentId,setCurrentId] = useState(null)

				/* Traer datos del Redux */
	const { user } = useSelector(( state ) => state.user)


    	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={planesList}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
                columnsToFilter={["titulo","nombre_escuela","fecha_creacion"]}
				placeholder="Buscar por Título o Escuela o Fecha registro"
			/>
		);
	}, [filterText, resetPagination]);

	const handleSendToBeneficios = (idPlan) => {
		navigate(`/listar-planes/administrar-beneficios/${idPlan}`);
	}

	const handleDisablePlan = (id,eliminado)=>{
		//console.log(id)
		mutate({id:id,eliminado:eliminado})
	}
	
	const handleDeletePlan = (id)=>{
	//	console.log(id)
		mutateDelete(id)
	}

	const handleEditPlan = (id)=>{
		//console.log(id)
		setCurrentId(id)
	}

	useEffect(()=>{
		//console.log('cambio ID')
		if(currentId)
		{setShowEdit(!showEdit)}
	},[currentId])

	useEffect(()=>{
		//console.log('cambio ID')
		if(!showEdit && currentId)
		{setCurrentId(null)}
	},[showEdit])


	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: '#',
				cell: (row)=> row.id,
				width: '5%',
			},
            {
				name: "Fecha registro",
				selector: (row) => row.fecha_registro,
				sortable: true,
				wrap:true
			},
			{
				name: "Id. Culqi",
				selector: (row) => <div style={{textAlign:'left'}}>{row.id_culqui}</div>,
				sortable: true,
                wrap:true
			},
			{
				name: "Nombre plan",
				selector: (row) => <div style={{textAlign:'left'}}>{row.nombre_plan}</div>,
				sortable: true,
                wrap:true
			},
            {
				name: "Frecuencia",
				selector: (row) => row.frecuencia_pago,
				sortable: true,
				wrap:true
			},
			{
				name: "Intervalo",
				selector: (row) => row.intervalo_count,
				sortable: true,
				wrap:true
			},
			{
				name: "Límite máx.",
				selector: (row) => row.limite,
				sortable: true,
				wrap:true
			},
			{
				name: "Precio",
				selector: (row) => `S/. ${row.precio}`,
				sortable: true,
			},
			{
				name: "Precio Regular",
				selector: (row) => `S/. ${row.precio_regular}`,
				sortable: true,
			},
			{
				name: "N° Beneficiarios",
				selector: (row) =>
					row.numero_estudiantes,
				wrap:true
			},
			{
				name: "Status",
				cell: (row) =>
					row.eliminado === "0" ? (
						<Badge className="ge-deg-primary p-1">Activo</Badge>
					) : (
						<Badge bg="danger">Inactivo</Badge>
					),
				sortable: true,
			},
			{
				name: "Acciones",
				cell: (row) => (
					<div className="d-flex">
						{
								row.eliminado === "0" ? (
									<button 
										className="btn btn-light btn-sm mr-2"
										onClick={() => handleDisablePlan(row.id, "1")}
									>
										<BsIcons.BsToggleOn color="#37af48" size={20} />
									</button>
								):(
									<button 
										className="btn btn-light btn-sm mr-2"
										onClick={() => handleDisablePlan(row.id, "0")}
										>
										<BsIcons.BsToggleOff color="#ff0000" size={20} />
									</button>
								)
						}
						<button
							className="btn ge-btn-primary btn-sm mr-2"
							onClick={()=>handleEditPlan(row.id)}
						>
							<FaIcons.FaEdit />
						</button>
						<button
							className='btn ge-btn-primary btn-sm mr-2'
							onClick={() => handleSendToBeneficios(row.id)}
						>
							<FaIcons.FaTasks/>
						</button>
						{ user.roles.includes("2") && (
							<button
								className="btn btn-danger btn-sm ml-2"
								onClick={()=>  handleDeletePlan(row.id_culqui)}
							>
								<FaIcons.FaTrash />
							</button>
						)}

						
					</div>
				),
			},
		],
		[]
	);


    return (
        <LayoutAdmin
        contentTitle="" routeTitle="Lista de Planes"
        >
			<CustomModal title={'Crear Plan'} show={showCreate} handleShow={setShowCreate} >
				<CrearPlan handleShow ={setShowCreate} />
			</CustomModal>
			<CustomModal title={'Editar Plan'} show={showEdit} handleShow={setShowEdit} >
				<EditarPlan idPlan={currentId} handleShow ={setShowEdit} handleCurrentId={setCurrentId} />
			</CustomModal>
           <div className='row'>
                <div className='col-12'>
                    <div className="card p-2">
						<div className="card-header ge-noborder">
							<div className="d-flex justify-content-between">
								<div>
									<img
										src="/images/file-icon.png"
										className="mr-2"
										style={{ width: "40px" }}
										alt="file-icon"
									/>
									<span className="ge-h3 ge-title">
										Lista de Planes
									</span>
								</div>
								<div className="">
									<button
										className="btn ge-btn-primary btn-sm"
										onClick={() => setShowCreate(true)}
									>
										<FaIcons.FaPlus />
										<span className="ml-2">Nuevo plan</span>
									</button>
								</div>
							</div>
						</div>
                        <div className='card-body'>
                        <DataTable
								className="custom-datatable"
								columns={columns}
								data={
									filteredItems.length > 0
										? filteredItems
										: planesList
								}
								subHeader
								subHeaderComponent={searchComponentMemo}
								pagination
								responsive
								fixedHeader
								fixedHeaderScrollHeight="300px"
								progressPending={isLoading}
								progressComponent={<LoaderSmall />}
							/>
                        </div>
                    </div>
                </div>
           </div>
        </LayoutAdmin>
    );
};

export default ListarPlanes;