import React from "react";
import * as FaIcons from "react-icons/fa";
import Rating from "react-rating";

const CardTopDocente = ({
    docenteData = {
        id_docente: "",
        nombres: "",
        apellidos: "",
        img_perfil: null,
        promedio_valoraciones: 0,
    }
}) => {
    
    const {
        // id_docente,
        nombres,
        apellidos,
        img_perfil,
        promedio_valoraciones
    } = docenteData;
    
    if (!docenteData) {
        return null;
    }
	return (
		<div 
            className="d-flex flex-column card-top-docente justify-content-center text-center"
            style={{
                height: "275px",
            }}
        >
			<div className="d-flex flex-column align-items-center justify-content-center avatar-docente mb-2">
				<img
					src={
						!img_perfil
							? `https://ui-avatars.com/api/?name=${nombres}+${apellidos}&background=random`
							: img_perfil.includes("files")
                                ? // ? `${process.env.REACT_APP_API_URL}${imagen}`
                                `${process.env.REACT_APP_API_URL}${img_perfil}`
                                : img_perfil
					}
					alt="docente-avatar"
				/>
				<span className="ge-title">
					{nombres} {apellidos}
				</span>
			</div>
            <span className="ge-title ge-h2">{promedio_valoraciones.replace('.',',')}{" "}</span>
			<Rating
				className="rating-stars mb-1"
				initialRating={promedio_valoraciones}
				emptySymbol={
					<FaIcons.FaRegStar visibility={"visible"} color="#547397" />
				}
				fullSymbol={
					<FaIcons.FaStar visibility={"visible"} color="#992AFF" />
				}
				fractions={10}
				readonly={true}
			/>
            <span className="mt-2 ge-txt-secondary ge-weight-medium-alt">
                Total en calificaciones:{" "}
                {promedio_valoraciones.replace('.',',')}{" "}
                {" "}/{" "}5,0
            </span>
		</div>
	);
};

export default CardTopDocente;
