import Swal from "sweetalert2";

export const SwalToast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.addEventListener("mouseenter", Swal.stopTimer);
		toast.addEventListener("mouseleave", Swal.resumeTimer);
	},
});




export const ToastToConfirm = (onConfirmed, params = null, text = "No podrás revertir esto!") => {
	Swal.fire({
		title: "¿Estás seguro?",
		text,
		icon: "warning",
		showCancelButton: true,
		confirmButtonColor: "#3085d6",
		cancelButtonColor: "#d33",
		confirmButtonText: "Sí!",
		cancelButtonText: "Cancelar",
	}).then((result) => {
		if (result.isConfirmed) {
			if(params) {
				onConfirmed(params);
			}else{
				onConfirmed();
			}
		}
	});
}

