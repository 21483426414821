import dayjs from "dayjs";
import { getAuthAxios } from "../../../axios/axiosCustom";

export const getGastos = async ({
    fecha_consulta
}) => {
    try{
        const fecha_format = dayjs(fecha_consulta).format("YYYY-MM");
        
        const axios = getAuthAxios();
        const response = await axios.get(`/ingresos_gastos/traer_gastos/${fecha_format}`);
        
        if(response.data.status){
            return response.data.data;
        }
        
        throw new Error('No se pudo obtener los gastos');
    }catch(error){
        console.error(error);
    }
}

/* Formulario gastos */

export const getTipoDeGastos = async () => {
    const axios = getAuthAxios();

    try {
        const response = await axios.get('/ingresos_gastos/traer_tipo_1');
        if(response.data.status){
            return response.data.data;
        }
    } catch (error) {
        console.log(error)
    }
}

export const getDocumentosGastos = async () => {
    const axios = getAuthAxios();

    try {
        const response = await axios.get('/ingresos_gastos/traer_documento');
        if(response.data.status){
            return response.data.data;
        }
    } catch (error) {
        console.log(error)
    }
}

export const getDetallesGastos = async (tipo) => {
    const axios = getAuthAxios();

    try {
        const response = await axios.get(`/ingresos_gastos/traer_detalle/${parseInt(tipo)}`);
        if(response.data.status){
            return response.data.data;
        }
    } catch (error) {
        console.log(error)
    }
}

export const getSubDetallesGastos = async (id_detalle) => {
    const axios = getAuthAxios();

    try {
        const response = await axios.get(`/ingresos_gastos/traer_detalle_sub_ingreso_gasto/${id_detalle}`);
        if(response.data.status){
            return response.data.data;
        }
    } catch (error) {
        console.log(error)
    }
}

export const enviarDatosGastos = async ({id_user, dataGastos}) => {
    const axios = getAuthAxios();

    try {
        const response = axios.post(`/ingresos_gastos/registrar_ingresos_gastos/${id_user}`, dataGastos)
        
        if(response.data.status){
            return response.data.message
        }

    } catch (error) {
        console.log(error)
    }
};