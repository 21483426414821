import { getAuthAxios } from "../../../axios/axiosCustom";



export const getGlosariesByCourse = async (idCourse) => {
    try{
        const axios = getAuthAxios();
        const response = await axios.get(`/glosario/curso/${idCourse}`);
        if(response.data.status){
            return response.data.data;
        }else{
            throw new Error(response.data.message);
        }
    }catch(error){
        throw new Error(error);
    }
}


export const createGlosary = async (glosary) => {
    try{
        
        const dataToApi = {
            titulo: glosary.title,
            descripcion: glosary.description,
            id_curso: glosary.idCourse,
        }
        
        const axios = getAuthAxios();
        const response = await axios.post(`/glosario/create_glosary`, dataToApi);
        if(response.data.status){
            return response.data.message;
        }else{
            throw new Error(response.data.message);
        }
    }catch(error){
        throw new Error(error);
    }
}


export const updateGlosary = async (glosary) => {
    try{
        const dataToApi = {
            titulo: glosary.titulo,
            descripcion: glosary.descripcion,
        }
        
        const axios = getAuthAxios();
        const response = await axios.post(`/glosario/edit_glosary/${glosary.id_glosario}`, dataToApi);
        if(response.data.status){
            return response.data.message;
        }else{
            throw new Error(response.data.message);
        }
    }catch(error){
        throw new Error(error);
    }
}


export const deleteGlosary = async (idGlosary) => {
    try{
        const axios = getAuthAxios();
        const response = await axios.put(`/glosario/delete_glosary/${idGlosary}`);
        if(response.data.status){
            return response.data.message;
        }else{
            throw new Error(response.data.message);
        }
    }catch(error){
        throw new Error(error);
    }
}