import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Spinner } from "react-bootstrap";
import { getDateTimeObject } from "../../utils/dateTime";
import * as FaIcons from "react-icons/fa";
import { getGraficoSuscripciones } from "../../controllers/Reportes/graficos";
import BarChart from "../../components/Dashboards/BarChart";
import MonthPicker from "../../components/DatePickers/MonthPicker";
import ReactDatePicker from "react-datepicker";
import CustomInputDate from "../../components/Custom/CustomInputDate";
import BarChartDataset from "../../components/Charts/BarChartDataset";

const GraficoSuscripciones = ({
	initialState = [
		new Date().setDate(new Date().getDate() - 6),
		new Date(),
	]
}) => {
	/* const actualDay = new Date();
	let daysAgo7 = new Date();
	daysAgo7.setDate(actualDay.getDate() - 6);
    
	const initialState = [daysAgo7, actualDay]; */
    
	const [dateRange, setDateRange] = useState(initialState);
	const [startDate, endDate] = dateRange;
	const chartRef = useRef(null);
    
	const {
		data: dataSuscripciones,
		isLoading: isLoadingSuscripciones,
		refetch: refetchSuscripciones,
	} = useQuery(
		["graficoSuscripciones", startDate + "/" + endDate],
		() => getGraficoSuscripciones(startDate, endDate),
		{
			refetchOnWindowFocus: false,
		}
	);
    
	/* useEffect(() => {
        if (startDate && endDate) {
            refetchSuscripciones();
        }
    }, [dateRange]); */
    
	return (
		<div className="row">
			<div className="col-12">
				<div className="card">
					<div className="d-flex flex-column flex-xl-row align-items-center justify-content-center justify-content-xl-between p-2">
						<div className="d-flex align-items-center p-2">
							<img
								src="/images/folder.png"
								alt="..."
								style={{
									objectFit: "cover",
									width: "50px",
									height: "50px",
									marginRight: "10px",
								}}
							/>
							<h5 className="txt-bold">Suscripciones</h5>
						</div>
						<div className="d-flex align-items-end">
							<div>
								{/* Range Picker */}
								<span className="txt-bold ge-title">
									Periodo:
								</span>
								<ReactDatePicker
									locale={"es"}
									selectsRange={true}
									startDate={startDate}
									endDate={endDate}
									onChange={(update) => {
										setDateRange(update);
									}}
									isClearable={true}
									// className="form-control ge-form my-2"
									placeholderText="Seleccione un rango"
									dateFormat="yyyy-MM-dd"
									maxDate={new Date()}
									customInput={<CustomInputDate />}
								/>
							</div>
						</div>
					</div>
					<div className="card-body">
						{isLoadingSuscripciones ? (
							<div
								style={{
									textAlign: "center",
									color: "#842eff",
								}}
							>
								{" "}
								<Spinner animation="border" />{" "}
							</div>
						) : dataSuscripciones ? (
							<BarChart
                                datos={dataSuscripciones}
                            />
						) : (
							<p>No es posible obtener datos</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default GraficoSuscripciones;
