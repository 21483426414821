import React from "react";
import './loader-card.scss';

const LoaderCard = () => {
	return (
		<div className="lds-ring">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};

export default LoaderCard;
