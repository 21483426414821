export const mapCourseDetailsToEdit = (data) => {
    
    return {
        ...data.curso,
    }
}

export const mapCourseModulesToEdit = (data) => {
    return {
        courseModules: data.temary,
    }
}