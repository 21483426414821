import React, { useMemo, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useQuery,useQueryClient,useMutation} from 'react-query';
import { NavLink, useNavigate } from 'react-router-dom';
import HeaderSearchTable from '../../components/Custom/HeaderSearchTable';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import * as FaIcons from 'react-icons/fa'
import * as BsIcons from 'react-icons/bs'
import DataTable from "react-data-table-component";
import LoaderSmall from '../../components/Loaders/LoaderSmall';
import dayjs from 'dayjs';
import { useSelector } from "react-redux";
import { useMutateDeletePostById } from '../../hooks/blog.hooks';
import { deleteAnounceById, disableAnounce, getAnounces } from '../../controllers/anuncios/anuncios';
import { disablePost } from '../../controllers/blog/posts';


const ListarAnuncios = () => {

    const {data : anunciosList,error,isLoading} = useQuery(['anuncios'],()=>getAnounces(),{
		refetchOnWindowFocus:false,
		retry:1,
	})

	const queryClient = useQueryClient()
    
    const {mutate, error : errorUpdate , isLoading : isLoadingUpdate } = useMutation(disableAnounce,{
        onSuccess: ()=>{
            queryClient.invalidateQueries(['anuncios'])
        }
    })


    const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);

	const navigate = useNavigate()

	const handleDisableAnounce = (id,eliminado)=>{
		//console.log(id)
		mutate({id:id,eliminado:eliminado})
	}


			/* Traer datos del Redux */
	const { user } = useSelector(( state ) => state.user)

	const {mutate:deleteById} = useMutation(deleteAnounceById,{
        onSuccess: ()=>{
            queryClient.invalidateQueries(['anuncios'])
        }
    })


    	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={anunciosList}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
                columnsToFilter={["anuncio","fecha_registro"]}
				placeholder="Buscar por contenido de anuncio o Fecha registro"
			/>
		);
	}, [filterText, resetPagination]);

	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: '#',
				cell: (row, index) => index+1,
				width: '5%',
			},
            {
				name: "Fecha registro",
				selector: (row) => row.fecha_registro,
				sortable: true,
			},
			{
				name: "Anuncio",
				selector: (row) => <div dangerouslySetInnerHTML={{__html:row.anuncio}}></div>,
				sortable: true,
                wrap:true
			},
            {
				name: "Fecha fin",
				selector: (row) => row.fecha_fin,
				sortable: true,
			},
			{
				name: "Status",
				cell: (row) =>
					parseInt(row.habilitado) === 1 ? (
						<Badge className="ge-deg-primary p-1">Público</Badge>
					) : (
						<Badge bg="danger">Sin publicar</Badge>
					),
				sortable: true,
			},
			{
				name: "Acciones",
				cell: (row) => (
					<div className="d-flex">
						<NavLink  to={`/editar-anuncio/${row.cod_unico}`}
							className="btn ge-btn-primary btn-sm mr-2"
						>
							<FaIcons.FaEdit />
						</NavLink>

					
						{
							parseInt(row.habilitado) === 1 ? (
									<button 
										className="btn btn-light btn-sm"
										onClick={() => handleDisableAnounce(row.id_anuncio, 0)}
									>
										<BsIcons.BsToggleOn color="#37af48" size={20} />
									</button>
								):(
									<button 
										className="btn btn-light btn-sm"
										onClick={() => handleDisableAnounce(row.id_anuncio, 1)}
										>
										<BsIcons.BsToggleOff color="#ff0000" size={20} />
									</button>
								)
							}
							{ user.roles.includes("2") && (
								<button
									className="btn btn-danger btn-sm ml-2"
									onClick={()=>  handleDeleteAnounceById(row.id_anuncio)}
								>
									<FaIcons.FaTrash />
								</button>
							)}
					</div>
				),
			},
		],
		[]
	);


	const handleDeleteAnounceById = (id) => {
		deleteById(id)
	};

    return (
        <LayoutAdmin
        contentTitle="" routeTitle="Lista de banners"
        >
           <div className='row'>
                <div className='col-md-12'>
                    <div className="card">
                            <div className="d-flex justify-content-between p-3">
                                <div>
                                    <img
                                        src="/images/file-icon.png"
                                        className="mr-2"
                                        style={{ width: "40px" }}
                                        alt="file-icon"
                                    />
                                    <span className="ge-h3 ge-title">
                                        Lista de banners
                                    </span>
                                </div>
								<div>
									<button className='btn ge-btn-primary' onClick={()=>navigate('/crear-anuncio')}>Crear banner</button>
								</div>
                            </div>
				
                        <div className='card-body'>
                        <DataTable
								className="custom-datatable"
								columns={columns}
								data={
									filteredItems.length > 0
										? filteredItems
										: anunciosList
								}
						
								pagination
								responsive
								fixedHeader
								fixedHeaderScrollHeight="300px"
								progressPending={isLoading}
								progressComponent={<LoaderSmall />}
							/>
                        </div>
                    </div>
                </div>
           </div>
        </LayoutAdmin>
    );
};

export default ListarAnuncios;