import React, { useMemo, useState } from "react";
import dayjs from "dayjs";
import LoaderSmall from "../../Loaders/LoaderSmall";
import DataTable from "react-data-table-component";
import * as FaIcons from "react-icons/fa";
import { Badge } from "react-bootstrap";
import ModalCreateCampaign from "./ModalCreateCampaign";
import ModalEditCampaign from "./ModalEditCampaign";
import { SwalToast } from "../../../utils/toasts";
import Swal from "sweetalert2";
import { useMutateToggleGoogleAdsCampaign, useQueryGoogleAdsCampaigns } from "../../../hooks/googleAds.hooks";
import CustomHeader from "../facebook/CustomHeader";

const GoogleCampaignList = () => {
    
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
	const { data: ggAdsList, isLoading } = useQueryGoogleAdsCampaigns();
    const {mutate: toggleCampaign, isLoading: isLoadingToggle} = useMutateToggleGoogleAdsCampaign();
    
    const [idCampaignToEdit, setIdCampaignToEdit] = useState(null);
    
    const handleToEdit = (idCampaign) => {
        setIdCampaignToEdit(idCampaign);
        setShowModalEdit(true);
    }
    
    const handleToToggle = (idRegistro, status) => {
        Swal.fire({
            title: "¿Estas seguro?",
            text: `Se ${status === 1 ? "activará" : "desactivará"} la campaña`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, continuar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                toggleCampaign({
                    id: idRegistro,
                    status
                },{
                    onSuccess: () => {
                        SwalToast.fire({
                            icon: "success",
                            title: "Campaña actualizada correctamente",
                        });
                    },
                    onError: () => {
                        SwalToast.fire({
                            icon: "error",
                            title: "Ha ocurrido un error",
                        });
                    }
                });
            }
        });
    }
    
	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<CustomHeader
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={ggAdsList}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
				placeholder="Buscar por titulo de campaña"
				columnsToFilter={["titulo"]}
			/>
		);
	}, [filterText, resetPagination]);
    
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: "#",
				cell: (row, index) => row.id_registro,
				width: "5%",
			},
            {
                name: "Título",
                selector: (row) => row.titulo,
                width: "300px",
            },
            {
				name: "Tipo de campaña",
				selector: (row) => row.tipo_campaign,
                width: "250px",
			},
			{
				name: "Fecha de inicio",
				cell: (row, index) =>
					dayjs(row.fecha_inicio).format("DD/MM/YYYY"),
                width: "150px",
			},
            {
                name: "Fecha de fin",
                cell: (row, index) =>
                    dayjs(row.fecha_fin).format("DD/MM/YYYY"),
                width: "150px",
            },
            /* {
                name: "Rango de consulta",
                cell: (row, index) =>
                    (row.date_start && row.date_stop)
                    ? dayjs(row.date_start).format("DD") + " - " + dayjs(row.date_stop).format("DD MMMM YYYY")
                    : "",
                width: "150px",
            }, */
			{
				name: "Clicks",
				selector: (row) => row.clicks ? row.clicks : 0,
			},
            {
                name: "Impresiones",
                selector: (row) => row.impressions ? row.impressions : 0,
            },
            {
                name: "CTR (%)",
                selector: (row) => row.ctr 
                    ? parseFloat(row.ctr).toFixed(2) + "%"
                    : "0%",
            },
            {
                name: "Gasto",
                selector: (row) => row.spend 
                    ? "S/ " + parseFloat(row.spend).toFixed(2)
                    : "S/ " + parseFloat(0).toFixed(2),
            },
            {
                name: "Estado",
                cell: (row, index) => parseInt(row.activo) === 1
                    ? <Badge bg="success" className="p-1">Activo</Badge>
                    : <Badge bg="danger" className="p-1">Inactivo</Badge>,
            },
			{
				name: "Acciones",
				cell: (row) => (
					<div className="d-flex justify-content-center align-items-center">
						<button
							className={`btn btn-sm btn-${parseInt(row.activo) === 1 ? "success" : "danger"} mx-1`}
							title={
                                parseInt(row.activo) === 1 ? "Desactivar" : "Activar"  
                            }
							onClick={() => {
                                handleToToggle(row.id_registro, parseInt(row.activo) === 1 ? 0 : 1);
                            }}
						>
                            {
                                parseInt(row.activo) === 1 ? <FaIcons.FaToggleOn size={20} /> : <FaIcons.FaToggleOff  size={20} />
                            }
						</button>
                        <button
                            className="btn btn-warning btn-sm mx-1 text-white"
                            title="Editar"
                            onClick={() => handleToEdit(row.id_registro)}
                        >
                            <FaIcons.FaEdit size={20} />
                        </button>
                        <a
                            className="btn btn-sm btn-primary mx-1"
                            title="Ver detalle"
                            href={`www.google.com`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FaIcons.FaEye size={20} />
                        </a>
					</div>
				),
			},
		],
		[]
	);
    
	return (
		<div className="card">
            <ModalCreateCampaign
                show={showModal}
                setShow={setShowModal}
            />
            
            <ModalEditCampaign 
                show={showModalEdit}
                setShow={setShowModalEdit}
                idCampaign={idCampaignToEdit}
            />
            <div className="d-flex flex-column align-items-center flex-md-row justify-content-md-between card-header card-no-after ge-noborder p-4">
                <div className="d-flex flex-column flex-sm-row align-items-center">
                    <img
                        src="/images/file-icon.png"
                        className="mr-2"
                        style={{ width: "40px" }}
                        alt="file-icon"
                    />
                    <span className="ge-h3 ge-title">
                        Campañas de Google Ads
                    </span>
                </div>
                <div className="my-2 my-md-0">
                    <button
                        className="btn ge-btn-deg-primary btn-sm"
                        onClick={() => setShowModal(true)}
                    >
                        <FaIcons.FaPlus />
                        <span className="ml-2">Registrar campaña</span>
                    </button>
                </div>
            </div>
			<div className="card-body">
				<div className="row">
					<div className="col-12">
						<DataTable
							className="custom-datatable"
							columns={columns}
							data={
								filteredItems.length > 0
									? filteredItems
									: ggAdsList
							}
							subHeader
							subHeaderComponent={searchComponentMemo}
							pagination
							responsive
							fixedHeader
							fixedHeaderScrollHeight="600px"
							progressPending={isLoading}
							progressComponent={<LoaderSmall />}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GoogleCampaignList;
