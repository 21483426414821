import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { pagesByRole } from './pagesByRole';
import _ from 'lodash';

const DashboardRoutes = () => {
    
    //TODO: Get the user information with role
    
    const {user, isAuthenticated} = useSelector(state => state.user);
    
    const haveAccess = (pageAllowedRoles) => {
        let intersectionRoles = [];
        intersectionRoles = _.intersection(pageAllowedRoles, user?.roles.map(role => parseInt(role)));
        return intersectionRoles.length > 0;
    }
    
    if(!isAuthenticated) {
        return null;
    }
    
	return (
        <Routes>
            <>
                {
                    pagesByRole.map((page, index) =>{
                        const canAccess = haveAccess(page.allowedRoles);
                        if(canAccess) {
                            return <Route key={index} path={page.path} element={<page.component />} />
                        }else{
                            return null
                        }
                    })
                }
                
                <Route
                    path="/*"
                    element={ <Navigate to="/" /> }
                />
            </>
        </Routes>
	);
};

export default DashboardRoutes;
