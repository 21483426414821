import React, { useEffect, useMemo, useState } from 'react';
import HeaderSearchTable from '../../components/Custom/HeaderSearchTable';
import LayoutAdmin from '../../components/layouts/LayoutAdmin';
import * as FaIcons from 'react-icons/fa'
import DataTable from "react-data-table-component";
import LoaderSmall from '../../components/Loaders/LoaderSmall';
import CustomModal from '../../components/Modal/CustomModal'; 
import { useParams } from 'react-router-dom';
import { useMutateDeletePlanBenefits, useQueryPlanById } from '../../hooks/planes.beneficios';
import CrearBeneficio from './beneficios/CrearBeneficio';


const AdministrarBeneficios = () => {
    const [filterText, setFilterText] = useState("");
    const [filteredItems, setFilteredItems] = useState();
    const [resetPagination, setResetPagination] = useState(false);
    const [ showCreate, setShowCreate ] = useState(false);


    const { idPlan } = useParams();

    const { mutate: mutateDeleteBenefits } = useMutateDeletePlanBenefits();
  
    const { data: benefits, isLoading } = useQueryPlanById(idPlan);

    	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={benefits}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
        columnsToFilter={["beneficio"]}
				placeholder="Buscar por beneficio"
			/>
		);
	}, [filterText, resetPagination]);


  useEffect(() => {

  },[benefits, isLoading])

  const handleDisableBenefits = (id,eliminado)=>{
	}
	
	const handleDeleteBenefits = (id)=>{
     mutateDeleteBenefits(id)
	}

	const handleEditBenefits = (id)=>{
	}

	/* Config Table */
	const columns = useMemo(
          () => [
            {
              name: "#",
              cell: (row, index) => index + 1,
              width: "5%",
            },
            {
              name: "Beneficios",
              selector: (row) => row.beneficio,
              sortable: true,
            },
            {
              name: "Acciones",
              cell: (row) => (
                <div className="d-flex">
                  <button
                    className="btn btn-danger btn-sm mr-2"
                    onClick={()=>handleDeleteBenefits(row.id)}
                  >
                    <FaIcons.FaTrash />
                  </button>
      
                  
                </div>
              )}
          ],[]);


    return (
        <LayoutAdmin
        contentTitle="" routeTitle="Lista de Beneficios"
        >
          <CustomModal title={'Crear Beneficio'} show={showCreate} handleShow={setShowCreate}>
            <CrearBeneficio idPlan={idPlan} handleShow={setShowCreate}/>
          </CustomModal>
          <div className='row'>
                <div className='col-12'>
                    <div className="card p-2">
                      <div className="card-header ge-noborder">
                        <div className="d-flex justify-content-between">
                          <div>
                            <img
                              src="/images/file-icon.png"
                              className="mr-2"
                              style={{ width: "40px" }}
                              alt="file-icon"
                            />
                            <span className="ge-h3 ge-title">
                              Lista de Beneficios
                            </span>
                          </div>
                          <div className="">
                            <button
                              className="btn ge-btn-primary btn-sm"
                              onClick={() => setShowCreate(true)}
                            >
                              <FaIcons.FaPlus />
                              <span className="ml-2">Nuevo Beneficio</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <DataTable
                          className="custom-datatable"
                          columns={columns}
                          data={
                            filteredItems?.length > 0
                              ? filteredItems
                              : benefits
                          }
                          subHeader
                          subHeaderComponent={searchComponentMemo}
                          pagination
                          responsive
                          fixedHeader
                          fixedHeaderScrollHeight="300px"
                          progressPending={isLoading}
                          progressComponent={<LoaderSmall />}
                        />
                        </div>
                    </div>
                </div>
           </div>
        </LayoutAdmin>
    );
};

export default AdministrarBeneficios;