import React from "react";
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const TimeLineTicket = ({ reporteInfo }) => {
	const { user } = useSelector((state) => state.user);
	
	if (!reporteInfo) {
		return null;
	}
	
	return (
		<VerticalTimeline layout="1-column-left" lineColor="#000">
			<VerticalTimelineElement
				contentStyle={{
					background: "rgb(153, 42, 255)",
					color: "#fff",
				}}
				contentArrowStyle={{
					borderRight: "7px solid rgb(153, 42, 255)",
				}}
				date={dayjs(reporteInfo.fecha_registro).format("DD/MM/YYYY")}
				iconStyle={{
					background: "rgb(152, 42, 255)",
					color: "#fff",
					border: "1px solid #000",
					boxShadow: "none",
					textAlign: "center",
				}}
				icon={
					<img
						src={
							user.img_perfil
								? process.env.REACT_APP_API_URL + user.img_perfil
								: "/images/user-icon.png"
						}
						className="rounded-circle"
						style={{ width: "100%" }}
						alt="..."
					/>
				}
			>
				<h5 className="vertical-timeline-element-subtitle">
					Descripción del problema:
				</h5>
				<p>{reporteInfo.problema}</p>
				{reporteInfo.evidencia_img && (
					<div className="row">
						<div className="col-12">
							<strong>Evidencia:</strong>
						</div>
						<div className="col-12">
							<img
								src={
									process.env.REACT_APP_API_URL +
									reporteInfo.evidencia_img
								}
								className="img-fluid"
								alt="..."
							/>
						</div>
					</div>
				)}
			</VerticalTimelineElement>
		</VerticalTimeline>
	);
};

export default TimeLineTicket;
