import React, { forwardRef } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);


const DoughnutChart = forwardRef(
	({ datos, showTitle = true, showLegend = true, showGrid = true }, ref) => {
		//console.log(datos.dataSets)
		const state = {
			labels: datos.labels,
			datasets: datos.dataSets,
		};
		
		return (
			<Doughnut
				ref={ref}
				datasetIdKey={`id-${datos.title}`}
				height={400}
				data={state}
				options={{
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						title: {
							display: showTitle,
							text: datos.title,
							fontSize: 20,
						},
						legend: {
							display: showLegend,
							position: "top",
						},
					},
				}}
			/>
		);
	}
);

export default DoughnutChart;
