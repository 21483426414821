import React, { useState } from "react";
import { Badge, Form, ListGroup, Spinner } from "react-bootstrap";
import { FilePond } from "react-filepond";
import { useParams } from "react-router-dom";
import { useMutateAddResources, useMutateAddResourcesUrl, useMutateDeleteResources, useQueryResourcesByClass } from "../../hooks/clase.hooks";
import * as FaIcons from "react-icons/fa";
import { SwalToast } from "../../utils/toasts";
import Swal from "sweetalert2";
import { useEffect } from "react";

const TabEditResources = () => {
    
    const {idClase} = useParams();
	const [files, setFiles] = useState([]);
    const [ urlResources, setUrlResources ] = useState("");
    const [ nombre, setNombre ] = useState("");
    const { data: resourcesList, isLoading } = useQueryResourcesByClass(idClase);
    const {mutate: mutateAddResource, isLoading: loadingCreate} = useMutateAddResources(idClase);
    const {mutate: mutateDeleteResource, isLoading: loadingDelete} = useMutateDeleteResources(idClase);
    const { mutate: mutateAddUrl, isLoading: loadingCreateUrl } = useMutateAddResourcesUrl(idClase);
    
	const acceptedFiles = [
		"image/*",
		"video/mp4",
		"application/pdf",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"application/vnd.openxmlformats-officedocument.presentationml.presentation",
	];
    
    const handleAddResource = (e) => {
        e.preventDefault();
        if(files.length > 0){
            const filesParsed = files.map((file) => file.file);
            mutateAddResource({
                idClass: idClase,
                resources: filesParsed
            },{
                onSuccess: () => {
                    setFiles([]);
                    SwalToast.fire({
                        icon: "success",
                        title: "Recursos agregados correctamente",
                    });
                },
                onError: (error) => {
                    console.log(error);
                    SwalToast.fire({
                        icon: "error",
                        title: "Error al agregar los recursos",
                    });
                }
            })
        }else{
            SwalToast.fire({
                icon: "warning",
                title: "Debe seleccionar un archivo",
            });
        }
    }
    
    
    const handleDeleteResource = (idResource) => {
        Swal.fire({
            icon: "warning",
            title: "¿Está seguro de eliminar el recurso?",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                mutateDeleteResource(idResource,{
                    onSuccess: () => {
                        SwalToast.fire({
                            icon: "success",
                            title: "Recurso eliminado correctamente",
                        });
                    },
                    onError: (error) => {
                        console.log(error);
                        SwalToast.fire({
                            icon: "error",
                            title: "Error al eliminar el recurso",
                        });
                    }
                })
            }
        });
    }

    const handleAddEnlaces = (e) => {
        e.preventDefault();
        if(nombre.length && urlResources.length){
            const data = {
                nombre_recurso: nombre,
                url_archivo: urlResources
            }
            mutateAddUrl({
                idClass: idClase,
                resourceUrl: data
            },{
                onSuccess: () => {
                    setNombre("");
                    setUrlResources("");
                    SwalToast.fire({
                        icon: "success",
                        title: "Recursos agregados correctamente",
                    });
                },
                onError: (error) => {
                    console.log(error);
                    SwalToast.fire({
                        icon: "error",
                        title: "Error al agregar los recursos",
                    });
                }
            })
        } else {
            SwalToast.fire({
                icon: "warning",
                title: "Debe seleccionar un archivo",
            });
        }
    }
    
	return (
		<div className="card">
			<div className="card-header ge-noborder"></div>
			<div className="card-body">
                <Form
                    className="mb-3"
                    onSubmit={handleAddResource}
                >
                    <div className="col-12 d-flex justify-content-end">
                        <button 
                            type="submit" 
                            className="btn ge-btn-primary btn-sm"
                            disabled={loadingCreate}
                        >
                            {
                                loadingCreate ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                : "Agregar recursos"
                            }
                        </button>
                    </div>
                    <Form.Group className="col-12 my-2">
                        <Form.Label>Recursos:<span className="txt-ge-muted">{" "}(máximo 5 archivos)</span></Form.Label>
                        <FilePond
                            files={files}
                            onupdatefiles={setFiles}
                            allowMultiple={true}
                            maxFiles={5}
                            name="files"
                            labelIdle='Arrastra tus archivos o <span class="filepond--label-action">Buscar</span>'
                            allowImageExifOrientation={true}
                            allowFileTypeValidation={true}
                            acceptedFileTypes={acceptedFiles}
                            labelFileTypeNotAllowed="Tipo de archivo no permitido"
                            credits={false}
                            checkValidity={true}
                            fileValidateTypeLabelExpectedTypes=""
                            labelInvalidField="Archivo inválido"
                        />
                    </Form.Group>
                </Form>
                <Form 
                    className="mb-3"
                    onSubmit={handleAddEnlaces}
                >
                    <Form.Group className="col-12 my-2">
                        <Form.Label> Enlaces</Form.Label>
                        <div className="col-12 d-flex flex-column">
                        <Form.Control 
                            placeholder="Nombre a mostrar del enlace"
                            className="col-md-5"
                            name="nombre"
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                        />
                        <Form.Control
                            name="enlaces"
                            placeholder="URL ej: https://crececongestora.com/"
                            className="col-md-5 mt-3"
                            value={urlResources}
                            onChange={(e) => setUrlResources(e.target.value)}
                        />
                            <button 
                                className="mt-2 btn ge-btn-primary btn-sm col-md-5"
                                type="submit"
                            >
                                Agregar enlace
                            </button>
                        </div>
                    </Form.Group>
                </Form>
				<div className="col-12">
					<small className="txt-small txt-bold txt-dark">Lista de recursos:</small>
					<ListGroup as="ol" numbered>
                        {
                            isLoading && (
                                <ListGroup.Item
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <Spinner animation="border" variant="primary" />
                                </ListGroup.Item>
                            )
                        }
                        {
                            (!isLoading && resourcesList.length > 0) && (
                                resourcesList.map((resource,i) => (
                                    <ListGroup.Item
                                        key={i}
                                        as="li"
                                        className="d-flex justify-content-between align-items-center"
                                    >
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">
                                                {/* Previsualizar el documento */}
                                                {/* <a href={`${process.env.REACT_APP_API_URL}/${resource.ruta_archivo}`} target="_blank" rel="noopener noreferrer"> */}
                                                {/* URL */}
                                                <a href={`${resource.ruta_archivo}`} target="_blank" rel="noopener noreferrer">
                                                    {resource.doc}
                                                    <FaIcons.FaFileDownload className="ml-2" />
                                                </a>
                                            </div>
                                        </div>
                                        <Badge 
                                            bg="danger" 
                                            className="ge-hover-scale" 
                                            pill
                                            onClick={() => handleDeleteResource(resource.id_archivo)}
                                        >
                                            <FaIcons.FaTrashAlt color="#fff" />
                                        </Badge>
                                    </ListGroup.Item>
                                ))
                            )
                        }
                        {
                            (!isLoading && resourcesList.length === 0) && (
                                <ListGroup.Item
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <span>No hay recursos</span>
                                </ListGroup.Item>
                            )
                        }
						
					</ListGroup>
				</div>
			</div>
		</div>
	);
};

export default TabEditResources;
