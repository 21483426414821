import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as FaIcons from "react-icons/fa";
import { SwalToast } from "../../utils/toasts";
import { Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import CustomInputDate from "../../components/Custom/CustomInputDate";
import { filterPassedTime } from "../../utils/time";
import CustomSelectPlanes from "../../components/Custom/CustomSelectPlanes";
import { getPlanes } from "../../controllers/planes/planes";
import { createFreeAccount } from "../../controllers/usuariosRoles/usuarios";


const CreateUserPlanFree = () => {
	
	const queryClient = useQueryClient();

	const {
		mutate,
	} = useMutation(createFreeAccount, {
		onSuccess: () => {
			queryClient.invalidateQueries(["getRutas"]);
		},
	});
	
	const{data:dataPlanes } =  useQuery(['planes'],getPlanes,
		{
			refetchOnWindowFocus:false
		}
	)

	const initialValues = {
		nombres:"",
		apellidos:"",
		correo:"",
		fecha_fin_suscripcion:"",
		pwd:"",
		producto:""
	};
	
	const validationSchema = yup.object({
		nombres: yup.string().required("El campo es requerido"),
		apellidos: yup.string().required("El campo es requerido"),
		correo: yup.string()
		.email('El correo no es valido')
		.required('El correo es requerido'),
		fecha_fin_suscripcion:yup.string().required("El campo es requerido"),
		pwd: yup.string()
		.matches('[a-zA-Z]', {
		  message: 'La contraseña debe tener al menos una letra.',
		  excludeEmptyString: true,
		})
		.matches('[0-9]', {
		  message: 'La contraseña debe tener al menos un número.',
		  excludeEmptyString: true,
		})
		.min(8, 'La contraseña debe tener como minimo 8 caracteres.')
		.required('La contraseña es requerida'),
		producto: yup.string().required("El campo es requerido")
	});
	
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values) => {
			const datos = { ...values };
			mutate(datos, {
				onSuccess: () => {
					SwalToast.fire({
						icon: "success",
						title: "Cuenta creada correctamente",
					});
					formik.resetForm();
					formik.values.producto=""
				},
				onError: (error) => {
					SwalToast.fire({
						icon: "error",
						title: "Error al crear la cuenta",
					});
				}
			});
		},
	});
	
	return (
		<LayoutAdmin contentTitle="Crear cuenta" routeTitle="Crear cuenta">
			<div className="card">
				<div className="card-body">
					<Form className="form-add-docente row" onSubmit={formik.handleSubmit}>
						{/* Datos generales */}
						<div className="col-md-6">
							<Form.Group className="mb-3">
								<Form.Label>Nombres:</Form.Label>
								<Form.Control
									type="text"
									className=""
									placeholder="Nombres"
									name="nombres"
									onChange={formik.handleChange}
									value={formik.values.nombres}
									isInvalid={formik.errors.nombres && formik.touched.nombres}
									isValid={!formik.errors.nombres && formik.touched.nombres}
								/>
								<Form.Control.Feedback type="invalid">
									{formik.errors.nombres}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>Apellidos:</Form.Label>
								<Form.Control
									type="text"
									className=""
									placeholder="Apellidos"
									name="apellidos"
									onChange={formik.handleChange}
									value={formik.values.apellidos}
									isInvalid={formik.errors.apellidos && formik.touched.apellidos}
									isValid={!formik.errors.apellidos && formik.touched.apellidos}
								/>
								<Form.Control.Feedback type="invalid">
									{formik.errors.apellidos}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>Correo:</Form.Label>
								<Form.Control
									type="text"
									className=""
									placeholder="Correo"
									name="correo"
									onChange={formik.handleChange}
									value={formik.values.correo}
									isInvalid={formik.errors.correo && formik.touched.correo}
									isValid={!formik.errors.correo && formik.touched.correo}
								/>
								<Form.Control.Feedback type="invalid">
									{formik.errors.correo}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>Password:</Form.Label>
								<Form.Control
									type="text"
									className=""
									placeholder="Contraseña"
									name="pwd"
									onChange={formik.handleChange}
									value={formik.values.pwd}
									isInvalid={formik.errors.pwd && formik.touched.pwd}
									isValid={!formik.errors.pwd && formik.touched.pwd}
								/>
								<Form.Control.Feedback type="invalid">
									{formik.errors.pwd}
								</Form.Control.Feedback>
							</Form.Group>
							</div>
							<div className="col-md-6">
							<Form.Group className="mb-3">
											<Form.Label>Fecha fin de suscripción:</Form.Label>
											<ReactDatePicker
												locale={"es"}
												selected={formik.values.fecha_fin_suscripcion}
												onChange={(date) => {
													formik.setFieldValue(
														"fecha_fin_suscripcion",
														date
													);
												}}
												isClearable={true}
												dateFormat="dd/MM/yyyy h:mm aa"
												showTimeInput
												timeInputLabel="Hora"
												filterTime={filterPassedTime}
												minDate={new Date()}
												customInput={<CustomInputDate />}
												name="fecha_fin_suscripcion"
											/>
											{
												formik.touched.fecha_fin_suscripcion && !!formik.errors.fecha_fin_suscripcion && (
													<div className="invalid-feedback">
														{formik.errors.fecha_fin_suscripcion}
													</div>
												)
											}
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>Plan:</Form.Label>
								<CustomSelectPlanes
                                        className=''
                                        onChange={(producto)=>{
											formik.setFieldValue(
														"producto",
														producto.id
													);
										}}
                                        value={formik.values.producto}
                                        options={dataPlanes}
										title={false}
									
								/>
								<Form.Control.Feedback type="invalid">
									{formik.errors.pwd}
								</Form.Control.Feedback>
							</Form.Group>
						</div>
						<Form.Group className="col-3 my-2">
							<button
								type="submit"
								className="btn ge-btn-deg-primary btn-block"
							>
								Crear cuenta
							</button>
						</Form.Group>
					</Form>
				</div>
			</div>
		</LayoutAdmin>
	);
};

export default CreateUserPlanFree;
