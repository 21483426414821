import React from "react";
import Select from "react-select";

export default ({ onChange, options, value, className , isMulti=false}) => {
    
	const defaultValue = (options, value) => {
		return options
			? !isMulti ? options.find((option) => option.id_curso === value) : value
			: "";
	};
    
	return (
		<div className={className} style={{ zIndex: 4 }}>
			<label style={{ color: "#842eff" }}>Seleccione un curso:</label>
			<Select
				isMulti={isMulti}
                placeholder="Seleccione un curso"
				noOptionsMessage={() => "No hay opciones"}
				value={defaultValue(options, value)}
				onChange={(value) => {
					if(!value){
						value={
							id_curso:''
						}
					}
					//console.log(value)
					onChange(value);
				}}
				options={options}
				getOptionValue={(cursos) => cursos.id_curso}
				getOptionLabel={(cursos) => cursos.nombre_curso}
				isClearable
			/>
		</div>
	);
};
