import dayjs from "dayjs";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { obtenerTiempoPermanencia } from "../../../controllers/dashboard_roles/manager.controller";
import { formatSecondsHMS } from "../../../utils/time";
import CustomInputDate from "../../Custom/CustomInputDate";

const TiempoPermanencia = () => {
    
	// start of month
	const startOfMonth = dayjs().startOf("month").$d;
	const actualDate = dayjs().$d;
	
	const initialState = [startOfMonth, actualDate];
	const [dateRange, setDateRange] = useState(initialState);
	const [startDate, endDate] = dateRange;
    const [data, setData] = useState(0);
    
    const getData = async () => {
        const data_ = await obtenerTiempoPermanencia(startDate, endDate);
        if(data_){
            setData(data_);
        }
    }
    
    useEffect(() => {
        if(startDate && endDate){
            getData();
        }
        
        return () => {
            setData(0);
        }
    }, [dateRange]);
	
	useEffect(() => {
		console.log(initialState);
	}, []);
	
	return (
		<div className="card ge-bl-2 h-100">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column align-items-center mb-4">
                        <h5 className="ge-title txt-bold">
                            Tiempo de permanencia de clientes
                        </h5>
						<div className="my-2">
							{/* Range Picker */}
							<span className="txt-bold ge-title">Mes:</span>
							<ReactDatePicker
								locale={"es"}
								selectsRange={true}
								startDate={startDate}
								endDate={endDate}
								onChange={(update) => {
									setDateRange(update);
								}}
								isClearable={true}
								// className="form-control ge-form my-2"
								placeholderText="Seleccione un rango"
								dateFormat="yyyy-MM-dd"
								maxDate={new Date()}
								customInput={<CustomInputDate />}
							/>
						</div>
					</div>
                    <div className="col-12 d-flex justify-content-center">
                        <span className="ge-h1 txt-bold ge-title text-center">
							{formatSecondsHMS(data)}
						</span>
                    </div>
				</div>
			</div>
		</div>
	);
};

export default TiempoPermanencia;
