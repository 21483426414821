import { getAuthAxios } from "../../axios/axiosCustom";
import Swal from "sweetalert2";

export const getAllAfiliados = async () => {
  const axios = getAuthAxios();
  try {
    const response = await axios.get(`/afiliados/getAll_solicitud_afiliados`);
    if (response.data.status) {
      return response.data.data;
    }
    throw new Error("Error al obtener los usuarios");
  } catch (e) {
    throw new Error("Error del servidor");
  }
};

export const getAfiliadoById = async (id_user) => {
  const axios = getAuthAxios();
  try {
    const response = await axios.get(
      `/afiliados/get_detalle_afiliado/${id_user}`
    );
    if (response.data.status) {
      return response.data;
    }
    throw new Error("Error al obtener los usuarios");
  } catch (e) {
    
  }
};

export const getCuentaAfiliadoById = async (id_user) => {
  const axios = getAuthAxios();
  try {
    const response = await axios.get(
      `/afiliados/get_cuenta_afiliado/${id_user}`
    );
    if (response.data.status) {
      return response.data;
    }
    throw new Error("Error al obtener los usuarios");
  } catch (e) {
    
  }
};

export const aceptODeclineAfiliados = async ({id_user, data}) => {
  const axios = getAuthAxios();
  const response = await axios.put(`/afiliados/aceptar_solicitud_afiliado/${id_user}`, data);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  if (response.data.status) {
    Toast.fire({
      icon: "success",
      title: response.data.message,
    });
    return response.data;
  }
  throw new Error(response.data.error);
};


export const aceptODeclineComisiones = async ({comision,id_user, data}) => {
  const axios = getAuthAxios();
  const response = await axios.put(`/afiliados/aceptar_comision_afiliado/${comision}`, data);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  if (response.data.status) {
    Toast.fire({
      icon: "success",
      title: response.data.message,
    });
    return id_user;
  }
  throw new Error(response.data.error);
};