import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useMutateCreateModule } from "../../../hooks/curso-modulos.hooks";
import { SwalToast } from "../../../utils/toasts";
import DarkModal from "../../Custom/DarkModal";

const ModalCreateModule = ({showModal, setShowModal, idCourse}) => {
    
	const [nameModule, setNameModule] = useState("");
    const {mutateAsync: mutateCreateModule, isLoading} = useMutateCreateModule(idCourse);
    
    
    const handleSubmit = async(e) => {
        e.preventDefault();
        if (nameModule.trim() === "") {
            return;
        }
        const data = {
            curso: idCourse,
            nombre: nameModule
        }
        try {
            await mutateCreateModule(data,{
                onSuccess: () => {
                    setNameModule("");
                    SwalToast.fire({
                        icon: "success",
                        title: "Módulo creado correctamente",
                    });
                    setShowModal(false);
                },
                onError: (err) => {
                    SwalToast.fire({
                        icon: "error",
                        title: "Error al crear el módulo",
                    });
                }
            });
        }catch(e){
            SwalToast.fire({
                icon: "error",
                title: "Error del servidor",
            });
        }
    }
    
	return (
		<DarkModal
			showModal={showModal}
			onHide={() => setShowModal(false)}
			headerComponent={<h5>Crear Módulo</h5>}
		>
			<Form
                onSubmit={handleSubmit}
            >
				<Form.Group controlId="formNameModule" className="my-3">
					<Form.Label>Nombre del módulo</Form.Label>
					<Form.Control
						type="text"
						placeholder="Ingrese el nombre del módulo"
                        autoComplete="off"
                        value={nameModule}
                        onChange={(e) => setNameModule(e.target.value)}
                        isInvalid={nameModule === ""}
                        isValid={nameModule !== ""}
					/>
					<Form.Control.Feedback type="invalid">
						El nombre del módulo es requerido
					</Form.Control.Feedback>
				</Form.Group>
                <div
                    className="d-flex justify-content-around w-100"
                >
                    <button
                        type="submit"
                        className="btn ge-btn-primary"
                        disabled={isLoading}
                    >
                        {
                            isLoading ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : (
                                "Crear"
                            )
                        }
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                            setShowModal(false);
                        }}
                        disabled={isLoading}
                    >
                        Cancelar
                    </button>
                </div>
			</Form>
		</DarkModal>
	);
};

export default ModalCreateModule;
