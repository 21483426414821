import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import FileItem from "./FileItem";

const baseStyle = {
	flex: 1,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "20px",
	borderWidth: 2,
	borderRadius: 5,
	borderColor: "#992AFF",
	borderStyle: "dashed",
	backgroundColor: "#E9CFFF",
	color: "#992AFF",
	outline: "none",
	transition: "border .24s ease-in-out",
	cursor: "pointer",
};

const focusedStyle = {
	borderColor: "#992AFF",
};

const acceptStyle = {
	borderColor: "#00e676",
};

const rejectStyle = {
	borderColor: "#ff1744",
};


const validateCustom = (file, filesToCompare) => {
    // validar que el archivo no exista en la lista
    const fileExists = filesToCompare.find((f) => f.file.name === file.name);
    if (fileExists) {
        return {
            code: "FILE_EXISTS",
            message: `No puede haber dos archivos con el mismo nombre`,
        };
    }else{
        // validar que el archivo tenga el nombre con formato DNI-NOMBRE.pdf
        const fileName = file.name.split("-");
        if (fileName.length === 2) {
            const [dni, name] = fileName;
            if (dni.length !== 8) {
                return {
                    code: `FILENAME_INVALID ${dni}`,
                    message: `El nombre del archivo no tiene el formato DNI-NOMBRE.pdf`
                };
            }
            return null;
        }
        return {
            code: `FILENAME_INVALID ${file.name}`,
            message: `El nombre del archivo no tiene el formato DNI-NOMBRE.pdf`
        };
    }
}



/* Component */

const DropZoneCustom = ({
	useDropzoneOptions,
    filesResult,
    setFilesResult,
    labelDropArea = "Arrastre y suelte los archivos o haga click para seleccionarlos",
    labelDropAreaAccept = "(Archivos permitidos: .pdf)",
	getFiles,
}) => {
    
    const [files, setFiles] = useState([]);
    
	//getFiles callback
	useEffect(() => {
		if (getFiles) {
			getFiles(files);
		}
	}, [files]);
	
	
	const {
		acceptedFiles,
		fileRejections,
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject,
	} = useDropzone({
        accept: {
            "application/pdf": [],
        },
        validator: (file) => validateCustom(file, files),
        ...useDropzoneOptions,
    });
    
	const style = useMemo(
		() => ({
			...baseStyle,
			...(isFocused ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isFocused, isDragAccept, isDragReject]
	);
    
	useEffect(() => {
		const mapAcceptedFiles = acceptedFiles.map((file) => ({
			file,
			errors: null,
		}));
        
		setFiles((prev) => [...prev, ...mapAcceptedFiles, ...fileRejections]);
	}, [acceptedFiles, fileRejections]);
    
    
	useEffect(() => {
        const filesWithErrors = files.filter((file) => file.errors !== null);
        if (filesWithErrors.length > 0) {
            setFilesResult({
                filesSuccess: files.filter((file) => file.errors === null),
                filesError: filesWithErrors
            });
        }else{
            setFilesResult({
                filesSuccess: files,
                filesError: []
            });
        }
	}, [files]);
    
    
    const handleDeleteFile = (file) => {
        const newFiles = files.filter((f) => f.file !== file);
        setFiles(newFiles);
    }
    
    
	return (
		<div {...getRootProps({ style })}>
			<input {...getInputProps()} />
			<p>
				{labelDropArea}
			</p>
			<em>{labelDropAreaAccept}</em>
			{files.length > 0 && (
				<div className="w-100 mt-3">
					{files.map(({ file, errors }, index) => (
						<FileItem 
                            key={index} 
                            file={file} 
                            errors={errors}
                            handleDeleteFile={handleDeleteFile}
                        />
					))}
				</div>
			)}
		</div>
	);
};

export default DropZoneCustom;
