import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { getMetricsByPlatform, getSelectPlatformsRS } from "../../../controllers/marketing/mkt_ventas/mktVentas.controller";
import { SwalToast } from "../../../utils/toasts";
import CustomInputDate from "../../Custom/CustomInputDate";

const RedesSociales = () => {
    
    const [startDate, setStartDate] = useState(new Date());
    const [platforms, setPlatforms] = useState([]);
    const [platformSelected, setPlatformSelected] = useState("");
    const [data, setData] = useState({
        total_publicaciones: 0,
        total_interacciones: 0,
        total_alcance: 0,
    });
    const { total_publicaciones, total_interacciones, total_alcance } = data;
    
    const getData = async () => {
        const data_ = await getMetricsByPlatform(startDate, platformSelected);
        if (data_) {
            setData(data_);
        } else {
            SwalToast.fire({
                icon: "error",
                title: "Error",
                text: "Error al obtener los datos",
            });
        }
    }
    
    const getPlatforms = async () => {
        const data_ = await getSelectPlatformsRS();
        if (data_) {
            setPlatforms(data_);
        }else{
            SwalToast.fire({
                icon: "error",
                title: "Error",
                text: "Error al obtener las plataformas",
            });
        }
    }
    
    useEffect(() => {
        if ((platformSelected && platformSelected !== "")  && startDate) {
            getData();
        }
        return () => {
            setData({
                total_publicaciones: 0,
                total_interacciones: 0,
                total_alcance: 0,
            });
        }
    }, [platformSelected, startDate]);
    
    useEffect(() => {
        getPlatforms();
        return () => {
            setPlatforms([]);
        }
    }, []);
    
	return (
		<div className="card">
			<div className="card-body">
				<div className="row">
					<div className="col-12 d-flex flex-column flex-lg-row justify-content-lg-between align-items-center mb-4">
						<div className="d-flex flex-column">
                            <h5 className="ge-title txt-bold">Métricas de redes sociales</h5>
                            <select 
                                className="ge-form form-control" 
                                name="platforms" 
                                id="platforms"
                                onChange={(e) => setPlatformSelected(e.target.value)}
                            >
                                <option value=""> -- Seleccione una plataforma -- </option>
                                {
                                    platforms.map((platform) => (
                                        <option key={platform.id_registro} value={platform.id_registro}>{platform.nombre}</option>
                                    ))
                                }
                            </select>
                        </div>
						<div className="my-2">
							{/* Range Picker */}
							<span className="txt-bold ge-title">Mes:</span>
							<ReactDatePicker
								locale={"es"}
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
								isClearable={true}
                                showMonthYearPicker
								placeholderText="Seleccione un mes"
								dateFormat="MM/yyyy"
								maxDate={new Date()}
								customInput={<CustomInputDate />}
							/>
						</div>
					</div>
                    {/* Cards de info */}
                    <div className="col-12 col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="ge-title txt-bold">Publicaciones</h5>
                                <p className="ge-h1 txt-bold">{total_publicaciones}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="ge-title txt-bold">Interacciones</h5>
                                <p className="ge-h1 txt-bold">{total_interacciones}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="ge-title txt-bold">Alcance</h5>
                                <p className="ge-h1 txt-bold">{total_alcance}</p>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	);
};

export default RedesSociales;
