import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { createCupon, editCupon } from "../../controllers/cupones/cupones.controller";
import { useSelector } from "react-redux";
import { filterPassedTime } from "../../utils/time";
import CustomInputDate from "../Custom/CustomInputDate";
import ReactDatePicker from "react-datepicker";
import { getDateTimeObject } from "../../utils/dateTime";
const dayJs = getDateTimeObject();



const ModalCreateEditCupon = ({show, title="Datos cupón", setShow,editData=null,setEditData}) => {
    
    const { user } = useSelector((state) => state.user);
	const [copied, setCopied] = useState(false);
   // const {data: dataCourse, isError, mutate: mutateCurso} = useMutateCreateCurso();
	const queryClient = useQueryClient();
	const {
		mutate:mutateCreate,
	} = useMutation(createCupon, {
		onSuccess: () => {
			queryClient.invalidateQueries("cupones");
		},
	});
    
	const {
		mutate:mutateEdit,
	} = useMutation(editCupon, {
		onSuccess: () => {
			queryClient.invalidateQueries("cupones");
		},
	});

	const initialValues = {
        cod_unico:editData ? editData.cod_unico : "",
		cupon: editData ? editData.cupon : "",
		descripcion: editData ? editData.descripcion : "",
		descuento: editData ? editData.descuento : "",
		nro_canjes: editData ? editData.nro_canjes : "",
		fecha_vencimiento: editData? dayJs(editData.fecha_vencimiento).$d  : "",
		tipo_producto: editData?editData.tipo_producto : "",
	};
	

	useEffect(()=>{
		if(editData) {
            formik.setValues(initialValues);
        }
	},[editData])
    
	const validationSchema = yup.object({
		cupon:  yup.string().required("El cupón es requerido."),
		descripcion: yup.string().required("La descripción es requerida."),
		descuento: yup.string().required("La cam´po es requerida."),
		nro_canjes: yup.string().required("El campo es requerido."),
		fecha_vencimiento: yup.string().required("La fecha es requerida."),
		tipo_producto: yup.string().required("El correo es requerido."),
	});
    
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
		   if(editData)
		   {
			mutateEdit(values)
		   }
		   else
		   {
            values.responsable=user.id_user
		    mutateCreate(values)
		   }

		   handleCloseModal();
        },
	});
    
    const handleCloseModal = () => {
        formik.resetForm();
		setEditData(null)
		setCopied(false)
        setShow(false);
    };

	/*const handleGeneratePassword = ()=>{
	const pwd =	generatePassword();
	formik.setFieldValue('password',pwd)
	}*/

	/*const handleCopyToClipboard = (text) => {
		navigator.clipboard
		  .writeText(text)
		  .then(() => {
			setCopied(true)
			SwalToast.fire({
			  title: 'Copiado al portapapeles',
			  icon: 'success',
			  timer: 1500,
			});
		  })
		  .catch((err) => {
			SwalToast.fire({
			  title: 'Error al copiar al portapapeles',
			  icon: 'error',
			  timer: 1500,
			});
		  });
	  };*/
    
	return (
		<Modal
			show={show}
			size="lg"
			centered
			backdrop="static"
			onHide={() => setShow(false)}
		>
			<Modal.Header className="ge-bg-primary txt-white">
				<Modal.Title> {title} </Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="container">
					<div className="card-body">
						<Form 
							className="row form-add-docente"
							onSubmit={formik.handleSubmit}
						>
							<Form.Group
								controlId="formBasicName"
								className="col-12 col-md-6 my-2"
							>
								<Form.Label>Cupón *</Form.Label>
								<Form.Control
									className=""
									type="text"
									placeholder="Cupón: Ejm - GESTORA2023"
									name="cupon"
									onChange={formik.handleChange}
									value={formik.values.cupon}
									autoComplete="off"
									isValid={
										formik.touched.cupon &&
										!formik.errors.cupon
									}
									isInvalid={
										formik.touched.cupon &&
										!!formik.errors.cupon
									}
									readOnly={editData?true:false}
									disabled={editData?true:false}
								/>
								<Form.Control.Feedback type="invalid">
									{formik.errors.cupon}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group
								controlId="formBasicName"
								className="col-12 col-md-6 my-2"
							>
								<Form.Label>Descripción *</Form.Label>
								<Form.Control
									className=""
									type="text"
									placeholder="Descripción"
									name="descripcion"
									onChange={formik.handleChange}
									value={formik.values.descripcion}
									autoComplete="off"
									isValid={
										formik.touched.descripcion &&
										!formik.errors.descripcion
									}
									isInvalid={
										formik.touched.descripcion &&
										!!formik.errors.descripcion
									}
								/>
								<Form.Control.Feedback type="invalid">
									{formik.errors.descripcion}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group
								controlId="formBasicName"
								className="col-12 col-md-6 my-2"
							>
								<Form.Label>% descuento *</Form.Label>
								<Form.Control
									className=""
									type="text"
									placeholder="% de descuento"
									name="descuento"
									onChange={formik.handleChange}
									value={formik.values.descuento}
									autoComplete="off"
									isValid={
										formik.touched.descuento &&
										!formik.errors.descuento
									}
									isInvalid={
										formik.touched.descuento &&
										!!formik.errors.descuento
									}
								/>
								<Form.Control.Feedback type="invalid">
									{formik.errors.descuento}
								</Form.Control.Feedback>
							</Form.Group>
                            <Form.Group className='col-12 col-md-6 my-2'>
                            <Form.Label>Nro. Canjes *</Form.Label>
								<Form.Control
									className=""
									type="text"
									placeholder="Número de canjes"
									name="nro_canjes"
									onChange={formik.handleChange}
									value={formik.values.nro_canjes}
									autoComplete="off"
									isValid={
										formik.touched.nro_canjes &&
										!formik.errors.nro_canjes
									}
									isInvalid={
										formik.touched.nro_canjes &&
										!!formik.errors.nro_canjes
									}
								/>
								<Form.Control.Feedback type="invalid">
									{formik.errors.nro_canjes}
								</Form.Control.Feedback>
							</Form.Group>
                            <Form.Group className='col-12 col-md-6 my-2'>
                                <Form.Label>Fecha vencimiento *</Form.Label>
                                <ReactDatePicker
                                locale={'es'}
                                selected={formik.values.fecha_vencimiento}
                                onChange={(date) => {
                                    formik.setFieldValue('fecha_vencimiento', date);
                                }}
                                isClearable={true}
                                dateFormat='dd/MM/yyyy h:mm aa'
                                showTimeInput
                                timeInputLabel='Hora'
                                filterTime={filterPassedTime}
                                minDate={new Date()}
                                customInput={<CustomInputDate />}
                                name='fecha_vencimiento'
                                />
                                <Form.Control.Feedback type='invalid'>
                                {formik.errors.fecha_vencimiento}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                controlId='formBasicNivel'
                                className='col-12 col-md-6 my-2'>
                                <Form.Label>Tipo *</Form.Label>
                                <Form.Select
                                className='form-control'
                                name='tipo_producto'
                                onChange={formik.handleChange}
                                value={formik.values.tipo_producto}
                                isValid={formik.touched.tipo_producto && !formik.errors.tipo_producto}
                                isInvalid={
                                    formik.touched.tipo_producto && !!formik.errors.tipo_producto
                                }>
                                <option value=''>Seleccione un nivel</option>
                                <option value='1'>Plan</option>
                                <option value='2'>Curso</option>
                                </Form.Select>
                                <Form.Control.Feedback type='invalid'>
                                {formik.errors.tipo_producto}
                                </Form.Control.Feedback>
                            </Form.Group>
							<div className="d-flex w-100 justify-content-end mt-2">
								<button
									type="submit"
									className="btn btn-success my-2 mx-2"
								>
								Guardar
								</button>
								<button
									type="button"
									className="btn btn-secondary my-2"
									onClick={handleCloseModal}
								>
									Cancelar
								</button>
							</div>
						</Form>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalCreateEditCupon;
