import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getAllUsers,
  createUserRoles,
  deleteUsuarioRoles,
  editUser,
  resetPassword,
} from "../controllers/usuariosRoles/usuarios";
import {
  getAllRoles,
  createRoles,
  editRol,
} from "../controllers/usuariosRoles/roles";

const KEY_USER_ROLES = "KEYUSERROLES";
const KEY_ROLES = "KEYROLES";

/* USUARIOS */
export const useQueryAllUsers = () => {
  return useQuery([KEY_USER_ROLES], getAllUsers, {
    retry: 2,
    refetchOnWindowFocus: false,
  });
};

export const useMutateDeleteUser = (id_user) => {
  const queryClient = useQueryClient();
  return useMutation(deleteUsuarioRoles, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([KEY_USER_ROLES, id_user]);
    },
  });
};

export const useMutateCreateUser = () => {
  const queryClient = useQueryClient();
  return useMutation(createUserRoles, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([KEY_USER_ROLES]);
    },
  });
};

export const useMutateEditUser = () => {
  const queryClient = useQueryClient();
  return useMutation(editUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([KEY_USER_ROLES]);
    },
  });
};

export const useMutationResetPassword = () => {
  const queryClient = useQueryClient();
  return useMutation(resetPassword);
};

/* ROLES */

export const useQueryAllRoles = () => {
  return useQuery([KEY_ROLES], getAllRoles, {
    retry: 2,
    refetchOnWindowFocus: false,
  });
};

export const useMutateCreateRol = () => {
  const queryClient = useQueryClient();
  return useMutation(createRoles, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([KEY_ROLES]);
    },
  });
};

export const useMutateEditRol = () => {
  const queryClient = useQueryClient();
  return useMutation(editRol, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([KEY_ROLES]);
    },
  });
};
