import React from "react";
import { Spinner } from "react-bootstrap";

const LoaderSmall = () => {
	return (
		<div
			className="d-flex justify-content-center align-items-center"
			style={{
				height: "100%",
				width: "100%",
			}}
		>
			<Spinner animation="border" variant="primary" />
		</div>
	);
};

export default LoaderSmall;
