import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { SwalToast } from "../../utils/toasts"; 
import { editUser } from "../../controllers/usuariosRoles/usuarios"; 

const EditUserModal = ({ handleShow, dataUser }) => {
  const [roles, setRoles] = useState([]);

  const {
    nombres,
    apellidos,
    celular,
    id_pais,
    id_user,
    roles: roles_user,
  } = dataUser;

  useEffect(() => {
    if (roles_user.length > 0) {
      const soloRolId = roles_user.map((v) => parseInt(v.id_rol));
      setRoles(soloRolId);
    }
  }, []);

  const initialValues = {
    Nombres: nombres ? nombres : "",
    Apellidos: apellidos ? apellidos : "",
    Celular: celular ? celular : "",
    id_pais: id_pais ? id_pais : "",
    Roles: [],
  };

  const validationSchema = Yup.object({
    Nombres: Yup.string().required("Este campo es requerido"),
    Apellidos: Yup.string().required("Este campo es requerido"),
    Celular: Yup.string().required("Este campo es requerido"),
    id_pais: Yup.string().required("El país es requerido"),
    Roles: Yup.array().required("Este campo es requerido"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      values.Roles = JSON.stringify(roles);
      const response = await editUser(id_user, values);
      if (response.status) {
        SwalToast.fire({
          icon: "success",
          title: "Usuario editado con exito",
        });
      }
    },
  });

  const check = (value) => {
    const rolesSelected = [...roles];
    if (rolesSelected.includes(value)) {
      rolesSelected.splice(rolesSelected.indexOf(value), 1);
    } else {
      rolesSelected.push(value);
    }
    setRoles(rolesSelected);
  };

  return (
    <div className="row">
      <div className="col-12">
        <Form className="row" onSubmit={formik.handleSubmit}>
          <Form.Group className="my-2 col-md-6">
            <Form.Label>Nombres</Form.Label>
            <Form.Control
              name="Nombres"
              onChange={formik.handleChange}
              value={formik.values.Nombres}
              placeholder="Escribe los nombres"
              type="text"
              isValid={formik.touched.Nombres && !formik.errors.Nombres}
              isInvalid={formik.touched.Nombres && !!formik.errors.Nombres}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Nombres}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="my-2 col-md-6">
            <Form.Label>Apellidos</Form.Label>
            <Form.Control
              name="Apellidos"
              onChange={formik.handleChange}
              value={formik.values.Apellidos}
              placeholder="Escribe los Apellidos"
              type="text"
              isValid={formik.touched.Apellidos && !formik.errors.Apellidos}
              isInvalid={formik.touched.Apellidos && !!formik.errors.Apellidos}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Apellidos}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formBasicPhone" className="col-md-6 my-2">
            <Form.Label>Teléfono</Form.Label>
            <PhoneInput
              regions={["south-america"]}
              country={"pe"}
              value={formik.values.Celular}
              onChange={(phone, country) => {
                formik.setFieldValue("Celular", phone);
                formik.setFieldValue("id_pais", country.name.toUpperCase());
              }}
              autoFormat
              inputClass="form-control my-1 w-100"
              countryCodeEditable={false}
              masks={{
                pe: "... ... ...",
              }}
              isValid={(value, country) => {
                if (formik.touched.Celular && !!formik.errors.Celular) {
                  return "Número de celular inválido";
                } else {
                  return true;
                }
              }}
            />
          </Form.Group>
          <Form.Group className="my-2 col-md-6">
            <Form.Label>Roles</Form.Label>
            <Form.Check
              type="checkbox"
              label="ADMIN"
              checked={roles.includes(2)}
              onChange={() => check(2)}
            />
            <Form.Check
              type="checkbox"
              label="DOCENTE"
              checked={roles.includes(3)}
              onChange={() => check(3)}
            />
            <Form.Check
              type="checkbox"
              label="MARKETING"
              checked={roles.includes(4)}
              onChange={() => check(4)}
            />
            <Form.Check
              type="checkbox"
              label="MANAGER"
              checked={roles.includes(5)}
              onChange={() => check(5)}
            />
            <Form.Check
              type="checkbox"
              label="CONTABILIDAD"
              checked={roles.includes(6)}
              onChange={() => check(6)}
            />
          </Form.Group>
          <button type="submit" className="btn ge-btn-primary btn-block mt-3">
            Editar Usuario
          </button>
        </Form>
      </div>
    </div>
  );
};

export default EditUserModal;
