import React, { useState, useEffect } from "react";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import {
  useQueryGetAllEncuestas,
  useQueryGetEncuestasById,
} from "../../hooks/nps.hooks";
import { useQueryCursos, useQueryCursosNet } from "../../hooks/cursos.hooks";

const NetPromoterScore = () => {
  const { data, isLoading } = useQueryGetAllEncuestas();
  const { data: cursos } = useQueryCursosNet();

  const [selectCurso, setSelectCurso] = useState("");
  const [encuestas, setEncuestas] = useState({
    porcentaje_detractores: 0,
    porcentaje_promotores: 0,
    encuestas_respondidas: 0,
    resultado_nps: 0,
  });

  const [encuestasByCurso, setEncuestasByCurso] = useState({
    porcentaje_detractores: 0,
    porcentaje_promotores: 0,
    encuestas_respondidas: 0,
    resultado_nps: 0,
  });

  const handleSelect = (e) => {
    const { value } = e.target;
    setSelectCurso(value);
  };
  const { data: npsByCurso } = useQueryGetEncuestasById(selectCurso);

  const {
    porcentaje_detractores,
    porcentaje_promotores,
    encuestas_respondidas,
  } = encuestas;

  const {
    porcentaje_detractores_curso,
    porcentaje_promotores_curso,
    encuestas_respondidas_curso,
  } = encuestasByCurso;

  useEffect(() => {
    if (data) {
     // console.log(data)
      let countDectractores = 0;
      let countPromotores = 0;
      let encuestas_res=0;
      data.forEach((encuesta) => {
        if (parseInt(encuesta.valoracion) <= 6 && encuesta.fecha_valoracion!=null) {
          countDectractores += 1;
        } 
        if(parseInt(encuesta.valoracion) >= 9 && encuesta.fecha_valoracion!=null){
          countPromotores += 1;
        }
        if(encuesta.fecha_valoracion!=null)
        {
          encuestas_res+=1;
        }
      });
      const parserData = {
        porcentaje_detractores:
          parseFloat((countDectractores / encuestas_res)* 100).toFixed(2),
        porcentaje_promotores: parseFloat((countPromotores / encuestas_res)* 100).toFixed(2),
        encuestas_respondidas: encuestas_res,
      };
      setEncuestas({ ...encuestas, ...parserData });
    }

    if (npsByCurso) {
      // console.log(data)
       let countDectractores = 0;
       let countPromotores = 0;
       let encuestas_res=0;
       npsByCurso.forEach((encuesta) => {
         if (parseInt(encuesta.valoracion) <= 6 && encuesta.fecha_valoracion!=null) {
           countDectractores += 1;
         } 
         if(parseInt(encuesta.valoracion) >= 9 && encuesta.fecha_valoracion!=null){
           countPromotores += 1;
         }
         if(encuesta.fecha_valoracion!=null)
         {
           encuestas_res+=1;
         }
       });
       const parserData = {
         porcentaje_detractores_curso:
           parseFloat((countDectractores / encuestas_res)* 100).toFixed(2),
         porcentaje_promotores_curso: parseFloat((countPromotores / encuestas_res)* 100).toFixed(2),
         encuestas_respondidas_curso: encuestas_res,
       };
       
       setEncuestasByCurso({ ...encuestas, ...parserData });
     }
  },[data,npsByCurso]);

  return (
    <LayoutAdmin>
      <div className="card p-4">
        <div className="col-md-12 d-flex align-items-start justify-content-center mt-4 p-3">
          <div className="col-md-4 card-nps">
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <span style={{ fontSize: "22px", fontWeight: "600" }}>
                  {encuestas_respondidas}
                </span>
                <span style={{ fontSize: "14px" }}>Encuestas respondidas</span>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <span style={{ fontSize: "22px", fontWeight: "600" }}>
                  {porcentaje_promotores - porcentaje_detractores + "%"}
                </span>
                <span style={{ fontSize: "14px" }}>Resultado % NPS</span>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center col-md-12 mt-4">
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <span className="mb-1">PROMOTORES</span>
                  <img
                    src="images/carita-npsgreen.png"
                    alt="carita-green"
                    width={45}
                  />
                  <span style={{ fontSize: "20px", fontWeight: "600" }}>
                    {porcentaje_promotores}%
                  </span>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <span className="mb-1">DETRACTORES</span>
                  <img
                    src="images/carita-npsred.png"
                    alt="carita-red"
                    width={45}
                  />
                  <span style={{ fontSize: "20px", fontWeight: "600" }}>
                    {porcentaje_detractores}%
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 card-nps ml-5">
            <div className="d-flex align-items-center justify-content-center w-100">
              <span style={{ fontSize: "20px" }}>Curso:</span>
              <select
                name="curso"
                className="form-control mx-2 my-2"
                onChange={handleSelect}
              >
                {cursos?.map((curso, index) => (
                  <option key={index} value={curso.id_curso}>
                    {curso.nombre_curso}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <span style={{ fontSize: "22px", fontWeight: "600" }}>
                  {encuestas_respondidas_curso}
                </span>
                <span style={{ fontSize: "14px" }}>Encuestas respondidas</span>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <span style={{ fontSize: "22px", fontWeight: "600" }}>{porcentaje_promotores_curso-porcentaje_detractores_curso}%</span>
                <span style={{ fontSize: "14px" }}>Resultado % NPS</span>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center col-md-12 mt-4">
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <span className="mb-1">PROMOTORES</span>
                  <img
                    src="images/carita-npsgreen.png"
                    alt="carita-green"
                    width={45}
                  />
                  <span style={{ fontSize: "20px", fontWeight: "600" }}>
                    {porcentaje_promotores_curso}%
                  </span>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <span className="mb-1">DETRACTORES</span>
                  <img
                    src="images/carita-npsred.png"
                    alt="carita-red"
                    width={45}
                  />
                  <span style={{ fontSize: "20px", fontWeight: "600" }}>
                    {porcentaje_detractores_curso}%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default NetPromoterScore;
