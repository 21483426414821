import { useQuery } from "react-query"
import { getOrganicTraffic } from "../../../controllers/marketing/google_analytics/googleAnalytics.controller";


const KEY_ORGANIC_TRAFFIC = "organicTraffic";

export const useQueryOrganicTraffic = (fecha_inicio, fecha_fin) => {
    return useQuery(
        [KEY_ORGANIC_TRAFFIC],
        () => getOrganicTraffic(fecha_inicio, fecha_fin),
        {
            refetchOnWindowFocus: false,
            retry: 2,
        }
    )
}