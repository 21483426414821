import { useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import { Form, Modal, Spinner } from 'react-bootstrap';
import * as yup from 'yup';
import * as FaIcons from 'react-icons/fa';
import InputModalIcons from '../Custom/InputModalIcons';
import { useMutateCreateCurso } from '../../hooks/cursos.hooks';
import { SwalToast } from '../../utils/toasts';
import { useQueryDocentesSelect } from '../../hooks/docentes.hooks';
import ReactDatePicker from 'react-datepicker';
import CustomInputDate from '../Custom/CustomInputDate';
import { useEffect } from 'react';
import { filterPassedTime } from '../../utils/time';
import { SUPPORTED_IMG_FORMATS } from '../../utils/helpers';

const validationSchema = yup.object({
  nombre: yup.string().min(2).required('El nombre es requerido'),
  docente: yup.string(),
  descripcion: yup.string().required('La descripción es requerida.'),
  videoPresentacion: yup.string().url('Ingrese una URL válida.'),
  duracion: yup.string(),
  idNivel: yup.string().required('El nivel es requerido.'),
  icono: yup.string().required('El icono es requerido.'),
  precio: yup.number().required('El precio es requerido.'),
  fecha_lanzamiento: yup
    .date()
    .required('La fecha de lanzamiento es requerida.'),
  imagen: yup
    .mixed()
    .required('La imagen es requerida.')
    .test('fileType', 'El archivo debe ser una imagen', (value) => {
      return value && SUPPORTED_IMG_FORMATS.includes(value.type);
    }),
});

const ModalCreateCourse = ({ show, setShow }) => {
  //fecha de lanzamiento
  // const [startDate, setStartDate] = useState(new Date());

  const [loading, setLoading] = useState(false);
  const [showModalIcon, setShowModalIcon] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const imgInputRef = useRef();
  const {
    data: dataCourse,
    isError,
    mutate: mutateCurso,
  } = useMutateCreateCurso();

  const { data: docentesSelect } = useQueryDocentesSelect();

  const initialValues = {
    nombre: '',
    docente: '',
    descripcion: '',
    imagen: null,
    videoPresentacion: '',
    duracion: '',
    idNivel: '',
    icono: '',
    precio: 0,
    fecha_lanzamiento: new Date(),
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      mutateCurso(values, {
        onSuccess: (data) => {
          //console.log(data);
          SwalToast.fire({
            icon: 'success',
            title: 'Curso creado correctamente',
          });
          setShow(false);
          formik.resetForm();
        },
        onError: () => {
          SwalToast.fire({
            icon: 'error',
            title: 'Error al crear el curso',
          });
        },
      });
    },
  });

  //Cambia la imagen de perfil
  const handleChangeImage = (e) => {
    e.preventDefault();
    if (imgInputRef.current.files.length > 0) {
      //validar solo imagenes
      const file = e.target.files[0];
      if (file.type.indexOf('image') < 0) {
        return;
      }
      // console.log(file);
      setImagePreview(URL.createObjectURL(file));
      formik.setFieldValue('imagen', file);
    }
  };

  const handleCloseModal = () => {
    formik.resetForm();
    URL.revokeObjectURL(imagePreview);
    setImagePreview(null);
    setShow(false);
  };

  return (
    <Modal
      show={show}
      size='md'
      centered
      backdrop='static'
      onHide={() => setShow(false)}>
      <Modal.Header className='ge-bg-primary txt-white' closeButton>
        <Modal.Title>Crear curso</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='container'>
          <div className='row'>
            <div className='card'>
              <div className='card-body'>
                <Form
                  className='row form-add-docente'
                  onSubmit={formik.handleSubmit}>
                  <Form.Group className='col-12'>
                    {/* Img perfil */}
                    <div className='d-flex justify-content-center w-100 mb-md-3 img-profile-container'>
                      <img
                        src={
                          !imagePreview
                            ? 'https://via.placeholder.com/150?text=Imagen+de+portada'
                            : imagePreview
                        }
                        alt='...'
                        className='img-profile'
                      />
                      <div className='button-container'>
                        <input
                          type='file'
                          ref={imgInputRef}
                          onChange={(e) => {
                            handleChangeImage(e);
                          }}
                          className='d-none'
                          disabled={loading}
                          accept={SUPPORTED_IMG_FORMATS.join(', ')}
                        />
                        <span
                          className='img-profile-button text-center'
                          onClick={() => imgInputRef.current.click()}>
                          <FaIcons.FaPencilAlt />
                        </span>
                      </div>
                    </div>
                    {formik.errors.imagen && formik.touched.imagen && (
                      <div className='text-center'>
                        <span className='text-danger'>
                          {formik.errors.imagen}
                        </span>
                      </div>
                    )}
                  </Form.Group>

                  <Form.Group
                    controlId='formBasicName'
                    className='col-12 col-md-6 my-2'>
                    <Form.Label>Nombre Curso *</Form.Label>
                    <Form.Control
                      className='ge-form'
                      type='text'
                      placeholder='Nombres'
                      name='nombre'
                      onChange={formik.handleChange}
                      value={formik.values.nombre}
                      autoComplete='off'
                      isValid={formik.touched.nombre && !formik.errors.nombre}
                      isInvalid={
                        formik.touched.nombre && !!formik.errors.nombre
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.nombre}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    controlId='formBasicName'
                    className='col-12 col-md-6 my-2'>
                    <Form.Label>Precio *</Form.Label>
                    <Form.Control
                      className='ge-form'
                      type='text'
                      placeholder='Precio'
                      name='precio'
                      onChange={formik.handleChange}
                      value={formik.values.precio}
                      autoComplete='off'
                      isValid={formik.touched.precio && !formik.errors.precio}
                      isInvalid={
                        formik.touched.precio && !!formik.errors.precio
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.precio}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    controlId='formVideoUrl'
                    className='col-12 col-md-6 my-2'>
                    <Form.Label>Video Presentación *</Form.Label>
                    <Form.Control
                      className='ge-form'
                      type='text'
                      placeholder='Eg: youtube.com/etc'
                      name='videoPresentacion'
                      onChange={formik.handleChange}
                      value={formik.values.videoPresentacion}
                      autoComplete='off'
                      isValid={
                        formik.touched.videoPresentacion &&
                        !formik.errors.videoPresentacion
                      }
                      isInvalid={
                        formik.touched.videoPresentacion &&
                        !!formik.errors.videoPresentacion
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.videoPresentacion}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    controlId='formBasicNivel'
                    className='col-12 col-md-6 my-2'>
                    <Form.Label>Nivel *</Form.Label>
                    <Form.Select
                      className='form-control ge-form'
                      name='idNivel'
                      onChange={formik.handleChange}
                      value={formik.values.idNivel}
                      isValid={formik.touched.idNivel && !formik.errors.idNivel}
                      isInvalid={
                        formik.touched.idNivel && !!formik.errors.idNivel
                      }>
                      <option value=''>Seleccione un nivel</option>
                      <option value='1'>Básico</option>
                      <option value='2'>Intermedio</option>
                      <option value='3'>Avanzado</option>
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.idNivel}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    controlId='formBasicDocente'
                    className='col-12 col-md-6 my-2'>
                    <Form.Label>Docente *</Form.Label>
                    <Form.Select
                      className='form-control ge-form'
                      name='docente'
                      onChange={formik.handleChange}
                      value={formik.values.docente}
                      isValid={formik.touched.docente && !formik.errors.docente}
                      isInvalid={
                        formik.touched.docente && !!formik.errors.docente
                      }>
                      <option value=''>Seleccione un docente</option>
                      {docentesSelect &&
                        docentesSelect.map((docente) => (
                          <option value={docente.id} key={docente.id}>
                            {`${docente.nombres} ${docente.apellidos}`}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.docente}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/*<Form.Group
                    controlId='Duración (en minutos)'
                    className='col-12 col-md-6 my-2'>
                    <Form.Label>Duración del curso</Form.Label>
                    <Form.Control
                      className='ge-form'
                      type='text'
                      placeholder='Eg: 60'
                      name='duracion'
                      onChange={formik.handleChange}
                      value={formik.values.duracion}
                      autoComplete='off'
                      isValid={
                        formik.touched.duracion && !formik.errors.duracion
                      }
                      isInvalid={
                        formik.touched.duracion && !!formik.errors.duracion
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.duracion}
                    </Form.Control.Feedback>
                    </Form.Group>*/}

                  <Form.Group
                    controlId='formBasicIcon'
                    className='col-12 col-md-6 my-2'>
                    <Form.Label>Icono *</Form.Label>
                    <InputModalIcons
                      show={showModalIcon}
                      setShow={setShowModalIcon}
                      icon={formik.values.icono}
                      setIcon={formik.setFieldValue}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.icono}
                    </Form.Control.Feedback>
                  </Form.Group>

                  

                  <Form.Group className='col-12 col-md-6 my-2'>
                    <Form.Label>Fecha de lanzamiento:</Form.Label>
                    <ReactDatePicker
                      locale={'es'}
                      selected={formik.values.fecha_lanzamiento}
                      onChange={(date) => {
                        formik.setFieldValue('fecha_lanzamiento', date);
                      }}
                      isClearable={true}
                      dateFormat='dd/MM/yyyy h:mm aa'
                      showTimeInput
                      timeInputLabel='Hora'
                      filterTime={filterPassedTime}
                      minDate={new Date()}
                      customInput={<CustomInputDate />}
                      name='fecha_lanzamiento'
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.fecha_lanzamiento}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    controlId='formBasicDescription'
                    className='col-12 my-2'>
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control
                      className='ge-form'
                      as='textarea'
                      rows='3'
                      placeholder='Descripción'
                      name='descripcion'
                      onChange={formik.handleChange}
                      value={formik.values.descripcion}
                      isValid={
                        formik.touched.descripcion && !formik.errors.descripcion
                      }
                      isInvalid={
                        formik.touched.descripcion &&
                        !!formik.errors.descripcion
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.descripcion}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className='d-flex w-100 justify-content-around'>
                    <button type='submit' className='btn ge-btn-primary my-2'>
                      Guardar
                    </button>
                    <button
                      type='button'
                      className='btn btn-secondary my-2'
                      onClick={handleCloseModal}>
                      Cancelar
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCreateCourse;
