// import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {getTokenLS} from '../utils/localStorage';


const PrivateRoutes = ({ component:Component }) => {
    
    const token = getTokenLS();
    
    return(
        <Routes>
            <Route
                path="/*"
                element={
                    token ? <Component /> : <Navigate to="/login" />
                }
            />
        </Routes>
    )
}

export default PrivateRoutes;