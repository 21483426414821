import React from "react";
import './loader.scss';

const Loader = () => {
	return (
		<div
			style={{
				width: "100vw",
				height: "100vh",
				backgroundColor: "#752ae5",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
			}}
		>
			<div className="sk-chase">
				<div className="sk-chase-dot" />
				<div className="sk-chase-dot" />
				<div className="sk-chase-dot" />
				<div className="sk-chase-dot" />
				<div className="sk-chase-dot" />
				<div className="sk-chase-dot" />
			</div>
            <div className="mt-3 txt-loader">
                <p>Cargando</p>
            </div>
		</div>
	);
};

export default Loader;
