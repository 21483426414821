import { legacy_createStore as createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { reducers } from "./reducers";
import thunkMiddleware from "redux-thunk";

export const store = createStore(
	reducers,
	composeWithDevTools(
		applyMiddleware(
            thunkMiddleware
        )
	)
);
