import React, { useMemo, useState } from "react";
import HeaderSearchTable from "../../components/Custom/HeaderSearchTable";
import LayoutAdmin from "../../components/layouts/LayoutAdmin";
import * as FaIcons from "react-icons/fa";
import Swal from "sweetalert2";
import { SwalToast } from "../../utils/toasts";
import DataTable from "react-data-table-component";
import LoaderSmall from "../../components/Loaders/LoaderSmall";
import SubirFacturasModal from "../../components/Facturas/SubirFacturasModal";
import { useMutateDeleteFacturaById, useQueryFacturasList } from "../../hooks/factura.hooks";

const SubirFacturaScreen = () => {
    
	const [filterText, setFilterText] = useState("");
	const [resetPagination, setResetPagination] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const {data: facturasList, isLoading} = useQueryFacturasList();

	const { mutate: mutateDeleteFactura } = useMutateDeleteFacturaById();
    
	
	/* Search Component */
	const searchComponentMemo = useMemo(() => {
		return (
			<HeaderSearchTable
				filterText={filterText}
				setFilterText={setFilterText}
				arrayData={facturasList}
				setArrayFiltered={setFilteredItems}
				setResetPagination={setResetPagination}
				placeholder="Buscar por DNI o RUC"
				columnsToFilter={["numero_documento"]}
			/>
		);
	}, [filterText, resetPagination]);
    
	
	const handleDeleteFile = (id_doc) => {
		mutateDeleteFactura(id_doc)
	}
	
	/* Config Table */
	const columns = useMemo(
		() => [
			{
				name: 'ID',
				selector: (row) => row.id_doc,
				width: '10%',
			},
			{
				name: "DNI o RUC",
                width: '250px',
				selector: (row) => row.numero_documento,
			},
			{
				name: "Fecha de registro",
				selector: (row) => row.fecha_registro,
			},
			{
				name: "Acciones",
                cell: (row) => (
					<div className="d-flex justify-content-center align-items-center">
						{/* Boton para ver el archivo en otra pestaña */}
						<a href={process.env.REACT_APP_API_URL+row.archivo} target="_blank" rel="noopener noreferrer">
							<button className="btn btn-documento btn-sm">
								<FaIcons.FaFilePdf className="text-light" />
							</button>
						</a>
						{/* Boton para eliminar */}
						<button className="btn btn-danger btn-sm ml-2" onClick={() => handleDeleteFile(row.id_doc)}>
							<FaIcons.FaTrashAlt />
						</button>
					</div>
				)
			},
		],
		[]
	);
	
	
	
	return (
		<LayoutAdmin contentTitle="" routeTitle="Lista de docentes">
            <SubirFacturasModal
                showModal={showModal}
                setShowModal={setShowModal}
            />
			<div className="row">
				<div className="col-12">
					<div className="card p-2">
						<div className="d-flex justify-content-between card-header card-no-after ge-noborder">
							<div>
								<img
									src="/images/file-icon.png"
									className="mr-2"
									style={{ width: "40px" }}
									alt="file-icon"
								/>
								<span className="ge-h3 ge-title">
									Facturas
								</span>
							</div>
							<div className="">
								<button
									className="btn ge-btn-deg-primary btn-sm"
									onClick={() => setShowModal(true)}
								>
									<FaIcons.FaPlus />
									<span className="ml-2">Subir facturas</span>
								</button>
							</div>
						</div>
						<div className="card-body">
							<DataTable
								className="custom-datatable"
								columns={columns}
								data={
									filteredItems.length > 0
										? filteredItems
										: facturasList
								}
								subHeader
								subHeaderComponent={searchComponentMemo}
								pagination
								responsive
								progressPending={isLoading}
								progressComponent={<LoaderSmall />}
								noDataComponent={
									<div className="d-flex justify-content-center  mt-4 p-4">
										<span className="ge-text-muted">
											No hay facturas registradas
										</span>
									</div>
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</LayoutAdmin>
	);
};

export default SubirFacturaScreen;
